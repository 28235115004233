/** @jsxImportSource @emotion/react */
import { Modal } from "../../modal";
import { useAppDispatch } from "store/storeTypes";
import { toggleCapabilitySearchModal } from "store/reducers/modalsSlice";
import tw from "twin.macro";
import { useSourceGroups } from "components/organisms/ResponsesWithSources/hooks";
import { useEffect, useState } from "react";
import type { WorkspaceFile } from "types/FileStorage";
import { getFile } from "api/api";
import CapabilitySearchModalHeader from "./CapabilitySearchModalHeader";
import SourceGroupList from "./SourceGroupList";

type CapabilitySearchModalInitialProps = {
  sourceChunkIds: string[];
  sourceId: string;
  sourceName: string;
  sourceType: string;
};

export type CapabilitySearchModalProps = {
  open: boolean;
  initialProps?: Partial<CapabilitySearchModalInitialProps>;
};

const CapabilitySearchModal = ({ open, initialProps }: CapabilitySearchModalProps) => {
  const { sourceChunkIds, sourceId, sourceName, sourceType } = initialProps || {};
  const dispatch = useAppDispatch();
  const [foundFile, setFoundFile] = useState<WorkspaceFile>();

  useEffect(() => {
    if (!sourceId || !open) return;
    getFile(sourceId).then((res) => {
      const file: WorkspaceFile = res?.data;
      setFoundFile(file);
    });
    return () => {
      setFoundFile(undefined);
    };
  }, [sourceId, open]);

  const { data, loading, expandContent, canExpandDown, canExpandUp } = useSourceGroups(
    sourceType || "",
    sourceId || "",
    sourceChunkIds || [],
  );

  // This helper attaches a ResizeObserver to a container so that once its size
  // changes (i.e. new content is added), it scrolls to the given position.
  const scrollOnResize = (container: HTMLDivElement, scrollOptions: ScrollToOptions) => {
    const observer = new ResizeObserver(() => {
      container.scrollTo({ ...scrollOptions, behavior: "smooth" });
      observer.disconnect();
    });
    observer.observe(container);
  };

  return (
    <Modal
      key={sourceId}
      title="Source File Information"
      modal
      open={open}
      onOpenChange={(openState) => {
        dispatch(toggleCapabilitySearchModal({ open: openState }));
      }}
      contentProps={{ css: tw`!min-w-[1000px] !max-w-[1000px]` }}
      header={<CapabilitySearchModalHeader sourceName={sourceName || ""} foundFile={foundFile} />}
      body={
        <div className="text-slate-700 px-6 text-sm text-gray-primary font-normal break-words whitespace-pre-line overflow-y-auto">
          {loading && <div>Loading content...</div>}
          {data && (
            <SourceGroupList
              data={data}
              expandContent={expandContent}
              canExpandUp={canExpandUp}
              canExpandDown={canExpandDown}
              scrollOnResize={scrollOnResize}
            />
          )}
        </div>
      }
    />
  );
};

export default CapabilitySearchModal;

import React, { useState, useEffect, useMemo } from "react";
import TeamFilter from "../TeamFilter";
import TypeFilter from "../TypeFilter";
import SearchBar from "../SearchBar";
import WorkflowCard from "./WorkflowCard";
import type { Workflow } from "pages/ai-assistant/constants";
import { WORKFLOW_OPTIONS } from "pages/ai-assistant/constants";
import type { TeamFilterValue, TypeFilterValue } from "../constants";
import { ALL_TEAMS, ALL_TYPES } from "../constants";
import { useFlags } from "hook/useFlags";
import { useFavoriteWorkflows } from "../useFavoriteWorkflows/useFavoriteWorkflows";
import FavoriteWorkflowsSection from "./FavoriteWorkflowsSection";

const Workflows: React.FC = () => {
  const flags = useFlags();
  const [currentTeamFilter, setCurrentTeamFilter] = useState<TeamFilterValue>(ALL_TEAMS);
  const [currentTypeFilter, setCurrentTypeFilter] = useState<TypeFilterValue>(ALL_TYPES);
  const [query, setQuery] = useState("");
  const [filteredWorkflows, setFilteredWorkflows] = useState<Workflow[]>(WORKFLOW_OPTIONS);

  const { hasFetchedFavorites, favoriteWorkflows, handleFavoriteWorkflow, handleUnfavoriteWorkflow } =
    useFavoriteWorkflows();

  const getFavoriteWorkflowObjects = (favoriteSubtypes: string[]): Workflow[] =>
    WORKFLOW_OPTIONS.filter((workflow) => favoriteSubtypes.includes(workflow.subType)).sort((a, b) =>
      a.label.localeCompare(b.label),
    );

  const favorites = useMemo(() => getFavoriteWorkflowObjects(favoriteWorkflows), [favoriteWorkflows]);
  const workflowFeatureConfig = flags.globalAssistantWorkflows;

  useEffect(() => {
    let workflows = WORKFLOW_OPTIONS;

    if (!workflowFeatureConfig || !workflowFeatureConfig.workflows) {
      workflows = [];
    } else {
      const enabledWorkflowSubTypes = workflowFeatureConfig.workflows
        .filter((item) => item.enabled)
        .map((item) => item.name);

      workflows = workflows.filter((workflow) => enabledWorkflowSubTypes.includes(workflow.subType));
      workflows.sort((a, b) => a.label.localeCompare(b.label));
    }

    if (currentTeamFilter !== ALL_TEAMS) {
      workflows = workflows.filter((workflow) => workflow.teams.includes(currentTeamFilter));
    }

    if (currentTypeFilter !== ALL_TYPES) {
      workflows = workflows.filter((workflow) => workflow.type === currentTypeFilter);
    }

    if (query) {
      workflows = workflows.filter((workflow) => workflow.label.toLowerCase().includes(query.toLowerCase()));
    }

    setFilteredWorkflows(workflows);
  }, [currentTeamFilter, currentTypeFilter, query, workflowFeatureConfig]);

  const topWorkflows = filteredWorkflows.filter((workflow) => workflow.top);

  return (
    <div className="bg-white relative z-0 flex h-full w-full overflow-y-scroll mb-12">
      <div className="w-full max-w-6xl mx-auto flex flex-col items-center gap-5 p-6 py-12">
        <h1 className="font-medium text-3xl">Explore Workflows</h1>
        <div className="text-gray-500 text-lg mb-1">
          Workflows guide you with clear steps to help you complete a specific task.
        </div>
        <div className="flex flex-col sm:flex-row gap-4 w-full">
          <TeamFilter currentFilter={currentTeamFilter} setCurrentFilter={setCurrentTeamFilter} />
          <TypeFilter currentFilter={currentTypeFilter} setCurrentFilter={setCurrentTypeFilter} />
          <SearchBar query={query} setQuery={setQuery} />
        </div>
        {!query && (
          <FavoriteWorkflowsSection
            favorites={favorites}
            favoriteWorkflows={favoriteWorkflows}
            handleFavoriteWorkflow={handleFavoriteWorkflow}
            handleUnfavoriteWorkflow={handleUnfavoriteWorkflow}
            hasFetchedFavorites={hasFetchedFavorites}
          />
        )}
        {topWorkflows.length > 0 && !query && (
          <>
            <h2 className="font-medium text-xl self-start mt-4">Featured</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full">
              {topWorkflows.map((workflow) => (
                <WorkflowCard
                  key={workflow.label}
                  workflow={workflow}
                  favoriteWorkflows={favoriteWorkflows}
                  handleFavoriteWorkflow={handleFavoriteWorkflow}
                  handleUnfavoriteWorkflow={handleUnfavoriteWorkflow}
                />
              ))}
            </div>
          </>
        )}
        {filteredWorkflows.length > 0 && (
          <>
            <h2 className="font-medium text-xl self-start mt-4">All</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full">
              {filteredWorkflows.map((workflow) => (
                <WorkflowCard
                  key={workflow.label}
                  workflow={workflow}
                  favoriteWorkflows={favoriteWorkflows}
                  handleFavoriteWorkflow={handleFavoriteWorkflow}
                  handleUnfavoriteWorkflow={handleUnfavoriteWorkflow}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Workflows;

import { WorkflowSub } from "pages/ai-assistant/constants";
import { useProjectWorkflowActions } from "pages/Workflows/useProjectWorkflowActions";
import { useCallback, useState } from "react";
import { toggleAssistant } from "store/reducers/copilot/copilotDrawerReducer";
import { setHideBlock, setHighlightedText } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch } from "store/storeTypes";

export const useTriggerFeedback = (commentContent: string, quoteText?: string) => {
  const dispatch = useAppDispatch();
  const { createNewWorkflow } = useProjectWorkflowActions();
  const [isLoading, setIsLoading] = useState(false);

  const handleApplyFeedback = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await createNewWorkflow(WorkflowSub.Revise, undefined, undefined, {
        revision_list: [commentContent],
        completed: true,
      });
      dispatch(toggleAssistant(true));
      dispatch(setHideBlock(true));
      if (quoteText) dispatch(setHighlightedText(quoteText));
    } catch {
    } finally {
      setIsLoading(false);
    }
  }, [commentContent, createNewWorkflow, dispatch, isLoading, quoteText]);

  return { handleApplyFeedback, isSubmittingFeedback: isLoading };
};

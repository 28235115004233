import { useEffect, useState } from "react";
import type { Editor } from "@tiptap/react";
import { ListOrderedIcon } from "../icons/ListOrdered";
import { ListUnorderedIcon } from "../icons/ListUnordered";
import { Button } from "../primitives/Button";
import styles from "./Toolbar.module.css";
import { ToolbarTooltip } from "../primitives/Tooltip";

type BlockState = {
  bulletList: boolean;
  orderedList: boolean;
};

export const ToolbarBlock = ({ editor, variant = "default" }: { editor: Editor; variant?: "default" | "bubble" }) => {
  const [blockState, setBlockState] = useState<BlockState>({
    bulletList: false,
    orderedList: false,
  });
  const isBubble = variant === "bubble";

  useEffect(() => {
    if (!editor) return;

    const updateBlockState = () => {
      setBlockState({
        bulletList: editor.isActive("bulletList"),
        orderedList: editor.isActive("orderedList"),
      });
    };

    updateBlockState();

    editor.on("selectionUpdate", updateBlockState);

    return () => {
      editor.off("selectionUpdate", updateBlockState);
    };
  }, [editor]);

  const iconProps = isBubble ? { style: { color: "#F4F4F5", fill: "#F4F4F5" } } : {};

  return (
    <>
      <ToolbarTooltip content="Bulleted list">
        <Button
          className={styles.toolbarButton}
          variant={isBubble ? "bubble" : "subtle"}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          disabled={!editor.can().chain().focus().toggleBulletList().run()}
          data-active={blockState.bulletList ? "is-active" : undefined}
          aria-label="Unordered list"
        >
          <ListUnorderedIcon {...iconProps} />
        </Button>
      </ToolbarTooltip>

      <ToolbarTooltip content="Numbered list">
        <Button
          className={styles.toolbarButton}
          variant={isBubble ? "bubble" : "subtle"}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          disabled={!editor.can().chain().focus().toggleOrderedList().run()}
          data-active={blockState.orderedList ? "is-active" : undefined}
          aria-label="Ordered list"
        >
          <ListOrderedIcon {...iconProps} />
        </Button>
      </ToolbarTooltip>
    </>
  );
};

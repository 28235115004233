import { DropdownMenu, DropdownMenuLabel } from "components/molecules/dropdown-menu";
import { ListFilter, MessageSquarePlus, X } from "lucide-react";
import { CommentsStatusFilter, CommentsUserFilter } from "../types";
import { useAppSelector } from "store/storeTypes";

interface CommentsHeaderProps {
  statusFilter: CommentsStatusFilter;
  userFilter: CommentsUserFilter;
  onStatusFilterChange: (newState: CommentsStatusFilter) => void;
  onUserFilterChange: (newState: CommentsUserFilter) => void;
  onClose: () => void;
}

const STATUS_FILTER_LABELS: Record<CommentsStatusFilter, string> = {
  [CommentsStatusFilter.Open]: "Open",
  [CommentsStatusFilter.Resolved]: "Resolved",
};

const USER_FILTER_LABELS: Record<CommentsUserFilter, string> = {
  [CommentsUserFilter.AllUsers]: "All Users",
  [CommentsUserFilter.VultronOnly]: "Vultron Only",
  [CommentsUserFilter.MemberOnly]: "Team Members",
  [CommentsUserFilter.AssignedToMe]: "Assigned to Me",
};

export const CommentsHeader = ({
  statusFilter,
  userFilter,
  onStatusFilterChange,
  onUserFilterChange,
  onClose,
}: CommentsHeaderProps) => {
  const editor = useAppSelector((state) => state.copilot.activeCommentEditor);

  const statusItems = [
    {
      key: "statusFilter",
      node: <DropdownMenuLabel label="Filter by status" />,
    },
    ...([CommentsStatusFilter.Open, CommentsStatusFilter.Resolved] as CommentsStatusFilter[]).map((filter) => ({
      key: filter,
      label: STATUS_FILTER_LABELS[filter],
      onSelect: () => onStatusFilterChange(filter),
      selected: statusFilter === filter,
    })),
  ];

  const userItems = [
    {
      key: "userFilter",
      node: <DropdownMenuLabel label="Filter by type" className="border-t border-t-gray-light mt-1.5 pt-3" />,
    },
    ...(
      [
        CommentsUserFilter.AllUsers,
        CommentsUserFilter.VultronOnly,
        CommentsUserFilter.MemberOnly,
        CommentsUserFilter.AssignedToMe,
      ] as CommentsUserFilter[]
    ).map((filter) => ({
      key: filter,
      label: USER_FILTER_LABELS[filter],
      onSelect: () => onUserFilterChange(filter),
      selected: userFilter === filter,
    })),
  ];

  const handleAddComment = () => {
    if (editor) {
      editor.chain().focus().setActiveComment().setDraft().run();
    }
  };

  return (
    <div className="h-[64px] flex items-center px-4 py-3 border-b border-gray-200">
      <div className="flex-1 flex items-center gap-1">
        <DropdownMenu
          items={[...statusItems, ...userItems]}
          triggerProps={{ className: "p-1 rounded-md hover:bg-gray-100 transition-colors" }}
        >
          <ListFilter size={20} color="#1E1E1e" />
        </DropdownMenu>
      </div>

      <div className="flex items-center gap-2">
        <h2 className="text-base font-normal">Comments</h2>
        <button
          className="p-1 rounded-md hover:bg-gray-100 transition-colors"
          onClick={handleAddComment}
          disabled={!editor || !editor.can().chain().focus().setDraft().run()}
          aria-label="Add comment"
        >
          <MessageSquarePlus size={20} color="#7A7F84" />
        </button>
      </div>

      <div className="flex-1 flex justify-end">
        <button onClick={onClose} className="p-1 rounded-md hover:bg-gray-100 transition-colors">
          <X size={20} />
        </button>
      </div>
    </div>
  );
};

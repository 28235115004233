import type { Editor } from "@tiptap/react";
import { BubbleMenu } from "@tiptap/react";
import styles from "./text-editor/TextEditor.module.css";
import { ToolbarAddComment } from "./ToolbarAddComment";
import { ToolbarRevise } from "./ToolbarRevise";
import { ToolbarTone } from "./ToolbarTone";
import { ToolbarDraft } from "./ToolbarDraft";
import { useFlags } from "hook/useFlags";
import { ToolbarScore } from "./ToolbarScore";
import { useAppSelector } from "store/storeTypes";

type Props = {
  editor: Editor;
};

export function SelectionMenu({ editor }: Props) {
  const flags = useFlags();
  const { toggleAskAiInputWthDrpDown } = useAppSelector((state) => state.yjsEditor);

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        zIndex: 99,
        followCursor: true,
        placement: "top-end",
        appendTo: () => document.getElementById("bubble-menu-portal") || document.body,
      }}
    >
      {shouldShowBubbleMenu(editor) ? (
        <div
          className={`${styles.bubbleMenuWrapper} w-max`}
          style={{ visibility: toggleAskAiInputWthDrpDown ? "hidden" : "visible" }}
        >
          {flags.showProjectWorkflows && (
            <>
              <ToolbarDraft editor={editor} />
              <div className={styles.bubbleDivider} />
              <ToolbarTone editor={editor} />
              <div className={styles.bubbleDivider} />
              <ToolbarRevise editor={editor} />
              <div className={styles.bubbleDivider} />
              <ToolbarScore editor={editor} />
              <div className={styles.bubbleDivider} />
            </>
          )}
          <ToolbarAddComment editor={editor} variant="bubble" />
        </div>
      ) : null}
    </BubbleMenu>
  );
}
export function shouldShowBubbleMenu(editor: Editor) {
  const canBold = editor.can().chain().focus().toggleBold().run();
  const canItalic = editor.can().chain().focus().toggleItalic().run();
  const canStrike = editor.can().chain().focus().toggleStrike().run();
  return canBold || canItalic || canStrike;
}

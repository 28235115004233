/** @jsxImportSource @emotion/react */

import type { PropsWithChildren } from "react";
import { useState } from "react";
import { ChevronRight } from "lucide-react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import "twin.macro";
import { AssigneesIcon, ComplianceBadgeIcon, StatusFlagIcon } from "utils/icons";
import AssigneesPopoverContent from "../assignees-popover-content";
import { xor } from "lodash";
import { useBulkUpdateOperation } from "./hooks";
import RequirementStatusPopoverContent from "../requirement-status-popover-content";
import { styles } from "components/molecules/dropdown-menu/styles";
import CompliancePopoverContent from "../compliance-popover-content/CompliancePopoverContent";

export type RequirementsMenubarPopoverProps = {
  contentProps?: DropdownMenu.DropdownMenuContentProps;
};

const RequirementAttributesMenu = ({ children, contentProps }: PropsWithChildren<RequirementsMenubarPopoverProps>) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { setComplianceStatus, setRequirementStatus, setAssignees } = useBulkUpdateOperation();

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.content} align="start" sideOffset={4} {...contentProps}>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger className="outline-none">
              <div className={styles.itemTrigger}>
                <div className="flex items-center gap-2">
                  <AssigneesIcon />
                  Assign to
                </div>
                <div className={styles.chevronRight}>
                  <ChevronRight size={14} />
                </div>
              </div>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent className={styles.subcontent} sideOffset={8}>
                <AssigneesPopoverContent
                  onUserSelect={(userId) => {
                    const newAssignees = xor(selectedUsers, [userId]);
                    setSelectedUsers(newAssignees);
                    setAssignees(newAssignees);
                  }}
                  onClearAll={() => {
                    setSelectedUsers([]);
                    setAssignees([]);
                  }}
                  selectedUsers={selectedUsers}
                />
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger className="outline-none">
              <div className={styles.itemTrigger}>
                <div className="flex items-center gap-2">
                  <StatusFlagIcon />
                  Status
                </div>
                <div className={styles.chevronRight}>
                  <ChevronRight size={14} />
                </div>
              </div>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent className={styles.subcontent} sideOffset={8}>
                <RequirementStatusPopoverContent
                  isolated
                  onStatusSelect={(newStatus) => {
                    setRequirementStatus(newStatus);
                  }}
                  tw="w-full text-sm"
                />
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger className="outline-none">
              <div className={styles.itemTrigger}>
                <div className="flex items-center gap-2">
                  <ComplianceBadgeIcon />
                  Compliance
                </div>
                <div className={styles.chevronRight}>
                  <ChevronRight size={14} />
                </div>
              </div>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent className={styles.subcontent} sideOffset={8}>
                <CompliancePopoverContent
                  isolated
                  onStatusSelect={(newStatus) => {
                    setComplianceStatus(newStatus);
                  }}
                  tw="w-full text-sm"
                />
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default RequirementAttributesMenu;

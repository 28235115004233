import { ArrowUp, Square } from "lucide-react";
import type { ButtonHTMLAttributes, ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { resetAbortController } from "store/reducers/sseControllerSlice";
import { setStreamingState } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAssistant } from "../AssistantContext";
import Tooltip from "components/atoms/tooltip";
import type { SendMessageVariables } from "../hooks";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  tooltipMessage?: ReactNode;
  defaultParams?: Partial<SendMessageVariables>;
}

const SubmitButton = ({ disabled = false, tooltipMessage, defaultParams, ...props }: Props) => {
  const { submitMessage } = useAssistant();
  const dispatch = useAppDispatch();
  const isStreamingInProgress = useAppSelector((root) => root.writingAssistant.streamState?.isStreamingInProgress);

  return (
    <Tooltip content={tooltipMessage} open disabled={!tooltipMessage}>
      <button
        className="bg-black justify-self-end text-sm flex items-center justify-center text-white w-[28px] min-w-[28px] h-[28px] min-h-[28px] duration-150 rounded-full disabled:bg-gray-200 disabled:text-slate-400 disabled:cursor-default hover:bg-slate-700"
        onClick={() => {
          if (isStreamingInProgress) {
            dispatch(resetAbortController());
            dispatch(setStreamingState({}));
          } else {
            submitMessage(defaultParams);
          }
        }}
        disabled={!isStreamingInProgress ? disabled : false}
        {...props}
      >
        {isStreamingInProgress ? <Square size={16} className="fill-current" /> : <ArrowUp size={18} />}
      </button>
    </Tooltip>
  );
};

export default SubmitButton;

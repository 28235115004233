import { WorkflowNameType, WorkflowSub } from "pages/ai-assistant/constants";

export const WORKFLOW_ACTIONS = [
  {
    text: "Search content library for organizational capabilities",
    name: WorkflowNameType.Search,
    subType: WorkflowSub.SearchCapability,
    label: "Search Capabilities",
  },
  {
    text: "Generate a solicitation report for relevance and pursuit strategy",
    name: WorkflowNameType.Report,
    subType: WorkflowSub.ReportSolicitation,
    label: "Full Solicitation Report",
  },
  {
    text: "Shred solicitation documents to build a compliance matrix",
    name: WorkflowNameType.Shred,
    subType: WorkflowSub.Shred,
    label: "Shred Documents",
  },
  {
    text: "Draft a response to solicitation requirements",
    name: WorkflowNameType.Draft,
    subType: WorkflowSub.Draft,
    label: "Draft Proposal Section",
  },
  {
    text: "Refine proposal content for clarity and completeness",
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.Revise,
    label: "Revise Content",
  },
] as const;

import VanillaSelectionArea from "@viselect/vanilla";
import type { SelectionEvents, SelectionOptions } from "@viselect/vanilla";
import type { PropsWithChildren } from "react";
import React, { useEffect, createContext, useContext, useRef, useState, useCallback } from "react";
import type { GroupedBlock } from "../types";
import classNames from "classnames";
import { useProxyRef } from "hook/useProxyRef";
import uniqBy from "lodash/uniqBy";
import isEqual from "lodash/isEqual";

export interface SelectionAreaProps extends Partial<SelectionOptions>, React.HTMLAttributes<HTMLDivElement> {
  id?: string;
  className?: string;
  isReadOnly: boolean;
  onBeforeStart?: SelectionEvents["beforestart"];
  onStart?: SelectionEvents["start"];
  onMove?: SelectionEvents["move"];
  onStop?: SelectionEvents["stop"];
}

const SelectionContext = createContext<{
  selectedBlocks?: GroupedBlock[];
  setSelectedBlocks?: React.Dispatch<React.SetStateAction<GroupedBlock[]>>;
  setSelection?: React.Dispatch<React.SetStateAction<VanillaSelectionArea | undefined>>;
  selectionState?: VanillaSelectionArea | undefined;
  clearSelection?: () => void;
  isDragging?: boolean;
  setIsDragging?: React.Dispatch<React.SetStateAction<boolean>>;
}>({});

export const useSelection = () => useContext(SelectionContext);

export const SelectionAreaListener: React.FunctionComponent<Omit<SelectionAreaProps, "boundaries">> = ({
  onBeforeStart,
  onStart,
  onMove,
  onStop,
  children,
  className,
  id,
  container,
  selectables,
  isReadOnly,
  ...props
}) => {
  const root = useRef<HTMLDivElement>(null);
  const { setSelection, selectionState, clearSelection, setIsDragging } = useSelection();

  useEffect(() => {
    const areaBoundaries = root.current as HTMLDivElement;

    const selection = new VanillaSelectionArea({
      boundaries: areaBoundaries,
      container,
      selectables,
      behaviour: {
        scrolling: {
          speedDivider: 4,
          startScrollMargins: {
            // 10% of the element's height, bound between 20 to 100px, use window as an approximation if areaBoundaries are not available
            y: Math.max(20, Math.min(100, (areaBoundaries?.clientHeight || window.innerHeight) * 0.1)),
          },
        },
      },
    });

    setSelection?.(selection);

    return () => {
      selection.destroy();
    };
  }, [container, selectables, setSelection]);

  useEffect(() => {
    selectionState?.on("beforestart", (evt) => onBeforeStart?.(evt));
    selectionState?.on("start", (evt) => {
      setIsDragging?.(true);
      onStart?.(evt);
    });
    selectionState?.on("move", (evt) => onMove?.(evt));
    selectionState?.on("stop", (evt) => {
      setIsDragging?.(false);
      onStop?.(evt);
    });

    return () => {
      selectionState?.unbindAllListeners();
    };
  }, [onBeforeStart, onMove, onStart, onStop, selectionState, setIsDragging]);

  useEffect(() => {
    if (isReadOnly) {
      selectionState?.destroy();
      clearSelection?.();
    }
  }, [isReadOnly, selectionState, clearSelection]);

  return (
    <div ref={root} className={classNames("selection:bg-transparent", className)} id={id} {...props}>
      {children}
    </div>
  );
};

export const SelectionAreaProvider = ({ children }: PropsWithChildren<{}>) => {
  const [selectedBlocks, setSelectedBlocks] = useState<GroupedBlock[]>([]);
  const [selectionState, setSelection] = useState<VanillaSelectionArea | undefined>();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const selectionStateRef = useProxyRef(selectionState);

  const clearSelection = useCallback(() => {
    setSelectedBlocks?.([]);
    selectionStateRef?.current?.clearSelection();
  }, []);

  const setSelectedBlocksWrapper = useCallback((update: React.SetStateAction<GroupedBlock[]>) => {
    setSelectedBlocks((prev) => {
      if (typeof update === "function") {
        const newBlocks = uniqBy(update(prev), "id");
        const isEqualValue = isEqual(newBlocks, prev);
        if (isEqualValue) return prev;

        selectionStateRef?.current?.clearSelection();
        selectionStateRef?.current?.select(newBlocks.map(({ id }) => `[data-element='${id}']`));

        return newBlocks;
      }

      const isEqualValue = isEqual(update, prev);
      if (isEqualValue) return prev;

      selectionStateRef?.current?.clearSelection();
      selectionStateRef?.current?.select(update.map(({ id }) => `[data-element='${id}']`));

      return update;
    });
  }, []);

  return (
    <SelectionContext.Provider
      value={{
        selectedBlocks,
        selectionState,
        setSelectedBlocks: setSelectedBlocksWrapper,
        setSelection,
        clearSelection,
        isDragging,
        setIsDragging,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

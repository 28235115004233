import * as Sentry from "@sentry/react";

export default function tryParseJSON<T>(value: string | null | undefined, defaultValue?: T): T | undefined {
  if (!value) return defaultValue;

  try {
    return JSON.parse(value) as T;
  } catch {
    try {
      const parsedArray = parseMultipleJSON(value, defaultValue);
      return parsedArray as unknown as T;
    } catch {
      return defaultValue;
    }
  }
}

export function parseMultipleJSON<T>(input: string, defaultValue?: T) {
  const results = [];
  let braceCount = 0;
  let startIndex = -1;

  for (let i = 0; i < input.length; i++) {
    const char = input[i];
    if (char === "{") {
      if (braceCount === 0) {
        startIndex = i;
      }
      braceCount++;
    } else if (char === "}") {
      braceCount--;
      if (braceCount === 0 && startIndex !== -1) {
        const jsonSubstring = input.substring(startIndex, i + 1);
        try {
          const parsed = JSON.parse(jsonSubstring);
          results.push(parsed);
        } catch (error) {
          console.error("Failed to parse JSON substring:", jsonSubstring, error);
          Sentry.captureException(`Failed to parse JSON substring:", ${error}`);
        }
        startIndex = -1;
      }
    }
  }

  return results.length > 0 ? results : defaultValue;
}

import type { ComponentProps } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import RequirementItem from "./RequirementItem";

const SortableItem = (props: ComponentProps<typeof RequirementItem>) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.row.requirement.id || "",
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };

  return (
    <RequirementItem
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      {...props}
      dragProps={{ attributes, listeners }}
    />
  );
};

export default SortableItem;

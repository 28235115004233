import { Button } from "components/editor/components";
import FileUploadLoader from "./FileUploadLoader";
import { Upload } from "lucide-react";
import type { InternalDocument } from "types/Project";

type Props = {
  isCompleted: boolean;
  isUploadingFiles: boolean;
  activeProjectFile: InternalDocument | null;
  inputFileNode: HTMLInputElement | null;
};

const EmptyStateContainer = ({ isCompleted, isUploadingFiles, activeProjectFile, inputFileNode }: Props) => {
  return (
    <div className="flex px-6 justify-center items-center w-full h-[calc(100%-32px)]">
      <div className="w-full max-w-[550px] p-4 py-6 flex justify-center items-center rounded-md shadow-lg bg-gray-200">
        {(isUploadingFiles || isCompleted) && !activeProjectFile ? (
          <FileUploadLoader isCompleted={isCompleted} isUploadingFiles={isUploadingFiles} />
        ) : (
          <div className="gap-y-2 flex flex-col justify-center items-center text-center">
            <div className="text-gray-700 font-medium">Upload a document to get started</div>
            <Button onClick={() => inputFileNode?.click()} variant="ghost" size="md">
              <div className="flex items-center gap-2 text-gray-500">
                <Upload size={18} className="shrink-0" />
                <span>Upload file</span>
              </div>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyStateContainer;

/** @jsxImportSource @emotion/react */

import { Search, X } from "lucide-react";
import { memo, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import {
  getFilteredRequirements,
  setFilteredRequirements,
  setHighlightedElementId,
  setRequirementFilters,
} from "store/reducers/extract/CurrentExtractionReducer";
import { useSearchParams } from "react-router-dom";
import { handleKeyboardSearch, highlightAndScrollToElement } from "../utils";
import DocumentSearchStepper from "./DocumentSearchStepper";
import DocumentSearchAskAiButton from "./DocumentSearchAskAiButton";
import DocumentSearchInput from "./DocumentSearchInput";
import DocumentSearchClearButton from "./DocumentSearchClearButton";
import { DEFAULT_FILTERS } from "../document-sidebar/requirements-filter/constants";

const DocumentSearch = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const [userIsSearching, setUserIsSearching] = useState(false);

  const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);
  const analysisId = useAppSelector((store) => store.currentExtractionState.currentExtraction?.id);
  const focusedSearchIndex = useAppSelector((store) => store.currentExtractionState.focusedSearchIndex);
  const groupedFilteredRequirementsByDocument = useAppSelector(
    (store) => store.currentExtractionState.groupedFilteredRequirementsByDocument,
  );
  const isLoadingFilteredRequirements = useAppSelector(
    (store) => store.currentExtractionState.isLoadingFilteredRequirements,
  );
  const searchQuery = useAppSelector((store) => store.currentExtractionState.searchQuery);

  const filteredRequirementsInActiveDocument = useMemo(() => {
    if (!activeDocument?.id) return [];
    return groupedFilteredRequirementsByDocument[activeDocument.id] || [];
  }, [activeDocument?.id, groupedFilteredRequirementsByDocument]);
  const projectId = searchParams.get("id")?.toLocaleLowerCase();
  const queryHasNoResults =
    filteredRequirementsInActiveDocument.length === 0 && searchQuery.length > 0 && !isLoadingFilteredRequirements;

  const cleanUpFilteredRequirements = () => {
    dispatch(setRequirementFilters(DEFAULT_FILTERS));
    dispatch(setFilteredRequirements([]));
    dispatch(setHighlightedElementId(""));
  };

  useEffect(() => {
    const interceptKeyboardSearch = (event: KeyboardEvent) => {
      handleKeyboardSearch(event, setUserIsSearching);
    };
    window.addEventListener("keydown", interceptKeyboardSearch);

    return () => {
      window.removeEventListener("keydown", interceptKeyboardSearch);
    };
  }, []);

  useEffect(() => {
    if (projectId && searchQuery) {
      dispatch(setRequirementFilters({ query: searchQuery, sections: [] }));
      dispatch(
        getFilteredRequirements({
          projectId,
          params: {
            keyword: [searchQuery],
          },
        }),
      );
    } else if (projectId && !searchQuery) {
      cleanUpFilteredRequirements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisId, projectId, searchQuery]);

  useEffect(() => {
    if (filteredRequirementsInActiveDocument) {
      const elementId = filteredRequirementsInActiveDocument[focusedSearchIndex]?.element_id;
      if (elementId) {
        highlightAndScrollToElement(elementId);
      }
      const blockNodes = document.querySelectorAll(`[data-element='${elementId}']`);
      blockNodes[0]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [filteredRequirementsInActiveDocument, focusedSearchIndex]);

  return (
    <>
      {userIsSearching ? (
        <div className="relative flex items-center text-xs">
          <DocumentSearchInput />
          <div className="absolute right-8 top-1/2 transform -translate-y-1/2 flex items-center text-gray-500 space-x-2">
            {queryHasNoResults && (
              <>
                <span>Not Found</span>
                <DocumentSearchAskAiButton setUserIsSearching={setUserIsSearching} />
              </>
            )}
            {filteredRequirementsInActiveDocument.length > 0 && !isLoadingFilteredRequirements && !!searchQuery && (
              <div className="flex items-center justify-center">
                {`${focusedSearchIndex + 1} of ${filteredRequirementsInActiveDocument.length}`}
                <DocumentSearchStepper filteredRequirementsInActiveDocument={filteredRequirementsInActiveDocument} />
              </div>
            )}
          </div>
          <DocumentSearchClearButton
            setUserIsSearching={setUserIsSearching}
            cleanUpFilteredRequirements={cleanUpFilteredRequirements}
          />
        </div>
      ) : (
        <button
          className="shadow backdrop-blur-lg rounded-md p-3 flex items-center bg-gray-300/60"
          onClick={() => setUserIsSearching(true)}
        >
          <Search size={16} />
        </button>
      )}
    </>
  );
};

export default memo(DocumentSearch);

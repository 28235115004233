/** @jsxImportSource @emotion/react */

import type { ComplianceMatrixRow as ImmutableComplianceMatrixRow } from "../CopilotSchemaImmutableTypes";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TabSlug } from "./types";
import type { FetchEventSourceInit } from "@microsoft/fetch-event-source";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useLocalStorage } from "hook/useLocalStorage";
import { getTemplateDetails } from "api/api";
import type { TemplateDetails } from "types/Templates";
import { useNotification } from "context/notificationContext";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { fetchTemplates } from "store/reducers/templates/templatesReducer";
import { useDebounce } from "react-use";
import type { ComplianceMatrixRow, SourceType, Storage, Template } from "../CopilotSchemaTypes";
import { createComplianceMatrixRow } from "utils/complianceMatrix";
import useRequirementOperations from "hook/useRequirementOperations";
import { LiveObject } from "YJSProvider/LiveObjects";
import { useMutation } from "YJSProvider/createYJSContext";
import { apiUrl } from "config/vultronConfig";

export const useTabFilter = (activeTab: TabSlug, sheetRequirements: ImmutableComplianceMatrixRow[]) => {
  const results = useMemo(
    () =>
      sheetRequirements.filter((row) => {
        if (activeTab === TabSlug.Assigned) {
          return !row?.requirement?.disregarded && !!row.proposal_reference.section_id;
        } else if (activeTab === TabSlug.Unassigned) {
          return !row?.requirement?.disregarded && !row.proposal_reference.section_id;
        } else if (activeTab === TabSlug.Disregarded) {
          return !!row?.requirement?.disregarded;
        }

        return row;
      }),
    [activeTab, sheetRequirements],
  );

  return { results };
};

export const useLock = () => {
  const lock = useMutation(({ storage }, volumeId: string, sectionId: string, locked: boolean) => {
    const volumes: any = storage.get("framework")?.get("volumes");
    volumes?.some((vol: any) => {
      if (vol.get("id") === volumeId) {
        const foundSection = vol.get("sections")?.find((sec: any) => sec.get("id") === sectionId);
        foundSection?.set("locked", locked);
        return true;
      }

      return false;
    });
  }, []);

  return lock;
};

export const useCreateAndAssignRequirement = () => {
  const { assignToSection } = useRequirementOperations();

  return useMutation(
    (
      { storage },
      selectedSectionId: string,
      onCreate?: (liveRow: string) => void,
      partialRowProperties?: Partial<ComplianceMatrixRow>,
    ) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];

      const newRow = createComplianceMatrixRow(partialRowProperties);
      complianceMatrix.push([newRow]);

      // Before we can access newRow it must be added to the yDoc (which is why complianceMatrix is pushing before this line)
      const newRowId = newRow.get("requirement").get("id");
      onCreate?.(newRowId);

      assignToSection(newRow.get("requirement")?.get("id"), selectedSectionId);
    },
    [],
  );
};

export const useTemplates = () => {
  const dispatch = useAppDispatch();
  const templates = useAppSelector((root) => root.templates.templates);
  const { setToast } = useNotification();

  const getTemplates = useCallback(async () => {
    try {
      await dispatch(fetchTemplates());
    } catch {
      setToast.error({
        title: "Unable to retrieve templates",
        msg: "We were unable to retrieve templates due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { templates, getTemplates };
};

export const useTemplateDetails = (id?: string) => {
  const [templateDetails, setTemplateDetails] = useState<TemplateDetails>();

  const fetchTemplateDetails = useCallback((id?: string) => {
    if (!id) return;
    getTemplateDetails(id)
      .then((resp) => setTemplateDetails(resp.data))
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      fetchTemplateDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { templateDetails, fetchTemplateDetails };
};

export const useSearchRequirements = (searchTerm: string, sheetRequirements: ImmutableComplianceMatrixRow[]) => {
  const [searchResults, setSearchResults] = useState<ImmutableComplianceMatrixRow[]>(sheetRequirements);

  const getSearchResults = useMemo(() => {
    const filteredResults = sheetRequirements.filter((row) =>
      (row.requirement.content || row.requirement.summarized_content)?.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    return filteredResults;
  }, [searchTerm, sheetRequirements]);

  useDebounce(
    () => {
      if (searchTerm.trim()) setSearchResults(getSearchResults);
    },
    300,
    [searchTerm, getSearchResults],
  );

  return { searchResults: !searchTerm.trim() ? sheetRequirements : searchResults };
};

export const useUpdateTemplate = () =>
  useMutation(({ storage }, templateProperties: Partial<Template>) => {
    (storage.get("framework") as Storage["framework"])?.set(
      "template",
      new LiveObject({
        id: (storage.get("framework") as Storage["framework"])?.get("template")?.get("id"),
        ...templateProperties,
      }),
    );
  }, []);

type GenerateRecommendedAPIContentVariables = {
  user_query: string;
  project_id: string;
  source_types: SourceType[];
  file_id_filters: string[];
  past_ideas: string[];
};

export const useGenerateRecommendedAPIContent = (options?: FetchEventSourceInit) => {
  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0", "");
  const useAuth0Header = use_auth0 === true;
  const controllerRef = useRef(new AbortController());
  const { current: controller } = controllerRef;

  const generateRecommendedContent = (body: GenerateRecommendedAPIContentVariables) => {
    fetchEventSource(`${apiUrl}/requirements/generate/writing/ideas/v2/stream`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Workspace: `Workspace ${workspace_id}`,
        Authorization: `Bearer ${localValue}`,
        "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
        Accept: "application/json",
      },
      body: JSON.stringify(body),
      signal: controller.signal,
      openWhenHidden: true,
      ...options,
    });
  };

  const abortConnection = useCallback(() => {
    controllerRef.current.abort();
    controllerRef.current = new AbortController();
  }, []);

  return { generateRecommendedContent, abortConnection };
};

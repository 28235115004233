import { unwrapResult } from "@reduxjs/toolkit";
import { createProjectAttachment } from "api/api";
import { useNotification } from "context/notificationContext";
import { useCallback, useState } from "react";
import { getProject } from "store/reducers/projectReducer";
import { useAppDispatch } from "store/storeTypes";
import { pluralizeWord } from "utils/string";

const INITIAL_UPLOAD_STATE = { isLoading: false, isCompleted: false, isError: false };

const useUploadProjectFiles = (internalContractId: string) => {
  const [uploadState, setUploadingState] = useState(INITIAL_UPLOAD_STATE);
  const dispatch = useAppDispatch();
  const { setToast } = useNotification();

  const uploadProjectFiles = useCallback(
    async (files: FileList | File[]) => {
      let uploadedFile;
      if (uploadState.isLoading) return;
      setUploadingState((prev) => ({ ...prev, isCompleted: false, isLoading: true, isError: false }));

      try {
        const [firstResult] = await Promise.allSettled(
          Array.from(files).map((file) => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("internal_upload_type", "0");

            return createProjectAttachment(internalContractId, formData);
          }),
        );
        const status = firstResult.status;

        const response = await dispatch(getProject({ internalContractId, triggerDocRefresh: true }));
        const respons = unwrapResult(response);

        if (status === "fulfilled") {
          uploadedFile = respons.project.internal_documents.find(({ id }) => id === firstResult.value.data.document_id);
        }
        setTimeout(() => {
          setToast.success({
            msg: `Uploaded ${files.length} ${pluralizeWord(files.length, "document")}`,
          });
        }, 500);
        setUploadingState((prev) => ({ ...prev, isLoading: false, isCompleted: true }));
      } catch {
        setToast.error({
          title: `Unable to upload ${pluralizeWord(files.length, "document")}`,
          msg: `We were unable to upload the ${pluralizeWord(
            files.length,
            "document",
          )} due to a technical issue on our end. Please try uploading again. If the issue persists, contact support@vultron.ai for assistance.`,
        });
        setUploadingState((prev) => ({ ...prev, isLoading: false, isError: true }));
      } finally {
        setTimeout(() => setUploadingState(INITIAL_UPLOAD_STATE), 2000);
      }

      return uploadedFile;
    },
    [dispatch, internalContractId, setToast, uploadState.isLoading],
  );

  return { uploadProjectFiles, ...uploadState };
};

export default useUploadProjectFiles;

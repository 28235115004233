import type { Dispatch, MutableRefObject, SetStateAction } from "react";
import { useCallback, useEffect, useRef } from "react";
import type { DocumentProps } from "react-pdf";

export const useObservePages = ({
  pageList,
  pageRefs,
  setCurrentPage,
  file,
}: {
  pageList: number[];
  pageRefs?: MutableRefObject<(HTMLDivElement | null)[]>;
  setCurrentPage?: Dispatch<SetStateAction<number | null>>;
  file?: DocumentProps["file"];
}) => {
  const isFirstPageInitiallyRendered = useRef<boolean>(false);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && pageRefs) {
          const pageIndex = pageRefs.current.indexOf(entry.target as HTMLDivElement);
          if (pageIndex !== -1 && isFirstPageInitiallyRendered.current) {
            setCurrentPage?.(pageIndex + 1);
          }
        }
      });
    },
    [setCurrentPage, pageRefs],
  );

  useEffect(() => {
    if (!setCurrentPage || !pageRefs?.current) return;
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "-50% 0% -50% 0%",
      threshold: 0,
    });

    pageRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, [pageList, handleObserver, setCurrentPage, pageRefs]);

  useEffect(() => {
    isFirstPageInitiallyRendered.current = false;
  }, [file]);

  return isFirstPageInitiallyRendered;
};

import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { useMemo } from "react";
import { useNotification } from "context/notificationContext";
import { useSearchParams } from "react-router-dom";
import { Link, Pencil, Trash2 } from "lucide-react";
import copyText from "utils/copyText";
import useExtractionOperations from "hook/useExtractionOperations";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { Extraction } from "components/copilot/CopilotSchemaTypes";
import { ExtractionStatus } from "components/copilot/CopilotSchemaTypes";

export const useExtractionDropdownItems = (
  extraction: ToImmutable<Extraction>,
  setIsEditingExtractionName: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { deleteExtraction } = useExtractionOperations();
  const { setToast } = useNotification();
  const [searchParams] = useSearchParams();
  const { name: extractionName, id: extractionId, status } = extraction;
  const link = `${
    window.location.origin
  }/dashboard/contracts/details/extractions/${extractionId}?${searchParams.toString()}`;
  const isExtracting = status === ExtractionStatus.Extracting;

  const items = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2 text-xs">
            <Pencil size={16} /> Edit Name
          </div>
        ),
        onSelect: () => {
          setIsEditingExtractionName(true);
        },
      },
      ...(!!extraction.status && extraction.status !== ExtractionStatus.Failed
        ? [
            {
              key: 2,
              label: (
                <div className="flex gap-2 text-xs items-center">
                  <Link size={16} /> Copy Link
                </div>
              ),
              onSelect: () =>
                copyText(link, () => {
                  setToast.success({
                    msg: "Copied link to generation",
                  });
                }),
            },
          ]
        : []),
      {
        key: 3,
        disabled: isExtracting,
        label: (
          <div className="flex gap-2 text-xs items-center text-red-500">
            <Trash2 size={16} /> Delete
          </div>
        ),
        onSelect: () => {
          if (isExtracting) return;

          triggerConfirm({
            proceedLabel: "Delete",
            header: `Are you sure you want to delete ${extractionName}?`,
            body: (
              <div>
                This action is irreversible and will delete all progress associated with the generation.
                <br />
                <br />
                Please confirm this action.
              </div>
            ),
          }).then((proceed) => {
            if (proceed) {
              deleteExtraction(extractionId);
            }
          });
        },
      },
    ],
    [
      deleteExtraction,
      extraction.status,
      extractionId,
      extractionName,
      isExtracting,
      link,
      setIsEditingExtractionName,
      setToast,
    ],
  );

  return { items };
};

/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Popover from "components/atoms/popover/Popover";
import ContentDrivePopoverContent from "components/molecules/content-drive-popover-content";
import { insertAssistantFiles, removeAssistantFiles } from "store/reducers/ai-assistant/aiAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import type { BaseFile } from "types/FileStorage";
import { transformToFileMention } from "components/copilot/writing-assistant-drawer/writing-assistant-input/utils";
import { useFlags } from "hook/useFlags";
import { ChevronDown, ListFilter } from "lucide-react";
import type { ReactNode } from "react";
import { getAttachLabel, getFolderFiles } from "utils/assistants/utils";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import { useNestedDriveMeta } from "components/molecules/content-drive-popover-content/hooks";
import Tooltip from "components/atoms/tooltip";

interface ContentLibraryPopoverProps {
  customButton?: ReactNode;
  hideBackground?: boolean;
}

const ContentLibraryPopover: React.FC<ContentLibraryPopoverProps> = ({ customButton, hideBackground }) => {
  const flags = useFlags();
  const [sourceOpen, setSourceOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { enableInternet, selectedFiles } = useAppSelector((state) => state.aiAssistantState);
  const selectedFileIds = useMemo(() => selectedFiles.map(({ id }) => id), [selectedFiles]);
  const activeSession = useAppSelector((state) => state.aiAssistantState.activeSession);

  const { docs, folders } = useNestedDriveMeta();

  const indexedDocs = useMemo(() => docs?.filter((doc) => doc.indexed) ?? [], [docs]);

  const allFiles = useMemo(() => [...indexedDocs, ...getFolderFiles(folders)], [folders, indexedDocs]);

  const handleSelectedDriveFiles = useCallback(
    (checked: boolean, files: BaseFile[]) => {
      if (checked) {
        dispatch(insertAssistantFiles(transformToFileMention(files)));
      } else {
        dispatch(removeAssistantFiles(files.map(({ id }) => id)));
      }
    },
    [dispatch],
  );

  const handleClearFiles = useCallback(
    (files: BaseFile[]) => {
      dispatch(removeAssistantFiles(files.map(({ id }) => id)));
    },
    [dispatch],
  );

  const hasAutoSelected = useRef(false);

  useEffect(() => {
    hasAutoSelected.current = false;
  }, [activeSession?.id]);

  useEffect(() => {
    if (selectedFiles.length === 0 && !hasAutoSelected.current && !activeSession?.workflow) {
      handleSelectedDriveFiles(true, allFiles);
      hasAutoSelected.current = true;
    }
  }, [activeSession?.workflow, allFiles, selectedFiles]);

  const isDisabled = enableInternet || activeSession?.input_field_type === INPUT_FIELD_OPTIONS.pendingWorkflow;

  return (
    <div
      ref={ref}
      className="flex items-center gap-1.5"
      css={[flags.documentAttachments && !hideBackground ? tw`bg-gray-100` : tw`bg-white`]}
    >
      <Popover
        content={
          <ContentDrivePopoverContent
            handleSelectedFiles={handleSelectedDriveFiles}
            onClear={handleClearFiles}
            selectedFiles={selectedFileIds}
          />
        }
        open={sourceOpen}
        onOpenChange={setSourceOpen}
        searchProps={{
          value: query,
          onChange: (e) => setQuery(e.target.value),
        }}
        contentProps={{
          align: "start",
          side: "top",
          css: [tw`backdrop-blur-sm ml-0 bg-[rgba(255,255,255,0.95)]`],
        }}
        portalProps={{ container: ref.current }}
      >
        {customButton ? (
          customButton
        ) : (
          <div>
            <button
              className="p-1 rounded-md text-gray-600 duration-100 disabled:text-gray-400"
              disabled={isDisabled}
              css={[!isDisabled && tw`hover:bg-gray-200 hover:text-black`]}
            >
              <Tooltip
                content="Internet is toggled on, so content library files are unavailable and will not be used to generate a response. Toggle it off to attach files."
                disabled={!enableInternet}
              >
                <div className="flex items-center justify-center gap-1">
                  <ListFilter size={14} />
                  <div className="text-sm">{getAttachLabel(selectedFiles, "Library")}</div>
                  {selectedFiles.length > 0 && <ChevronDown size={14} />}
                </div>
              </Tooltip>
            </button>
          </div>
        )}
      </Popover>
    </div>
  );
};

export default ContentLibraryPopover;

/** @jsxImportSource @emotion/react */
import classNames from "classnames";
import type {
  ReactNode,
  KeyboardEventHandler,
  PointerEventHandler,
  TouchEventHandler,
  FocusEventHandler,
  MouseEventHandler,
  HTMLProps,
} from "react";
import { forwardRef } from "react";
import styles from "./Button.module.css";
import type { TwStyle } from "twin.macro";
import tw from "twin.macro";

type Size = "sm" | "sm/md" | "md";
type Variant = "link" | "ghost" | "secondary" | "primary" | "outline" | "primaryBlack";

interface Props extends Omit<HTMLProps<HTMLButtonElement>, "size"> {
  children: ReactNode;
  variant: Variant;
  isSquare?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
  onPointerEnter?: PointerEventHandler<HTMLButtonElement>;
  onPointerLeave?: PointerEventHandler<HTMLButtonElement>;
  onPointerDown?: PointerEventHandler<HTMLButtonElement>;
  onFocus?: FocusEventHandler<HTMLButtonElement>;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  onTouchStart?: TouchEventHandler<HTMLButtonElement>;
  ariaLabel?: string;
  type?: "submit" | "reset" | "button";
  className?: string;
  size?: Size;
  loading?: boolean;
}

const sizeStyles: Record<Size, TwStyle> = {
  sm: tw`h-7 min-h-[28px] px-4 py-[4px] text-xs font-medium rounded-md`,
  "sm/md": tw`h-[34px] min-h-[34px] px-4 py-[4px] text-xs font-medium rounded-md`,
  md: tw`h-10 min-h-[40px] px-4 py-[9px] text-sm font-medium rounded-md`,
};

const variantStyles: Partial<Record<Variant, TwStyle>> = {
  link: tw`text-action duration-100 font-medium h-auto p-0 hover:text-action-hover disabled:!text-gray-disabled`,
  primary: tw`bg-action font-medium text-white duration-150 hover:bg-action-hover disabled:!bg-gray-200 disabled:!text-slate-400`,
  outline: tw`bg-white border border-solid border-action font-medium text-action duration-150 hover:not-disabled:bg-action-light disabled:!border-slate-400 disabled:!text-slate-400`,
  primaryBlack: tw`bg-gray-darkest text-white duration-150 hover:bg-black/80 disabled:!bg-gray-200 disabled:!text-slate-400`,
};

const loaderStyles: Partial<Record<Variant, TwStyle>> = {
  primary: tw`border-t-white border-action-hover`,
  outline: tw`border-t-action border-white`,
};

const loaderSizeStyles: Partial<Record<Size, TwStyle>> = {
  sm: tw`!w-[14px] !h-[14px] !min-w-[14px] !min-h-[14px] border-2`,
  md: tw`!w-5 !h-5 !min-w-[20px] !min-h-[20px]`,
};

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      variant,
      isSquare,
      onClick,
      onKeyDown,
      onPointerEnter,
      onPointerLeave,
      onPointerDown,
      onFocus,
      onBlur,
      onTouchStart,
      ariaLabel,
      type,
      className,
      size,
      loading,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={classNames(
          styles.button,
          {
            [styles.button_ghost]: variant === "ghost",
            [styles.button_secondary]: variant === "secondary",
            [styles.button_square]: isSquare,
          },
          className,
        )}
        css={[size && sizeStyles[size], variantStyles[variant]]}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        onFocus={onFocus}
        onBlur={onBlur}
        onPointerDown={onPointerDown}
        onTouchStart={onTouchStart}
        aria-label={ariaLabel}
        type={type}
        {...props}
      >
        {loading && (
          <div className="loading-wrapper sm_spinner">
            <div className="loader-wrap !border-0 mr-2" css={[size && loaderSizeStyles[size], !children && tw`mr-0`]}>
              <div
                className="border-[3px] border-solid rounded-full origin-center w-5 h-5"
                css={[
                  loaderStyles[variant],
                  { animation: "spin 0.5s linear infinite;" },
                  size && loaderSizeStyles[size],
                  props.disabled && tw`border-gray-100 border-t-slate-400`,
                ]}
              />
            </div>
          </div>
        )}
        {children}
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { CommentThread } from "components/Comments/types";
import { CommentsStatusFilter, ThreadContext } from "components/Comments/types";
import { Thread } from "components/Comments/components/Thread";
import { useComments } from "api/comments/useComments";
import { findMarkInRange } from "components/Comments/utils";
import type { Editor } from "@tiptap/react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { useActiveThread } from "components/Comments/components/useThread";
import { useDraft } from "components/Comments/components/useDraft";
import { CommentDraft } from "components/Comments/components/CommentDraft";
import ClickAwayListener from "helpers/ClickAwayListener";
import { clearDraft } from "components/Comments/CommentsDraftPlugin";
import { debounce } from "lodash";
import type { Mark } from "@tiptap/pm/model";

interface ComputedPosition {
  thread?: CommentThread;
  isDraft?: boolean;
  desired: number; // Desired top edge computed from the editor marker (may be negative)
  height: number; // Measured height of the thread component
  final: number; // Final computed top edge after stacking
}

const COMMENT_SPACING = 12;
const DEBOUNCE_WAIT = 300;
const VIEWPORT_BUFFER = 800; // px
const INITIAL_VIEWPORT_HEIGHT = 2000; // Initial render height

export const DocumentComments = ({
  editor,
  editorContainerRef,
  scrollContainerRef,
}: {
  editor?: Editor | null;
  editorContainerRef: React.RefObject<HTMLDivElement>;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}) => {
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLowerCase();
  const referenceId = searchParams.get("docId")?.toLowerCase();
  const commentsOpen = useAppSelector((state) => state.commentsDrawer.commentsDrawerOpen);
  const activeThread = useActiveThread(editor);
  const { data: threads } = useComments(internalContractId || "", referenceId);
  const draft = useDraft(editor || null);
  const [initialThreadId] = useState(searchParams.get("threadId"));
  const [viewport, setViewport] = useState({
    top: 0,
    bottom: INITIAL_VIEWPORT_HEIGHT,
  });

  // Ref for the container.
  const containerRef = useRef<HTMLDivElement>(null);

  // keep measured thread heights in state for stability.
  const [threadHeights, setThreadHeights] = useState<{ [id: string]: number }>({});
  const [forceUpdate, setForceUpdate] = useState(0);

  // Update measured height when a thread's DOM node mounts/updates.
  const handleRef = (threadId: string) => (el: HTMLDivElement | null) => {
    if (el) {
      const newHeight = el.offsetHeight;
      setThreadHeights((prev) => {
        if (prev[threadId] !== newHeight) {
          return { ...prev, [threadId]: newHeight };
        }
        return prev;
      });
    }
  };

  // Debounced update function
  const debouncedUpdate = useMemo(
    () =>
      debounce(() => {
        setForceUpdate((prev) => prev + 1);
      }, DEBOUNCE_WAIT),
    [],
  );

  // immediate update function
  const immediateUpdate = useCallback(() => {
    setForceUpdate((prev) => prev + 1);
  }, []);

  // Set up editor update listeners
  useEffect(() => {
    if (!editor) return;

    const handleUpdate = () => {
      debouncedUpdate();
    };

    editor.on("update", handleUpdate);
    return () => {
      editor.off("update", handleUpdate);
      debouncedUpdate.cancel();
    };
  }, [editor, debouncedUpdate]);

  const computedPositions = useMemo((): ComputedPosition[] => {
    if (!editor || !threads || commentsOpen) return [];

    const raw: ComputedPosition[] = [];
    // Iterate over each thread from the query.
    threads.forEach((thread, index) => {
      if (thread.resolved) return; // skip resolved threads
      // Try to find a mark for this thread.
      const markResult = findMarkInRange(
        editor.state.doc,
        0,
        editor.state.doc.nodeSize - 2,
        (mark: Mark) => mark.type.name === "comment" && mark.attrs.id === thread.id,
      );
      if (markResult) {
        const { pos } = markResult;
        const domResult = editor.view.domAtPos(pos);
        if (domResult.node instanceof HTMLElement) {
          const rect = domResult.node.getBoundingClientRect();
          const editorRect = editor.view.dom.getBoundingClientRect();
          const scrollTop = editor.view.dom.parentElement?.scrollTop || 0;
          const desired = rect.top - editorRect.top + scrollTop;
          const height = threadHeights[thread.id] || 200;
          raw.push({ thread, desired, height, final: desired, isDraft: false });
        } else {
          // Fallback if node is not HTMLElement.
          raw.push({ thread, desired: 0, height: 200, final: 0, isDraft: false });
        }
      } else {
        // No mark found: fallback to a default position based on index.
        const desired = index * (200 + COMMENT_SPACING);
        raw.push({ thread, desired, height: 200, final: desired, isDraft: false });
      }
    });

    // Add draft if it exists
    if (draft?.domNode instanceof HTMLElement) {
      const { from } = editor.state.selection;
      const domResult = editor.view.domAtPos(from);
      if (domResult.node instanceof HTMLElement) {
        const rect = domResult.node.getBoundingClientRect();
        const editorRect = editor.view.dom.getBoundingClientRect();
        const scrollTop = editor.view.dom.parentElement?.scrollTop || 0;
        const height = threadHeights["draft"] || 0;
        raw.push({
          isDraft: true,
          desired: rect.top - editorRect.top + scrollTop,
          height,
          final: rect.top - editorRect.top + scrollTop,
        });
      }
    }

    // Sort and calculate positions
    raw.sort((a, b) => a.desired - b.desired);

    raw.forEach((pos, i) => {
      if (i === 0) {
        pos.final = pos.desired;
      } else {
        const prev = raw[i - 1];
        const required = prev.final + prev.height + COMMENT_SPACING;
        pos.final = Math.max(pos.desired, required);
      }
    });

    // Adjust positions for active thread or draft
    const activePosition = draft ? raw.find((p) => p.isDraft) : raw.find((p) => p.thread?.id === activeThread);
    if (activePosition) {
      const delta = activePosition.final - activePosition.desired;
      raw.forEach((pos) => {
        pos.final = pos.final - delta;
      });
    }

    return raw;
  }, [editor?.state.doc, threads, commentsOpen, activeThread, threadHeights, draft, forceUpdate]);

  // Initialize viewport based on scroll container
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    setViewport({
      top: scrollContainer.scrollTop - VIEWPORT_BUFFER,
      bottom: scrollContainer.scrollTop + scrollContainer.clientHeight + VIEWPORT_BUFFER,
    });
  }, [scrollContainerRef.current]);

  // scroll listener to the container
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const handleScroll = () => {
      setViewport({
        top: scrollContainer.scrollTop - VIEWPORT_BUFFER,
        bottom: scrollContainer.scrollTop + scrollContainer.clientHeight + VIEWPORT_BUFFER,
      });
    };

    scrollContainer.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [scrollContainerRef.current]);

  const visiblePositions = useMemo(() => {
    if (!computedPositions.length) return [];

    // filter positions based on viewport and active state
    return computedPositions.filter((position) => {
      // Always include active thread or draft comments
      if (position.thread?.id === activeThread || position.isDraft) {
        return true;
      }

      // For other comments, only include if they're within the viewport (with buffer)
      const posTop = position.final;
      const posBottom = position.final + position.height;

      // Include if any part of the comment is in the viewport
      return posTop <= viewport.bottom && posBottom >= viewport.top;
    });
  }, [computedPositions, activeThread, viewport.top, viewport.bottom]);

  const draftContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!draftContainerRef.current || !draft) return;
    // Create a ResizeObserver to watch for changes in the draft container's size.
    const observer = new ResizeObserver(() => {
      if (draftContainerRef.current) {
        const newHeight = draftContainerRef.current.offsetHeight;
        setThreadHeights((prev) => {
          if (prev["draft"] !== newHeight) {
            return { ...prev, draft: newHeight };
          }
          return prev;
        });
        immediateUpdate();
      }
    });
    observer.observe(draftContainerRef.current);
    return () => {
      observer.disconnect();
    };
  }, [draft, immediateUpdate]);

  // Scroll to the initial thread (pasting links)
  useEffect(() => {
    if (editor && containerRef.current && initialThreadId) {
      editor.commands.setActiveComment(initialThreadId);
    }
  }, [editor, initialThreadId]);

  if (commentsOpen || !editor || !internalContractId) return null;

  return (
    <div
      ref={containerRef}
      className="sticky top-8 h-[calc(100vh-4rem)] w-full"
      style={{ position: "relative", overflow: "visible" }}
    >
      {visiblePositions.map((position) => {
        if (position.isDraft) {
          return (
            <ClickAwayListener
              key="draft"
              onClickAway={(e) => {
                // Don't clear if clicking on Tippy content (used by mentions)
                if ((e.target as HTMLElement).closest("[data-tippy-root]")) {
                  return;
                }

                clearDraft(editor);
              }}
            >
              <div
                ref={draftContainerRef}
                className="absolute w-full transition-all duration-200"
                style={{ top: `${position.final}px` }}
              >
                <CommentDraft
                  editor={editor}
                  internalContractId={internalContractId}
                  referenceId={referenceId}
                  context={ThreadContext.PROPOSAL}
                />
              </div>
            </ClickAwayListener>
          );
        }

        if (!position.thread) return null;

        const isActive = position.thread.id === activeThread;
        return (
          <ClickAwayListener
            onClickAway={(e) => {
              // Check if the click was inside the editor
              if (editorContainerRef.current && editorContainerRef.current.contains(e.target as Node)) {
                return;
              }
              // Don't deactivate if clicking on interactive elements
              if ((e.target as HTMLElement).closest('[data-interactive="true"]')) {
                return;
              }
              // Don't deactivate if clicking on another thread container
              if ((e.target as HTMLElement).closest('[data-thread-container="true"]')) {
                return;
              }
              editor?.commands.setActiveComment(undefined);
            }}
            key={position.thread.id}
          >
            <div
              ref={handleRef(position.thread.id)}
              className="absolute w-full transition-all duration-200"
              data-thread-container="true"
              style={{
                top: `${position.final}px`,
                zIndex: isActive ? 10 : 1,
              }}
              onClick={(e) => {
                // Don't activate if clicking on interactive elements
                if ((e.target as HTMLElement).closest('[data-interactive="true"]')) {
                  return;
                }
                if (editor) editor.commands.setActiveComment(position.thread?.id);
              }}
            >
              <Thread
                thread={position.thread}
                context={ThreadContext.PROPOSAL}
                internalContractId={internalContractId}
                referenceId={referenceId}
                editor={editor}
                editorId={undefined}
                setDisableScroll={() => {}}
                initialActiveThreadIdRef={{ current: null }}
                statusFilter={CommentsStatusFilter.Open}
                isActiveThread={isActive}
                onContentChange={immediateUpdate}
              />
            </div>
          </ClickAwayListener>
        );
      })}
    </div>
  );
};

import { useComments } from "../../../api/comments/useComments";
import { Thread } from "./Thread";
import { useActiveThread } from "./useThread";
import Loader from "utils/Spinner";
import { ThreadContext, CommentsStatusFilter, CommentsUserFilter } from "../types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import { useThreadEditorMapping } from "./useThreadEditorMapping";
import type { Editor } from "@tiptap/react";
import { useDraft } from "./useDraft";
import { CommentDraft } from "./CommentDraft";
import ClickAwayListener from "helpers/ClickAwayListener";
import { clearDraft } from "../CommentsDraftPlugin";

interface CommentsPanelProps {
  context: ThreadContext;
  internalContractId: string;
  referenceId?: string;
  statusFilter: CommentsStatusFilter;
  userFilter: CommentsUserFilter;
  setStatusFilter: (newState: CommentsStatusFilter) => void;
}

export const CommentsPanel = ({
  internalContractId,
  referenceId,
  context,
  statusFilter,
  userFilter,
  setStatusFilter,
}: CommentsPanelProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const commentIdParam = searchParams.get("commentId");

  const { resolvedThreads, unresolvedThreads, isLoading, data } = useComments(internalContractId, referenceId);
  const editor = useAppSelector((state) => state.copilot.activeCommentEditor);
  const activeEditorID = useAppSelector((state) => state.copilot.activeEditorId);
  const activeThreadId = useActiveThread(editor);
  const [activeCommentId, setActiveCommentId] = useState<string | null>(null);
  const threadEditorMapping = useThreadEditorMapping(data, context, internalContractId, referenceId);
  const [disableScroll, setDisableScroll] = useState(false);
  const allThreads = useMemo(() => [...resolvedThreads, ...unresolvedThreads], [resolvedThreads, unresolvedThreads]);
  const initialActiveThreadIdRef = useRef(searchParams.get("threadId")); // the threadId to activate on load
  const draft = useDraft(editor || null);
  const isStatusFilterResolved = statusFilter === CommentsStatusFilter.Resolved;
  const currentUser = useAppSelector((store) => store.auth.currentUser);

  useEffect(() => {
    if (!activeThreadId || isLoading) return;
    // If the active thread was clicked while in Resolved mode, switch to Open mode.
    if (isStatusFilterResolved) {
      setStatusFilter(CommentsStatusFilter.Open);
    }
  }, [activeThreadId, isLoading]);

  useEffect(() => {
    if (!initialActiveThreadIdRef.current) return;
    // remove params from URL
    searchParams.delete("threadId");
    searchParams.delete("commentId");
    setSearchParams(searchParams, { replace: true });
  }, [isLoading]);

  // Update hidden comments in the editor based on user filter
  const getFilteredThreadIds = useMemo(() => {
    return (userFilter: CommentsUserFilter) => {
      return allThreads
        .filter((thread) => {
          let userMatch = true;
          switch (userFilter) {
            case CommentsUserFilter.VultronOnly:
              userMatch = thread.comments.some((comment) => comment.commenter.is_vultron);
              break;
            case CommentsUserFilter.MemberOnly:
              userMatch = thread.comments.every((comment) => !comment.commenter.is_vultron);
              break;
            case CommentsUserFilter.AssignedToMe:
              userMatch = thread.assignee?.id === currentUser?.id;
              break;
            case CommentsUserFilter.AllUsers:
            default:
              userMatch = true;
          }

          return userMatch;
        })
        .map((thread) => thread.id);
    };
  }, [allThreads, currentUser?.id]);

  const updateHiddenComments = () => {
    const visibleThreadIds = getFilteredThreadIds(userFilter);
    const hiddenThreadIds = allThreads.map((thread) => thread.id).filter((id) => !visibleThreadIds.includes(id));
    if (editor) {
      editor.commands.setHiddenComments(hiddenThreadIds);
    }
  };

  useEffect(() => {
    if (!isLoading && allThreads.length > 0) {
      updateHiddenComments();
    }
  }, [isLoading, allThreads, statusFilter, userFilter]);

  // Update active comment based on URL param
  useEffect(() => {
    setActiveCommentId(commentIdParam);
    if (commentIdParam) {
      setSearchParams(
        (prev) => {
          prev.delete("commentId");
          return prev;
        },
        { replace: true },
      );
    }
  }, [commentIdParam, setSearchParams]);

  // Switch to open comments when a draft is created
  useEffect(() => {
    if (draft && isStatusFilterResolved) {
      setStatusFilter(CommentsStatusFilter.Open);
    }
  }, [draft, statusFilter, setStatusFilter, isStatusFilterResolved]);

  // Choose the threads based on the current filter state (resolved vs. unresolved)
  const threads = isStatusFilterResolved ? resolvedThreads : unresolvedThreads;

  // Apply additional filtering based on the user filter
  const filteredThreads = useMemo(() => {
    return threads.filter((thread) => {
      if (thread.comments.length === 0) {
        return false;
      }
      if (userFilter === CommentsUserFilter.VultronOnly) {
        return thread.comments.some((comment) => comment.commenter.is_vultron);
      } else if (userFilter === CommentsUserFilter.MemberOnly) {
        return thread.comments.every((comment) => !comment.commenter.is_vultron);
      } else if (userFilter === CommentsUserFilter.AssignedToMe) {
        return thread.assignee?.id === currentUser?.id;
      } else if (userFilter === CommentsUserFilter.AllUsers) {
        return true;
      }
      return false;
    });
  }, [currentUser?.id, threads, userFilter]);

  if (!editor) return null;

  if (isLoading)
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loader type={undefined} />
      </div>
    );

  const showNoCommentsMessage = filteredThreads.length === 0 && !draft;
  const showDraft = draft && statusFilter === CommentsStatusFilter.Open;

  return (
    <div className="flex flex-col flex-1 overflow-auto w-full">
      <div className={`${disableScroll ? "overflow-hidden" : "overflow-auto"} flex-1 w-full p-4`}>
        <div className="flex flex-col space-y-4">
          {/* threads */}
          {filteredThreads.map((thread) => {
            let threadEditor: Editor | undefined | null;
            if (context === ThreadContext.PROPOSAL) {
              threadEditor = editor;
            } else {
              threadEditor = threadEditorMapping[thread.id] === activeEditorID ? editor : undefined;
            }
            return (
              <Thread
                editorId={threadEditorMapping[thread.id]}
                editor={threadEditor}
                thread={thread}
                key={thread.id}
                internalContractId={internalContractId}
                referenceId={referenceId}
                isActiveThread={thread.id === activeThreadId}
                context={context}
                activeCommentId={activeCommentId}
                statusFilter={statusFilter}
                setDisableScroll={setDisableScroll}
                initialActiveThreadIdRef={initialActiveThreadIdRef}
              />
            );
          })}
          {/* new comment */}
          {showDraft && (
            <ClickAwayListener onClickAway={() => clearDraft(editor)}>
              <div>
                <CommentDraft
                  editor={editor}
                  internalContractId={internalContractId}
                  referenceId={referenceId}
                  context={context}
                />
              </div>
            </ClickAwayListener>
          )}
          {/* no comments message */}
          {showNoCommentsMessage && (
            <div className="pt-2 flex flex-col items-center h-full justify-center">
              <span className="font-medium text-xl mb-1">No comments</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

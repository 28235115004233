import type { ComponentProps } from "react";

export function LinkIcon(props: ComponentProps<"svg">) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.1428 8.69964C7.44327 9.10134 7.82662 9.43372 8.26684 9.67423C8.70707 9.91475 9.19387 10.0578 9.69423 10.0936C10.1946 10.1294 10.6968 10.0572 11.1668 9.88192C11.6368 9.7066 12.0636 9.43224 12.4183 9.07746L14.5173 6.97846C15.1545 6.31867 15.5071 5.43498 15.4992 4.51773C15.4912 3.60048 15.1233 2.72306 14.4747 2.07444C13.826 1.42582 12.9486 1.05791 12.0314 1.04994C11.1141 1.04197 10.2304 1.39458 9.57063 2.03183L8.36721 3.22825M9.94146 7.30031C9.64098 6.89861 9.25763 6.56623 8.81741 6.32572C8.37718 6.0852 7.89038 5.94218 7.39002 5.90634C6.88966 5.87051 6.38745 5.9427 5.91744 6.11803C5.44743 6.29335 5.02063 6.56771 4.66598 6.92249L2.56698 9.02149C1.92973 9.68128 1.57712 10.565 1.58509 11.4822C1.59306 12.3995 1.96098 13.2769 2.6096 13.9255C3.25822 14.5741 4.13564 14.942 5.05289 14.95C5.97014 14.958 6.85383 14.6054 7.51362 13.9681L8.71005 12.7717"
        stroke="#1E1E1E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

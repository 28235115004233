import type { WritingAssistantUser, WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import { nanoid } from "nanoid";
import { LiveList, LiveObject } from "YJSProvider/LiveObjects";

export const createWritingAssistant = (
  userId: string,
  properties?: Partial<WritingAssistantUser>,
): LiveObject<WritingAssistantUser> => {
  return new LiveObject({
    user_id: userId,
    conversation: new LiveList([]),
    sessions: new LiveList([]),
    archived_sessions: new LiveList([]),
    ...properties,
  });
};

export const createWritingAssistantSession = (
  properties?: Partial<WritingAssistantSession>,
): LiveObject<WritingAssistantSession> => {
  const newID = nanoid();
  return new LiveObject({
    id: properties?.id || newID,
    name: "",
    updated_at: new Date().toISOString(),
    conversation: new LiveList([]),
    ...properties,
  });
};

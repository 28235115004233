/** @jsxImportSource @emotion/react */
import { ArrowDown, ArrowUp } from "lucide-react";
import Tooltip from "components/atoms/tooltip";
import tw from "twin.macro";

type ExpandButtonsProps = {
  idx: number;
  expandContent: (groupIndex: number, previous: boolean) => Promise<void>;
  canExpandUp: boolean;
  canExpandDown: boolean;
  isFullPage?: boolean;
  localRef: React.RefObject<HTMLDivElement>;
  scrollOnResize: (container: HTMLDivElement, scrollOptions: ScrollToOptions) => void;
};

const ExpandButtons = ({
  idx,
  expandContent,
  canExpandUp,
  canExpandDown,
  isFullPage,
  localRef,
  scrollOnResize,
}: ExpandButtonsProps) => {
  return (
    <div className="flex gap-2 justify-end py-2">
      <button
        onClick={async () => {
          await expandContent(idx, true);
          if (localRef.current) {
            scrollOnResize(localRef.current, { top: 0 });
          }
        }}
        className="p-1.5 pr-2 rounded-md border border-gray-300 text-xs  disabled:text-gray-400 hover:bg-zinc-100"
        css={[!isFullPage && tw`bg-zinc-50`]}
        disabled={!canExpandUp}
      >
        <Tooltip
          content={
            canExpandUp ? "Expand and view more text above this content" : "There is no more text above this content"
          }
          contentProps={{ sideOffset: 12 }}
          delayDuration={200}
        >
          {isFullPage ? (
            <div className="flex items-center gap-1">
              <ArrowUp size={16} /> Expand Above
            </div>
          ) : (
            <ArrowUp size={16} />
          )}
        </Tooltip>
      </button>
      <button
        onClick={async () => {
          await expandContent(idx, false);
          if (localRef.current) {
            scrollOnResize(localRef.current, { top: localRef.current.scrollHeight });
          }
        }}
        className="p-1.5 pr-2 rounded-md border border-gray-300 text-xs  disabled:text-gray-400 hover:text-black"
        css={[!isFullPage && tw`bg-zinc-50`]}
        disabled={!canExpandDown}
      >
        <Tooltip
          content={
            canExpandDown ? "Expand and view more text below this content" : "There is no more text below this content"
          }
          contentProps={{ sideOffset: 12 }}
          delayDuration={200}
        >
          {isFullPage ? (
            <div className="flex items-center gap-1">
              <ArrowDown size={16} /> Expand Below
            </div>
          ) : (
            <ArrowDown size={16} />
          )}
        </Tooltip>
      </button>
    </div>
  );
};

export default ExpandButtons;

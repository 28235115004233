import type { ReactNode } from "react";
import React, { createContext } from "react";
import { useScale } from "./hooks";

type ScaleContextType = {
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  handleWheel: (event: React.WheelEvent<HTMLDivElement>) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  scaledDocRef: React.RefObject<HTMLDivElement>;
};

export const ScaleContext = createContext<ScaleContextType>({
  scale: 1,
  setScale: () => {},
  handleWheel: () => {},
  containerRef: { current: null },
  scaledDocRef: { current: null },
});

export const ScaleProvider = ({ children }: { children: ReactNode }) => {
  const scaleProps = useScale();

  return <ScaleContext.Provider value={scaleProps}>{children}</ScaleContext.Provider>;
};

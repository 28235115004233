/** @jsxImportSource @emotion/react */

import { setAssistantPrompt } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import type { InstructionExtraState } from "../hooks";
import { useInputFocus } from "../hooks";
import VoiceTranslateItem from "components/voice-assist/VoiceTranslateItem";
import WritingAssistantInputActions from "./input-actions";
import HighlightedTextDisplay from "./HighlightTextDisplay";
import SubmitButton from "../SubmitButton";
import { useAssistant } from "../../AssistantContext";
import { useMemo } from "react";

type Props = {
  instructionExtraState: InstructionExtraState;
};

const DefaultWritingAssistantInput = ({ instructionExtraState }: Props) => {
  const activeProject = useAppSelector((root) => root.project.activeProject);
  const { prompt, streamState, highlightedText } = useAppSelector((root) => root.writingAssistant);
  const { isStreamingInProgress } = streamState;
  const inputRef = useInputFocus();
  const dispatch = useAppDispatch();
  const { submitMessage } = useAssistant();
  const canSubmit = !!prompt.trim() && !isStreamingInProgress && !!activeProject?.internal_contract.id;
  const trailingSpace = (prompt: string): string => {
    if (prompt === "" || prompt[prompt.length - 1] === " ") {
      return "";
    } else {
      return " ";
    }
  };

  const handleTranslation = (message: string) => {
    const spacing = trailingSpace(prompt);
    dispatch(setAssistantPrompt(prompt + spacing + message));
  };
  const { excludedComponents } = instructionExtraState;
  const { internet: shouldHideInternet } = excludedComponents;
  const defaultSubmitParams = useMemo(
    () => (shouldHideInternet ? { use_internet: false } : undefined),
    [shouldHideInternet],
  );

  const isWorkflowSession = useAppSelector((root) => !!root.writingAssistant.activeSession?.workflow);

  return (
    <>
      <div className="relative flex flex-col p-3 gap-2 h-full">
        {!isWorkflowSession && !!highlightedText.trim() && <HighlightedTextDisplay text={highlightedText} />}
        <div className="flex flex-1">
          <textarea
            ref={inputRef}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey && canSubmit) {
                submitMessage(defaultSubmitParams);
              }
            }}
            readOnly={isStreamingInProgress}
            autoFocus
            value={prompt}
            className="text-gray-600 !h-[inherit] text-sm flex-1 outline-none resize-none w-full"
            placeholder="Ask me anything..."
            onChange={(e) => dispatch(setAssistantPrompt(e.target.value))}
          />
        </div>
        <div className="flex flex-row justify-end items-center gap-1">
          <VoiceTranslateItem
            styles={tw`bg-white text-gray-600 duration-150 rounded-full hover:text-black duration-100 hover:bg-gray-200`}
            height={"7"}
            width={"7"}
            onComplete={handleTranslation}
            showTooltip
          />
          <SubmitButton disabled={!canSubmit} defaultParams={defaultSubmitParams} />
        </div>
      </div>
      <div className="bg-gray-100 border-t border-gray-300 pr-3 pl-2 py-2">
        <WritingAssistantInputActions instructionExtraState={instructionExtraState} />
      </div>
    </>
  );
};

export default DefaultWritingAssistantInput;

import { useMutation } from "utils/yjs-configs/ai-assistant/yjs.config";
import type { AIAssistant, AIAssistantSession, VultronBlock } from "utils/yjs-configs/ai-assistant/schema";
import { find, findIndex, LiveList, update } from "YJSProvider/LiveObjects";
import { createAIAssistantSession } from "utils/Liveblocks/AIAssistant";
import { useNavigate } from "react-router-dom";
import { deleteAssistantChatDocument, getAssistantSessionDocuments, uploadAIAssistantChatDocument } from "api/api";
import { useNotification } from "context/notificationContext";
import * as logger from "utils/log";
import type { ChatSessionDocument } from "types/Assistants/types";
import type { Workflow } from "pages/ai-assistant/constants";

const useAIAssistantOperations = () => {
  const navigate = useNavigate();
  const { setToast } = useNotification();

  const deleteMessageBlock = useMutation(({ storage }, sessionId: string, blockId: string) => {
    const aiAssistant = storage.get("ai_assistant");
    const sessions = aiAssistant?.get("sessions") as AIAssistant["sessions"];

    if (!sessions) return;

    const foundSessionConversation: AIAssistantSession["conversation"] | undefined = find(
      sessions,
      (session) => session.get("id") === sessionId,
    )?.get("conversation");

    if (!foundSessionConversation) return;
    const foundBlockIdx = findIndex(foundSessionConversation, (block) => block.get("id") === blockId);

    if (typeof foundBlockIdx !== "number" || foundBlockIdx === -1) return;

    foundSessionConversation?.delete(foundBlockIdx);
  }, []);

  const archiveSession = useMutation(({ storage }, sessionId: string) => {
    const aiAssistant = storage.get("ai_assistant");
    const sessions = aiAssistant?.get("sessions") as AIAssistant["sessions"] | undefined;

    if (!sessions) return;

    const foundSessionIdx = findIndex(sessions, (session) => session.get("id") === sessionId);
    const foundSession = sessions?.get(foundSessionIdx);

    if (!foundSession) return;

    const clonedSession = foundSession.clone();
    const archivedSessions = aiAssistant.get("archived_sessions");

    if (!archivedSessions) aiAssistant.set("archived_sessions", new LiveList([]));

    aiAssistant.get("archived_sessions")?.push([clonedSession]);
    sessions.delete(foundSessionIdx);
  }, []);

  const createSession = useMutation(
    ({ storage }, sessionName?: string, id?: string, workflow?: Workflow["name"], subType?: Workflow["subType"]) => {
      const liveSessions = storage.get("ai_assistant")?.get("sessions") as AIAssistant["sessions"];

      const newSession = createAIAssistantSession({
        name: sessionName?.slice(0, 150),
        ...(id && { id }),
        ...(workflow && { workflow }),
        ...(subType && { workflow_subtype: subType }),
      });
      liveSessions.push([newSession]);

      navigate(`/dashboard/ai-assistant/${newSession.get("id")}`);

      return newSession;
    },
    [navigate],
  );

  const unarchiveSession = useMutation(({ storage }, sessionId: string) => {
    const aiAssistant = storage.get("ai_assistant");
    const archivedSessions = aiAssistant?.get("archived_sessions") as AIAssistant["archived_sessions"] | undefined;

    if (!archivedSessions) return;

    const foundSessionIdx = findIndex(archivedSessions, (session) => session.get("id") === sessionId);
    const foundSession = archivedSessions?.get(foundSessionIdx);

    if (!foundSession) return;

    const clonedSession = foundSession.clone();

    aiAssistant.get("sessions")?.push([clonedSession]);
    archivedSessions.delete(foundSessionIdx);
  }, []);

  const updateSession = useMutation(({ storage }, sessionId: string, properties: Partial<AIAssistantSession>) => {
    const aiAssistant = storage.get("ai_assistant");
    const sessions = aiAssistant?.get("sessions") as AIAssistant["sessions"] | undefined;

    if (!sessions) return;

    const foundSession = find(sessions, (session) => session.get("id") === sessionId);

    if (!foundSession) return;

    update(foundSession, { ...properties, updated_at: new Date().toISOString() });
  }, []);

  const deleteSession = useMutation(({ storage }, sessionId: string) => {
    const aiAssistant = storage.get("ai_assistant");
    const sessions = aiAssistant?.get("sessions") as AIAssistant["sessions"] | undefined;

    if (!sessions) return;

    const foundSessionIdx = findIndex(sessions, (session) => session.get("id") === sessionId);
    const foundSession = sessions.get(foundSessionIdx);

    if (!foundSession) return;

    sessions.delete(foundSessionIdx);
  }, []);

  const deleteArchivedSession = useMutation(({ storage }, sessionId: string) => {
    const aiAssistant = storage.get("ai_assistant");
    const sessions = aiAssistant?.get("archived_sessions") as AIAssistant["archived_sessions"] | undefined;

    if (!sessions) return;

    const foundSessionIdx = findIndex(sessions, (session) => session.get("id") === sessionId);
    const foundSession = sessions.get(foundSessionIdx);

    if (!foundSession) return;

    sessions.delete(foundSessionIdx);
  }, []);

  const uploadChatDocument = async (file: File, chatSessionId: string): Promise<any> => {
    try {
      await uploadAIAssistantChatDocument(file, chatSessionId);
    } catch (error) {
      logger.error(error as Error, "File upload failed");
      setToast.error({ msg: "File upload failed." });
      throw new Error("File upload failed. Please try again.", { cause: error });
    }
  };

  const updateAiAssistantVultronBlock = useMutation(
    ({ storage }, sessionId: string, properties: Partial<VultronBlock>) => {
      const aiAssistant = storage.get("ai_assistant");
      const sessions = aiAssistant?.get("sessions") as AIAssistant["sessions"] | undefined;

      if (!sessions) return;

      const foundSessionIdx = findIndex(sessions, (session) => session.get("id") === sessionId);
      const foundSession = sessions.get(foundSessionIdx);

      if (!foundSession) return;

      const conversation = foundSession.get("conversation");

      const lastMessage = conversation.get(conversation.length - 1);

      update(lastMessage, properties);
    },
    [],
  );

  const deleteChatDocument = async (chatSessionDocumentId: string): Promise<void> => {
    try {
      await deleteAssistantChatDocument(chatSessionDocumentId);
    } catch (error) {
      logger.error(error as Error, "Failed to delete chat document");
      setToast.error({ msg: "File delete failed." });
      throw new Error("Failed to delete chat document. Please try again", { cause: error });
    }
  };

  const getChatSessionDocuments = async (chatSessionId: string): Promise<ChatSessionDocument[]> => {
    try {
      const response = await getAssistantSessionDocuments(chatSessionId);
      return response.data;
    } catch (error) {
      logger.error(error as Error, "Error fetching chat session documents");
      setToast.error({ msg: "Failed to get session documents." });
      throw new Error("Unable to fetch chat session documents. Please try again.", { cause: error });
    }
  };

  return {
    updateSession,
    unarchiveSession,
    deleteMessageBlock,
    archiveSession,
    deleteSession,
    deleteArchivedSession,
    createSession,
    uploadChatDocument,
    deleteChatDocument,
    getChatSessionDocuments,
    updateAiAssistantVultronBlock,
  };
};

export default useAIAssistantOperations;

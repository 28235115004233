import { Skeleton } from "components/molecules/skeleton";
import useGetForms from "hook/capture/useGetForms";
import { useParams } from "react-router-dom";
import type { CaptureSchemaType } from "types/Capture";
import FormItemRow from "../form-item-row";
import { CAPTURE_SCHEMA_TYPE_PREFIX } from "const-values/Capture";

const CaptureForm = () => {
  const { schemaType } = useParams<{ schemaType: string }>();
  const schemaTypeWithPrefix = schemaType
    ? (`${CAPTURE_SCHEMA_TYPE_PREFIX}:${schemaType}` as CaptureSchemaType)
    : undefined;

  const { data } = useGetForms({ schema_type: schemaTypeWithPrefix }, { enabled: !!schemaTypeWithPrefix });
  const hasForms = Boolean(data?.length);
  const currentForm = data?.[0];

  return (
    <div className="flex flex-col gap-4">
      {currentForm &&
        currentForm.items.map((item) => <FormItemRow key={item.id} formId={currentForm.id} formItem={item} />)}
      {!hasForms && new Array(4).fill(0).map((_, i) => <Skeleton key={i} borderRadius={4} height={181} width="100%" />)}
    </div>
  );
};

export default CaptureForm;

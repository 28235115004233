/** @jsxImportSource @emotion/react */

import { setToggleInternet } from "store/reducers/ai-assistant/aiAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import Switch from "components/atoms/switch/Switch";
import { useFlags } from "hook/useFlags";

const InternetToggle: React.FC = () => {
  const enableInternet = useAppSelector((root) => root.aiAssistantState.enableInternet);
  const flags = useFlags();

  const dispatch = useAppDispatch();

  return flags.disableInternetChat ? null : (
    <>
      <div className="flex items-center">
        <Switch
          checked={enableInternet}
          onCheckedChange={() => dispatch(setToggleInternet())}
          size="sm"
          variant="outline"
        />
      </div>
      <div className="ml-1 text-sm" css={[enableInternet ? tw`text-gray-700` : tw`text-gray-400`]}>
        Internet
      </div>
    </>
  );
};

export default InternetToggle;

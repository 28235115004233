/** @jsxImportSource @emotion/react */

import Tooltip from "components/atoms/tooltip/Tooltip";
import AiSmartLight from "components/organisms/ai-smart-light/AiSmartLight";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getWordCount } from "utils/getWordCount";
import type { Storage as ImmutableStorage } from "../../../components/copilot/CopilotSchemaImmutableTypes";
import { RequirementStatus } from "../../../components/copilot/CopilotSchemaImmutableTypes";
import type { ComplianceMatrixRow as ImmutableComplianceMatrixRow } from "../../../components/copilot/CopilotSchemaImmutableTypes";
import type { Storage } from "../../../components/copilot/CopilotSchemaTypes";
import { useDebounce } from "react-use";
import { AiOption } from "components/organisms/ai-smart-light/utils";
import tw from "twin.macro";
import ProposalSectionLoading from "Assets/gifs/proposal-section-loading.gif";
import { ArrowUp, ChevronUp, Lock, LockOpen, MessageSquare, Redo, Undo, X } from "lucide-react";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import { useAIRevise } from "../../../components/copilot/hooks";
import { useParams, useSearchParams } from "react-router-dom";
import Selections from "../../../components/copilot/Selections";
import ResponseSources from "pages/draft-section/draft-section-requirements/ResponseSources";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { useNotification } from "context/notificationContext";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setResponseErrorRequirementIds } from "store/reducers/draft/sectionReducer";
import { toggleEvaluationModal } from "store/reducers/modalsSlice";
import { getRequirementFeedback, createRequirementFeedback } from "api/api";
import grayflag from "../../../Assets/grayflag.svg";
import moment from "moment";
import useRequirementOperations from "hook/useRequirementOperations";
import { useMutation, useStorage, useUpdateMyPresence } from "YJSProvider/createYJSContext";
import { find, update } from "YJSProvider/LiveObjects";
import { isEqual } from "lodash";
import type { RequirementEditorOptions } from "components/requirement-editor/useRequirementEditor";
import { useRequirementEditor } from "components/requirement-editor/useRequirementEditor";
import { RequirementEditor } from "components/requirement-editor/RequirementEditor";
import { Button } from "components/yjs-editor/primitives/Button";
import { extractThreadIds } from "components/Comments/utils";
import { useFlags } from "hook/useFlags";

const LOADING_MSGS = ["Searching for relevant content", "Analyzing data", "Processing content", "Preparing response"];
const INITIAL_LOADING_MSG = LOADING_MSGS[0];

type WrittenResponseProps = {
  complianceMatrixRow: ImmutableComplianceMatrixRow;
};

const RequirementResponse = ({ complianceMatrixRow }: WrittenResponseProps) => {
  const { setToast } = useNotification();
  const {
    written_content: responseText,
    written_html_content: responseHtml,
    requirement,
    requirement_status,
    is_response_generating: isGenerating,
    is_response_in_queue: isInQueue,
    locked: rowLocked,
    locked_actor,
  } = complianceMatrixRow;
  const dispatch = useAppDispatch();
  const { currentUser, workspaceMembers } = useAppSelector((store) => store.auth);

  const lockedByUsername = useMemo(
    () => workspaceMembers?.find((user) => user.id === locked_actor)?.username || "Removed User",
    [locked_actor, workspaceMembers],
  );
  const generateResponseQueue = useAppSelector((root) => root.requirementsSmartResponse.generateResponseQueue);
  const positionInQueue = useMemo(
    () => generateResponseQueue.findIndex((item) => item.requirement?.id === requirement.id),
    [generateResponseQueue, requirement.id],
  );
  const isRedTeam =
    generateResponseQueue[positionInQueue]?.decompose_requirement && generateResponseQueue[positionInQueue]?.red_team;
  const { setRequirementLock, setRequirementStatus } = useRequirementOperations();
  const responseErrorRequirementIds = useAppSelector(
    (store) => store.sectionState.requirementsState.responseErrorRequirementIds,
  );
  const containerRef = useRef(null);
  const [searchParams] = useSearchParams();
  const params = useParams();
  const internalContractId = searchParams.get("id");
  const volumeId = params.volumeId;
  const [editorContent, setEditorContent] = useState("");
  const [reviseActive, setReviseActive] = useState(false);
  const [isReplacingText, setIsReplacingText] = useState(false);
  const [reviseFeedback, setReviseFeedback] = useState<string>("");
  const requirementContent = requirement?.content || requirement?.summarized_content || "";
  const winThemes = useStorage(
    (root) => (root.win_themes as ImmutableStorage["win_themes"])?.filter(({ content }) => !!content),
    isEqual,
  );
  const isLockedInRow = rowLocked || false;
  const [locked, setLocked] = useState<boolean>(false);
  const [loadingMsg, setLoadingMsg] = useState<string>(INITIAL_LOADING_MSG);
  const updateMyPresence = useUpdateMyPresence();
  const isResponseError = responseErrorRequirementIds.includes(requirement.id);
  const flags = useFlags();
  const reviseRef = useRef<HTMLInputElement | null>(null);
  useAnimateLoadingMsg(!!isGenerating, 4000, LOADING_MSGS, (msg) => setLoadingMsg(msg));

  const { isLoading: isRevising, reviseText } = useAIRevise((text) => {
    if (!requirementEditor) return;
    requirementEditor.commands.insertContentAt(requirementEditor.state.selection, text);
    setWrittenContent(requirementEditor.getText(), requirementEditor.getHTML());
  });
  const isStatusTodo = (requirement_status || RequirementStatus.Todo) === RequirementStatus.Todo;
  const isLoading = isGenerating || isInQueue || isRevising || isReplacingText;

  const normalResponseTime = flags.enableOnsiteFeatures ? "2 minutes" : "a minute";
  const redTeamResponseTime = flags.enableOnsiteFeatures ? "5 minutes" : "3 minutes";
  const revisionTime = flags.enableOnsiteFeatures ? "a minute" : "30 seconds";

  const handleResponseChange = useCallback(
    (html: string) => {
      if (isGenerating || isInQueue) return;
      const newText = html;
      setEditorContent(newText);
    },
    [isGenerating, isInQueue],
  );

  const requirementOptions: RequirementEditorOptions = useMemo(
    () => ({
      placeholder: "Add your response or provide writing directions and guidelines to generate a response…",
      onChange(value, rawText) {
        if (
          isLoading ||
          document.activeElement?.tagName === "BODY" ||
          document.activeElement?.id === "ai-smart-light-button"
        ) {
          return;
        }
        handleResponseChange(value);
        if (rawText?.trim()?.length) {
          if (responseErrorRequirementIds.includes(requirement.id))
            dispatch(setResponseErrorRequirementIds({ add: false, data: [requirement.id] }));
          if (isStatusTodo) setRequirementStatus(requirement.id, RequirementStatus.InProgress);
        }
      },
      onBlur({ event: e }) {
        const target = e.relatedTarget;
        if (
          target instanceof HTMLElement &&
          (target.classList.contains("ai-smartlight-menu") || target.classList.contains("comment-button"))
        )
          return;
        setTimeout(() => updateMyPresence({ selectedId: null }), 100);
        e.stopPropagation();
      },
      editable: !isLockedInRow,
    }),
    [
      dispatch,
      handleResponseChange,
      isLoading,
      isLockedInRow,
      isStatusTodo,
      requirement.id,
      responseErrorRequirementIds,
      setRequirementStatus,
      updateMyPresence,
    ],
  );

  const {
    editor: requirementEditor,
    selectedText,
    resetSelection,
  } = useRequirementEditor(
    responseHtml || editorContent || responseText || "",
    requirementOptions,
    !isLockedInRow,
    internalContractId ?? "",
    volumeId ?? "",
  );

  const setWrittenContent = useMutation(
    ({ storage }, content: string, htmlContent: string, threadIds?: string[]) => {
      const requirements = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      if (!requirements) return;
      const liveRequirement = find(requirements, (row) => row.get("requirement")?.get("id") === requirement?.id);

      if (!liveRequirement) return;

      update(liveRequirement, { written_content: content, written_html_content: htmlContent });

      // keep threadIds in sync for a case when user deletes a comment from the doc but the thread still exists
      if (threadIds) {
        const existingThreadIds = liveRequirement?.get("thread_ids") || [];
        const updatedThreadIds = Array.from(new Set([...existingThreadIds, ...threadIds]));
        liveRequirement?.set("thread_ids", updatedThreadIds);
      }
    },
    [requirement?.id],
  );

  const firstUpdate = useRef(true);

  useDebounce(
    () => {
      if (!isGenerating && !firstUpdate.current && !isInQueue && editorContent !== responseHtml) {
        if (!requirementEditor) return;
        const doc = requirementEditor.getJSON();
        const threadIds = doc ? extractThreadIds(doc) : undefined;
        setWrittenContent(requirementEditor.getText(), requirementEditor.getHTML(), threadIds);
      }
      firstUpdate.current = false;
    },
    200,
    [editorContent],
  );

  useEffect(() => {
    if (locked || isGenerating) setEditorContent(responseHtml || responseText || "");
  }, [isGenerating, responseText, locked]);

  useEffect(() => {
    if (!requirement?.id) return;
    setEditorContent(responseHtml || responseText || "");
    return () => {
      resetSelection();
      setIsReplacingText(false);
      setReviseActive(false);
      setReviseFeedback("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirement?.id]);

  const plainEditorText = requirementEditor?.getText() || "";
  const wordCount = useMemo(() => getWordCount(plainEditorText), [editorContent]);
  const canRevise =
    !isGenerating &&
    !isInQueue &&
    !isReplacingText &&
    !isRevising &&
    !!requirementContent?.trim() &&
    !!reviseFeedback?.trim() &&
    !requirementEditor?.state?.selection.empty;

  useEffect(() => {
    if (reviseActive) {
      setTimeout(() => reviseRef?.current?.focus(), 200);
    }
  }, [reviseActive]);

  useEffect(() => {
    requirementEditor?.setEditable(!isLoading);
  }, [requirementEditor, isLoading]);

  useEffect(() => {
    if (isGenerating || requirementEditor?.isFocused) {
      updateMyPresence({
        selectedId: `${complianceMatrixRow.requirement.id}-requirement-response`,
        name: currentUser?.username,
      });
    } else {
      updateMyPresence({
        selectedId: null,
      });
    }
  }, [
    isGenerating,
    complianceMatrixRow.requirement.id,
    currentUser?.username,
    updateMyPresence,
    requirementEditor?.isFocused,
  ]);

  useEffect(() => {
    const onVisibleChange = () => {
      if ((requirementEditor?.isFocused && document.visibilityState === "visible") || isGenerating) {
        requirementEditor?.commands.focus();

        updateMyPresence({
          selectedId: `${complianceMatrixRow.requirement.id}-requirement-response`,
          name: currentUser?.username,
        });
      }
      if (document.visibilityState === "hidden" && !isGenerating) {
        updateMyPresence({
          selectedId: null,
        });
      }
    };
    document.addEventListener("visibilitychange", onVisibleChange);
    return () => document.removeEventListener("visibilitychange", onVisibleChange);
  }, [
    isGenerating,
    complianceMatrixRow.requirement.id,
    currentUser?.username,
    updateMyPresence,
    requirementEditor?.isFocused,
    requirementEditor?.commands,
  ]);

  const [evaluation, setEvaluation] = useState({
    loadingEvaluation: true,
    needsEvaluation: true,
    createdBy: "",
    categories: [],
    totalGrade: "",
    createdAt: "",
  });

  const changeLock = useCallback(
    (locked: boolean, locked_actor: string) => {
      if (!locked_actor) {
        return;
      }
      setRequirementLock(requirement.id, locked, locked_actor);
    },
    [requirement.id, setRequirementLock],
  );

  const fetchEvaluation = async () => {
    try {
      setEvaluation({
        loadingEvaluation: true,
        needsEvaluation: true,
        createdBy: "",
        categories: [],
        totalGrade: "",
        createdAt: "",
      });
      const response = await getRequirementFeedback(internalContractId, complianceMatrixRow.requirement.id);

      if (response && response.status !== 204) {
        setEvaluation({
          loadingEvaluation: false,
          needsEvaluation: false,
          createdAt: response.data.created_at,
          createdBy: response.data.created_by,
          categories: response.data.categories,
          totalGrade: response.data.total_score,
        });
      }
      if (response && response.status === 204) {
        setEvaluation({
          loadingEvaluation: false,
          needsEvaluation: true,
          createdBy: "",
          categories: [],
          totalGrade: "",
          createdAt: "",
        });
      }
    } catch (error) {
      // add toast
    }
  };

  const calculateDaysInThePast = (date: string | Date): string => {
    const duration = moment(date).fromNow(true); // This will give "2 days", "a month", etc., in a human-readable format.

    if (duration === "a month") return "one month";
    if (duration === "a day") return "one day";
    if (duration === "a year") return "one year";
    // ... Add any other specific checks you want here ...

    return duration;
  };

  // on evaluation button click
  const onEvaluationBtn = () => {
    dispatch(
      toggleEvaluationModal({
        open: true,
        initialProps: {
          evaluation: evaluation,
          setEvaluation: setEvaluation,
          calculateDaysInThePast: calculateDaysInThePast,
          onEvaluationBtn: onEvaluationBtn,
          showBtnLoading: true,
          writtenContent: responseText,
        },
      }),
    );
    createRequirementFeedback(internalContractId, complianceMatrixRow.requirement.id)
      .then((res) => {
        setEvaluation({
          loadingEvaluation: false,
          needsEvaluation: false,
          createdAt: res.data.created_at,
          createdBy: res.data.created_by,
          categories: res.data.categories,
          totalGrade: res.data.total_score,
        });
        dispatch(
          toggleEvaluationModal({
            open: true,
            initialProps: {
              evaluation: {
                createdAt: res.data.created_at,
                createdBy: res.data.created_by,
                categories: res.data.categories,
                totalGrade: res.data.total_score,
                needsEvaluation: false,
              },
              setEvaluation: setEvaluation,
              calculateDaysInThePast: calculateDaysInThePast,
              onEvaluationBtn: onEvaluationBtn,
              showBtnLoading: false,
              writtenContent: responseText,
            },
          }),
        );
      })
      .catch((err) => {
        dispatch(
          toggleEvaluationModal({
            open: true,
            initialProps: {
              evaluation: evaluation,
              setEvaluation: setEvaluation,
              calculateDaysInThePast: calculateDaysInThePast,
              onEvaluationBtn: onEvaluationBtn,
              showBtnLoading: false,
              writtenContent: responseText,
            },
          }),
        );
        setToast.error({
          title: "Unable to generate score",
          msg: "We were unable to score the response due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      });
  };

  useEffect(() => {
    fetchEvaluation();
  }, [complianceMatrixRow.requirement.id]);

  const shouldShowLoader =
    (!!isGenerating && !complianceMatrixRow.written_content?.trim()) || (!!isInQueue && !isGenerating);

  const gradingTooltip = (): string => {
    if (evaluation.loadingEvaluation) return "Loading...";
    if (!complianceMatrixRow.written_content?.trim()) return "Unable to provide a score without a response.";
    return "";
  };
  const disableScoringButton =
    evaluation.loadingEvaluation || !complianceMatrixRow.written_content?.trim() || isLockedInRow;

  return (
    <div ref={containerRef} className="flex flex-col relative mx-1.5 pb-0.5">
      <div className="z-[2] bg-slate-50 border-light border px-4 py-1.5 rounded-t-md flex items-center justify-between overflow-hidden">
        <div className="flex gap-1 items-center justify-center py-1.5">
          <div className="text-slate-500 text-xs font-normal">Response</div>
          <Tooltip content={isLockedInRow ? `Unlock Response. Locked by ${lockedByUsername}` : "Lock Response"}>
            <button
              onClick={() => changeLock(!isLockedInRow, currentUser?.id || "")}
              className="text-xs px-0.5 text-slate-600 hover:text-slate-900"
            >
              {isLockedInRow ? <Lock size={12} /> : <LockOpen size={12} />}
            </button>
          </Tooltip>
        </div>
        {isLockedInRow && <span className="rounded-[4px] bg-[#DBE0E5] text-[#5B6B79] text-xs px-3 py-1">Locked</span>}
      </div>
      <div className="relative">
        <div
          className="flex relative z-[2] items-center justify-between px-4 py-1.5 gap-8 bg-white border-b border-x border-zinc-200"
          css={[isLoading && tw`pointer-events-none`]}
        >
          <div
            className="flex items-center"
            onBlur={(e) => {
              if (reviseActive || e.relatedTarget?.classList?.contains("ai-smartlight-menu")) return;
              setTimeout(() => updateMyPresence({ selectedId: null }), 100);
            }}
          >
            <div className="text-slate-500 text-xs font-normal">
              {wordCount || 0} word{(wordCount || 0) !== 1 && "s"}
            </div>
            <div className="w-px h-4 ml-2 mr-1 bg-slate-400" />
            <AiSmartLight
              dynamicItems={[
                {
                  value: AiOption.Revise,
                  onSelect: () => {
                    setReviseActive(true);
                  },
                },
              ]}
              container={containerRef.current}
              isReplacingText={isReplacingText}
              setIsReplacingText={setIsReplacingText}
              replaceText={(text) => {
                if (!requirementEditor) return;
                requirementEditor.commands.insertContentAt(requirementEditor.state.selection, text);
                setWrittenContent(requirementEditor.getText(), requirementEditor.getHTML());
                setReviseActive(false);
                setReviseFeedback("");
              }}
              selectedText={selectedText}
              isSelected={selectedText?.length > 0}
            />
            <Button
              variant="subtle"
              className="comment-button disabled:text-gray-400"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (requirementEditor?.state.selection.empty) {
                  // No text selected, create a general comment
                  requirementEditor.commands.setDraft();
                } else {
                  // Text is selected, create a comment with quote
                  requirementEditor?.chain().setActiveComment().setDraft().run();
                }
              }}
              disabled={
                isLockedInRow || (!requirementEditor?.can().setDraft() && !requirementEditor?.state.selection.empty)
              }
              aria-label="Comment"
            >
              <MessageSquare size={18} />
            </Button>
            {!isReplacingText && (
              <Tooltip delayDuration={500} content="Undo" contentProps={{ className: "!py-1 !px-2" }}>
                <button
                  disabled={isLockedInRow || !requirementEditor?.can()?.undo()}
                  name="Undo"
                  className="text-slate-500 flex items-center justify-center p-1 ml-1 rounded-md transition-colors duration-150 hover:bg-slate-200 disabled:text-gray-300 disabled:pointer-events-none"
                  onClick={() => {
                    requirementEditor?.commands.undo();
                  }}
                >
                  <Undo size={18} />
                </button>
              </Tooltip>
            )}
            {!isReplacingText && (
              <Tooltip delayDuration={500} content="Redo" contentProps={{ className: "!py-1 !px-2" }}>
                <button
                  disabled={isLockedInRow || !requirementEditor?.can()?.redo()}
                  name="Undo"
                  className="text-slate-500 flex items-center justify-center p-1 ml-1 rounded-md duration-150 transition-colors hover:bg-slate-200 disabled:text-gray-300 disabled:pointer-events-none"
                  onClick={() => {
                    requirementEditor?.commands.redo();
                  }}
                >
                  <Redo size={18} />
                </button>
              </Tooltip>
            )}
          </div>
          <div>
            {evaluation.needsEvaluation ? (
              <>
                <Tooltip content={gradingTooltip()} disabled={!gradingTooltip()}>
                  <button
                    className="text-xs px-3 py-1 flex text-slate-600 bg-slate-200 rounded shadow-sm ml-2 disabled:no-pointer-event hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
                    onClick={() => onEvaluationBtn()}
                    disabled={disableScoringButton}
                  >
                    <div className="mt-0.5 mr-1.5">
                      <img src={grayflag} alt="" className="cursor-pointer min-w-2.5 min-h-2.5" />
                    </div>
                    <div className="">Score</div>
                  </button>
                </Tooltip>
              </>
            ) : (
              <Tooltip content={gradingTooltip()} disabled={!gradingTooltip()}>
                <button
                  className="text-xs px-3 py-1 flex text-slate-600 bg-slate-200 ml-2 rounded shadow-sm disabled:no-pointer-event hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
                  onClick={() =>
                    dispatch(
                      toggleEvaluationModal({
                        open: true,
                        initialProps: {
                          evaluation: evaluation,
                          setEvaluation: setEvaluation,
                          calculateDaysInThePast: calculateDaysInThePast,
                          onEvaluationBtn: onEvaluationBtn,
                          showBtnLoading: false,
                          writtenContent: responseText,
                        },
                      }),
                    )
                  }
                  disabled={disableScoringButton}
                >
                  <div className="mt-0.5 mr-1.5">
                    <img src={grayflag} alt="" className="cursor-pointer min-w-2.5 min-h-2.5" />
                  </div>
                  <div className="">Score</div>
                </button>
              </Tooltip>
            )}
          </div>
        </div>

        <div
          className="bg-slate-100 justify-between py-2.5 text-action z-[1] absolute text-xs gap-2 flex items-center px-4 aboslute translate-y-0 left-0 right-0 bottom-0 duration-150"
          css={[isResponseError && tw`translate-y-full`]}
        >
          Vultron could not find any relevant content to generate a response. Please upload more content or lower the
          sensitivity and try again.
          <button onClick={() => dispatch(setResponseErrorRequirementIds({ add: false, data: [requirement.id] }))}>
            <X size={16} />
          </button>
        </div>
      </div>
      <div
        className="pr-2 pl-4 items-center py-0 min-h-0 h-0 duration-150 border-x border-transparent flex gap-2 overflow-hidden"
        css={[reviseActive && tw`py-3 min-h-[42px] border-gray-lightest border-b`, isRevising && tw`bg-slate-50`]}
      >
        <input
          readOnly={isRevising}
          disabled={isRevising}
          ref={reviseRef}
          value={reviseFeedback}
          onChange={(e) => setReviseFeedback(e.target.value)}
          className="flex-1 resize-none outline-none text-xs py-1 disabled:bg-slate-50"
          placeholder={isRevising ? `This could take up to ${revisionTime}...` : "Enter revisions to implement..."}
          onKeyDown={(e) => {
            if (e.key === "Enter" && canRevise && internalContractId) {
              setReviseFeedback("");
              reviseText({
                project_id: internalContractId,
                requirement: requirementContent,
                previous_response: selectedText || "",
                user_feedback: reviseFeedback,
                win_themes: winThemes?.map(({ content }) => content) || [],
              });
            }
          }}
        />
        <div className="flex gap-1">
          <button
            onClick={() => {
              if (!canRevise || !internalContractId) return;
              setReviseFeedback("");
              reviseText({
                project_id: internalContractId,
                requirement: requirementContent,
                previous_response: selectedText || "",
                user_feedback: reviseFeedback,
                win_themes: winThemes?.map(({ content }) => content) || [],
              });
            }}
            disabled={!canRevise && !isRevising}
            className="bg-action relative text-sm cursor-pointer flex items-center justify-center text-white w-5 min-w-[20px] h-5 duration-150 rounded-full hover:bg-action-hover disabled:bg-slate-200 disabled:text-slate-400 disabled:cursor-default"
            css={[isRevising && tw`pointer-events-none`]}
          >
            {isRevising ? <SpinnerCircle className="h-3.5 w-3.5" /> : <ArrowUp size={14} />}
          </button>
          <button
            onClick={() => {
              setReviseActive(false);
              setReviseFeedback("");
            }}
            disabled={isRevising}
            className="bg-slate-300 text-sm cursor-pointer flex items-center justify-center text-[16px] text-slate-800 w-5 min-w-[20px] h-5 duration-150 rounded-full hover:bg-slate-200 disabled:bg-slate-200 disabled:text-slate-400 disabled:cursor-default"
          >
            <ChevronUp size={14} />
          </button>
        </div>
      </div>
      <div
        id={`${complianceMatrixRow.requirement.id}-requirement-response`}
        className="selected-ai text-xs relative border-x border-zinc-200 bg-white w-full cursor-text"
      >
        <Selections
          id={`${complianceMatrixRow.requirement.id}-requirement-response`}
          containerProps={{ css: tw`!rounded-md -left-0.5 -right-0.5 -top-0.5 -bottom-0.5` }}
          nameProps={{ css: tw`top-0.5 left-0.5 right-auto` }}
          hasActiveOthers={(hasOthers) => hasOthers !== locked && setLocked(hasOthers)}
        />
        {shouldShowLoader && (
          <div className="z-[1] flex justify-center items-center absolute inset-x-0 inset-y-0 bg-[rgba(255,255,255,0.2)] backdrop-blur-sm">
            <div className="flex flex-col gap-2 items-center">
              <img className="w-[130px] h-[130px]" src={ProposalSectionLoading} alt="" />
              <div className="text-center text-gray-darkest">
                <div className="text-sm font-medium">
                  {isGenerating ? loadingMsg : "Response in queue"}
                  {!!isGenerating && <span className="loading-ellipsis" />}
                </div>
                <div className="text-xs mt-1 text-slate-500">
                  {isGenerating
                    ? `This may take up to ${
                        isRedTeam ? redTeamResponseTime : normalResponseTime
                      }. Please stay on this screen`
                    : `Position in queue: ${positionInQueue + 1}`}
                </div>
              </div>
            </div>
          </div>
        )}
        <RequirementEditor
          editor={requirementEditor}
          internalContractId={internalContractId || ""}
          referenceId={volumeId}
          requirementId={complianceMatrixRow.requirement.id}
          threadIds={complianceMatrixRow.thread_ids}
          sectionId={complianceMatrixRow.proposal_reference.section_id || ""}
        />
      </div>
      <ResponseSources responseSources={complianceMatrixRow.response_sources} />
    </div>
  );
};

export default RequirementResponse;

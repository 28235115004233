/** @jsxImportSource @emotion/react */

import type { PropsWithChildren } from "react";
import type { NavLinkProps } from "react-router-dom";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";

interface Props extends NavLinkProps {}

const BasicSidebarItem = ({ children, ...props }: PropsWithChildren<Props>) => {
  return (
    <NavLink {...props}>
      {({ isActive }) => (
        <div
          className="flex capitalize items-center p-2.5 min-w-36 text-sm rounded-md hover:bg-hover-default h-11"
          css={[isActive && tw`bg-[#F6F6F6] text-black`]}
        >
          {children}
        </div>
      )}
    </NavLink>
  );
};

export default BasicSidebarItem;

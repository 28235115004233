import CoreBlock from "components/molecules/ai-assistant-blocks/core-block";
import VultronBlock from "components/molecules/ai-assistant-blocks/vultron-block";
import type { AIAssistantBlock } from "utils/yjs-configs/ai-assistant/schema";
import { AssistantBlockType } from "utils/yjs-configs/ai-assistant/schema";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { WORKFLOW_OPTIONS } from "./constants";
import AIAssistantInput from "./ai-assistant-input/AIAssistantInput";
import ChecklistSubmit from "./UserInputs/checklist/ChecklistSubmit";
import ModularFileUpload from "./UserInputs/modular-file-upload/ModularFileUpload";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import type { InputFieldType } from "types/Assistants/types";
import DynamicInput from "./UserInputs/dynamic-input/DynamicInput";

export const renderBlock = (block: ToImmutable<AIAssistantBlock>) => {
  switch (block.type) {
    case AssistantBlockType.CoreBlock:
      return <CoreBlock key={block.id} block={block} />;
    case AssistantBlockType.VultronBlock:
      return <VultronBlock key={block.id} block={block} />;

    default:
      return <div />;
  }
};

export const findWorkflowLabelFromSubtype = (subtype: string) => {
  const workflow = WORKFLOW_OPTIONS.find((workflow) => workflow.subType === subtype);
  return workflow?.label || "";
};
interface RenderInputComponentProps {
  inputType: InputFieldType;
  globalAssistantWorkflows: boolean;
  onFileUpload: (files: File[]) => void;
}

export function renderInputComponent({ inputType, globalAssistantWorkflows, onFileUpload }: RenderInputComponentProps) {
  if (!globalAssistantWorkflows) {
    return <AIAssistantInput />;
  }

  switch (inputType) {
    case INPUT_FIELD_OPTIONS.checklistGroup:
    case INPUT_FIELD_OPTIONS.checklist:
      return <ChecklistSubmit />;
    case INPUT_FIELD_OPTIONS.dynamic:
      return <DynamicInput />;
    case INPUT_FIELD_OPTIONS.string:
      return <AIAssistantInput />;
    case INPUT_FIELD_OPTIONS.contentLibrary:
    case INPUT_FIELD_OPTIONS.allFiles:
    case INPUT_FIELD_OPTIONS.files:
      return <ModularFileUpload inputType={inputType} />;
    default:
      return <AIAssistantInput />;
  }
}

import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import History from "@tiptap/extension-history";
import type { EditorOptions } from "@tiptap/react";
import { useEditor } from "@tiptap/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CustomHardBreak } from "./CustomHardBreak";
import { CommentsMark } from "components/Comments";
import type { YJSProvider } from "YJSProvider/YJSProvider";
import { useCommentOperations } from "api/comments/useCommentOperations";
import PasteExtension from "components/yjs-editor/components/extensions/PasteExtension";
import { setHighlightedText } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch } from "store/storeTypes";

const getExtensions = ({
  placeholder,
  removeThread,
  restoreThread,
  provider,
}: {
  provider: YJSProvider;
  placeholder?: string;
  removeThread: (threadId: string) => void;
  restoreThread: (threadId: string) => void;
}) => [
  Document,
  Text,
  Paragraph,
  Placeholder.configure({
    placeholder,
    emptyNodeClass:
      "first:before:text-gray-400 first:before:float-left first:before:content-[attr(data-placeholder)] first:before:pointer-events-none first:before:h-0",
  }),
  CustomHardBreak,
  History,
  CommentsMark.configure({
    provider,
    removeThread,
    restoreThread,
  }),
  PasteExtension,
];

export type RequirementEditorOptions = Partial<EditorOptions> & {
  placeholder?: string;
  onChange?: (content: string, rawText: string) => void;
  provider?: YJSProvider;
};

export const useRequirementEditor = (
  _content: string,
  options: RequirementEditorOptions,
  isEditable?: boolean,
  internalContractId?: string,
  volumeId?: string,
) => {
  const isEditing = useRef(false);
  const editingInterval = useRef<NodeJS.Timeout | undefined>();
  const [selectedText, setSelectedText] = useState<string>("");
  const { onBlur, onChange, ...rest } = options || {};
  const dispatch = useAppDispatch();
  // Replace all \n with <br> to display new lines, when calling getText it will be replaced back to \n
  const content = useMemo(() => _content.replace(/\n/g, "<br>"), [_content]);
  const { deleteThreadMutation, restoreThreadMutation } = useCommentOperations(internalContractId!, volumeId);

  const removeThread = useCallback(
    (thread: string) => deleteThreadMutation.mutate({ thread_id: thread }),
    [deleteThreadMutation],
  );
  const restoreThread = useCallback(
    (thread: string) => restoreThreadMutation.mutate({ thread_id: thread }),
    [restoreThreadMutation],
  );

  const extensions = useMemo(
    () =>
      getExtensions({
        placeholder: options?.placeholder,
        removeThread,
        restoreThread,
        provider: options.provider!,
      }),
    [options?.placeholder, removeThread, restoreThread, options.provider],
  );

  const editor = useEditor({
    content,
    extensions,
    editorProps: {
      attributes: {
        class: "focus:outline-none min-h-[230px] requirement-response-tiptap-editor",
      },
    },
    onUpdate: ({ editor }) => {
      onChange?.(editor.getHTML(), editor.getText());
      isEditing.current = true;
      if (editingInterval.current) {
        clearTimeout(editingInterval.current);
      }
      editingInterval.current = setTimeout(() => {
        isEditing.current = false;
      }, 1000);
    },
    onSelectionUpdate: ({ editor }) => {
      if (!editor.isEditable) return;
      const selection = editor.state.selection;
      const { from, to } = selection;
      const text = !selection.empty ? editor.state.doc.textBetween(from, to, "\n") : "";
      setSelectedText(text);
      dispatch(setHighlightedText(text));
    },
    onBlur(args) {
      const { event: e } = args;
      const target = e.relatedTarget;

      if (
        target instanceof HTMLElement &&
        (target.classList.contains("ai-smartlight-menu") || target.classList.contains("comment-button"))
      )
        return;
      resetSelection();
      onBlur?.(args);
    },
    ...rest,
  });

  const resetSelection = useCallback(() => {
    editor?.commands.blur();
    editor?.commands.setTextSelection(editor.state.selection.from);
    setSelectedText("");
  }, [editor?.commands]);

  useEffect(() => {
    // Do not update the content if the editor is being edited
    if (!editor || isEditing.current) return;
    editor.commands.setContent(content);
  }, [content]);

  useEffect(() => {
    if (editor && editor.isEditable !== !!isEditable) {
      if (!isEditable) {
        editor.commands.blur();
        setSelectedText("");
      }
      editor.setEditable(!!isEditable);
    }
  }, [editor, isEditable]);

  return {
    editor,
    selectedText,
    resetSelection,
  };
};

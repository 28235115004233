import classNames from "classnames";

type Props = {
  className?: string;
};

export const MobileScreen = ({ className }: Props) => {
  return (
    <div
      className={classNames("h-screen flex flex-col items-center justify-center bg-[#f6f6f6] text-center", className)}
    >
      <div className="mx-auto w-full max-w-xs space-y-3 rounded-lg bg-white px-4 py-6 shadow-lg">
        <h3 className="text-lg font-semibold text-brand-dark">Window size too small</h3>
        <p className="text-gray-medium">
          Please zoom out with <strong>Ctrl</strong> and <strong>-</strong> <br />
          or increase your window size.
        </p>
      </div>
    </div>
  );
};

export default MobileScreen;

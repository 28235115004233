import { createSlice } from "@reduxjs/toolkit";

interface CommentsDrawerState {
  commentsDrawerOpen: boolean;
}

const initialState: CommentsDrawerState = {
  commentsDrawerOpen: false,
};

const commentsDrawerSlice = createSlice({
  name: "commentsDrawer",
  initialState,
  reducers: {
    toggleCommentsDrawer: (state, action: { payload: boolean }) => {
      state.commentsDrawerOpen = action.payload;
    },
  },
});

export const { toggleCommentsDrawer } = commentsDrawerSlice.actions;
export default commentsDrawerSlice.reducer;

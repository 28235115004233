/** @jsxImportSource @emotion/react */

import type { DropResult } from "react-beautiful-dnd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import tw from "twin.macro";
import { Empty } from "components/molecules/empty";
import { ArrowUp } from "lucide-react";
import Tooltip from "components/atoms/tooltip";
import loader from "Assets/loader.svg";
import Icon from "components/atoms/icons/Icon";
import { Checkbox } from "components/atoms/checkbox";
import { DocumentsMentionInput } from "components/molecules/documents-mention-input";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { getAllPromptFiles } from "api/api";
import type {
  DocumentInputContent,
  DocumentItem,
} from "components/molecules/documents-mention-input/DocumentsMentionInput";
import { useSearchParams } from "react-router-dom";
import type { ComplianceMatrixRowContentBody, Storage } from "components/copilot/CopilotSchemaTypes";
import { SourceType } from "components/copilot/CopilotSchemaTypes";
import type { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";
import { xor } from "lodash";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useObserveSseController } from "hook/useObserveSseController";
import { useNotification } from "context/notificationContext";
import { EventStreamContentType } from "@microsoft/fetch-event-source";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { useGenerateRecommendedAPIContent } from "components/copilot/Framework/hooks";
import IdeationContentCard from "./IdeationContentCard";
import { LOADING_MSGS } from "./constants";
import { setWritingPromptIdeationState } from "store/reducers/copilot/WritingPromptIdeationReducer";
import useRequirementOperations from "hook/useRequirementOperations";
import { useMutation } from "YJSProvider/createYJSContext";
import { findIndex, LiveList, moveItem } from "YJSProvider/LiveObjects";
import * as logger from "utils/log";

const GENERATED_CONTENT_DROPPABLE = "GENERATED_CONTENT";
const INITIAL_QUERY_STATE: DocumentInputContent = {
  raw: "",
  plainText: "",
  mentions: [],
};
const SELECTED_CONTENT_DROPPABLE = "SELECTED_CONTENT";

type Props = {
  liveComplianceMatrixRow?: ComplianceMatrixRow;
};

const WritingPromptIdeation = ({ liveComplianceMatrixRow }: Props) => {
  const { isIdeationLoading } = useAppSelector((root) => root.writingPromptIdeation);

  const { open } = useAppSelector((store) => store.modals.writingPromptIdeationModal);

  const {
    requirement,
    generated_content_ideas_v2: generatedContent,
    selected_content_v2: selectedContent,
  } = liveComplianceMatrixRow || {};
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [documents, setDocuments] = useState<DocumentItem[]>([]);
  const [sourceTypes, setSourceTypes] = useState<SourceType[]>([SourceType.AI, SourceType.ContentDrive]);
  const [searchParams] = useSearchParams();
  const { appendGeneratedContentIdeas } = useRequirementOperations();
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
  const [queryState, setQueryState] = useState<DocumentInputContent>(INITIAL_QUERY_STATE);
  const dispatch = useAppDispatch();
  const { setToast } = useNotification();
  const [loadingMsg, setLoadingMsg] = useState(LOADING_MSGS[0]);
  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  useAnimateLoadingMsg(isIdeationLoading, 4000, LOADING_MSGS, (msg) => setLoadingMsg(msg));

  useLayoutEffect(() => {
    if (autoScroll) {
      setTimeout(() => {
        scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight + 21);
        setAutoScroll(true);
      }, 0);
    }
  }, [autoScroll, generatedContent]);

  const { generateRecommendedContent, abortConnection } = useGenerateRecommendedAPIContent({
    onmessage(msg) {
      if (msg.data?.length) {
        try {
          const content = JSON.parse(msg.data);
          if (requirement?.id) appendGeneratedContentIdeas(requirement.id, [content]);
        } catch {
          // silently ignore
        }
      }
    },
    async onopen(response) {
      if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
        return; // everything's good
      } else if (response.status === 204) {
        setToast.warning({
          title: "Unable to generate content",
          msg: "Vultron could not find any relevant content to generate ideas. Please import more content and try again.",
          duration: 10000,
        });
      } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
        setToast.error({
          title: "Unable to generate content",
          msg: "We were unable to generate ideas due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
        dispatch(setWritingPromptIdeationState({ isIdeationLoading: false }));
        logger.error("Writing direction ideation failed", response);
        throw new Error("Writing direction ideation failed");
      }
    },
    onclose() {
      dispatch(setWritingPromptIdeationState({ isIdeationLoading: false }));
    },
    onerror(err) {
      setToast.error({
        title: "Unable to generate ideas",
        msg: "We were unable to generate ideas due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
      });
      dispatch(setWritingPromptIdeationState({ isIdeationLoading: false }));

      if (err instanceof Error) {
        logger.error(err);
        throw err;
      }
    },
  });

  useEffect(() => {
    if (open && internalContractId) {
      getAllPromptFiles(internalContractId, "&bucket_only=False")
        .then((res) => {
          const transformedData = res?.data.map((item: { name?: string }) => ({
            ...item,
            display: item.name,
          }));
          setDocuments(transformedData);
        })
        .catch(() => {});
    }

    if (!open) abortConnection();
  }, [abortConnection, internalContractId, open]);

  useObserveSseController(abortConnection, () => dispatch(setWritingPromptIdeationState({ isIdeationLoading: false })));

  const generateRecommendedAPIContent = useCallback(() => {
    if (isIdeationLoading || !queryState.plainText?.trim() || !internalContractId) return;

    setQueryState(INITIAL_QUERY_STATE);
    setAutoScroll(true);
    setLoadingMsg(LOADING_MSGS[0]);
    dispatch(setWritingPromptIdeationState({ isIdeationLoading: true }));

    generateRecommendedContent({
      user_query: queryState.plainText,
      project_id: internalContractId,
      source_types: sourceTypes,
      past_ideas: generatedContent?.map(({ content }) => content) || [],
      file_id_filters: queryState.mentions?.map(({ id }) => id) || [],
    });
  }, [
    dispatch,
    generateRecommendedContent,
    generatedContent,
    internalContractId,
    isIdeationLoading,
    queryState.mentions,
    queryState.plainText,
    sourceTypes,
  ]);

  const dragEnd = useMutation(
    ({ storage }, { destination, source, type }: DropResult) => {
      if (type !== "IDEATION_DRAWER_CONTENT") return;
      const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;
      if (!matrix?.length) return;
      const liveRequirementIndex = findIndex(matrix, (row) => row.get("requirement")?.get("id") === requirement?.id);

      if (liveRequirementIndex === -1) return;
      const liveRequirement = matrix.get(liveRequirementIndex);
      let generatedContent: LiveList<ComplianceMatrixRowContentBody> | undefined =
        liveRequirement?.get("generated_content_ideas_v2");
      let selectedContent: LiveList<ComplianceMatrixRowContentBody> | undefined =
        liveRequirement?.get("selected_content_v2");

      if (!(generatedContent instanceof LiveList))
        liveRequirement?.set("generated_content_ideas_v2", new LiveList(generatedContent || []));
      if (!(selectedContent instanceof LiveList))
        liveRequirement?.set("selected_content_v2", new LiveList(selectedContent || []));

      generatedContent = liveRequirement?.get("generated_content_ideas_v2");
      selectedContent = liveRequirement?.get("selected_content_v2");

      if (
        source?.droppableId === GENERATED_CONTENT_DROPPABLE &&
        destination?.droppableId === GENERATED_CONTENT_DROPPABLE
      ) {
        const reqIdea = liveRequirement?.get("generated_content_ideas_v2");
        if (reqIdea) moveItem(reqIdea, source?.index, destination?.index);
      }
      if (
        source?.droppableId === SELECTED_CONTENT_DROPPABLE &&
        destination?.droppableId === SELECTED_CONTENT_DROPPABLE
      ) {
        const reqContent = liveRequirement?.get("selected_content_v2");
        if (reqContent) moveItem(reqContent, source?.index, destination?.index);
      }
      if (
        source?.droppableId === GENERATED_CONTENT_DROPPABLE &&
        destination?.droppableId === SELECTED_CONTENT_DROPPABLE
      ) {
        const activeDraggableContent = generatedContent?.get(source?.index);
        if (!activeDraggableContent) return;
        generatedContent?.delete(source?.index);
        if (!selectedContent) {
          liveRequirement?.set("selected_content_v2", new LiveList([activeDraggableContent]));
        } else selectedContent?.insert(destination?.index, [activeDraggableContent]);
      }
      if (
        source?.droppableId === SELECTED_CONTENT_DROPPABLE &&
        destination?.droppableId === GENERATED_CONTENT_DROPPABLE
      ) {
        const activeDraggableContent = selectedContent?.get(source?.index);
        if (!activeDraggableContent) return;
        selectedContent?.delete(source?.index);
        if (!generatedContent) {
          liveRequirement?.set("generated_content_ideas_v2", new LiveList([activeDraggableContent]));
        } else generatedContent?.insert(destination?.index, [activeDraggableContent]);
      }
    },
    [requirement],
  );

  return (
    <DragDropContext onDragEnd={dragEnd}>
      <div ref={containerRef} className="flex gap-3 h-full w-full">
        <div className="relative flex-1 rounded-md border border-gray-lightest shadow h-full flex flex-col">
          <div className="text-xs text-slate-500 bg-gray-200 rounded-t py-1 px-2 absolute -top-6 left-2">
            Generated Ideas
          </div>
          <Droppable droppableId={GENERATED_CONTENT_DROPPABLE} type="IDEATION_DRAWER_CONTENT">
            {(provided, snapshot) => (
              <div
                className="p-3 pb-0 overflow-y-auto h-full relative"
                {...provided.droppableProps}
                ref={(ref) => {
                  provided.innerRef(ref);
                  scrollRef.current = ref;
                }}
                onScroll={(e) => {
                  const shouldAutoScroll =
                    e.currentTarget.scrollTop + 5 >= e.currentTarget.scrollHeight - e.currentTarget.clientHeight;
                  if (shouldAutoScroll !== autoScroll) setAutoScroll(shouldAutoScroll);
                }}
                css={[snapshot.isDraggingOver && tw`bg-action-lightest`]}
              >
                {generatedContent?.map((contentIdea, i) => (
                  <Draggable
                    key={`${contentIdea.content}_${i}`}
                    index={i}
                    draggableId={`${GENERATED_CONTENT_DROPPABLE}-${i}`}
                  >
                    {(dragProvided, snapshot) => (
                      <IdeationContentCard
                        key={`${contentIdea.content}_${i}`}
                        item={contentIdea}
                        provided={dragProvided}
                        snapshot={snapshot}
                        type="generated"
                        itemId={requirement?.id}
                        index={i}
                        containerRef={containerRef.current}
                      />
                    )}
                  </Draggable>
                ))}
                {isIdeationLoading && (
                  <div
                    className="flex flex-col justify-center items-center w-[60%] mx-auto"
                    css={[!generatedContent?.length && tw`h-full`]}
                  >
                    <img src={loader} alt="icon" className="h-12 w-12" />
                    {!generatedContent?.length && (
                      <div className="text-sm text-[#96A4AF]">
                        {loadingMsg}
                        <span className="loading-ellipsis" />
                      </div>
                    )}
                  </div>
                )}
                {!isIdeationLoading && !generatedContent?.length && (
                  <Empty
                    containerProps={{
                      style: {
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      },
                    }}
                    className="-mr-2"
                    heading="Generate writing direction ideas"
                    title="Add a topic below to get started"
                  />
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="flex flex-col p-2 shadow-[0px_-1px_2px_rgba(0,0,0,0.07)]">
            <div className="relative" css={{ "& > .documentMentionInput": { paddingBottom: 24 } }}>
              <DocumentsMentionInput
                height={70}
                documents={documents}
                content={queryState}
                setContent={setQueryState}
                onEnter={() => {
                  generateRecommendedAPIContent();
                }}
                submitDisabled={!queryState.plainText?.trim() || !sourceTypes.length || isIdeationLoading}
              />
              <button
                onClick={() => {
                  generateRecommendedAPIContent();
                }}
                name="Check"
                disabled={!queryState.plainText?.trim() || !sourceTypes.length || isIdeationLoading}
                className="bg-action absolute right-1 bottom-1 text-sm cursor-pointer flex items-center justify-center text-white w-5 min-w-[20px] h-5 duration-150 rounded-full hover:bg-action-hover disabled:bg-gray-200 disabled:text-slate-400 disabled:cursor-default"
              >
                <ArrowUp size={14} />
              </button>
              <div className="absolute gap-3 bottom-0 left-0 flex items-center justify-between text-xs pt-1 pl-2 pb-1.5">
                <div className="text-gray-600 font-medium">Sources:</div>
                <div className="flex gap-3 text-gray-600 font-medium">
                  <div className="flex items-center gap-1">
                    <Checkbox
                      size="sm"
                      onCheck={() => setSourceTypes(xor(sourceTypes, [SourceType.ContentDrive]))}
                      checked={sourceTypes.includes(SourceType.ContentDrive)}
                    />
                    Content Library
                    <Tooltip
                      delayDuration={200}
                      content={
                        <div className="text-center text-stone-900 text-xs">
                          <span className="font-normal">Use </span>
                          <span className="font-semibold">content library </span>
                          <span className="font-normal">
                            to generate <br /> ideas to write about
                          </span>
                        </div>
                      }
                    >
                      <Icon name="InfoCircle" className="w-4 min-w-[16px] h-4" />
                    </Tooltip>
                  </div>
                  <div className="flex items-center gap-1">
                    <Checkbox
                      size="sm"
                      onCheck={() => setSourceTypes(xor(sourceTypes, [SourceType.AI]))}
                      checked={sourceTypes.includes(SourceType.AI)}
                    />
                    Vultron AI
                    <Tooltip
                      delayDuration={200}
                      content={
                        <div className="text-center text-stone-900 text-xs">
                          <span className="font-semibold">Vultron </span>
                          <span className="font-normal">
                            will generate new
                            <br /> ideas to write about
                          </span>
                        </div>
                      }
                    >
                      <Icon name="InfoCircle" className="w-4 min-w-[16px] h-4" />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 rounded-md border border-gray-lightest shadow h-full flex flex-col">
          <div className="text-xs text-slate-500 bg-gray-200 rounded-t py-1 px-2 absolute -top-6 left-2">
            Selected Ideas
          </div>
          <Droppable droppableId={SELECTED_CONTENT_DROPPABLE} type="IDEATION_DRAWER_CONTENT">
            {(provided, snapshot) => (
              <div
                className="p-3 h-full overflow-y-auto"
                {...provided.droppableProps}
                ref={provided.innerRef}
                css={[snapshot.isDraggingOver && tw`bg-action-lightest`]}
              >
                {selectedContent?.map((selectedContentItem, i) => (
                  <Draggable
                    key={`${selectedContentItem.content}_${i}`}
                    index={i}
                    draggableId={`${SELECTED_CONTENT_DROPPABLE}-${i}`}
                  >
                    {(dragProvided, snapshot) => (
                      <IdeationContentCard
                        key={`${selectedContentItem.content}_${i}`}
                        item={selectedContentItem}
                        provided={dragProvided}
                        snapshot={snapshot}
                        type="selected"
                        itemId={requirement?.id}
                        index={i}
                        containerRef={containerRef.current}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};

export default WritingPromptIdeation;

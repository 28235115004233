/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
//
import logo from "Assets/svgs/vultron-logo.svg";
import group from "../../Assets/Group 754.svg";
import ClickAwayListener from "../../helpers/ClickAwayListener";
import { useLocalStorage } from "hook/useLocalStorage";
import { getSortByTransactionDate } from "utils/helpers";
import { Spinner } from "utils/icons";
import { useNavigate } from "react-router-dom/dist";
import { AvatarGroup } from "components/molecules/avatar-group";
import "twin.macro";
import { useNotification } from "context/notificationContext";
import tw from "twin.macro";
import Icon from "components/atoms/icons/Icon";
import { useAppDispatch } from "store/storeTypes";
import { setDocumentNavHistory, setMediaNavHistory } from "store/reducers/driveReducerSlice";
import NewFeatureBannerController from "./NewFeatureBanner";
import { FileText, Folder, Settings, Sparkles } from "lucide-react";
import { pluralizeWord } from "utils/string";
import { useAppSelector } from "store/storeTypes";
import { useFlags } from "hook/useFlags";
import { toggleCreateProjectModal } from "store/reducers/modalsSlice";
import { CREATE_NEW_PROJECT } from "const-values/testIds";

const aiAssistantNavItem = {
  title: "Assistant",
  path: "/dashboard/ai-assistant",
  icon: Sparkles,
};

const projectsNavItem = {
  title: "Projects",
  path: "/dashboard/contracts",
  icon: FileText,
};

const useSideLinks = () => {
  const flags = useFlags();

  return [
    ...(flags.isAssistantLandingPage ? [aiAssistantNavItem, projectsNavItem] : [projectsNavItem, aiAssistantNavItem]),
    {
      title: "Content Library",
      path: "/dashboard/library",
      icon: Folder,
    },
    {
      title: "Settings",
      path: "/dashboard/settings",
      icon: Settings,
    },
  ];
};

// ------------ Sidebar for dashboard ------------------
const SideBar = () => {
  const [workSpaceModal, setWorkSpaceModal] = useState(false);
  const { localValue, setLocalVal } = useLocalStorage("vultron_workspace_id", "");
  const { myWorkSpaces, workspaceMembers, isLoadingWorkSpaces, currentWorkspace } = useAppSelector(
    (store) => store.auth,
  );
  const { setToast } = useNotification();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const sideLinks = useSideLinks();

  // select workspace to loggedIn
  const selectWorkSpace = (id: string) => {
    if (id?.length > 0 && id === localValue) return;
    setLocalVal(id);
    setToast.warning({ msg: "Workspace changed" });
    setWorkSpaceModal(false);
    setTimeout(() => {
      navigate("/dashboard");
      window.location.reload();
    }, 250);
  };

  return (
    <div className="bg-white border-0 scrollbar-none border-r-[2px] border-gray-100 h-full flex flex-col relative">
      {/*logo */}
      <div className="p-5">
        <img src={logo} alt="logo" className="max-w-none" />
      </div>
      {/* Navigation Menu */}
      <div className="mt-5 text-sm overflow-y-auto h-full flex-grow max-h-full pb-[200px]">
        {sideLinks?.map(({ path, title, icon: Icon, ...props }, i) => (
          <NavLink key={i} to={path} {...props} className="text-sm">
            {({ isActive }) => (
              <span
                css={[isActive ? tw`bg-[#F6F6F6]  text-gray-darkest` : tw`hover:bg-hover-default text-[#5b6b7a]`]}
                className="flex items-center gap-2.5 mb-1 px-4 py-3 mx-2 rounded"
                onClick={() => {
                  if (path === "/dashboard/library") {
                    dispatch(setDocumentNavHistory([]));
                    dispatch(setMediaNavHistory([]));
                  }
                }}
                data-testid={`sidebar-${title}`}
              >
                <div className="flex justify-center items-center w-[20px] h-[18px]">
                  <Icon
                    size={18}
                    className="text-[#5b6b7a] w-[18px] h-[18px]"
                    css={[isActive && tw`text-gray-darkest`, path === "/dashboard/help" && tw`stroke-[0.3] p-[1px]`]}
                  />
                </div>
                <span>{title}</span>
              </span>
            )}
          </NavLink>
        ))}
        <span
          onClick={() => dispatch(toggleCreateProjectModal({ open: true }))}
          className="text-gray-darkest flex flex-grow items-center hover:border-gray-300 border border-gray-200 bg-gray-100 cursor-pointer font-medium text-sm px-4 py-3 mx-2 rounded gap-2.5 mt-4"
          data-testid={CREATE_NEW_PROJECT.newProject}
        >
          <div className="w-[20px] flex justify-center items-center">
            <Icon name="Plus" className="w-[20px] h-[20px]" />
          </div>
          <span>New Project</span>
        </span>
      </div>
      {/* Sidebar bottom area */}
      <div className="w-full relative">
        <NewFeatureBannerController />
        {isLoadingWorkSpaces ? (
          <div className="flex p-5 bg-[#f7f7f7] w-full items-center justify-center">
            <Spinner classes="!text-black" width={27} height={35} />
          </div>
        ) : (
          <div className="p-5">
            <ClickAwayListener onClickAway={() => setWorkSpaceModal(false)} className="relative">
              <div
                role="button"
                onClick={() => setWorkSpaceModal(!workSpaceModal)}
                className="mt-auto w-full select-none cursor-pointer"
              >
                <div className="mb-2 flex items-center justify-start">
                  <p className="font-semibold text-sm mr-2">{currentWorkspace?.name || "No Workspace"}</p>
                  <span>
                    <img className="" src={group} alt="" width={12} />
                  </span>
                </div>
                <div className="flex gap-3 items-center justify-start">
                  <AvatarGroup
                    size={24}
                    maxCount={5}
                    avatars={workspaceMembers?.map((user) => ({
                      id: user?.username,
                      name: user?.username,
                      size: 24,
                      tooltip: true,
                    }))}
                  />
                  <span className="text-gray-text text-xs whitespace-nowrap">
                    {currentWorkspace?.number_of_users || 0}{" "}
                    {pluralizeWord(currentWorkspace?.number_of_users || 0, "member")}
                  </span>
                </div>
              </div>
              {/* ********* Workspace Modal **********  */}
              {workSpaceModal ? (
                <div className="absolute border rounded-lg shadow-lg bottom-[100%] left-0 bg-white max-w-[297px] min-w-[240px] z-[222] max-h-[400px] overflow-y-auto">
                  {/* create new */}
                  <NavLink
                    to="/dashboard/create-workspace"
                    state={{
                      from: { pathname },
                    }}
                    onClick={() => setWorkSpaceModal(false)}
                    className="px-4 flex items-center py-2 border-b cursor-pointer select-none sticky top-0 bg-white"
                    role="button"
                  >
                    <Icon name="Plus" className="w-[20px] h-[20px]" />
                    <p className="text-gray-500 text-[14px] px-2">Create new workspace</p>
                  </NavLink>
                  {/* all workspace list */}
                  {myWorkSpaces?.workspaces &&
                    Object.values(myWorkSpaces?.workspaces)
                      ?.sort((a, b) => getSortByTransactionDate(a, b, "created_at"))
                      .map((workSpace, i) => (
                        <div
                          key={i}
                          onClick={() => selectWorkSpace(workSpace?.id)}
                          role="button"
                          className={`px-4 py-1 w-full cursor-pointer select-none hover:bg-[#f7f7f7] z-20 ${
                            myWorkSpaces.workspaces?.length === i + 1 ? "" : " border-b"
                          } ${workSpace?.id?.length > 0 && workSpace?.id === localValue ? " bg-[#f5f5f5] " : ""}`}
                        >
                          <div className="mb-1 flex items-center">
                            <p className="font-semibold text-[14px]">{workSpace?.name}</p>
                          </div>
                          <div className="flex pb-1 gap-2 items-center">
                            <div className="flex items-center -space-x-2">
                              <AvatarGroup
                                maxCount={6}
                                avatars={[...new Array(workSpace?.number_of_users || 0)]?.map((v, ind) => ({
                                  id: workSpace?.users[ind],
                                  name: workSpace?.users[ind],
                                  className: "!text-[13px]",
                                  alt: workSpace?.users[ind],
                                  size: 26,
                                }))}
                                size={26}
                              />
                            </div>
                            <span className="text-gray-text text-xs">
                              {workSpace?.number_of_users} {pluralizeWord(workSpace?.number_of_users, "member")}
                            </span>
                          </div>
                        </div>
                      ))}
                </div>
              ) : null}
              {/* ************************ End ************* */}
            </ClickAwayListener>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;

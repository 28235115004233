import { isEqual } from "lodash";
import type { NodeViewProps } from "@tiptap/react";
import { NodeViewContent, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { GripHorizontal } from "lucide-react";
import { TableCell } from "@tiptap/extension-table-cell";
import Popover from "components/atoms/popover";
import { TableOptionsContent } from "./TableOptions";
import { useState } from "react";

const CustomTableCellComponent: React.FC<NodeViewProps> = ({ editor }) => {
  const isEditable = editor.isEditable;
  const [showOptions, setShowOptions] = useState(false);

  const cellActions = !isEditable ? null : (
    <div className="absolute top-0 left-0 w-full h-full">
      <Popover
        open={showOptions}
        onOpenChange={setShowOptions}
        content={<TableOptionsContent editor={editor} />}
        contentProps={{ align: "start", side: "right", sideOffset: 5 }}
      >
        <button
          onClick={() => setShowOptions(true)}
          className="absolute -left-3 top-1/2 -translate-y-1/2 w-6 h-6 bg-white rounded-full shadow-sm border border-gray-200 opacity-0 group-hover:opacity-100 hover:bg-gray-50 transition-opacity"
        >
          <GripHorizontal size={14} className="m-auto text-gray-600" />
        </button>
      </Popover>
    </div>
  );

  return (
    <NodeViewWrapper as="div" className="relative group">
      {cellActions}
      <NodeViewContent as="div" className="p-3" />
    </NodeViewWrapper>
  );
};

export const CustomTableCell = TableCell.extend({
  addNodeView() {
    return ReactNodeViewRenderer(CustomTableCellComponent, {
      update: ({ updateProps, oldNode, newNode }) => {
        if (isEqual(oldNode.attrs, newNode.attrs)) {
          return false;
        }
        updateProps();
        return true;
      },
      as: "td",
      className: "tiptap-table-cell",
    });
  },
});

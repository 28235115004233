/** @jsxImportSource @emotion/react */

import type { AxiosResponse } from "axios";
import { Button } from "components/editor/components";
import { ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import type { GeneratedDraft } from "store/reducers/extract/CurrentExtractionReducer";
import { getTemplateDetails } from "api/api";
import { motion } from "framer-motion";
import type { TemplateDetails } from "types/Templates";
import { transformGeneratedDraftToLiveblocks } from "./utils";
import { transformImmutableVolumesToYJS } from "components/copilot/Framework/utils";
import { useAppSelector } from "store/storeTypes";
import { useTemplates } from "components/copilot/Framework/hooks";
import { xor } from "lodash";
import { ChevronDown, ChevronUp, List } from "lucide-react";
import { useCallback, useMemo, useRef, useState } from "react";
import LoadingDisplay from "components/molecules/loading-display";
import useExtractionOperations from "hook/useExtractionOperations";
import tw from "twin.macro";
import { useTrackUserMetric } from "utils/metrics";
import { useFlags } from "hook/useFlags";
import TemplateTableRow from "./TemplateTableRow";
import { isInstantDraftStarted } from "../utils";
import { YJS_OPERATIONS } from "const-values/yjs";
import { dateFormatter } from "utils/timerUtils";
import * as logger from "utils/log";

const variants = {
  hidden: {
    opacity: 0,
    y: "0.65rem",
  },
  visible: {
    opacity: 1,
    y: "0rem",
  },
};

enum TemplateSelectionMode {
  Recommended = 1,
  Library,
}

const TemplateSelection = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { templates } = useTemplates();
  const flags = useFlags();
  const [selectingTemplateIds, setSelectingTemplateIds] = useState<string[]>([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<string[]>([]);
  const [showTemplateLibrary, setShowTemplateLibrary] = useState(false);
  const [templateSelectionMode, setTemplateSelectionMode] = useState(TemplateSelectionMode.Recommended);
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const generatedTemplates = useAppSelector((store) => store.currentExtractionState.templates);
  const isExtractingDocument = useAppSelector((store) => store.currentExtractionState.isExtractingDocument);
  const {
    addAttribution,
    appendExtractionDrafts,
    deleteDraft,
    appendExtractionDraftsToContextBank,
    deleteContextualDraft,
  } = useExtractionOperations();
  const trackUserEvent = useTrackUserMetric();

  const onSelectTemplate = useCallback(
    async (templateId: string) => {
      if (selectingTemplateIds.includes(templateId) || !extraction?.id) return;
      setSelectingTemplateIds((prev) => xor(prev, [templateId]));

      try {
        const { data }: AxiosResponse<TemplateDetails> = await getTemplateDetails(templateId);
        addAttribution(YJS_OPERATIONS.EXTRACTION.SELECT_LIBRARY_TEMPLATE);
        if (data) {
          const transformedLiveVolumes = transformImmutableVolumesToYJS(data.data?.volumes || []);

          appendExtractionDrafts(extraction.id, transformedLiveVolumes);
          setSelectedTemplateIds((prev) => [...prev, templateId]);
        } else {
          throw new Error("No data retrieved from getTemplateDetails");
        }
      } catch (err) {
        logger.error(err as Error);
      } finally {
        setSelectingTemplateIds((prev) => xor(prev, [templateId]));
      }
    },
    [addAttribution, appendExtractionDrafts, extraction?.id, selectingTemplateIds],
  );

  const onSelectGeneratedDraft = useCallback(
    (draft: GeneratedDraft, isContextual?: boolean) => {
      if (selectingTemplateIds.includes(draft.id) || !extraction?.id) return;
      setSelectingTemplateIds((prev) => xor(prev, [draft.id]));
      trackUserEvent("Generate: Template Selected", {
        analysis_id: extraction?.id,
        solicitation_type: extraction?.solicitation_type,
      });

      const hasDraft = isContextual
        ? extraction.framework.context_bank?.some((existingDraft) => draft.id === existingDraft.id)
        : extraction.framework.volumes.some((existingDraft) => draft.id === existingDraft.id);

      if (!hasDraft) {
        const transformedLiveVolumes = transformGeneratedDraftToLiveblocks(draft);
        if (isContextual) appendExtractionDraftsToContextBank(extraction.id, [transformedLiveVolumes]);
        if (!isContextual) appendExtractionDrafts(extraction.id, [transformedLiveVolumes]);
      }

      setSelectingTemplateIds((prev) => xor(prev, [draft.id]));
    },
    [
      appendExtractionDrafts,
      appendExtractionDraftsToContextBank,
      extraction?.framework.context_bank,
      extraction?.framework.volumes,
      extraction?.id,
      selectingTemplateIds,
      extraction?.solicitation_type,
      trackUserEvent,
    ],
  );

  const isReadOnly =
    extraction?.status === ExtractionStatus.Completed || isInstantDraftStarted(extraction?.instantDraftConfig?.status);

  const handleIdentifiedTemplateSelection = useCallback(
    (draft: GeneratedDraft, hasDraft: boolean) => {
      if (!hasDraft && !isReadOnly) {
        onSelectGeneratedDraft(draft);
        addAttribution(YJS_OPERATIONS.EXTRACTION.SELECT_TEMPLATE);
      } else if (hasDraft && !isReadOnly && extraction?.id) {
        deleteDraft(extraction.id, draft.id);
        addAttribution(YJS_OPERATIONS.EXTRACTION.DESELECT_TEMPLATE);
      }
    },
    [deleteDraft, extraction?.id, isReadOnly, onSelectGeneratedDraft, addAttribution],
  );

  const handleIdentifiedContextualTemplateSelection = useCallback(
    (draft: GeneratedDraft, hasDraft: boolean) => {
      if (!hasDraft && !isReadOnly) {
        onSelectGeneratedDraft(draft, true);
        addAttribution(YJS_OPERATIONS.EXTRACTION.SELECT_CONTEXT_TEMPLATE);
      } else if (hasDraft && !isReadOnly && extraction?.id) {
        deleteContextualDraft(extraction.id, draft.id);
        addAttribution(YJS_OPERATIONS.EXTRACTION.DESELECT_CONTEXT_TEMPLATE);
      }
    },
    [addAttribution, deleteContextualDraft, extraction?.id, isReadOnly, onSelectGeneratedDraft],
  );

  const hasGeneratedTemplates = useMemo(
    () => generatedTemplates.some((template) => template.generated),
    [generatedTemplates],
  );

  const nonContextTemplates = useMemo(
    () => generatedTemplates.filter(({ context_template }) => !context_template),
    [generatedTemplates],
  );
  const contextTemplates = useMemo(
    () => generatedTemplates.filter(({ context_template }) => !!context_template),
    [generatedTemplates],
  );

  if (!extraction?.id) return null;

  if (isExtractingDocument) return <LoadingDisplay />;

  if (flags.cmTemplates) {
    return (
      <div ref={containerRef} className="flex flex-col gap-4 h-full">
        {!!templates?.length && !!generatedTemplates?.length && (
          <div className="flex flex-col gap-2 h-full">
            <div className="w-full flex justify-between items-center p-6 gap-6">
              <div className="flex flex-col gap-1.5">
                <div className="font-semibold text-base">Select Template & Sections</div>
                <div className="text-sm text-gray-500">
                  {templateSelectionMode === TemplateSelectionMode.Recommended &&
                    (hasGeneratedTemplates
                      ? "We did not find a specified template within the documents. Please select the generated volumes or extracted sections to import."
                      : "We found a specified template within the documents. Please select the volumes or relevant sections to import.")}
                  {templateSelectionMode === TemplateSelectionMode.Library && "Select library templates to import."}
                </div>
              </div>
              <Button
                size="md"
                variant="outline"
                className="text-xs !border-[#D0D5DD] !text-gray-800 flex justify-center items-center gap-1.5 hover:!bg-gray-800/[0.02] hover:!border-gray-darkest"
                onClick={() =>
                  setTemplateSelectionMode(
                    templateSelectionMode === TemplateSelectionMode.Library
                      ? TemplateSelectionMode.Recommended
                      : TemplateSelectionMode.Library,
                  )
                }
              >
                <List size={14} /> {templateSelectionMode === TemplateSelectionMode.Recommended && "Template Library"}
                {templateSelectionMode === TemplateSelectionMode.Library && "Recommended Templates"}
              </Button>
            </div>
            <div className="overflow-auto mx-6 mb-6 border rounded-md border-gray-light">
              <table className="w-full">
                <thead className="text-sm bg-[#FCFCFD] whitespace-nowrap sticky top-0 shadow-[0px_1px_rgb(219_224_229)]">
                  <tr className="text-left text-[#667085]">
                    <th className="pl-4 pr-10 py-3 font-light">Template</th>
                    {templateSelectionMode === TemplateSelectionMode.Library && (
                      <th className="px-4 py-3 font-light text-center">Last Updated</th>
                    )}
                    {templateSelectionMode === TemplateSelectionMode.Recommended && (
                      <th className="px-4 py-3 font-light">Type</th>
                    )}
                    {templateSelectionMode === TemplateSelectionMode.Recommended && (
                      <th className="px-4 py-3 font-light">Description</th>
                    )}
                    {templateSelectionMode === TemplateSelectionMode.Recommended && (
                      <th className="px-4 py-3 font-light w-[20%] min-w-[200px]">Source</th>
                    )}
                    <th className="px-5 py-3 w-full" />
                  </tr>
                </thead>
                <tbody>
                  {templateSelectionMode === TemplateSelectionMode.Recommended &&
                    nonContextTemplates.map((template) =>
                      template.template_data.volumes.map((draft) => {
                        return (
                          <TemplateTableRow
                            key={draft.id}
                            containerRef={containerRef}
                            onSelectTemplate={(hasDraft) => handleIdentifiedTemplateSelection(draft, hasDraft)}
                            draft={draft}
                            selectingTemplateIds={selectingTemplateIds}
                            template={template}
                            extraction={extraction}
                          />
                        );
                      }),
                    )}
                  {templateSelectionMode === TemplateSelectionMode.Recommended && !!contextTemplates.length && (
                    <tr>
                      <td className="h-[44px] border-b border-gray-lightest bg-[#FCFCFD]">
                        <div className="text-[#667085] text-sm pl-4">Section</div>
                      </td>
                      <td className="h-[44px] border-b border-gray-lightest bg-[#FCFCFD]" />
                      <td className="h-[44px] border-b border-gray-lightest bg-[#FCFCFD]" />
                      <td className="h-[44px] border-b border-gray-lightest bg-[#FCFCFD]" />
                      <td className="h-[44px] border-b border-gray-lightest w-full bg-[#FCFCFD]" />
                    </tr>
                  )}
                  {templateSelectionMode === TemplateSelectionMode.Recommended &&
                    contextTemplates.map((template) =>
                      template.template_data.volumes.map((draft) => {
                        return (
                          <TemplateTableRow
                            key={draft.id}
                            containerRef={containerRef}
                            onSelectTemplate={(hasDraftOrExistsInContextBank) =>
                              handleIdentifiedContextualTemplateSelection(draft, hasDraftOrExistsInContextBank)
                            }
                            draft={draft}
                            selectingTemplateIds={selectingTemplateIds}
                            template={template}
                            extraction={extraction}
                            isContextual
                          />
                        );
                      }),
                    )}
                  {templateSelectionMode === TemplateSelectionMode.Library &&
                    templates.map((template) => {
                      const hasSelectedTemplate = selectedTemplateIds.includes(template.id);
                      const isSelecting = selectingTemplateIds.includes(template.id);
                      const formattedDate = dateFormatter(template.updated_at, "MM/DD/YYYY");
                      return (
                        <tr key={template?.id} className="text-left text-sm border-b border-b-gray-lightest">
                          <td className="pl-4 pr-10 py-3">
                            <div className="text-gray-darkest  w-max max-w-[400px] 2xl:max-w-[500px] 3xl:max-w-[650px] line-clamp-2 font-medium">
                              {template.name}
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="text-gray-500 text-center">{formattedDate}</div>
                          </td>
                          <td className="px-4 py-3" align="right">
                            <div className="text-gray-500">
                              <Button
                                loading={isSelecting}
                                disabled={isSelecting || isReadOnly || hasSelectedTemplate}
                                variant="primary"
                                size="sm"
                                onClick={() => !isReadOnly && onSelectTemplate(template.id)}
                                className="!bg-[#EEE] w-[83px] !text-[#475569] rounded py-1 px-2.5 text-xs font-medium duration-100 hover:brightness-90"
                              >
                                {hasSelectedTemplate ? "Selected" : "Select"}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="relative h-full overflow-y-auto p-8 pt-8">
        <div className="flex flex-col gap-8">
          {!!generatedTemplates?.length && (
            <div className="flex flex-col gap-4 border border-gray-lightest shadow rounded-lg p-4 bg-white">
              <div className="flex flex-col gap-1">
                <div className="font-semibold text-base">
                  {hasGeneratedTemplates ? "Templates Generated from Documents" : "Templates Found in Documents"}
                </div>
                <div className="text-sm text-gray-500">
                  {hasGeneratedTemplates
                    ? "We did not find a specified proposal template in the documents. We have generated a few templates based on the contents of the document. Please select and import the templates."
                    : "We found specified proposal templates in the documents. Please select and import the templates."}
                </div>
              </div>
              <div className="flex flex-wrap gap-4">
                {generatedTemplates.map((template, i) =>
                  template.template_data.volumes.map((draft) => {
                    const isContextual = template.context_template;
                    const isSelecting = selectingTemplateIds.includes(draft.id);
                    const hasDraftOrExistsInContextBank = isContextual
                      ? !!extraction.framework.context_bank?.some((existingDraft) => draft.id === existingDraft.id)
                      : extraction.framework.volumes.some((existingDraft) => draft.id === existingDraft.id);

                    return (
                      <motion.div
                        key={draft.id}
                        transition={{ duration: 0.4, delay: i / 0.9 - i }}
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                      >
                        <div
                          className="text-sm w-[240px] justify-between h-[106px] items-start rounded shadow border border-gray-lightest p-3 flex flex-col gap-3"
                          css={[
                            hasDraftOrExistsInContextBank && tw`bg-slate-100 border-slate-400`,
                            !!template.context_template && tw`bg-gray-darkest text-white hover:bg-zinc-700`,
                            isContextual && tw`bg-gray-darkest text-white`,
                          ]}
                        >
                          <div className="line-clamp-2" style={{ wordBreak: "break-word" }}>
                            {draft.title}
                          </div>
                          <Button
                            loading={isSelecting}
                            disabled={isSelecting || isReadOnly}
                            variant="primary"
                            size="sm"
                            onClick={() =>
                              isContextual
                                ? handleIdentifiedContextualTemplateSelection(draft, hasDraftOrExistsInContextBank)
                                : handleIdentifiedTemplateSelection(draft, hasDraftOrExistsInContextBank)
                            }
                            className="!text-slate-600 !bg-slate-200 rounded py-1 px-2.5 text-xs font-medium duration-100 hover:!bg-slate-300"
                            css={[hasDraftOrExistsInContextBank && tw`!bg-slate-300`]}
                          >
                            {hasDraftOrExistsInContextBank ? "Deselect" : "Select"}
                          </Button>
                        </div>
                      </motion.div>
                    );
                  }),
                )}
              </div>
            </div>
          )}

          <div className="flex flex-col gap-4">
            {!!templates?.length && !!generatedTemplates?.length && (
              <div className="flex flex-col gap-4 border border-gray-lightest shadow rounded-lg p-4 bg-white">
                <div className="w-full flex justify-between items-center">
                  <div className="font-semibold text-base">Template Library</div>
                  <button
                    className="text-xs text-gray-500 flex justify-center items-center gap-1"
                    onClick={() => setShowTemplateLibrary(!showTemplateLibrary)}
                  >
                    {showTemplateLibrary ? "Hide Template Library" : "Show Template Library"}{" "}
                    {showTemplateLibrary ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
                  </button>
                </div>
                {showTemplateLibrary && (
                  <>
                    <div className="text-sm text-gray-500">Select library templates to import.</div>
                    <table className="table-auto w-full">
                      <thead className="text-sm bg-[#F0F4F8]">
                        <tr className="text-left text-gray-500">
                          <th className="px-4 py-2 font-light">Templates</th>
                          <th className="px-4 py-2 font-light">Last Updated</th>
                          <th className="px-5 py-2 font-light text-right">Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        {templates.map((template) => {
                          const hasSelectedTemplate = selectedTemplateIds.includes(template.id);
                          const isSelecting = selectingTemplateIds.includes(template.id);
                          const formattedDate = dateFormatter(template.updated_at, "MM/DD/YYYY");
                          return (
                            <tr key={template?.id} className="text-left text-sm border border-b-[#F0F4F8] border-x-0">
                              <td>
                                <div className="text-gray-500 w-full ml-2 px-4 py-3">{template.name}</div>
                              </td>
                              <td>
                                <div className="text-gray-500 w-full ml-2 px-4 py-3">{formattedDate}</div>
                              </td>
                              <td>
                                <div className="text-gray-500 w-full p-3 py-3 flex justify-end min-w-[120px]">
                                  <Button
                                    loading={isSelecting}
                                    disabled={isSelecting || isReadOnly}
                                    variant="primary"
                                    size="sm"
                                    onClick={() => !isReadOnly && onSelectTemplate(template.id)}
                                    className="!bg-slate-200 !text-slate-600 rounded py-1 px-2.5 text-xs font-medium duration-100 hover:!bg-slate-300"
                                  >
                                    {hasSelectedTemplate ? "Selected" : "Select"}
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateSelection;

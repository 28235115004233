import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom/dist";
import { useDispatch } from "react-redux";
import { Spinner } from "utils/icons";
import { validateField } from "utils/helpers";
import { getWorkSpaces } from "store/reducers/authReducerSlice";
import { createWorkspace, getWorkspaceNames } from "api/api";
import { ArrowLeft } from "lucide-react";
import { useSearchDebounce } from "hook/useDebounce";
import { useNotification } from "context/notificationContext";
import { AUTH } from "const-values/testIds";
import * as logger from "utils/log";

import type React from "react";

const initialState = {
  company: "",
  description: "-",
  name: "",
  user_role: "",
};

const CreateWorkSpace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setToast } = useNotification();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [error] = useState(initialState);
  const [workspaceExist, setworkspaceExist] = useState(false);
  const { search, setSearchQuery } = useSearchDebounce();

  // Handle change input
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // handle workspace in change event
  const handleWorkspaceName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setFormData({
      ...formData,
      name: val,
    });
    setSearchQuery(val);
  };

  // check for workspace availability
  useEffect(() => {
    if (!validateField(formData.name)) return;
    getWorkspaceNames(formData.name)
      .then((res) => {
        setworkspaceExist(res?.data?.exists);
      })
      .catch((err) => {
        logger.error(err);
      });
  }, [search]);

  // on submit
  const createNewWorkSpace = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) return;
    if (formData.name && formData.company) {
      setLoading(true);
      const synthetic_test_secret = localStorage.getItem("synthetic_test_secret");
      createWorkspace(
        {
          company_name: formData.company,
          name: formData.name,
          description: formData.description,
        },
        synthetic_test_secret ? { "X-Synthetic-Test-User-Token": synthetic_test_secret } : {},
      )
        .then(() => {
          dispatch(getWorkSpaces());
          setToast.success({
            msg: "Workspace created",
          });
          navigate("/select-workspace");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setToast.error({
            title: "Unable to create workspace",
            msg: "We were unable to create the workspace due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
          logger.error(err, "Error while creating a new workspace");
        });
    }
  };

  const areAllFieldsValid = () => {
    return formData.name && formData.company && formData.description && !workspaceExist;
  };

  return (
    <div className="flex w-full min-h-[100vh]">
      <div className="top-5 left-5 font-primary-font absolute">
        {/* @ts-expect-error Usage of -1 to go back is not supported by types and should be updated. See https://stackoverflow.com/questions/72676015/react-router-go-back-using-link */}
        <Link to={window.history.state && window.history.state.idx > 0 ? -1 : `/dashboard`} className="text-gray-400">
          <ArrowLeft size={29} />{" "}
        </Link>
      </div>
      <div className="py-4 m-auto max-w-[560px] w-full">
        <div className="flex flex-col items-center justify-center font-primary-font">
          <h1 className="text-4xl py-1 text-center">Create a team workspace </h1>
          <p className="text-gray-500 py-3 pb-5">Let's prepare your workspace.</p>
        </div>
        <form onSubmit={createNewWorkSpace} className="flex flex-col items-center justify-center">
          <div className="flex flex-col items-start justify-start pt-6 font-primary-font w-[90%]">
            <label htmlFor="name" className="pb-2 text-darkest items-center flex gap-1">
              Workspace Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={handleWorkspaceName}
              value={formData.name}
              placeholder="Ex: Acme Team"
              className={`border-gray-300 focus:border-black border-1.5 rounded-md p-2 w-full focus:outline-none ${
                workspaceExist ? "border-red-300 focus:border-red-300" : ""
              } ${error.name ? "border-red-300 focus:border-red-300" : ""}`}
              data-testid={AUTH.createWorkspace.workspaceName}
            />
            {workspaceExist ? (
              <p className="text-red-500 mt-1 ml-1 mb-2 block text-[14px]">Workspace name already exists</p>
            ) : null}
          </div>
          <div className="flex flex-col items-start justify-start pt-6 font-primary-font w-[90%]">
            <label htmlFor="name" className="pb-2 text-darkest items-center flex gap-1">
              Organization Name
            </label>
            <input
              type="text"
              name="company"
              id="company"
              onChange={handleChange}
              value={formData.company}
              placeholder="Ex: Acme Inc."
              className={`${
                error?.company ? "border-red-500 focus:border-red-500" : "border-gray-300 focus:border-black"
              }  border-1.5 rounded-md p-2 w-full focus:outline-none`}
              data-testid={AUTH.createWorkspace.companyName}
            />
          </div>

          <button
            type="submit"
            disabled={!areAllFieldsValid() || loading}
            title={loading ? "Creating workspace" : "Create workspace"}
            className={`bg-dark text-white flex my-8 w-[90%] flex-col rounded-md p-3 items-center text-lg ${
              !areAllFieldsValid() || loading ? "disabled:opacity-60" : ""
            }`}
            data-testid={AUTH.createWorkspace.createButton}
          >
            {loading ? (
              <span className="flex gap-3">
                <span>
                  <Spinner />
                </span>
              </span>
            ) : (
              <span>Continue</span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateWorkSpace;

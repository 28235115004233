import type { ComplianceMatrixRow } from "components/copilot/CopilotSchemaTypes";
import type { Requirement } from "types/Requirement";
import type { ToImmutable } from "YJSProvider/LiveObjects";
export enum StepValue {
  Requirements = 1,
  Template = 2 /** @deprecated */,
  Assign = 3 /** @deprecated */,
  Review = 4,
  Generate = 5,
}

export type ValidStepValueType = Exclude<StepValue, StepValue.Assign>;
export type ValidStepValueTypeWithoutTemplateStep = Exclude<StepValue, StepValue.Assign | StepValue.Template>;

// --------------------- Coordinates ---------------------

export type MergedRequirement = {
  analysis_id: string;
  bounds: { page_number: number; bounds: Bounds }[];
  child_requirements: Requirement[];
  content: string;
  document_id: string;
  id: string;
  response: string | null;
  volume_id: string | null;
  section_id: string | null;
  skipped: boolean;
};

export interface MergedBlock {
  bounds: Bounds;
  id: string;
  page: number;
}

// --------------------- Coordinates ---------------------

export interface DocumentReferenceRoot {
  elements: DocumentReference[];
}

export interface DocumentReference {
  text: string;
  bounds?: Bounds;
  id: string;
  page?: number;
  layout_type?: "title" | "header" | "footer" | "section_header" | "section_footer" | "page_number" | "figure";
  parts?: Part[];
  items?: Item[];
}

export interface Part {
  text: string;
  bounds: Bounds;
  id: string;
  rows?: Row[][];
  headers: any;
}

export interface Row {
  text: string;
  bounds: Bounds;
  id: string;
  page: number;
  table_element_type: string;
}

export interface Item {
  text: string;
  bounds: Bounds;
  id: string;
  page: number;
  layout_type?: string;
}

export type Bounds = {
  top_left: Coordinates;
  top_right: Coordinates;
  bottom_left: Coordinates;
  bottom_right: Coordinates;
};

export type Coordinates = {
  X: number;
  Y: number;
};

// --------------------- Rendered Block ---------------------

export type GroupedBlock = (DocumentReference | Part | Item | MergedBlock) & {
  requirement: ToImmutable<ComplianceMatrixRow>;
  isMergedRequirement?: boolean;
};

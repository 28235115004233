/** @jsxImportSource @emotion/react */

import { useCallback, useState } from "react";
import { statusList } from "../../config/data";
import star from "../../Assets/star.png";
import filledStar from "../../Assets/filledstar.png";
import { useDispatch } from "react-redux";
import { getProposalDocuments, updateContractManagerItems } from "api/api";
import { EllipsisVertical, Trash2 } from "lucide-react";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { getContractManagerData, setContractManager } from "store/reducers/contractManagerReducerSlice";
import { DateComp } from "./DateComp";
import { AssignedDropDown } from "./ContractsTable";
import tw from "twin.macro";
import type { MappedContract } from "./types";
import type { ContractData } from "api/types";
import { useFlags } from "hook/useFlags";
import { CopilotPresencePage } from "types/Presence";
import { useNavigate } from "react-router-dom";
import { NEW_FLOW_LAUNCH_DATE } from "const-values/Project";
import Spinner from "utils/Spinner";
import * as logger from "utils/log";
import getStoredPages from "utils/Project/getStoredPages";

type Props = {
  data: MappedContract;
  contracts: Array<{
    assigned_user_ids: string[];
    estimated_value: number;
    id: string;
    internal_contract_id: string;
    order_key: number;
    starred: boolean;
    status: number;
  }>;
  setSelectedProject: (selectedProject: MappedContract) => unknown;
  setDeleteModalActive: (isDeleteModalActive: boolean) => unknown;
  onDateSelect: (internalContractId: string, data: Partial<ContractData>) => unknown;
};

export function ContractRow({ data, contracts, setSelectedProject, setDeleteModalActive, onDateSelect }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const flags = useFlags();
  const [isGettingProposalDocument, setIsGettingProposalDocument] = useState(false);
  const updateSelectedMember = useCallback(
    async (projectId: string, assigned_user_ids: string[]) => {
      const newContracts = [...(contracts || [])];
      const rowIndex = newContracts.findIndex((v) => v.id === projectId);
      newContracts[rowIndex] = {
        ...newContracts[rowIndex],
        assigned_user_ids: assigned_user_ids,
      };

      dispatch(
        setContractManager({
          data: newContracts,
          loading: false,
        }),
      );
      updateContractManagerItems({
        updates: newContracts
          .map((v, i) => ({
            internal_contract_id: v.id,
            starred: v.starred,
            status: v.status,
            assigned_user_ids: v.assigned_user_ids,
            order_key: i,
            estimated_value: 0,
          }))
          .filter((_, i) => i === rowIndex),
      });
    },
    [contracts, dispatch],
  );

  // handle toggle star contract
  const handleFavoriteUpdate = async (id: string) => {
    const newContracts = [...contracts];
    const rowIndex = newContracts.findIndex((v) => v.id === id);
    newContracts[rowIndex] = {
      ...newContracts[rowIndex],
      starred: !newContracts[rowIndex]?.starred,
    };

    dispatch(
      setContractManager({
        data: newContracts,
        loading: false,
      }),
    );
    await updateContractManagerItems({
      updates: newContracts
        .map((v, i) => ({
          internal_contract_id: v.id,
          starred: v.starred,
          status: v.status,
          assigned_user_ids: v.assigned_user_ids,
          order_key: i,
          estimated_value: 0,
        }))
        .filter((_, i) => i === rowIndex),
    });
  };

  // update status of a specific record
  const handleUpdateStatus = useCallback(
    async (id: string, statusIndex: number) => {
      const newContracts = [...contracts];
      const rowIndex = newContracts.findIndex((v) => v.id === id);
      newContracts[rowIndex] = {
        ...newContracts[rowIndex],
        status: statusIndex,
      };

      dispatch(
        setContractManager({
          data: newContracts,
          loading: false,
        }),
      );

      await updateContractManagerItems({
        updates: newContracts
          .map((v, i) => ({
            internal_contract_id: v.id,
            starred: v.starred,
            status: v.status,
            assigned_user_ids: v.assigned_user_ids,
            order_key: i,
            estimated_value: 0,
          }))
          .filter((_, i) => i === rowIndex),
      });
      dispatch(getContractManagerData());
    },
    [contracts, dispatch],
  );

  const statusItems = useCallback(
    (id: string) => {
      return statusList?.map((status, index) => ({
        key: index,
        style: tw`!bg-white py-0.5`,
        label: (
          <div
            className="text-xs px-3 py-2 my-px rounded-full duration-150 hover:[filter:brightness(0.92)]"
            style={{
              color: status?.color || "",
              background: status?.bg || "",
            }}
          >
            {status?.label}
          </div>
        ),
        onSelect: () => handleUpdateStatus(id, index),
      }));
    },
    [handleUpdateStatus],
  );

  const statusStyling = statusList[data?.status];
  const contractDetailPath = flags.enableNewProjectFlow
    ? `/dashboard/contracts/details/?id=${data.id}&tab=${CopilotPresencePage.Proposal}`
    : `/dashboard/contracts/details/?id=${data.id}`;

  const onContractClick = useCallback(async () => {
    if (isGettingProposalDocument) return;

    const storedPages = getStoredPages(flags.enableNewProjectFlow);
    const lastPage = storedPages[data.id];

    const fetchAndNavigateToProposal = async () => {
      try {
        setIsGettingProposalDocument(true);
        const { data: proposals } = await getProposalDocuments(data.id);
        navigate(contractDetailPath, { state: proposals?.length === 1 ? { docId: proposals[0]?.id } : undefined });
      } catch {
        logger.error("Error navigating to new project flow");
      } finally {
        setIsGettingProposalDocument(false);
      }
    };

    if (!flags.enableNewProjectFlow) {
      navigate(contractDetailPath);
    } else {
      const isNewFlowProject = new Date(data.publishDate).getTime() >= new Date(NEW_FLOW_LAUNCH_DATE).getTime();

      if (lastPage) {
        try {
          const url = new URL(lastPage);
          const searchParams = new URLSearchParams(url.search);
          const docId = searchParams.get("docId");
          const tab = searchParams.get("tab");

          if (tab === CopilotPresencePage.Proposal && docId && !isNewFlowProject) {
            searchParams.delete("docId");
            navigate({ pathname: url.pathname, search: searchParams.toString() });
          } else navigate({ pathname: url.pathname, search: searchParams.toString() });
        } catch {
          localStorage.removeItem(flags.enableNewProjectFlow ? "vultron_last_page_url" : "vultron_last_page");
          fetchAndNavigateToProposal();
        }
      } else {
        fetchAndNavigateToProposal();
      }
    }
  }, [contractDetailPath, data.id, data.publishDate, flags.enableNewProjectFlow, isGettingProposalDocument, navigate]);

  return (
    <tr className={`${data?.starred ? " w-full border-b" : "border-b "} hover:bg-[#f5f5f5]`} key={data.id}>
      <td className="pl-4 py-1 min-w-[45px] min-[1550px]:min-w-[45px] min-[1550px]:w-[54px] text-center">
        {!isGettingProposalDocument ? (
          <img
            className="mx-auto  max-w-full object-contain cursor-pointer"
            src={data?.starred ? filledStar : star}
            alt=""
            onClick={() => {
              handleFavoriteUpdate(data?.id);
            }}
          />
        ) : (
          <Spinner />
        )}
      </td>
      <td className="min-w-[144px] w-[95%] text-sm relative">
        <button className="absolute inset-0 flex items-center justify-between px-4 py-4" onClick={onContractClick}>
          <p className="text-ellipsis line-clamp-2">{data?.projectName || "-"}</p>
        </button>
      </td>

      <td className="min-[1550px]:min-w-[175px] min-[1550px]:max-w-[150px] min-[1550px]:w-[150px] min-w-[142px] w-[142px] max-w-[142px] text-sm">
        <DateComp internalContractId={data.id} dateString={data.responseDate} onDateSelect={onDateSelect} />
      </td>
      <td className="pr-3 pl-[0.45rem] py-2 w-[160px] min-[1550px]:min-w-[170px] min-[1550px]:w-[190px]  group-hover/row:bg-[#f5f5f5]">
        <AssignedDropDown data={data} updateSelectedMember={updateSelectedMember} />
      </td>
      <td className="px-4 min-w-[155px] max-w-[155px]">
        <div className="relative w-full">
          <DropdownMenu items={statusItems(data?.id)} modal>
            <button
              className="text-xs py-1 px-3 rounded-full"
              style={{
                color: statusStyling.color || "",
                background: statusStyling.bg || "",
              }}
            >
              {statusStyling.label}
            </button>
          </DropdownMenu>
        </div>
      </td>

      <td className="pr-3 pl-[0.45rem] py-2 w-[50px] justify-center items-center group-hover/row:bg-[#f5f5f5] relative">
        <DropdownMenu
          modal
          items={[
            {
              key: 1,
              onSelect: () => {
                setSelectedProject(data);
                setDeleteModalActive(true);
              },
              label: (
                <div className="flex items-center gap-2 text-red-600 text-sm disabled:opacity-60 cursor-pointer select-none w-full">
                  <Trash2 size={14} />
                  Delete
                </div>
              ),
            },
          ]}
        >
          <EllipsisVertical size={20} className="cursor-pointer" />
        </DropdownMenu>
      </td>
    </tr>
  );
}

/** @jsxImportSource @emotion/react */

import { useAppDispatch, useAppSelector } from "store/storeTypes";
import useWritingAssistantOperations from "hook/writing-assistant/useWritingAssistantOperations";
import { DEFAULT_SESSION_NAME } from "components/molecules/assistant-sidebar-row/constants";
import { findWorkflowLabelFromSubtype } from "utils/assistants/utils";
import { setActiveSession } from "store/reducers/writing-assistant/writingAssistantReducer";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";

const WorkflowEndedBlock = () => {
  const activeSession = useAppSelector((store) => store.writingAssistant.activeSession);
  const { createSession } = useWritingAssistantOperations();
  const dispatch = useAppDispatch();

  if (!activeSession?.workflowEnded || !activeSession?.workflow) return null;

  return (
    <div className="flex flex-col items-center justify-center w-full gap-2.5 pt-4 pb-12 text-sm">
      <div className="flex items-center gap-1.5">
        The {findWorkflowLabelFromSubtype(activeSession?.workflow_subtype || activeSession?.workflow)} workflow is
        complete.
      </div>
      <button
        onClick={() => {
          const newSession = createSession({ name: DEFAULT_SESSION_NAME });
          const immutableSession = newSession?.toJSON() as ToImmutable<WritingAssistantSession>;
          dispatch(setActiveSession(immutableSession));
        }}
        className="border border-gray-200 flex items-center justify-center px-2 py-1.5 rounded-md hover:bg-gray-100"
      >
        Start a new session
      </button>
    </div>
  );
};

export default WorkflowEndedBlock;

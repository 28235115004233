import type React from "react";

type Props = {
  title?: React.ReactNode;
} & Pick<React.ComponentProps<"button">, "onClick" | "type" | "disabled" | "className">;

const CFWButton = ({ title, onClick, type, disabled, className }: Props) => {
  return (
    <button
      className={`w-full py-3 rounded-md text-[12.5px] bg-action text-[#f1f1f1] active:bg-[#304aa5de] ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default CFWButton;

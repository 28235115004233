export const EXPLANATION_OPTIONS = [
  "Didn't follow instructions",
  "Doesn't match my style",
  "Misused source material",
  "Too many buzzwords",
  "Not comprehensive enough",
  "Too long",
  "Too short",
  "Not factually correct",
] as const;

export const RESPONSE_OPTIONS = {
  NONE: "none",
  POSITIVE: "positive",
  NEGATIVE: "negative",
} as const;

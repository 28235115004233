export function computePages(totalItems: number, itemsPerPage: number): number {
  if (itemsPerPage <= 0) {
    return 0;
  }

  return Math.ceil(totalItems / itemsPerPage);
}

/**
 * Returns the base URL for thread notifications
 */
export const getThreadNotificationBaseUrl = () => {
  const origin = window.location.origin;
  const pathname = window.location.pathname.split("?")[0]; // Get path without query params
  return `${origin}${pathname}`;
};

/**
 * Returns the current tab from URL parameters or empty string if not found
 */
export const getCurrentTab = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("tab") || "";
};

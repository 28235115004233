/** @jsxImportSource @emotion/react */

import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import {
  toggleActions,
  toggleAssistant,
  toggleComments,
  toggleContentSearch,
  toggleRequirements,
} from "store/reducers/copilot/copilotDrawerReducer";
import { useFlags } from "hook/useFlags";
import { ThreadContext } from "components/Comments/types";
import { CopilotPresencePage } from "types/Presence";
import { AlignLeft, X } from "lucide-react";
import { useState } from "react";
import WritingAssistantSessionsDrawer from "../writing-assistant-drawer/writing-assistant-sessions-drawer";
import ProjectAssistantHeader from "./ProjectAssistantHeader";

const CopilotActionsOptions = () => {
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const params = useParams();
  const [sessionsDrawerOpen, setSessionsDrawerOpen] = useState(false);
  const { assistantOpen, contentSearchOpen, requirementsOpen, requirementDetailsOpen, commentsOpen } = useAppSelector(
    (root) => root.copilotDrawer,
  );
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
  const referenceId = searchParams.get("docId")?.toLocaleLowerCase() || params.volumeId;
  const commentsContext = params.volumeId ? ThreadContext.DRAFT : ThreadContext.PROPOSAL;
  const isProposalPage = searchParams.get("tab") === CopilotPresencePage.Proposal;
  const isDraftPage = commentsContext === ThreadContext.DRAFT;

  // this will be permanent after we remove the draft/requirements pages
  if (isProposalPage || (!isDraftPage && flags.disableSearchAndRequirementsActions))
    return (
      <>
        <WritingAssistantSessionsDrawer open={sessionsDrawerOpen} onOpenChange={setSessionsDrawerOpen} />
        <ProjectAssistantHeader setSessionsDrawerOpen={setSessionsDrawerOpen} />
      </>
    );

  return (
    <>
      <WritingAssistantSessionsDrawer open={sessionsDrawerOpen} onOpenChange={setSessionsDrawerOpen} />
      <div className="flex w-full items-center justify-start gap-2 px-3 min-h-[46px] h-[46px] bg-white">
        {assistantOpen && (
          <button onClick={() => setSessionsDrawerOpen(true)} className="p-1 rounded duration-100 hover:bg-gray-100">
            <AlignLeft size={18} />
          </button>
        )}
        <button
          className="h-full m-2 border-b border-white px-0.5 hover:border-gray-darkest"
          css={[assistantOpen ? tw`border-gray-darkest font-semibold` : tw`font-medium`]}
          onClick={() => {
            dispatch(toggleAssistant(true));
          }}
        >
          <span className="text-sm text-gray-darkest">Assistant</span>
        </button>
        {!flags.disableSearchAndRequirementsActions && (
          <>
            <button
              className="h-full m-2 border-b border-white px-0.5 hover:border-gray-darkest"
              css={[
                requirementsOpen || requirementDetailsOpen ? tw`border-gray-darkest font-semibold` : tw`font-medium`,
              ]}
              onClick={() => {
                dispatch(toggleRequirements(true));
              }}
            >
              <span className="text-sm text-gray-darkest">Requirements</span>
            </button>
            <button
              className="h-full m-2 border-b border-white px-0.5 hover:border-gray-darkest"
              css={[contentSearchOpen ? tw`border-gray-darkest font-semibold` : tw`font-medium`]}
              onClick={() => {
                dispatch(toggleContentSearch(true));
              }}
            >
              <span className="text-sm text-gray-darkest">Search</span>
            </button>
          </>
        )}
        {internalContractId && referenceId && isDraftPage && (
          <button
            className="h-full m-2 border-b border-white px-0.5 hover:border-gray-darkest"
            css={[commentsOpen ? tw`border-gray-darkest font-semibold` : tw`font-medium`]}
            onClick={() => {
              dispatch(toggleComments(true));
            }}
          >
            <span className="text-sm text-gray-darkest">Comments</span>
          </button>
        )}
        <button
          onClick={() => dispatch(toggleActions(false))}
          className="ml-auto text-gray-darkest duration-100 hover:text-gray-500"
        >
          <X size={18} />
        </button>
      </div>
    </>
  );
};

export default CopilotActionsOptions;

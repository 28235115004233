import { useEffect, useMemo, useState } from "react";
import { VultronAvatar } from "components/molecules/avatar";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import type { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import { getAndBuildInputMetadataFromBody } from "utils/assistants/utils";
import MarkdownContent from "components/molecules/ai-assistant-blocks/vultron-block/vultron-block-subsections/MarkdownContent";
import type { SelectionItem } from "types/Assistants/types";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";

export type ChecklistItemsState = Record<string, boolean>;

interface ChecklistProps<T> {
  blockId: string;
  instruction: string;
  selections: SelectionItem[];
  activeSession: ToImmutable<T>;
  onChecklistChange: (checklistType: "checklist", checklistState: ChecklistItemsState) => void;
}

const ChecklistBlock = <T extends AIAssistantSession | WritingAssistantSession>({
  blockId,
  instruction,
  selections,
  activeSession,
  onChecklistChange,
}: ChecklistProps<T>) => {
  const convertSelectionsToMap = (selectionItems: SelectionItem[]) => Object.fromEntries(selectionItems);
  const [checklistItems, setChecklistItems] = useState<ChecklistItemsState>(() => convertSelectionsToMap(selections));
  const isLastBlock = activeSession?.conversation[activeSession.conversation.length - 1]?.id === blockId;

  useEffect(() => {
    if (!isLastBlock && activeSession?.conversation) {
      const currentIndex = activeSession.conversation.findIndex((msg) => msg.id === blockId);
      const currentBlock = activeSession.conversation[currentIndex];

      if (currentBlock && "input_metadata" in currentBlock && currentBlock.input_metadata?.data) {
        setChecklistItems(currentBlock.input_metadata?.data);
        return;
      }

      if (currentIndex !== -1 && currentIndex < activeSession.conversation.length - 1) {
        const nextMessage = activeSession.conversation[currentIndex + 1];

        // legacy back-compat
        if (nextMessage?.body) {
          const metadata = getAndBuildInputMetadataFromBody(currentBlock.body);
          const entries = Object.entries(metadata?.data || {});
          const selectedOptions = convertSelectionsToMap(
            nextMessage.body.split(",").map((item) => [item.trim(), true] as SelectionItem),
          );
          const updatedSelections = convertSelectionsToMap(
            entries.map(([item]) => [item, selectedOptions[item] || false]),
          );

          setChecklistItems(updatedSelections);
        }
      }
    }
  }, [isLastBlock, activeSession?.conversation, blockId]);

  const checklistEntries = useMemo(() => Object.entries(checklistItems), [checklistItems]);

  useEffect(() => {
    if (isLastBlock) {
      onChecklistChange(INPUT_FIELD_OPTIONS.checklist, checklistItems);
    }
  }, [checklistItems, onChecklistChange, isLastBlock]);

  const handleCheckboxChange = (option: string, checked: boolean) => {
    if (!activeSession) return;
    setChecklistItems((prevItems) => ({ ...prevItems, [option]: checked }));
  };

  return (
    <div className="flex flex-row gap-2.5 w-full">
      <VultronAvatar size={26} isSquare />
      <div className="flex flex-col text-sm gap-3 flex-1 min-w-0">
        <h2 className="text-base font-semibold text-gray-800">{instruction}</h2>
        <ul className="flex flex-col gap-2">
          {checklistEntries.map(([option, selected]) => (
            <li key={option} className="flex items-start">
              <input
                type="checkbox"
                name={option}
                id={option}
                checked={selected}
                onChange={(e) => handleCheckboxChange(option, e.target.checked)}
                className="mr-2 mt-1.5 h-4 w-4 shrink-0 text-blue-600 border-gray-300 cursor-pointer rounded-md disabled:cursor-not-allowed"
                disabled={!isLastBlock}
              />
              <label aria-label={option} htmlFor={option} className="text-sm text-gray-700">
                <MarkdownContent blockContent={option} isStreaming={false} enableInternet={false} />
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ChecklistBlock;

/** @jsxImportSource @emotion/react */

import type { SolicitationType } from "components/copilot/CopilotSchemaTypes";
import { SOLICITATION_TYPE_TO_META } from "./constants";
import { useAppDispatch } from "store/storeTypes";
import { setExtractState } from "store/reducers/extract/ExtractReducerV2";
import tw from "twin.macro";

const ProjectSelection = ({ selectedSolicitationType }: { selectedSolicitationType: SolicitationType | null }) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="flex flex-col">
        <div className="font-normal text-xs text-gray-500">Step 1 </div>
        <div className="font-medium text-base">Select Type</div>
      </div>
      <div className="flex flex-wrap gap-3 whitespace-nowrap">
        {Object.entries(SOLICITATION_TYPE_TO_META).map(([key, value]) => {
          const isCurrentSolicitationType = selectedSolicitationType === key;
          return (
            <button
              key={key}
              className="flex flex-col justify-center gap-2 px-4 py-2 border border-gray-lightest rounded-md bg-white shadow-sharp-thin hover:bg-action-lightest"
              onClick={() => dispatch(setExtractState({ selectedSolicitationType: key as SolicitationType }))}
              css={[isCurrentSolicitationType && tw`bg-[#EBEFFD] border-[#2A47AB] text-[#2A47AB]`]}
            >
              <div className="flex items-center gap-2 text-sm">{value.label}</div>
            </button>
          );
        })}
      </div>
    </>
  );
};

export default ProjectSelection;

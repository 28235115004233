import { useSearchParams, NavLink } from "react-router-dom";
import Account from "./Account";
import WorkSpace from "./WorkSpace";
import Integrations from "./Integrations";
import { CircleUserRound, Plus, Settings as Gear } from "lucide-react";
import { useAppSelector } from "store/storeTypes";

// --------------------------- Settings page main Wrapper, All 2 tabs --------------------------
const Settings = () => {
  const [searchParams] = useSearchParams();
  const tabName = searchParams.get("tab")?.toLocaleLowerCase() || "workspace";
  const workspace = useAppSelector((store) => store.auth.currentWorkspace);

  const tabs = [
    {
      activeImg: <Gear size={20} className="mt-0.5 text-gray-darkest" />,
      name: "Workspace",
      slug: "workspace",
      img: <Gear size={20} className="w-5 h-5 mt-0.5" />,
      component: <WorkSpace />,
    },
    {
      activeImg: <CircleUserRound size={16} className="w-4 h-4 mt-1 text-gray-darkest" />,
      name: "Account",
      slug: "account",
      query: "",
      img: <CircleUserRound size={16} className="w-4 h-4 mt-1" />,
      component: <Account />,
    },
  ];
  if (workspace.enable_integrations) {
    tabs.push({
      activeImg: <Plus size={20} className="w-5 h-5 mt-0.5 text-gray-darkest" />,
      name: "Integrations",
      slug: "integrations",
      img: <Plus size={20} className="w-5 h-5 mt-0.5" />,
      component: <Integrations />,
    });
  }

  return (
    <div className="inline overflow-auto bg-white h-full">
      {/* Tabs */}
      <div className="flex border-b border-[#ebeced] items-center shadow-sm bg-white pl-6">
        {tabs.map((tab, i) => (
          <NavLink
            className={`${
              tabName === tab.slug ? "border-gray-darkest text-gray-darkest" : "border-transparent"
            } "items-center flex gap-1 text-gray-text cursor-pointer border-b py-4 mr-5`}
            key={i}
            to={`/dashboard/settings?tab=${tab.slug}`}
          >
            {tabName === tab.slug ? tab.activeImg : tab.img}
            <span className={`cursor-pointer ml-0.5 ${tabName === tab.slug ? "text-gray-darkest" : "text-gray-text"}`}>
              {tab.name}
            </span>
          </NavLink>
        ))}
      </div>
      {/* Contract Details Content */}
      {tabs?.find((v) => v?.slug === tabName)?.component}
    </div>
  );
};

export default Settings;

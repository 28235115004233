import { useCallback, useState } from "react";
import { downloadProposal } from "api/api";
import { Button } from "../primitives/Button";
import { Upload } from "lucide-react";
import { Spinner } from "utils/icons";
import { useSearchParams } from "react-router-dom";
import { ToolbarTooltip } from "../primitives/Tooltip";
import * as logger from "utils/log";
import { theme } from "twin.macro";

const DATA_FORMAT = "yjs";
export const ExportButton = () => {
  const [searchParams] = useSearchParams();
  const [exporting, setExporting] = useState(false);
  const proposalDocId = searchParams.get("docId")?.toLowerCase() || "";

  const handleExport = useCallback(async () => {
    if (!proposalDocId) return;

    setExporting(true);

    try {
      const response = await downloadProposal(proposalDocId, DATA_FORMAT);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      const filename = `proposal_${new Date().toISOString().split("T")[0]}.docx`;
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      logger.error(error as Error);
    } finally {
      setExporting(false);
    }
  }, [proposalDocId]);

  return (
    <ToolbarTooltip content="Export document">
      <Button
        variant="subtle"
        className="border border-gray-200 bg-gray-100 cursor-pointer h-8 hover:border-gray-300"
        style={{ borderRadius: "0.25rem" }}
        onClick={handleExport}
        disabled={!proposalDocId || exporting}
        aria-label="Export document"
      >
        <div className="flex items-center gap-1 text-gray-darkest ml-1.5">
          <span className="text-xs">{exporting ? "Exporting" : "Export"}</span>
          {exporting ? (
            <Spinner classes="text-gray-darkest items-center" width={12} height={12} />
          ) : (
            <Upload style={{ height: "12px", color: theme`colors.gray.darkest` }} />
          )}
        </div>
      </Button>
    </ToolbarTooltip>
  );
};

/** @jsxImportSource @emotion/react */
import type { SourceGroup } from "components/molecules/ai-assistant-blocks/vultron-block/constants";
import { forwardRef, useRef } from "react";
import { mergeRefs } from "utils/MergeRefs/mergeRefs";
import tw from "twin.macro";
import ExpandButtons from "./ExpandButtons";
import { renderContent } from "./utils";

type SourceGroupItemProps = {
  group: SourceGroup;
  idx: number;
  expandContent: (groupIndex: number, previous: boolean) => Promise<void>;
  canExpandUp: boolean;
  canExpandDown: boolean;
  scrollOnResize: (container: HTMLDivElement, scrollOptions: ScrollToOptions) => void;
  highlightInitialContent?: boolean;
  isFullPage?: boolean;
};

const SourceGroupItem = forwardRef<HTMLDivElement, SourceGroupItemProps>(
  (
    { group, idx, expandContent, canExpandUp, canExpandDown, scrollOnResize, highlightInitialContent, isFullPage },
    forwardedRef,
  ) => {
    const localRef = useRef<HTMLDivElement>(null);
    const setRef = mergeRefs(localRef, forwardedRef);
    const initialContentRef = useRef(group.content);
    const initialContent = initialContentRef.current;
    const fullContent = group.content;

    const expandButtonsProps = {
      idx,
      expandContent,
      canExpandUp,
      canExpandDown,
      isFullPage,
      localRef,
      scrollOnResize,
    };

    const contentJSX = renderContent(fullContent, highlightInitialContent, initialContent);

    if (isFullPage) {
      return (
        <div className="relative flex flex-col gap-2">
          <div ref={setRef} className="max-h-[calc(90vh-8rem)] p-6 overflow-y-auto">
            {contentJSX}
          </div>
          <ExpandButtons {...expandButtonsProps} />
        </div>
      );
    }

    return (
      <div
        id={`group-${idx}`}
        ref={setRef}
        className="relative flex flex-col gap-4 pt-4 overflow-y-auto px-2 max-h-[400px] bg-zinc-50 rounded-md border border-gray-200"
      >
        <div className="px-4">{contentJSX}</div>
        <div
          className="flex gap-2 justify-end sticky bottom-0 w-full py-2 backdrop-blur-xs"
          css={tw`bg-gradient-to-t from-zinc-50/90 via-zinc-50/70 to-zinc-50/10`}
        >
          <ExpandButtons {...expandButtonsProps} />
        </div>
      </div>
    );
  },
);

export default SourceGroupItem;

import { ResponseTolerance } from "types/Requirement";
import { FilterOption } from "./utils";
import { ReactComponent as Stars } from "Assets/stars.svg";
import { ReactComponent as Atom } from "Assets/atom.svg";
import { ReactComponent as Diamond } from "Assets/diamond.svg";

export enum SortKey {
  RequirementText = "requirementText",
  RequirementStatus = "requirement_status",
  ComplianceStatus = "compliance_status",
  ProposalVolume = "proposalVolume",
  ProposalSection = "proposalSection",
}

export enum Direction {
  Descending = "descending",
  Ascending = "ascending",
}

export const DEFAULT_DOC = [{ id: null, file_name: "No Document" }];

export const RESPONSE_TOLERANCE_OPTIONS = [
  {
    name: "Flexible",
    icon: Stars,
    value: ResponseTolerance.Flexible,
    text: "Vultron will always generate a response even when no relevant content was found.",
  },
  {
    name: "Moderate",
    icon: Atom,
    value: ResponseTolerance.Moderate,
    text: "Vultron will attempt to generate a response from any relevant content found.",
  },
  {
    name: "Strict",
    icon: Diamond,
    value: ResponseTolerance.Strict,
    text: "Vultron will only generate a response from directly relevant content.",
  },
];

export enum ColumnOption {
  "Assignees" = "Assignees",
  "Status" = "Status",
  "Compliance" = "Compliance",
  "ProposalVolume" = "Proposal Volume",
  "ProposalSection" = "Proposal Section",
}

export enum ResponseColumnOption {
  "Response" = "Response",
  "Sources" = "Sources",
  "Relevance" = "Relevancy",
  "Score" = "Score",
}

export const COLUMN_OPTION_TO_FILTER_KEY = {
  [ColumnOption.Assignees]: FilterOption.Assignees,
  [ColumnOption.Status]: FilterOption.Status,
  [ColumnOption.Compliance]: FilterOption.Compliance,
  [ColumnOption.ProposalVolume]: FilterOption.ProposalVolume,
  [ColumnOption.ProposalSection]: FilterOption.ProposalSection,
};

export const COLUMN_OPTION_TO_EXPORT_KEY = {
  [ColumnOption.Assignees]: "assignees",
  [ColumnOption.Status]: "status",
  [ColumnOption.Compliance]: "compliance",
  [ColumnOption.ProposalVolume]: "proposal_volume",
  [ColumnOption.ProposalSection]: "proposal_section",
  [ResponseColumnOption.Response]: "response",
  [ResponseColumnOption.Relevance]: "relevance",
  [ResponseColumnOption.Sources]: "sources",
  [ResponseColumnOption.Score]: "score",
};

export const SORT_KEY_TO_TEXT = {
  [SortKey.ComplianceStatus]: "Compliance",
  [SortKey.RequirementStatus]: "Status",
  [SortKey.ProposalSection]: "Section",
  [SortKey.ProposalVolume]: "Volume",
  [SortKey.RequirementText]: "Requirement",
};

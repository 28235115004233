import type { Editor } from "@tiptap/react";
import { useCallback, useEffect, useState } from "react";
import { Select } from "../primitives/Select";
import { DEFAULT_FONT_SIZE, FONT_SIZE_OPTIONS } from "./constants";
import { ToolbarTooltip } from "../primitives/Tooltip";

export const ToolbarFontSize = ({ editor }: { editor: Editor }) => {
  const [currentFontSize, setCurrentFontSize] = useState(DEFAULT_FONT_SIZE);

  useEffect(() => {
    if (!editor) return;

    const updateFontSize = () => {
      const fontSize = editor.getAttributes("textStyle").fontSize || DEFAULT_FONT_SIZE;
      setCurrentFontSize(fontSize);
    };

    updateFontSize();

    editor.on("selectionUpdate", updateFontSize);
    editor.on("update", updateFontSize);

    return () => {
      editor.off("selectionUpdate", updateFontSize);
      editor.off("update", updateFontSize);
    };
  }, [editor]);

  const onFontSizeChange = useCallback(
    (value: string) => {
      if (!editor) return;
      editor.chain().focus().setFontSize(value).run();
    },
    [editor],
  );

  return (
    <ToolbarTooltip content="Font size">
      <div>
        <Select
          variant="subtle"
          value={currentFontSize}
          initialValue={DEFAULT_FONT_SIZE}
          items={FONT_SIZE_OPTIONS}
          onChange={onFontSizeChange}
          className="h-8 border border-gray-200 rounded text-sm"
        />
      </div>
    </ToolbarTooltip>
  );
};

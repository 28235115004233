import type { Editor } from "@tiptap/react";
import { useCommentsQuery } from "api/comments/useComments";
import { findMarksInRange } from "../utils";
import { useEffect, useRef } from "react";

export const useCommentsSync = (editor: Editor | null, internalContractId: string, referenceId?: string | null) => {
  const isSyncedRef = useRef(false);
  const { data: comments, isLoading } = useCommentsQuery(internalContractId, referenceId || undefined);
  // fetch comments data using the useCommentsQuery hook

  useEffect(() => {
    if (!editor || !comments || isSyncedRef.current) {
      return;
    }
    // find all comment marks in the document
    const commentMarks = findMarksInRange(
      editor.state.doc,
      0,
      editor.state.doc.nodeSize - 2,
      (m) => m.type.name === "comment",
    );

    // create a map lookup of comment ids and resolved status
    const commentsMap = new Map(comments.map((comment) => [comment.id, comment.resolved]));

    // iterate over the comment marks and update the resolved status
    commentMarks.forEach(({ mark }) => {
      const threadId = mark.attrs.id;
      const markResolved = mark.attrs.resolved;
      const commentResolved = commentsMap.get(threadId);

      // if the comment doesn't exist in our data, remove the mark
      if (commentResolved === undefined) {
        editor.commands.removeCommentMark(threadId);
      }
      // if the resolved status in the mark doesn't match our data, update it
      else if (commentResolved !== markResolved) {
        editor.commands.resolveCommentMark(threadId, commentResolved);
      }
    });
    isSyncedRef.current = true;
  }, [editor, comments]);

  return { isLoading };
};

import { useAppSelector } from "store/storeTypes";
import { useStorage } from "YJSProvider/createYJSContext";

const useGetActiveWritingAssistantUser = () => {
  const currentUserId = useAppSelector((store) => store.auth.currentUser?.id);

  const writingAssistantUser = useStorage((root) => {
    if (!currentUserId) return;
    const writingAssistantUser = root.writing_assistant?.[currentUserId];

    if (!writingAssistantUser) return;

    return writingAssistantUser;
  });

  return writingAssistantUser;
};

export default useGetActiveWritingAssistantUser;

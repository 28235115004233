import { Checkbox } from "components/atoms/checkbox";
import Tooltip from "components/atoms/tooltip";
import type { BaseFile, WorkspaceFile } from "types/FileStorage";
import { getFileIcon } from "utils/getFileIcon";
import { styles } from "./styles";
import { useRef } from "react";
import useDocumentPreview from "hook/useDocumentPreview";
import { Download, ExternalLink } from "lucide-react";
import { toggleDocumentViewerModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";

type Props = {
  file: WorkspaceFile;
  handleSelectedFiles: (checked: boolean, files: BaseFile[]) => void;
  isSelected: boolean;
  disabled: boolean;
};

const FileRow = ({ file, handleSelectedFiles, disabled, isSelected }: Props) => {
  const fileNameRef = useRef<HTMLDivElement | null>(null);
  const { downloadFile } = useDocumentPreview();
  const dispatch = useAppDispatch();

  return (
    <div className={styles.checkboxItemRow}>
      <Tooltip
        key={file.id}
        disabled={(fileNameRef.current?.clientWidth || 0) < 314}
        disableHoverableContent
        content={file.name}
        contentProps={{ side: "left", align: "end" }}
      >
        <div className="group text-stone-800 flex items-center gap-2 text-sm flex-1 truncate text-start py-1.5">
          <img src={getFileIcon(file.file_extension_type)} alt="" className="h-[18px]" />
          <div ref={fileNameRef} className="truncate">
            {file.name}
          </div>
          <button
            onClick={() => downloadFile(file.download_url)}
            className="opacity-0 group-hover:opacity-100 text-[16px] pb-0.5 text-action duration-150 hover:text-action-hover"
          >
            <Download size={16} />
          </button>
          {file.file_extension_type === "pdf" && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                dispatch(toggleDocumentViewerModal({ open: true, initialProps: { url: file.download_url } }));
              }}
              className="opacity-0 group-hover:opacity-100 text-xs pb-0.5 text-action duration-150 hover:text-action-hover"
            >
              <ExternalLink size={16} className="shrink-0" />
            </button>
          )}
        </div>
      </Tooltip>
      <Tooltip content="The file is still processing" disabled={file.indexed}>
        <div>
          <Checkbox
            disabled={disabled || !file.indexed}
            checked={isSelected}
            onCheck={(checkValue) => handleSelectedFiles(checkValue, [file])}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default FileRow;

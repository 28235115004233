import { useMutation } from "YJSProvider/createYJSContext";
import { useAppSelector } from "store/storeTypes";
import type { WritingAssistantSession, WritingAssistantUser } from "components/copilot/CopilotSchemaTypes";
import type { LiveObject } from "YJSProvider/LiveObjects";
import { find, findIndex } from "YJSProvider/LiveObjects";

export const useDeleteAssistantBlock = () => {
  const currentUserId = useAppSelector((root) => root.auth.currentUser?.id);
  const activeSessionId = useAppSelector((root) => root.writingAssistant.activeSession?.id);

  return useMutation(
    ({ storage }, blockId: string) => {
      if (!currentUserId) return;
      const liveSessions = storage
        .get("writing_assistant")
        ?.get(currentUserId)
        ?.get("sessions") as WritingAssistantUser["sessions"];

      if (!liveSessions) return;

      const liveSession: LiveObject<WritingAssistantSession> | undefined = liveSessions
        ? find(liveSessions, (session) => session.get("id") === activeSessionId)
        : undefined;

      const myConversation = liveSession?.get("conversation") as WritingAssistantSession["conversation"];

      if (!myConversation) return;
      const msgIdx = findIndex(myConversation, (block) => block.get("id") === blockId);
      if (msgIdx === -1) return;

      myConversation?.delete(msgIdx);
    },
    [currentUserId],
  );
};

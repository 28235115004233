export const decrementScale = (scale: number) => {
  if (scale > 0.4 && scale <= 1.1) {
    return Math.max(scale - 0.1, 0.4);
  } else if (scale > 1.1 && scale <= 1.25) {
    return scale - 0.15;
  } else if (scale > 1.25 && scale <= 2) {
    return scale - 0.25;
  } else if (scale > 2 && scale <= 3) {
    return scale - 0.5;
  } else return scale;
};

export const incrementScale = (scale: number) => {
  if (scale >= 0.3 && scale <= 1) {
    return scale + 0.1;
  } else if (scale > 1 && scale < 1.25) {
    return scale + 0.15;
  } else if (scale >= 1.25 && scale < 2) {
    return scale + 0.25;
  } else if (scale >= 2 && scale < 3) {
    return Math.min(scale + 0.5, 3);
  } else return scale;
};

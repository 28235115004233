/** @jsxImportSource @emotion/react */

import type { Extraction, Storage } from "components/copilot/CopilotSchemaTypes";
import { ExtractionErrorReason, ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { Link, useSearchParams } from "react-router-dom";
import Chip from "components/atoms/chip";
import { theme } from "twin.macro";
import tw from "twin.macro";
import { EXTRACTION_STATUS_TO_META } from "../constants";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";
import type { LiveObject, ToImmutable } from "YJSProvider/LiveObjects";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { ArrowLeft, ArrowRight, EllipsisVertical } from "lucide-react";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { useExtractionDropdownItems } from "./hooks";
import useExtractionOperations from "hook/useExtractionOperations";
import { StepValue } from "../doc-viewer";
import * as logger from "utils/log";

const SHADOW_BUFFER = 10;
const BATCH_LIMIT = 3;
const ITEM_WIDTH = 220;

const ExtractionCarouselItemStatus = ({ status }: { status: ExtractionStatus }) => {
  const operationInProgress = status === ExtractionStatus.Extracting || status === ExtractionStatus.Drafting;
  return (
    <Chip
      variant="secondary"
      colors={{
        primaryColor: EXTRACTION_STATUS_TO_META[status].colors.primary,
        secondaryColor: EXTRACTION_STATUS_TO_META[status].colors.secondary,
      }}
    >
      <div className="gap-1.5 flex items-center justify-center">
        <div
          className="rounded-full w-2 h-2"
          style={{ backgroundColor: EXTRACTION_STATUS_TO_META[status].colors.secondary }}
        />
        <div>
          {EXTRACTION_STATUS_TO_META[status].label}
          {operationInProgress && <span className="loading-ellipsis" />}
        </div>
      </div>
    </Chip>
  );
};

const ExtractionItem = ({ extraction }: { extraction: ToImmutable<Extraction> }) => {
  const [searchParams] = useSearchParams();
  const directionPath = `extractions/${extraction.id}?${searchParams.toString()}`;
  const [isEditingExtractionName, setIsEditingExtractionName] = useState(false);
  const { items } = useExtractionDropdownItems(extraction, setIsEditingExtractionName);
  const { setExtractionName } = useExtractionOperations();
  const [localExtractionName, setLocalExtractionName] = useState(extraction.name);

  return (
    <div
      key={extraction.id}
      style={{ width: ITEM_WIDTH }}
      className={`relative h-[95px] rounded-md border border-gray-light shadow-sharp-thin p-3 ${
        extraction.status === ExtractionStatus.Failed ? "" : "hover:bg-action-lightest"
      }`}
    >
      <Link
        to={directionPath}
        className={`absolute inset-0 ${extraction.status === ExtractionStatus.Failed ? "pointer-events-none" : ""}`}
      ></Link>
      <div className="flex flex-col h-full justify-between">
        <div className="flex justify-between gap-2">
          {isEditingExtractionName ? (
            <input
              autoFocus
              className="outline-none self-start text-sm pointer-events-none text-gray-darkest w-[90%] bg-transparent"
              value={localExtractionName}
              onChange={(e) => setLocalExtractionName(e.target.value)}
              onBlur={() => {
                if (extraction?.id) {
                  setExtractionName(extraction.id, localExtractionName);
                }
                setTimeout(() => setIsEditingExtractionName(false), 100);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  if (extraction?.id) {
                    setExtractionName(extraction.id, localExtractionName);
                  }
                  setTimeout(() => setIsEditingExtractionName(false), 100);
                }
              }}
            />
          ) : (
            <div className="text-sm text-slate-700 line-clamp-2 w-[90%]">{extraction.name}</div>
          )}
          <DropdownMenu
            contentProps={{ align: "end", css: tw`min-w-[120px]` }}
            triggerProps={{ className: "h-6 p-1 rounded-md hover:bg-slate-200 z-[100]" }}
            items={items}
          >
            <EllipsisVertical size={14} className="text-slate-400" />
          </DropdownMenu>
        </div>
        <Tooltip
          delayDuration={100}
          content={
            extraction?.error_reason === ExtractionErrorReason.PDFConversion
              ? "We were unable to process this document. Please convert it to a PDF and try again."
              : "We were unable to process the documents due to a technical issue on our end. Please contact support@vultron.ai for assistance."
          }
          disabled={extraction.status !== ExtractionStatus.Failed}
        >
          <div className="flex justify-between">
            <ExtractionCarouselItemStatus status={extraction.status} />
            <Chip
              variant="secondary"
              colors={{
                primaryColor: theme`colors.gray.200`,
                secondaryColor: theme`colors.gray.600`,
              }}
            >
              <div>
                {extraction.file_ids.length} file
                {extraction.file_ids.length === 1 ? "" : "s"}
              </div>
            </Chip>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export const ExtractionCarousel = ({
  extractions,
}: {
  extractions: NonNullable<ToImmutable<LiveObject<Storage>>["extractions"]>;
}) => {
  const containerRef = useRef<HTMLUListElement | null>(null);
  const itemsRef = useRef<(HTMLLIElement | null)[]>([]);
  const totalBatches = extractions.length;
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0);
  const [isDisplayingAll, setIsDisplayingAll] = useState(false);

  const canScrollPrev = currentBatchIndex > 0;
  const canScrollNext = currentBatchIndex + BATCH_LIMIT < totalBatches && !isDisplayingAll;
  const offsetX = useMotionValue(0);
  const animatedX = useSpring(offsetX, {
    damping: 20,
    stiffness: 150,
  });

  useEffect(() => {
    setIsDisplayingAll(
      (containerRef.current?.getBoundingClientRect()?.width || 0) + SHADOW_BUFFER >
        itemsRef.current
          .slice(currentBatchIndex)
          .reduce((acc, node) => (acc += node?.getBoundingClientRect().width || 0), 0),
    );
  }, [currentBatchIndex]);

  const scrollPrev = useCallback(() => {
    if (!canScrollPrev) return;

    const nextWidth = itemsRef.current
      .slice(currentBatchIndex - BATCH_LIMIT, currentBatchIndex)
      .reduce((acc, node) => (acc += node?.getBoundingClientRect().width || 0), 0);

    if (nextWidth === undefined) return;
    offsetX.set(offsetX.get() + nextWidth);

    setCurrentBatchIndex((prev) => (prev - BATCH_LIMIT < 0 ? 0 : prev - BATCH_LIMIT));
  }, [canScrollPrev, currentBatchIndex, offsetX]);

  const scrollNext = useCallback(() => {
    if (!canScrollNext) return;
    const nextWidth = itemsRef.current
      .slice(currentBatchIndex + 1, currentBatchIndex + BATCH_LIMIT + 1)
      .reduce((acc, node) => (acc += node?.getBoundingClientRect().width || 0), 0);
    if (!nextWidth) return;

    offsetX.set(offsetX.get() - nextWidth);

    setCurrentBatchIndex((prev) => prev + BATCH_LIMIT);
  }, [canScrollNext, currentBatchIndex, offsetX]);

  const patchedExtractions = useMemo(
    () =>
      extractions.map((extraction) => ({
        ...extraction,
        status: extraction.status || ExtractionStatus.Failed,
        step: extraction.step || StepValue.Requirements,
      })),
    [extractions],
  );

  useEffect(() => {
    const extractionsWithMissingProperties = extractions.filter((extraction) => !extraction.status || !extraction.step);

    if (extractionsWithMissingProperties?.length) {
      logger.info("Extraction is missing properties", { extractionsWithMissingProperties });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center flex-row">
      {(canScrollNext || canScrollPrev) && (
        <button
          className="p-1.5 rounded-full flex justify-center items-center text-lg duration-150 hover:bg-black/5"
          css={[!canScrollPrev && tw`opacity-40 pointer-events-none`]}
          onClick={scrollPrev}
        >
          <ArrowLeft size={18} />
        </button>
      )}
      <div className="overflow-hidden py-2 relative flex-1">
        {canScrollNext && (
          <div className="w-12 z-[1] absolute right-0 top-0 bottom-0 bg-gradient-to-l to-transparent from-white" />
        )}
        <motion.ul ref={containerRef} className="flex flex-row items-start" style={{ x: animatedX }}>
          {patchedExtractions.map((extraction, index) => {
            return (
              <motion.li
                ref={(el) => (itemsRef.current[index] = el)}
                layout
                key={extraction.id}
                className="group relative shrink-0 px-1.5 select-none transition-opacity duration-300"
                transition={{
                  ease: "easeInOut",
                  duration: 0.4,
                }}
              >
                <ExtractionItem extraction={extraction} key={extraction.id} />
              </motion.li>
            );
          })}
        </motion.ul>
      </div>
      {canScrollNext && (
        <button
          className="p-1.5 rounded-full flex justify-center items-center text-lg duration-150 hover:bg-black/5"
          css={[!canScrollNext && tw`opacity-0 pointer-events-none`]}
          onClick={scrollNext}
        >
          <ArrowRight size={18} />
        </button>
      )}
    </div>
  );
};

/** @jsxImportSource @emotion/react */
import { ArrowUpRight } from "lucide-react";
import { type WorkflowLabel, type WorkflowName, type WorkflowSubType } from "pages/ai-assistant/constants";
import { useGlobalWorkflowActions } from "pages/Workflows/useGlobalWorkflowActions";
import { WORKFLOW_ACTIONS } from "./constants";

const WorkflowActions = () => {
  const { createWorkflow } = useGlobalWorkflowActions();

  const handleClick = (action: { name: WorkflowName; subType: WorkflowSubType; label: WorkflowLabel }) => {
    createWorkflow(action.name, action.subType, action.label);
  };

  return (
    <div className="pointer-events-auto flex flex-col gap-2 border border-gray-light p-4 bg-white rounded-b-md">
      {WORKFLOW_ACTIONS.map((action, index) => (
        <button
          key={index}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => handleClick(action)}
          className="group flex items-center justify-between p-2 hover:bg-gray-100 rounded-md w-full"
        >
          <span>{action.text}</span>
          <ArrowUpRight size={18} className="opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
        </button>
      ))}
    </div>
  );
};

export default WorkflowActions;

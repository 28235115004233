import type { MutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "api/queryClient";
import type { AxiosError } from "axios";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import type { CaptureSchemaType, Form } from "types/Capture";
import { CAPTURE_FORMS_QUERY_KEY } from "./useGetForms";

export type CreateFormVariables = {
  schema_type: CaptureSchemaType;
  project_id?: string;
};

interface Options extends MutationOptions<Form, AxiosError, CreateFormVariables> {}

const useCreateForm = (options?: Options) => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");

  return useMutation<Form, AxiosError, CreateFormVariables>({
    mutationFn: (variables) => axios.post("/forms/", { project_id: projectId, ...variables }).then((res) => res.data),
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [CAPTURE_FORMS_QUERY_KEY, { project_id: projectId, ...variables }] });
    },
    ...options,
  });
};

export default useCreateForm;

import type { ReactNode } from "react";
import { useEffect, useRef } from "react";

interface ClickAwayListenerProps {
  onClickAway?: (event: MouseEvent) => void;
  children: ReactNode;
  className?: string;
  excludingItem?: string;
  excludingItems?: string[];
}

const ClickAwayListener: React.FC<ClickAwayListenerProps> = ({
  onClickAway,
  children,
  className,
  excludingItem,
  excludingItems,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isToast = event.target as Element;
      if (isToast?.closest("[data-toast]")) return;
      if (event.target instanceof HTMLElement && event.target.id === excludingItem) return;
      if (excludingItems && excludingItems?.find((v) => v === (event.target as HTMLElement).id)) return;
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickAway?.(event);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [excludingItem, excludingItems, onClickAway]);

  return (
    <div ref={ref} className={`${className ? className : ""}`}>
      {children}
    </div>
  );
};

export default ClickAwayListener;

import type { Editor } from "@tiptap/react";
import { Mic } from "lucide-react";
import { Button } from "../primitives/Button";
import styles from "./Toolbar.module.css";
import Popover from "components/atoms/popover";
import VoiceTranslateItem from "components/voice-assist/VoiceTranslateItem";
import { ToolbarTooltip } from "../primitives/Tooltip";

export const ToolbarDictate = ({ editor }: { editor: Editor }) => {
  const handleDictationComplete = (text: string) => {
    if (!editor) return;

    editor.chain().focus().insertContent(text).run();
  };

  return (
    <ToolbarTooltip content="Dictate">
      <div>
        <Popover
          content={
            <div className="flex flex-col items-center gap-2 p-4">
              <VoiceTranslateItem onComplete={handleDictationComplete} width="10" height="10" />
              <span className="text-xs text-gray-500">Click to start/stop recording</span>
            </div>
          }
        >
          <Button variant="subtle" className={styles.toolbarButton} aria-label="Voice dictation">
            <div className="flex flex-col items-center">
              <Mic style={{ height: "16px" }} />
              <span className="text-xs text-gray-darkest">Dictate</span>
            </div>
          </Button>
        </Popover>
      </div>
    </ToolbarTooltip>
  );
};

import type { DragEndEvent, DragStartEvent, UniqueIdentifier } from "@dnd-kit/core";
import { KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import type { ComplianceMatrixRow, Storage } from "components/copilot/CopilotSchemaTypes";
import { useCallback, useState } from "react";
import { useMutation } from "YJSProvider/createYJSContext";
import { find, findIndex, moveItem } from "YJSProvider/LiveObjects";

export const useDrag = () => {
  const [activeDragId, setActiveDragId] = useState<UniqueIdentifier | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveDragId(event.active.id);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveDragId(null);
  }, []);

  const handleDragEnd = useMutation(({ storage }, event: DragEndEvent, reqId?: string) => {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;
      if (!matrix) return;
      const liveRow = find(matrix, (row) => row.get("requirement")?.get("id") === reqId);

      const livePrompts = liveRow?.get("writing_prompts") as ComplianceMatrixRow["writing_prompts"];

      if (!livePrompts) return;
      const sourceIndex = findIndex(livePrompts, (row) => row.get("id") === active.id);
      const destinationIndex = findIndex(livePrompts, (row) => row.get("id") === over.id);
      if (sourceIndex === -1 || destinationIndex === -1) return;
      moveItem(livePrompts, sourceIndex, destinationIndex);
    }

    setActiveDragId(null);
  }, []);

  return { sensors, handleDragStart, handleDragEnd, handleDragCancel, activeDragId };
};

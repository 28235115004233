import type {
  WritingAssistantBlock,
  VultronBlock as WritingAssistantVultronBlock,
} from "components/copilot/CopilotSchemaTypes";
import type { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import type { AIAssistantBlock, VultronBlock as AIAssistantVultronBlock } from "utils/yjs-configs/ai-assistant/schema";
import type { ToImmutable } from "YJSProvider/LiveObjects";

export interface ChatSessionDocument {
  id: string;
  is_processing: boolean;
  name: string;
  file_extension_type: string;
  download_url: string;
  secure_preview_url: string;
}

export type FileMention = {
  id: string;
  name: string;
  fileExtensionType: string;
};

export interface SourceFile {
  type: string;
  source_id: string;
  source_name: string;
  chunk_ids: string[];
  preview_content?: string;
}

export enum BlockContentItemSourceTypes {
  Inline = "inline",
}

export interface BlockContentItem {
  response: string;
  source_files: SourceFile[];
  source_type?: BlockContentItemSourceTypes;
}

export type InputObject = {
  type: number;
  id: string;
  updated_at: string;
  body: string;
  sources?: ToImmutable<AIAssistantBlock | WritingAssistantBlock>["sources"];
  prompt?: string;
  promptSources?: ToImmutable<AIAssistantVultronBlock | WritingAssistantVultronBlock>["promptSources"];
  error?: boolean;
  enableInternet?: boolean;
  workflow?: string;
};

export type InputFieldType = (typeof INPUT_FIELD_OPTIONS)[keyof typeof INPUT_FIELD_OPTIONS];

export enum FileInputFields {
  ContentLibrary = "content_library",
  ChatDocuments = "chat_documents",
  ProjectScoped = "project_scoped",
  Internet = "internet",
}

export type BaseWorkflowInstructions = {
  instruction: string;
  name: string;
};

export type BaseWorkflowExtra = {
  docx_export?: boolean;
};

export type SelectionItem = [string, boolean];

export interface ChecklistWorkflowInstructions extends BaseWorkflowInstructions {
  type: "checklist";
  extra: {
    custom_inputs: boolean;
    max_options: number;
    selections: SelectionItem[];
  } & BaseWorkflowExtra;
}

export interface ChecklistGroupWorkflowInstructions extends BaseWorkflowInstructions {
  type: "checklist_group";
  extra: {
    custom_inputs: boolean;
    max_options: number;
    groups: {
      extra_context?: string[];
      title: string;
      subtitle: string;
      selections: SelectionItem[];
    }[];
  } & BaseWorkflowExtra;
}

export interface DynamicWorkflowInstructions extends BaseWorkflowInstructions {
  type: "dynamic";
  extra: {
    supported_upload_types?: string[];
    supported_document_types?: string[];
    max_file_count?: number;
    max_project_count?: number;
    max_upload_count?: number;
    max_content_library_count?: number;
    max_total_count?: number;
    exclude?: FileInputFields[];
    selection_method?: "and" | "or";
  } & BaseWorkflowExtra;
}

export type WorkflowInstructions =
  | ChecklistGroupWorkflowInstructions
  | ChecklistWorkflowInstructions
  | StrWorkflowInstructions
  | PendingWorkflowWorkflowInstructions
  | FilesWorkflowInstructions
  | EnumWorkflowInstructions
  | EndWorkflowWorkflowInstructions
  | BooleanWorkflowInstructions
  | ContentLibraryWorkflowInstructions
  | AllFilesWorkflowInstructions
  | ChatWorkflowInstructions
  | DynamicWorkflowInstructions;

interface BaseOldWorkflowInstructions extends BaseWorkflowInstructions {
  extra: {
    supported_upload_types?: string[];
    supported_document_types?: string[];
    max_file_count?: number;
    max_upload_count?: number;
    max_content_library_count?: number;
    max_project_count?: number;
    max_total_count?: number;
    exclude?: FileInputFields[];
  } & BaseWorkflowExtra;
}
interface StrWorkflowInstructions extends BaseOldWorkflowInstructions {
  type: "str";
}
interface PendingWorkflowWorkflowInstructions extends BaseOldWorkflowInstructions {
  type: "pending_workflow";
}
interface FilesWorkflowInstructions extends BaseOldWorkflowInstructions {
  type: "files";
}
interface EnumWorkflowInstructions extends BaseOldWorkflowInstructions {
  type: "enum";
}
interface EndWorkflowWorkflowInstructions extends BaseOldWorkflowInstructions {
  type: "end_workflow";
}
interface BooleanWorkflowInstructions extends BaseOldWorkflowInstructions {
  type: "boolean";
}
interface ContentLibraryWorkflowInstructions extends BaseOldWorkflowInstructions {
  type: "content_library";
}
interface AllFilesWorkflowInstructions extends BaseOldWorkflowInstructions {
  type: "all_files";
}
interface ChatWorkflowInstructions extends BaseOldWorkflowInstructions {
  type: "chat";
}

export interface ChatTaskStatuses {
  upload_chat_document_tasks: TaskDetail[];
}

export interface TaskDetail {
  status: string;
  minutes_time_remaining?: number | null;
  reference_id: string;
  chat_session_id: string;
  task_id: string;
  initial_loader: {
    load_percent: number;
    status: "queued" | "completed";
  };
}

export type ChecklistMetadata = {
  type: "checklist";
  data: Record<string, boolean>;
};
export type ChecklistGroupMetadata = {
  type: "checklist_group";
  data: Record<string, boolean>;
};
export type InputMetadata = ChecklistMetadata | ChecklistGroupMetadata;

/** @jsxImportSource @emotion/react */

import { useMemo, useRef, useState } from "react";

import DraggingOverlay from "./DraggingOverlay";
import { useDrag, useRequirementTableScrollManager } from "./hooks";
import RequirementSectionListContainer from "./RequirementSectionListContainer";
import RequirementsListHeader from "./RequirementsListHeader";

import { useSelection } from "../../../document-display/SelectionContext";
import { useCurrentFormattedExtraction } from "../hooks";
import { DndContext, DragOverlay, MeasuringStrategy } from "@dnd-kit/core";
import type { Volume } from "components/copilot/CopilotSchemaTypes";
import { Empty } from "components/molecules/empty";
import type { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/types";
import type { ViewportListRef } from "react-viewport-list";
import { ViewportList } from "react-viewport-list";
import { useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { pluralizeWord } from "utils/string";
import type { ToImmutable } from "YJSProvider/LiveObjects";

type Props = {
  dragDrafts: (Omit<ToImmutable<Volume>, "sections"> & { sections: FormattedSection[] })[];
  isReadOnly: boolean;
};

const RequirementListContainer = ({ dragDrafts, isReadOnly }: Props) => {
  const { selectedBlocks } = useSelection();
  const [volumeListRef, setVolumeListRef] = useState<ViewportListRef | null>(null);
  const sectionListRefs = useRef<Record<string, ViewportListRef | null>>({});
  const { extraction } = useCurrentFormattedExtraction();
  const viewportRef = useRef<HTMLDivElement | null>(
    document.getElementById("template-manager-section-scroll") as HTMLDivElement | null,
  );
  const isDragActive = useAppSelector((store) => store.dragDropState.isDragActive);

  const flattenedSections = useMemo(
    () =>
      dragDrafts.map((draft) => ({
        ...draft,
        sections: draft.sections.flatMap((section) => [section, ...(section.subsections || [])]),
      })),
    [dragDrafts],
  );

  useRequirementTableScrollManager({
    volumeListRef,
    sectionListRefs: sectionListRefs.current,
    flattenedSections,
  });

  const {
    collisionDetectionStrategy,
    sensors,
    handleDragCancel,
    handleDragEnd,
    handleDragStart,
    activeDragId,
    handleDragOver,
    groupedComplianceMatrix,
  } = useDrag({
    complianceMatrix: extraction?.compliance_matrix,
    flattenedSections,
  });

  const extractionHasSections = useMemo(
    () => extraction?.framework?.volumes?.some((vol) => vol.sections?.length),
    [extraction?.framework?.volumes],
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragOver={handleDragOver}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
    >
      {!!selectedBlocks?.length && extractionHasSections && (
        <>
          <div className="bg-gray-darkest text-white text-[13px] p-1 py-2 flex items-center justify-center">
            Hover below to add selected {pluralizeWord(selectedBlocks.length, "requirement")}
          </div>

          <div className="absolute inset-0 border-4 border-dashed border-gray-darkest pointer-events-none z-10" />
        </>
      )}
      <div
        ref={viewportRef}
        className="overflow-y-auto relative"
        id="template-manager-section-scroll"
        css={[selectedBlocks?.length || isDragActive ? tw`h-[calc(100%)] pb-20` : tw`h-full`, { zIndex: 5 }]}
      >
        {!flattenedSections.length && (
          <div className="px-8 h-full">
            <Empty
              heading="No Requirements Assigned"
              title="Begin by creating an outline and assigning requirements from the document"
            />
          </div>
        )}
        <ViewportList ref={setVolumeListRef} viewportRef={viewportRef} items={flattenedSections || []}>
          {(draftItem) => {
            const { sections, id: volumeId, title: volumeTitle } = draftItem;

            return (
              <div key={volumeId} className="flex flex-col relative" id={`template-manager-volume-${volumeId}`}>
                <RequirementsListHeader
                  volumeId={volumeId}
                  extractionId={extraction?.id}
                  title={volumeTitle}
                  isReadOnly={isReadOnly}
                />
                <ViewportList
                  overscan={100}
                  ref={(ref) => {
                    sectionListRefs.current = { ...sectionListRefs.current, [volumeId]: ref };
                  }}
                  viewportRef={viewportRef}
                  items={sections || []}
                >
                  {(sectionItem) => (
                    <RequirementSectionListContainer
                      key={sectionItem.id}
                      groupedComplianceMatrix={groupedComplianceMatrix}
                      isReadOnly={isReadOnly}
                      volumeId={volumeId}
                      extractionId={extraction?.id || ""}
                      section={sectionItem}
                    />
                  )}
                </ViewportList>
              </div>
            );
          }}
        </ViewportList>
      </div>
      <DragOverlay style={{ transformOrigin: "0 0 " }}>
        {!!activeDragId && (
          <DraggingOverlay requirements={extraction?.compliance_matrix || []} activeDragId={activeDragId} />
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default RequirementListContainer;

import { useEffect } from "react";

export const useAutoScroll = (ref: React.RefObject<HTMLElement>, isActive: boolean | undefined, delay = 100) => {
  useEffect(() => {
    if (isActive && ref.current) {
      const timeoutId = setTimeout(() => {
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [isActive, ref, delay]);
};

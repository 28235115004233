/** @jsxImportSource @emotion/react */

import type { ProjectAttachment } from "store/reducers/projectAttachmentsReducer";
import type { DropdownMenuProps } from "../dropdown-menu";
import DropdownMenu from "../dropdown-menu";
import { memo, useMemo, useRef, useState } from "react";
import { useAppSelector } from "store/storeTypes";
import { File, Upload } from "lucide-react";
import type { MenuItem } from "../dropdown-menu/DropdownMenu";
import { useAcceptedFileTypes } from "hook/file-upload/useAcceptedFileTypes";
import useUploadProjectFiles from "hook/Project/useUploadProjectFiles";
import ProgressLoader from "../progress-loader";
import type { InternalDocument } from "types/Project";
import useGetActiveProjectId from "hook/Project/useGetActiveProjectId";
import Tooltip from "components/atoms/tooltip";

type LocalUploadProps = {
  uploadProjectFiles: never;
  isUploadingFiles: never;
  isCompleted: never;
};

type ExternalUploadProps = {
  uploadProjectFiles: (files: FileList) => void;
  isUploadingFiles: boolean;
  isCompleted: boolean;
};

type UploadProps = LocalUploadProps | ExternalUploadProps;

type Props = {
  onSelect: (file: InternalDocument) => void;
  acceptedFiles?: string[];
} & Partial<UploadProps> &
  Omit<DropdownMenuProps<ProjectAttachment>, "items" | "multiselect">;

const ProjectAttachmentsDropdown = ({
  onSelect,
  children,
  uploadProjectFiles,
  isUploadingFiles,
  isCompleted,
  acceptedFiles,
  ...props
}: Props) => {
  const internalContractId = useGetActiveProjectId();
  const projectAttachments = useAppSelector((root) => root.project.activeProject?.internal_documents);
  const [open, setOpen] = useState(false);
  const acceptFileTypes = useAcceptedFileTypes();
  const uploadProjectFilesState = useUploadProjectFiles(internalContractId);
  const {
    uploadProjectFiles: localUploadProjectFiles,
    isLoading: isLocalUploadingFiles,
    isCompleted: isLocalCompleted,
  } = uploadProjectFilesState;
  const isUploadCompleted = isCompleted || isLocalCompleted;
  const isUploading = isUploadingFiles || isLocalUploadingFiles;
  const uploadFiles = uploadProjectFiles || localUploadProjectFiles;
  const localInputFile = useRef<HTMLInputElement | null>(null);

  const items: MenuItem<InternalDocument>[] = useMemo(
    () => [
      ...(projectAttachments || []).map((file, i) => {
        const isDisabled = !acceptedFiles?.includes(file.file_type || "");
        return {
          key: file.id || i,
          label: (
            <Tooltip content="Only PDFs are supported" disableHoverableContent disabled={!isDisabled}>
              <div className="flex items-center gap-2 min-w-0">
                <File size={14} className="shrink-0" />
                <span className="truncate">{file.name}</span>
              </div>
            </Tooltip>
          ),
          value: file,
          searchValue: file.name,
          onSelect: () => {
            if (isDisabled) return;
            onSelect(file);
            setOpen(false);
          },
          disabled: isDisabled,
        };
      }),
      {
        key: "UploadProjectAttachmentsDropdown",
        label: (
          <div className="flex items-center gap-2 text-gray-500">
            <Upload size={14} className="shrink-0" />
            <span>Upload file</span>
          </div>
        ),
        onSelect: (_, e) => {
          e?.preventDefault();
          localInputFile.current?.click();
        },
      },
    ],

    [acceptedFiles, onSelect, projectAttachments],
  );

  return (
    <DropdownMenu
      contentProps={{ style: { overflow: "hidden" } }}
      searchable
      open={open}
      onOpenChange={setOpen}
      items={items}
      overlay={
        isUploading || isUploadCompleted ? (
          <div className="flex justify-center items-center fixed rounded-md inset-0 bg-gray-200/70 backdrop-blur-sm">
            <div className="w-3/4">
              <ProgressLoader
                label={
                  <div>
                    {isUploadCompleted ? (
                      "Upload Complete!"
                    ) : (
                      <>
                        Uploading
                        <span className="loading-ellipsis" />
                      </>
                    )}
                  </div>
                }
                isCompleted={isUploadCompleted}
                isLoading={isUploading}
              />
            </div>
          </div>
        ) : undefined
      }
      {...props}
    >
      {children}
      <input
        type="file"
        multiple
        ref={localInputFile}
        className="hidden"
        accept={acceptFileTypes}
        onChange={(e) => {
          e?.preventDefault();
          if (e.target.files?.length) uploadFiles(e.target.files);
        }}
      />
    </DropdownMenu>
  );
};

export default memo(ProjectAttachmentsDropdown);

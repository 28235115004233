import { STORAGE_KEY } from "./constants";

export function getInitialMaxForSession(sessionId: string, maxMinutesRemaining: number): number {
  let dataObj: Record<string, number> = {};
  const storedData = localStorage.getItem(STORAGE_KEY);
  if (storedData) {
    try {
      dataObj = JSON.parse(storedData);
    } catch (error) {
      dataObj = {};
    }
  }
  if (dataObj[sessionId] != null) {
    return dataObj[sessionId];
  } else {
    const initialValue = maxMinutesRemaining + 1;
    dataObj[sessionId] = initialValue;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(dataObj));
    return initialValue;
  }
}

export function removeInitialMaxForSession(sessionId: string): void {
  const storedData = localStorage.getItem(STORAGE_KEY);
  let dataObj: Record<string, number> = {};
  if (storedData) {
    try {
      dataObj = JSON.parse(storedData);
    } catch (error) {
      dataObj = {};
    }
  }
  if (dataObj[sessionId] != null) {
    delete dataObj[sessionId];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(dataObj));
  }
}

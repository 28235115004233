import { useAppSelector } from "store/storeTypes";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { VultronBlock as LiveVultronBlock } from "utils/yjs-configs/ai-assistant/schema";
import { Download } from "lucide-react";
import { useExportChatMessage } from "pages/ai-assistant/hooks";
import { extractFirstHeaderText } from "./utils";

interface VultronBlockProps {
  blockContent: ToImmutable<LiveVultronBlock>["body"];
}

const DownloadContentButton = ({ blockContent }: VultronBlockProps) => {
  const { activeSession } = useAppSelector((root) => root.aiAssistantState);
  const documentHeader = extractFirstHeaderText(blockContent);

  const { exportChatMessage } = useExportChatMessage();

  const handleExport = async () => {
    if (!activeSession) return;
    await exportChatMessage(activeSession?.id, blockContent, "global", documentHeader || "");
  };

  return (
    <button className="flex items-center gap-1.5 text-blue-500" onClick={() => handleExport()}>
      {documentHeader || "Download the file"}
      <Download size={14} />
    </button>
  );
};

export default DownloadContentButton;

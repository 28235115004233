import React from "react";
import { File } from "lucide-react";
import { useAppDispatch } from "store/storeTypes";
import { toggleCapabilitySearchModal } from "store/reducers/modalsSlice";
import type { SourceFile } from "types/Assistants/types";

const SourceFileButton: React.FC<{ file: SourceFile }> = ({ file }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col">
      <button
        key={file.source_id}
        onClick={() => {
          dispatch(
            toggleCapabilitySearchModal({
              open: true,
              initialProps: {
                sourceChunkIds: file.chunk_ids,
                sourceId: file.source_id,
                sourceName: file.source_name,
                sourceType: file.type,
              },
            }),
          );
        }}
        className="flex gap-1.5 items-center rounded-lg shadow border border-gray-light bg-white pl-2 py-1.5 pr-3 max-w-[220px] duration-100 hover:bg-gray-100"
      >
        <div className="w-[4]">
          <File size={14} />
        </div>
        <div className="flex flex-col items-start min-w-0">
          <div className="text-xs text-gray-darkest max-w-full truncate">{file.source_name}</div>
        </div>
      </button>
    </div>
  );
};

export default SourceFileButton;

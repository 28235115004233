import type { WritingAssistantBlock } from "../../CopilotSchemaImmutableTypes";
import { AssistantBlockType } from "../../CopilotSchemaImmutableTypes";
import { CoreBlock } from "./blocks";
import { VultronBlock } from "./blocks";

export const renderBlock = (block: WritingAssistantBlock) => {
  if (block.hideBlock) {
    return <div className="hidden" />;
  }
  switch (block.type) {
    case AssistantBlockType.CoreBlock:
      return <CoreBlock block={block} />;
    case AssistantBlockType.VultronBlock:
      return <VultronBlock block={block} />;

    default:
      return <div />;
  }
};

/** @jsxImportSource @emotion/react */

import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useTrackUserMetric } from "utils/metrics";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { AIAssistantSession, VultronBlock as LiveVultronBlock } from "utils/yjs-configs/ai-assistant/schema";
import { useAssistant } from "pages/ai-assistant/ai-assistant-input/hooks";
import ErrorBlock from "./vultron-block-subsections/ErrorBlock";
import MarkdownContent from "./vultron-block-subsections/MarkdownContent";
import ActionsSection from "./vultron-block-subsections/ActionsSection";
import SourceSection from "./vultron-block-subsections/SourceSection";
import { extractWorkflowData } from "./utils";
import tw from "twin.macro";
import { VultronAvatar } from "components/molecules/avatar";
import DownloadContentButton from "./DownloadContentButton/DownloadContentButton";
import { useGlobalWorkflowActions } from "pages/Workflows/useGlobalWorkflowActions";
import TypingEffect from "components/TypingEffect";
import ResponsesWithSources from "../../../organisms/ResponsesWithSources/ResponsesWithSources";
import ResponseFeedback from "./vultron-block-subsections/response-feedback/ResponseFeedback";
import { extractInstruction } from "utils/assistants/utils";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import PendingLoader from "./pending-loader/PendingLoader";
import { setActiveMetadata } from "store/reducers/ai-assistant/aiAssistantReducer";
import ChecklistBlock from "components/molecules/assistant-blocks/checklist-block";
import type { ChecklistItemsState } from "components/molecules/assistant-blocks/checklist-block/ChecklistBlock";
import ChecklistGroupBlock from "components/molecules/assistant-blocks/checklist-group-block";

interface VultronBlockProps {
  block: ToImmutable<LiveVultronBlock>;
}

const VultronBlock = ({ block }: VultronBlockProps) => {
  const { endWorkflow } = useGlobalWorkflowActions();
  const activeSession = useAppSelector((root) => root.aiAssistantState.activeSession);
  const streamState = useAppSelector((root) => root.aiAssistantState.streamState);
  const { isStreamingInProgress, streamCopy, blockId } = streamState;
  const { refreshMessage } = useAssistant();
  const trackUserEvent = useTrackUserMetric();
  const dispatch = useAppDispatch();
  const isStreaming = isStreamingInProgress && blockId === block.id;
  const isWorkflowSession = !!activeSession?.workflow;
  const hasEmptyResponseError = !block.body && !isStreaming;

  const determineBlockType = useMemo(() => {
    if (block.enableInternet) {
      return "internet";
    } else if (block.promptSources?.length) {
      return "content search";
    } else {
      return "other";
    }
  }, [block]);

  const blockHasError = block.error || hasEmptyResponseError;

  const workflowData = isWorkflowSession ? extractWorkflowData(block.body) : null;
  const blockContent = isWorkflowSession
    ? extractInstruction(block.body, activeSession, endWorkflow, workflowData?.type)
    : block.body;

  const showExportButton = workflowData?.extra?.docx_export;
  const showLoadingAnimation = (isStreaming && !streamCopy) || (!blockContent && !blockHasError && isWorkflowSession);

  const onChecklistChange = useCallback(
    (checklistType: "checklist" | "checklist_group", checklistState: ChecklistItemsState) => {
      dispatch(
        setActiveMetadata({
          type: checklistType,
          data: checklistState,
        }),
      );
    },
    [dispatch],
  );

  if (workflowData?.type === INPUT_FIELD_OPTIONS.checklistGroup && activeSession) {
    return (
      <ChecklistGroupBlock<AIAssistantSession>
        blockId={block.id}
        instruction={workflowData.instruction}
        groups={workflowData.extra.groups}
        activeSession={activeSession}
        onChecklistChange={onChecklistChange}
      />
    );
  }

  if (workflowData?.type === INPUT_FIELD_OPTIONS.checklist && activeSession) {
    return (
      <ChecklistBlock<AIAssistantSession>
        blockId={block.id}
        instruction={workflowData.instruction}
        selections={workflowData.extra.selections}
        activeSession={activeSession}
        onChecklistChange={onChecklistChange}
      />
    );
  }
  const isPending = activeSession?.input_field_type === INPUT_FIELD_OPTIONS.pendingWorkflow;

  if (Array.isArray(blockContent)) {
    return <ResponsesWithSources blockContent={blockContent} />;
  }

  return (
    <div
      className="flex flex-row gap-2.5 w-full"
      css={[isStreaming && { scrollMarginTop: 8 }]}
      id={isStreaming ? `assistant-block-${blockId}` : undefined}
    >
      <VultronAvatar size={26} isSquare />
      <div className="flex flex-col text-sm gap-3 flex-1 min-w-0">
        {showLoadingAnimation && !isPending && (
          <div className="relative" style={{ height: block.error ? 30 : 24 }}>
            <TypingEffect
              style={{
                position: "absolute",
                top: 6,
                left: 0,
                padding: 0,
              }}
            />
          </div>
        )}
        {isPending && !blockContent && <PendingLoader />}
        {blockHasError && (
          <ErrorBlock
            errorMessage={
              "There was an issue processing the request. Please try again. If the issue persists, please contact us at support@vultron.ai."
            }
          />
        )}
        {!blockHasError && blockContent && (
          <>
            <MarkdownContent
              blockContent={isWorkflowSession ? blockContent : block.body}
              isStreaming={isStreaming}
              streamCopy={streamCopy}
              enableInternet={!!block.enableInternet}
            />
            {showExportButton && <DownloadContentButton blockContent={blockContent} />}
          </>
        )}
        <SourceSection sources={block.sources} isStreaming={isStreaming} />
        {!!blockContent && (
          <div className="relative w-full">
            <div
              className="flex flex-row items-center"
              css={[
                isStreaming && tw`opacity-0 pointer-events-none`,
                isWorkflowSession ? tw`justify-end` : tw`justify-between`,
              ]}
            >
              {!isWorkflowSession && (
                <ActionsSection
                  blockBody={blockContent}
                  blockId={block.id}
                  trackUserEvent={trackUserEvent}
                  determineBlockType={determineBlockType}
                  refreshMessage={refreshMessage}
                />
              )}
              <ResponseFeedback />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VultronBlock;

export const AVATAR_BG_COLOR = "#f3f4f6";

export const INPUT_FIELD_OPTIONS = {
  allFiles: "all_files",
  boolean: "boolean",
  chat: "chat",
  checklist: "checklist",
  checklistGroup: "checklist_group",
  contentLibrary: "content_library",
  dynamic: "dynamic",
  endWorkflow: "end_workflow",
  enum: "enum",
  files: "files",
  pendingWorkflow: "pending_workflow",
  string: "str",
} as const;

export const defaultAcceptedFileTypes = {
  "text/plain": [".txt"],
  "application/pdf": [".pdf"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
};

import React from "react";

export function renderContent(
  fullContent: string,
  highlightInitialContent = false,
  initialContent = "",
): React.ReactNode {
  if (!highlightInitialContent) {
    return fullContent
      .split(/\n+/)
      .filter((piece) => piece.trim() !== "")
      .map((piece, i) => (
        <div key={i} className="mb-2">
          {piece}
        </div>
      ));
  }

  const index = fullContent.indexOf(initialContent);
  const preText = index !== -1 ? fullContent.slice(0, index) : "";
  const postText = index !== -1 ? fullContent.slice(index + initialContent.length) : "";
  const prePieces = preText.split(/\n+/).filter((piece) => piece.trim() !== "");
  const initialPieces = initialContent.split(/\n+/).filter((piece) => piece.trim() !== "");
  const postPieces = postText.split(/\n+/).filter((piece) => piece.trim() !== "");

  return (
    <>
      {prePieces.map((piece, i) => (
        <div key={`pre-${i}`} className="mb-2">
          {piece}
        </div>
      ))}
      <div key="initial" className="mb-2 font-medium italic">
        {initialPieces.map((piece, i) => (
          <div key={`initial-${i}`}>
            <mark className="bg-yellow-50">{piece}</mark>
          </div>
        ))}
      </div>
      {postPieces.map((piece, i) => (
        <div key={`post-${i}`} className="mb-2">
          {piece}
        </div>
      ))}
    </>
  );
}

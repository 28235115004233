export const COLOR_OPTIONS = [
  [
    { value: "#000000", title: "Black" },
    { value: "#434343", title: "Dark Gray 4" },
    { value: "#666666", title: "Dark Gray 3" },
    { value: "#999999", title: "Dark Gray 2" },
    { value: "#B7B7B7", title: "Dark Gray 1" },
    { value: "#CCCCCC", title: "Gray" },
    { value: "#EFEFEF", title: "Light Gray" },
    { value: "#F3F3F3", title: "Very Light Gray" },
    { value: "#FFFFFF", title: "White" },
  ],
  [
    { value: "#FF0000", title: "Red" },
    { value: "#FF9900", title: "Orange" },
    { value: "#FFFF00", title: "Yellow" },
    { value: "#00FF00", title: "Green" },
    { value: "#00FFFF", title: "Cyan" },
    { value: "#4A86E8", title: "Blue" },
    { value: "#9900FF", title: "Purple" },
    { value: "#FF00FF", title: "Magenta" },
    { value: "#980000", title: "Dark Red" },
  ],
  [
    { value: "#E6B8AF", title: "Light Red" },
    { value: "#F4CCCC", title: "Light Red 2" },
    { value: "#FCE5CD", title: "Light Orange" },
    { value: "#FFF2CC", title: "Light Yellow" },
    { value: "#D9EAD3", title: "Light Green" },
    { value: "#D0E0E3", title: "Light Cyan" },
    { value: "#CFE2F3", title: "Light Blue" },
    { value: "#D9D2E9", title: "Light Purple" },
    { value: "#EAD1DC", title: "Light Magenta" },
  ],
];

export const LINE_SPACING_OPTIONS = [
  { value: "1", title: "1" },
  { value: "1.15", title: "1.15" },
  { value: "1.5", title: "1.5" },
  { value: "2", title: "2" },
  { value: "2.5", title: "2.5" },
  { value: "3", title: "3" },
];

export const DEFAULT_LINE_SPACING = "1.5";

export const FONT_SIZE_OPTIONS = [
  { value: "8pt", title: "8" },
  { value: "9pt", title: "9" },
  { value: "10pt", title: "10" },
  { value: "11pt", title: "11" },
  { value: "12pt", title: "12" },
  { value: "14pt", title: "14" },
  { value: "18pt", title: "18" },
  { value: "24pt", title: "24" },
  { value: "30pt", title: "30" },
  { value: "36pt", title: "36" },
  { value: "48pt", title: "48" },
  { value: "60pt", title: "60" },
  { value: "72pt", title: "72" },
  { value: "96pt", title: "96" },
];

export const DEFAULT_FONT_SIZE = "12pt";

export const TOOLBAR_BUTTON_STYLES = {
  DEFAULT:
    "flex items-center gap-2 p-1 mx-1 rounded text-zinc-100 hover:enabled:bg-[#404040] text-sm font-normal disabled:opacity-50 disabled:cursor-not-allowed",
};

export const SCALE_OPTIONS = [
  { value: "0.5", title: "50%" },
  { value: "0.75", title: "75%" },
  { value: "0.9", title: "90%" },
  { value: "1", title: "100%" },
  { value: "1.25", title: "125%" },
  { value: "1.5", title: "150%" },
  { value: "1.75", title: "175%" },
  { value: "2", title: "200%" },
];

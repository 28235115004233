import { mergeAttributes, Node } from "@tiptap/react";
import type { DocumentMargin } from "./constants";
import { DefaultMarginOptions, DOCUMENT_MARGIN_NODE, margins } from "./constants";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    Document: {
      setMargin: (margin: DocumentMargin | DefaultMarginOptions) => ReturnType;
    };
  }
}

interface DocumentAttributes {
  marginType: DefaultMarginOptions | "Custom";
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export const DocumentMarginNode = Node.create({
  name: DOCUMENT_MARGIN_NODE,
  content: "block+",
  addAttributes() {
    return {
      marginType: {
        default: DefaultMarginOptions.Normal,
      },
      top: {
        default: margins[DefaultMarginOptions.Normal].top,
        renderHTML: (attributes) => {
          return { style: `margin-top: ${attributes.top}in` };
        },
      },
      bottom: {
        default: margins[DefaultMarginOptions.Normal].bottom,
        renderHTML: (attributes) => {
          return { style: `margin-bottom: ${attributes.bottom}in` };
        },
      },
      left: {
        default: margins[DefaultMarginOptions.Normal].left,
        renderHTML: (attributes) => {
          return { style: `margin-left: ${attributes.left}in` };
        },
      },
      right: {
        default: margins[DefaultMarginOptions.Normal].right,
        renderHTML: (attributes) => {
          return { style: `margin-right: ${attributes.right}in` };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "div",
        getAttrs: (element) => {
          if (!(element instanceof HTMLElement)) return {};
          return {
            top: parseFloat(element.style.marginTop) || margins[DefaultMarginOptions.Normal].top,
            bottom: parseFloat(element.style.marginBottom) || margins[DefaultMarginOptions.Normal].bottom,
            left: parseFloat(element.style.marginLeft) || margins[DefaultMarginOptions.Normal].left,
            right: parseFloat(element.style.marginRight) || margins[DefaultMarginOptions.Normal].right,
          };
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setMargin:
        (marginType) =>
        ({ commands }) => {
          const newAttributes: Partial<DocumentAttributes> = {
            marginType: typeof marginType === "string" ? marginType : "Custom",
          };
          if (typeof marginType === "string") {
            const margin = margins[marginType];
            newAttributes.top = margin.top;
            newAttributes.bottom = margin.bottom;
            newAttributes.left = margin.left;
            newAttributes.right = margin.right;
          } else {
            newAttributes.top = marginType.top;
            newAttributes.bottom = marginType.bottom;
            newAttributes.left = marginType.left;
            newAttributes.right = marginType.right;
          }
          return commands.updateAttributes(DOCUMENT_MARGIN_NODE, newAttributes);
        },
    };
  },
});

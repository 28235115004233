/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";

const SessionEditor: FC<{
  localSessionName: string;
  setLocalSessionName: (name: string) => void;
  session: ToImmutable<AIAssistantSession | WritingAssistantSession>;
  updateSession: (sessionId: string, properties: Partial<AIAssistantSession | WritingAssistantSession>) => void;
  onEditComplete?: () => void;
}> = ({ localSessionName, setLocalSessionName, session, updateSession, onEditComplete }) => (
  <div className="px-2.5 py-2 w-full">
    <input
      autoFocus
      onKeyDown={(e) => {
        if (e.code === "Enter") {
          if (localSessionName.trim() !== session.name.trim()) {
            updateSession(session.id, { name: localSessionName.trim() });
          }
          onEditComplete?.();
        }
      }}
      onBlur={() => {
        if (localSessionName.trim() !== session.name.trim()) {
          updateSession(session.id, { name: localSessionName.trim() });
        }
        onEditComplete?.();
      }}
      className="text-sm outline-none h-5 text-slate-700 font-medium w-full bg-transparent"
      value={localSessionName}
      onChange={(e) => setLocalSessionName(e.target.value)}
    />
  </div>
);

export default SessionEditor;

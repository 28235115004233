import React from "react";
import SourceFileButton from "./SourceFileButton";
import type { SourceFile } from "types/Assistants/types";

interface SourceFileButtonsProps {
  sourceFiles: SourceFile[];
}

const SourceFileButtons: React.FC<SourceFileButtonsProps> = ({ sourceFiles }) => {
  if (!sourceFiles?.length) return null;
  return (
    <div className="relative -mx-2 mb-4">
      <div className="flex items-center gap-2 pt-0.5 pb-1.5 pl-0.5 pr-[22px] overflow-x-auto">
        {sourceFiles.map((file) => (
          <SourceFileButton key={file.source_id} file={file} />
        ))}
      </div>
    </div>
  );
};

export default SourceFileButtons;

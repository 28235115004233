import * as RadixSelect from "@radix-ui/react-select";
import clsx from "clsx";
import { CheckIcon } from "components/yjs-editor/icons/Check";
import type { CSSProperties } from "react";
import { useCallback, useEffect, useState } from "react";
import styles from "./Select.module.css";
import { ChevronDown } from "lucide-react";

interface Item extends RadixSelect.SelectItemProps {
  value: string;
  title?: string;
  description?: string;
}

interface Props extends Omit<RadixSelect.SelectProps, "onValueChange"> {
  variant?: "regular" | "subtle";
  initialValue?: string;
  value?: string;
  items: Item[];
  onChange?: RadixSelect.SelectProps["onValueChange"];
  placeholder?: RadixSelect.SelectValueProps["placeholder"];
  aboveOverlay?: boolean;
  className?: RadixSelect.SelectTriggerProps["className"];
  useInternalValue?: boolean;
  customTrigger?: React.ReactNode;
}

export function Select({
  variant = "regular",
  initialValue,
  value,
  items,
  onChange,
  placeholder,
  aboveOverlay,
  className,
  useInternalValue = true,
  customTrigger,
  ...props
}: Props) {
  const [internalValue, setInternalValue] = useState(initialValue);

  const handleValueChange = useCallback(
    (newValue: string) => {
      if (newValue !== undefined) {
        setInternalValue(newValue);
        onChange?.(newValue);
      }
    },
    [onChange],
  );

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <RadixSelect.Root
      value={useInternalValue ? internalValue : value}
      onValueChange={handleValueChange}
      defaultValue={initialValue}
      {...props}
    >
      {customTrigger ? (
        <RadixSelect.Trigger
          className={clsx(className, styles.trigger, {
            [styles.triggerSubtle]: variant === "subtle",
          })}
        >
          {customTrigger}
        </RadixSelect.Trigger>
      ) : (
        <RadixSelect.Trigger
          className={clsx(className, styles.trigger, {
            [styles.triggerSubtle]: variant === "subtle",
          })}
        >
          <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            <RadixSelect.Value placeholder={placeholder} className={styles.triggerValue} />
          </span>
          <RadixSelect.Icon className={styles.triggerIcon}>
            <ChevronDown size={12} />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
      )}

      <RadixSelect.Content
        position="popper"
        className={styles.select}
        style={
          {
            zIndex: aboveOverlay ? "var(--z-overlay)" : undefined,
          } as CSSProperties
        }
      >
        <RadixSelect.Viewport>
          {items.map(({ value: itemValue, title, description, ...props }) => (
            <RadixSelect.Item
              key={itemValue}
              value={itemValue}
              className={styles.item}
              {...props}
              onPointerUp={() => {
                if (itemValue === value) {
                  // The item is selected again
                  handleValueChange(itemValue);
                }
              }}
            >
              <div className={styles.itemIndicator}>
                <RadixSelect.ItemIndicator>
                  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <CheckIcon />
                  </svg>
                </RadixSelect.ItemIndicator>
              </div>
              <div className={styles.itemInfo}>
                <RadixSelect.ItemText className={styles.itemTitle}>{title ?? itemValue}</RadixSelect.ItemText>
                {description && <span className={styles.itemDescription}>{description}</span>}
              </div>
            </RadixSelect.Item>
          ))}
        </RadixSelect.Viewport>
      </RadixSelect.Content>
    </RadixSelect.Root>
  );
}

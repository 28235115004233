/** @jsxImportSource @emotion/react */
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { TriangleAlert } from "lucide-react";
import type { getDocumentStatus } from "../getDocumentStatus";
import type { getReviewStatus } from "../getReviewStatus";

interface DocumentFooterProps {
  formatted_set_date: string;
  status: ReturnType<typeof getDocumentStatus>;
  reviewStatus: ReturnType<typeof getReviewStatus>;
}

const DocumentFooter: React.FC<DocumentFooterProps> = ({ formatted_set_date, status, reviewStatus }) => (
  <div>
    <div>
      <p className="text-xxs text-gray-mid mb-2">{formatted_set_date}</p>
    </div>
    <div className="w-full flex items-center">
      {!!status && status.showTooltip && (
        <div className="flex items-center">
          <p className={`text-xxs ${status.color} mr-1`}>{status.text}</p>
          <Tooltip
            contentProps={{ alignOffset: -78, align: "start" }}
            content={
              <div>
                <span className="font-normal">{status.tooltipText}</span>
              </div>
            }
          >
            <Icon name="InfoCircle" className={`w-3 h-3 ${status.color} mt-0.5`} />
          </Tooltip>
        </div>
      )}
      <div className="flex-1" />
      {reviewStatus.needsReview && (
        <Tooltip
          contentProps={{ alignOffset: -78, align: "start" }}
          content={
            <div>
              <span className="font-normal">{reviewStatus.tooltipText}</span>
            </div>
          }
        >
          <div className={`flex items-center gap-1 ${reviewStatus.color}`}>
            <TriangleAlert size={10} />
            <div className="text-xxs">{reviewStatus.text}</div>
          </div>
        </Tooltip>
      )}
    </div>
  </div>
);

export default DocumentFooter;

import { useCallback, useMemo, useState } from "react";
import { LoaderCircle, PencilLine } from "lucide-react";
import { useProjectWorkflowActions } from "pages/Workflows/useProjectWorkflowActions";
import type { Editor } from "@tiptap/react";
import { TOOLBAR_BUTTON_STYLES } from "./constants";
import { WorkflowSub } from "pages/ai-assistant/constants";

export const ToolbarDraft = ({ editor }: { editor: Editor }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { createNewWorkflow } = useProjectWorkflowActions();

  const textSelection = useMemo(() => {
    const selection = editor.state.selection;
    const { from, to } = selection;
    return !selection.empty ? editor.state.doc.textBetween(from, to, "\n").trim() : "";
  }, [editor.state.doc, editor.state.selection]);

  const handleDraft = useCallback(() => {
    if (isLoading) return;
    setIsLoading(true);

    createNewWorkflow(WorkflowSub.Draft, textSelection).finally(() => setIsLoading(false));
  }, [isLoading, createNewWorkflow, textSelection]);

  return (
    <button
      className={TOOLBAR_BUTTON_STYLES.DEFAULT}
      onClick={handleDraft}
      disabled={isLoading || !textSelection}
      aria-label="Draft"
    >
      {isLoading ? <LoaderCircle className="animate-spin" size={16} /> : <PencilLine size={16} />}
      <span>Draft</span>
    </button>
  );
};

import type { Mark, Node } from "@tiptap/pm/model";
import type { YJSProvider } from "YJSProvider/YJSProvider";

export interface Comment {
  id: string;
  content: string;
  commenter: {
    id: string;
    username: string;
    email: string;
    is_vultron: boolean;
  };
  created_at: Date;
  updated_at: Date;
  reactions: Record<string, string[]>;
  mentions?: string[];
  review_tags?: string[];
}

export interface Assignee {
  id: string;
  username: string;
  email: string;
}

export interface CommentThread {
  commenter_id: string;
  comments: Comment[];
  created_at: Date;
  deleted: boolean;
  id: string;
  internal_contract_id: string;
  is_read: boolean;
  quote_text: string;
  reference_id: string;
  resolved: boolean;
  updated_at: Date;
  assignee: {
    id: string;
    username: string;
    email: string;
  } | null;
  mentions?: string[];
}

export interface FindMarkResult {
  mark: Mark;
  pos: number;
  node: Node;
}

export interface CommentsExtensionOptions {
  provider: YJSProvider;
}

export enum ThreadContext {
  PROPOSAL = "PROPOSAL_DOC",
  DRAFT = "DRAFT",
}

export enum CommentsStatusFilter {
  Open = "Open",
  Resolved = "Resolved",
}

export enum CommentsUserFilter {
  AllUsers = "All",
  VultronOnly = "Vultron",
  MemberOnly = "Team Members",
  AssignedToMe = "Assigned to Me",
}

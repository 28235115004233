import * as logger from "utils/log";

const getStoredPages = (newFlow = false) => {
  try {
    if (typeof localStorage !== "undefined") {
      const rawData = localStorage.getItem(newFlow ? "vultron_last_page_url" : "vultron_last_page");
      const parsed = JSON.parse(rawData || "{}");
      if (typeof parsed === "object") {
        return parsed;
      }
    }
  } catch (error) {
    logger.warn((error as Error)?.message || (error as string), "Failed to parse stored navigation state");
  }
  return {};
};

export default getStoredPages;

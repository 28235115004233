export const AUTH = {
  loginButton: "login-button",
  workspaceName: "workspace-name",
  createWorkSpaceBtn: "create-workspace",
  createWorkspace: {
    workspaceName: "workspace-name",
    companyName: "company-name",
    companyOverview: "company-overview",
    createButton: "create-button",
  },
};
export const TEST_DATA = {
  fileNames: ["testDocument1.docx", "testDocument2.docx"],
  searchFileNames: ["testDocument1.docx", "randomName.docx"],
};
export const CREATE_NEW_PROJECT = {
  newProject: "new-project",
  projectTitle: "project-title",
  clientName: "client-name",
  createProject: "create-project",
};
export const CAPTURE = {
  navigation: {
    customer: "customer",
    solution: "solution",
    approach: "approach",
    differentiation: "differentiation",
    winThemes: "win-themes",
  },
  formItem: {
    row: "form-item-row",
    textArea: "form-item-textarea",
    deadlineBtn: "deadline-btn",
    removeDeadlineBtn: "remove-deadline-btn",
    statusBtn: "status-btn",
    assignBtn: "assign-btn",
    dictationBtn: "dictation-btn",
    stopRecordingBtn: "stop-recording-btn",
    datePicker: {
      dayWithText: (day: string) => `.rmdp-day:has(span.sd:has-text("${day}"))`,
    },
  },
  status: {
    toDo: "not_started",
    inProgress: "in_progress",
    done: "done",
  },
  assignments: {
    unassign: "unassign",
    assign: "assign",
  },
};
export const DOCUMENTS = {
  navigation: {
    projectSpecific: "Project Specific",
    contentLibrary: "Content Library",
  },
  documents: {
    row: "row",
    typeDropdown: "type-dropdown",
    type: "type",
    uploadDocuments: "upload-documents",
  },
  dropdownOptions: {
    solicitation: "solicitation",
    intelligence: "intelligence",
    meeting_notes: "meeting_notes",
    customer_insight: "customer_insight",
    no_classification: "no_classification",
  },
  verticalDots: {
    button: "button",
    download: "1",
    delete: "2",
  },
  processingStatus: {
    pending: "Pending",
    processed: "Processed",
  },
  contentLibrary: {
    selectedDocuments: "selected-documents",
    toggle: "content-library-toggle",
    contentSection: "content-library-section",
    listItem: {
      container: "content-library-list-item",
      name: "content-library-doc-name",
      addButton: "content-library-doc-add",
    },
    selectedPanel: {
      documentItem: "content-library-selected-item",
      removeButton: "content-library-selected-remove",
      resetButton: "content-library-selected-reset",
    },
    search: {
      input: "content-library-search-input",
    },
    filters: {
      type: "content-library-filters-type",
      date: "content-library-filters-date",
    },
    typeOptions: {
      proposal: "1",
      technicalDocumentation: "2",
      whitepaper: "3",
      resume: "4",
      reports: "5",
      certifications: "6",
      pastPerformance: "7",
      solutions: "8",
      capabilities: "9",
      projectMANAGEMENT: "10",
    },
    dateOptions: {
      yesterday: "0",
      lastWeek: "1",
      lastMonth: "2",
      lastSixMonths: "3",
      lastYear: "4",
    },
  },
};
export const CONTENT_LIBRARY = {
  document: {
    card: "document-card",
    name: "document-name",
  },
  upload: {
    fileInput: "file-input",
  },
  folder: {
    createButton: "content-library-folder-create",
    nameInput: "content-library-folder-name",
    submitButton: "content-library-folder-submit",
    cancelButton: "content-library-folder-cancel",
    folderItem: "content-library-folder-item",
  },
};
export const PROJECTS_NAV = {
  navigation: {
    home: "Home",
    dashboard: "Dashboard",
    documents: "Documents",
    capture: "Capture",
    generate: "Generate",
    requirements: "Requirements",
    draft: "Draft",
    proposals: "Proposals",
  },
};
export const SIDEBAR_NAV = {
  navigation: {
    projects: "sidebar-Projects",
    aiAssistant: "sidebar-AI Assistant",
    contentLibrary: "sidebar-Content Library",
    settings: "sidebar-Settings",
    supportCenter: "sidebar-Support Center",
  },
};

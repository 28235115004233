import React from "react";
import WorkflowCard from "./WorkflowCard";
import type { Workflow } from "pages/ai-assistant/constants";

interface FavoriteWorkflowsSectionProps {
  favorites: Workflow[];
  hasFetchedFavorites: boolean;
  favoriteWorkflows: string[];
  handleFavoriteWorkflow: (workflow: string, workflow_subtype?: string | null) => Promise<void>;
  handleUnfavoriteWorkflow: (workflow: string, workflow_subtype?: string | null) => Promise<void>;
}

const FavoriteWorkflowsSection: React.FC<FavoriteWorkflowsSectionProps> = ({
  favorites,
  hasFetchedFavorites,
  favoriteWorkflows,
  handleFavoriteWorkflow,
  handleUnfavoriteWorkflow,
}) => {
  return (
    <>
      <h2 className="font-medium text-xl self-start mt-4">Favorites</h2>
      {favorites.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full">
          {favorites.map((workflow) => (
            <WorkflowCard
              key={workflow.label}
              workflow={workflow}
              favoriteWorkflows={favoriteWorkflows}
              handleFavoriteWorkflow={handleFavoriteWorkflow}
              handleUnfavoriteWorkflow={handleUnfavoriteWorkflow}
            />
          ))}
        </div>
      ) : (
        <div className="text-gray-500 text-sm mb-1">
          {hasFetchedFavorites && "No favorite workflows yet. Save your most-used workflows for quick access."}
        </div>
      )}
    </>
  );
};

export default FavoriteWorkflowsSection;

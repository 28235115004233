import type { ChatSession } from "types/Chat";
import search from "../../Assets/search-normal.png";
import plus from "../../Assets/plus.svg";
import edit from "../../Assets/edit-2.svg";
import trash from "../../Assets/chattrash.svg";
import tick from "../../Assets/tick.png";
import cross from "../../Assets/cross.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { createNewSessionAPI, deleteChatSessionAPI, editSessionNameAPI } from "api/api";
import { formatDate, getTimeDifference } from "helpers/helper";
import { useNotification } from "context/notificationContext";

type Props = {
  internalContractId: string;
  chatSessions: ChatSession[];
  setChatSessions: (chatSessions: ChatSession[]) => void;
  searchParams: URLSearchParams;
  getAllSessions: () => void;
  creatingSession: boolean;
  setCreatingSession: (creatingSession: boolean) => void;
};

const ChatHistory = ({
  internalContractId,
  chatSessions,
  setChatSessions,
  searchParams,
  getAllSessions,
  creatingSession,
  setCreatingSession,
}: Props) => {
  const tabName = searchParams.get("tab")?.toLocaleLowerCase() || "ask-ai";
  const id = searchParams.get("id")?.toLocaleLowerCase();
  const chatId = searchParams.get("chatId")?.toLocaleLowerCase();

  const { setToast } = useNotification();
  const navigate = useNavigate();

  const historyTopBoxRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [chatMessagesHeight, setChatMessagesHeight] = useState(500);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [sessionToEdit, setSessionToEdit] = useState("");
  const [editableTitle, setEditableTitle] = useState("");

  const createNewSession = () => {
    if (creatingSession) return;

    const data = {
      internal_contract_id: internalContractId,
    };
    setCreatingSession(true);
    createNewSessionAPI(data)
      .then((res) => {
        getAllSessions();
      })
      .catch((err) => {
        setToast.error({
          title: "Oops!",
          msg: "There was an error creating a new chat session. Please try again.",
        });
      });
  };

  const changeTitle = () => {
    const data = {
      session_id: sessionToEdit,
      name: editableTitle,
    };

    const tempChatSessions = JSON.parse(JSON.stringify(chatSessions));

    const session = chatSessions.find((s) => s.id === sessionToEdit);
    if (session) {
      session.name = editableTitle;
    }
    setChatSessions([...chatSessions]);
    setEditableTitle("");
    setSessionToEdit("");
    editSessionNameAPI(data).catch(() => {
      setChatSessions([...tempChatSessions]);
      setToast.error({
        title: "Oops!",
        msg: "There was an error updating the chat session. Please try again.",
      });
    });
  };

  const deleteChatSession = (session_id: string) => {
    const data = {
      session_id,
      internal_contract_id: internalContractId,
    };
    const tempChatSessions = JSON.parse(JSON.stringify(chatSessions));
    const index = chatSessions.findIndex((s) => s.id === session_id);
    chatSessions.splice(index, 1);
    setChatSessions([...chatSessions]);
    navigate(`/dashboard/contracts/details/?tab=${tabName}&id=${id}&chatId=${chatSessions[0]?.id || "0ds"}`);
    deleteChatSessionAPI(data).catch(() => {
      setChatSessions([...tempChatSessions]);
      setToast.error({
        title: "Oops!",
        msg: "There was an error deleting the chat session. Please try again.",
      });
    });
  };

  const editSessionName = (session_id: string, session_name: string) => {
    setSessionToEdit(session_id);
    setEditableTitle(session_name);
  };

  const titleChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => setEditableTitle(event.target.value);
  const cancelTitleEdit = () => {
    setEditableTitle("");
    setSessionToEdit("");
  };

  useEffect(() => {
    if (boxRef?.current && historyTopBoxRef?.current) {
      setChatMessagesHeight(boxRef.current.offsetHeight - historyTopBoxRef?.current.offsetHeight);
    }
  }, [boxRef, historyTopBoxRef]);

  const filteredChatSessions = useMemo(
    () =>
      searchKeyword
        ? chatSessions.filter((cS) => cS.name?.toLowerCase().includes(searchKeyword.toLowerCase()))
        : [...chatSessions],
    [searchKeyword, chatSessions],
  );

  //
  return (
    <div className="flex flex-col gap-5 flex-grow" ref={boxRef}>
      <div className="p-4 bg-white rounded-t-lg  top-0 lef-0 z-[2] flex flex-col gap-5" ref={historyTopBoxRef}>
        <div className="font-[500]">Chat History</div>
        {/* search bar */}
        <div>
          <div className="w-full min-w-[255px] flex items-center gap-2 p-3 rounded-md border bg-white min-h-full h-full">
            <img src={search} alt="" className="mr-1 w-4 h-4" />
            <input
              type="text"
              className="bg-white focus:outline-none focus:ring-0 focus:ring-gray-200 text-sm text-gray-500 w-full placeholder:text-sm"
              placeholder="Search"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
        {/* New session */}
        <div className="flex gap-2 cursor-pointer" onClick={createNewSession}>
          <img src={plus} alt="" /> <span className="text-action">Start a new session</span>
        </div>
      </div>
      {/* all chat history */}
      <div
        className="flex-grow overflow-y-scroll sessions"
        style={{
          height: chatMessagesHeight,
        }}
      >
        {creatingSession && <SessionSkeleton />}
        {filteredChatSessions?.length === 0 ? (
          <div className="flex-grow flex justify-center items-center" style={{ height: "20rem" }}>
            <h3>No Chat Sessions</h3>
          </div>
        ) : (
          <div className="pb-10">
            {filteredChatSessions.map((session, i) => (
              <div
                className={`flex justify-between items-center py-2 px-4 border-b  border-gray-300 ${
                  chatId === session.id ? "bg-gray-100 border-b-transparent" : ""
                }`}
                key={i}
              >
                {sessionToEdit === session.id ? (
                  <>
                    <div>
                      <input
                        type="text"
                        defaultValue={session.name || ""}
                        onChange={titleChangeHandler}
                        className="border border-indigo-400 text-ellipsis w-full text-sm line-clamp-1 "
                        onKeyDown={(e) => {
                          if (e.key == "Enter") changeTitle();
                        }}
                      />
                      <span className="text-gray-text text-[11px]">{getTimeDifference(session?.created_at)}</span>
                    </div>
                    <div className="flex gap-2 items-center">
                      <div className="relative group flex justify-center items-center" onClick={changeTitle}>
                        <img src={tick} alt="" className="cursor-pointer w-4 h-4" />
                      </div>
                      <div className="relative group" onClick={cancelTitleEdit}>
                        <img src={cross} alt="" className="cursor-pointer w-6 h-6" />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <NavLink
                      to={`/dashboard/contracts/details/?tab=${tabName}&id=${id}&chatId=${session.id}`}
                      className={`w-[85%] cursor-pointer`}
                    >
                      <span className="line-clamp-1 text-ellipsis w-full text-sm">
                        {session?.name || `Chat session ${formatDate(session.created_at)}`}
                      </span>
                      <span className="text-gray-text text-[11px]">{getTimeDifference(session?.created_at)}</span>
                    </NavLink>
                    <div className="flex gap-2 items-center">
                      <div className="relative group" onClick={() => editSessionName(session.id, session.name || "")}>
                        <img src={edit} alt="" className="cursor-pointer w-4 h-4" />
                      </div>
                      <div className="relative group">
                        <img
                          src={trash}
                          alt=""
                          className="cursor-pointer w-4 h-4"
                          onClick={() => deleteChatSession(session.id)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const SessionSkeleton = () => {
  return (
    <div
      role="status"
      className="max-w-md p-3 space-y-3  divide-y divide-gray-200  animate-pulse dark:divide-gray-700 md:p-4  border-b border-t border-gray-300"
    >
      <div className="flex items-center justify-between">
        <div>
          <div className="w-32 h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 mb-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 w-24 "></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
      </div>

      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default ChatHistory;

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { EvaluationModalProps } from "components/copilot/ComplianceMatrix/EvaluationModal";
import type { TemplatesModalProps } from "components/copilot/Framework/TemplatesModal";
import type { CreateProjectModalProps } from "components/modals/create-project-modal/CreateProjectModal";
import type { PreviewProposalModalProps } from "components/modals/preview-proposal-modal/PreviewProposalModal";
import type { SaveTemplateModalProps } from "components/modals/save-template-modal";
import type { StrictnessModalProps } from "components/modals/strictness-modal/StrictnessModal";
import type { WritingPromptIdeationModalProps } from "components/modals/writing-prompt-ideation-modal/WritingPromptIdeationModal";
import type { CapabilitySearchModalProps } from "components/organisms/global-modals/capability-search-modal/CapabilitySearchModal";
import type { DocumentViewerModalProps } from "components/organisms/global-modals/DocumentViewerModal";
import type { RecentFilesModalProps } from "components/organisms/global-assistant/modals/recent-files-modal/RecentFilesModal";
import type { SourceModalProps } from "components/organisms/global-modals/SourceModal";
import type { RequirementExtractionModalProps } from "components/organisms/requirement-extraction-modal/RequirementExtractionModal";

enum Modals {
  CapabilitySearchModal = "capabilitySearchModal",
  CreateProjectModal = "createProjectModal",
  DocumentViewerModal = "documentViewerModal",
  EvaluationModal = "evaluationModal",
  PreviewProposalModal = "previewProposalModal",
  RecentFilesModal = "recentFilesModal",
  RequirementExtractionModal = "requirementExtractionModal",
  SaveTemplateModal = "saveTemplateModal",
  SourceModal = "sourceModal",
  StrictnessModal = "strictnessModal",
  TemplatesModal = "templatesModal",
  WritingPromptIdeationModal = "writingPromptIdeationModal",
}

type InitialState = {
  [Modals.CapabilitySearchModal]: CapabilitySearchModalProps;
  [Modals.CreateProjectModal]: CreateProjectModalProps;
  [Modals.DocumentViewerModal]: DocumentViewerModalProps;
  [Modals.EvaluationModal]: EvaluationModalProps;
  [Modals.PreviewProposalModal]: PreviewProposalModalProps;
  [Modals.RecentFilesModal]: RecentFilesModalProps;
  [Modals.RequirementExtractionModal]: RequirementExtractionModalProps;
  [Modals.SaveTemplateModal]: SaveTemplateModalProps;
  [Modals.SourceModal]: SourceModalProps;
  [Modals.StrictnessModal]: StrictnessModalProps;
  [Modals.TemplatesModal]: TemplatesModalProps;
  [Modals.WritingPromptIdeationModal]: WritingPromptIdeationModalProps;
};

const initialState: InitialState = {
  capabilitySearchModal: {
    open: false,
    initialProps: {},
  },
  createProjectModal: {
    open: false,
  },
  documentViewerModal: {
    open: false,
    initialProps: {},
  },
  evaluationModal: {
    open: false,
    initialProps: {},
  },
  previewProposalModal: {
    open: false,
  },
  recentFilesModal: {
    open: false,
  },
  requirementExtractionModal: {
    open: false,
  },
  saveTemplateModal: {
    open: false,
    initialProps: {},
  },
  sourceModal: {
    open: false,
    initialProps: {},
  },
  strictnessModal: {
    open: false,
    initialProps: {},
  },
  templatesModal: {
    open: false,
  },
  writingPromptIdeationModal: {
    open: false,
  },
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    toggleCapabilitySearchModal: (state: InitialState, action: PayloadAction<CapabilitySearchModalProps>) => {
      return { ...state, capabilitySearchModal: { ...state.capabilitySearchModal, ...action.payload } };
    },
    toggleCreateProjectModal: (state, action: PayloadAction<CreateProjectModalProps>) => {
      return { ...state, createProjectModal: action.payload };
    },
    toggleDocumentViewerModal: (state, action: PayloadAction<DocumentViewerModalProps>) => {
      return { ...state, documentViewerModal: action.payload };
    },
    toggleEvaluationModal: (state, action: PayloadAction<EvaluationModalProps>) => {
      return { ...state, evaluationModal: action.payload };
    },
    togglePreviewProposalModal: (state, action: PayloadAction<PreviewProposalModalProps>) => {
      return { ...state, previewProposalModal: action.payload };
    },
    toggleRecentFilesModal: (state: InitialState, action: PayloadAction<RecentFilesModalProps>) => {
      return { ...state, recentFilesModal: action.payload };
    },
    toggleRequirementExtractionModal: (state, action: PayloadAction<RequirementExtractionModalProps>) => {
      return { ...state, requirementExtractionModal: action.payload };
    },
    toggleSaveTemplateModal: (state, action: PayloadAction<SaveTemplateModalProps>) => {
      return { ...state, saveTemplateModal: action.payload };
    },
    toggleSourceModal: (state: InitialState, action: PayloadAction<SourceModalProps>) => {
      return { ...initialState, sourceModal: { ...state.sourceModal, ...action.payload } };
    },
    toggleStrictnessModal: (state, action: PayloadAction<StrictnessModalProps>) => {
      return { ...state, strictnessModal: action.payload };
    },
    toggleTemplatesModal: (state, action: PayloadAction<TemplatesModalProps>) => {
      return { ...state, templatesModal: action.payload };
    },
    toggleWritingPromptsIdeationModal: (state, action: PayloadAction<WritingPromptIdeationModalProps>) => {
      return { ...state, writingPromptIdeationModal: action.payload };
    },
  },
});

export default modalsSlice.reducer;
export const {
  toggleCapabilitySearchModal,
  toggleCreateProjectModal,
  toggleDocumentViewerModal,
  toggleEvaluationModal,
  togglePreviewProposalModal,
  toggleRecentFilesModal,
  toggleRequirementExtractionModal,
  toggleSaveTemplateModal,
  toggleSourceModal,
  toggleStrictnessModal,
  toggleTemplatesModal,
  toggleWritingPromptsIdeationModal,
} = modalsSlice.actions;

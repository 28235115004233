import type { ComponentProps } from "react";

export function CommentIcon(props: ComponentProps<"svg">) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.499 10C14.499 10.3536 14.3585 10.6928 14.1085 10.9428C13.8585 11.1929 13.5193 11.3333 13.1657 11.3333H5.16569L2.49902 14V3.33333C2.49902 2.97971 2.6395 2.64057 2.88955 2.39052C3.1396 2.14048 3.47873 2 3.83236 2H13.1657C13.5193 2 13.8585 2.14048 14.1085 2.39052C14.3585 2.64057 14.499 2.97971 14.499 3.33333V10Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

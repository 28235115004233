/** @jsxImportSource @emotion/react */

import type { PropsWithChildren } from "react";

type Props = {
  color: string;
};

const ChipWithDot = ({ children, color }: PropsWithChildren<Props>) => {
  return (
    <div
      className="text-gray-500 whitespace-nowrap px-2 py-1 font-medium w-fit flex items-center justify-center text-center text-xs  rounded-full"
      css={{
        backgroundColor: `${color}19`,
        color,
      }}
    >
      <div className="rounded-full w-2 h-2 mr-2" css={{ backgroundColor: color }} />
      {children}
    </div>
  );
};

export default ChipWithDot;

import { captureException, showReportDialog as sentryShowReportDialog } from "@sentry/react";
import { isPlainObject } from "lodash";

type Extras = {
  [x: string]: unknown;
};
type Level = "fatal" | "error" | "warning" | "info";

function getExtras(param?: Response | string | Extras): Extras {
  if (!param) {
    return {};
  }

  if (param instanceof Response) {
    return { response: param };
  }

  if (typeof param === "string") {
    return { msg: param };
  }

  if (isPlainObject(param)) {
    return param as Extras;
  }

  return {};
}

function getMainMessage(level: Level, msg: string | Error): string | Error {
  if (msg instanceof Error) {
    return msg;
  }
  if ((level === "error" || level === "fatal") && typeof msg === "string") {
    return new Error(msg);
  }
  return msg;
}

const consoleLevel = {
  fatal: "error",
  error: "error",
  warning: "warn",
  info: "info",
} as const;

function reportMsg(
  level: Level,
  msg: string | Error,
  additionalMessage?: Response | string | Extras,
  ...params: unknown[]
): string | undefined {
  const eventId = captureException(getMainMessage(level, msg), {
    level,
    extra: getExtras(additionalMessage),
  });
  console[consoleLevel[level]](msg, additionalMessage, ...params);

  return eventId;
}

export function fatal(msg: string | Error, additionalMessage?: Response | string | Extras, ...params: unknown[]) {
  return reportMsg("fatal", msg, additionalMessage, ...params);
}

export function error(msg: string | Error, additionalMessage?: Response | string | Extras, ...params: unknown[]) {
  return reportMsg("error", msg, additionalMessage, ...params);
}

export function warn(msg: string, additionalMessage?: Response | string | Extras, ...params: unknown[]) {
  return reportMsg("warning", msg, additionalMessage, ...params);
}

export function info(msg: string, additionalMessage?: Response | string | Extras, ...params: unknown[]) {
  return reportMsg("info", msg, additionalMessage, ...params);
}

export function showReportDialog(eventId: string) {
  sentryShowReportDialog({
    eventId,
    title: "Feedback Form",
    subtitle: "Provide additional details to help us fix this issue.",
    subtitle2: "Your feedback is valuable to us.",
    labelComments: "What were you doing when the error occurred?",
    labelSubmit: "Send Feedback",
  });
}

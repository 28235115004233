/** @jsxImportSource @emotion/react */

import type { ChangeEvent, FocusEvent, FormEvent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { DndContext } from "@dnd-kit/core";
import Tooltip from "components/atoms/tooltip";
import tw, { theme } from "twin.macro";
import { useFlags } from "hook/useFlags";
import { useParams, useSearchParams } from "react-router-dom";
import Copilot from "components/copilot/Copilot";
import { motion } from "framer-motion";
import CopilotActionsPanel from "components/copilot/copilot-actions-panel";
import LiveOthers from "components/copilot/LiveOthers";
import RequirementsBulkUpdate from "components/organisms/requirements-bulk-update/RequirementsBulkUpdate";
import { ProjectHeader } from "components/molecules/project-header";
import SideNavigation from "components/molecules/side-navigation/SideNavigation";
import { RoomProvider } from "YJSProvider/createYJSContext";
import { ScreenSpinner } from "utils/icons";
import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { useDrag as useBeautifulDrag } from "components/copilot/hooks";
import styles from "./ProjectDetails.module.css";
import { clearProject, getProject } from "store/reducers/projectReducer";
import { fetchFileStorage } from "store/reducers/driveReducerSlice";
import { getProjectAttachments, resetProjectAttachmentsState } from "store/reducers/projectAttachmentsReducer";
import { resetAssistantState } from "store/reducers/writing-assistant/writingAssistantReducer";
import { resetBannerState } from "store/reducers/copilot/copilotBannerReducer";
import { resetContentSearchState } from "store/reducers/copilot/contentSearchReducer";
import { resetRequirementsDrawerState } from "store/reducers/copilot/requirementsDrawerReducer";
import { resetRequirementsState } from "store/reducers/copilot/requirementsReducer";
import { toggleAssistant } from "store/reducers/copilot/copilotDrawerReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { getInternalContractDetails, updateInternalContractDetails } from "api/api";
import { useNotification } from "context/notificationContext";
import { CopilotPresencePage } from "types/Presence";
import { TOP_BANNER } from "const-values/Banner";
import {
  useCloseInifiniteResponseGenerations,
  useCatchResizeObserver,
  useDrag,
  usePollActiveProject,
  usePrefetchCaptureForms,
  useInspectCorruptedObjects,
  useInitWritingAssistant,
  useResizeAssistantPanels,
} from "./hooks";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import { useMaintainLocation } from "hook/useMaintainLocation";
import type { Project } from "types/Project";
import { AssistantProvider } from "components/copilot/writing-assistant-drawer/AssistantContext";
import { toggleCommentsDrawer } from "store/reducers/proposal/commentsDrawerSlice";
import ProjectWorkflowSelectionPopover from "components/organisms/project-workflow-selection-popover";
import { LayoutGrid } from "lucide-react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

const ProjectDetails = () => {
  useMaintainLocation();
  const [searchParams] = useSearchParams();

  const internalContractId: string = searchParams.get("id")?.toLocaleLowerCase() || "";
  const { fullscreen, sidebarVisible } = useAppSelector((root) => root.copilot);
  const flags = useFlags();

  return (
    <div
      className="bg-[#f8f9fa] z-[3] flex flex-row duration-150 absolute top-[65px] right-0 left-[234px] bottom-0 overflow-hidden"
      css={[
        fullscreen && tw`duration-200 top-0 left-0 right-0`,
        !!flags.topBannerV2?.bannerCopy && {
          top: fullscreen ? TOP_BANNER.HEIGHT : `calc(65px + ${TOP_BANNER.HEIGHT}px)`,
        },
        fullscreen && sidebarVisible && tw`left-[234px]`,
      ]}
    >
      <RoomProvider
        authParams={["copilot", internalContractId, internalContractId]}
        id={internalContractId}
        fallback={<LoadingText />}
      >
        <ProjectRoom />
      </RoomProvider>
    </div>
  );
};

export default ProjectDetails;

const ProjectRoom = () => {
  useCatchResizeObserver();
  const { leftPanelRef, rightPanelRef, containerRef, defaultOpenSize, shouldAnimate, rightPanelMinSize } =
    useResizeAssistantPanels();
  const { setToast } = useNotification();
  const { volumeId } = useParams();
  const [searchParams] = useSearchParams();
  const tabName = searchParams.get("tab")?.toLocaleLowerCase() || CopilotPresencePage.Proposal;
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase() || "";
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const [internalContractDetails, setInternalContractDetails] = useState<Project>({} as Project);
  const [contractDetails, setContractDetails] = useState({});
  const [title, setTitle] = useState("");
  const [titleEditable, setTitleEditable] = useState(false);
  const [clientNameEditable, setClientNameEditable] = useState(false);
  const [clientName, setClientName] = useState("");
  const [assistantHovered, setAssistantHovered] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const { sensors, handleDragCancel, handleDragEnd, handleDragStart } = useDrag();
  const [isPinned, setIsPinned] = usePersistedStorage<boolean>("vultron_pin_navigation", false);
  usePollActiveProject(internalContractId);
  useCloseInifiniteResponseGenerations();
  usePrefetchCaptureForms();
  useInspectCorruptedObjects();
  useInitWritingAssistant();

  useEffect(() => {
    if (!internalContractId) return;
    dispatch(resetBannerState());
    dispatch(getProject({ internalContractId, triggerDocRefresh: true }));
    dispatch(fetchFileStorage());
    dispatch(getProjectAttachments(internalContractId));
    return () => {
      dispatch(clearProject());
      dispatch(resetAssistantState());
      dispatch(resetRequirementsDrawerState());
      dispatch(resetContentSearchState());
      dispatch(resetRequirementsState());
      dispatch(resetProjectAttachmentsState());
    };
  }, [dispatch, internalContractId]);

  // Fetch contract information and mark as viewed
  useEffect(() => {
    if (!internalContractId) return;
    getInternalContractDetails(internalContractId)
      .then((res) => {
        setInternalContractDetails(res?.data || {});
        const contractDetails = { ...res?.data?.internal_contract?.contract, notes: res?.data?.notes };
        setContractDetails(contractDetails || {});
      })
      .catch(() => {
        setToast.error({
          title: "Unable to load project",
          msg: "We were unable to load the project due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      })
      .finally(() => {});
    if (forceRefresh) dispatch(getProject({ internalContractId, triggerDocRefresh: true }));

    setForceRefresh(false);
  }, [internalContractId, forceRefresh, dispatch, setToast]);

  useEffect(() => {
    const internalCDetails = JSON.parse(JSON.stringify(internalContractDetails));
    setTitle(internalCDetails?.internal_contract?.project_name);
    setClientName(internalCDetails?.internal_contract?.client_name || "");
  }, [internalContractDetails]);

  const titleChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTitle(value);
  }, []);

  const clientNameChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setClientName(value);
  }, []);

  const changeTitle = async (e: FormEvent<HTMLFormElement> | FocusEvent<HTMLInputElement>) => {
    e.preventDefault();

    setTitleEditable(false);
    if (!internalContractId) {
      return;
    }
    await updateInternalContractDetails(internalContractId, { project_name: title });
  };

  const changeClientName = async (e: FormEvent<HTMLFormElement> | FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    setClientNameEditable(false);
    if (!internalContractId) return;

    await updateInternalContractDetails(internalContractId, { client_name: clientName });
  };
  const editTitle = () => {
    setTitleEditable(true);
  };

  const isProjectLoading = useMemo(
    () =>
      (internalContractDetails && Object.keys(internalContractDetails).length === 0) ||
      (contractDetails && Object.keys(contractDetails).length === 0),
    [contractDetails, internalContractDetails],
  );
  const dragEnd = useBeautifulDrag();

  const containerStyling = isPinned ? tw`w-[calc(100vw-230px)] left-[230px]` : tw`w-[calc(100vw-92px)] left-[92px]`;

  return (
    <DragDropContext onDragEnd={dragEnd}>
      <DndContext
        sensors={sensors}
        collisionDetection={verticalSortableListCollisionDetection}
        onDragEnd={(event) => handleDragEnd(event)}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
      >
        <div className="flex">
          {!volumeId && <SideNavigation isPinned={isPinned} setIsPinned={setIsPinned} />}
          <div className="flex flex-col relative duration-300" css={[!volumeId ? containerStyling : tw`w-[100vw]`]}>
            {!volumeId && (
              <ProjectHeader
                isLoading={isProjectLoading}
                back={
                  tabName !== CopilotPresencePage.AskAi
                    ? `/dashboard/contracts`
                    : `/dashboard/contracts/details/?tab=${CopilotPresencePage.Proposal}&id=${internalContractId}&chatId=0ds`
                }
                actions={
                  <div className="flex items-center justify-end gap-2 w-[80px]">
                    <LiveOthers tab={tabName} avatarSize={22} />
                    <div className="flex flex-row items-center gap-1.5">
                      <Tooltip content="Assistant">
                        <button
                          className="flex justify-center items-center h-8 w-8 relative"
                          onMouseEnter={() => setAssistantHovered(true)}
                          onMouseLeave={() => setAssistantHovered(false)}
                          onClick={() => {
                            dispatch(toggleAssistant(true));
                            dispatch(toggleCommentsDrawer(false));
                          }}
                        >
                          {assistantHovered && <div className={`${styles.animatedGradient} pointer-events-none`} />}

                          <motion.div
                            className="z-[1]"
                            initial={{ rotate: 0 }}
                            animate={{
                              rotate: assistantHovered ? 180 : 0,
                            }}
                            transition={{
                              type: "spring",
                              damping: 12,
                              stiffness: 100,
                              duration: assistantHovered ? 0.5 : 0.5,
                              ease: "easeOut",
                            }}
                          >
                            <span
                              className="duration-700 text-xl"
                              style={{
                                color: assistantHovered ? "#fff" : theme`colors.gray.darkest`,
                                fill: assistantHovered ? "#fff" : "transparent",
                              }}
                            >
                              ✦
                            </span>
                          </motion.div>
                          <div
                            className="flex absolute w-full h-full items-center justify-center duration-500"
                            style={{ transform: assistantHovered ? "scale(1)" : "scale(0)" }}
                          >
                            <div className={`${styles.animatedDiv}`} />
                            <div className={`${styles.animatedDiv2}`} />
                          </div>
                        </button>
                      </Tooltip>
                      {flags.showProjectWorkflows && (
                        <ProjectWorkflowSelectionPopover>
                          <div>
                            <Tooltip content="Select Workflow">
                              <button
                                className="text-[22px] h-8 w-8 text-gray-darkest rounded-md flex justify-center items-center duration-150 bg-transparent hover:bg-action-lightest hover:brightness-95"
                                aria-label="Workflow selection button"
                              >
                                <LayoutGrid size={22} />
                              </button>
                            </Tooltip>
                          </div>
                        </ProjectWorkflowSelectionPopover>
                      )}
                    </div>
                  </div>
                }
                header={
                  <div className="flex items-center w-full text-gray-darkest">
                    <div className="flex items-center gap-2 w-full min-w-0">
                      <form onSubmit={changeTitle} className="flex-1 min-w-0 flex justify-end items-center">
                        {titleEditable ? (
                          <input
                            type="text"
                            value={title}
                            className="w-full min-w-0 px-2 py-1 border-none outline-none bg-white text-end"
                            onChange={titleChangeHandler}
                            onBlur={changeTitle}
                            autoFocus
                          />
                        ) : (
                          <div
                            className="inline-block px-2 py-1 rounded-md truncate hover:bg-gray-100 cursor-text text-end"
                            onClick={editTitle}
                            title="Project Name"
                          >
                            {title}
                          </div>
                        )}
                      </form>

                      <span className="text-gray-400 select-none flex-shrink-0">/</span>

                      <form onSubmit={changeClientName} className="flex-1 min-w-0 flex items-center">
                        {clientNameEditable ? (
                          <input
                            type="text"
                            value={clientName}
                            className="w-full min-w-0 px-2 py-1 border-none outline-none bg-white text-start"
                            onChange={clientNameChangeHandler}
                            onBlur={changeClientName}
                            autoFocus
                          />
                        ) : (
                          <div
                            className="inline-block px-2 py-1 rounded-md truncate hover:bg-gray-100 cursor-text text-start"
                            onClick={() => setClientNameEditable(true)}
                            title="Client Name"
                          >
                            {clientName}
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                }
              />
            )}
            <AssistantProvider>
              <div
                ref={containerRef}
                className="flex h-[calc(100%-40px)] w-full"
                css={[volumeId ? tw`h-full` : tw`h-[calc(100%-40px)]`]}
              >
                <PanelGroup direction="horizontal">
                  <Panel ref={leftPanelRef} minSize={30}>
                    {internalContractDetails && (
                      <Copilot
                        isProjectLoading={isProjectLoading}
                        contractDetails={contractDetails}
                        internalContractId={internalContractId}
                        internalContractDetails={internalContractDetails}
                        setForceRefreshContract={setForceRefresh}
                        setInternalContractDetails={setInternalContractDetails}
                      />
                    )}
                  </Panel>
                  <PanelResizeHandle className="z-[3] w-px bg-[#d1d5db] relative flex justify-center delay-300 duration-150 hover:bg-gray-darkest hover:z-[11] hover:scale-x-[2.5] hover:rounded-full" />
                  <Panel
                    ref={rightPanelRef}
                    collapsible
                    onExpand={() => dispatch(toggleAssistant(true))}
                    onCollapse={() => dispatch(toggleAssistant(false))}
                    defaultSize={defaultOpenSize}
                    minSize={rightPanelMinSize}
                    className="z-[3]"
                    style={shouldAnimate ? { transition: "flex-grow 0.2s ease-in-out" } : undefined}
                  >
                    <CopilotActionsPanel />
                  </Panel>
                </PanelGroup>

                <RequirementsBulkUpdate />
              </div>
            </AssistantProvider>
          </div>
        </div>
      </DndContext>
    </DragDropContext>
  );
};

const LoadingText = () => {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((currentDots) => {
        if (currentDots.length < 3) {
          return currentDots + ".";
        }
        return ".";
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="p-20 w-full my-auto flex flex-col items-center justify-center gap-3">
      <ScreenSpinner />
      <div className="relative text-gray-400">
        <span>Establishing secure connection</span>
        <span className="absolute left-full top-0">{dots}</span>
      </div>
    </div>
  );
};

import * as logger from "utils/log";

const copyText = async (content: string, onSuccess?: () => void) => {
  const normalizedContent = content.replace(/&nbsp;/g, " ");

  try {
    await navigator.clipboard.writeText(normalizedContent);
    onSuccess?.();
  } catch (err) {
    if (err instanceof Error) {
      logger.error(err, "Failed to copy text");
    } else {
      logger.error("Failed to copy text", `Unexpected error type ${typeof err}: ${err}`);
    }
  }
};

export default copyText;

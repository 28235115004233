import type { ProposalDocument } from "types/Proposal";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInternalContractDetails } from "api/api";
import axios from "axios";
import keyBy from "lodash/keyBy";
import type { RootState } from "store/storeTypes";
import type { ProjectProgress } from "types/InternalContract";
import type { Project } from "types/Project";
import type { Requirement } from "types/Requirement";

export const getProject = createAsyncThunk<
  { project: Project; triggerDocRefresh: boolean },
  { internalContractId: string; triggerDocRefresh?: boolean },
  { state: RootState }
>(
  "project/getProject",
  async ({ internalContractId, triggerDocRefresh = false }) => {
    return getInternalContractDetails(internalContractId).then((res) => {
      return { project: res.data, triggerDocRefresh };
    });
  },
  {
    condition: (_, { getState }) => {
      const { project } = getState();
      const { isLoadingProject } = project;

      if (isLoadingProject) return false;
    },
  },
);

export const getAtlasRequirements = createAsyncThunk<Requirement[], string>(
  "project/getRequirements",
  async (internalContractId) => {
    const { data } = await axios.get<Requirement[]>(`/autopilot/${internalContractId}/requirements`);

    return data;
  },
);

type State = {
  activeProject?: Project;
  progress?: ProjectProgress;
  isLoadingProject: boolean;
  groupedAtlasRequirements: Record<string, Requirement> | null;
  isLoadingAtlasRequirements: boolean;
  proposals: ProposalDocument[];
};

const initialState: State = {
  activeProject: undefined,
  progress: undefined,
  isLoadingProject: false,
  groupedAtlasRequirements: null,
  isLoadingAtlasRequirements: false,
  proposals: [],
};

const projectReducer = createSlice({
  name: "project",
  initialState,
  reducers: {
    clearProject: () => {
      return initialState;
    },
    setActiveProject: (state: State, action: PayloadAction<State["activeProject"]>) => {
      state.activeProject = action.payload;
    },
    setProposals: (state: State, action: PayloadAction<State["proposals"]>) => {
      state.proposals = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProject.pending, (state) => {
        state.isLoadingProject = true;
      })
      .addCase(getProject.fulfilled, (state, action) => {
        if (action.payload.triggerDocRefresh) {
          state.activeProject = action.payload.project;
        } else {
          const { notes, milestones, links, internal_contract } = action.payload.project;
          if (!state.activeProject) {
            state.activeProject = action.payload.project;
          } else {
            state.activeProject.internal_contract = internal_contract;
            state.activeProject.links = links;
            state.activeProject.notes = notes;
            state.activeProject.milestones = milestones;
          }
        }
        state.isLoadingProject = false;
      })
      .addCase(getProject.rejected, (state) => {
        state.isLoadingProject = false;
      })
      .addCase(getAtlasRequirements.pending, (state) => {
        state.isLoadingAtlasRequirements = true;
      })
      .addCase(getAtlasRequirements.fulfilled, (state, action) => {
        state.groupedAtlasRequirements = keyBy(action.payload, "id");
        state.isLoadingAtlasRequirements = false;
      })
      .addCase(getAtlasRequirements.rejected, (state) => {
        state.isLoadingAtlasRequirements = false;
      });
  },
});

export const { setActiveProject, clearProject, setProposals } = projectReducer.actions;

export default projectReducer.reducer;

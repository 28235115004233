import type { ComplianceMatrixRow } from "components/copilot/CopilotSchemaTypes";
import groupBy from "lodash/groupBy";
import type { ToImmutable } from "YJSProvider/LiveObjects";

export const groupComplianceMatrix = (matrix: ToImmutable<ComplianceMatrixRow>[]) => {
  const grouped = groupBy(
    (
      matrix.filter(
        (row) => !row.requirement.soft_deleted && !!row.proposal_reference.section_id && !row.requirement.skipped,
      ) || []
    ).sort((a, b) => (a.requirement.section_order || 0) - (b.requirement.section_order || 0)),
    (row) => row.proposal_reference.section_id,
  );

  return grouped;
};

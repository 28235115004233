import { ToolbarTable } from "../toolbar-table/ToolbarTable";
import { ToolbarAlignment } from "../ToolbarAlignment";
import { ToolbarBlock } from "../ToolbarBlock";
import { ToolbarCommands } from "../ToolbarCommands";
import { ToolbarHeadings } from "../ToolbarHeadings";
import { ToolbarInline } from "../ToolbarInline";
import { ToolbarMedia } from "../ToolbarMedia";
import type { ToolbarSection } from "./types";
import { ToolbarLineSpacing } from "../ToolbarLineSpacing";
import { ToolbarIndent } from "../ToolbarIndent";
import { ToolbarDictate } from "../ToolbarDictate";
import { ToolbarLink } from "../ToolbarLink";
import { ToolbarMargins } from "../toolbar-margins/ToolbarMargins";
import { ToolbarFontFamily } from "../ToolbarFontFamily";
import { ToolbarFontSize } from "../ToolbarFontSize";
import { ToolbarScale } from "../ToolbarScale";

// Array of toolbar sections with their respective components
// We then use the useToolbarOverflow hook to determine if a section is overflowing
// If it is overflowing we will hide it and display it in a dropdown
export const toolbarSections: ToolbarSection[] = [
  { key: "commands", component: ToolbarCommands },
  {
    key: "headings-scale",
    component: ({ editor }) => (
      <div className="flex gap-1">
        <ToolbarHeadings editor={editor} />
        <ToolbarScale />
      </div>
    ),
  },
  {
    key: "fontFamily-fontSize",
    component: ({ editor }) => (
      <div className="flex gap-1 px-2">
        <ToolbarFontFamily editor={editor} />
        <ToolbarFontSize editor={editor} />
      </div>
    ),
  },
  { key: "inline", component: ToolbarInline },
  { key: "block", component: ToolbarBlock },
  { key: "indent", component: ToolbarIndent },
  {
    key: "lineSpacing-margins",
    component: ({ editor }) => (
      <>
        <ToolbarLineSpacing editor={editor} />
        <ToolbarMargins editor={editor} />
      </>
    ),
  },
  { key: "alignment", component: ToolbarAlignment },
  {
    key: "table-media-link",
    component: ({ editor }) => (
      <>
        <ToolbarTable editor={editor} />
        <ToolbarMedia editor={editor} />
        <ToolbarLink editor={editor} />
      </>
    ),
  },
  { key: "dictate", component: ToolbarDictate },
];

import axios from "axios";
import { getWorkSpaces, setAuth } from "store/reducers/authReducerSlice";
import { useNavigate } from "react-router-dom/dist";
import { useNotification } from "context/notificationContext";
import { useEffect } from "react";
import { useAppDispatch } from "store/storeTypes";
import { AUTH } from "const-values/testIds";
import * as logger from "utils/log";

// ---------------- Auth 1st screen -------------------
const ContinueWithEmail = () => {
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const auth0OperationSuccessful = params.get("success");
  const message = params.get("message");

  useEffect(() => {
    if (auth0OperationSuccessful !== null) {
      if (auth0OperationSuccessful === "true") {
        const successMessage = message || "Log in using your email and password to continue.";
        setToast.success({ title: "Success", msg: successMessage });
      } else {
        const errMsg = message || "An error occurred. Please retry or if the issue persists contact support@vultron.ai";
        setToast.error({
          title: "Error",
          msg: errMsg,
        });
      }
    }
  }, [auth0OperationSuccessful, message]);

  const handleLogin = async () => {
    const synthetic_test_secret = localStorage.getItem("synthetic_test_secret");
    if (synthetic_test_secret) {
      axios.defaults.headers["X-Synthetic-Test-User-Token"] = synthetic_test_secret;
    }
    axios
      .post("/users/auth0/login", {}, { withCredentials: true })
      .then((response) => {
        const maybeSyntheticToken = response.data.user_token;
        if (maybeSyntheticToken) {
          localStorage.setItem("vultron_user_token", JSON.stringify(maybeSyntheticToken));
          localStorage.setItem("vultron_user_sso", JSON.stringify(false));
          axios.defaults.headers.common["Authorization"] = `Bearer ${maybeSyntheticToken}`;
          axios.defaults.headers["X-Authorization-SSO"] = false;
          dispatch(getWorkSpaces());
          dispatch(
            setAuth({
              isAuthenticated: true,
              isLoading: false,
            }),
          );
          navigate("/select-workspace");
          return;
        }
        const redirectUrl = response.data.url;
        window.location.href = redirectUrl;
      })
      .catch((error) => {
        logger.error(error, "Log in error");
      });
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center ">
        <h1 className="text-4xl font-medium py-1">Welcome to Vultron</h1>
        <p className="text-gray-600 py-2">Log in with your work email.</p>
      </div>
      <button
        className="bg-midnight-900 duration-150 hover:bg-midnight-700 text-white flex my-2 w-[450px] flex-col rounded-md mt-6"
        onClick={handleLogin}
        data-testid={AUTH.loginButton}
      >
        <span className="w-full flex p-4 items-center gap-3 justify-center">
          {" "}
          <span>Log in</span>{" "}
        </span>
      </button>
    </>
  );
};

export default ContinueWithEmail;

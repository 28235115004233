import { getProject } from "store/reducers/projectReducer";
import { INTERNAL_DOCUMENT_CLASSIFICATIONS, NO_CLASSIFICATION } from "./constants";
import type { InternalDocument } from "types/Project";
import type { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import { useAppDispatch } from "store/storeTypes";
import { useNotification } from "context/notificationContext";
import { useOutletContext } from "react-router-dom";
import { useMemo } from "react";

import axios from "axios";

export const useGetDocumentClassificationOptions = (doc: InternalDocument): MenuItem<any>[] => {
  const dispatch = useAppDispatch();
  const { internalContractId } = useOutletContext<{
    internalContractId: string;
  }>();
  const { setToast } = useNotification();

  const classificationOptions = useMemo(() => {
    return Object.keys(INTERNAL_DOCUMENT_CLASSIFICATIONS).map((classification) => {
      return {
        key: classification,
        label: INTERNAL_DOCUMENT_CLASSIFICATIONS[classification],
        onSelect: () => {
          axios
            .put(`/contracts/internal/document/${doc.id}/`, {
              classification: classification !== NO_CLASSIFICATION ? classification : null,
            })
            .then(() => {
              dispatch(getProject({ internalContractId, triggerDocRefresh: true }));
            })
            .catch(() => {
              setToast.error({
                title: "Unable to update document classification",
                msg: "We were unable to update the document classification due to a technical issue on our end. Please refresh and try again.",
              });
            });
        },
      };
    });
  }, [dispatch, doc.id, internalContractId, setToast]);

  return classificationOptions;
};

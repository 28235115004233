import { jwtDecode } from "jwt-decode";

const REFRESH_TIME = 5 * 60 * 1000; // 5 minutes

// Function to set the refresh timer in localStorage
export const setSessionRefreshTimer = () => {
  const refreshTime = Date.now() + REFRESH_TIME;
  localStorage.setItem("vultron_refresh_timer", refreshTime.toString());
};

const getSessionRefreshTimer = () => {
  const timer = localStorage.getItem("vultron_refresh_timer");
  return timer ? parseInt(timer, 10) : null;
};

export const isSessionRefreshRequired = () => {
  // Check if the refresh timer is set and not expired
  const timer = getSessionRefreshTimer();
  if (timer && Date.now() < timer) return false;

  // Set the refresh timer to avoid multiple requests refreshing
  setSessionRefreshTimer();
  return true;
};

export const isJWTExpired = (token: string) => {
  if (!token) return true;
  try {
    const decoded: { exp: number } = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    return true;
  }
};

export function getDiffInDays(startDate: Date, endDate: Date) {
  const diffInMilliseconds = endDate.getTime() - startDate.getTime();

  return Number.isNaN(diffInMilliseconds) ? NaN : Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24));
}

export function isSameDay(date1: Date, date2: Date) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

const DAYS_IN_YEAR = 365;
const DAYS_IN_MONTH = 30;

export const formatTimeLeft = (date: string): string => {
  const diffInDays = Math.abs(getDiffInDays(new Date(date), new Date()));

  if (Number.isNaN(diffInDays)) {
    return "";
  }

  if (Math.abs(diffInDays) >= DAYS_IN_YEAR) {
    const years = Math.round(diffInDays / DAYS_IN_YEAR);
    return years === 1 ? "one year" : `${Math.abs(years)} years`;
  }

  if (Math.abs(diffInDays) >= DAYS_IN_MONTH) {
    const months = Math.round(diffInDays / DAYS_IN_MONTH);
    return months === 1 ? "one month" : `${Math.abs(months)} months`;
  }

  if (Math.abs(diffInDays) === 1) {
    return "one day";
  }

  return `${Math.abs(diffInDays)} days`;
};

function getOrdinalSuffix(day: number): string {
  if (day >= 11 && day <= 13) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export type ValidDateFormats = "MM/DD/YYYY" | "YYYY-MM-DD" | "MMMM DD, YYYY" | "MM-DD-YYYY" | "MMM Do";

const dateFormats: {
  [format in Exclude<ValidDateFormats, "YYYY-MM-DD" | "MM-DD-YYYY" | "MMM Do">]: Intl.DateTimeFormatOptions;
} = {
  "MM/DD/YYYY": { month: "2-digit", day: "2-digit", year: "numeric" },
  "MMMM DD, YYYY": {
    month: "long",
    day: "2-digit",
    year: "numeric",
  },
};

export function dateFormatter(date: string | Date | undefined, format: ValidDateFormats, fallback = "-"): string {
  if (!date) {
    return fallback;
  }

  const dateObj = typeof date === "string" ? new Date(date) : date;

  if (Number.isNaN(dateObj.getTime())) {
    return fallback;
  }

  if (format === "MM/DD/YYYY" || format === "MMMM DD, YYYY") {
    return new Intl.DateTimeFormat("en-US", {
      ...dateFormats[format],
      timeZone: "UTC",
    }).format(dateObj);
  }

  const month = dateObj.toLocaleString("en-US", { month: "short", timeZone: "UTC" });
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  if (format === "YYYY-MM-DD") {
    return `${year}-${String(dateObj.getUTCMonth() + 1).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
  }

  if (format === "MMM Do") {
    return `${month} ${day}${getOrdinalSuffix(day)}`;
  }

  return `${String(dateObj.getUTCMonth() + 1).padStart(2, "0")}-${String(day).padStart(2, "0")}-${year}`;
}

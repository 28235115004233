import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import type { ChatSessionDocument, FileMention, InputFieldType } from "types/Assistants/types";

export const createInputFieldData = ({
  contentLibraryFiles,
  uploadedFiles,
  projectScoped,
  text,
  inputType,
}: {
  contentLibraryFiles: FileMention[] | null;
  uploadedFiles: ChatSessionDocument[] | null;
  projectScoped?: FileMention[] | null;
  text?: string;
  inputType: InputFieldType;
}) => {
  if (inputType === INPUT_FIELD_OPTIONS.allFiles) {
    return {
      action: "",
      input_field_type: INPUT_FIELD_OPTIONS.allFiles,
      data: {
        uploaded:
          uploadedFiles?.map((file) => ({
            file_id: file.id,
            file_name: file.name,
            file_url: file.secure_preview_url || "",
          })) || [],
        content_library:
          contentLibraryFiles?.map((file) => ({
            file_id: file.id,
            file_name: file.name,
          })) || [],
        project_scoped:
          projectScoped?.map((file) => ({
            file_id: file.id,
            file_name: file.name,
          })) || [],
      },
    };
  } else if (inputType === INPUT_FIELD_OPTIONS.contentLibrary) {
    return {
      action: "",
      input_field_type: INPUT_FIELD_OPTIONS.contentLibrary,
      data: {
        files:
          contentLibraryFiles?.map((file) => ({
            file_id: file.id,
            file_name: file.name,
          })) || [],
      },
    };
  } else if (inputType === INPUT_FIELD_OPTIONS.files) {
    return {
      action: "",
      input_field_type: INPUT_FIELD_OPTIONS.files,
      data: {
        files:
          uploadedFiles?.map((file) => ({
            file_id: file.id,
            file_name: file.name,
            file_url: file.secure_preview_url || "",
          })) || [],
      },
    };
  } else if (inputType === INPUT_FIELD_OPTIONS.dynamic) {
    return {
      action: "",
      input_field_type: INPUT_FIELD_OPTIONS.dynamic,
      data: {
        text: text || "",
        content_library:
          contentLibraryFiles?.map((file) => ({
            file_id: file.id,
            file_name: file.name,
          })) || [],
        uploaded:
          uploadedFiles?.map((file) => ({
            file_id: file.id,
            file_name: file.name,
            file_url: file.secure_preview_url || "",
          })) || [],
        project_scoped:
          projectScoped?.map((file) => ({
            file_id: file.id,
            file_name: file.name,
          })) || [],
      },
    };
  }
};

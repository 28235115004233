/** @jsxImportSource @emotion/react */

import { Avatar } from "components/molecules/avatar";
import LiveDate from "components/molecules/live-date";
import { AVATAR_BG_COLOR } from "const-values/assistants/constants";
import { useAppSelector } from "store/storeTypes";
import { theme } from "twin.macro";

interface GenericBlockProps {
  message: string;
  updatedAt?: string;
}

const GenericBlock = ({ message, updatedAt }: GenericBlockProps) => {
  const { currentUser } = useAppSelector((root) => root.auth);

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="gap-2 flex">
        <Avatar
          size={26}
          id={currentUser?.username}
          name={currentUser?.username}
          className="text-base"
          isCircle={false}
          bgColor={AVATAR_BG_COLOR}
          textColor={theme`colors.gray.darkest`}
        />
        <div className="flex text-sm mt-0.5">{message}</div>
        {updatedAt && (
          <div className="flex justify-end">
            <LiveDate date={updatedAt} duration={30000} />
          </div>
        )}
      </div>
    </div>
  );
};

export default GenericBlock;

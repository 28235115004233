import { getFile } from "api/api";
import { useSourceGroups } from "components/organisms/ResponsesWithSources/hooks";
import SourceGroupList from "components/organisms/global-modals/capability-search-modal/SourceGroupList";
import { ArrowLeft, Download } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import type { WorkspaceFile } from "types/FileStorage";

const Sources = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fileId = searchParams.get("file_id");
  const fileContentId = searchParams.get("file_content_id");
  const [fileDetails, setFileDetails] = useState<WorkspaceFile | null>(null);

  useEffect(() => {
    if (!fileId) return;
    getFile(fileId)
      .then((res) => {
        const file: WorkspaceFile = res?.data;
        setFileDetails(file);
      })
      .catch(() => {});
    return () => {
      setFileDetails(null);
    };
  }, [fileId]);

  const initialChunkIds = useMemo(() => (fileContentId ? [fileContentId] : []), [fileContentId]);

  const { data, error, loading, expandContent, canExpandDown, canExpandUp } = useSourceGroups(
    "content_library",
    fileId || "",
    initialChunkIds,
  );

  const preExpanded = useRef(false);
  useEffect(() => {
    if (data && !loading && !preExpanded.current) {
      data.forEach((group, idx) => {
        if (group.chunk_ids.length === 1) {
          expandContent(idx, true);
          expandContent(idx, false);
        }
      });
      preExpanded.current = true;
    }
  }, [data, loading, expandContent]);

  const scrollOnResize = (container: HTMLDivElement, scrollOptions: ScrollToOptions) => {
    const observer = new ResizeObserver(() => {
      container.scrollTo({ ...scrollOptions, behavior: "smooth" });
      observer.disconnect();
    });
    observer.observe(container);
  };

  return (
    <div className="inline overflow-auto bg-white h-full">
      <div className="flex flex-col px-6 py-6">
        <button onClick={() => navigate("/dashboard", { replace: true })}>
          <div className="text-sm flex items-center gap-1.5 mb-10">
            <ArrowLeft size={16} />
            Return to Dashboard
          </div>
        </button>
        <div className="pl-8">
          {!!fileDetails && (
            <div className="text-xl font-medium mb-4 flex items-center gap-2">
              {fileDetails.name}
              <a
                href={fileDetails.download_url}
                download={fileDetails.name}
                className="text-[18px] pb-0.5 text-gray-500 duration-150 hover:text-gray-600"
              >
                <Download size={18} />
              </a>
            </div>
          )}
          {loading && <div>Loading...</div>}
          {error && <div className="text-red-400 text-xs">Error loading content</div>}
          {!loading && data && (
            <div className="max-w-[80vw]">
              <SourceGroupList
                data={data}
                expandContent={expandContent}
                canExpandUp={canExpandUp}
                canExpandDown={canExpandDown}
                scrollOnResize={scrollOnResize}
                highlightInitialContent
                isFullPage
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sources;

/** @jsxImportSource @emotion/react */
import Popover from "components/atoms/popover/Popover";
import Tooltip from "components/atoms/tooltip/Tooltip";
import ContentDrivePopoverContent from "components/molecules/content-drive-popover-content";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getProjectAttachments } from "store/reducers/projectAttachmentsReducer";
import {
  insertAssistantFiles,
  removeAssistantFiles,
  setEnableInternet,
} from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import type { BaseFile, WorkspaceFile } from "types/FileStorage";
import AttachmentsPopover from "../../attachments-popover";
import { CONTENT_PROPS, SOURCE_TO_META, Source } from "../../constants";
import { transformToFileMention } from "../../utils";
import { useSearchParams } from "react-router-dom";
import { ChevronDown, FolderClosed, ListFilter } from "lucide-react";
import { PopoverAnchor } from "@radix-ui/react-popover";
import AddAttachmentsButton from "./AddAttatchmentsButton";
import { pluralizeWord } from "utils/string";
import type { InstructionExtraState } from "../../hooks";
import { useNestedDriveMeta } from "components/molecules/content-drive-popover-content/hooks";
import { getFolderFiles } from "utils/assistants/utils";

type Props = {
  instructionExtraState: InstructionExtraState;
  containerNode: HTMLDivElement | null;
};

const FileSelection = ({ containerNode, instructionExtraState }: Props) => {
  const { docs, folders } = useNestedDriveMeta();
  const activeSession = useAppSelector((root) => root.writingAssistant.activeSession);
  const indexedDocs = useMemo(() => docs?.filter((doc) => doc.indexed) ?? [], [docs]);
  const allFiles = useMemo(() => [...indexedDocs, ...getFolderFiles(folders)], [folders, indexedDocs]);

  useEffect(() => {
    if (!activeSession?.workflow) {
      handleSelectedDriveFiles(true, allFiles);
    }
  }, [activeSession?.workflow, activeSession?.id, allFiles]);

  const [query, setQuery] = useState<string>("");
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
  const dispatch = useAppDispatch();
  const { selectedProjectFiles, selectedContentLibraryFiles, enableInternet } = useAppSelector(
    (root) => root.writingAssistant,
  );
  const { maxFileCounts, excludedComponents } = instructionExtraState;
  const selectedContentLibraryFileIds = useMemo(
    () => selectedContentLibraryFiles.map(({ id }) => id),
    [selectedContentLibraryFiles],
  );
  const selectedProjectFileIds = useMemo(() => selectedProjectFiles.map(({ id }) => id), [selectedProjectFiles]);
  const handleSelectedProjectFiles = useCallback(
    (checked: boolean, files: Pick<WorkspaceFile, "id" | "name" | "file_extension_type">[]) => {
      if (checked) {
        dispatch(insertAssistantFiles({ selectedProjectFiles: transformToFileMention(files) }));
      } else {
        dispatch(removeAssistantFiles(files.map(({ id }) => id)));
      }
      dispatch(setEnableInternet(false));
    },
    [dispatch],
  );
  const handleSelectedDriveFiles = useCallback(
    (checked: boolean, files: BaseFile[]) => {
      if (checked) {
        dispatch(insertAssistantFiles({ selectedContentLibraryFiles: transformToFileMention(files) }));
      } else {
        dispatch(removeAssistantFiles(files.map(({ id }) => id)));
      }
      dispatch(setEnableInternet(false));
    },
    [dispatch],
  );
  return (
    <div className="flex items-center gap-x-2">
      {!excludedComponents?.contentLibrary && (
        <Popover
          content={
            <ContentDrivePopoverContent
              handleSelectedFiles={handleSelectedDriveFiles}
              onClear={(files) => dispatch(removeAssistantFiles(files.map(({ id }) => id)))}
              selectedFiles={selectedContentLibraryFileIds}
              maxFileCount={maxFileCounts?.contentLibrary}
            />
          }
          contentProps={CONTENT_PROPS}
          portalProps={{ container: containerNode }}
        >
          <div onClick={(e) => e.stopPropagation()}>
            <Tooltip content="Content Library">
              <button
                disabled={enableInternet}
                className="flex items-center justify-center text-xs gap-1 p-1 rounded-md text-gray-600 hover:text-black duration-100 hover:bg-gray-200 disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:text-gray-400"
              >
                <PopoverAnchor asChild>
                  <ListFilter size={16} />
                </PopoverAnchor>
                <div className="text-sm">
                  {selectedContentLibraryFiles?.length
                    ? `${selectedContentLibraryFiles.length} ${pluralizeWord(selectedContentLibraryFiles.length, "file")}`
                    : "Library"}
                </div>
                <ChevronDown size={16} />
              </button>
            </Tooltip>
          </div>
        </Popover>
      )}
      {!excludedComponents?.projectSpecific && (
        <Popover
          content={
            <AttachmentsPopover
              query={query}
              selectedFiles={selectedProjectFileIds}
              handleSelectedFiles={handleSelectedProjectFiles}
              maxFileCount={maxFileCounts?.projectSpecific}
            />
          }
          onOpenChange={(o) => {
            if (o && internalContractId) dispatch(getProjectAttachments(internalContractId));
          }}
          searchProps={{
            value: query,
            onChange: (e) => setQuery(e.target.value),
            placeholder: SOURCE_TO_META[Source.ProjectAttachments].searchPlaceholder,
          }}
          searchable
          contentProps={CONTENT_PROPS}
          portalProps={{ container: containerNode }}
        >
          <div onClick={(e) => e.stopPropagation()}>
            <Tooltip content="Documents">
              <button
                disabled={enableInternet}
                className="flex items-center justify-center text-xs gap-1 p-1 rounded-md text-gray-600 hover:text-black duration-100 hover:bg-gray-200 disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:text-gray-400"
              >
                <PopoverAnchor asChild>
                  <FolderClosed size={16} />
                </PopoverAnchor>
                <div className="text-sm">
                  {selectedProjectFiles?.length
                    ? `${selectedProjectFiles.length} ${pluralizeWord(selectedProjectFiles.length, "file")}`
                    : "Documents"}
                </div>
                <ChevronDown size={16} />
              </button>
            </Tooltip>
          </div>
        </Popover>
      )}
      {!excludedComponents?.upload && (
        <AddAttachmentsButton maxFileCount={maxFileCounts?.upload || maxFileCounts.total || undefined} />
      )}
    </div>
  );
};

export default FileSelection;

import React from "react";
import { Spinner } from "components/yjs-editor/primitives/Spinner";
import { getFileIcon } from "utils/getFileIcon";
import { X } from "lucide-react";
import type { DecoratedChatSessionDocument } from "utils/yjs-configs/ai-assistant/schema";
import Tooltip from "components/atoms/tooltip";
import { DOC_STATUS_OPTIONS } from "./constants";

type FileItemProps = {
  doc: DecoratedChatSessionDocument;
  className?: string;
  maxWidth?: number;
  disableTooltip?: boolean;
  handleFileDelete: (file: DecoratedChatSessionDocument) => void;
};

const FileItem: React.FC<FileItemProps> = ({
  handleFileDelete,
  doc,
  className = "",
  maxWidth = 270,
  disableTooltip = true,
}) => {
  const getTimeEstimationLabel = (doc: DecoratedChatSessionDocument) => {
    if (doc.minutes_time_remaining) {
      return `${doc.minutes_time_remaining} minutes remaining`;
    } else if (doc.status === DOC_STATUS_OPTIONS.queued) {
      return "Document is queued for processing";
    }
    return "";
  };

  return (
    <Tooltip content={doc.name} disabled={disableTooltip}>
      <div className={className}>
        <>
          <div className="flex items-center gap-1 justify-between">
            <div className="flex items-center gap-2">
              {doc.is_processing ? (
                <Spinner size={20} className="animate-spin" />
              ) : (
                <img src={getFileIcon(doc.file_extension_type)} alt="" className="h-[20px] w-[20px]" />
              )}
              <div className="truncate" style={{ minWidth: `${maxWidth}px`, maxWidth: `${maxWidth}px` }}>
                {doc.name}
              </div>
            </div>
            <button
              onClick={() => handleFileDelete(doc)}
              className="text-xs rounded-sm hover:text-black duration-100 hover:bg-zinc-200 p-0.5"
              data-testid="delete-file"
            >
              <X size={16} />
            </button>
          </div>
          {doc.is_processing && <div className="text-xxs text-gray-500 pl-7">{getTimeEstimationLabel(doc)}</div>}
        </>
      </div>
    </Tooltip>
  );
};

export default FileItem;

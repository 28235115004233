import { TabSlug } from "./types";

export const DRIVE_TAB_TO_META: Record<TabSlug, { name: string }> = {
  [TabSlug.Documents]: {
    name: "Documents",
  },
  [TabSlug.Media]: {
    name: "Graphics",
  },
};

export const ACCEPT_MEDIA_TYPES = {
  "image/jpeg": [".jpeg"],
  "image/png": [".png"],
};

export const MAX_MEDIA_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const ACCEPTED_FORMATS: Record<
  | "text/plain"
  | "application/pdf"
  | "application/doc"
  | "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  | "application/pptx"
  | "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  string[]
> = {
  "text/plain": [".txt"],
  "application/pdf": [".pdf"],
  "application/doc": [".doc", ".docx", ".dot"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/pptx": [".pptx"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
};

export const DOCUMENT_UPLOAD_FORMATS = {
  "application/pdf": [".pdf"],
  "application/doc": [".doc", ".docx", ".dot"],
};

export const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200MB

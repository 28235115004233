import FileItem from "components/molecules/assistant-uploaded-files";
import React from "react";
import type { DecoratedChatSessionDocument } from "utils/yjs-configs/ai-assistant/schema";

type FileListProps = {
  files: DecoratedChatSessionDocument[];
  onDelete: (file: DecoratedChatSessionDocument) => void;
};

const FileList: React.FC<FileListProps> = ({ files, onDelete }) => {
  return (
    <div className="flex flex-col pb-1">
      {files.map((doc) => (
        <FileItem key={doc.id} handleFileDelete={onDelete} doc={doc} className="pt-4" />
      ))}
    </div>
  );
};

export default FileList;

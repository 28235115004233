import { useCallback, useMemo, useState } from "react";
import { useProjectWorkflowActions } from "pages/Workflows/useProjectWorkflowActions";
import type { Editor } from "@tiptap/react";
import { TOOLBAR_BUTTON_STYLES } from "./constants";
import { WORKFLOW_TO_META, WorkflowSub } from "pages/ai-assistant/constants";
import { LoaderCircle } from "lucide-react";

export const ToolbarScore = ({ editor }: { editor: Editor }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { createNewWorkflow } = useProjectWorkflowActions();

  const textSelection = useMemo(() => {
    const selection = editor.state.selection;
    const { from, to } = selection;
    return !selection.empty ? editor.state.doc.textBetween(from, to, "\n").trim() : "";
  }, [editor.state.doc, editor.state.selection]);
  const ScoreIcon = WORKFLOW_TO_META[WorkflowSub.ScoreRequirementResponse].icon;
  const handleScore = useCallback(() => {
    if (isLoading) return;
    setIsLoading(true);

    createNewWorkflow(WorkflowSub.ScoreRequirementResponse, textSelection).finally(() => setIsLoading(false));
  }, [isLoading, createNewWorkflow, textSelection]);

  return (
    <button
      className={TOOLBAR_BUTTON_STYLES.DEFAULT}
      onClick={handleScore}
      disabled={isLoading || !textSelection}
      aria-label="Score"
    >
      {isLoading ? <LoaderCircle className="animate-spin" size={16} /> : <ScoreIcon size={16} />}
      <span>Score</span>
    </button>
  );
};

import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/unionBy";
import type {
  ChatSessionDocument,
  ChatTaskStatuses,
  FileMention,
  InputMetadata,
  TaskDetail,
} from "types/Assistants/types";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import * as logger from "utils/log";
import { getAIAssistantChatTaskStatuses, getAssistantSessionDocuments } from "api/api";
import isEqual from "lodash/isEqual";

export const getChatTaskStatuses = createAsyncThunk<ChatTaskStatuses>(
  "chat/getChatTaskStatuses",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAIAssistantChatTaskStatuses();
      return response.data;
    } catch (error) {
      logger.error(error as Error, "Error fetching chat task statuses:");
      return rejectWithValue(
        (error as { response?: { data?: string } }).response?.data ||
          "Unable to fetch chat task statuses. Please try again.",
      );
    }
  },
);

export const getChatSessionDocuments = createAsyncThunk<
  ChatSessionDocument[],
  string,
  { rejectValue: { message: string; error?: unknown } }
>("chat/fetchChatSessionDocuments", async (sessionId, { rejectWithValue }) => {
  try {
    const response = await getAssistantSessionDocuments(sessionId);
    return response.data;
  } catch (error) {
    logger.error(error as Error, "Error fetching chat session documents");

    return rejectWithValue({
      message: "An unexpected error occurred while fetching chat session documents.",
      error,
    });
  }
});

export type State = {
  open: boolean;
  prompt: string;
  highlightedText: string;
  sessionEditable: string;
  streamState: {
    blockId: string | null;
    isStreamingInProgress: boolean;
    streamCopy: string;
  };
  selectedProjectFiles: FileMention[];
  selectedContentLibraryFiles: FileMention[];
  enableInternet: boolean;
  hideBlock?: boolean;
  activeSession?: ToImmutable<WritingAssistantSession>;
  uploadChatDocumentTasks: TaskDetail[] | null;
  uploadedDocuments: ChatSessionDocument[] | null;
  activeMetadata?: InputMetadata;
};

const initialState: State = {
  open: false,
  prompt: "",
  highlightedText: "",
  streamState: {
    blockId: null,
    isStreamingInProgress: false,
    streamCopy: "",
  },
  selectedProjectFiles: [],
  selectedContentLibraryFiles: [],
  enableInternet: false,
  hideBlock: false,
  activeSession: undefined,
  sessionEditable: "",
  uploadChatDocumentTasks: null,
  uploadedDocuments: null,
  activeMetadata: undefined,
};

const writingAssistantReducer = createSlice({
  name: "writingAssistant",
  initialState,
  reducers: {
    resetAssistantState: () => {
      return cloneDeep(initialState);
    },
    setAssistantPrompt: (state: State, action: PayloadAction<State["prompt"]>) => {
      state.prompt = action.payload;
    },
    setSessionEditable: (state: State, action: PayloadAction<State["sessionEditable"]>) => {
      state.sessionEditable = action.payload;
    },
    setActiveSession: (state: State, action: PayloadAction<State["activeSession"]>) => {
      state.activeSession = action.payload;
      state.selectedContentLibraryFiles = [];
      state.selectedProjectFiles = [];
      state.uploadedDocuments = [];
      state.enableInternet = false;
      state.uploadChatDocumentTasks = null;
      state.uploadedDocuments = null;
    },
    updateActiveSession: (state: State, action: PayloadAction<Partial<State["activeSession"]>>) => {
      Object.keys(action.payload || {}).forEach((key) => {
        const typedKey = key as keyof State["activeSession"];
        if (state.activeSession && action.payload) state.activeSession[typedKey] = action.payload[typedKey];
      });
    },
    setHighlightedText: (state: State, action: PayloadAction<State["highlightedText"]>) => {
      state.highlightedText = action.payload;
    },
    insertAssistantFiles: (
      state: State,
      action: PayloadAction<{
        selectedProjectFiles?: State["selectedProjectFiles"];
        selectedContentLibraryFiles?: State["selectedContentLibraryFiles"];
      }>,
    ) => {
      const { selectedProjectFiles, selectedContentLibraryFiles } = action.payload;
      if (selectedProjectFiles)
        state.selectedProjectFiles = uniqBy([...state.selectedProjectFiles, ...selectedProjectFiles], "id");
      if (selectedContentLibraryFiles)
        state.selectedContentLibraryFiles = uniqBy(
          [...state.selectedContentLibraryFiles, ...selectedContentLibraryFiles],
          "id",
        );
    },
    removeAssistantFiles: (state: State, action: PayloadAction<FileMention["id"][]>) => {
      state.selectedProjectFiles = state.selectedProjectFiles.filter(({ id }) => !action.payload.includes(id));
      state.selectedContentLibraryFiles = state.selectedContentLibraryFiles.filter(
        ({ id }) => !action.payload.includes(id),
      );
    },
    removeAllAssistantFiles: (state: State) => {
      state.selectedContentLibraryFiles = [];
      state.selectedProjectFiles = [];
      state.uploadedDocuments = [];
    },
    removeSubmitOptions: (state: State) => {
      state.selectedContentLibraryFiles = [];
      state.selectedProjectFiles = [];
      state.uploadedDocuments = [];
      state.enableInternet = false;
    },
    setEnableInternet: (state: State, action: PayloadAction<boolean>) => {
      state.enableInternet = action.payload;
    },
    setHideBlock: (state: State, action: PayloadAction<boolean>) => {
      state.hideBlock = action.payload;
    },
    setActiveMetadata: (state: State, action: PayloadAction<State["activeMetadata"]>) => {
      state.activeMetadata = action.payload;
    },
    setStreamingState: (state: State, action: PayloadAction<Partial<State["streamState"]>>) => {
      const reset = !Object.keys(action.payload).length;
      if (reset) {
        state.streamState = initialState.streamState;
      } else {
        state.streamState = { ...state.streamState, ...action.payload };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChatTaskStatuses.fulfilled, (state, action) => {
      const shouldUpdate = !isEqual(state.uploadChatDocumentTasks, action.payload.upload_chat_document_tasks);

      if (shouldUpdate) {
        state.uploadChatDocumentTasks = action.payload.upload_chat_document_tasks;
      }
    });

    builder.addCase(getChatSessionDocuments.fulfilled, (state, action) => {
      state.uploadedDocuments = action.payload;
    });
  },
});

export const {
  setStreamingState,
  removeAllAssistantFiles,
  resetAssistantState,
  setAssistantPrompt,
  setHighlightedText,
  insertAssistantFiles,
  removeAssistantFiles,
  setEnableInternet,
  setHideBlock,
  setActiveSession,
  setSessionEditable,
  updateActiveSession,
  setActiveMetadata,
  removeSubmitOptions,
} = writingAssistantReducer.actions;

export default writingAssistantReducer.reducer;

import axios from "axios";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import type { Requirement } from "types/Requirement";
import * as logger from "utils/log";

const useCreateAtlasRequirement = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const [isLoading, setIsLoading] = useState(false);

  const createRequirement = useCallback(
    async (body: { content: string }) => {
      if (!projectId || isLoading) return;

      setIsLoading(true);
      try {
        const { data } = await axios.post<Requirement>(`/autopilot/${projectId}/requirements`, body);

        return data;
      } catch (e) {
        logger.error(e as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, projectId],
  );

  return { createRequirement, isCreatingRequirement: isLoading };
};

export default useCreateAtlasRequirement;

import { useEffect, useState } from "react";
import type { Editor } from "@tiptap/react";
import { AlignCenterIcon } from "../icons/AlignCenter";
import { AlignJustifyIcon } from "../icons/AlignJustify";
import { AlignLeftIcon } from "../icons/AlignLeft";
import { AlignRightIcon } from "../icons/AlignRight";
import { Button } from "../primitives/Button";
import styles from "./Toolbar.module.css";
import { ToolbarTooltip } from "../primitives/Tooltip";
import { getCurrentAlignment } from "components/yjs-editor/components/utils";

type TextAlignment = "left" | "center" | "right" | "justify";

export const ToolbarAlignment = ({ editor }: { editor: Editor }) => {
  const [currentAlignment, setCurrentAlignment] = useState<TextAlignment>("left");

  useEffect(() => {
    if (!editor) return;

    const updateAlignment = () => {
      setCurrentAlignment(getCurrentAlignment(editor));
    };

    updateAlignment();

    editor.on("selectionUpdate", updateAlignment);
    editor.on("update", updateAlignment);

    return () => {
      editor.off("selectionUpdate", updateAlignment);
      editor.off("update", updateAlignment);
    };
  }, [editor]);

  const setAlignment = (alignment: TextAlignment) => {
    editor.chain().focus().setTextAlign(alignment).run();
  };

  return (
    <>
      <ToolbarTooltip content="Left align">
        <Button
          variant="subtle"
          className={styles.toolbarButton}
          onClick={() => setAlignment("left")}
          data-active={currentAlignment === "left" ? "is-active" : undefined}
          aria-label="Align left"
        >
          <AlignLeftIcon />
        </Button>
      </ToolbarTooltip>

      <ToolbarTooltip content="Center align">
        <Button
          variant="subtle"
          className={styles.toolbarButton}
          onClick={() => setAlignment("center")}
          data-active={currentAlignment === "center" ? "is-active" : undefined}
          aria-label="Align center"
        >
          <AlignCenterIcon />
        </Button>
      </ToolbarTooltip>

      <ToolbarTooltip content="Right align">
        <Button
          variant="subtle"
          className={styles.toolbarButton}
          onClick={() => setAlignment("right")}
          data-active={currentAlignment === "right" ? "is-active" : undefined}
          aria-label="Align right"
        >
          <AlignRightIcon />
        </Button>
      </ToolbarTooltip>

      <ToolbarTooltip content="Justify">
        <Button
          variant="subtle"
          className={styles.toolbarButton}
          onClick={() => setAlignment("justify")}
          data-active={currentAlignment === "justify" ? "is-active" : undefined}
          aria-label="Justify"
        >
          <AlignJustifyIcon />
        </Button>
      </ToolbarTooltip>
    </>
  );
};

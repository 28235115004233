import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useTrackUserMetric } from "utils/metrics";
import { EventTracking } from "utils/constants";
import { createProjectChatSession } from "api/api";
import type { Workflow } from "pages/ai-assistant/constants";
import {
  WORKFLOW_OPTIONS,
  WorkflowSub,
  HIGHLIGHT_DRAFT_WORKFLOW,
  type WorkflowLabel,
} from "pages/ai-assistant/constants";
import * as logger from "utils/log";
import useWritingAssistantOperations from "hook/writing-assistant/useWritingAssistantOperations";
import useGetActiveProjectId from "hook/Project/useGetActiveProjectId";
import { setActiveSession, setHighlightedText } from "store/reducers/writing-assistant/writingAssistantReducer";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import { toggleAssistant } from "store/reducers/copilot/copilotDrawerReducer";
import { useNotification } from "context/notificationContext";
import type { CreateGlobalChatSession } from "types/Chat";

export const useProjectWorkflowActions = () => {
  const activeSession = useAppSelector((store) => store.writingAssistant.activeSession);
  const { createSession } = useWritingAssistantOperations();
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const trackUserEvent = useTrackUserMetric();
  const projectId = useGetActiveProjectId();

  const createWorkflow = useCallback(
    async (
      workflow: Workflow["name"],
      subType: Workflow["subType"],
      label: WorkflowLabel,
      extra_workflow_arguments?: CreateGlobalChatSession["extra_workflow_arguments"],
    ) => {
      // @ts-expect-error
      const normalizedSubType = workflow === subType ? undefined : subType;
      try {
        if (!projectId) throw new Error("projectId not found");

        const newSession = await createProjectChatSession({
          name: label,
          workflow,
          workflow_subtype: normalizedSubType,
          project_id: projectId,
          extra_workflow_arguments,
        });
        const createdSession = createSession({
          name: label,
          id: newSession.data.id,
          workflow,
          workflow_subtype: normalizedSubType,
        });
        trackUserEvent(EventTracking.PROJECT_WORKFLOW_INITIATED, {
          session_id: newSession.data.id,
          workflow_type: workflow,
          workflow_subtype: normalizedSubType,
          project_id: projectId,
        });
        return createdSession;
      } catch (error) {
        logger.error(error as Error, "Error creating workflow");
        setToast.error({
          msg: `Failed to create ${label} workflow. If the issue persists, please contact us at support@vultron.ai.`,
        });
      }
    },
    [createSession, projectId, trackUserEvent, setToast],
  );

  const endWorkflow = useCallback(() => {
    if (activeSession) {
      trackUserEvent(EventTracking.PROJECT_WORKFLOW_COMPLETED, {
        session_id: activeSession.id,
        workflow: activeSession.workflow,
        workflow_subtype: activeSession.workflow_subtype,
        project_id: projectId,
      });
      dispatch(setActiveSession({ ...activeSession, workflowEnded: true }));
    }
  }, [activeSession, dispatch, projectId, trackUserEvent]);

  const createNewWorkflow = useCallback(
    async (
      subType: WorkflowSub,
      textSelection?: string,
      workflowOptions: Workflow[] = WORKFLOW_OPTIONS,
      extra_workflow_arguments?: CreateGlobalChatSession["extra_workflow_arguments"],
    ) => {
      const isDraftSubType = subType === WorkflowSub.Draft;
      const workflowInfo =
        isDraftSubType && textSelection
          ? HIGHLIGHT_DRAFT_WORKFLOW
          : workflowOptions.find((option) => option.subType === subType);
      if (!workflowInfo) return;

      try {
        const createdYjsSession = await createWorkflow(
          workflowInfo.name,
          workflowInfo.subType,
          workflowInfo.label,
          extra_workflow_arguments,
        );
        const immutableYjsSession = createdYjsSession?.toJSON() as ToImmutable<WritingAssistantSession> | undefined;
        if (immutableYjsSession) {
          dispatch(toggleAssistant(true));
          dispatch(setActiveSession(immutableYjsSession));

          if (textSelection) {
            dispatch(setHighlightedText(textSelection));
          }
        }
      } catch (e) {
        logger.error(e as Error, `Failed to create ${workflowInfo.label} workflow`);
      }
    },
    [createWorkflow, dispatch],
  );

  return {
    createWorkflow,
    endWorkflow,
    createNewWorkflow,
  };
};

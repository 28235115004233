import compact from "lodash/compact";
import type { ResponseColumnOption } from "./constants";
import { COLUMN_OPTION_TO_EXPORT_KEY, ColumnOption, Direction, SortKey } from "./constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { Framework, ComplianceMatrixRow as ImmutableComplianceMatrixRow } from "../CopilotSchemaImmutableTypes";
import type { UpdatedRequirement } from "./TableHeader";
import type { ComplianceMatrixRow, Storage } from "../CopilotSchemaTypes";
import { useMutation } from "YJSProvider/createYJSContext";
import { createComplianceMatrixRow } from "utils/complianceMatrix";
import type { ActiveFilter } from "./utils";
import { filterOut, getAllSections } from "./utils";
import { useSearchParams } from "react-router-dom";
import { downloadExcel } from "api/api";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { getAtlasRequirements } from "store/reducers/projectReducer";
import * as logger from "utils/log";

export const useCopilotSearch = (filteredComplianceMatrix: ImmutableComplianceMatrixRow[]) => {
  const [query, setQuery] = useState("");

  const filteredResults = useMemo(
    () =>
      filteredComplianceMatrix.filter((row) =>
        (row.requirement?.content?.toLowerCase() || row.requirement?.summarized_content?.toLowerCase() || "").includes(
          query.toLowerCase(),
        ),
      ),
    [filteredComplianceMatrix, query],
  );

  return { query, setQuery, filteredResults };
};

type SortState = { key: SortKey; direction: Direction };

const sortMap: Record<SortKey, (a: UpdatedRequirement, b: UpdatedRequirement) => number> = {
  [SortKey.ComplianceStatus]: (a, b) =>
    a[SortKey.ComplianceStatus]?.toLowerCase()?.localeCompare(b[SortKey.ComplianceStatus]?.toLowerCase() || "") || 0,
  [SortKey.RequirementStatus]: (a, b) =>
    a[SortKey.RequirementStatus]?.toLowerCase()?.localeCompare(b[SortKey.RequirementStatus]?.toLowerCase() || "") || 0,
  [SortKey.ProposalSection]: (a, b) => a[SortKey.ProposalSection] - b[SortKey.ProposalSection],
  [SortKey.ProposalVolume]: (a, b) => a[SortKey.ProposalVolume] - b[SortKey.ProposalVolume],
  [SortKey.RequirementText]: (a, b) =>
    a[SortKey.RequirementText]?.toLowerCase().localeCompare(b[SortKey.RequirementText]?.toLowerCase()),
};

export const useSortable = (items: UpdatedRequirement[], sortActive: boolean) => {
  const [sortConfig, setSortConfig] = useState<SortState>({
    key: SortKey.RequirementText,
    direction: Direction.Ascending,
  });

  const sortedItems = useMemo(() => {
    if (!sortActive) return items;
    const sortableItems = [...items];
    if (sortConfig !== null) {
      if (sortConfig.direction === Direction.Ascending) {
        sortableItems.sort(sortMap[sortConfig.key]);
      } else sortableItems.sort(sortMap[sortConfig.key]).reverse();
    }
    return sortableItems;
  }, [items, sortActive, sortConfig]);

  const handleSorting = useCallback((sortState: Partial<SortState>) => {
    return setSortConfig((prev) => ({ ...prev, ...sortState }));
  }, []);

  return {
    items: sortedItems,
    sortConfig,
    handleSorting,
  };
};

export const useInsertRequirementRow = () => {
  const insertRequirementRow = useMutation(
    ({ storage }, index: number, partialProperties?: Partial<ComplianceMatrixRow> | undefined) => {
      const newRequirement = createComplianceMatrixRow(partialProperties);
      (storage.get("compliance_matrix") as Storage["compliance_matrix"]).insert(index, [newRequirement]);
    },
    [createComplianceMatrixRow],
  );

  return insertRequirementRow;
};

export const useExportToSheet = ({
  searchFilters,
  frameworkState,
  requirementsOnly,
  selectedColumns,
  sortConfig,
  sortActive,
}: {
  searchFilters: ActiveFilter;
  frameworkState: Framework;
  requirementsOnly: boolean;
  selectedColumns: ColumnOption[] | ResponseColumnOption[];
  sortActive: boolean;
  sortConfig: SortState;
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id")?.toLocaleLowerCase();
  const [exporting, setExporting] = useState(false);

  const transformRequirements = useCallback(
    (requirements: ImmutableComplianceMatrixRow[], frameworkState: Framework) => {
      const allSections = getAllSections(frameworkState?.volumes) || [];

      return requirements?.map((v) => {
        const volId = v?.proposal_reference?.volume_id;
        const secId = v?.proposal_reference?.section_id;
        const volumeIndex = frameworkState?.volumes?.findIndex((vl) => vl?.id === volId);
        return {
          ...v,
          requirementText: v?.requirement?.content || v?.requirement?.summarized_content || "",
          proposalVolume: volumeIndex,
          proposalSection: allSections.findIndex((sc) => sc?.id === secId),
        };
      });
    },
    [],
  );

  const exportRequirements = useMutation(
    ({ storage }) => {
      const transformedColumns = compact(selectedColumns.map((column) => COLUMN_OPTION_TO_EXPORT_KEY[column]));

      const immutableComplianceMatrix = (
        storage.get("compliance_matrix") as Storage["compliance_matrix"]
      ).toJSON() as unknown as ImmutableComplianceMatrixRow[];
      const immutableFramework = (storage.get("framework") as Storage["framework"]).toJSON() as unknown as Framework;

      const transformedReqs = transformRequirements(
        immutableComplianceMatrix,
        immutableFramework,
      ) as UpdatedRequirement[];

      const sortedImmutableComplianceMatrix =
        sortConfig.direction === Direction.Ascending
          ? [...transformedReqs].sort(sortMap[sortConfig.key])
          : [...transformedReqs].sort(sortMap[sortConfig.key]).reverse();

      const filteredRequirementIds = (sortActive ? sortedImmutableComplianceMatrix : immutableComplianceMatrix)
        .filter((row) => filterOut({ row, searchFilters, frameworkState, requirementsOnly }))
        .map(({ requirement }) => requirement.id);

      setExporting(true);
      downloadExcel(id, { requirements: filteredRequirementIds, columns: transformedColumns })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          const filename = `compliance-matrix_${new Date().toISOString().split("T")[0]}.xlsx`;
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error: Error) => logger.error(error))
        .finally(() => {
          setExporting(false);
        });
    },
    [
      frameworkState,
      id,
      requirementsOnly,
      searchFilters,
      selectedColumns,
      sortActive,
      sortConfig.direction,
      sortConfig.key,
      transformRequirements,
    ],
  );

  return { exportRequirements, exporting };
};

export const useGetAtlasRequirements = (internalContractId: string) => {
  const isLoadingAtlasRequirements = useAppSelector((store) => store.project.isLoadingAtlasRequirements);
  const groupedAtlasRequirements = useAppSelector((store) => store.project.groupedAtlasRequirements);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (internalContractId) dispatch(getAtlasRequirements(internalContractId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalContractId, dispatch]);

  return { isLoading: isLoadingAtlasRequirements, groupedAtlasRequirements };
};

export const useColumnOptions = () => {
  return useMemo(
    () => [
      ColumnOption.Assignees,
      ColumnOption.Status,
      ColumnOption.Compliance,
      ColumnOption.ProposalVolume,
      ColumnOption.ProposalSection,
    ],
    [],
  );
};

import { CopilotPresencePage } from "types/Presence";

export const NEW_FLOW_PAGE_SEARCH_PARAMS = [CopilotPresencePage.Inputs];
export const LEGACY_FLOW_PAGE_SEARCH_PARAMS = [
  CopilotPresencePage.ComplianceMatrix,
  CopilotPresencePage.Extract,
  CopilotPresencePage.Template,
  CopilotPresencePage.Documents,
  CopilotPresencePage.Capture,
  CopilotPresencePage.Project,
];

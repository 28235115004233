import config from "config/vultronConfig";
import { STATIC_FEATURE_FLAG_MAP } from "const-values/StaticFeatureFlagMap";
import { useFlags as useLDFlags } from "launchdarkly-react-client-sdk";
import type { FeatureFlagSet } from "types/FeatureFlagSet";
import { useParsedQueryParams } from "./useParseQueryParams";
import { useMemo } from "react";
import { once } from "lodash";

const logFlagsAndOverrides = once((flags, overrides) => {
  const tableData = Object.fromEntries(
    Object.keys({ ...flags, ...overrides })
      .sort()
      .map((key) => [
        key,
        {
          value: flags[key] ?? "-",
          override: overrides[key as keyof FeatureFlagSet] ?? "—",
        },
      ]),
  );

  console.table(tableData);
});

export function useFlags(): FeatureFlagSet {
  const flags = useLDFlags<FeatureFlagSet>();
  const allowURLOverrides = !!(
    process.env.NODE_ENV === "development" ||
    (config.isLaunchDarklyEnabled && flags.allowURLParams) ||
    config.uiFlags?.allowURLParams
  );
  const urlOverrides = useParsedQueryParams(allowURLOverrides);
  const anyOverrides = {
    ...config.uiFlags,
    ...urlOverrides,
  };

  const finalFlags = useMemo(
    () => ({
      ...(config.isLaunchDarklyEnabled ? flags : STATIC_FEATURE_FLAG_MAP),
      ...config.uiFlags,
      ...urlOverrides,
    }),
    [flags, urlOverrides],
  );

  if (Object.keys(anyOverrides).length) {
    logFlagsAndOverrides(config.isLaunchDarklyEnabled ? flags : STATIC_FEATURE_FLAG_MAP, anyOverrides);
  }

  return finalFlags;
}

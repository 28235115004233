import { useEffect, useMemo, useRef } from "react";
import { setHighlightedText } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useProxyRef } from "hook/useProxyRef";
import { useAssistant } from "../AssistantContext";
import { extractExcludeArrayFromVultronBlock, parseWorkflowInstructions } from "utils/assistants/utils";
import { FileInputFields } from "types/Assistants/types";
import { WORKFLOWS_IN_TOOLBAR } from "pages/project-details/constants";
import type { WorkflowNameType, WorkflowSub } from "pages/ai-assistant/constants";

export const useResetWritingAssistantSelection = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setHighlightedText(""));
    return () => {
      dispatch(setHighlightedText(""));
    };
  }, [dispatch]);
};

export const useInputFocus = () => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const activeSession = useAppSelector((root) => root.writingAssistant.activeSession);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [activeSession?.id, inputRef]);

  return inputRef;
};

export const useTriggerWorkflowMessage = () => {
  const { highlightedText, activeSession } = useAppSelector((root) => root.writingAssistant);
  const highlightedTextRef = useProxyRef(highlightedText);
  const { submitMessage } = useAssistant();
  const submitMessageRef = useProxyRef(submitMessage);

  useEffect(() => {
    if (
      (activeSession?.workflow_subtype || activeSession?.workflow) &&
      !activeSession.conversation.length &&
      activeSession?.id
    ) {
      const workflow: WorkflowSub | WorkflowNameType | undefined =
        activeSession?.workflow_subtype || activeSession?.workflow;
      const shouldSkipFirstMessage = workflow && WORKFLOWS_IN_TOOLBAR.includes(workflow);
      let params = {};
      if (shouldSkipFirstMessage) {
        const userRequest = highlightedTextRef.current;
        params = {
          ...params,
          user_request: userRequest,
          session_id: activeSession.id,
          is_first_message: !userRequest?.trim(),
        };
      }

      submitMessageRef.current?.(params);
    }
  }, [
    activeSession?.conversation.length,
    activeSession?.id,
    activeSession?.workflow_subtype,
    activeSession?.workflow,
    highlightedTextRef,
    submitMessageRef,
  ]);
};

export type InstructionExtraState = {
  maxFileCounts: Partial<{
    contentLibrary: number | null;
    projectSpecific: number | null;
    upload: number | null;
    total: number | null;
    supportedUploadTypes: string[];
  }>;
  excludedComponents: Partial<{
    contentLibrary: boolean;
    projectSpecific: boolean;
    upload: boolean;
    internet: boolean;
  }>;
};
export const useInstructionState = () => {
  const conversation = useAppSelector((root) => root.writingAssistant.activeSession?.conversation);
  const parsedInputInfo = parseWorkflowInstructions(conversation || []);
  const parsedInputInfoWasParsed = !!parsedInputInfo && typeof parsedInputInfo !== "string";
  const inputExtraInfo = parsedInputInfoWasParsed ? parsedInputInfo?.extra : undefined;

  const maxFileCounts = useMemo(
    () =>
      inputExtraInfo && ("max_content_library_count" in inputExtraInfo || "supported_upload_types" in inputExtraInfo)
        ? {
            contentLibrary: inputExtraInfo?.max_content_library_count,
            projectSpecific: inputExtraInfo?.max_project_count,
            upload: inputExtraInfo?.max_upload_count,
            total: inputExtraInfo?.max_total_count,
            supportedUploadTypes: inputExtraInfo?.supported_upload_types,
          }
        : {},
    [inputExtraInfo],
  );

  const excluded = useMemo(() => {
    if (!conversation) return [];
    const lastMessage = conversation.at(-1);
    if (!lastMessage) return [];
    const excludedFields = extractExcludeArrayFromVultronBlock(lastMessage);

    return excludedFields;
  }, [conversation]);

  const excludedComponents = useMemo(
    () => ({
      contentLibrary: excluded?.includes(FileInputFields.ContentLibrary),
      projectSpecific: excluded?.includes(FileInputFields.ProjectScoped),
      upload: excluded?.includes(FileInputFields.ChatDocuments),
      internet: excluded?.includes(FileInputFields.Internet),
    }),
    [excluded],
  );
  return { maxFileCounts, excludedComponents };
};

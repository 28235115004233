import type { HTMLAttributes, ReactNode } from "react";
import React from "react";
import { motion } from "framer-motion";
import { useProgress } from "./hooks";

interface ProgressLoaderProps extends HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
  isCompleted: boolean;
  label: ReactNode;
}

const ProgressLoader: React.FC<ProgressLoaderProps> = ({ isLoading, isCompleted, label, ...props }) => {
  const progress = useProgress(isLoading, isCompleted);

  return (
    <div className="flex flex-col items-center w-full max-w-md mx-auto" {...props}>
      <p className="text-lg font-medium">{label}</p>

      <div className="relative w-full h-2 bg-gray-300 rounded-full overflow-hidden mt-2 shadow-expanded">
        <motion.div
          className="h-full bg-black rounded-full"
          initial={{ width: progress }}
          animate={{ width: `${progress}%` }}
          transition={{ ease: "linear", duration: 0.5 }}
        />
      </div>
    </div>
  );
};

export default ProgressLoader;

/** @jsxImportSource @emotion/react */

import type { ComponentProps, InputHTMLAttributes, PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "react";
import type { PopoverProps, PopperArrowProps, PortalProps } from "@radix-ui/react-popover";
import { Root, Trigger, Portal, Content, Arrow } from "@radix-ui/react-popover";
import { Search } from "lucide-react";
import type { Interpolation, Theme } from "@emotion/react";

export interface Props extends PopoverProps {
  content: ReactNode;
  arrow?: boolean;
  contentProps?: ComponentProps<typeof Content> & { css?: Interpolation<Theme> };
  arrowProps?: PopperArrowProps & { css?: Interpolation<Theme> };
  portalProps?: PortalProps;
  searchable?: boolean;
  searchProps?: InputHTMLAttributes<HTMLInputElement>;
}

const Popover = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, content, arrow, contentProps, arrowProps, portalProps, searchable, searchProps, ...props }, ref) => (
    <Root {...props}>
      <Trigger asChild>{children}</Trigger>
      <Portal {...portalProps}>
        <Content
          onOpenAutoFocus={(e) => e.preventDefault()}
          ref={ref}
          className="z-[100] text-sm mx-2 shadow rounded-md outline-none bg-white border border-zinc-200"
          sideOffset={5}
          {...contentProps}
        >
          {searchable && (
            <div className="px-2 gap-1.5 flex flex-row items-center border-b border-b-gray-light">
              <Search size={14} className="text-gray-400" />
              <input autoFocus className="flex-1 outline-none py-3 text-sm text-gray-600" {...searchProps} />
            </div>
          )}
          {content}
          {arrow && <Arrow className="fill-white drop-shadow-lg" {...arrowProps} />}
        </Content>
      </Portal>
    </Root>
  ),
);

export default Popover;

/** @jsxImportSource @emotion/react */

const RecentFilesModalHeader = () => (
  <div className="flex flex-col gap-0.5 pr-3">
    <h2 tw="text-xl font-bold">Recent Files</h2>
    <div className="text-sm text-gray-500 font-normal">Select from the files you recently used in the Assistant.</div>
  </div>
);

export default RecentFilesModalHeader;

import type { Transaction } from "@tiptap/pm/state";
import type { Editor } from "@tiptap/react";
import { useEffect } from "react";

export const useOnEditorTransaction = (
  editor: Editor | undefined | null,
  callback: (transaction: any, editor: Editor) => void,
) => {
  useEffect(() => {
    if (!editor) return;

    // Handler function to wrap the callback
    const handler = ({ transaction, editor }: { transaction: Transaction; editor: Editor }) => {
      callback(transaction, editor as Editor);
    };

    // Register the handler
    editor.on("transaction", handler);

    // Cleanup function to unregister the handler
    return () => {
      editor.off("transaction", handler);
    };
  }, [editor, callback]);
};

import type { ComponentProps } from "react";

export function OutdentIcon(props: ComponentProps<"svg">) {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.12598 5.99998H2.04264M2.04264 5.99998L4.08431 8.04165M2.04264 5.99998L4.08431 3.95831"
        stroke="#1E1E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5845 6H10.0845M16.5845 11H1.58447M16.5845 1H1.58447"
        stroke="#1E1E1E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

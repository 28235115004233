import { createSlice } from "@reduxjs/toolkit";

const controller = new AbortController();

interface SseControllerState {
  abortController: AbortController;
}

const initialState: SseControllerState = {
  abortController: controller,
};

const sseControllerSlice = createSlice({
  name: "sseController",
  initialState,
  reducers: {
    resetAbortController: (state) => {
      state.abortController.abort();
      state.abortController = new AbortController();
    },
  },
});

export const { resetAbortController } = sseControllerSlice.actions;
export default sseControllerSlice.reducer;

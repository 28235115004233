export function mergeRefs<T>(...refs: Array<React.Ref<T> | undefined>) {
  return (node: T) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(node);
      } else if (ref) {
        // Cast to MutableRefObject to allow assignment.
        (ref as React.MutableRefObject<T | null>).current = node;
      }
    });
  };
}

import Popover from "components/atoms/popover/Popover";
import ReviewEnginePopover from "components/molecules/review-engine-popover/ReviewEnginePopover";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { cancelReviewEngine } from "api/api";
import {
  addDocIdToCanceledReviews,
  removeDocIdFromReviews,
  setReviewEngineOpen,
} from "store/reducers/proposal/proposalReducer";
import tw from "twin.macro";
import { ReactComponent as Sparkles } from "Assets/svgs/sparkles.svg";
import { Spinner } from "utils/icons";
import { useFlags } from "hook/useFlags";
import { Avatars } from "components/editor/components/Avatars";
import { useAppDispatch, useAppSelector } from "store/storeTypes";

interface ProposalActionsProps {
  proposalDocId: string;
  referenceId?: string;
  isProposalReviewTaskInProgress: boolean;
  reviewInProgressButtonText: string;
}

const ProposalActions = ({
  proposalDocId,
  referenceId,
  isProposalReviewTaskInProgress,
  reviewInProgressButtonText,
}: ProposalActionsProps) => {
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const reviewEngineOpen = useAppSelector((state) => state.proposal.reviewEngineOpen);

  const handleReviewEngineButtonClick = () => {
    if (isProposalReviewTaskInProgress) {
      triggerConfirm({
        header: "Are you sure you want to cancel this review?",
        body: "Canceling will stop the review process and no comments will be added for this review.",
      }).then((proceed) => {
        if (proceed && referenceId) {
          cancelReviewEngine(referenceId);
          dispatch(removeDocIdFromReviews(referenceId));
          dispatch(addDocIdToCanceledReviews(referenceId));
          dispatch(setReviewEngineOpen(false));
        }
      });
    } else {
      dispatch(setReviewEngineOpen(true));
    }
  };

  return (
    <div className="flex items-center gap-1">
      <Avatars />
      <Popover
        modal
        content={<ReviewEnginePopover proposalDocId={proposalDocId} />}
        contentProps={{
          align: "end",
          alignOffset: -8,
          css: tw`border-gray-900`,
        }}
        open={reviewEngineOpen && !isProposalReviewTaskInProgress}
        onOpenChange={(o) => dispatch(setReviewEngineOpen(o))}
      >
        <button
          {...(!flags.enableCancelReviewEngine && { disabled: isProposalReviewTaskInProgress })}
          {...(flags.enableCancelReviewEngine && { onClick: handleReviewEngineButtonClick })}
          className="bg-[radial-gradient(50%_50%_at_50%_50%,_#646464_0%,_#1E1E1E_100%)] cursor-pointer h-8 px-3.5 flex items-center justify-center gap-2.5 rounded text-white text-xs enabled:hover:bg-[radial-gradient(50%_50%_at_50%_50%,_#5e5d5d_0%,_#333333_100%)]"
        >
          {isProposalReviewTaskInProgress ? reviewInProgressButtonText : "AI Review"}
          {isProposalReviewTaskInProgress ? (
            <Spinner classes="!text-black items-center" width={12} height={12} />
          ) : (
            <Sparkles className="white" size={12} />
          )}
        </button>
      </Popover>
    </div>
  );
};

export default ProposalActions;

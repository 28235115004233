import type { HTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";

interface CommentCardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const CommentCard = forwardRef<HTMLDivElement, CommentCardProps>(
  ({ children, className = "", ...props }, ref) => {
    const baseClasses =
      "group relative flex flex-col w-full h-fit p-3.5 gap-2.5 transition-background duration-200 rounded-md border border-[#E5E7EB] bg-white shadow-[0px_4px_8px_0px_#67676726]";
    return (
      <div ref={ref} className={`${baseClasses} ${className}`} {...props}>
        {children}
      </div>
    );
  },
);

import { useAppSelector } from "store/storeTypes";
import { Avatar } from "components/molecules/avatar";
import { Search, UserPlus, X } from "lucide-react";
import { Modal } from "components/organisms/modal";
import { useState, useMemo, useEffect } from "react";
import Switch from "components/atoms/switch/Switch";

interface AssignMenuProps {
  open: boolean;
  onClose: () => void;
  onAssign: (userId: string | null, username: string | null, sendEmail?: boolean) => void;
  currentAssigneeId?: string;
}

export const AssignMenu = ({ open, onClose, onAssign, currentAssigneeId }: AssignMenuProps) => {
  const currentUser = useAppSelector((store) => store.auth.currentUser);
  const workspaceMembers = useAppSelector((store) => store.auth.workspaceMembers);
  const [search, setSearch] = useState("");
  const [sendNotification, setSendNotification] = useState(true);

  // Reset sendNotification to true whenever the modal opens
  useEffect(() => {
    if (open) {
      setSendNotification(true);
    }
  }, [open]);

  const filteredMembers = useMemo(() => {
    if (!currentUser) return [];
    const searchLower = search.toLowerCase();
    return workspaceMembers.filter((member) => member.username.toLowerCase().includes(searchLower));
  }, [workspaceMembers, search, currentUser]);

  const currentAssignee = useMemo(
    () => workspaceMembers.find((member) => member.id === currentAssigneeId),
    [workspaceMembers, currentAssigneeId],
  );

  const showNoResults = search && filteredMembers.length === 0;

  if (!currentUser) return null;

  const body = (
    <div className="flex flex-col px-6">
      {/* Search Input */}
      <div className="relative mb-4">
        <div className="absolute left-3 flex items-center h-full">
          <Search size={16} className="text-gray-400" />
        </div>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search members..."
          className="w-full pl-9 pr-8 py-2 bg-gray-50 border border-gray-200 rounded-md 
                   text-sm focus:outline-none focus:ring-1 focus:ring-[#1e1e1e]"
        />
        {search && (
          <div className="absolute right-3 top-0 flex items-center h-full">
            <button
              onClick={() => setSearch("")}
              className="text-gray-400 hover:text-gray-600"
              aria-label="Clear search"
            >
              <X size={14} />
            </button>
          </div>
        )}
      </div>

      {/* Members List */}
      <div className="overflow-y-auto max-h-[200px]">
        {filteredMembers.map((member) => (
          <div
            key={member.id}
            className={`flex items-center justify-between h-10 px-2.5 rounded-md hover:bg-gray-50 
              ${currentAssigneeId === member.id ? "bg-gray-50 pointer-events-none" : "cursor-pointer"}`}
            onClick={() => currentAssigneeId !== member.id && onAssign(member.id, member.username, sendNotification)}
          >
            <div className="flex items-center gap-2">
              <Avatar size={22} textColor="#FFFFFF" name={member.username} id={member.id} />
              <span className="text-sm">{member.username}</span>
            </div>

            <div className="flex items-center justify-center h-6.5">
              {currentAssigneeId === member.id && (
                <div className="py-1 text-xs bg-black/70 text-white px-2 rounded-full border border-black/70">
                  Current
                </div>
              )}
            </div>
          </div>
        ))}

        {showNoResults && (
          <div className="flex items-center justify-center h-10 px-2.5 rounded-md">
            <span className="text-sm text-gray-500 truncate">No members found matching "{search}"</span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      onOpenChange={(open) => !open && onClose()}
      title={currentAssignee ? "Reassign" : "Assign Thread"}
      header={
        <div className="flex gap-2 items-center">
          <UserPlus size={18} />
          {currentAssignee ? "Reassign" : "Assign Thread"}
        </div>
      }
      body={body}
    />
  );
};

/** @jsxImportSource @emotion/react */
import TextareaAutosize from "react-textarea-autosize";
import tw from "twin.macro";

interface InputTextareaProps {
  prompt: string;
  promptRef: React.RefObject<HTMLTextAreaElement>;
  isStreamingInProgress: boolean;
  onSubmit: () => void;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  flags: { documentAttachments: boolean };
  canSubmit: boolean;
  className?: string;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => void;
}

const InputTextarea: React.FC<InputTextareaProps> = ({
  prompt,
  promptRef,
  isStreamingInProgress,
  onSubmit,
  onChange,
  flags,
  canSubmit,
  className,
  onBlur,
  onFocus,
  onMouseDown,
}) => (
  <TextareaAutosize
    ref={promptRef}
    onKeyDown={(e) => {
      if (e.key === "Enter" && !e.shiftKey && canSubmit) {
        onSubmit();
      }
    }}
    readOnly={isStreamingInProgress}
    autoFocus
    minRows={1}
    maxRows={12}
    value={prompt}
    className={`text-slate-700 text-base px-4 pt-3.5 pb-10 flex-1 outline-none resize-none w-full ${className}`}
    placeholder="Ask me anything..."
    onChange={onChange}
    onBlur={onBlur}
    onFocus={onFocus}
    onMouseDown={onMouseDown}
    css={[flags.documentAttachments ? tw`pb-10` : tw`pb-12`]}
  />
);

export default InputTextarea;

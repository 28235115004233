import type { CollisionDetection, DragEndEvent, DragOverEvent, DragStartEvent, UniqueIdentifier } from "@dnd-kit/core";
import {
  closestCenter,
  getFirstCollision,
  MouseSensor,
  PointerSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import type { ComplianceMatrixRow, Extraction, Framework } from "components/copilot/CopilotSchemaTypes";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { insert, move } from "utils/array";
import { groupComplianceMatrix } from "./utils";
import { throttle } from "lodash";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setIsDragActive } from "store/reducers/drag-drop/DragDropReducer";
import useExtractionOperations from "hook/useExtractionOperations";
import { useParams } from "react-router-dom";
import type { ViewportListRef } from "react-viewport-list";
import type { FormattedVolume } from "types/Volume";
import { setRequirementsTableScrollToState } from "store/reducers/extract/CurrentExtractionReducer";

export const useDrag = ({
  complianceMatrix,
  flattenedSections,
}: {
  complianceMatrix?: ToImmutable<ComplianceMatrixRow>[];
  flattenedSections: ToImmutable<Framework["volumes"]>;
}) => {
  const { reorderRequirementsInTable } = useExtractionOperations();
  const { extractionId } = useParams();
  const dispatch = useAppDispatch();
  const [activeDragId, setActiveDragId] = useState<UniqueIdentifier | null>(null);
  const [groupedComplianceMatrix, setGroupedComplianceMatrix] = useState<
    Record<string, ToImmutable<Extraction["compliance_matrix"]>>
  >({});
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);

  useEffect(() => {
    const grouped = groupComplianceMatrix(complianceMatrix || []);
    setGroupedComplianceMatrix(grouped);
  }, [complianceMatrix]);

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");

      if (overId !== null) {
        if (overId in groupedComplianceMatrix) {
          const containerItems = groupedComplianceMatrix[overId];

          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId && containerItems.some((row) => row.requirement.id === container.id),
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeDragId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeDragId, groupedComplianceMatrix],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4,
      },
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setActiveDragId(event.active.id);
      dispatch(setIsDragActive(true));
    },
    [dispatch],
  );

  const handleDragCancel = useCallback(() => {
    const grouped = groupComplianceMatrix(complianceMatrix || []);
    setGroupedComplianceMatrix(grouped);
    setActiveDragId(null);
    dispatch(setIsDragActive(false));
  }, [complianceMatrix, dispatch]);

  const requirementIdToSectionIdMap = useMemo(() => {
    const entries = Object.entries(groupedComplianceMatrix);
    return entries.reduce<Record<string, string>>((acc, [sectionId, requirements]) => {
      requirements.forEach(({ requirement }) => {
        acc[requirement.id] = sectionId;
      });
      return acc;
    }, {});
  }, [groupedComplianceMatrix]);

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      const overId = over?.id;

      if (overId) {
        const sections = flattenedSections.flatMap((v) => v.sections);
        const overContainerId =
          requirementIdToSectionIdMap[overId] || sections.find((section) => section.id === over?.id)?.id;

        if (!overContainerId || !extractionId) {
          handleDragCancel();
          return;
        }

        const droppableTargetContainerRequirements = groupedComplianceMatrix[overContainerId] || [];
        const indexOfNewPlacement = !droppableTargetContainerRequirements?.length
          ? 0
          : droppableTargetContainerRequirements?.findIndex((row) => row.requirement.id === overId);
        const indexToDrop = indexOfNewPlacement || 0;
        const reorderedSection = move(
          droppableTargetContainerRequirements || [],
          droppableTargetContainerRequirements?.findIndex((row) => row.requirement.id === active.id),
          indexToDrop,
        ).map((row, i) => ({
          ...row,
          requirement: {
            ...row?.requirement,
            section_order: i,
          },
        }));
        setGroupedComplianceMatrix((prev) => {
          recentlyMovedToNewContainer.current = true;

          return {
            ...prev,
            [overContainerId]: reorderedSection,
          };
        });

        reorderRequirementsInTable(extractionId, reorderedSection, overContainerId);
      }

      setActiveDragId(null);
      dispatch(setIsDragActive(false));
    },
    [
      dispatch,
      flattenedSections,
      requirementIdToSectionIdMap,
      extractionId,
      groupedComplianceMatrix,
      reorderRequirementsInTable,
      handleDragCancel,
    ],
  );

  const handleDragOver = useMemo(
    () =>
      throttle(
        ({ active, over }: DragOverEvent) => {
          const overId = over?.id;
          const sections = flattenedSections.flatMap((v) => v.sections);

          if (!overId || active?.id === overId) {
            return;
          }

          const overContainerId =
            requirementIdToSectionIdMap[overId] || sections.find((section) => section.id === over?.id)?.id;

          const activeContainerId = requirementIdToSectionIdMap[active.id];

          if (!overContainerId || !activeContainerId || overContainerId === activeContainerId) {
            return;
          }

          const isDroppingIntoDifferentSection = activeContainerId !== overContainerId;
          if (isDroppingIntoDifferentSection) {
            const droppableTargetContainerRequirements = groupedComplianceMatrix[overContainerId] || [];
            const indexOfNewPlacement = !droppableTargetContainerRequirements?.length
              ? 0
              : droppableTargetContainerRequirements?.findIndex((row) => row.requirement.id === overId);
            const indexToDrop =
              droppableTargetContainerRequirements[indexOfNewPlacement]?.requirement?.section_order ||
              indexOfNewPlacement ||
              0;

            const requirementToInsert = groupedComplianceMatrix[activeContainerId].find(
              ({ requirement }) => requirement.id === active.id,
            );

            if (!requirementToInsert) return;

            const requirementWithUpdatedSectionOrder = {
              ...requirementToInsert,
              requirement: {
                ...requirementToInsert?.requirement,
                section_order: indexToDrop === 0 ? indexToDrop : indexToDrop + 1,
              },
            };

            setGroupedComplianceMatrix((prev) => {
              recentlyMovedToNewContainer.current = true;

              return {
                ...prev,
                [activeContainerId]: groupedComplianceMatrix[activeContainerId].filter(
                  ({ requirement }) => requirement.id !== active.id,
                ),
                [overContainerId]: insert(
                  droppableTargetContainerRequirements || [],
                  requirementWithUpdatedSectionOrder,
                  indexToDrop === 0 ? indexToDrop : indexToDrop + 1,
                ),
              };
            });
          }
        },
        20,
        { leading: false, trailing: true },
      ),
    [flattenedSections, requirementIdToSectionIdMap, groupedComplianceMatrix],
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [groupedComplianceMatrix]);

  return {
    collisionDetectionStrategy,
    sensors,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    activeDragId,
    handleDragOver,
    groupedComplianceMatrix,
  };
};

export const useRequirementTableScrollManager = ({
  flattenedSections,
  sectionListRefs,
  volumeListRef,
  requirementListRef,
}: {
  flattenedSections: FormattedVolume[];
  sectionListRefs?: Record<string, ViewportListRef | null>;
  volumeListRef?: ViewportListRef | null;
  requirementListRef?: ViewportListRef | null;
}) => {
  const dispatch = useAppDispatch();
  const requirementsTableScrollToState = useAppSelector(
    (store) => store.currentExtractionState.requirementsTableScrollToState,
  );

  const getVolumeAndSectionIdx = useCallback(() => {
    return flattenedSections.reduce(
      (acc, volume, i) => {
        const foundSectionIdx = volume.sections.findIndex(
          (section) => section.id === requirementsTableScrollToState.sectionId,
        );
        const hasFoundSection = foundSectionIdx >= 0;

        if (requirementsTableScrollToState.volumeId && volume.id === requirementsTableScrollToState.volumeId)
          acc.volumeIdx = i;
        if (!requirementsTableScrollToState.volumeId && !!requirementsTableScrollToState.sectionId && hasFoundSection) {
          acc.volumeIdx = i;
        }
        if (hasFoundSection) acc.sectionIdx = foundSectionIdx;

        return acc;
      },
      { volumeIdx: 0, sectionIdx: 0 },
    );
  }, [flattenedSections, requirementsTableScrollToState.sectionId, requirementsTableScrollToState.volumeId]);

  useLayoutEffect(() => {
    const hasScrollState = !!Object.keys(requirementsTableScrollToState)?.length;
    if (!hasScrollState) return;

    const sectionHeaderHeightDiff = 40;

    if (requirementsTableScrollToState.sectionId || requirementsTableScrollToState.volumeId) {
      const { volumeIdx, sectionIdx } = getVolumeAndSectionIdx();
      volumeListRef?.scrollToIndex({
        index: volumeIdx,
      });

      if (requirementsTableScrollToState.sectionId) {
        const volumeId = flattenedSections[volumeIdx]?.id || "";
        const sectionListRef = sectionListRefs?.[volumeId];

        sectionListRef?.scrollToIndex({
          index: sectionIdx,
          offset: -sectionHeaderHeightDiff,
        });
      }

      dispatch(setRequirementsTableScrollToState({}));
    }
  }, [
    dispatch,
    flattenedSections,
    getVolumeAndSectionIdx,
    requirementsTableScrollToState,
    sectionListRefs,
    volumeListRef,
  ]);
};

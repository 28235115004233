import { Upload } from "lucide-react";
import { DOCUMENTS } from "const-values/testIds";

interface ProjectSpecificEmptyStateProps {
  uploadFiles: (files: FileList | null) => void;
}

const ProjectSpecificEmptyState = ({ uploadFiles }: ProjectSpecificEmptyStateProps) => {
  return (
    <tr>
      <td colSpan={2} className="w-full">
        <div className="min-h-[65vh] w-full bg-white flex justify-center items-center flex-col py-7 px-2">
          <div className="font-medium text-xl mb-1">No Documents</div>
          <span className="text-gray-text text-sm mt-3">Upload all documents relevant to this project.</span>
          <label
            htmlFor="input"
            className="bg-gray-200 flex my-5 text-gray-600 py-1.5 px-2 text-sm items-center justify-center self-center rounded-md gap-2 w-[180px] cursor-pointer"
          >
            <Upload size={14} />
            Upload Documents
          </label>
          <input
            type="file"
            id="input"
            multiple
            className="hidden"
            onChange={(event) => uploadFiles(event.target.files)}
            data-testid={DOCUMENTS.documents.uploadDocuments}
          />
        </div>
      </td>
    </tr>
  );
};

export default ProjectSpecificEmptyState;

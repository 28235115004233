import type { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { getDiffInDays } from "utils/timerUtils";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import type {
  BlockContentItem,
  ChatSessionDocument,
  ChecklistMetadata,
  FileInputFields,
  FileMention,
  InputFieldType,
  InputMetadata,
  InputObject,
  TaskDetail,
  WorkflowInstructions,
} from "types/Assistants/types";
import tryParseJSON from "utils/tryParseJSON";
import * as logger from "utils/log";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import { WORKFLOW_OPTIONS } from "pages/ai-assistant/constants";
import uniq from "lodash/uniq";
import { AssistantBlockType } from "components/copilot/CopilotSchemaImmutableTypes";
import type { InputField } from "types/Chat";
import type { BaseFile, Subdirectory } from "types/FileStorage";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

const getDaysAgoCategory = (session?: string): string => {
  const sessionDate = new Date(session || Date.now());
  const daysDiff = getDiffInDays(sessionDate, new Date());

  if (daysDiff === 0) {
    return "Today";
  }
  if (daysDiff <= 1) {
    return "Yesterday";
  }
  if (daysDiff <= 7) {
    return "Last 7 days";
  }
  if (daysDiff <= 30) {
    return "Last 30 days";
  }
  if (daysDiff <= 90) {
    return "Last 90 days";
  }

  return `${sessionDate.getFullYear()}`;
};

export const getFolderFiles = (folders: Subdirectory[] | undefined) =>
  (folders ?? []).reduce((acc, folder) => [...acc, ...(folder.all_nested_files ?? [])], [] as BaseFile[]);

export const groupYjsSessionsByCategory = <T extends AIAssistantSession | WritingAssistantSession>(
  sessions: ToImmutable<T[]>,
): Map<string, ToImmutable<T[]>> => {
  const groups = new Map<string, ToImmutable<T>[]>();

  sessions?.forEach((session) => {
    if (!session.updated_at) return; // Skip if `updated_at` is missing (AIAssistantSession case)

    const category = getDaysAgoCategory(session.updated_at);
    if (!groups.has(category)) {
      groups.set(category, []);
    }

    groups.get(category)!.push(session);
  });

  return groups;
};

export const getAttachLabel = (
  uploadedDocuments: (ChatSessionDocument | FileMention)[] | null,
  emptyStateLabel: string,
  isProcessing = false,
  maxCount = 12,
): string => {
  if (isProcessing) return "Processing...";

  if (!uploadedDocuments?.length) return emptyStateLabel;

  if (uploadedDocuments.length > 1) {
    return `${uploadedDocuments.length} files`;
  }

  const file = uploadedDocuments[0];
  const name = file.name;
  const fileExtension = "fileExtensionType" in file ? file.fileExtensionType : file.file_extension_type;

  if (name.length > maxCount) {
    return `${name.slice(0, maxCount)}...${fileExtension || ""}`;
  }

  return name;
};

export function getProcessingMessage(percentage: number): string {
  if (percentage < 25) {
    return "Processing inputs…";
  } else if (percentage < 50) {
    return "Analyzing inputs…";
  } else if (percentage < 75) {
    return "Reviewing inputs…";
  } else {
    return "Processing workflow…";
  }
}

export const getWorkflowLabel = (subType: string): string => {
  const option = WORKFLOW_OPTIONS.find((workflow) => workflow.subType === subType);
  return option ? option.label : "";
};

type LastItemType = { type: typeof INPUT_FIELD_OPTIONS.endWorkflow };

function isEndWorkflowItem(item: unknown): item is LastItemType {
  return (
    typeof item === "object" &&
    item !== null &&
    "type" in item &&
    (item as any).type === INPUT_FIELD_OPTIONS.endWorkflow
  );
}

export function extractInstruction<T extends AIAssistantSession | WritingAssistantSession>(
  input: string,
  activeSession?: ToImmutable<T>,
  endWorkflow?: (activeSession: ToImmutable<T>) => void,
  workflowType?: string,
): string | BlockContentItem[] | [...BlockContentItem[], WorkflowInstructions] | null {
  if (workflowType === INPUT_FIELD_OPTIONS.pendingWorkflow) return null;
  // If the input does NOT start with a JSON structure ("{" or "["),
  // assume there's leading text before an appended JSON block.
  if (!/^\s*[{[]/.test(input)) {
    // Group 1: any text at the beginning (including newlines)
    // Group 2: the JSON block starting at the last '{' until the end.
    const regex = /^([\s\S]+?)(\{[\s\S]+\})\s*$/;
    const match = input.match(regex);
    if (match) {
      const textBefore = match[1].trim();
      const jsonPart = match[2].trim();
      const jsonParsed = tryParseJSON(jsonPart);
      if (jsonParsed && typeof jsonParsed === "object") {
        if ("type" in jsonParsed && String(jsonParsed.type).trim() === INPUT_FIELD_OPTIONS.endWorkflow) {
          if (activeSession?.id && !activeSession.workflowEnded && endWorkflow) {
            endWorkflow(activeSession);
          }
          if ("instruction" in jsonParsed) {
            const instructionValue = String(jsonParsed.instruction).trim();
            if (instructionValue !== "") {
              return instructionValue;
            }
          }
          return textBefore;
        }
        if ("instruction" in jsonParsed) {
          const instructionValue = String(jsonParsed.instruction).trim();
          if (instructionValue !== "") {
            return instructionValue;
          }
        }
        return textBefore;
      }
    }
  }

  // Fallback: try parsing the entire input as JSON.
  const parsed = tryParseJSON(input);
  if (parsed !== null && typeof parsed === "object") {
    if ("type" in parsed) {
      const inputFieldType = String(parsed.type).trim();
      if (inputFieldType === INPUT_FIELD_OPTIONS.endWorkflow || inputFieldType === "end_workflow") {
        if (activeSession?.id && !activeSession.workflowEnded && endWorkflow) {
          endWorkflow(activeSession);
        }
        return "";
      }
      // Added check for pending workflow in the fallback branch.
      if (inputFieldType === INPUT_FIELD_OPTIONS.pendingWorkflow || inputFieldType === "pending_workflow") {
        if ("extra" in parsed && parsed.extra && typeof parsed.extra === "object" && "task_id" in parsed.extra) {
          return parsed.extra.task_id as string;
        }
      }
    }

    if (Array.isArray(parsed)) {
      if (parsed.length > 0 && isEndWorkflowItem(parsed[parsed.length - 1])) {
        if (activeSession?.id && !activeSession.workflowEnded && endWorkflow) {
          endWorkflow(activeSession);
        }
        parsed.pop();
      }
      return parsed;
    }
    if ("instruction" in parsed) {
      const instructionValue = String(parsed.instruction).trim();
      if (instructionValue !== "") {
        return instructionValue;
      }
    }
  }

  // Fallback: return the input as-is.
  return input;
}

export const extractMetadataFromLastVultronBlock = (
  conversation: ToImmutable<WritingAssistantSession["conversation"] | ToImmutable<AIAssistantSession["conversation"]>>,
) => {
  const lastVultronBlock = conversation.findLast((block) => block.type === AssistantBlockType.VultronBlock);
  if (lastVultronBlock && !lastVultronBlock.error) return lastVultronBlock.input_metadata;
};
export const extractTypeFromLastVultronBlock = (input: InputObject[]): InputFieldType => {
  let lastItem: InputObject | undefined;
  for (let i = input.length - 1; i >= 0; i--) {
    if (input[i].type === 2 && !input[i].error) {
      lastItem = input[i];
      break;
    }
  }

  if (lastItem) {
    try {
      const parsedBody: unknown = tryParseJSON(lastItem.body);
      if (
        typeof parsedBody === "object" &&
        parsedBody !== null &&
        "type" in parsedBody &&
        Object.values(INPUT_FIELD_OPTIONS).includes((parsedBody as { type: InputFieldType }).type)
      ) {
        return (parsedBody as { type: InputFieldType }).type;
      }
    } catch (e) {
      logger.error(e as Error, `Failed to parse body for item with id ${lastItem.id}`);
    }
  }

  return INPUT_FIELD_OPTIONS.string;
};

export const parseWorkflowInstructions = (items: Array<{ body: string }>): WorkflowInstructions | string | null => {
  if (items.length === 0) return null;
  const lastItem = items[items.length - 1];
  return tryParseJSON(lastItem.body) ?? lastItem.body;
};

const getAndBuildChecklistMetadata = (instructions: WorkflowInstructions[]) => {
  const checklistInstructions = instructions.filter(
    ({ type, extra }) => type === INPUT_FIELD_OPTIONS.checklist && extra?.selections?.length,
  );
  if (!checklistInstructions.length) return;
  const data = checklistInstructions.reduce<ChecklistMetadata["data"]>((acc, { extra }) => {
    if (!("selections" in extra)) return acc;
    extra.selections.forEach(([label, value]) => {
      acc[label] = value;
    });
    return acc;
  }, {});
  return { type: INPUT_FIELD_OPTIONS.checklist, data };
};
const getAndBuildChecklistGroupMetadata = (instructions: WorkflowInstructions[]) => {
  const checklistGroupInstructions = instructions.filter(({ type }) => type === INPUT_FIELD_OPTIONS.checklistGroup);
  if (!checklistGroupInstructions.length) return;
  const data = checklistGroupInstructions.reduce<ChecklistMetadata["data"]>((acc, { extra }) => {
    if (!("groups" in extra)) return acc;
    const selections = extra.groups.flatMap((group) => group.selections);
    selections.forEach(([label, value]) => {
      acc[label] = value;
    });
    return acc;
  }, {});
  return { type: INPUT_FIELD_OPTIONS.checklistGroup, data };
};

export const getAndBuildInputMetadataFromBody = (body: string) => {
  let metadata: InputMetadata | undefined;
  const instructions = tryParseJSON<WorkflowInstructions | WorkflowInstructions[] | undefined>(body);
  if (!instructions) return;
  const flattenedInstructions = Array.isArray(instructions) ? instructions : [instructions];
  const firstInputType = flattenedInstructions.at(0)?.type;

  switch (firstInputType) {
    case INPUT_FIELD_OPTIONS.checklist:
      metadata = getAndBuildChecklistMetadata(flattenedInstructions);
      break;
    case INPUT_FIELD_OPTIONS.checklistGroup:
      metadata = getAndBuildChecklistGroupMetadata(flattenedInstructions);
      break;
  }

  return metadata;
};

export const buildInputFieldFromActiveMetadata = (activeMetadata: InputMetadata): InputField | undefined => {
  if (
    activeMetadata.type === INPUT_FIELD_OPTIONS.checklist ||
    activeMetadata.type === INPUT_FIELD_OPTIONS.checklistGroup
  )
    return {
      action: "",
      input_field_type: activeMetadata.type,
      data: { selections: Object.keys(pickBy(activeMetadata.data, identity)) },
    };
};

export const generateSupportedFileTypesMessage = (extra: { supported_document_types: string[] }): string => {
  const formattedTypes = extra.supported_document_types.map((type) => type.toLowerCase());
  const lastType = formattedTypes.pop();
  const baseMessage =
    formattedTypes.length > 0
      ? `${formattedTypes.join(", ")}${formattedTypes.length > 1 ? "," : ""} and ${lastType}`
      : lastType;

  return `We accept ${baseMessage} files`;
};

export const mapUploadedItemsWithTasks = (
  uploadedDocuments: ChatSessionDocument[],
  uploadChatDocumentTasks: TaskDetail[],
  activeSessionId: string | undefined,
) => {
  if (!uploadedDocuments) return [];

  return uploadedDocuments.map((item) => {
    const matchingTask = uploadChatDocumentTasks?.find(
      (task) => task.reference_id === item.id && task.chat_session_id === activeSessionId,
    );

    return matchingTask ? { ...item, minutes_time_remaining: matchingTask.minutes_time_remaining ?? undefined } : item;
  });
};

export const generateSupportedFileTypesObject = (fileTypes: string[]) => {
  const extensionToMimeMapping: Record<string, string> = {
    ".txt": "text/plain",
    ".pdf": "application/pdf",
    ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  };

  const result: Record<string, string[]> = {};

  fileTypes.forEach((ext) => {
    const normalizedExt = ext.startsWith(".") ? ext : `.${ext}`;
    const mimeType = extensionToMimeMapping[normalizedExt];

    if (mimeType) {
      if (!result[mimeType]) {
        result[mimeType] = [];
      }
      if (!result[mimeType].includes(normalizedExt)) {
        result[mimeType].push(normalizedExt);
      }
    }
  });

  return result;
};

export const findWorkflowLabelFromSubtype = (subtype: string) => {
  const workflow = WORKFLOW_OPTIONS.find((workflow) => workflow.subType === subtype);
  return workflow?.label || "";
};

const getExcludedFromInstruction = (workflowInstruction: WorkflowInstructions) => {
  if (
    typeof workflowInstruction === "object" &&
    workflowInstruction !== null &&
    "extra" in workflowInstruction &&
    workflowInstruction.extra &&
    typeof workflowInstruction.extra === "object" &&
    "exclude" in workflowInstruction.extra &&
    Array.isArray((workflowInstruction as { extra: { exclude: unknown } }).extra.exclude)
  ) {
    return (workflowInstruction as { extra: { exclude: FileInputFields[] } }).extra.exclude;
  }
  return [];
};
export const extractExcludeArrayFromVultronBlock = (input: InputObject): FileInputFields[] => {
  try {
    const workflowInstruction: unknown = tryParseJSON(input.body);

    if (Array.isArray(workflowInstruction)) {
      return uniq((workflowInstruction as WorkflowInstructions[]).flatMap(getExcludedFromInstruction));
    }

    if (
      typeof workflowInstruction === "object" &&
      workflowInstruction !== null &&
      "extra" in workflowInstruction &&
      workflowInstruction.extra &&
      typeof workflowInstruction.extra === "object" &&
      "exclude" in workflowInstruction.extra &&
      Array.isArray((workflowInstruction as { extra: { exclude: unknown } }).extra.exclude)
    ) {
      return (workflowInstruction as { extra: { exclude: FileInputFields[] } }).extra.exclude;
    }
  } catch (e) {
    logger.error(e as Error, `Failed to parse body for item with id ${input.id}`);
  }

  return [];
};

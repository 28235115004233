import { useCallback, useEffect, useMemo, useState } from "react";
import * as logger from "utils/log";

export default function usePersistedStorage<T>(key: string, defaultValue?: T): [T | undefined, (value: T) => void] {
  const [value, setValue] = useState(() => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  });

  useEffect(() => {
    const item = localStorage.getItem(key);

    if (!item && defaultValue) {
      localStorage.setItem(key, JSON.stringify(defaultValue));
    }

    setValue(item ? JSON.parse(item) : defaultValue);

    function handler(e: StorageEvent) {
      if (e.key !== key) return;

      setValue(JSON.parse(e.newValue ?? '""'));
    }

    window.addEventListener("storage", handler);

    return () => {
      window.removeEventListener("storage", handler);
    };
  }, [defaultValue, key]);

  const setValueWrap = useCallback(
    (value: T) => {
      try {
        setValue(value);
        const newStringifiedValue = JSON.stringify(value);
        localStorage.setItem(key, newStringifiedValue);
        if (typeof window !== "undefined") {
          window.dispatchEvent(new StorageEvent("storage", { key, newValue: newStringifiedValue }));
        }
      } catch (e) {
        logger.error(e as Error);
      }
    },
    [key],
  );

  return useMemo(() => [value, setValueWrap], [setValueWrap, value]);
}

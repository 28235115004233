import type { ComponentProps } from "react";

export function HighlightIcon({
  variant = "default",
  ...props
}: ComponentProps<"svg"> & { variant?: "default" | "bubble" }) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-[18px] h-[18px]"
      {...props}
    >
      {variant === "bubble" ? (
        <>
          <path
            d="M2.70215 8.3623L4.57715 6.95605L6.9209 9.2998V10.1331H5.51465V11.1748H1.2959L2.70215 9.76855V8.3623Z"
            fill="#F4F4F5"
            stroke="#F4F4F5"
            strokeWidth="0.75"
          />
          <path
            d="M4.6875 6.95605L7.03125 9.2998L12.8577 3.88952C13.2637 3.51254 13.2858 2.87735 12.907 2.47304L12.0712 1.58083C11.6977 1.18221 11.0735 1.157 10.6691 1.52421L4.6875 6.95605Z"
            fill="#363636"
            stroke="#F4F4F5"
            strokeWidth="0.75"
          />
        </>
      ) : (
        <>
          <path d="M1.9668 9L3.9668 7.5L6.4668 10V10.8889H4.9668V12H0.466797L1.9668 10.5V9Z" fill="#1E1E1E" />
          <path
            d="M4.08447 7.5L6.58447 10L12.8484 4.18347C13.2544 3.80649 13.2765 3.17129 12.8978 2.76699L11.9155 1.71853C11.5421 1.3199 10.9178 1.29469 10.5135 1.6619L4.08447 7.5Z"
            fill="#FCFCFC"
            stroke="#1E1E1E"
          />
        </>
      )}
    </svg>
  );
}

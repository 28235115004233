/** @jsxImportSource @emotion/react */

import { useEffect, useState, useCallback } from "react";
import { INITIAL_LOADING_MSG, INITIAL_LOADING_STATE, SECOND_LOADING_MSG, THIRD_LOADING_MSG } from "../constants";
import SourceWrapper from "../SourceWrapper";
import { VultronAvatar } from "components/molecules/avatar";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { RotateCw, Trash2, ClipboardCopy } from "lucide-react";
import TypingEffect from "components/TypingEffect";
import InternetAnimation from "Assets/gifs/internet-loading.gif";
import tw from "twin.macro";
import LiveDate from "components/molecules/live-date";
import FileCheckIcon from "Assets/ai-assistant/file-check.svg";
import FileXIcon from "Assets/ai-assistant/file-x.svg";
import Tooltip from "components/atoms/tooltip/Tooltip";
import GlobeX from "Assets/globe-x.svg";
import Icon from "components/atoms/icons/Icon";
import { useProjectWorkflowActions } from "pages/Workflows/useProjectWorkflowActions";
import { extractInstruction } from "utils/assistants/utils";
import MarkdownContent from "components/molecules/ai-assistant-blocks/vultron-block/vultron-block-subsections/MarkdownContent";
import ResponsesWithSources from "components/organisms/ResponsesWithSources/ResponsesWithSources";
import { useAssistant } from "../../AssistantContext";
import { useDeleteAssistantBlock } from "../hooks";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import type { VultronBlock as LiveVultronBlock, WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import { extractWorkflowData } from "components/molecules/ai-assistant-blocks/vultron-block/utils";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { setActiveMetadata } from "store/reducers/writing-assistant/writingAssistantReducer";
import ChecklistBlock from "components/molecules/assistant-blocks/checklist-block";
import type { ChecklistItemsState } from "components/molecules/assistant-blocks/checklist-block/ChecklistBlock";
import ChecklistGroupBlock from "components/molecules/assistant-blocks/checklist-group-block";

const VultronBlock = ({ block }: { block: ToImmutable<LiveVultronBlock> }) => {
  const deleteBlock = useDeleteAssistantBlock();
  const dispatch = useAppDispatch();
  const streamState = useAppSelector((root) => root.writingAssistant.streamState);
  const activeSession = useAppSelector((root) => root.writingAssistant.activeSession);
  const isWorkflowSession = !!activeSession?.workflow;
  const { isStreamingInProgress, streamCopy, blockId } = streamState;
  const { refreshMessage } = useAssistant();
  const { endWorkflow } = useProjectWorkflowActions();
  const blockContent = isWorkflowSession ? extractInstruction(block.body, activeSession, endWorkflow) : block.body;
  const isStreaming = isStreamingInProgress && blockId === block.id;
  const workflowData = isWorkflowSession ? extractWorkflowData(block.body) : null;
  const hasEmptyResponseError = !block.body && !isStreaming;
  const [internetLoadingState, setInternetLoadingState] = useState(INITIAL_LOADING_STATE);
  const tiptapEditor = useAppSelector((state: any) => state.yjsEditor.storeEditorOfYJSTipTap);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let nextTimeout: NodeJS.Timeout;
    if (isStreaming) {
      setInternetLoadingState((prev) => ({ ...prev, msg: INITIAL_LOADING_MSG }));
      timeout = setTimeout(() => setInternetLoadingState((prev) => ({ ...prev, msg: SECOND_LOADING_MSG })), 10000);
      nextTimeout = setTimeout(() => setInternetLoadingState((prev) => ({ ...prev, msg: THIRD_LOADING_MSG })), 20000);
    }
    return () => {
      clearTimeout(timeout);
      clearTimeout(nextTimeout);
    };
  }, [isStreaming]);

  const onChecklistChange = useCallback(
    (checklistType: "checklist" | "checklist_group", checklistState: ChecklistItemsState) => {
      dispatch(
        setActiveMetadata({
          type: checklistType,
          data: checklistState,
        }),
      );
    },
    [dispatch],
  );

  if (workflowData?.type === INPUT_FIELD_OPTIONS.checklistGroup && activeSession) {
    return (
      <ChecklistGroupBlock<WritingAssistantSession>
        blockId={block.id}
        instruction={workflowData.instruction}
        groups={workflowData.extra.groups}
        activeSession={activeSession}
        onChecklistChange={onChecklistChange}
      />
    );
  }

  if (workflowData?.type === INPUT_FIELD_OPTIONS.checklist && activeSession) {
    return (
      <ChecklistBlock<WritingAssistantSession>
        blockId={block.id}
        instruction={workflowData.instruction}
        selections={workflowData.extra.selections}
        activeSession={activeSession}
        onChecklistChange={onChecklistChange}
      />
    );
  }

  if (Array.isArray(blockContent)) {
    return <ResponsesWithSources blockContent={blockContent} />;
  }

  return (
    <div
      className="flex flex-row gap-2 w-full"
      css={[isStreaming && { scrollMarginTop: 8 }]}
      id={isStreaming ? `assistant-block-${blockId}` : undefined}
    >
      <VultronAvatar size={30} isSquare />
      <div className="flex flex-col gap-3 text-sm flex-1 min-w-0">
        {!isStreaming && (block.error || hasEmptyResponseError) ? (
          <div className="text-red-400 whitespace-pre-wrap text-sm px-1.5 py-1">
            There was an issue processing the request. Please try again. If the issue persists, please contact us at
            support@vultron.ai.
          </div>
        ) : (
          <>
            {!block.error && !!blockContent && (
              <div className="min-h-6">
                <MarkdownContent
                  blockContent={blockContent}
                  isStreaming={isStreaming}
                  streamCopy={streamCopy}
                  enableInternet={!!block.enableInternet}
                />
              </div>
            )}
            {isStreaming && !streamCopy && !block.enableInternet && (
              <div
                className="relative"
                style={{
                  height: block.error ? 30 : 24,
                }}
              >
                <TypingEffect
                  style={{
                    position: "absolute",
                    top: 6,
                    left: 0,
                    padding: 0,
                  }}
                />
              </div>
            )}
            {isStreaming && !streamCopy && block.enableInternet && (
              <div className="flex items-center text-sm">
                <img src={InternetAnimation} alt="" className="w-[30px] h-[30px]" />
                <div className="text-action ml-1">{internetLoadingState.msg}</div>
                <span className="loading-ellipsis" />
              </div>
            )}
          </>
        )}

        {!!block.sources?.length && !isStreaming && <SourceWrapper sources={block.sources} />}
        <div
          className="flex flex-row justify-between items-center"
          css={[isStreaming && tw`opacity-0 pointer-events-none`]}
        >
          <div className="flex flex-row gap-2 items-center text-gray-400">
            {tiptapEditor && (
              <Tooltip content="Insert Into Proposal">
                <button
                  onClick={() => {
                    tiptapEditor.chain().focus().insertContentAt(tiptapEditor.state.selection, blockContent).run();
                  }}
                  className="duration-150 hover:text-slate-600"
                >
                  <ClipboardCopy size={14} className="stroke-[1.6]" />
                </button>
              </Tooltip>
            )}
            {!isWorkflowSession && (
              <>
                {tiptapEditor && <div className="h-4 w-px bg-gray-400" />}
                <button onClick={() => deleteBlock(block.id)} className="duration-150 hover:text-slate-600">
                  <Trash2 size={14} className="stroke-[1.6]" />
                </button>
                {!!block.prompt?.trim() && !isStreamingInProgress && (
                  <>
                    <div className="h-4 w-px bg-gray-400" />
                    <button onClick={() => refreshMessage(block.id)} className="duration-150 hover:text-slate-600">
                      <RotateCw size={14} className="stroke-[1.7]" />
                    </button>
                  </>
                )}

                <div className="h-4 w-px bg-gray-400" />
                {block.enableInternet && !block.sources?.length && (
                  <Tooltip content="No external sources were used in this response.">
                    <div className="relative flex items-center justify-center h-[15px] w-[15px]">
                      <img src={GlobeX} alt="No external sources were used" className="w-4 h-4 text-black" />
                    </div>
                  </Tooltip>
                )}
                {block.enableInternet && !!block.sources?.length && (
                  <Tooltip content="The external sources above were used in this response. Please confirm important information from these sources.">
                    <div className="relative flex items-center justify-center h-[15px] w-[15px]">
                      <Icon name="Internet" className="w-4 h-4 text-black" />
                    </div>
                  </Tooltip>
                )}
                {!block.enableInternet && !!block.sources?.length && (
                  <Tooltip content="The sources above were used in this response. Please use the provided sources to confirm important information.">
                    <img src={FileCheckIcon} alt="Sources available" className="w-4 h-4" />
                  </Tooltip>
                )}
                {!block.enableInternet &&
                  !block.sources?.length &&
                  (block.promptSources?.length ? (
                    <Tooltip content="No relevant sources were found in the selected files for this response. This response was generated using no direct sources.">
                      <img src={FileXIcon} alt="No prompt sources available" className="w-4 h-4" />
                    </Tooltip>
                  ) : (
                    <Tooltip content="No sources have been selected for this response. Please validate and confirm the content. You can select files or enable the internet mode to use direct sources.">
                      <img src={FileXIcon} alt="No sources available" className="w-4 h-4" />
                    </Tooltip>
                  ))}
              </>
            )}
          </div>
          <LiveDate date={block.updated_at} duration={30000} />
        </div>
      </div>
    </div>
  );
};
export default VultronBlock;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDropDownOptions } from "api/api";

export const getAllDrpOptions = createAsyncThunk("utilsSlice/getAllDrpOptions", async () => {
  return getDropDownOptions().then((res) => {
    const data = res.data || [];
    return data;
  });
});

const initialState = {
  optionList: [],
  isLoadingUtils: false,
  savedSearches: [],
  isLoadingSavedSearches: false,
};

export const utilsReducerSlice = createSlice({
  name: "utilsSlice",
  initialState,
  reducers: {
    setUtils: (state, action) => {
      state.optionList = action.payload.data;
      state.isLoadingUtils = action.payload.loading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDrpOptions.pending, (state) => {
        state.isLoadingUtils = true;
      })
      .addCase(getAllDrpOptions.fulfilled, (state, action) => {
        state.optionList = action.payload || [];
        state.isLoadingUtils = false;
      })
      .addCase(getAllDrpOptions.rejected, (state) => {
        state.isLoadingUtils = false;
      });
  },
});

export const { setUtils } = utilsReducerSlice.actions;

export default utilsReducerSlice.reducer;

import { useDocViewNotification } from "./context";

import * as Toast from "@radix-ui/react-toast";
import { X } from "lucide-react";

const DocumentToast = () => {
  const { clearToast, notification } = useDocViewNotification();

  return (
    <Toast.Root
      className="bg-black/70 backdrop-blur-md rounded-md data-[state=open]:animate-fadeIn data-[state=closed]:animate-[animateDelayHide_0ms_forwards]"
      onOpenChange={(open) => {
        if (!open) clearToast();
      }}
      open={!!notification}
      asChild
      duration={notification?.duration || 2000}
    >
      <div className="flex flex-row gap-6 justify-between items-center px-3 py-2">
        <Toast.Description className="text-sm text-white">{notification?.msg}</Toast.Description>
        <Toast.Close className="text-white text-xs">
          <X size={12} />
        </Toast.Close>
      </div>
    </Toast.Root>
  );
};

export default DocumentToast;

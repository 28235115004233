import * as logger from "utils/log";

type PollingCallback = () => unknown | Promise<unknown>;

export const createPollingManager = () => {
  const pollers = new Map<PollingCallback, { controller: AbortController; subscribers: Set<() => void> }>();

  const register = (callback: PollingCallback, interval = 3000): (() => void) => {
    if (!pollers.has(callback)) {
      const controller = new AbortController();
      pollers.set(callback, {
        controller,
        subscribers: new Set<() => void>(),
      });

      startPolling(callback, interval, controller.signal);
    }

    const poller = pollers.get(callback)!;
    const unsubscribe = () => unregister(callback, unsubscribe);
    poller.subscribers.add(unsubscribe);

    return unsubscribe;
  };

  const unregister = (callback: PollingCallback, subscriber: () => void): void => {
    const poller = pollers.get(callback);

    if (!poller) return;

    poller.subscribers.delete(subscriber);

    if (poller.subscribers.size === 0) {
      poller.controller.abort();
      pollers.delete(callback);
    }
  };

  const startPolling = async (callback: PollingCallback, interval: number, signal: AbortSignal): Promise<void> => {
    try {
      while (!signal.aborted) {
        await callback();
        await new Promise((resolve) => setTimeout(resolve, interval));
      }
    } catch (err) {
      if (signal.aborted) {
        console.log(`Polling aborted for callback:`, callback);
      } else {
        logger.error(err as Error, "Error during polling");
      }
    }
  };

  return { register };
};

import type { Editor } from "@tiptap/react";
import { useEditor } from "@tiptap/react";
import { useCallback, useEffect, useRef } from "react";
import { debounce, isEqual } from "lodash";
import Document from "@tiptap/extension-document";
import DragHandle from "@tiptap-pro/extension-drag-handle";
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import History from "@tiptap/extension-history";
import NodeRange from "@tiptap-pro/extension-node-range";
import Text from "@tiptap/extension-text";
import { TextSelection } from "@tiptap/pm/state";
import type { Level } from "@tiptap/extension-heading";
import type { EditorActive, OnOutlineEditorChange, OutlineContent } from "../types";
import type { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { ClipboardHandler } from "../plugins/ClipboardHandler";
import { EnterHandler } from "../plugins/EnterHandler";
import { OnLineChangePlugin } from "../plugins/OnLineChangePlugin";
import { RestrictedHeading } from "../plugins/RestrictedHeading";
import { fixRequirements, fixTree } from "./utils";
import { setRequirementsTableScrollToState } from "store/reducers/extract/CurrentExtractionReducer";
import * as logger from "utils/log";

function setEditorContent(editor: Editor | null, content: OutlineContent[]) {
  if (!editor) {
    return;
  }

  const { from, to } = editor.state.selection;
  editor.commands.setContent(
    {
      type: "doc",
      content,
    },
    false,
  );
  const maxPos = editor.state.doc.content.size;
  // Prevent setting a selection out of bounds, which would cause an error in TipTap.
  // If this condition triggers, it likely indicates a bug elsewhere, as selections should always be valid.
  if (from <= maxPos && to <= maxPos) {
    const position = TextSelection.create(editor.state.doc, from, to);

    editor.view.dispatch(editor.state.tr.setSelection(position).setMeta("addToHistory", false));
  } else {
    logger.warn("Unable to set selection, out of bounds attempt prevented");
  }
}

type Props = {
  content: OutlineContent[];
  onChange: OnOutlineEditorChange;
  version: number;
  isReadOnly: boolean;
  maxLevel: Level;
};

export function useEditorState({ content, onChange, version, isReadOnly, maxLevel }: Props): {
  editor: Editor | null;
  active: EditorActive;
} {
  const dispatch = useAppDispatch();
  const appliedVersion = useRef(0);
  const editorActive = useRef<EditorActive>({ line: 0, nodes: [] });
  const matrix = useAppSelector(
    (root) => root.currentExtractionState.currentExtraction?.compliance_matrix as ComplianceMatrixRow[] | undefined,
  );

  const onImmediateUpdate = useCallback(
    ({ editor }: { editor: Editor | null }) => {
      if (!editor || isReadOnly) {
        return;
      }
      const editorContent = editor.getJSON().content as OutlineContent[];

      if (isEqual(content, editorContent)) {
        logger.info("no changes detected, skipping update");
        return;
      }
      const [fixes, fixedContent] = fixTree(content, editorContent, maxLevel);

      if (fixes.length) {
        logger.warn(`Incorrect state detected, patch applied`, {
          fixes: fixes.join("\n"),
          reference: content,
          updated: editorContent,
        });
        setEditorContent(editor, fixedContent);
        // We return since the editor update will already trigger a new transaction
        return;
      }
      const complianceMatrixChanges = fixRequirements(content, fixedContent, matrix);
      appliedVersion.current += 1;
      onChange(fixedContent, complianceMatrixChanges, appliedVersion.current);
    },
    [onChange, content, isReadOnly, matrix, maxLevel],
  );
  const onUpdate = useCallback(debounce(onImmediateUpdate, 300), [onImmediateUpdate]);

  const editor = useEditor({
    editable: !isReadOnly,
    extensions: [
      ClipboardHandler.configure({
        maxLevel,
      }),
      EnterHandler,
      Document,
      Text,
      History,
      RestrictedHeading.configure({
        maxLevel,
      }),
      Gapcursor,
      NodeRange,
      ...(isReadOnly
        ? []
        : [
            Dropcursor.configure({
              color: "rgb(96, 165, 250)",
              width: 2,
            }),
            DragHandle.configure({
              render() {
                const element = document.createElement("div");
                element.classList.add("custom-drag-handle");
                return element;
              },
              onNodeChange() {
                onUpdate({ editor });
              },
            }),
          ]),
      OnLineChangePlugin.configure({
        onChange: (line, nodes) => {
          // ignore changes if the editor wasn't focused, since they were triggered elsewhere
          if (!editor?.isFocused) {
            return;
          }
          const attrs = nodes?.[0]?.node?.attrs;

          if (!attrs) {
            // skip, no node selected
          } else if (attrs.level === 1) {
            dispatch(
              setRequirementsTableScrollToState({
                volumeId: attrs.xid,
              }),
            );
          } else {
            dispatch(
              setRequirementsTableScrollToState({
                sectionId: attrs.xid,
              }),
            );
          }
          editorActive.current = { line, nodes };
        },
      }),
    ],
    content: {
      type: "doc",
      content,
    },
    onUpdate,
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(
        {
          type: "doc",
          content,
        },
        false,
      );
    }
  }, [editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    // Skip update if the version is behind or the same than the current version
    if (version && appliedVersion.current && version <= appliedVersion.current) {
      return;
    }

    setEditorContent(editor, content);

    // Only set version if it has already been initialized or if drafts have previously loaded
    // Otherwise we may end up setting the version before drafts actually load and prevent updates.
    if (appliedVersion.current || content.length) {
      appliedVersion.current = version;
    }
  }, [editor, content]); // intentionally ignore version field since it's only updated based on contents

  return { editor, active: editorActive.current };
}

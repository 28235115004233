import type { Subdirectory, WorkspaceFile, WorkspaceMedia } from "./../../types/FileStorage";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllDirectories, getDirectories } from "api/api";
import axios from "axios";
import type { RootState } from "store/storeTypes";

export const fetchFileStorage = createAsyncThunk<
  {
    rootSubdirectories: Subdirectory[];
    allDirectories: Subdirectory[];
    rootFiles: WorkspaceFile[];
    isInitialFetch: boolean;
    userTags: string[];
  },
  { isInitialFetch?: boolean } | undefined,
  { state: RootState }
>("fileStorage", async (config) => {
  const { isInitialFetch = false } = config || {};
  const [rootResponse, allDirsResponse] = await Promise.all([getDirectories(), getAllDirectories()]);

  const tags = new Set<string>();
  rootResponse.data.files.flatMap((file: WorkspaceFile) => file.tags).forEach((tag: string) => tags.add(tag));

  return {
    rootSubdirectories: rootResponse.data.subdirectories,
    allDirectories: allDirsResponse.data,
    rootFiles: rootResponse.data.files,
    isInitialFetch,
    userTags: Array.from(tags),
  };
});

export const fetchMediaStorage = createAsyncThunk("mediaStorage", async () => {
  const rootResponse = await axios.get("image_search/directory");
  const allDirsResponse = await axios.get("image_search/directory/all");

  const root = {
    id: "",
    name: "Main Library",
    children: [],
  };

  const allFolderNodes: { [id: string]: FolderNode } = { "": root };
  const user_tags = new Set<string>();
  let current: FolderNode[] = [root];
  while (current.length > 0) {
    const next: FolderNode[] = [];
    for (const currentNode of current) {
      const response = currentNode.id ? await axios.get(`image_search/directory/${currentNode.id}`) : rootResponse;
      const { subdirectories, files } = response.data;
      for (const subdirectory of subdirectories) {
        const { id, name } = subdirectory;
        const newNode = {
          id,
          name,
          children: [],
          parentId: currentNode.id,
        };
        currentNode.children.push(newNode);
        allFolderNodes[id] = newNode;
      }
      files.flatMap((file: WorkspaceMedia) => file.user_tags).forEach((tag: string) => user_tags.add(tag));
      next.push(...currentNode.children);
    }
    current = next;
  }

  return {
    allFolderNodes,
    rootSubdirectories: rootResponse.data.subdirectories,
    allDirectories: allDirsResponse.data,
    rootFiles: rootResponse.data.files,
    userTags: Array.from(user_tags),
  };
});

export interface NavHistory {
  id: string;
  name: string;
}

export interface FolderNode {
  id: string;
  name: string;
  children: FolderNode[];
  parentId?: string;
}

type DriveState = {
  fileStorage: {
    rootFiles: WorkspaceFile[];
    rootSubdirectories: Subdirectory[];
    allDirectories: Subdirectory[];
    isLoading: boolean;
    navHistory: NavHistory[];
    isInitialFetch: boolean;
    userTags: string[];
  };
  media: {
    rootFiles: WorkspaceMedia[];
    rootSubdirectories: Subdirectory[];
    allDirectories: Subdirectory[];
    isLoading: boolean;
    selectedFolderHeading: string;
    draggingDocIds: string[];
    draggingFolderId: string;
    isDragOverDrive: boolean;
    navHistory: NavHistory[];
    allFolderNodes: { [id: string]: FolderNode };
    userTags: string[];
  };
};

const initialState: DriveState = {
  fileStorage: {
    rootFiles: [],
    rootSubdirectories: [],
    allDirectories: [],
    isLoading: false,
    navHistory: [],
    isInitialFetch: false,
    userTags: [],
  },
  media: {
    rootFiles: [],
    rootSubdirectories: [],
    allDirectories: [],
    isLoading: false,
    selectedFolderHeading: "",
    draggingDocIds: [],
    draggingFolderId: "",
    isDragOverDrive: false,
    navHistory: [],
    allFolderNodes: {},

    userTags: [],
  },
};

const driveSlice = createSlice({
  name: "drive",
  initialState,
  reducers: {
    updateAllFolders: (state, action) => {
      state.fileStorage.rootSubdirectories = action.payload;
    },
    updateAllDocs: (state, action) => {
      state.fileStorage.rootFiles = action.payload;
    },
    setDocumentNavHistory: (state, action) => {
      state.fileStorage.navHistory = action.payload;
    },
    setDocumentUserTags: (state, action) => {
      state.fileStorage.userTags = action.payload;
    },
    updateMediaFolderNodes: (state, action) => {
      state.media.allFolderNodes = action.payload;
    },
    updateMediaRootFolders: (state, action) => {
      state.media.rootSubdirectories = action.payload;
    },
    updateMediaRootDocs: (state, action) => {
      state.media.rootFiles = action.payload;
    },
    setIsDragOverDrive: (state, action) => {
      state.media.isDragOverDrive = action.payload;
    },
    setDraggingDocIds: (state, action) => {
      state.media.draggingDocIds = action.payload;
    },
    setDraggingFolderId: (state, action) => {
      state.media.draggingFolderId = action.payload;
    },
    setSelectedFolderHeading: (state, action) => {
      state.media.selectedFolderHeading = action.payload;
    },
    setMediaNavHistory: (state, action) => {
      state.media.navHistory = action.payload;
    },
    setMediaUserTags: (state, action) => {
      state.media.userTags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFileStorage.pending, (state, action) => {
        state.fileStorage.isLoading = true;
        const { isInitialFetch = false } = action.meta.arg || {};

        if (isInitialFetch) {
          state.fileStorage.isInitialFetch = true;
        }
      })
      .addCase(fetchFileStorage.fulfilled, (state, action) => {
        state.fileStorage.isLoading = false;
        state.fileStorage.isInitialFetch = false;
        state.fileStorage.rootSubdirectories = action.payload.rootSubdirectories || [];
        state.fileStorage.allDirectories = action.payload.allDirectories || [];
        state.fileStorage.rootFiles = action.payload.rootFiles || [];
        state.fileStorage.userTags = action.payload.userTags || [];
      })
      .addCase(fetchFileStorage.rejected, (state) => {
        state.fileStorage.isLoading = false;
        state.fileStorage.isInitialFetch = false;
      })
      .addCase(fetchMediaStorage.pending, (state) => {
        state.media.isLoading = true;
      })
      .addCase(fetchMediaStorage.fulfilled, (state, action) => {
        state.media.isLoading = false;
        state.media.rootSubdirectories = action.payload.rootSubdirectories;
        state.media.allDirectories = action.payload.allDirectories;
        state.media.rootFiles = action.payload.rootFiles;
        state.media.allFolderNodes = action.payload.allFolderNodes;
        state.media.userTags = action.payload.userTags;
      })
      .addCase(fetchMediaStorage.rejected, (state) => {
        state.media.isLoading = false;
      });
  },
});

export default driveSlice.reducer;
export const {
  updateAllFolders,
  updateAllDocs,
  setIsDragOverDrive,
  setDraggingDocIds,
  setDraggingFolderId,
  setSelectedFolderHeading,
  setDocumentNavHistory,
  setMediaNavHistory,
  updateMediaRootDocs,
  updateMediaRootFolders,
  updateMediaFolderNodes,
  setMediaUserTags,
  setDocumentUserTags,
} = driveSlice.actions;

import type { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { Button } from "components/editor/components";
import Input from "components/molecules/input";
import { Modal } from "components/organisms/modal";
import { EMPTY_SHEET } from "const-values/Sheets";
import useSheetOperations from "hook/useSheetOperations";
import type { ComponentProps } from "react";
import { useEffect, useMemo, useState } from "react";
import { setActiveSheetState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch } from "store/storeTypes";
import tw from "twin.macro";
import { createSheet } from "utils/sheet";

interface Props extends Pick<ComponentProps<typeof Modal>, "open" | "onOpenChange"> {
  sheets: Sheet[];
}

const NewSheetModal = ({ sheets, ...props }: Props) => {
  const [sheetName, setSheetName] = useState("");
  const { appendNewSheets } = useSheetOperations();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.open) setSheetName("");
  }, [props.open]);

  const isSheetValid = useMemo(() => {
    return (
      !!sheetName.trim() &&
      sheetName.length <= 31 &&
      sheetName?.toLowerCase() !== EMPTY_SHEET.name.toLowerCase() &&
      !sheets.some((sheet) => sheet.name.toLowerCase() === sheetName.toLowerCase())
    );
  }, [sheetName, sheets]);

  const canSubmit = isSheetValid;

  return (
    <Modal
      contentProps={{ css: tw`max-w-[450px]` }}
      title="New sheet dialog"
      header="New Sheet"
      body={
        <div className="flex flex-col px-6">
          <label className="text-sm">
            Name <span className="text-xxs text-slate-500">({sheetName.length}/31 characters)</span>
          </label>
          <Input
            placeholder="Sheet name..."
            value={sheetName}
            onChange={(e) => {
              if (e.target.value.length <= 31) {
                setSheetName(e.target.value);
              }
            }}
          />
        </div>
      }
      footer={
        <Button
          disabled={!canSubmit}
          onClick={() => {
            if (!canSubmit) return;
            const createdSheet = createSheet({ name: sheetName });
            appendNewSheets([createdSheet]);
            dispatch(setActiveSheetState(createdSheet.toJSON() as Sheet));
            props.onOpenChange?.(false);
          }}
          size="md"
          variant="primary"
        >
          Create
        </Button>
      }
      {...props}
    />
  );
};

export default NewSheetModal;

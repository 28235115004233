import { Extension } from "@tiptap/core";
import type { Node } from "@tiptap/pm/model";
import { DEFAULT_LINE_SPACING } from "../constants";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    lineSpacing: {
      setLineSpacing: (value: string) => ReturnType;
    };
  }
}

export const LineSpacingExtension = Extension.create({
  name: "lineSpacing",

  addOptions() {
    return {
      types: ["paragraph", "heading"],
      defaultSpacing: DEFAULT_LINE_SPACING,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            default: this.options.defaultSpacing,
            parseHTML: (element) => {
              return element.style.lineHeight || this.options.defaultSpacing;
            },
            renderHTML: (attributes) => {
              if (!attributes.lineHeight) return {};

              return {
                style: `line-height: ${attributes.lineHeight}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setLineSpacing:
        (spacing: string) =>
        ({ tr, state, dispatch }) => {
          // Get the selection
          const { from, to } = state.selection;
          let hasChanged = false;

          // Helper to update node attributes
          const updateNode = (pos: number, node: Node) => {
            tr.setNodeMarkup(pos, null, {
              ...node.attrs,
              lineHeight: spacing,
            });
            hasChanged = true;
          };

          // If no selection, update current node
          if (from === to) {
            const $pos = state.doc.resolve(from);
            const node = $pos.parent;
            if (this.options.types.includes(node.type.name)) {
              updateNode($pos.before($pos.depth), node);
            }
          } else {
            // Update all nodes in selection
            state.doc.nodesBetween(from, to, (node, pos) => {
              if (this.options.types.includes(node.type.name)) {
                updateNode(pos, node);
              }
              return true;
            });
          }

          if (hasChanged && dispatch) {
            dispatch(tr);
          }

          return hasChanged;
        },
    };
  },
});

import { useAppSelector } from "store/storeTypes";
import { parseWorkflowInstructions } from "utils/assistants/utils";
import { FileInputFields } from "types/Assistants/types";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";

export function useDynamicInputValidation() {
  const { prompt, activeSession, selectedFiles, uploadedDocuments } = useAppSelector((root) => root.aiAssistantState);

  const conversation = activeSession?.conversation || [];
  const parsedInfo = parseWorkflowInstructions(conversation);
  const isParsedAndDynamic =
    !!parsedInfo && typeof parsedInfo !== "string" && parsedInfo.type === INPUT_FIELD_OPTIONS.dynamic;

  const acceptedFileTypes =
    isParsedAndDynamic && parsedInfo.extra.supported_upload_types
      ? parsedInfo.extra.supported_upload_types
      : ["txt", "pdf", "docx", "xlsx", "pptx"];
  const excludedOptions = isParsedAndDynamic ? parsedInfo.extra.exclude : [];
  const maxTotalCount = isParsedAndDynamic ? parsedInfo.extra.max_total_count : undefined;
  const selectionMethod = isParsedAndDynamic ? parsedInfo.extra.selection_method : undefined;
  const allowContentLibrary = !excludedOptions?.includes(FileInputFields.ContentLibrary);
  const allowDocuments = !excludedOptions?.includes(FileInputFields.ChatDocuments);

  const processedUploadedDocuments = uploadedDocuments?.filter((file) => !file.is_processing);

  const totalFiles =
    (allowDocuments ? uploadedDocuments?.length || 0 : 0) + (allowContentLibrary ? selectedFiles?.length || 0 : 0);

  const fileCountLimitExceeded = !!(maxTotalCount && totalFiles > maxTotalCount);
  const promptProvided = prompt.trim().length > 0;
  const uploadedProvided = (uploadedDocuments ?? []).length > 0;
  const selectedProvided = (selectedFiles ?? []).length > 0;
  const inputsCount = (promptProvided ? 1 : 0) + (uploadedProvided ? 1 : 0) + (selectedProvided ? 1 : 0);
  const multipleInputsProvided = selectionMethod === "or" && inputsCount > 1;

  const hasUnsupportedFileTypes = !!(
    selectedFiles?.some((file) => !acceptedFileTypes.includes(file.fileExtensionType)) ||
    uploadedDocuments?.some((file) => !acceptedFileTypes.includes(file.file_extension_type))
  );

  const disableSubmit =
    fileCountLimitExceeded ||
    multipleInputsProvided ||
    hasUnsupportedFileTypes ||
    (!prompt && !selectedFiles.length && !processedUploadedDocuments?.length);

  return {
    acceptedFileTypes,
    maxTotalCount,
    fileCountLimitExceeded,
    multipleInputsProvided,
    hasUnsupportedFileTypes,
    totalFiles,
    disableSubmit,
    prompt,
  };
}

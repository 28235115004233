import { BoldIcon } from "../icons/Bold";
import { Button } from "../primitives/Button";
import type { Editor } from "@tiptap/react";
import { ItalicIcon } from "../icons";
import { ReactComponent as UnderlineIcon } from "../../editor/icons/underline.svg";
import { StrikethroughIcon } from "../icons/Strikethrough";
import { useCallback, useEffect, useState } from "react";
import styles from "./Toolbar.module.css";
import { ToolbarColor } from "./ToolbarColor";
import { ToolbarHighlight } from "./ToolbarHighlight";
import { ToolbarTooltip } from "../primitives/Tooltip";

type FormatState = {
  bold: boolean;
  italic: boolean;
  strike: boolean;
  underline: boolean;
};

interface ToolbarProps {
  editor: Editor;
  variant?: "default" | "bubble";
}

export const ToolbarInline = ({ editor, variant }: ToolbarProps) => {
  const [formatState, setFormatState] = useState<FormatState>({
    bold: false,
    italic: false,
    strike: false,
    underline: false,
  });
  const isBubble = variant === "bubble";

  useEffect(() => {
    if (!editor) return;

    const updateFormatState = () => {
      setFormatState({
        bold: editor.isActive("bold"),
        italic: editor.isActive("italic"),
        strike: editor.isActive("strike"),
        underline: editor.isActive("underline"),
      });
    };

    updateFormatState();
    editor.on("focus", updateFormatState);
    editor.on("selectionUpdate", updateFormatState);

    return () => {
      editor.off("focus", updateFormatState);
      editor.off("selectionUpdate", updateFormatState);
    };
  }, [editor]);

  const handleUnderline = useCallback(() => {
    editor.chain().focus().toggleUnderline().run();
  }, [editor]);

  const iconProps = isBubble ? { style: { color: "#F4F4F5", fill: "#F4F4F5" } } : {};

  return (
    <>
      <ToolbarTooltip content="Bold">
        <Button
          variant={isBubble ? "bubble" : "subtle"}
          className={styles.toolbarButton}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          data-active={formatState.bold ? "is-active" : undefined}
          aria-label="Bold"
        >
          <BoldIcon {...iconProps} />
        </Button>
      </ToolbarTooltip>

      <ToolbarTooltip content="Italic">
        <Button
          variant={isBubble ? "bubble" : "subtle"}
          className={styles.toolbarButton}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          data-active={formatState.italic ? "is-active" : undefined}
          aria-label="Italic"
        >
          <ItalicIcon {...iconProps} />
        </Button>
      </ToolbarTooltip>

      <ToolbarTooltip content="Underline">
        <Button
          variant={isBubble ? "bubble" : "subtle"}
          className={styles.toolbarButton}
          onClick={handleUnderline}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          data-active={formatState.underline ? "is-active" : undefined}
          aria-label="underline"
        >
          <UnderlineIcon {...iconProps} />
        </Button>
      </ToolbarTooltip>

      <ToolbarTooltip content="Strikethrough">
        <Button
          variant={isBubble ? "bubble" : "subtle"}
          className={styles.toolbarButton}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          data-active={formatState.strike ? "is-active" : undefined}
          aria-label="Strikethrough"
        >
          <StrikethroughIcon {...iconProps} />
        </Button>
      </ToolbarTooltip>

      {!isBubble && <ToolbarColor editor={editor} />}
      <ToolbarHighlight editor={editor} variant={isBubble ? "bubble" : "default"} />
    </>
  );
};

import { parseEnvBoolean } from "./utils/parse";

export type VultronConfig = NonNullable<Window["VULTRON_CONFIG"]>;

/**
 * Checks if the "sd" parameter is present in the URL query string and returns a well formed URL if present or available
 * on localStorage.
 *
 * - If "sd" is present but empty (e.g., `?sd=`), it removes it from storage
 * - If "sd" is present with a subdomain (e.g., `?sd=sahra`), it sets it and returns the URL
 * - If "sd" is missing from the URL, it retrieves the value from local storage
 */
function getStorageOverride() {
  try {
    const SUBDOMAIN_STORAGE_KEY = "vultron_dev_subdomain";
    const SUBDOMAIN_URL_KEY = "sd";
    if (process.env.NODE_ENV === "development") {
      const params = new URLSearchParams(window.location.search);
      const subDomainParam = params.get(SUBDOMAIN_URL_KEY)?.trim().toLowerCase();

      if (params.has(SUBDOMAIN_URL_KEY)) {
        if (subDomainParam) {
          localStorage.setItem(SUBDOMAIN_STORAGE_KEY, subDomainParam);
        } else {
          localStorage.removeItem(SUBDOMAIN_STORAGE_KEY);
        }
      }
      const retrievedSubdomain = subDomainParam || localStorage.getItem(SUBDOMAIN_STORAGE_KEY);

      if (retrievedSubdomain) {
        console.log(`🔄 Loaded subdomain override from storage: 🌍 ${retrievedSubdomain}. Clear with "?sd="`);

        return `https://${retrievedSubdomain}.vultron.ai/api`;
      }
    }
    return undefined;
  } catch {
    return undefined;
  }
}

function getSubdomain(url: string) {
  try {
    const hostname = new URL(url).hostname;
    const parts = hostname.split(".");

    // Ensure we have at least one subdomain (exclude domains like "vultron.ai" or "localhost")
    if (parts.length > 2) {
      // Handles nested subdomains (e.g. "dev.stage.vultron.ai" -> "dev.stage"
      return parts.slice(0, -2).join(".");
    }
    return "";
  } catch (e) {
    // Since these are providing default values only, we don't want to log any errors if we can't figure out a default
    // (a set value may still be provided)
    return "";
  }
}

const {
  REACT_APP_AMPLITUDE_PROD,
  REACT_APP_BASE_URL = "",
  REACT_APP_BASE_URL_OVERRIDE = "",
  REACT_APP_BYPASS_USER_SESSION,
  REACT_APP_DD_APPLICATION_ID = "",
  REACT_APP_DD_CLIENT_TOKEN = "",
  REACT_APP_INTERCOM_APP_ID,
  REACT_APP_IS_ONSITE_DEPLOYMENT = "",
  REACT_APP_LD_CLIENT_ID = "",
  REACT_APP_USE_LAUNCHDARKLY,
} = process.env;

const defaultConfig: VultronConfig = {
  amplitudeProd: REACT_APP_AMPLITUDE_PROD,
  apiUrl: getStorageOverride() || REACT_APP_BASE_URL_OVERRIDE || REACT_APP_BASE_URL,
  datadogApplicationId: REACT_APP_DD_APPLICATION_ID,
  datadogClientToken: REACT_APP_DD_CLIENT_TOKEN,
  intercomAppId: REACT_APP_INTERCOM_APP_ID,
  isAmplitudeEnabled: false,
  isLaunchDarklyEnabled: parseEnvBoolean(REACT_APP_USE_LAUNCHDARKLY, true),
  isOnsiteDeployment: parseEnvBoolean(REACT_APP_IS_ONSITE_DEPLOYMENT, false),
  isSessionValidationBypassed: parseEnvBoolean(REACT_APP_BYPASS_USER_SESSION, false),
  ldClientId: REACT_APP_LD_CLIENT_ID,
  sentryDSN: undefined,
  sentrySampleRate: 1,
  uiFlags: {},
};

const config: VultronConfig = {
  ...defaultConfig,
  ...window.VULTRON_CONFIG,
};

const datadogEnv = config.datadogEnv || getSubdomain(config.apiUrl) || `unknown-${window.location.hostname}`;

export { datadogEnv };
export const {
  apiUrl,
  amplitudeProd,
  datadogApplicationId,
  datadogClientToken,
  intercomAppId,
  isAmplitudeEnabled,
  isLaunchDarklyEnabled,
  isOnsiteDeployment,
  isSessionValidationBypassed,
  ldClientId,
  sentryDSN,
  sentrySampleRate,
} = config;
export default config;

import { getSingleDirectory } from "api/api";
import type { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store/storeTypes";
import type { Subdirectory, WorkspaceFile } from "types/FileStorage";
import type { MenuItem, MultiselectMenuItem } from "../dropdown-menu/DropdownMenu";
import { BEFORE_STRING_OFFSET_DAY } from "pages/drive/documents/hooks";

export type NestedDriveState = {
  current_directory: Subdirectory | null;
  files: WorkspaceFile[];
  subdirectories: Subdirectory[];
};

export const useNestedDriveMeta = () => {
  const [nextNestedDriveState, setNextNestedDriveState] = useState<{ [key: string]: NestedDriveState }>({});
  const [currentNestedDriveState, setCurrentNestedDriveState] = useState<NestedDriveState>();
  const [isLoading, setIsLoading] = useState({ current: false, next: false });
  const { rootFiles: allDocs, rootSubdirectories: allFolders } = useAppSelector((root) => root.drive.fileStorage);

  const getNextNestedDriveState = useCallback(() => {
    if (isLoading.next) return;
    setIsLoading((prev) => ({ ...prev, next: true }));
    const directoryRequests = (currentNestedDriveState?.subdirectories ?? allFolders ?? []).map(
      ({ id }) => getSingleDirectory(id) as Promise<AxiosResponse<NestedDriveState, any>>,
    );

    Promise.all(directoryRequests)
      .then((results) => {
        setNextNestedDriveState(
          results.reduce<{ [key: string]: NestedDriveState }>((acc, { data }) => {
            if (data.current_directory) return { [data.current_directory?.id]: data, ...acc };
            return acc;
          }, {}),
        );
      })
      .catch(() => {})
      .finally(() => setIsLoading((prev) => ({ ...prev, next: false })));
  }, [isLoading.next, currentNestedDriveState?.subdirectories, allFolders]);

  const getCurrentNestedDriveState = useCallback(
    (directoryId: string) => {
      if (isLoading.current) return;
      setIsLoading((prev) => ({ ...prev, current: true }));

      (getSingleDirectory(directoryId) as Promise<AxiosResponse<NestedDriveState, any>>)
        .then((results) => setCurrentNestedDriveState(results.data))
        .catch(() => {})
        .finally(() => setIsLoading((prev) => ({ ...prev, current: false })));
    },
    [isLoading],
  );

  useEffect(() => {
    getNextNestedDriveState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNestedDriveState?.current_directory?.id]);

  const docs = currentNestedDriveState?.files || allDocs;
  const folders = useMemo(
    () => currentNestedDriveState?.subdirectories || allFolders,
    [allFolders, currentNestedDriveState?.subdirectories],
  );

  return {
    docs,
    folders,
    nextNestedDriveState,
    currentNestedDriveState,
    setCurrentNestedDriveState,
    isLoading,
    getCurrentNestedDriveState,
  };
};

export const useContentLibraryFilterItems = ({
  publishDatesConfig,
  typesConfig,
}: Partial<{
  publishDatesConfig: { uploaded: string; onSelect: (val: keyof typeof BEFORE_STRING_OFFSET_DAY) => void };
  typesConfig: { documentTypes: string[]; onCheck: (val: string[] | string) => void };
}>) => {
  const { uploaded, onSelect } = publishDatesConfig || {};
  const { documentTypes, onCheck } = typesConfig || {};

  const publishDateItemOptions: MenuItem<string>[] = useMemo(
    () =>
      (Object.keys(BEFORE_STRING_OFFSET_DAY) as (keyof typeof BEFORE_STRING_OFFSET_DAY)[]).map((value, i) => ({
        key: i,
        label: value,
        value,
        onSelect: () => onSelect?.(value),
        selected: value === uploaded,
      })),
    [onSelect, uploaded],
  );
  const typeItems: MultiselectMenuItem<string>[] = useMemo(
    () => [
      {
        key: 1,
        label: "Proposal",
        value: "Proposal",
        checked: documentTypes?.includes("Proposal"),
        onCheck: (_, val) => {
          onCheck?.([val, "Past Proposal"]);
        },
      },
      {
        key: 2,
        label: "Technical Documentation",
        value: "Technical Documentation",
        checked: documentTypes?.includes("Technical Documentation"),
        onCheck: (_, val) => {
          onCheck?.([val, "Product Documentation"]);
        },
      },
      {
        key: 3,
        label: "Whitepaper",
        value: "Whitepaper",
        checked: documentTypes?.includes("Whitepaper"),
        onCheck: (_, val) => {
          onCheck?.([val, "Technical Whitepaper"]);
        },
      },
      {
        key: 4,
        label: "Resume",
        value: "Resume",
        checked: documentTypes?.includes("Resume"),
        onCheck: (_, val) => onCheck?.(val),
      },
      {
        key: 5,
        label: "Reports",
        value: "Reports",
        checked: documentTypes?.includes("Reports"),
        onCheck: (_, val) => {
          onCheck?.([val, "Marketing Material"]);
        },
      },
      {
        key: 6,
        label: "Certifications",
        value: "Certifications",
        checked: documentTypes?.includes("Certifications"),
        onCheck: (_, val) => {
          onCheck?.([val, "Certification"]);
        },
      },
      {
        key: 7,
        label: "Past Performance",
        value: "Past Performance",
        checked: documentTypes?.includes("Past Performance"),
        onCheck: (_, val) => onCheck?.(val),
      },
      {
        key: 8,
        label: "Solutions",
        value: "Solutions",
        checked: documentTypes?.includes("Solutions"),
        onCheck: (_, val) => onCheck?.(val),
      },
      {
        key: 9,
        label: "Capabilities",
        value: "Capabilities",
        checked: documentTypes?.includes("Capabilities"),
        onCheck: (_, val) => onCheck?.(val),
      },
      {
        key: 10,
        label: "Project Management",
        value: "Project Management",
        checked: documentTypes?.includes("Project Management"),
        onCheck: (_, val) => onCheck?.(val),
      },
    ],
    [documentTypes, onCheck],
  );

  return { typeItems, publishDateItemOptions };
};

import { ReactComponent as Note } from "Assets/note-2.svg";
import type { PopoverProps } from "components/atoms/popover";
import { Crosshair, FileQuestion, List, Table, UnfoldVertical } from "lucide-react";
import type { FC } from "react";
import tw from "twin.macro";

export enum Source {
  ProjectAttachments = 1,
  ContentDrive,
  Search,
  Internet,
}

export const DELIMITER = "tk^-$-^tk";

export const QUICK_ACTIONS = [
  {
    label: "Substantiate",
    value: "Substantiate this",
    icon: Crosshair,
  },
  {
    label: "Explain How",
    value: "Explain how in steps",
    icon: FileQuestion,
  },
  {
    label: "Expand",
    value: "Expand on this",
    icon: UnfoldVertical,
  },
  {
    label: "Convert to Table",
    value:
      "Convert the following content into a markdown table with appropriate column headers. Ensure all rows and columns are aligned in standard markdown table format.",
    icon: Table,
  },
  {
    label: "Convert to List",
    value: "Convert the content below into a markdown list, clearly separating each item as a distinct entry.",
    icon: List,
  },
];

export const SOURCE_TO_META: Record<Source, { copy: string; searchPlaceholder?: string; icon: FC; tooltip?: string }> =
  {
    [Source.Internet]: {
      copy: "Internet",
      icon: Note,
      tooltip:
        "Search the entire internet, a full website, or a specific link. Please indicate how to use the link, when provided, such as whether to use the full website or only the specific link.",
    },
    [Source.ProjectAttachments]: {
      searchPlaceholder: "Search documents...",
      copy: "Project Specific",
      icon: Note,
    },
    [Source.ContentDrive]: {
      searchPlaceholder: "Search content library documents...",
      copy: "Content Library",
      icon: Note,
    },
    [Source.Search]: {
      copy: "Search",
      icon: Note,
    },
  };

export const CONTENT_PROPS: PopoverProps["contentProps"] = {
  align: "end",
  side: "top",
  css: [tw`backdrop-blur-sm ml-0 mr-0 bg-[rgba(255,255,255,0.95)]`],
  onClick: (e) => e.stopPropagation(),
};

import React from "react";
import type { SourceFile } from "types/Assistants/types";
import InlineSourceFileButton from "./InlineSourceFileButton";

interface InlineSourceFileButtonsProps {
  sourceFiles: SourceFile[];
}

const InlineSourceFileButtons: React.FC<InlineSourceFileButtonsProps> = ({ sourceFiles }) => {
  return (
    <span className="inline-flex flex-wrap gap-1.5 align-baseline ml-1.5">
      {sourceFiles.map((file, index) => (
        <InlineSourceFileButton key={file.source_id} file={file} index={index} />
      ))}
    </span>
  );
};

export default InlineSourceFileButtons;

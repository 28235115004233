import { useEffect, useState } from "react";
import { VultronAvatar } from "components/molecules/avatar";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import type { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import MarkdownContent from "components/molecules/ai-assistant-blocks/vultron-block/vultron-block-subsections/MarkdownContent";
import type { ChecklistGroupWorkflowInstructions, SelectionItem } from "types/Assistants/types";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";

export type ChecklistItemsState = Record<string, boolean>;

interface ChecklistProps<T> {
  blockId: string;
  instruction: string;
  groups: ChecklistGroupWorkflowInstructions["extra"]["groups"];
  activeSession: ToImmutable<T>;
  onChecklistChange: (checklistType: "checklist_group", checklistState: ChecklistItemsState) => void;
}

const ChecklistGroupBlock = <T extends AIAssistantSession | WritingAssistantSession>({
  blockId,
  instruction,
  groups,
  activeSession,
  onChecklistChange,
}: ChecklistProps<T>) => {
  const convertGroupSelectionsToMap = (selectionItems: SelectionItem[]) => Object.fromEntries(selectionItems);
  const [checklistItems, setChecklistItems] = useState<ChecklistItemsState>(() =>
    convertGroupSelectionsToMap(
      groups.flatMap(({ title, selections }) =>
        selections.map(([label, value]) => [`${title}:${label}`, value] as SelectionItem),
      ),
    ),
  );
  const isLastBlock = activeSession?.conversation[activeSession.conversation.length - 1]?.id === blockId;

  useEffect(() => {
    if (!isLastBlock && activeSession?.conversation) {
      const currentIndex = activeSession.conversation.findIndex((msg) => msg.id === blockId);
      const currentBlock = activeSession.conversation[currentIndex];
      if (currentBlock && "input_metadata" in currentBlock && currentBlock.input_metadata?.data) {
        setChecklistItems(currentBlock.input_metadata?.data);
      }
    }
  }, [isLastBlock, activeSession?.conversation, blockId]);

  useEffect(() => {
    if (isLastBlock) {
      onChecklistChange(INPUT_FIELD_OPTIONS.checklistGroup, checklistItems);
    }
  }, [checklistItems, onChecklistChange, isLastBlock]);

  const handleCheckboxChange = (option: string, checked: boolean) => {
    if (!activeSession) return;
    setChecklistItems((prevItems) => ({ ...prevItems, [option]: checked }));
  };

  return (
    <div className="flex flex-row gap-2.5 w-full">
      <VultronAvatar size={26} isSquare />
      <div className="flex flex-col text-sm gap-6 flex-1 min-w-0">
        <div className="flex flex-col">
          <h2 className="text-base font-semibold text-gray-800">{instruction}</h2>
          <div className="text-sm font-normal text-gray-500">Here is the score and feedback for the response</div>
        </div>
        <div className="flex flex-col gap-8">
          {groups.map((group) => (
            <div key={group.title} className="flex flex-col gap-2">
              <div key={group.title} className="flex flex-col">
                <div className="text-base font-bold text-gray-darkest">{group.title}</div>
                <div className="text-sm font-normal text-gray-500">{group.subtitle}</div>
              </div>

              <ul className="flex flex-col gap-2">
                {group.selections.map(([option], i) => {
                  const extraContext = group.extra_context?.[i];
                  const content = extraContext ? `<strong>${extraContext}</strong>${option}` : option;
                  return (
                    <li key={option} className="flex items-start">
                      <input
                        type="checkbox"
                        name={option}
                        id={option}
                        checked={checklistItems[`${group.title}:${option}`]}
                        onChange={(e) => handleCheckboxChange(`${group.title}:${option}`, e.target.checked)}
                        className="mr-2 mt-1.5 h-4 w-4 shrink-0 text-blue-600 border-gray-300 cursor-pointer rounded-md disabled:cursor-not-allowed"
                        disabled={!isLastBlock}
                      />
                      <label aria-label={option} htmlFor={option} className="text-sm text-gray-700">
                        <MarkdownContent blockContent={content} isStreaming={false} enableInternet={false} />
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChecklistGroupBlock;

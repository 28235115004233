import { useSearchParams, useParams } from "react-router-dom";
import { useAppDispatch } from "store/storeTypes";
import { toggleCommentsDrawer } from "store/reducers/proposal/commentsDrawerSlice";
import { CommentsPanel } from "./CommentsPanel";
import { ThreadContext, CommentsStatusFilter, CommentsUserFilter } from "../types";
import { CommentsHeader } from "./CommentsHeader";
import { useState } from "react";

const DEFAULT_FILTERS = {
  status: CommentsStatusFilter.Open,
  user: CommentsUserFilter.AllUsers,
};

export const CommentsDrawer = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const internalContractId = searchParams.get("id")?.toLowerCase();
  const referenceId = searchParams.get("docId")?.toLowerCase() || params.volumeId;
  const commentsContext = params.volumeId ? ThreadContext.DRAFT : ThreadContext.PROPOSAL;
  const [statusFilter, setStatusFilter] = useState<CommentsStatusFilter>(DEFAULT_FILTERS.status);
  const [userFilter, setUserFilter] = useState<CommentsUserFilter>(DEFAULT_FILTERS.user);

  const handleStatusFilterChange = (newState: CommentsStatusFilter) => {
    setStatusFilter(newState);
  };

  const handleUserFilterChange = (newState: CommentsUserFilter) => {
    setUserFilter(newState);
  };

  const handleClose = () => dispatch(toggleCommentsDrawer(false));

  return (
    <div className="flex flex-col h-full border-l border-gray-200">
      <CommentsHeader
        statusFilter={statusFilter}
        userFilter={userFilter}
        onStatusFilterChange={handleStatusFilterChange}
        onUserFilterChange={handleUserFilterChange}
        onClose={handleClose}
      />

      {internalContractId && referenceId && (
        <div className="flex-1 overflow-auto">
          <CommentsPanel
            internalContractId={internalContractId}
            referenceId={referenceId}
            context={commentsContext}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            userFilter={userFilter}
          />
        </div>
      )}
    </div>
  );
};

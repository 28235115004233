import { useState, useRef, useMemo } from "react";
import {
  ALL_REVISE_WORKFLOW_OPTIONS,
  TONE_WORKFLOW_OPTIONS,
  WorkflowSub,
  ADDITIONAL_TONE_WORKFLOW_OPTIONS,
} from "pages/ai-assistant/constants";
import { useProjectWorkflowActions } from "pages/Workflows/useProjectWorkflowActions";
import type { Editor } from "@tiptap/react";
import { ChevronDown, AudioLines, LoaderCircle } from "lucide-react";
import { TOOLBAR_BUTTON_STYLES } from "./constants";
import Popover from "components/atoms/popover/Popover";
import { useFlags } from "hook/useFlags";

export const ToolbarTone = ({ editor }: { editor: Editor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const flags = useFlags();

  const { createNewWorkflow } = useProjectWorkflowActions();

  const textSelection = useMemo(() => {
    const selection = editor.state.selection;
    const { from, to } = selection;
    return !selection.empty ? editor.state.doc.textBetween(from, to, "\n").trim() : "";
  }, [editor.state.doc, editor.state.selection]);

  const handleRevise = (option: WorkflowSub = WorkflowSub.Revise) => {
    if (isLoading) return;
    setIsLoading(true);

    createNewWorkflow(option, textSelection, ALL_REVISE_WORKFLOW_OPTIONS).finally(() => {
      setIsLoading(false);
    });
  };

  const allowedToneOptions = flags.additionalToneOptions
    ? [...TONE_WORKFLOW_OPTIONS, ...ADDITIONAL_TONE_WORKFLOW_OPTIONS]
    : TONE_WORKFLOW_OPTIONS;
  return (
    <Popover
      open={isOpen}
      onOpenChange={setIsOpen}
      content={
        <div className="flex flex-col">
          {allowedToneOptions.map(({ label, subType }) => (
            <div
              key={subType}
              role="button"
              className="flex gap-2 w-34 border-[#404040] mt-2 px-3 py-2 hover:bg-[#404040] cursor-pointer text-white rounded-lg text-sm"
              onPointerDown={() => {
                setIsOpen(false);
                handleRevise(subType);
              }}
            >
              {label}
            </div>
          ))}
        </div>
      }
      contentProps={{
        className: "z-[100] mt-2 bg-gray-darkest border shadow p-1 pt-0 pb-2 rounded-lg",
      }}
    >
      <button
        ref={triggerRef}
        onClick={() => setIsOpen((prev) => !prev)}
        className={TOOLBAR_BUTTON_STYLES.DEFAULT}
        disabled={isLoading}
        aria-label="Tone"
      >
        {isLoading ? <LoaderCircle className="animate-spin" size={16} /> : <AudioLines size={16} />}
        <span>Tone</span>
        <ChevronDown size={12} />
      </button>
    </Popover>
  );
};

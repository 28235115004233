/** @jsxImportSource @emotion/react */

import React, { memo } from "react";
import { WORKFLOW_OPTIONS } from "./constants";
import { useGlobalWorkflowActions } from "pages/Workflows/useGlobalWorkflowActions";
import { useFlags } from "hook/useFlags";
import { useAppSelector } from "store/storeTypes";
import { useNavigate } from "react-router-dom";

const WorkflowButtons: React.FC = () => {
  const flags = useFlags();
  const navigate = useNavigate();

  const { createWorkflow } = useGlobalWorkflowActions();
  const { activeSession } = useAppSelector((root) => root.aiAssistantState);

  if (
    !flags.globalAssistantWorkflows ||
    !Array.isArray(flags.globalAssistantWorkflows.workflows) ||
    activeSession?.conversation.length
  ) {
    return null;
  }

  return (
    <div className="relative flex justify-center items-center pb-6 gap-2 mx-4 lg:px-2 w-full lg:mx-auto lg:max-w-3xl xl:max-w-4xl 2xl:max-w-[1000px] 3xl:max-w-[1100px]">
      <div className="flex flex-wrap gap-2 mt-2">
        {flags.globalAssistantWorkflows.workflows.map((workflow) => {
          const foundWorkflowInfo = WORKFLOW_OPTIONS.find((option) => option.subType === workflow.name);
          if (!foundWorkflowInfo || !foundWorkflowInfo.featured) return null;
          const IconComponent = foundWorkflowInfo.icon;

          return (
            <button
              key={foundWorkflowInfo.subType}
              onClick={() => createWorkflow(foundWorkflowInfo.name, foundWorkflowInfo.subType, foundWorkflowInfo.label)}
              className="flex items-center justify-center gap-1.5 border border-gray-300 text-sm text-gray-500 rounded-md px-3 py-2 w-auto hover:bg-gray-100 transition-colors duration-200"
            >
              {IconComponent && <IconComponent className={`${foundWorkflowInfo.color} h-[14px] w-[14px]`} />}
              {foundWorkflowInfo.label}
            </button>
          );
        })}
        <button
          onClick={() => navigate("/dashboard/ai-assistant/workflows")}
          className="flex items-center justify-center gap-1.5 border border-gray-300 text-sm text-gray-500 rounded-md px-3 py-2 w-auto hover:bg-gray-100 transition-colors duration-200"
        >
          Explore All
        </button>
      </div>
    </div>
  );
};

export default memo(WorkflowButtons);

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type State = {
  isDragActive: boolean;
};

const initialState: State = {
  isDragActive: false,
};

const dragDropReducer = createSlice({
  name: "dragDropReducer",
  initialState,
  reducers: {
    setIsDragActive: (state: State, action: PayloadAction<State["isDragActive"]>) => {
      state.isDragActive = action.payload;
    },
  },
});

export const { setIsDragActive } = dragDropReducer.actions;

export default dragDropReducer.reducer;

/** @jsxImportSource @emotion/react */

import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import WritingAssistant from "components/copilot/writing-assistant-drawer";
import CopilotActionsOptions from "./CopilotActionsOptions";
import { ContentSearchContent } from "../Framework/content-search-drawer";
import { ScreenSpinner } from "utils/icons";
import RequirementDetails from "components/organisms/requirement-details";
import { toggleRequirements } from "store/reducers/copilot/copilotDrawerReducer";
import { setActiveRequirementId } from "store/reducers/copilot/requirementsReducer";
import Icon from "components/atoms/icons/Icon";
import { RequirementsDrawerContent } from "../Framework/requirements-drawer/RequirementsDrawer";
import "./styles.css";
import { useStatus } from "YJSProvider/createYJSContext";
import { useParams, useSearchParams } from "react-router-dom";
import { ThreadContext } from "components/Comments/types";
import { useState } from "react";
import { CommentsHeader } from "components/Comments/components/CommentsHeader";
import { CommentsPanel } from "components/Comments/components/CommentsPanel";
import { CommentsStatusFilter, CommentsUserFilter } from "components/Comments/types";
import { toggleComments } from "store/reducers/copilot/copilotDrawerReducer";

const CopilotActionsPanel = () => {
  const dispatch = useAppDispatch();
  const { open, assistantOpen, contentSearchOpen, requirementsOpen, requirementDetailsOpen, commentsOpen } =
    useAppSelector((root) => root.copilotDrawer);
  const yjsStatus = useStatus();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
  const referenceId = searchParams.get("docId")?.toLocaleLowerCase() || params.volumeId;
  const commentsContext = params.volumeId ? ThreadContext.DRAFT : ThreadContext.PROPOSAL;
  const [statusFilter, setStatusFilter] = useState<CommentsStatusFilter>(CommentsStatusFilter.Open);
  const [userFilter, setUserFilter] = useState<CommentsUserFilter>(CommentsUserFilter.AllUsers);

  return (
    <div
      className="bg-[#f8f9fa] pl-0 h-full w-full duration-200"
      css={[
        open && tw`relative w-full animate-fadeIn [animation-delay: 150ms]`,
        !open && tw`relative animate-delayHide [animation-delay: 400ms] pointer-events-none`,
      ]}
    >
      <div className="relative flex flex-col h-full items-center bg-white overflow-hidden">
        {yjsStatus !== "connected" && (
          <div className="p-7 top-0 left-0 right-0 bottom-0 absolute bg-[#5f55550e] z-[999999999] backdrop-blur-[2px] my-auto flex items-center justify-center">
            <ScreenSpinner />
          </div>
        )}

        <CopilotActionsOptions />

        {requirementDetailsOpen && (
          <div className="w-full text-base font-semibold text-gray-600 px-3 py-2">
            <div className="flex gap-1 items-center">
              <button
                className="!h-5 !w-5 text-inherit hover:text-gray-darkest"
                onClick={() => {
                  dispatch(toggleRequirements(true));
                  dispatch(setActiveRequirementId(null));
                }}
              >
                <Icon name="ArrowLeft" className="h-5 w-5" />
              </button>
              <span>Requirements</span>
            </div>
          </div>
        )}
        {assistantOpen && <WritingAssistant />}
        {contentSearchOpen && (
          <div className="h-[calc(100%-46px)] w-full flex flex-col pt-4 border-t border-gray-light">
            <ContentSearchContent />
          </div>
        )}
        {(requirementsOpen || requirementDetailsOpen) && (
          <div
            className="relative h-[calc(100%-46px)] w-full flex flex-col pt-4 border-t border-gray-light"
            css={[requirementDetailsOpen && tw`h-[calc(100%-94px)]`]}
          >
            <RequirementsDrawerContent />
            {requirementDetailsOpen && <RequirementDetails />}
          </div>
        )}
        {commentsOpen && internalContractId && referenceId && commentsContext === ThreadContext.DRAFT && (
          <div className="h-[calc(100%-46px)] w-full flex flex-col border-t border-gray-light">
            <CommentsHeader
              statusFilter={statusFilter}
              userFilter={userFilter}
              onStatusFilterChange={setStatusFilter}
              onUserFilterChange={setUserFilter}
              onClose={() => dispatch(toggleComments(false))}
            />
            <CommentsPanel
              internalContractId={internalContractId}
              referenceId={referenceId}
              context={commentsContext}
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
              userFilter={userFilter}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CopilotActionsPanel;

/** @jsxImportSource @emotion/react */

import { createTemplate } from "api/api";
import type { Framework, Storage, Volume } from "components/copilot/CopilotSchemaTypes";
import { transformFrameworkToSavePayload } from "components/copilot/Framework/utils";
import { Button } from "components/editor/components";
import { Modal } from "components/organisms/modal";
import { useNotification } from "context/notificationContext";
import { useHandleApiError } from "hook/useHandleApiError";
import { useState } from "react";
import { toggleSaveTemplateModal } from "store/reducers/modalsSlice";
import { fetchTemplates } from "store/reducers/templates/templatesReducer";
import { useAppDispatch } from "store/storeTypes";
import tw from "twin.macro";
import type { TemplateDetails } from "types/Templates";
import { useMutation } from "YJSProvider/createYJSContext";
import type { ToImmutable } from "YJSProvider/LiveObjects";

export interface SaveTemplateModalProps {
  open: boolean;
  initialProps?: Partial<{
    volumeId: string;
  }>;
}

const SaveTemplateModal = ({ open, initialProps }: SaveTemplateModalProps) => {
  const { volumeId } = initialProps || {};
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const [newTemplate, setNewTemplate] = useState<Partial<Pick<TemplateDetails, "name" | "data">>>({ name: "" });
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { setError } = useHandleApiError();

  const onSave = useMutation(
    async ({ storage }) => {
      if (isSaving) return;
      setIsSaving(true);
      try {
        const framework = storage?.get("framework") as Storage["framework"];
        const volumes = framework?.get("volumes") as Framework["volumes"] | undefined;
        const immutableVolumes = (volumes?.toArray().map((vol) => vol.toJSON()) || []) as ToImmutable<Volume>[];
        const foundVolume = volumeId ? immutableVolumes.filter((volume) => volume.id === volumeId) : immutableVolumes;
        const data = { volumes: transformFrameworkToSavePayload(foundVolume) };

        const {
          data: { id },
        } = await createTemplate({ name: newTemplate.name, data });

        framework?.get("template")?.set("id", id);

        try {
          await dispatch(fetchTemplates());
        } catch {
          setToast.error({
            title: "Unable to save template",
            msg: "We were unable to save the template due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
        }
        dispatch(toggleSaveTemplateModal({ open: false }));
        setNewTemplate({ name: "" });
      } catch (err) {
        setError("Failed to create the template. Please refresh and try again.");
      } finally {
        setIsSaving(false);
      }
    },
    [newTemplate, volumeId],
  );

  return (
    <Modal
      open={open}
      onOpenChange={(o) => {
        if (!o) {
          setNewTemplate({ name: "" });
        }
        dispatch(toggleSaveTemplateModal({ open: o }));
      }}
      title="New template dialog"
      header="New Template"
      body={
        <div className=" w-full gap-2 flex-col justify-start items-start inline-flex px-6">
          <div className="text-gray-500 text-sm font-normal leading-snug">Template Name</div>
          <input
            autoFocus
            value={newTemplate.name}
            onChange={(e) => setNewTemplate((prev) => ({ ...prev, name: e.target.value }))}
            className="w-full px-3 py-2 bg-white rounded-lg border border-neutral-300 outline-0"
          />
        </div>
      }
      footer={
        <>
          <Button
            size="md"
            variant="outline"
            className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
            onClick={() => {
              dispatch(toggleSaveTemplateModal({ open: false }));
              setNewTemplate({ name: "" });
            }}
          >
            Cancel
          </Button>
          <Button
            size="md"
            variant="primary"
            loading={isSaving}
            css={[isSaving && tw`pointer-events-none`]}
            disabled={isSaving || !newTemplate.name?.length}
            onClick={() => onSave()}
          >
            Save
          </Button>
        </>
      }
    />
  );
};

export default SaveTemplateModal;

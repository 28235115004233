/** @jsxImportSource @emotion/react */
import InternetToggle from "./InternetToggle";

import AIAssistantInputActions from "../AIAssistantInputActions";
import VoiceTranslateItem from "components/voice-assist/VoiceTranslateItem";
import { ArrowUp, Square } from "lucide-react";
import tw from "twin.macro";
import Tooltip from "components/atoms/tooltip";
import { useAppSelector } from "store/storeTypes";
import { FileInputFields } from "types/Assistants/types";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
interface InputFooterProps {
  flags: { documentAttachments: boolean };
  isStreamingInProgress: boolean;
  isCurrentSessionInTasks: boolean;
  abortConnection: () => void;
  submitMessage: () => void;
  handleTranslation: (message: string) => void;
  canSubmit: boolean;
  className?: string;
}

const InputFooter: React.FC<InputFooterProps> = ({
  flags,
  isStreamingInProgress,
  isCurrentSessionInTasks,
  abortConnection,
  submitMessage,
  handleTranslation,
  canSubmit,
  className,
}) => {
  const activeSession = useAppSelector((state) => state.aiAssistantState.activeSession);
  const hideInternetToggle =
    (activeSession?.excluded_fields?.includes(FileInputFields.Internet) ||
      activeSession?.input_field_type === INPUT_FIELD_OPTIONS.string ||
      activeSession?.input_field_type === INPUT_FIELD_OPTIONS.dynamic) &&
    activeSession?.workflow;

  return (
    <div
      className={`absolute flex items-center bottom-0 right-0  bg-white ${className}`}
      css={[flags.documentAttachments ? tw`justify-end` : tw`justify-between`]}
    >
      {!flags.documentAttachments && <AIAssistantInputActions />}
      <div className="flex gap-1 items-center">
        {flags.documentAttachments && !hideInternetToggle && <InternetToggle />}
        <VoiceTranslateItem
          styles={tw`bg-white text-gray-600 duration-150 rounded-full hover:text-black duration-100 hover:bg-gray-200`}
          height={"7"}
          width={"7"}
          onComplete={handleTranslation}
          showTooltip
        />
        <Tooltip content="File processing in progress" disabled={!isCurrentSessionInTasks}>
          <button
            className="bg-black text-sm flex items-center justify-center text-white w-[28px] min-w-[28px] h-[28px] min-h-[28px] duration-150 rounded-full disabled:bg-gray-200 disabled:text-slate-400 disabled:cursor-default hover:bg-slate-700"
            onClick={() => {
              if (isStreamingInProgress) {
                abortConnection();
              } else {
                submitMessage();
              }
            }}
            disabled={!canSubmit && !isStreamingInProgress}
          >
            {isStreamingInProgress ? <Square size={16} className="fill-current" /> : <ArrowUp size={18} />}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default InputFooter;

import { ContentNavigator } from "./ContentNavigator";
import type { Editor } from "@tiptap/react";
import { DocumentComments } from "./DocumentComments";
import { useAppSelector } from "store/storeTypes";
import styles from "../Toolbar.module.css";
import { useRef, useContext } from "react";
import { ScaleContext } from "./ScaleContext";

// default page size
const PAGE = {
  width: 816, // ~8.5in
  height: 1056, // ~11in
  minLeftWidth: 260, // content navigator min width
  rightWidth: 320, // comments sidebar width
};

type DocumentLayoutProps = {
  toolbar: React.ReactNode;
  children: React.ReactNode;
  editor?: Editor | null;
};

export const DocumentLayout = ({ toolbar, children, editor }: DocumentLayoutProps) => {
  const commentsOpen = useAppSelector((state) => state.commentsDrawer.commentsDrawerOpen);
  const rightWidth = commentsOpen ? 32 : PAGE.rightWidth;
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { handleWheel, scale } = useContext(ScaleContext);

  // Compute the effective width based on the current scale.
  const effectiveWidth = PAGE.width * scale;
  // Total min width: left sidebar + document area + comments sidebar.
  const totalMinWidth = PAGE.minLeftWidth + effectiveWidth + rightWidth;

  return (
    <div className="flex flex-col h-full bg-gray-lightest">
      {/* Toolbar Section */}
      <div className="w-full bg-white border-b border-gray-200 shadow-sm z-10">
        <div className={`${styles.scrollableToolbar}`}>{toolbar}</div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 flex min-h-0 relative">
        <div className="flex-1 overflow-auto" ref={scrollContainerRef}>
          <div className="flex" style={{ minWidth: `${totalMinWidth}px` }}>
            <div className="flex-shrink-0" style={{ width: `${PAGE.minLeftWidth}px` }}>
              <div className="sticky top-8 h-fit px-4 w-full">
                <ContentNavigator editor={editor} />
              </div>
            </div>

            {/* Document Area */}
            <div className="mx-auto h-full flex">
              <div
                onWheel={handleWheel}
                className="flex-shrink-0 h-full py-8"
                style={{ width: `${effectiveWidth}px` }}
                id="bubble-menu-portal"
              >
                <div ref={editorContainerRef} style={{ transform: `scale(${scale})`, transformOrigin: "top left" }}>
                  <div
                    className="bg-white shadow-lg"
                    style={{ width: `${PAGE.width}px`, minHeight: `${PAGE.height}px` }}
                  >
                    {children}
                  </div>
                </div>
              </div>

              {!commentsOpen && (
                <div className="flex-shrink-0" style={{ width: `${rightWidth}px` }}>
                  <div className="px-4 w-full">
                    <DocumentComments
                      editor={editor}
                      editorContainerRef={editorContainerRef}
                      scrollContainerRef={scrollContainerRef}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useProxyRef } from "hook/useProxyRef";
import { useEffect, useRef } from "react";
import type { ImperativePanelHandle } from "react-resizable-panels";
import { toggleActions } from "store/reducers/copilot/copilotDrawerReducer";
import { toggleCommentsDrawer } from "store/reducers/proposal/commentsDrawerSlice";
import { setActiveProjectFile } from "store/reducers/proposal/proposalReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";

export const usePanelBehavior = () => {
  const documentViewerOpen = useAppSelector((state) => state.proposal.documentViewerOpen);
  const activeProjectFile = useAppSelector((state) => state.proposal.activeProjectFile);
  const leftPanelRef = useRef<ImperativePanelHandle>(null);
  const dispatch = useAppDispatch();
  const projectAttachments = useAppSelector((root) => root.project.activeProject?.internal_documents);
  const projectAttachmentsRef = useProxyRef(projectAttachments);

  useEffect(() => {
    if (documentViewerOpen) leftPanelRef.current?.resize(50);
    if (!documentViewerOpen) leftPanelRef.current?.collapse();
  }, [documentViewerOpen]);

  useEffect(() => {
    if (!documentViewerOpen) return;

    if (!activeProjectFile) {
      const firstFile = projectAttachmentsRef.current?.find((file) => file.file_type === "pdf");
      if (firstFile) dispatch(setActiveProjectFile(firstFile));
    }
  }, [activeProjectFile, dispatch, documentViewerOpen, projectAttachmentsRef]);

  return { leftPanelRef };
};

export const useSyncPanels = () => {
  const commentsDrawerOpen = useAppSelector((store) => store.commentsDrawer.commentsDrawerOpen);
  const copilotDrawerOpen = useAppSelector((store) => store.copilotDrawer.open);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (commentsDrawerOpen) dispatch(toggleActions(false));
  }, [commentsDrawerOpen, dispatch]);
  useEffect(() => {
    if (copilotDrawerOpen) dispatch(toggleCommentsDrawer(false));
  }, [copilotDrawerOpen, dispatch]);
};

import type { WorkflowNameType, WorkflowSub } from "pages/ai-assistant/constants";
import type { ChatSessionDocument, FileInputFields, InputFieldType, InputMetadata } from "types/Assistants/types";
import type { InputField } from "types/Chat";
import type { LiveList, LiveObject } from "YJSProvider/LiveObjects";

export type AIAssistant = {
  sessions: LiveList<LiveObject<AIAssistantSession>>;
  archived_sessions: LiveList<LiveObject<AIAssistantSession>>;
};

export type AIAssistantBlock = CoreBlock | VultronBlock;

export type AIAssistantSession = {
  id: string;
  name: string;
  updated_at?: string;
  conversation: LiveList<LiveObject<AIAssistantBlock>>;
  excluded_fields?: FileInputFields[];
  input_field_type?: InputFieldType;
  input_field?: InputField;
  workflow?: WorkflowNameType;
  workflow_subtype?: WorkflowSub;
  workflowEnded?: boolean;
};

export enum AssistantBlockType {
  CoreBlock = 1,
  VultronBlock,
}

export type BaseBlock = {
  id: string;
  updated_at: string;
  body: string;
};

export type BlockSource = {
  name: string;
  extension_type: string;
  content: string;
  reference_id: string;
  date: string;
  citation_reference?: string;
};

export type DecoratedChatSessionDocument = ChatSessionDocument & {
  minutes_time_remaining?: number;
  status?: string;
};

export type CoreBlock = {
  type: AssistantBlockType.CoreBlock;
  sources?: LiveList<LiveObject<{ id: string; name: string; extension_type: string }>> | LiveList<never>;
  hide_user_block?: boolean;
  inputFileType?: string;
} & BaseBlock;

export type Storage = {
  ai_assistant: LiveObject<AIAssistant>;
};

export type UserMeta = {
  presence: {};
  info: {
    id: string;
  };
};

export type VultronBlock = {
  type: AssistantBlockType.VultronBlock;
  prompt?: string;
  promptSources?: string[];
  sources: LiveList<LiveObject<BlockSource>> | LiveList<never>;
  error?: boolean;
  enableInternet?: boolean;
  inputFileType?: string;
  input_metadata?: InputMetadata;
} & BaseBlock;

import { Button } from "../primitives/Button";
import { CommentIcon } from "../icons/CommentIcon";
import { ToolbarTooltip } from "../primitives/Tooltip";
import { useDispatch } from "react-redux";
import { toggleCommentsDrawer } from "store/reducers/proposal/commentsDrawerSlice";
import { toggleActions } from "store/reducers/copilot/copilotDrawerReducer";

export const CommentsPanelButton = () => {
  const dispatch = useDispatch();

  const handleCommentClick = () => {
    dispatch(toggleCommentsDrawer(true));
    dispatch(toggleActions(false));
  };

  return (
    <ToolbarTooltip content="Comments panel">
      <Button
        variant="subtle"
        className="border border-gray-200 bg-gray-100 cursor-pointer h-8 hover:border-gray-300"
        onClick={handleCommentClick}
        aria-label="Comments panel"
        style={{ borderRadius: "0.25rem" }}
      >
        <div className="flex items-center gap-1 text-gray-darkest ml-1.5">
          <span className="text-xs">Comments</span>
          <CommentIcon style={{ height: "12px" }} />
        </div>
      </Button>
    </ToolbarTooltip>
  );
};

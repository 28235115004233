import styles from "./Loading.module.css";
import loader from "../../../Assets/loading.svg";

export function Loading() {
  return (
    <div className={styles.loading}>
      <img src={loader} alt="Loading" />
    </div>
  );
}

/** @jsxImportSource @emotion/react */

import { Modal } from "../../organisms/modal";
import type { ComponentProps } from "react";
import { useAppDispatch } from "store/storeTypes";
import { removeDocIdToCanceledReviews } from "store/reducers/proposal/proposalReducer";
import { ReactComponent as CloseCircle } from "../../../Assets/close-circle.svg";

export interface ReviewEngineModalProps extends Pick<ComponentProps<typeof Modal>, "open"> {
  referenceId: string;
}

const ReviewEngineCanceledModal = ({ referenceId, ...props }: ReviewEngineModalProps) => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      title="Review complete dialog"
      header={
        <div className="flex gap-1 items-center">
          <CloseCircle className="h-6 w-6 text-green-700" /> Review Canceled
        </div>
      }
      onOpenChange={() => dispatch(removeDocIdToCanceledReviews(referenceId))}
      body={<div className="flex flex-col gap-2.5 px-6 text-sm">No feedback was added for this proposal.</div>}
      footer={
        <>
          <button
            className="text-white text-sm bg-gray-900 px-3 py-2 rounded"
            onClick={() => dispatch(removeDocIdToCanceledReviews(referenceId))}
          >
            Acknowledge
          </button>
        </>
      }
      {...props}
    />
  );
};

export default ReviewEngineCanceledModal;

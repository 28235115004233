/** @jsxImportSource @emotion/react */

import { ReactComponent as ChevronsRight } from "../../../Assets/chevrons-right.svg";
import { ReactComponent as Draggable } from "../../../Assets/draggable.svg";
import { ReactComponent as MinusCircle } from "../../../Assets/minus-cirlce.svg";
import { ReactComponent as ChevronDown } from "../../../Assets/chevron-down.svg";
import { ReactComponent as Plus } from "../../../Assets/plus-v2.svg";
import { ReactComponent as PlusCircle } from "../../../Assets/plus-circle.svg";
import { ReactComponent as NoteRemove } from "../../../Assets/note-remove.svg";
import { ReactComponent as Swap } from "../../../Assets/swap.svg";
import { ReactComponent as Check } from "../../../Assets/check.svg";
import { ReactComponent as Generate } from "../../../Assets/generate-v2.svg";
import { ReactComponent as Pencil } from "../../../Assets/pencil.svg";
import { ReactComponent as Undo } from "../../../Assets/undo.svg";
import { ReactComponent as WarningCircle } from "../../../Assets/warning-circle.svg";
import { ReactComponent as InfoCircle } from "../../../Assets/info-circle-v2.svg";
import { ReactComponent as InfoCircleBold } from "../../../Assets/info-circle-bold.svg";
import { ReactComponent as TaskSquare } from "../../../Assets/task-square-v2.svg";
import { ReactComponent as Save } from "../../../Assets/save-v2.svg";
import { ReactComponent as EraserSquare } from "../../../Assets/eraser-square-v2.svg";
import { ReactComponent as CarrotDown } from "../../../Assets/carrot-down.svg";
import { ReactComponent as Trash } from "../../../Assets/trash-v2.svg";
import { ReactComponent as NoteFold } from "../../../Assets/note-fold.svg";
import { ReactComponent as NoteQueue } from "../../../Assets/note-queue.svg";
import { ReactComponent as Copy } from "../../../Assets/copy-v2.svg";
import { ReactComponent as Upload } from "../../../Assets/upload.svg";
import { ReactComponent as Columns } from "../../../Assets/columns.svg";
import { ReactComponent as Export } from "../../../Assets/export-v2.svg";
import { ReactComponent as Revise } from "../../../Assets/revise.svg";
import { ReactComponent as Binder } from "../../../Assets/binder.svg";
import { ReactComponent as ArrowLeft } from "../../../Assets/arrow-left-v2.svg";
import { ReactComponent as StatusTodo } from "../../../Assets/statuses/sections/todo.svg";
import { ReactComponent as StatusInProgress } from "../../../Assets/statuses/sections/in-progress.svg";
import { ReactComponent as StatusInReview } from "../../../Assets/statuses/sections/in-review.svg";
import { ReactComponent as StatusDone } from "../../../Assets/statuses/sections/done.svg";
import { ReactComponent as NotCompliant } from "../../../Assets/compliance/not-compliant.svg";
import { ReactComponent as PartialCompliant } from "../../../Assets/compliance/partial-compliant.svg";
import { ReactComponent as FullCompliant } from "../../../Assets/compliance/full-compliant.svg";
import { ReactComponent as EmptyCompliant } from "../../../Assets/compliance/empty-compliant.svg";
import { ReactComponent as Paperclip } from "../../../Assets/paperclip.svg";
import { ReactComponent as Internet } from "../../../Assets/Internet.svg";
import { ReactComponent as Draft } from "../../../Assets/drafts/draft.svg";
import { ReactComponent as Section } from "../../../Assets/drafts/section.svg";
import type { HTMLProps } from "react";
import { forwardRef } from "react";
import "twin.macro";

enum IconType {
  ArrowLeft,
  Binder,
  CarrotDown,
  Check,
  ChevronDown,
  ChevronsRight,
  Columns,
  Copy,
  Draft,
  Draggable,
  EmptyCompliant,
  EraserSquare,
  Export,
  FullCompliant,
  Generate,
  InfoCircle,
  InfoCircleBold,
  Internet,
  MinusCircle,
  NotCompliant,
  NoteFold,
  NoteQueue,
  NoteRemove,
  Pencil,
  PartialCompliant,
  Paperclip,
  Plus,
  PlusCircle,
  Revise,
  Save,
  Section,
  StatusDone,
  StatusInProgress,
  StatusInReview,
  StatusTodo,
  Swap,
  TaskSquare,
  Trash,
  Undo,
  Upload,
  WarningCircle,
}

const ICONS: Record<keyof typeof IconType, React.FC> = {
  ArrowLeft,
  Binder,
  CarrotDown,
  Check,
  ChevronDown,
  ChevronsRight,
  Columns,
  Copy,
  Draft,
  Draggable,
  EmptyCompliant,
  EraserSquare,
  Export,
  FullCompliant,
  Generate,
  InfoCircle,
  InfoCircleBold,
  Internet,
  MinusCircle,
  NotCompliant,
  NoteFold,
  NoteQueue,
  NoteRemove,
  Pencil,
  PartialCompliant,
  Paperclip,
  Plus,
  PlusCircle,
  Revise,
  Save,
  Section,
  StatusDone,
  StatusInProgress,
  StatusInReview,
  StatusTodo,
  Swap,
  TaskSquare,
  Trash,
  Undo,
  Upload,
  WarningCircle,
};

type Props = {
  name: keyof typeof IconType;
} & HTMLProps<SVGElement>;

const Icon = forwardRef<SVGElement, Props>(({ name, ...props }, ref) => {
  const Component = ICONS[name];
  return <Component ref={ref} {...props} />;
});

export default Icon;

import type { ToImmutable } from "YJSProvider/LiveObjects";
import SourceWrapper from "./SourceWrapper";
import type { VultronBlock } from "components/copilot/CopilotSchemaTypes";

interface SourceSectionProps {
  sources: ToImmutable<VultronBlock>["sources"];
  isStreaming: boolean;
}

const SourceSection = ({ sources, isStreaming }: SourceSectionProps) => {
  return <>{!isStreaming && !!sources?.length && <SourceWrapper sources={sources} />}</>;
};

export default SourceSection;

/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from "react";
import { LoaderCircle, File, FilePlus2, CircleAlert, ChevronDown } from "lucide-react";
import Popover from "components/atoms/popover";
import { useFileUploadHandler } from "./hooks";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { getAttachLabel } from "utils/assistants/utils";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import useAIAssistantOperations from "hook/useAIAssistantOperations";
import { getChatSessionDocuments } from "store/reducers/ai-assistant/aiAssistantReducer";
import AttachmentButtonPopoverContent from "components/organisms/AddAttachmentsButtonPopoverContent";
import Tooltip from "components/atoms/tooltip";
import AttachmentTypeDropdown from "./AttachmentTypeDropdown";

interface AddAttachmentsButtonProps {
  maxFileCount?: number;
}

const AddAttachmentsButton: React.FC<AddAttachmentsButtonProps> = ({ maxFileCount }) => {
  const { isProcessing, documentsWithErrors, handleFileUpload, uploadedDocuments } = useFileUploadHandler();
  const { deleteChatDocument } = useAIAssistantOperations();
  const [filePopoverOpen, setFilePopoverOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { activeSession, uploadChatDocumentTasks } = useAppSelector((state) => state.aiAssistantState);
  const enableInternet = useAppSelector((root) => root.aiAssistantState.enableInternet);

  const onDeleteSuccess = useCallback(
    () => dispatch(getChatSessionDocuments(activeSession?.id || "")),
    [dispatch, activeSession?.id],
  );

  const isDisabled = enableInternet || activeSession?.input_field_type === INPUT_FIELD_OPTIONS.pendingWorkflow;

  return (
    <>
      {uploadedDocuments?.length ? (
        <Popover
          open={filePopoverOpen}
          onOpenChange={setFilePopoverOpen}
          contentProps={{ align: "start" }}
          content={
            <AttachmentButtonPopoverContent
              uploadChatDocumentTasks={uploadChatDocumentTasks}
              uploadedDocuments={uploadedDocuments}
              activeSessionId={activeSession?.id}
              onDeleteSuccess={onDeleteSuccess}
              deleteDocument={deleteChatDocument}
              handleFileUpload={handleFileUpload}
            />
          }
        >
          <button
            className="flex items-center text-sm justify-center gap-1.5 px-2 py-1 rounded-md duration-100"
            disabled={isDisabled}
            css={[isDisabled ? tw`text-gray-400` : tw`text-gray-700 hover:text-black hover:bg-gray-200`]}
          >
            <Tooltip
              content="Internet is toggled on, so file attachments are unavailable. Toggle it off to attach files."
              disabled={!enableInternet}
            >
              <div className="flex items-center gap-1.5">
                {isProcessing ? <LoaderCircle className="animate-spin" size={16} /> : <File size={14} />}
                {getAttachLabel(uploadedDocuments, "Attach", isProcessing)}
                {documentsWithErrors.length > 0 && (
                  <CircleAlert size={13} className="text-red-500" data-testid="error-icon" />
                )}
                <ChevronDown size={14} />
              </div>
            </Tooltip>
          </button>
        </Popover>
      ) : (
        <Popover
          open={filePopoverOpen}
          onOpenChange={setFilePopoverOpen}
          contentProps={{ align: "start" }}
          content={<AttachmentTypeDropdown isDisabled={isDisabled} maxFileCount={maxFileCount} />}
        >
          <button
            className="flex items-center text-sm justify-center gap-1.5 px-2 py-1 rounded-md duration-100"
            disabled={isDisabled}
            css={[isDisabled ? tw`text-gray-400` : tw`text-gray-700 hover:text-black hover:bg-gray-200`]}
          >
            <Tooltip
              content="Internet is toggled on, so file attachments are unavailable and will not be used to generate a response. Toggle it off to attach files."
              disabled={!enableInternet}
            >
              <div className="flex items-center gap-1.5">
                {isProcessing ? <LoaderCircle className="animate-spin" size={16} /> : <FilePlus2 size={14} />}
                {getAttachLabel([], "Attach", isProcessing)}
                {documentsWithErrors.length > 0 && (
                  <CircleAlert size={13} className="text-red-500" data-testid="error-icon" />
                )}
                <ChevronDown size={14} />
              </div>
            </Tooltip>
          </button>
        </Popover>
      )}
    </>
  );
};

export default AddAttachmentsButton;

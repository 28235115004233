/** @jsxImportSource @emotion/react */

import { useState } from "react";
import axios from "axios";
import { VOICE_ASSIST_READY, VOICE_ASSIST_PROCESSING, VOICE_ASSIST_RECORDING, VOICE_TRANSLATE_API } from "./constants";
import { useNotification } from "context/notificationContext";

import type { ButtonHTMLAttributes } from "react";
import "twin.macro";
import { Mic, Square } from "lucide-react";
import type { Interpolation, Theme } from "@emotion/react";
import tw from "twin.macro";
import { CAPTURE } from "const-values/testIds";
import Tooltip from "components/atoms/tooltip";

interface Props extends ButtonHTMLAttributes<HTMLDivElement> {
  onComplete: (message: string) => void;
  height?: string;
  width?: string;
  bg?: string;
  text?: string;
  hoverText?: string;
  hoverBg?: string;
  rounded?: string;
  styles?: Interpolation<Theme>;
  showTooltip?: boolean;
}

interface State {
  mediaRecorder: MediaRecorder | undefined;
  voiceAssistState: string;
}

const VoiceTranslateItem = ({
  onComplete,
  height,
  width,
  bg,
  text,
  hoverText,
  hoverBg,
  rounded,
  disabled,
  styles,
  showTooltip,
  ...props
}: Props) => {
  const { setToast } = useNotification();
  const [state, setState] = useState<State>({
    mediaRecorder: undefined,
    voiceAssistState: VOICE_ASSIST_READY,
  });

  const handlePermissionDenied = () => {
    setToast.error({
      title: "Microphone Access Denied",
      msg: "Enable microphone access to use this feature. Please check and update your browser settings.",
    });
  };

  const createMediaRecorder = async () => {
    try {
      if (disabled) {
        return;
      }
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      setState((prev) => ({ ...prev, mediaRecorder }));
      return mediaRecorder;
    } catch (error) {
      handlePermissionDenied();
    }
  };

  const onRecordingComplete = async (audioBlob: any) => {
    try {
      if (disabled) {
        return;
      }
      const formData = new FormData();
      formData.append("audio", audioBlob, "memo.webm");
      const resp = await axios.postForm(VOICE_TRANSLATE_API, formData);
      const message = resp.data.message;
      onComplete(message);
      setState({ ...state, voiceAssistState: VOICE_ASSIST_READY });
    } catch (e) {
      setToast.error({
        title: "Unable to translate request",
        msg: "We were unable to translate the request due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
      });
    }
    setState({ ...state, voiceAssistState: VOICE_ASSIST_READY });
  };

  const handleStartRecording = async () => {
    try {
      if (disabled) {
        return;
      }
      const mediaRecorder = await createMediaRecorder();
      if (!mediaRecorder) {
        return;
      }
      mediaRecorder.ondataavailable = (e) => {
        onRecordingComplete(e.data);
      };
      setState((prevState) => ({ ...prevState, mediaRecorder, voiceAssistState: VOICE_ASSIST_RECORDING }));
      mediaRecorder.start();
    } catch (error) {
      setToast.error({
        title: "Unable to start microphone",
        msg: "We were unable to start the microphone due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
      });
      setState({ ...state, voiceAssistState: VOICE_ASSIST_READY });
    }
  };

  const handleStopRecording = () => {
    if (state.mediaRecorder) {
      const tracks = state.mediaRecorder.stream.getTracks();
      tracks.forEach((track) => track.stop());
      state.mediaRecorder.stop();
      setState((prevState) => ({ mediaRecorder: undefined, voiceAssistState: VOICE_ASSIST_PROCESSING }));
    }
  };

  const renderHeight = height || "6";
  const renderWidth = width || "6";
  const renderBackground = bg || "black";
  const renderText = text || "white";
  const renderHoverText = hoverText || "[#4f4e4e]";
  const renderHoverBackground = hoverBg || "[#4f4e4e]";
  const roundedCorners = rounded || "rounded-full";
  const renderDisable = `opacity-50 hover:${renderText} hover:${renderBackground} cursor-normal`;
  const renderNormal = `hover:text-${renderHoverText} hover:bg-${renderHoverBackground} cursor-pointer`;
  const disabledStyling = disabled ? renderDisable : renderNormal;

  return (
    <Tooltip content="Use Microphone" disabled={!showTooltip}>
      <div
        className={`bg-${renderBackground} p-[2px] relative text-sm flex items-center justify-center text-${renderText} w-${renderWidth} h-${renderHeight} min-w-[20px] min-h-[20px] duration-150 ${roundedCorners} disabled:bg-gray-200 disabled:text-slate-400 ${disabledStyling}`}
        css={[
          styles,
          (state.voiceAssistState === VOICE_ASSIST_RECORDING || state.voiceAssistState === VOICE_ASSIST_PROCESSING) &&
            tw`bg-black`,
        ]}
        {...props}
      >
        {state.voiceAssistState === VOICE_ASSIST_READY && (
          <Mic onClick={handleStartRecording} className="h-[70%] w-[70%]" data-testid={CAPTURE.formItem.dictationBtn} />
        )}
        {state.voiceAssistState === VOICE_ASSIST_RECORDING && (
          <Square
            onClick={handleStopRecording}
            className="h-[70%] w-[70%] fill-current"
            data-testid={CAPTURE.formItem.stopRecordingBtn}
          />
        )}
        {state.voiceAssistState === VOICE_ASSIST_PROCESSING && <div className={`loader-circle h-[70%] w-[70%]`} />}
      </div>
    </Tooltip>
  );
};

export default VoiceTranslateItem;

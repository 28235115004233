/** @jsxImportSource @emotion/react */
import DropdownMenu from "components/molecules/dropdown-menu";
import type { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import { ChevronDown } from "lucide-react";
import { ALL_TEAM_OPTIONS } from "pages/ai-assistant/constants";
import React from "react";
import type { TeamFilterValue } from "./constants";
import { ALL_TEAMS } from "./constants";

interface TeamFilterProps {
  currentFilter: TeamFilterValue;
  setCurrentFilter: React.Dispatch<React.SetStateAction<TeamFilterValue>>;
}

const TeamFilter: React.FC<TeamFilterProps> = ({ currentFilter, setCurrentFilter }) => {
  const handleSelect = (team: TeamFilterValue) => {
    setCurrentFilter(currentFilter === team ? ALL_TEAMS : team);
  };

  const dropdownOptions: MenuItem<void>[] = [
    {
      key: "all",
      label: <div>All Teams</div>,
      onSelect: () => setCurrentFilter(ALL_TEAMS),
    },
    ...ALL_TEAM_OPTIONS.map((team) => ({
      key: team,
      label: <div>{team}</div>,
      onSelect: () => handleSelect(team),
    })),
  ];

  return (
    <DropdownMenu
      triggerProps={{
        className: "border border-gray-200 rounded-md min-w-40 flex items-center justify-between p-2 text-sm",
      }}
      items={dropdownOptions}
    >
      {currentFilter ? currentFilter : "All Teams"}
      <ChevronDown size={16} />
    </DropdownMenu>
  );
};

export default TeamFilter;

/** @jsxImportSource @emotion/react */

import type { Dispatch, SetStateAction } from "react";
import { memo } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useAskAiContext } from "../AskAiContext";
import { setSearchQuery, toggleAskAiOpen } from "store/reducers/extract/CurrentExtractionReducer";
import "../styles.css";

interface DocumentSearchAskAiButtonProps {
  setUserIsSearching: Dispatch<SetStateAction<boolean>>;
}

const DocumentSearchAskAiButton = ({ setUserIsSearching }: DocumentSearchAskAiButtonProps) => {
  const dispatch = useAppDispatch();
  const { handleAskAi, setQuery } = useAskAiContext();

  const askAiOpen = useAppSelector((store) => store.currentExtractionState.askAiOpen);
  const searchQuery = useAppSelector((store) => store.currentExtractionState.searchQuery);

  const handleAskAI = () => {
    if (!askAiOpen) dispatch(toggleAskAiOpen());
    setUserIsSearching(false);
    dispatch(setSearchQuery(""));
    setQuery(searchQuery);
    handleAskAi({ search: true, user_query: searchQuery });
  };

  return (
    <button className="ask-ai-button py-1 px-2 rounded-md text-white" onClick={handleAskAI}>
      Ask AI
    </button>
  );
};

export default memo(DocumentSearchAskAiButton);

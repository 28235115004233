import { useState, useCallback, useEffect } from "react";
import { addFavoriteWorkflow, deleteFavoriteWorkflow, getFavoriteWorkflows } from "api/api";
import * as logger from "utils/log";
import { useTrackUserMetric } from "utils/metrics";
import { EventTracking } from "utils/constants";
import useGetActiveProjectId from "hook/Project/useGetActiveProjectId";

export const useFavoriteWorkflows = () => {
  const [favoriteWorkflows, setFavoriteWorkflows] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [hasFetchedFavorites, setHasFetchedFavorites] = useState(false);

  const projectId = useGetActiveProjectId();
  const trackUserEvent = useTrackUserMetric();

  const fetchFavoriteWorkflows = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getFavoriteWorkflows();
      setFavoriteWorkflows(response.data.favorited_workflows);
    } catch (err) {
      logger.error(err as Error, "Error fetching favorite workflows");
    } finally {
      setHasFetchedFavorites(true);
      setLoading(false);
    }
  }, []);

  const handleFavoriteWorkflow = useCallback(
    async (workflow: string, workflow_subtype?: string | null) => {
      setLoading(true);
      setError(null);
      try {
        await addFavoriteWorkflow(workflow, workflow === workflow_subtype ? null : workflow_subtype);
        await fetchFavoriteWorkflows();
      } catch (err) {
        logger.error(err as Error, "Error favoriting workflow");
      } finally {
        setLoading(false);

        trackUserEvent(EventTracking.WORKFLOW_FAVORITED, {
          project_id: projectId,
          workflow: workflow,
          workflow_subtype: workflow_subtype,
        });
      }
    },
    [fetchFavoriteWorkflows],
  );

  const handleUnfavoriteWorkflow = useCallback(
    async (workflow: string, workflow_subtype?: string | null) => {
      setLoading(true);
      setError(null);
      try {
        await deleteFavoriteWorkflow(workflow, workflow === workflow_subtype ? null : workflow_subtype);
        await fetchFavoriteWorkflows();
      } catch (err: unknown) {
        logger.error(err as Error, "Error unfavoriting workflow");
      } finally {
        setLoading(false);
      }
    },
    [fetchFavoriteWorkflows],
  );

  useEffect(() => {
    fetchFavoriteWorkflows();
  }, [fetchFavoriteWorkflows]);

  return {
    favoriteWorkflows,
    hasFetchedFavorites,
    loading,
    error,
    fetchFavoriteWorkflows,
    handleFavoriteWorkflow,
    handleUnfavoriteWorkflow,
  };
};

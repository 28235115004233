export type BlurInputOrSubmitFormEvent = React.FocusEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>;

export enum PartnerName {
  FederalCompass = "Federal Compass",
}

export type Partner = {
  id: string;
  name: PartnerName;
  description: string;
  logo?: string;
  token?: string;
};

export type PartnerCard = {
  partner_id: string;
  partner_name: PartnerName;
  partner_description: string;
  partner_logo?: string;
  // now partner_token can be string, undefined, or null
  partner_token?: string | null;
};

import { X } from "lucide-react";
import WritingAssistantInputQuickActions from "../WritingAssistantInputQuickActions";
import { setHighlightedText } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch } from "store/storeTypes";

interface HighlightedTextDisplayProps {
  text: string;
}

const HighlightedTextDisplay = ({ text }: HighlightedTextDisplayProps) => {
  const dispatch = useAppDispatch();
  const textStyle = `whitespace-pre-wrap break-words`;
  const scrollableTextStyle = `max-h-72 overflow-auto`;

  return (
    <div className="absolute pb-3 inset-x-0 top-0 transform -translate-y-full whitespace-pre-wrap break-words z-[9]">
      <div className="bg-white relative mt-2 mx-3 rounded-lg border border-black shadow-lg">
        <button
          onClick={() => dispatch(setHighlightedText(""))}
          className="absolute top-3 right-3 text-slate-500 duration-150 hover:text-slate-900"
        >
          <X size={14} />
        </button>
        <p className="text-xs font-semibold px-4 pt-4 text-gray-600">The assistant will act on the highlighted text:</p>
        <p className={`text-xs text-gray-700 pb-4 px-4 pt-2 ${scrollableTextStyle} ${textStyle}`} title={text}>
          {text}
        </p>
        <WritingAssistantInputQuickActions />
      </div>
    </div>
  );
};

export default HighlightedTextDisplay;

import { Avatar } from "components/molecules/avatar";
import { useAppSelector } from "store/storeTypes";
import { useDeleteAssistantBlock } from "../hooks";
import { Trash2 } from "lucide-react";
import LiveDate from "components/molecules/live-date";
import { MarkdownEditor } from "../../../MarkdownEditor";
import { theme } from "twin.macro";
import { AVATAR_BG_COLOR, INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import { pluralizeWord } from "utils/string";
import FilesBlock from "components/molecules/ai-assistant-blocks/core-block/FilesBlock";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { CoreBlock as CoreBlockType } from "../../../CopilotSchemaTypes";

const CoreBlock = ({ block }: { block: ToImmutable<CoreBlockType> }) => {
  const currentUser = useAppSelector((root) => root.auth.currentUser);
  const deleteBlock = useDeleteAssistantBlock();
  const isWorkflowSession = useAppSelector((root) => !!root.writingAssistant.activeSession?.workflow);
  const uploadedDocuments = useAppSelector((root) => root.writingAssistant.uploadedDocuments);
  let blockContent = block.body;

  if (block.inputFileType === INPUT_FIELD_OPTIONS.files) {
    return <FilesBlock />;
  }

  if (
    block.inputFileType === INPUT_FIELD_OPTIONS.contentLibrary ||
    block.inputFileType === INPUT_FIELD_OPTIONS.allFiles
  ) {
    if (!block.sources) {
      blockContent = "No files selected";
    } else {
      const totalDocuments = block.sources.length + (uploadedDocuments?.length ?? 0);
      blockContent = `${totalDocuments} ${pluralizeWord(totalDocuments, "file")} selected`;
    }
  }

  if (
    !blockContent ||
    block.inputFileType === INPUT_FIELD_OPTIONS.checklist ||
    block.inputFileType === INPUT_FIELD_OPTIONS.checklistGroup
  )
    return;

  return (
    <div className="flex flex-row gap-2 w-full">
      <Avatar
        size={30}
        id={currentUser?.username}
        name={currentUser?.username}
        className="text-base"
        isCircle={false}
        bgColor={AVATAR_BG_COLOR}
        textColor={theme`colors.gray.darkest`}
      />
      <div className="flex flex-col gap-3 flex-1 min-w-0 text-sm">
        <MarkdownEditor content={blockContent} />
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-2 items-center text-gray-400">
            {!isWorkflowSession && (
              <button onClick={() => deleteBlock(block.id)} className="duration-150 hover:text-slate-600">
                <Trash2 size={14} className="stroke-[1.6]" />
              </button>
            )}
          </div>
          <LiveDate date={block.updated_at} duration={30000} />
        </div>
      </div>
    </div>
  );
};

export default CoreBlock;

/** @jsxImportSource @emotion/react */
import DropdownMenu from "components/molecules/dropdown-menu";
import type { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import { ChevronDown } from "lucide-react";
import React from "react";
import { TYPE_OPTIONS } from "pages/ai-assistant/constants";
import type { TypeFilterValue } from "./constants";
import { ALL_TYPES } from "./constants";

interface TypeFilterProps {
  currentFilter: TypeFilterValue;
  setCurrentFilter: React.Dispatch<React.SetStateAction<TypeFilterValue>>;
}

const TypeFilter: React.FC<TypeFilterProps> = ({ currentFilter, setCurrentFilter }) => {
  const handleSelect = (name: TypeFilterValue) => {
    setCurrentFilter(currentFilter === name ? ALL_TYPES : name);
  };

  const dropdownOptions: MenuItem<void>[] = [
    {
      key: "all",
      label: <div>All Types</div>,
      onSelect: () => setCurrentFilter(ALL_TYPES),
    },
    ...TYPE_OPTIONS.map((type) => ({
      key: type,
      label: <div>{type}</div>,
      onSelect: () => handleSelect(type),
    })),
  ];

  return (
    <DropdownMenu
      triggerProps={{
        className:
          "border border-gray-200 rounded-md min-w-40 flex items-center justify-between capitalize p-2 text-sm",
      }}
      items={dropdownOptions}
    >
      {currentFilter ? currentFilter : "All Types"}
      <ChevronDown size={16} />
    </DropdownMenu>
  );
};

export default TypeFilter;

import type { WheelEvent } from "react";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ProposalReviewStatus } from "components/yjs-editor/components/text-editor/constants";
import { useComments } from "api/comments/useComments";
import { useAppSelector } from "store/storeTypes";
import { addDocIdToSuccessfulReviews, removeDocIdFromReviews } from "store/reducers/proposal/proposalReducer";
import type { ExtractionTask } from "types/Autopilot/HealthCheck";
import { toggleCommentsDrawer } from "store/reducers/proposal/commentsDrawerSlice";

export const useReviewEngine = (internalContractId: string, referenceId: string) => {
  const dispatch = useDispatch();
  const proposalReviewStatus = useRef(ProposalReviewStatus.Idle);
  const proposal_review_tasks = useAppSelector((state) => state.autopilotHealthCheck.proposal_review_tasks);
  const { proposalsWithSuccessfulReviews, reviewsInProgress, proposalsWithCanceledReviews } = useAppSelector(
    (state) => state.proposal,
  );
  const { refetch } = useComments(internalContractId, referenceId);

  const proposalHasSuccessfulReview = useMemo(() => {
    if (!referenceId) return false;
    return proposalsWithSuccessfulReviews.includes(referenceId);
  }, [proposalsWithSuccessfulReviews, referenceId]);

  const proposalHasCanceledReview = useMemo(() => {
    if (!referenceId) return false;
    return proposalsWithCanceledReviews.includes(referenceId);
  }, [proposalsWithCanceledReviews, referenceId]);

  const proposalReviewTaskInProgress = useMemo(() => {
    if (!referenceId) return null;

    const taskInProgress = proposal_review_tasks?.find((task) => task.reference_id === referenceId && !task.failed);

    if (!taskInProgress && reviewsInProgress.includes(referenceId)) {
      return {
        extraction_id: null,
        reference_id: referenceId,
        is_started: true,
        failed: false,
        minutes_time_remaining: undefined,
      };
    }

    return taskInProgress || null;
  }, [proposal_review_tasks, referenceId, reviewsInProgress]);

  useEffect(() => {
    const refetchComments = async () => {
      const response = await refetch();

      if (response.isSuccess) {
        const hasVultronComments = response.data?.some((thread) =>
          thread.comments.some((comment) => comment.commenter.is_vultron),
        );
        if (referenceId && proposalHasCanceledReview) {
          // No action on canceled reviews
        } else if (!hasVultronComments && referenceId) {
          dispatch(addDocIdToSuccessfulReviews(referenceId));
        } else {
          dispatch(toggleCommentsDrawer(true));
        }
        proposalReviewStatus.current = ProposalReviewStatus.Idle;
      }
    };

    if (!proposalReviewTaskInProgress && proposalReviewStatus.current === ProposalReviewStatus.InProgress) {
      refetchComments();
    } else if (proposalReviewTaskInProgress) {
      proposalReviewStatus.current = ProposalReviewStatus.InProgress;
    }
  }, [proposalReviewTaskInProgress, proposalHasCanceledReview, referenceId, refetch, dispatch]);

  useEffect(() => {
    const removeReviewId = (id: string) => {
      dispatch(removeDocIdFromReviews(id));
    };

    if (proposal_review_tasks.length && reviewsInProgress.length) {
      for (const id of reviewsInProgress) {
        const relatedTasks = proposal_review_tasks.filter((task: ExtractionTask) => task.reference_id === id);

        const taskInProgress = relatedTasks.some((task: ExtractionTask) => task.is_started);

        if (taskInProgress) {
          removeReviewId(id);
        } else {
          setTimeout(() => {
            removeReviewId(id);
          }, 8000);
        }
      }
    }
  }, [dispatch, proposal_review_tasks, referenceId, reviewsInProgress]);

  return {
    proposalHasSuccessfulReview,
    proposalReviewTaskInProgress,
    proposalHasCanceledReview,
  };
};

export const useScale = () => {
  const [scale, setScale] = useState(1);
  const scaledDocRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const rafId = requestAnimationFrame(() => {
      if (!scaledDocRef.current || !containerRef.current) return;

      scaledDocRef.current.style.transform = `scale(${scale})`;
      const scaledWidth = scaledDocRef.current.getBoundingClientRect().width;
      const scaledHeight = scaledDocRef.current.getBoundingClientRect().height;

      const diff = scaledWidth - containerRef.current.clientWidth;

      containerRef.current.style.height = `${scaledHeight}px`;

      if (diff < 0) return;
      containerRef.current.style.marginLeft = `${diff / 2}px`;
      containerRef.current.style.marginRight = `${diff / 2}px`;
    });

    return () => cancelAnimationFrame(rafId);
  }, [scale]);

  useEffect(() => {
    /**
     * Disables pinch-to-zoom using Ctrl + Scroll (or Cmd + Scroll on macOS).
     *
     * - `passive: false` is necessary because the default behavior of wheel events
     *   in browsers is to allow scrolling/zooming. To call `event.preventDefault()`,
     *   the event listener must be explicitly non-passive.
     * - `disableZoom` is kept minimal and performant to avoid blocking the main thread.
     *   Since wheel events fire rapidly, any delays here can lead to performance issues.
     */
    const disableZoom = (event: globalThis.WheelEvent) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    };
    window.addEventListener("wheel", disableZoom, { passive: false });

    return () => {
      window.removeEventListener("wheel", disableZoom);
    };
  }, []);

  const isScrollingRef = useRef<boolean>(false);
  const handleWheel = useCallback((event: WheelEvent<HTMLDivElement>) => {
    if (!event.ctrlKey && !event.metaKey) {
      isScrollingRef.current = true;
      setTimeout(() => {
        isScrollingRef.current = false;
      }, 200);
    }
    if (!isScrollingRef.current) {
      setScale((prevScale) => Math.min(Math.max(prevScale + event.deltaY * -0.01, 0.5), 3));
    }
  }, []);

  return { handleWheel, scale, setScale, containerRef, scaledDocRef };
};

/** @jsxImportSource @emotion/react */
import React from "react";
import { ArrowUp, X } from "lucide-react";
import { FlexibleTextarea } from "components/molecules/flexible-textarea";
import tw from "twin.macro";

interface FeedbackOptionsPanelProps {
  explanationOptions: readonly string[];
  selectedExplanation?: string;
  otherExplanation: string;
  showTextArea: boolean;
  onClose: () => void;
  onSelectExplanation: (explanation: string) => void;
  onOtherExplanationChange: (value: string) => void;
  onSubmitOtherExplanation: () => void;
  onToggleTextArea: () => void;
}

const FeedbackOptionsPanel: React.FC<FeedbackOptionsPanelProps> = ({
  explanationOptions,
  selectedExplanation,
  otherExplanation,
  showTextArea,
  onClose,
  onSelectExplanation,
  onOtherExplanationChange,
  onSubmitOtherExplanation,
  onToggleTextArea,
}) => {
  return (
    <div className="w-full px-[14px] py-3 border border-gray-300 rounded-md flex flex-col gap-2">
      <div className="flex justify-between text-sm text-gray-500">
        What didn&apos;t you like about this response
        <button onClick={onClose} className="flex items-center justify-center hover:text-gray-900">
          <X size={16} />
        </button>
      </div>
      {showTextArea ? (
        <div className="relative">
          <FlexibleTextarea
            value={otherExplanation}
            placeholder="Add specific details..."
            className="p-2 outline-none border border-gray-200 rounded-md resize-none bg-transparent pointer-events-auto min-h-[75px] w-full"
            onChange={(e) => onOtherExplanationChange(e.currentTarget.value)}
            autoFocus
          />
          <button
            className="absolute bottom-3 right-2 bg-black text-sm flex items-center justify-center text-white w-[20px] min-w-[20px] h-[20px] min-h-[20px] duration-150 rounded-full disabled:bg-gray-200 disabled:text-slate-400 disabled:cursor-default hover:bg-slate-700"
            data-testid="submit-arrow"
            disabled={!otherExplanation}
            onClick={onSubmitOtherExplanation}
          >
            <ArrowUp size={14} />
          </button>
        </div>
      ) : (
        <div className="flex items-center gap-x-3 gap-y-2 flex-wrap">
          {explanationOptions.map((explanation) => (
            <button
              key={explanation}
              onClick={() => onSelectExplanation(explanation)}
              className="flex items-center justify-center px-2 py-1 border border-gray-300 hover:bg-zinc-50 rounded-md text-gray-900 text-xs"
              css={[selectedExplanation === explanation ? tw`bg-zinc-100` : tw`bg-white`]}
            >
              {explanation}
            </button>
          ))}
          <button
            onClick={onToggleTextArea}
            className="flex items-center justify-center px-2 py-1 border border-gray-300 hover:bg-zinc-50 rounded-md text-gray-900 text-xs"
            css={[
              selectedExplanation && !explanationOptions.includes(selectedExplanation) ? tw`bg-zinc-100` : tw`bg-white`,
            ]}
          >
            Other...
          </button>
        </div>
      )}
    </div>
  );
};

export default FeedbackOptionsPanel;

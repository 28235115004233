/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useContentLibraryFilterItems, useNestedDriveMeta } from "./hooks";
import type { BaseFile } from "types/FileStorage";
import { Button } from "components/editor/components";
import { ArrowLeft, Search } from "lucide-react";
import FileRow from "./FileRow";
import FolderRow from "./FolderRow";
import { DropdownMenu } from "../dropdown-menu";
import Icon from "components/atoms/icons/Icon";
import { useDirectoryFileSearch } from "pages/drive/documents/hooks";
import { xor } from "lodash";
import { Skeleton } from "../skeleton";
import { LEGACY_DOCUMENT_TYPES } from "pages/drive/documents/constants";
import { getFolderFiles } from "utils/assistants/utils";

type Props = {
  handleSelectedFiles: (checked: boolean, files: BaseFile[]) => void;
  selectedFiles: string[];
  onClear: (files: BaseFile[]) => void;
  maxFileCount?: number | null;
};

const ContentDrivePopoverContent: React.FC<Props> = ({ handleSelectedFiles, selectedFiles, onClear, maxFileCount }) => {
  const {
    isSearching,
    searchResults,
    isSearchActive,
    setDocumentTypes,
    setSearchQuery,
    resetSearch,
    searchQuery,
    documentTypes,
    setUploaded,
    uploaded,
    setDirectoryId,
    folderSearchResults,
  } = useDirectoryFileSearch();

  const {
    docs,
    folders,
    nextNestedDriveState,
    currentNestedDriveState,
    setCurrentNestedDriveState,
    getCurrentNestedDriveState,
    isLoading: { current: isLoadingCurrentDirectory, next: isLoadingNestedDirectories },
  } = useNestedDriveMeta();

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setDirectoryId(currentNestedDriveState?.current_directory?.id || "");
  }, [currentNestedDriveState?.current_directory?.id, setDirectoryId]);

  const filteredDocs = useMemo(() => (isSearchActive ? searchResults : docs), [docs, isSearchActive, searchResults]);
  const filteredFolders = useMemo(
    () => (isSearchActive ? folderSearchResults : folders),
    [folders, isSearchActive, folderSearchResults],
  );
  const isEmpty = !filteredDocs.length && !filteredFolders.length;

  const onSelectAll = useCallback(() => {
    const folderFiles = getFolderFiles(filteredFolders);
    handleSelectedFiles(true, [...filteredDocs, ...folderFiles]);
  }, [filteredDocs, filteredFolders, handleSelectedFiles]);

  const handleClear = useCallback(() => {
    const folderFiles = getFolderFiles(filteredFolders);
    onClear([...filteredDocs, ...folderFiles]);
  }, [filteredDocs, filteredFolders, onClear]);

  const { typeItems, publishDateItemOptions } = useContentLibraryFilterItems({
    publishDatesConfig: { uploaded, onSelect: (val) => setUploaded(val === uploaded ? "" : val) },
    typesConfig: {
      documentTypes,
      onCheck: (val: string | string[]) => {
        if (Array.isArray(val)) {
          setDocumentTypes((prev) => xor(prev, val));
        } else {
          setDocumentTypes((prev) => xor(prev, [val]));
        }
      },
    },
  });

  return (
    <div className="min-h-[380px] max-w-[560px]">
      <div className="px-2 gap-1.5 flex items-center border-b border-b-gray-light">
        <Search size={14} className="text-gray-400" />
        <input
          autoFocus
          className="flex-1 outline-none py-3 text-sm text-gray-600"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search content library documents..."
        />
      </div>
      <div
        ref={containerRef}
        className="flex px-3 py-1 text-xs border-b border-gray-light justify-between items-center"
      >
        {currentNestedDriveState?.current_directory?.id ? (
          <Button
            variant="link"
            size="sm"
            className="group flex gap-1.5 items-center mr-4"
            onClick={() => {
              setCurrentNestedDriveState(undefined);
              if (isSearchActive) resetSearch();
            }}
          >
            <ArrowLeft size={16} className="duration-150 group-hover:-translate-x-1" />
            <div className="inline font-medium">Back to Main Library</div>
          </Button>
        ) : (
          <div className="text-gray-mid mr-2">Content Library</div>
        )}
        <div className="flex items-center gap-2">
          <div className="flex gap-1">
            <DropdownMenu portalProps={{ container: containerRef.current }} multiselect items={typeItems}>
              <div className="rounded min-w-[90px] text-xxs w-[60px] bg-white flex justify-between px-1.5 py-0.5 items-center gap-1 border border-gray-light text-slate-600">
                <div className="truncate">
                  {documentTypes.length
                    ? documentTypes.filter((document) => !LEGACY_DOCUMENT_TYPES.includes(document)).join(", ")
                    : "Type"}
                </div>
                <Icon name="CarrotDown" className="min-w-3" />
              </div>
            </DropdownMenu>
            <DropdownMenu portalProps={{ container: containerRef.current }} items={publishDateItemOptions}>
              <div className="rounded min-w-[80px] text-xxs w-[80px] bg-white flex justify-between px-1.5 py-0.5 items-center gap-1 border border-gray-light text-slate-600">
                <div className="truncate">{uploaded || "Uploaded"}</div>
                <Icon name="CarrotDown" className="min-w-3" />
              </div>
            </DropdownMenu>
          </div>
          <div className="flex items-center gap-2">
            <Button onClick={handleClear} variant="link" size="sm" className="!text-xxs mr-1 !text-red-500">
              Clear
            </Button>
            <Button onClick={onSelectAll} variant="link" size="sm" className="!text-xxs mr-1">
              Select All
            </Button>
          </div>
        </div>
      </div>
      <div className="text-stone-800 text-sm w-full h-full max-h-[296px] overflow-y-auto flex flex-col gap-1 p-1">
        {(isSearching || isLoadingCurrentDirectory) &&
          Array.from({ length: 6 }).map((_, i) => (
            <div key={i} className="flex h-8 items-center justify-between pr-3 pl-2">
              <div className="flex items-center gap-2">
                <Skeleton borderRadius={1} height={18} width={16} />
                <Skeleton borderRadius={2} height={20} width={170} />
              </div>
              <Skeleton borderRadius={4} height={18} width={18} />
            </div>
          ))}
        {!isSearching &&
          !isLoadingCurrentDirectory &&
          filteredFolders.map((folder) => (
            <FolderRow
              key={folder.id}
              setCurrentNestedDriveState={(id) => {
                const newCurrentDriveState = nextNestedDriveState?.[id];
                if (isSearchActive) {
                  getCurrentNestedDriveState(id);
                  resetSearch();
                } else {
                  setCurrentNestedDriveState(newCurrentDriveState);
                }
              }}
              folder={folder}
              selectedFiles={selectedFiles}
              handleSelectedFiles={handleSelectedFiles}
              isLoading={isLoadingNestedDirectories}
              shouldRemoveCheckbox={!!maxFileCount}
            />
          ))}
        {!isSearching &&
          !isLoadingCurrentDirectory &&
          filteredDocs.map((file) => {
            const isSelected = selectedFiles.includes(file.id);
            return (
              <FileRow
                key={file.id}
                file={file}
                handleSelectedFiles={handleSelectedFiles}
                isSelected={isSelected}
                disabled={!!maxFileCount && selectedFiles.length >= maxFileCount && !isSelected}
              />
            );
          })}
        {isEmpty && !isSearching && !isLoadingCurrentDirectory && (
          <div className="min-h-[240px] text-xs text-gray-400 w-full flex justify-center items-center">
            No documents found
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentDrivePopoverContent;

// useReadySessions.ts
import { useState, useCallback } from "react";

export function useReadySessions() {
  const [readySessions, setReadySessions] = useState<Set<string>>(new Set());

  const setReady = useCallback((sessionId: string, ready: boolean) => {
    setReadySessions((prev) => {
      const newSet = new Set(prev);
      if (ready) {
        newSet.add(sessionId);
      } else {
        newSet.delete(sessionId);
      }
      return newSet;
    });
  }, []);

  return { readySessions, setReady };
}

import ProgressLoader from "components/molecules/progress-loader";

type Props = {
  isCompleted: boolean;
  isUploadingFiles: boolean;
};

const FileUploadLoader = ({ isCompleted, isUploadingFiles }: Props) => {
  return (
    <div className="w-3/4">
      <ProgressLoader
        label={
          <div>
            {isCompleted ? (
              "Upload Complete!"
            ) : (
              <>
                Uploading
                <span className="loading-ellipsis" />
              </>
            )}
          </div>
        }
        isCompleted={isCompleted}
        isLoading={isUploadingFiles}
      />
    </div>
  );
};

export default FileUploadLoader;

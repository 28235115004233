import { useState } from "react";
import { clearDraft } from "../CommentsDraftPlugin";
import { useDraft } from "./useDraft";
import type { Editor, useEditor } from "@tiptap/react";
import { CommentEditor } from "./CommentEditor/CommentEditor";
import { useCommentOperations } from "../../../api/comments/useCommentOperations";
import { v4 } from "uuid";
import { ThreadContext } from "../types";
import { useMutation } from "YJSProvider/createYJSContext";
import { find } from "YJSProvider/LiveObjects";
import type { Storage } from "components/copilot/CopilotSchemaTypes";
import { X } from "lucide-react";
import { Button } from "components/yjs-editor/primitives/Button";
import Avatar from "components/molecules/avatar";
import { useAppSelector } from "store/storeTypes";
import { CommentCard } from "./CommentCard";
import { Checkbox } from "components/atoms/checkbox";
import { extractMentionedUserIds } from "components/Comments/utils";
import { getCurrentTab, getThreadNotificationBaseUrl } from "api/utils";

interface CommentDraftProps {
  editor: Editor;
  internalContractId: string;
  context: ThreadContext;
  referenceId?: string;
  requirementId?: string;
}

export const CommentDraft = ({
  editor,
  internalContractId,
  referenceId,
  context,
  requirementId,
}: CommentDraftProps) => {
  const draft = useDraft(editor);
  const [draftEditor, setDraftEditor] = useState<ReturnType<typeof useEditor> | null>(null);
  const [isDraftEmpty, setIsDraftEmpty] = useState(true);
  const [mentionedUser, setMentionedUser] = useState<{ id: string; username: string } | undefined>();
  const [isAssignMode, setIsAssignMode] = useState(false);

  const currentUser = useAppSelector((store) => store.auth.currentUser);
  const { createCommentMutation } = useCommentOperations(internalContractId, referenceId);
  const updateThreadIds = useMutation(({ storage }, threadId: string, requirementId: string) => {
    const requirements = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    if (!requirements) return;
    const liveRequirement = find(requirements, (row) => row.get("requirement")?.get("id") === requirementId);

    if (!liveRequirement) return;

    // keep threadIds in sync for a case when user deletes a comment from the doc but the thread still exists
    const existingThreadIds = liveRequirement?.get("thread_ids") || [];
    const updatedThreadIds = Array.from(new Set([...existingThreadIds, threadId]));
    liveRequirement?.set("thread_ids", updatedThreadIds);
  }, []);

  const handleComment = (content: string, mentions: string[]) => {
    if (!draft) return;
    const new_thread_id = v4();
    createCommentMutation.mutate({
      content,
      editor,
      new_thread_id,
      new_comment_id: v4(),
      quote_text: draft.quote_text,
      context,
      assignee_id: isAssignMode ? mentionedUser?.id : undefined,
      assignee_username: isAssignMode ? mentionedUser?.username : undefined,
      mentions,
      base_url: getThreadNotificationBaseUrl(),
      tab: getCurrentTab(),
      send_email: true,
    });
    if (draft.isEmpty && context === ThreadContext.DRAFT && requirementId) {
      updateThreadIds(new_thread_id, requirementId);
    }
    return true;
  };

  const handleSubmitComment = () => {
    if (draftEditor?.getHTML()) {
      // Extract mentioned user IDs from the document
      const mentions = draftEditor.state.doc ? extractMentionedUserIds(draftEditor.state.doc) : [];

      handleComment(draftEditor.getHTML(), mentions);
      draftEditor.commands.setContent("");
      setIsAssignMode(false);
      setMentionedUser(undefined);
    }
  };

  if (!draft) return null;

  return (
    <CommentCard>
      <button className="absolute top-2 right-2 p-1 hover:bg-gray-100 rounded" onClick={() => clearDraft(editor)}>
        <X size={14} color="#9CA3AF" />
      </button>
      <div className="flex items-center gap-2">
        <Avatar size={22} textColor="#FFFFFF" name={currentUser?.username} id={currentUser?.id} />
        <div className="text-gray-darkest font-medium text-sm">{currentUser?.username}</div>
      </div>
      <div className="border-[0.5px] border-[#D0D0D0] rounded-md p-1 truncate">
        <CommentEditor
          readonly={false}
          editorRef={setDraftEditor}
          autofocus
          onUpdate={setIsDraftEmpty}
          onAddComment={handleComment}
          onMentionChange={setMentionedUser}
        />
      </div>
      <div className="flex items-center w-full">
        {mentionedUser && (
          <div
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 cursor-pointer flex-grow min-w-0 mr-3"
            onClick={() => setIsAssignMode(!isAssignMode)}
          >
            <Checkbox size="sm" checked={isAssignMode} onCheck={setIsAssignMode} />
            <span className="truncate" title={`Assign to ${mentionedUser.username}`}>
              Assign to {mentionedUser.username}
            </span>
          </div>
        )}
        <Button variant="thread" onClick={handleSubmitComment} disabled={isDraftEmpty} className="ml-auto">
          {isAssignMode ? "Assign" : "Comment"}
        </Button>
      </div>
    </CommentCard>
  );
};

import type { PopoverProps } from "components/atoms/popover";
import Popover from "components/atoms/popover";
import Tooltip from "components/atoms/tooltip";
import { ALL_REVISE_WORKFLOW_OPTIONS, WORKFLOW_OPTIONS, WorkflowSub } from "pages/ai-assistant/constants";
import { ACTIVE_WORKFLOWS } from "pages/project-details/constants";
import type { PropsWithChildren, ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import type { AllowedProjectWorkflowSubTypes } from "types/Assistants/ProjectAssistant";
import ProjectWorkflowSelectionPopoverRow from "./ProjectWorkflowSelectionPopoverRow";
import { LoaderCircle, Sparkles } from "lucide-react";
import { setReviewEngineOpen } from "store/reducers/proposal/proposalReducer";
import { useAppDispatch } from "store/storeTypes";
import { CopilotPresencePage } from "types/Presence";
import { useSearchParams } from "react-router-dom";
import { useProjectWorkflowActions } from "pages/Workflows/useProjectWorkflowActions";
import { setHighlightedText } from "store/reducers/writing-assistant/writingAssistantReducer";

interface Props extends Partial<PopoverProps> {
  topSection?: ReactNode;
  bottomSection?: ReactNode;
}

const ProjectWorkflowSelectionPopover = ({
  topSection,
  bottomSection,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const isProposalPage = searchParams.get("tab") === CopilotPresencePage.Proposal && !!searchParams.get("docId");
  const allowedWorkflows = useMemo(
    () =>
      WORKFLOW_OPTIONS.filter(({ subType }) => ACTIVE_WORKFLOWS.includes(subType as AllowedProjectWorkflowSubTypes)),
    [],
  );
  const workflowOptions = useMemo(
    () => (expanded ? allowedWorkflows : allowedWorkflows.slice(0, 3)),
    [allowedWorkflows, expanded],
  );
  const recommendedWorkflows = useMemo(
    () =>
      allowedWorkflows.filter(
        ({ subType }) =>
          subType === WorkflowSub.Draft ||
          subType === WorkflowSub.ExtractOutline ||
          subType === WorkflowSub.ScoreRequirementResponse,
      ),
    [allowedWorkflows],
  );

  const [workflowLoading, setWorkflowLoading] = useState<WorkflowSub | undefined>();

  const { createNewWorkflow } = useProjectWorkflowActions();

  useEffect(() => {
    if (!open) setExpanded(false);
  }, [open]);

  return (
    <Tooltip content="Workflows">
      <Popover
        open={open}
        onOpenChange={setOpen}
        {...props}
        content={
          <div className="flex flex-col p-1 gap-1">
            {topSection && (
              <div className="flex flex-col gap-1">
                {topSection}
                <hr />
              </div>
            )}
            <div className="flex flex-col gap-2">
              <div className="pb-0 p-2 text-xs font-medium text-gray-600">Recommended Workflows</div>
              <div className="flex flex-col w-full">
                {recommendedWorkflows.map(({ name, icon, label, subType }) => {
                  const Icon = icon;
                  const isReviseWorkflow = ALL_REVISE_WORKFLOW_OPTIONS.some(
                    ({ subType: workflowSubType }) => subType === workflowSubType,
                  );

                  return (
                    <ProjectWorkflowSelectionPopoverRow
                      onClick={() => {
                        if (workflowLoading) return;
                        setWorkflowLoading(subType);
                        dispatch(setHighlightedText(""));
                        createNewWorkflow?.(
                          subType,
                          undefined,
                          isReviseWorkflow ? ALL_REVISE_WORKFLOW_OPTIONS : undefined,
                        ).finally(() => setWorkflowLoading(undefined));
                      }}
                      key={name}
                      aria-label={label}
                    >
                      {workflowLoading === subType ? (
                        <LoaderCircle className="animate-spin" size={16} />
                      ) : (
                        <Icon size={16} />
                      )}
                      <div className="text-sm text-gray-darkest truncate">{label}</div>
                    </ProjectWorkflowSelectionPopoverRow>
                  );
                })}
              </div>
              <hr />
              <div className="flex flex-col items-start">
                <div className="pb-0 p-2 text-xs font-medium text-gray-600">All Workflows</div>
                <div className="flex flex-col w-full">
                  {workflowOptions.map(({ name, icon, label, subType }) => {
                    const Icon = icon;
                    const isReviseWorkflow = ALL_REVISE_WORKFLOW_OPTIONS.some(
                      ({ subType: workflowSubType }) => subType === workflowSubType,
                    );
                    return (
                      <ProjectWorkflowSelectionPopoverRow
                        onClick={() => {
                          if (workflowLoading) return;
                          setWorkflowLoading(subType);
                          createNewWorkflow?.(
                            subType,
                            undefined,
                            isReviseWorkflow ? ALL_REVISE_WORKFLOW_OPTIONS : undefined,
                          ).finally(() => setWorkflowLoading(undefined));
                        }}
                        key={name}
                        aria-label={label}
                      >
                        {workflowLoading === subType ? (
                          <LoaderCircle className="animate-spin" size={16} />
                        ) : (
                          <Icon size={16} />
                        )}
                        <div className="text-sm text-gray-darkest truncate">{label}</div>
                      </ProjectWorkflowSelectionPopoverRow>
                    );
                  })}
                  {isProposalPage && (
                    <ProjectWorkflowSelectionPopoverRow
                      onClick={() => {
                        dispatch(setReviewEngineOpen(true));
                        setOpen(false);
                      }}
                      aria-label="Review Proposal"
                    >
                      <Sparkles size={14} />

                      <div className="text-sm text-gray-darkest truncate">Review Proposal</div>
                    </ProjectWorkflowSelectionPopoverRow>
                  )}
                </div>
                {!expanded && (
                  <button
                    onClick={() => setExpanded(true)}
                    className="p-2 pl-2.5 pt-1 text-gray-400 text-xs duration-100 transition-colors hover:text-gray-500"
                  >
                    View more
                  </button>
                )}
              </div>
            </div>
            {bottomSection && (
              <div className="flex flex-col gap-1">
                <hr />
                {bottomSection}
              </div>
            )}
          </div>
        }
      >
        {children}
      </Popover>
    </Tooltip>
  );
};

export default ProjectWorkflowSelectionPopover;

/** @jsxImportSource @emotion/react */

import Icon from "components/atoms/icons/Icon";
import DropdownMenu from "components/molecules/dropdown-menu";
import useCreateAtlasRequirement from "hook/Requirements/useCreateRequirement";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { useInsertRequirementRow } from "./hooks";
import { toggleRequirementExtractionModal } from "store/reducers/modalsSlice";
import tw from "twin.macro";
import { EMPTY_SHEET } from "const-values/Sheets";
import { useFlags } from "hook/useFlags";
import * as logger from "utils/log";

type Props = {
  isDisabled: boolean;
};

export default function AddNewButton({ isDisabled }: Props) {
  const activeSheet = useAppSelector((root) => root.requirements.activeSheet);
  const { createRequirement: createAtlasRequirement, isCreatingRequirement } = useCreateAtlasRequirement();
  const dispatch = useAppDispatch();
  const insertRequirementRow = useInsertRequirementRow();
  const flags = useFlags();
  const addRequirementRow = async () => {
    if (isDisabled || isCreatingRequirement) {
      return;
    }

    try {
      const atlasRequirement = await createAtlasRequirement({ content: "" });

      if (!atlasRequirement) {
        throw new Error(`Failed to create atlast requirement`);
      }

      const newReq = createComplianceMatrixRowRequirement({
        id: atlasRequirement.id,
        ...(activeSheet?.id !== EMPTY_SHEET.id && { extraction_id: activeSheet?.id }),
      });
      insertRequirementRow(0, { requirement: newReq });
    } catch (e) {
      logger.error(e as Error);
    }
  };

  const disabledStyle = tw`bg-gray-200 text-slate-400 hover:border-gray-200`;

  /**
   * If disableExtractionFromTextInRequirements is true, there would be only one entry in the
   * dropdown. To avoid that UX, we replace it with a single button instead
   */
  if (flags.disableExtractionFromTextInRequirements) {
    return (
      <button
        name="New Requirement"
        className="text-gray-darkest flex flex-grow items-center hover:border-gray-300 border border-gray-200 bg-gray-100 cursor-pointer font-medium text-sm px-2 py-1.5 rounded gap-2"
        css={[isDisabled && disabledStyle]}
        onClick={addRequirementRow}
      >
        <Icon name="Plus" className="w-4 h-4" />
        <span>New Requirement</span>
      </button>
    );
  }

  return (
    <DropdownMenu
      modal={false}
      triggerProps={{ className: "rounded" }}
      items={[
        {
          key: 1,
          label: "New Entry",
          disabled: isCreatingRequirement,
          onSelect: addRequirementRow,
        },
        {
          key: 2,
          label: "From Text",
          onSelect: () => dispatch(toggleRequirementExtractionModal({ open: true })),
        },
      ]}
      disabled={isDisabled}
    >
      <div
        className="text-gray-darkest flex flex-grow items-center hover:border-gray-300 border border-gray-200 bg-gray-100 cursor-pointer font-medium text-sm px-2 py-1.5 rounded gap-2"
        css={[isDisabled && tw`bg-gray-200 text-slate-400`]}
      >
        <Icon name="Plus" className="w-[15px] h-[15px]" /> Add
      </div>
    </DropdownMenu>
  );
}

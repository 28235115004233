import { useCallback } from "react";
import { useAppSelector } from "store/storeTypes";
import { ArrowRight } from "lucide-react";
import { useAssistant } from "../../AssistantContext";
import useWritingAssistantOperations from "hook/writing-assistant/useWritingAssistantOperations";
import { buildInputFieldFromActiveMetadata } from "utils/assistants/utils";

const ChecklistSubmitInput = () => {
  const activeSession = useAppSelector((state) => state.writingAssistant.activeSession);
  const activeMetadata = useAppSelector((state) => state.writingAssistant.activeMetadata);
  const { submitMessage } = useAssistant();
  const { updateWritingAssistantVultronBlock } = useWritingAssistantOperations();

  const handleSubmit = useCallback(() => {
    if (!activeSession || !activeMetadata) return;
    updateWritingAssistantVultronBlock(activeSession.id, {
      input_metadata: activeMetadata,
    });
    submitMessage({ input_field: buildInputFieldFromActiveMetadata(activeMetadata) });
  }, [activeMetadata, activeSession, submitMessage, updateWritingAssistantVultronBlock]);

  return (
    <>
      <div className="relative flex">
        <div className="flex flex-row justify-between items-center gap-1 w-full py-2">
          <button
            type="button"
            aria-label="Continue"
            disabled={!activeSession || !activeMetadata}
            onClick={handleSubmit}
            className="p-2 gap-2 flex items-center justify-center rounded-md font-medium text-gray-700 hover:text-gray-900 disabled:opacity-50"
          >
            <ArrowRight size={16} strokeWidth={2.5} />
            Continue
          </button>
        </div>
      </div>
      <div className="bg-gray-100 border-t text-sm border-gray-300 pr-3 pl-2 py-2">Select continue to proceed</div>
    </>
  );
};

export default ChecklistSubmitInput;

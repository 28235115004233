/** @jsxImportSource @emotion/react */
import { ExternalLink } from "lucide-react";
import { toggleDocumentViewerModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";

interface OpenButtonProps {
  download_url: string;
}

const OpenButton: React.FC<OpenButtonProps> = ({ download_url }) => {
  const dispatch = useAppDispatch();
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        dispatch(toggleDocumentViewerModal({ open: true, initialProps: { url: download_url } }));
      }}
      className="text-xs z-[1] select-none flex items-center gap-2 ml-auto absolute p-1.5 top-2 right-2 shadow-sharp-thin rounded transition-opacity opacity-0 bg-gray-300 text-slate-900 group-hover:opacity-100 hover:brightness-95 font-medium"
    >
      <span className="truncate">Open</span>
      <ExternalLink size={14} className="shrink-0" />
    </button>
  );
};

export default OpenButton;

/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import Input from "react-input-mask";
import { editForm, streamEnhance, toggleEnhanceLoading } from "store/reducers/newProjectReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { CalenderIcon } from "utils/icons";
import { useObserveSseController } from "hook/useObserveSseController";
import { useEnhanceDescription } from "./hooks";
import { EventStreamContentType } from "@microsoft/fetch-event-source";
import { useNotification } from "context/notificationContext";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { CREATE_NEW_PROJECT } from "const-values/testIds";
import { dateFormatter } from "utils/timerUtils";

const ENHANCE_LOADING_MSGS = ["Analyzing", "Enhancing"];

const EssentialDetails = () => {
  const [titleIsBlurredNoContent, setTitleIsBlurredNoContent] = useState(false);
  const [clientNameIsBlurredNoContent, setClientNameIsBlurredNoContent] = useState(false);
  const { setToast } = useNotification();
  const [, setEnhanceLoadingMsg] = useState(ENHANCE_LOADING_MSGS[0]);
  const {
    form: { title, response_date, client_name },
    isEnhanceLoading,
  } = useAppSelector((root) => root.newProject);
  const attachments = useAppSelector((root) => root.newProject.attachments);
  const dispatch = useAppDispatch();
  useAnimateLoadingMsg(isEnhanceLoading, 3500, ENHANCE_LOADING_MSGS, (msg) => setEnhanceLoadingMsg(msg));

  const { abortConnection } = useEnhanceDescription({
    onmessage(msg) {
      if (msg.data?.length) {
        dispatch(streamEnhance(msg.data));
      }
    },
    async onopen(response) {
      dispatch(editForm({ description: "" }));
      if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
        return; // everything's good
      } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
        setToast.error({
          title: "Unable to format description",
          msg: "We were unable to format the description due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
        dispatch(toggleEnhanceLoading(false));
        // client-side errors are usually non-retriable:
        // throw new FatalError();
      } else {
        // throw new RetriableError();
      }
    },
    onclose() {
      dispatch(toggleEnhanceLoading(false));
      // if the server closes the connection unexpectedly, retry:
      // throw new RetriableError();
    },
    onerror(err) {
      setToast.error({
        title: "Unable to format description",
        msg: "We were unable to format the description due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
      });
      dispatch(toggleEnhanceLoading(false));
      if (err instanceof Error) {
        throw err; // rethrow to stop the operation
      } else {
        // do nothing to automatically retry. You can also
        // return a specific retry interval here.
      }
    },
  });

  useObserveSseController(abortConnection, () => dispatch(toggleEnhanceLoading(false)));

  useEffect(() => {
    if (attachments.length) {
      setTitleIsBlurredNoContent(!title.trim());
      setClientNameIsBlurredNoContent(!client_name.trim());
    }
  }, [attachments, client_name, setClientNameIsBlurredNoContent, title]);

  return (
    <div className="pl-6">
      <div className="flex flex-col gap-6 pr-4 pt-5 pb-6">
        <div className="flex flex-col gap-2">
          <div className="font-medium text-sm">Project Title</div>
          <input
            onBlur={() => {
              if (!title.trim()) {
                setTitleIsBlurredNoContent(true);
              }
              dispatch(editForm({ title: title.trim() }));
            }}
            value={title}
            onChange={(e) => {
              if (e.target.value.trim()) {
                setTitleIsBlurredNoContent(false);
              }
              dispatch(editForm({ title: e.target.value }));
            }}
            className="text-sm border border-gray-light rounded-md px-3 py-2 outline-none"
            css={[titleIsBlurredNoContent && tw`border-red-500`]}
            placeholder="Ex: Joint Targeting System"
            required
            data-testid={CREATE_NEW_PROJECT.projectTitle}
          />
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col gap-6 flex-1">
            <div className="flex flex-col gap-2 pr-2">
              <div className="flex">
                <div className="font-medium text-sm">Client Name</div>
              </div>
              <input
                value={client_name}
                onBlur={() => {
                  if (!client_name.trim()) {
                    setClientNameIsBlurredNoContent(true);
                  }
                }}
                onChange={(e) => {
                  if (e.target.value.trim()) {
                    setClientNameIsBlurredNoContent(false);
                  }
                  dispatch(editForm({ client_name: e.target.value }));
                }}
                className="text-sm border border-gray-light rounded-md px-3 py-2 outline-none"
                css={[clientNameIsBlurredNoContent && tw`border-red-400`]}
                placeholder="Ex: Department of Defense"
                required
                data-testid={CREATE_NEW_PROJECT.clientName}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <div className="font-medium text-sm">Deadline</div>
            <DatePicker
              arrow={false}
              calendarPosition="top-end"
              format="MM/DD/YYYY"
              value={response_date || new Date()}
              onChange={(date) => {
                const dateValue = date?.valueOf();
                if (typeof dateValue === "number" && !Number.isNaN(dateValue) && dateValue > 0) {
                  dispatch(editForm({ response_date: new Date(dateValue)?.toISOString() }));
                } else dispatch(editForm({ response_date: undefined }));
              }}
              containerClassName="!w-full !max-w-full"
              render={(value, openCalendar, handleValueChange) => {
                return (
                  <div
                    className="relative text-sm px-3 py-2 pb-2.5"
                    onClick={openCalendar}
                    css={[!value && tw`text-gray-400`]}
                  >
                    <div className="absolute z-[1] inset-y-0 right-3 flex items-center cursor-pointer select-none">
                      <CalenderIcon />
                    </div>
                    <Input
                      placeholder={dateFormatter(new Date(), "MM/DD/YYYY")}
                      className="absolute pl-2 right-0 top-0 bottom-0 left-0 border outline-none border-gray-light rounded-md"
                      mask="99/99/9999"
                      maskChar="-"
                      onFocus={openCalendar}
                      onChange={handleValueChange}
                      value={value}
                    />
                    {dateFormatter(response_date || new Date(), "MM/DD/YYYY")}
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EssentialDetails;

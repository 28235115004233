import { useCallback } from "react";
import type { TaskDetail } from "types/Assistants/types";

export const useIsSessionInTasks = (uploadChatDocumentTasks: TaskDetail[] | null, sessionId?: string) => {
  const isSessionInTasks = useCallback(() => {
    if (!sessionId || !uploadChatDocumentTasks) return false;
    return uploadChatDocumentTasks.some((task) => task.chat_session_id === sessionId && task.status !== "Failed");
  }, [sessionId, uploadChatDocumentTasks]);

  return isSessionInTasks();
};

/** @jsxImportSource @emotion/react */
import Tooltip from "components/atoms/tooltip/Tooltip";
import { Info } from "lucide-react";
import { secondsSinceUploadMessage } from "./utils";

interface DocumentPreviewProps {
  createdAt: string;
  doc: string;
  indexed?: boolean;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ createdAt, doc, indexed }) => {
  return (
    <figure className="relative overflow-hidden w-full h-48 bg-[#DEE1E3] p-5 cursor-pointer">
      <div className="relative w-full h-full flex justify-center items-center">
        <img src={doc} alt="Doc Preview" loading="lazy" className="w-auto max-w-full pointer-events-none" />
      </div>
      {!indexed && !!createdAt && (
        <div className="absolute bottom-12 left-0 right-0 bg-black bg-opacity-80 h-9 flex items-center justify-between rounded-t-md p-2.5">
          <div className="flex items-center gap-2">
            <div className="relative">
              <div className="rounded-full w-2 h-2 bg-white" />
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="rounded-full w-1.5 h-1.5 bg-white animate-ping" />
              </div>
            </div>
            <span className="text-white text-xs">{secondsSinceUploadMessage(createdAt)}</span>
          </div>
          <Tooltip content="This may take up to 45 minutes, depending on the size. The document will be available for use once processing is complete.">
            <Info className="text-neutral-300" size={12} />
          </Tooltip>
        </div>
      )}
      <div className="iframe-overlay absolute h-full w-full cursor-pointer bg-transparent"></div>
    </figure>
  );
};

export default DocumentPreview;

import { useAppSelector } from "store/storeTypes";
import { memo } from "react";
import RecentFilesModal from "components/organisms/global-assistant/modals/recent-files-modal/RecentFilesModal";

const AIAssistantModals = () => {
  const { recentFilesModal } = useAppSelector((state) => state.modals);

  return <RecentFilesModal {...recentFilesModal} />;
};

export default memo(AIAssistantModals);

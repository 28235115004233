import { useEffect, useState } from "react";
import type { Editor } from "@tiptap/react";
import { LinkIcon } from "../icons";
import { Button } from "../primitives/Button";
import { EditorLinkModal } from "components/editor/components/EditorLinkModal";
import styles from "./Toolbar.module.css";
import { ToolbarTooltip } from "../primitives/Tooltip";
import * as logger from "utils/log";

export const ToolbarLink = ({ editor }: { editor: Editor }) => {
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [isLinkActive, setIsLinkActive] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);

  useEffect(() => {
    if (!editor) return;

    const updateState = () => {
      setIsLinkActive(editor.isActive("a"));
      setHasSelection(!editor.state.selection.empty);
    };

    updateState();

    editor.on("selectionUpdate", updateState);

    return () => {
      editor.off("selectionUpdate", updateState);
    };
  }, [editor]);

  const handleLinkSubmit = (input: string) => {
    if (!input) {
      editor.chain().focus().unsetLink().run();
      return;
    }

    try {
      // Use the URL constructor to normalize and validate the URL.
      const { href } = new URL(input.startsWith("http") ? input : `https://${input}`);
      editor.chain().focus().setLink({ href }).run();
    } catch (error) {
      logger.error(error as Error, "Invalid URL");
    }
  };

  return (
    <>
      <ToolbarTooltip content="Insert link">
        <Button
          variant="subtle"
          className={styles.toolbarButton}
          onClick={() => setShowLinkModal(true)}
          disabled={!hasSelection}
          data-active={isLinkActive ? "is-active" : undefined}
          aria-label="Add link"
        >
          <LinkIcon />
        </Button>
      </ToolbarTooltip>
      <EditorLinkModal
        showLinkModal={showLinkModal}
        setShowLinkModal={setShowLinkModal}
        initialUrl={editor.getAttributes("a").href || "https://"}
        onSubmit={handleLinkSubmit}
      />
    </>
  );
};

/** @jsxImportSource @emotion/react */
import type { ChangeEvent, ReactNode } from "react";
import React, { useRef } from "react";
import tw from "twin.macro";

interface FileUploadButtonProps {
  onFileUpload: (files: FileList | null) => void;
  accept?: string;
  labelClassName?: string;
  children: ReactNode;
  disabled?: boolean;
}

const FileSelector: React.FC<FileUploadButtonProps> = ({
  onFileUpload,
  accept = ".pdf,.doc,.docx,.txt",
  labelClassName,
  children,
  disabled,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onFileUpload) {
      onFileUpload(event.target.files);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <label
      className={labelClassName}
      css={[disabled ? tw`text-gray-400 cursor-default` : tw`text-gray-700 hover:text-black hover:bg-gray-200`]}
    >
      <input
        ref={fileInputRef}
        type="file"
        multiple
        accept={accept}
        className="hidden"
        onChange={handleFileChange}
        disabled={disabled}
      />
      {children}
    </label>
  );
};

export default FileSelector;

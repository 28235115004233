import { LiveObject } from "YJSProvider/LiveObjects";
import type { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { nanoid } from "nanoid";

export const createSheet = (properties: Partial<Sheet> & Pick<Sheet, "name">, newID = nanoid()): LiveObject<Sheet> => {
  return new LiveObject({
    id: newID,
    ...properties,
  });
};

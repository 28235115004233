import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from "./FallbackComponent";
import * as logger from "utils/log";

const ErrorBoundaryWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error, info) => {
        const eventId = logger.fatal(error, { componentStack: info?.componentStack });

        if (eventId) {
          logger.showReportDialog(eventId);
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;

export function secondsSinceUploadMessage(timestamp: string): string {
  const pastTime = new Date(timestamp).getTime();
  const nowTime = Date.now();
  const elapsed = Math.floor((nowTime - pastTime) / 1000);

  if (elapsed <= 5) {
    return "Processing Document...";
  } else if (elapsed <= 13) {
    return "Uploading Document...";
  } else if (elapsed <= 25) {
    return "Scanning Document...";
  } else if (elapsed <= 41) {
    return "Processing Document...";
  } else if (elapsed <= 101) {
    return "Extracting Key Information...";
  } else if (elapsed <= 281) {
    return "Learning...";
  } else if (elapsed <= 461) {
    return "Indexing Learnings...";
  } else if (elapsed <= 581) {
    return "Enhancing Metadata...";
  } else if (elapsed <= 701) {
    return "Confirming Data Quality...";
  } else if (elapsed <= 941) {
    return "Refreshing Knowledge Base...";
  } else {
    return "Updating Knowledge Base...";
  }
}

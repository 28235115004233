/** @jsxImportSource @emotion/react */

import { ReactComponent as VultronLockup } from "Assets/svgs/vultron-avatar.svg";
import { ReactComponent as VultronLogoBlack } from "Assets/svgs/vultron-logo-black.svg";
import type { HTMLAttributes } from "react";
import tw from "twin.macro";

interface Props extends HTMLAttributes<HTMLDivElement> {
  size: number;
  isSquare?: boolean;
}

const VultronAvatar = ({ size, isSquare = false, ...props }: Props) => (
  <div
    className="flex items-center justify-center "
    css={[isSquare ? tw`bg-gray-100 rounded-md p-[3px]` : tw`rounded-full p-[5px] bg-gray-darkest`]}
    style={{
      width: size + "px",
      minWidth: size + "px",
      maxWidth: size + "px",
      height: size + "px",
      minHeight: size + "px",
      maxHeight: size + "px",
    }}
    {...props}
  >
    {isSquare ? <VultronLogoBlack /> : <VultronLockup className="w-full h-full overflow-visible" />}
  </div>
);

export default VultronAvatar;

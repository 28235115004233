/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Archive, Ellipsis, LoaderCircle } from "lucide-react";
import Tooltip from "components/atoms/tooltip";
import type { FC } from "react";

const RowOptions: FC<{
  isActive: boolean;
  isCurrentSessionInTasks: boolean;
  isReady: boolean;
  optionsOpen: boolean;
  setOptionsOpen: (open: boolean) => void;
  actionItems: any[];
  archiveSession: (sessionId: string) => void;
  sessionId: string;
}> = ({
  isActive,
  isCurrentSessionInTasks,
  isReady,
  optionsOpen,
  setOptionsOpen,
  actionItems,
  archiveSession,
  sessionId,
}) => {
  return (
    <div className="flex gap-1">
      {isCurrentSessionInTasks && !isReady && (
        <div className="flex absolute inset-y-0 right-0 pl-1 pr-3 items-center group-hover:hidden">
          <LoaderCircle className="animate-spin" size={16} />
        </div>
      )}
      {isReady && (
        <div className="flex absolute inset-y-0 right-0 pl-1 pr-3 items-center group-hover:hidden">
          <div className="rounded-full w-2.5 h-2.5 bg-blue-500" />
        </div>
      )}
      <div
        className="opacity-0 text-slate-700 flex items-center gap-1 absolute bottom-0 right-0 top-0 pl-1 pr-3 bg-layout-gray-light-hover group-hover:opacity-100"
        css={[isActive && tw`!bg-layout-gray-light-active`, optionsOpen && tw`opacity-100`]}
      >
        <DropdownMenu
          open={optionsOpen}
          onOpenChange={setOptionsOpen}
          contentProps={{ align: "start", side: "bottom" }}
          items={actionItems}
          triggerProps={{ css: tw`duration-150 hover:opacity-50` }}
        >
          <div>
            <Ellipsis size={18} />
          </div>
        </DropdownMenu>
        <Tooltip content="Archive">
          <button onClick={() => archiveSession(sessionId)} className="duration-150 hover:opacity-50">
            <Archive size={18} />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default RowOptions;

import moment from "moment";
import type { HTMLAttributes } from "react";
import { useEffect, useState } from "react";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "just now",
    ss: "%d seconds ago",
    m: "1 minute ago",
    mm: "%d minutes ago",
    h: "1 hour ago",
    hh: "%d hours ago",
    d: "a day ago",
    dd: "%d days ago",
    M: "a month ago",
    MM: "%d months ago",
    y: "a year ago",
    yy: "%d years ago",
  },
});

interface Props extends HTMLAttributes<HTMLDivElement> {
  date: string;
  duration?: number;
}

const LiveDate = ({ date, duration, ...props }: Props) => {
  const [liveDate, setLiveDate] = useState(moment(date).fromNow(true));

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    intervalId = setInterval(
      () =>
        setLiveDate(() => {
          return moment(date).fromNow(true);
        }),
      duration,
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [date, duration]);

  return (
    <div className="text-xs text-slate-500 ml-4" {...props}>
      {liveDate}
    </div>
  );
};

export default LiveDate;

/** @jsxImportSource @emotion/react */

import { useMemo } from "react";

import "twin.macro";
import { DropdownMenu } from "../dropdown-menu";
import { styles } from "../dropdown-menu/styles";
import Icon from "components/atoms/icons/Icon";
import type { ComplianceMatrixRow, Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { useNotification } from "context/notificationContext";
import { useGenerateRequirementHeading } from "hook/draft/useGenerateRequirementHeading";
import { useBuildRequirementLink } from "hook/Requirements/useBuildRequirementLink";
import useRequirementOperations from "hook/useRequirementOperations";
import { ChevronRight, Eye, EyeOff, LayoutGrid, Link, ListPlus, Trash2 } from "lucide-react";
import { useFlags } from "hook/useFlags";
import type { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/types";
import type { PropsWithChildren } from "react";
import { toggleRequirementDetails } from "store/reducers/copilot/copilotDrawerReducer";
import { setActiveRequirementId, updateCheckedState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch } from "store/storeTypes";
import copyText from "utils/copyText";
import { useTrackUserMetric } from "utils/metrics";
import { useStorage } from "YJSProvider/createYJSContext";

const extendedStyles = {
  item: `${styles.item} text-xs`,
};

export type RequirementsMenubarPopoverProps = {
  sections: FormattedSection[];
  complianceMatrixRow: ComplianceMatrixRow;
  disabled?: boolean;
  enableDelete?: boolean;
};

const RequirementOptionsDropdown = ({
  sections,
  children,
  complianceMatrixRow,
  enableDelete,
}: PropsWithChildren<RequirementsMenubarPopoverProps>) => {
  const { setToast } = useNotification();
  const sheets = useStorage((storage) => storage.sheets || []);
  const { requirement, proposal_reference, written_content } = complianceMatrixRow;
  const { generateRequirementHeading } = useGenerateRequirementHeading();
  const { deleteRequirementRow, assignToSection, disregardRequirement, setSheet } = useRequirementOperations();
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const copyLink = useBuildRequirementLink(proposal_reference.volume_id, proposal_reference.section_id, requirement.id);

  const isAssigned = !!complianceMatrixRow.proposal_reference.section_id;
  const isDisregarded = !!complianceMatrixRow.requirement.disregarded;
  const trackUserEvent = useTrackUserMetric();

  const items = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2 text-xs">
            <LayoutGrid size={16} />
            Details
          </div>
        ),
        onSelect: () => {
          dispatch(setActiveRequirementId(requirement.id));
          dispatch(toggleRequirementDetails(true));
        },
      },
      ...(isAssigned && !flags.enableNewProjectFlow
        ? [
            {
              key: 2,
              label: (
                <div className="flex items-center gap-2 text-xs">
                  <Link size={16} className="w-4" /> Copy link
                </div>
              ),
              onSelect: () => {
                copyText(copyLink.link, () =>
                  setToast.success({
                    msg: "Copied",
                  }),
                );
                trackUserEvent("Requirements: Requirement Link Copied", {
                  requirement_id: String(requirement.id),
                });
              },
            },
          ]
        : []),
      ...(isAssigned
        ? [
            {
              key: 3,
              label: (
                <div className="flex items-center gap-2 text-xs">
                  <Icon name="NoteRemove" tw="w-4" />
                  Unassign
                </div>
              ),
              onSelect: () => {
                assignToSection(requirement.id, null);
                dispatch(updateCheckedState({ [requirement.id]: false }));
              },
            },
          ]
        : []),
      {
        key: 4,
        label: (
          <div className="flex items-center gap-2 text-xs">
            {!isDisregarded ? <EyeOff size={14} className="w-4" /> : <Eye size={14} className="w-4" />}
            {isDisregarded ? "Undo disregard" : "Disregard"}
          </div>
        ),
        onSelect: () => {
          disregardRequirement(requirement.id, isDisregarded);
          if (!isDisregarded) dispatch(updateCheckedState({ [requirement.id]: false }));
        },
      },

      ...(!!sections.length && !isDisregarded
        ? [
            {
              key: 5,
              label: "",
              subs: !!sections.length && !isDisregarded && (
                <DropdownMenu.Sub key={5}>
                  <DropdownMenu.SubTrigger className={extendedStyles.item}>
                    <div className="flex items-center gap-2 text-xs">
                      <Icon name="Swap" tw="w-4" />
                      {isAssigned ? "Move to" : "Assign to"}
                    </div>
                    <div className={styles.chevronRight}>
                      <ChevronRight size={14} />
                    </div>
                  </DropdownMenu.SubTrigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.SubContent className={styles.content} sideOffset={8}>
                      {sections.map((section) =>
                        section.subsections?.length ? (
                          <DropdownMenu.Sub key={section.id}>
                            <DropdownMenu.SubTrigger className={extendedStyles.item}>
                              <DropdownMenu.Item
                                className="flex items-center w-full justify-between"
                                onPointerLeave={(event) => event.preventDefault()}
                                onPointerMove={(event) => event.preventDefault()}
                                onSelect={() => {
                                  assignToSection(requirement.id, section.id);
                                  if (written_content || requirement?.content || requirement?.summarized_content) {
                                    generateRequirementHeading({
                                      requirement_ids: [requirement.id],
                                    });
                                  }
                                  dispatch(updateCheckedState({ [requirement.id]: false }));
                                }}
                              >
                                {section.title}
                                <div className={styles.chevronRight}>
                                  <ChevronRight size={14} />
                                </div>
                              </DropdownMenu.Item>
                            </DropdownMenu.SubTrigger>
                            <DropdownMenu.Portal>
                              <DropdownMenu.SubContent className={styles.content} sideOffset={8}>
                                {section.subsections?.map(
                                  (subsection) =>
                                    complianceMatrixRow.proposal_reference.section_id !== subsection.id && (
                                      <DropdownMenu.Item
                                        key={subsection.id}
                                        className={extendedStyles.item}
                                        onSelect={() => {
                                          assignToSection(requirement.id, subsection.id);
                                          dispatch(
                                            updateCheckedState({
                                              [requirement.id]: false,
                                            }),
                                          );
                                        }}
                                      >
                                        {subsection.title || <span className="text-slate-400">Subsection name...</span>}
                                      </DropdownMenu.Item>
                                    ),
                                )}
                              </DropdownMenu.SubContent>
                            </DropdownMenu.Portal>
                          </DropdownMenu.Sub>
                        ) : (
                          <DropdownMenu.Item
                            key={section.id}
                            className={extendedStyles.item}
                            onSelect={() => {
                              assignToSection(requirement.id, section.id);
                              dispatch(updateCheckedState({ [requirement.id]: false }));
                            }}
                          >
                            {section.title || <span className="text-slate-400">Section name...</span>}
                          </DropdownMenu.Item>
                        ),
                      )}
                    </DropdownMenu.SubContent>
                  </DropdownMenu.Portal>
                </DropdownMenu.Sub>
              ),
              onSelect: () => {},
            },
          ]
        : []),
      ...(sheets.length
        ? [
            {
              key: 6,
              label: "",
              subs: !!sheets.length && !isDisregarded && (
                <DropdownMenu.Sub key={6}>
                  <DropdownMenu.SubTrigger className={extendedStyles.item}>
                    <div className="flex items-center gap-2 text-xs">
                      <ListPlus size={16} />
                      {requirement?.extraction_id?.length ? "Change sheet" : "Add to sheet"}
                    </div>
                    <div className="ml-auto pl-3">
                      <ChevronRight size={14} />
                    </div>
                  </DropdownMenu.SubTrigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.SubContent className={styles.content} sideOffset={8}>
                      {sheets.map((sheet: Sheet) => (
                        <DropdownMenu.Sub key={sheet.id}>
                          <DropdownMenu.SubTrigger className={extendedStyles.item}>
                            <DropdownMenu.Item
                              className="flex items-center w-full justify-between"
                              onPointerLeave={(event) => event.preventDefault()}
                              onPointerMove={(event) => event.preventDefault()}
                              onSelect={() => {
                                setSheet(requirement.id, sheet.id);
                              }}
                            >
                              {sheet.name}
                            </DropdownMenu.Item>
                          </DropdownMenu.SubTrigger>
                        </DropdownMenu.Sub>
                      ))}
                    </DropdownMenu.SubContent>
                  </DropdownMenu.Portal>
                </DropdownMenu.Sub>
              ),
              onSelect: () => {},
            },
          ]
        : []),
      ...(enableDelete
        ? [
            {
              key: 7,
              label: (
                <div className="flex gap-2 text-xs items-center text-red-500">
                  <Trash2 size={16} /> Delete
                </div>
              ),
              onSelect: () => deleteRequirementRow(requirement.id),
            },
          ]
        : []),
    ],
    [
      assignToSection,
      complianceMatrixRow.proposal_reference.section_id,
      copyLink.link,
      deleteRequirementRow,
      dispatch,
      disregardRequirement,
      enableDelete,
      flags.enableNewProjectFlow,
      generateRequirementHeading,
      isAssigned,
      isDisregarded,
      requirement?.content,
      requirement?.extraction_id?.length,
      requirement.id,
      requirement?.summarized_content,
      sections,
      setSheet,
      setToast,
      sheets,
      trackUserEvent,
      written_content,
    ],
  );

  return (
    <DropdownMenu modal items={items}>
      {children}
    </DropdownMenu>
  );
};

export default RequirementOptionsDropdown;

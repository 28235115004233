import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import useGetActiveWritingAssistantUser from "hook/writing-assistant/useGetActiveWritingAssistantUser";
import { useMemo } from "react";
import { groupYjsSessionsByCategory } from "utils/assistants/utils";

export const useAssistantSessionState = () => {
  const writingAssistantUser = useGetActiveWritingAssistantUser();
  const archivedSessions = useMemo(
    () => writingAssistantUser?.archived_sessions || [],
    [writingAssistantUser?.archived_sessions],
  );
  const sessions = useMemo(() => writingAssistantUser?.sessions || [], [writingAssistantUser?.sessions]);

  const sortedSessions = useMemo(
    () =>
      [...sessions].sort((a, b) => {
        if (a.updated_at && b.updated_at) {
          return -a.updated_at.localeCompare(b.updated_at);
        }
        return 0;
      }),
    [sessions],
  );

  const groupedSessions = useMemo(
    () => groupYjsSessionsByCategory<WritingAssistantSession>(sortedSessions),
    [sortedSessions],
  );
  const sessionEntries = useMemo(() => Array.from(groupedSessions.entries()), [groupedSessions]);

  return { archivedSessions, sessionEntries };
};

import type { Editor } from "@tiptap/react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenMediaModal } from "store/reducers/yjs-editor-reducer";
import { ImageIcon } from "../icons/Image";
import { Button } from "../primitives/Button";
import InsertImageModal from "./ContentLibrary/modals/InsertImageModal";
import styles from "./Toolbar.module.css";
import { ToolbarTooltip } from "../primitives/Tooltip";

export const ToolbarMedia = ({ editor }: { editor: Editor }) => {
  const dispatch = useDispatch();
  const { openMediaModal } = useSelector((state: any) => state.yjsEditor);

  return (
    <>
      <ToolbarTooltip content="Insert image">
        <Button
          className={styles.toolbarButton}
          variant="subtle"
          onClick={() => dispatch(setOpenMediaModal(true))}
          aria-label="Image"
        >
          <ImageIcon className="text-gray-500" />
        </Button>
      </ToolbarTooltip>
      <InsertImageModal editor={editor} open={openMediaModal} onOpenChange={() => dispatch(setOpenMediaModal(false))} />
    </>
  );
};

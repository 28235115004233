import type { WorkflowInstructions } from "types/Assistants/types";
import tryParseJSON from "utils/tryParseJSON";

export function extractWorkflowData(input: string): WorkflowInstructions | undefined {
  const jsonMatch = input.match(/\{[\s\S]*\}$/);
  if (jsonMatch) {
    const jsonString = jsonMatch[0];
    const jsonParsed = tryParseJSON(jsonString);
    if (typeof jsonParsed === "object" && jsonParsed !== null && "type" in jsonParsed) {
      return jsonParsed as WorkflowInstructions;
    }
  }
}

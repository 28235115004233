import { ArrowUp } from "lucide-react";
import { useAssistant } from "../ai-assistant-input/hooks";
import Tooltip from "components/atoms/tooltip";

interface SubmitButtonProps {
  submitDisabled?: boolean;
  tooltipMessage?: string;
}

const SubmitButton = ({ submitDisabled = false, tooltipMessage }: SubmitButtonProps) => {
  const { submitMessage } = useAssistant();

  return (
    <Tooltip content={tooltipMessage} disabled={!tooltipMessage}>
      <button
        className="absolute bottom-2 right-2 bg-black text-sm flex items-center justify-center text-white w-[32px] min-w-[32px] h-[32px] min-h-[32px] duration-150 rounded-full disabled:bg-gray-200 disabled:text-slate-400 disabled:cursor-default hover:bg-slate-700"
        disabled={submitDisabled}
        onClick={() => submitMessage()}
      >
        <ArrowUp size={18} />
      </button>
    </Tooltip>
  );
};

export default SubmitButton;

// Using variable from process.env instead of vultronConfig to ensure no external dependencies. We want to ensure this
// runs first before any app code.
const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;

const intercomStub: Window["Intercom"] = function (...args) {
  intercomStub.q?.push(args);
};
intercomStub.q = [];

function initializeIntercom() {
  if (!intercomAppId) {
    console.warn("Intercom REACT_APP_INTERCOM_APP_ID is missing.");
    window.Intercom = intercomStub;
    return;
  }

  const { Intercom, intercomSettings } = window;

  if (typeof Intercom === "function") {
    Intercom("reattach_activator");
    Intercom("update", intercomSettings);
  } else {
    window.Intercom = intercomStub;

    const loadIntercomScript = () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = `https://widget.intercom.io/widget/${intercomAppId}`;
      document.head.appendChild(script);
    };

    window.addEventListener("load", loadIntercomScript);
  }
}

initializeIntercom();

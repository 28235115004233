import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import type { CaptureSchema } from "types/Capture";
import { CaptureSchemaType } from "types/Capture";

export const SCHEMAS_QUERY_KEY = "capture/schemas";

const useGetSchemas = (options?: Partial<UseQueryOptions<CaptureSchema[], Error>>) => {
  const { data = [], ...rest } = useQuery<CaptureSchema[], Error>({
    queryKey: [SCHEMAS_QUERY_KEY],
    queryFn: () =>
      axios
        .get<CaptureSchema[]>("/forms/schema")
        .then((resp) => resp.data.filter((schema) => schema.type !== CaptureSchemaType.PastPerformance)),
    staleTime: 1 * 60 * 1000,
    ...options,
  });

  return {
    data,
    ...rest,
  };
};

export default useGetSchemas;

/** @jsxImportSource @emotion/react */

import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import { useDrag } from "./hooks";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SectionNavRow from "./SectionNavRow";
import SortableItem from "./SortableItem";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import type { Section } from "components/copilot/CopilotSchemaImmutableTypes";

type Props = {
  sections?: Section[];
};

const SubsectionsGroup = ({ sections }: Props) => {
  const { volumeId } = useParams();
  const { handleDragCancel, handleDragEnd, handleDragStart, sensors, activeDragId } = useDrag();

  const activeSection = useMemo(
    () => sections?.find((section) => section.id === activeDragId) || {},
    [activeDragId, sections],
  );

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={(event) => handleDragEnd(event, volumeId)}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
    >
      <SortableContext id="TEMPLATE_SUBSECTIONS" items={sections || []} strategy={verticalListSortingStrategy}>
        {sections?.map((section) => <SortableItem key={section.id} id={section.id} section={section} />)}
        <DragOverlay style={{ transformOrigin: "0 0 " }}>
          {!!activeDragId && <SectionNavRow section={activeSection} isDragging />}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
};

export default SubsectionsGroup;

import { ThumbsUp, ThumbsDown } from "lucide-react";
import type { ResponseOption } from "./ResponseFeedback";
import Tooltip from "components/atoms/tooltip";

interface FeedbackButtonProps {
  onClick: () => void;
  isActive: boolean;
  Icon: React.ElementType;
  content: string;
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({ onClick, isActive, Icon, content }) => {
  return (
    <Tooltip content={content}>
      <button onClick={onClick} className="flex items-center justify-center">
        <Icon size={14} className="duration-150 text-gray-500" fill={isActive ? "#d1d5db" : "none"} />
      </button>
    </Tooltip>
  );
};

interface FeedbackButtonsProps {
  response: ResponseOption;
  onPositiveClick: () => void;
  onNegativeClick: () => void;
}

const FeedbackButtons: React.FC<FeedbackButtonsProps> = ({ response, onPositiveClick, onNegativeClick }) => {
  return (
    <div className="flex items-center justify-end gap-1.5">
      <FeedbackButton
        onClick={onPositiveClick}
        isActive={response === "positive"}
        Icon={ThumbsUp}
        content={"Good Response"}
      />
      <FeedbackButton
        onClick={onNegativeClick}
        isActive={response === "negative"}
        Icon={ThumbsDown}
        content={"Bad Response"}
      />
    </div>
  );
};

export default FeedbackButtons;

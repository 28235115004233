/** @jsxImportSource @emotion/react */

import type { TooltipProps, TooltipContentProps, PortalProps } from "@radix-ui/react-tooltip";
import { Root, Trigger, Portal, Content } from "@radix-ui/react-tooltip";
import type { PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "react";
import "twin.macro";
import styles from "./tooltip.module.css";

interface Props extends TooltipProps {
  content: ReactNode;
  contentProps?: TooltipContentProps;
  portalProps?: PortalProps;
  disabled?: boolean;
}
const Tooltip = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, content, contentProps, portalProps, disabled, ...props }, ref) => (
    <Root delayDuration={350} {...props}>
      <Trigger asChild>{children}</Trigger>
      {!disabled && (
        <Portal {...portalProps}>
          <Content
            ref={ref}
            className={`z-[999] text-xs break-words max-w-[300px] px-3 py-2 mx-2 shadow rounded-md font-medium bg-gray-950 text-white border border-gray-800 ${styles.tooltip}`}
            sideOffset={5}
            style={{ animation: "0.15s ease-in-out" }}
            {...contentProps}
          >
            {content}
          </Content>
        </Portal>
      )}
    </Root>
  ),
);

Tooltip.displayName = "Tooltip";

export default Tooltip;

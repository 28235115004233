/** @jsxImportSource @emotion/react */

import { useEffect, useMemo } from "react";
import EditorToggler from "./EditorToggler";
import { CopilotPresencePage } from "types/Presence";
import { useSearchParams } from "react-router-dom";
import Icon from "components/atoms/icons/Icon";
import { useFlags } from "hook/useFlags";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import ProposalActions from "./ProposalActions";
import { resetProposalState } from "store/reducers/proposal/proposalReducer";
import { ExportButton } from "components/yjs-editor/components/ExportButton";
import { CommentsPanelButton } from "components/yjs-editor/components/CommentsPanelButton";
import { useSyncPanels } from "./hooks";

type Props = {
  fullscreen: boolean;
  setForceRefresh: (newValue: boolean) => unknown;
  setDocId: (docId: string) => unknown;
  proposalDocId: string;
};

const Proposal = ({ fullscreen, setForceRefresh, setDocId, proposalDocId }: Props) => {
  const flags = useFlags();
  const [searchParams] = useSearchParams();
  const nestedTab = searchParams.get("tab")?.toLocaleLowerCase();
  const referenceId = searchParams.get("docId")?.toLocaleLowerCase();
  const fullScreen = useAppSelector((state) => state.aiReducer.isExpanded);
  const proposal_review_tasks = useAppSelector((state) => state.autopilotHealthCheck.proposal_review_tasks);
  const reviewsInProgress = useAppSelector((state) => state.proposal.reviewsInProgress);
  const dispatch = useAppDispatch();
  useSyncPanels();
  const reviewInProgressButtonText = flags.enableCancelReviewEngine ? "Cancel Review" : "Reviewing...";

  const isProposalReviewTaskInProgress = useMemo(() => {
    if (!referenceId) return false;

    const isInProgressOrQueued = proposal_review_tasks?.some(
      (task) => task.reference_id === referenceId && !task.failed,
    );

    return isInProgressOrQueued || reviewsInProgress.includes(referenceId);
  }, [proposal_review_tasks, referenceId, reviewsInProgress]);

  useEffect(() => {
    return () => {
      dispatch(resetProposalState());
    };
  }, [dispatch]);

  return (
    <div className="pt-3 px-5 flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
      <div className="flex items-center pb-2 mb-1">
        <button
          onClick={() => {
            setDocId("");
            setForceRefresh(true);
          }}
          className="w-36 flex items-center justify-center gap-1.5 h-8 px-2.5 rounded border border-[rgb(218,220,224)] text-[rgb(95,99,104)] text-xs hover:bg-[rgb(218,220,224,0.2)] active:text-[rgb(60,64,67)]"
        >
          <Icon name="ArrowLeft" className="h-4 w-4" />
          All Proposals
        </button>
        <div className="flex items-center justify-end w-full gap-2 ml-auto">
          <ProposalActions
            proposalDocId={proposalDocId}
            isProposalReviewTaskInProgress={isProposalReviewTaskInProgress}
            reviewInProgressButtonText={reviewInProgressButtonText}
            referenceId={referenceId}
          />
          <CommentsPanelButton />
          <ExportButton />
        </div>
      </div>
      <div
        className="border border-gray-200 bg-white pb-2 overflow-hidden flex flex-col flex-1 rounded-t-md"
        style={{
          position: fullScreen ? "fixed" : undefined,
          top: fullScreen ? "64px" : "",
          left: fullScreen ? "233px" : "",
          width: fullScreen ? "calc(100% - 233px)" : "",
          bottom: 0,
          overflow: "hidden",
          zIndex: fullScreen ? "20" : "",
        }}
      >
        <EditorToggler fullscreen={fullscreen} liveCursor={CopilotPresencePage.Proposal === nestedTab} />
      </div>
    </div>
  );
};

export default Proposal;

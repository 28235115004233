/** @jsxImportSource @emotion/react */
import React from "react";
import { ExternalLink, File } from "lucide-react";

interface InlineSourceContentPreviewProps {
  sourceName: string;
  sourcePreview: string;
  onClick: () => void;
}

const InlineSourceContentPreview: React.FC<InlineSourceContentPreviewProps> = ({
  sourceName,
  sourcePreview,
  onClick,
}) => {
  return (
    <div className="flex flex-col gap-1 border border-gray-200 rounded-md min-w-[300px] max-w-[300px] p-2 bg-white">
      <div className="font-medium text-sm flex items-center justify-between">
        <div className="flex items-center gap-1">
          <File size={14} />
          <button onClick={onClick} className="truncate max-w-[240px] hover:text-blue-500">
            {sourceName}
          </button>
        </div>
        <button onClick={onClick}>
          <ExternalLink size={14} className="mb-0.5 ml-1 text-zinc-500 hover:text-blue-500" />
        </button>
      </div>
      <div className="line-clamp-4 text-xs text-gray-500">{sourcePreview}</div>
    </div>
  );
};

export default InlineSourceContentPreview;

import type { Editor } from "@tiptap/react";
import { ExpandIcon } from "utils/icons";
import { isExpandedAction } from "store/reducers/aiReducerSlice";
import { toolbarSections } from "./toolbar-sections/constants";
import { ChevronDown, SquareSplitHorizontal } from "lucide-react";
import { useAppDispatch } from "store/storeTypes";
import { setActiveProjectFile } from "store/reducers/proposal/proposalReducer";
import ProjectAttachmentsDropdown from "components/molecules/project-attachments-dropdown";
import styles from "./Toolbar.module.css";
import { useRef } from "react";

type Props = {
  editor: Editor;
  fullscreen?: boolean;
};

export function Toolbar({ editor, fullscreen }: Props) {
  const toolbarContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  return (
    <div className="flex items-center min-w-max h-16 shadow-sm" ref={toolbarContainerRef}>
      <div className={styles.toolbar}>
        {!fullscreen && (
          <span
            onClick={() => dispatch(isExpandedAction())}
            role="button"
            aria-label="Expand"
            className={`${styles.toolbarButton} cursor-pointer select-none w-max max-w-full}`}
          >
            <ExpandIcon />
          </span>
        )}
        <div className={styles.toolbarSection}>
          <ProjectAttachmentsDropdown
            contentProps={{ align: "start" }}
            onSelect={(file) => dispatch(setActiveProjectFile(file))}
            acceptedFiles={["pdf"]}
            triggerProps={{ className: "min-w-0" }}
          >
            <div className="flex py-2 px-2.5 whitespace-nowrap items-center gap-1.5 text-gray-darkest text-sm duration-100 transition-colors rounded-md bg-white hover:bg-gray-100">
              <SquareSplitHorizontal size={16} />
              <span>Open File</span>
              <ChevronDown size={16} />
            </div>
          </ProjectAttachmentsDropdown>
        </div>
        {toolbarSections.map((section) => (
          <div key={section.key} className={styles.toolbarSection}>
            <section.component editor={editor} />
          </div>
        ))}
      </div>
    </div>
  );
}

import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import type { NodeAndPos } from "../types";
import { Decoration, DecorationSet } from "prosemirror-view";

interface SelectionChangeOptions {
  onChange?: (line: number, nodes: NodeAndPos[]) => void;
  getActiveLine?: () => number | null; // Function to retrieve the Redux state for active line
}

export const OnLineChangePlugin = Extension.create<SelectionChangeOptions>({
  name: "OnLineChangePlugin",

  addOptions() {
    return {
      onChange: () => {},
      getActiveLine: () => null, // Default to no external line change
    };
  },

  addProseMirrorPlugins() {
    const pluginKey = new PluginKey("OnLineChangePlugin");
    const { onChange, getActiveLine } = this.options;

    return [
      new Plugin({
        key: pluginKey,
        state: {
          init: () => ({ lastLine: null as number | null, decorations: DecorationSet.empty }),
          apply(_tr, value, _oldState, newState) {
            const externalLine = getActiveLine?.() ?? null;
            const { selection } = newState;
            const { from, to } = selection;
            const startLine = newState.doc.textBetween(0, from, "\n").split("\n").length;
            const nodes: NodeAndPos[] = [];

            newState.doc.nodesBetween(from, to, (node, pos) => {
              if (node.type.name === "heading") {
                nodes.push({ node, pos });
              }
            });

            const newLine = externalLine !== null ? externalLine : startLine;

            // Check if the line number has changed or if an external action triggered it
            if (newLine !== value.lastLine || nodes.length > 1) {
              onChange?.(newLine, nodes);

              // Apply decoration (CSS class) to the active node
              const decorations = DecorationSet.empty;
              if (nodes.length > 0) {
                const activeNode = nodes[0];
                const deco = Decoration.node(activeNode.pos, activeNode.pos + activeNode.node.nodeSize, {
                  class: "active-line",
                });
                return { lastLine: newLine, decorations: decorations.add(newState.doc, [deco]) };
              }

              return { lastLine: newLine, decorations };
            }

            return value;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state)?.decorations;
          },
        },
      }),
    ];
  },
});

export enum CopilotPresencePage {
  AskAi = "ask-ai",
  Project = "project",
  ComplianceMatrix = "compliancematrix",
  Documents = "documents",
  Inputs = "inputs",
  Template = "framework",
  Capture = "capture",
  Proposal = "proposal",
  ProposalPlan = "proposalplan",
  Extract = "extract",
}

import { exportChatMarkdown } from "api/api";
import { useNotification } from "context/notificationContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIsSessionInTasks } from "hook/assistants/hooks";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import type { ChatMessage } from "types/Chat";
import * as logger from "utils/log";
import { setActiveMetadata } from "store/reducers/ai-assistant/aiAssistantReducer";
import { extractMetadataFromLastVultronBlock } from "utils/assistants/utils";

export const useIsCurrentSessionInTasks = () => {
  const { activeSession, uploadChatDocumentTasks } = useAppSelector((root) => root.aiAssistantState);
  const isSessionInTasks = useIsSessionInTasks(uploadChatDocumentTasks, activeSession?.id);
  return isSessionInTasks;
};

export const maxMinutesRemainingResponses = {
  SESSION_NOT_IN_TASK_QUEUE: -1,
  TASK_TIME_CALCULATING: -2,
};

export const useMaxMinutesRemainingForSession = (sessionId?: string): number => {
  const { uploadChatDocumentTasks } = useAppSelector((root) => root.aiAssistantState);
  const isSessionInTasks = useIsSessionInTasks(uploadChatDocumentTasks, sessionId);

  return useMemo(() => {
    if (!sessionId || !uploadChatDocumentTasks?.length || !isSessionInTasks) {
      return maxMinutesRemainingResponses.SESSION_NOT_IN_TASK_QUEUE;
    }

    const sessionTasks = uploadChatDocumentTasks.filter(
      (task) => task.chat_session_id === sessionId && task.minutes_time_remaining != null,
    );

    if (!sessionTasks.length) {
      return maxMinutesRemainingResponses.TASK_TIME_CALCULATING;
    }

    return Math.max(...sessionTasks.map((task) => task.minutes_time_remaining as number));
  }, [isSessionInTasks, sessionId, uploadChatDocumentTasks]);
};

export const useExportChatMessage = () => {
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { setToast } = useNotification();

  const exportChatMessage = useCallback(
    async (
      sessionId: string,
      markdownContent: ChatMessage["content"],
      chatSessionType: ChatMessage["session_type"],
      contentHeader?: string,
    ) => {
      setIsExporting(true);
      setError(null);
      try {
        const response = await exportChatMarkdown(sessionId, {
          markdown_content: markdownContent,
          chat_session_type: chatSessionType,
          ...(contentHeader && { name: contentHeader }),
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        let fileName = contentHeader ? `${contentHeader}.docx` : "exported_chat_session.docx";
        const contentDisposition = response.headers["content-disposition"];
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?([^"]+)"?/);
          if (match && match[1]) {
            fileName = match[1];
          }
        }

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (err) {
        logger.error(err as Error, "Error exporting chat session");
        setToast.error({
          msg: "Failed to export chat session. Please try again.",
        });
      } finally {
        setIsExporting(false);
      }
    },
    [setToast],
  );

  return { exportChatMessage, isExporting, error };
};

export const useSyncFromLastMessage = () => {
  const dispatch = useAppDispatch();
  const activeSessionConversation = useAppSelector((root) => root.aiAssistantState.activeSession?.conversation);

  const messageMetadata = useMemo(() => {
    return extractMetadataFromLastVultronBlock(activeSessionConversation || []);
  }, [activeSessionConversation?.length]);

  useEffect(() => {
    dispatch(setActiveMetadata(messageMetadata));
  }, [dispatch, messageMetadata]);
};

import { MarkdownEditor } from "components/copilot/MarkdownEditor";
import TypingEffect from "components/TypingEffect";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { INTERNET_LOADING_MSGS } from "../constants";
import InternetAnimation from "Assets/gifs/black-internet-loading.gif";
import { useState, useEffect } from "react";

interface MarkdownContentProps {
  blockContent: string;
  isStreaming: boolean;
  streamCopy?: string;
  enableInternet: boolean;
}

const MarkdownContent = ({ blockContent, isStreaming, streamCopy, enableInternet }: MarkdownContentProps) => {
  const [loadingMsg, setLoadingMsg] = useState(INTERNET_LOADING_MSGS[0]);

  useEffect(() => {
    setLoadingMsg(INTERNET_LOADING_MSGS[0]);
  }, [isStreaming]);

  useAnimateLoadingMsg(isStreaming && enableInternet, 10000, INTERNET_LOADING_MSGS, (msg) => setLoadingMsg(msg));

  if (isStreaming && !streamCopy && !enableInternet) {
    return;
  }

  if (isStreaming && !streamCopy && enableInternet) {
    return (
      <div className="flex items-center text-sm">
        <img src={InternetAnimation} alt="" className="w-[30px] h-[30px]" />
        <div className="text-action ml-1">{loadingMsg}</div>
        <span className="loading-ellipsis" />
      </div>
    );
  }

  return (
    <div className="min-h-6">
      <MarkdownEditor content={blockContent} isStreaming={isStreaming} streamCopy={streamCopy} />
    </div>
  );
};

export default MarkdownContent;

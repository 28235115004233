import type React from "react";
import { useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import axios from "axios";

import { useFlags } from "hook/useFlags";
import useLogout from "hook/useLogout";
import { useAppSelector } from "store/storeTypes";
import { useSearchParamsRedirection } from "./hooks";

import ScreenSpinner from "../utils/Spinner";

import AIAssistant from "pages/ai-assistant";
import AIAssistantRoom from "pages/ai-assistant/room";
import Capture from "pages/capture";
import CheckLoginTokenPage from "../pages/auth/CheckLoginTokenPage";
import ConfirmEmail from "../pages/auth/ConfirmEmailPage";
import ContractManagement from "../pages/contracts-management/ContractManagement";
import ContinueWithEmail from "../pages/auth/ContinueWithEmail";
import { ControlItemSlug } from "pages/draft-section/hooks";
import CreateWorkSpace from "../pages/auth/CreateWorkSpace";
import Dashboard from "../pages/dashboard/Dashboard";
import DocumentsContent from "pages/drive/documents/DocumentsContent";
import DraftSection, { DraftSectionEmpty, DraftSectionProposal, DraftSectionRequirements } from "pages/draft-section";
import DrivePage, { MediaContent } from "pages/drive";
import FolderPage from "pages/drive/FolderPage";
import LoginPage from "../pages/auth/LoginPage";
import ProjectDetails from "pages/project-details";
import SelectWorkSpacePage from "../pages/auth/SelectWorkSpacePage";
import Settings from "../pages/Settings";
import Sources from "pages/Sources/Sources";
import WelcomePage from "../pages/auth/WelcomePage";
import Workflows from "pages/Workflows/workflows";

import ExtractDocViewerModal from "components/copilot/extract-v2/doc-viewer/ExtractDocViewerModal";
import ContentLibrary from "components/copilot/ProposalPlan/content-library/ContentLibrary";
import ProjectSpecific from "components/copilot/ProposalPlan/project-specific/ProjectSpecific";

import CaptureForm from "components/organisms/capture-form";
import WinThemesContent from "components/organisms/win-themes-content";

// --------------- main wrapper for all routing within the web app -------------------
const AppRouter = () => {
  const flags = useFlags();
  useSearchParamsRedirection();
  const { search } = useLocation();

  return (
    <Routes>
      {/* UnAuthenticated routes */}
      <Route path="/" element={<Navigate to="/auth" replace />} />
      <Route path="auth" element={<WelcomePage />}>
        <Route index element={<ContinueWithEmail />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="confirm-email" element={<ConfirmEmail />} />
        <Route path="redirect" element={<CheckLoginTokenPage />} />
      </Route>
      {/* Authenticated routes */}
      <Route
        path="select-workspace"
        element={
          <ProtectedRoute redirectPath="/auth">
            <SelectWorkSpacePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="create-workspace"
        element={
          <ProtectedRoute redirectPath="/auth">
            <CreateWorkSpace />
          </ProtectedRoute>
        }
      />
      <Route
        path="dashboard"
        element={
          <ProtectedRoute redirectPath="/auth">
            <Dashboard />
          </ProtectedRoute>
        }
      >
        <Route
          index
          element={
            <Navigate to={flags.isAssistantLandingPage ? "/dashboard/ai-assistant" : "/dashboard/contracts"} replace />
          }
        />
        <Route path="contracts" element={<ContractManagement />} />
        <Route path="contracts/details" element={<ProjectDetails />} />
        {!flags.enableNewProjectFlow ? (
          <>
            <Route path="contracts/details/documents" element={<ProjectDetails />}>
              <Route index element={<Navigate to="project-specific" replace />} />
              <Route path="project-specific" element={<ProjectSpecific />} />
              <Route path="content-library" element={<ContentLibrary />} />
            </Route>
            <Route path="contracts/details/capture" element={<ProjectDetails />}>
              <Route index element={<CaptureForm />} />
              <Route path="win-themes" element={<WinThemesContent />} />
              <Route path=":schemaType" element={<CaptureForm />} />
            </Route>
            <Route path="contracts/details/extractions" element={<ProjectDetails />}>
              <Route path=":extractionId" element={<ExtractDocViewerModal />} />
            </Route>
            <Route path="contracts/details/volumes/:volumeId" element={<ProjectDetails />}>
              <Route index element={<DraftSectionEmpty />} />
              <Route path="sections/:sectionId" element={<DraftSection />}>
                <Route path={ControlItemSlug.requirements} element={<DraftSectionRequirements />} />
                <Route path={ControlItemSlug.proposal} element={<DraftSectionProposal />} />
              </Route>
            </Route>
          </>
        ) : (
          <>
            <Route path="contracts/details/inputs" element={<ProjectDetails />}>
              <Route index element={<Navigate to={`documents${search}`} replace />} />
              <Route path="documents" element={<ProjectSpecific />} />
              <Route path="content-library" element={<ContentLibrary />} />
              <Route path="capture" element={<Capture />}>
                <Route path="win-themes" element={<WinThemesContent />} />
                <Route path=":schemaType" element={<CaptureForm />} />
              </Route>
            </Route>
          </>
        )}
        <Route path="ai-assistant" element={<AIAssistantRoom />}>
          <Route index element={<AIAssistant />} />
          <Route path=":currentSessionId" element={<AIAssistant />} />
          <Route
            path="workflows"
            element={flags.globalAssistantWorkflows ? <Workflows /> : <Navigate to="/dashboard/ai-assistant" replace />}
          />
        </Route>
        <Route path="library" element={<DrivePage />}>
          <Route index element={<Navigate to="documents" replace />} />
          <Route path="documents" element={<DocumentsContent />} />
          <Route path="documents/folder/:folderId" element={<FolderPage />} />
          <Route path="media" element={<MediaContent />} />
          <Route path="media/folder/:folderId" element={<MediaContent />} />
        </Route>
        <Route path="settings" element={<Settings />} />
        <Route path="sources" element={<Sources />} />
        <Route path="create-workspace" element={<CreateWorkSpace />} />
        <Route path="select-workspace" element={<SelectWorkSpacePage />} />
      </Route>
      <Route path="*" element={<h1>404 not found</h1>} />
    </Routes>
  );
};

// This is wrapper component for protected routes & it will also validate role based routing.
type ProtectedRouteProps = {
  redirectPath?: string;
  children: React.ReactNode;
};
const ProtectedRoute = ({ redirectPath = "/", children }: ProtectedRouteProps) => {
  const { isAuthenticated, isLoading } = useAppSelector((store) => store.auth);
  const location = useLocation();
  const logout = useLogout();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 403) {
          logout();
        }
        return Promise.reject(error);
      },
    );
  }, [logout]);

  // if auth loading
  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <ScreenSpinner />
      </div>
    );
  }
  // if user is not authenticated
  if (!isAuthenticated) {
    window.Intercom("shutdown");
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }
  return children ? children : <Outlet />;
};

export default AppRouter;

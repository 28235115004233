import { Minus, Plus, ZoomIn } from "lucide-react";
import type { Dispatch, SetStateAction } from "react";
import { decrementScale, incrementScale } from "utils/scale";

type Props = {
  scale: number;
  setScale: Dispatch<SetStateAction<number>>;
};

const Scale = ({ scale, setScale }: Props) => {
  return (
    <div className="shadow backdrop-blur-lg rounded-md p-1.5 pl-3 flex gap-4 flex-row items-center bg-gray-300/60">
      <div className="text-xs flex items-center gap-2">
        <ZoomIn size={16} className="stroke-[0.5]" />
        <div className="text-xs font-semibold">{Math.trunc(scale * 100)}%</div>
      </div>
      <div className="flex items-center gap-1">
        <button
          aria-label="Increase scale"
          className="w-7 h-7 flex justify-center items-center rounded-md bg-black/[0.12] hover:bg-black/[0.2] active:bg-black/[0.24]"
          onClick={() => setScale(incrementScale)}
        >
          <Plus size={14} />
        </button>
        <button
          aria-label="Decrease scale"
          className="w-7 h-7 flex justify-center items-center rounded-md bg-black/[0.12] hover:bg-black/[0.2] active:bg-black/[0.24]"
          onClick={() => setScale(decrementScale)}
        >
          <Minus size={14} />
        </button>
      </div>
    </div>
  );
};

export default Scale;

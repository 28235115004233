import { LucideX } from "lucide-react";

const OutlineGeneratedBanner = ({ onOutlineBannerUpdate }: { onOutlineBannerUpdate: () => void }) => {
  return (
    <div className="border-2 border-gray-darkest bg-gray-300/75 gap-x-2 backdrop-blur-md text-xs font-medium p-1.5 sticky top-0 z-[2] flex flex-row items-start justify-between">
      Proposal outline was generated from the solicitation{" "}
      <button className="duration-150 transition-opacity hover:opacity-80" onClick={onOutlineBannerUpdate}>
        <LucideX className="shrink-0" size={18} />
      </button>
    </div>
  );
};

export default OutlineGeneratedBanner;

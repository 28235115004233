import { ChevronDown, LucideNewspaper, Sparkles } from "lucide-react";
import { WORKFLOW_TO_META, WorkflowNameType, WorkflowSub } from "pages/ai-assistant/constants";
import { useProjectWorkflowActions } from "pages/Workflows/useProjectWorkflowActions";
import { toggleAssistant } from "store/reducers/copilot/copilotDrawerReducer";
import { useAppDispatch } from "store/storeTypes";
import { setActiveSession } from "store/reducers/writing-assistant/writingAssistantReducer";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import useWritingAssistantOperations from "hook/writing-assistant/useWritingAssistantOperations";
import { DEFAULT_SESSION_NAME } from "components/molecules/assistant-sidebar-row/constants";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import DropdownMenu from "components/molecules/dropdown-menu";
import { useStartProposalItems } from "./hooks";

const FloatingPlaceholder = () => {
  const { createNewWorkflow } = useProjectWorkflowActions();
  const dispatch = useAppDispatch();
  const DraftIcon = WORKFLOW_TO_META[WorkflowNameType.Draft].icon;
  const DraftIconColor = WORKFLOW_TO_META[WorkflowNameType.Draft].color;
  const { createSession } = useWritingAssistantOperations();
  const items = useStartProposalItems();

  return (
    <div className="flex flex-col gap-4 absolute top-[96px] left-[96px] pointer-events-none">
      <div className="text-gray-400/80">Click here to start writing or paste your content</div>
      <div className="flex flex-col gap-2">
        <div className="text-base text-zinc-600 mb-1">Quick Starters</div>
        <div className="flex flex-row gap-2 pointer-events-auto z-[1]">
          <button
            className="flex flex-row gap-1.5 items-center rounded-full font-normal border border-zinc-200 text-sm px-3 py-1 bg-gray-100 text-zinc-500 duration-100 hover:bg-gray-200"
            onClick={() => createNewWorkflow(WorkflowSub.Draft)}
          >
            <DraftIcon size={14} style={{ color: DraftIconColor }} />
            Draft
          </button>
          <DropdownMenu contentProps={{ align: "start" }} items={items}>
            <div className="flex flex-row gap-1.5 items-center rounded-full font-normal border border-zinc-200 text-sm px-3 py-1 bg-gray-100 text-zinc-500 duration-100 hover:bg-gray-200">
              <LucideNewspaper size={14} className="text-green-500" />
              Start Proposal
              <ChevronDown size={16} />
            </div>
          </DropdownMenu>
          <button
            onClick={() => {
              const newSession = createSession({ name: DEFAULT_SESSION_NAME });
              const immutableSession = newSession?.toJSON() as ToImmutable<WritingAssistantSession>;
              dispatch(setActiveSession(immutableSession));
              dispatch(toggleAssistant(true));
            }}
            className="flex flex-row gap-1.5 items-center rounded-full font-normal border border-zinc-200 text-sm px-3 py-1 bg-gray-100 text-zinc-500 duration-100 hover:bg-gray-200"
          >
            <Sparkles size={14} className="text-purple-400" />
            Start with Assistant
          </button>
        </div>
      </div>
    </div>
  );
};

export default FloatingPlaceholder;

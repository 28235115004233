import type { ComponentProps } from "react";
import { useMemo } from "react";
import RequirementItem from "./RequirementItem";
import type { UniqueIdentifier } from "@dnd-kit/core";
import type RequirementList from "./RequirementList";

interface DragProps extends Pick<ComponentProps<typeof RequirementItem>, "sectionId" | "idx"> {
  activeDragId: UniqueIdentifier;
  requirements: ComponentProps<typeof RequirementList>["requirements"];
}

const DraggingOverlay = ({ activeDragId, requirements }: DragProps) => {
  const activeRequirement = useMemo(
    () => requirements.find((row) => row.requirement.id === activeDragId),
    [activeDragId, requirements],
  );
  if (!activeRequirement) return null;
  return (
    <div className="markdown-body">
      <RequirementItem isReadOnly row={activeRequirement} isDragging />
    </div>
  );
};

export default DraggingOverlay;

/** @jsxImportSource @emotion/react */

import { EditorContent, useEditor } from "@tiptap/react";
import { getCommentEditorExtensions } from "./CommentExtensions";
import { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import tw from "twin.macro";
import { useAppSelector } from "store/storeTypes";
import "tippy.js/dist/tippy.css";
import { extractMentionedUserIds } from "components/Comments/utils";

interface CommentEditorProps {
  readonly: boolean;
  placeholder?: string;
  content?: string;
  onAddComment?: (comment: string, mentions: string[]) => boolean | void;
  autofocus?: boolean;
  onUpdate?: (isEmpty: boolean) => void;
  editorRef?: (editor: ReturnType<typeof useEditor>) => void;
  setDisableScroll?: React.Dispatch<React.SetStateAction<boolean>>;
  truncated?: boolean;
  onMentionChange?: (mentionedUser?: { id: string; username: string }) => void;
}

export const CommentEditor = ({
  readonly,
  onAddComment,
  content,
  autofocus,
  placeholder = "Comment or mention others with @",
  onUpdate,
  editorRef,
  setDisableScroll,
  truncated = false,
  onMentionChange,
}: CommentEditorProps) => {
  const commentResponseWrapperRef = useRef<HTMLDivElement | null>(null);
  const [showFullComment, setShowFullComment] = useState(false);
  const { workspaceMembers } = useAppSelector((store) => store.auth);
  const isMentionActiveRef = useRef(false);

  const showVisibilityControls =
    readonly &&
    commentResponseWrapperRef?.current?.offsetHeight &&
    commentResponseWrapperRef?.current?.offsetHeight > 200;

  const extensions = useMemo(
    () => getCommentEditorExtensions(placeholder, workspaceMembers, setDisableScroll, isMentionActiveRef),
    [placeholder, workspaceMembers, setDisableScroll],
  );

  const editor = useEditor({
    extensions,
    autofocus: !!autofocus,
    editable: !readonly,
    editorProps: {
      attributes: {
        class: "focus:outline-none",
      },
      // Handle Enter key press
      handleKeyDown: (view: any, e: KeyboardEvent) => {
        if (e.key === "Enter" && !e.shiftKey) {
          // If a mention suggestion is active, let it handle the key press.
          if (isMentionActiveRef.current) {
            return false;
          }
          const editorContent = editor?.getHTML();
          if (!editorContent) {
            e.preventDefault();
            return true;
          }
          if (onAddComment) {
            e.preventDefault();
            const mentionedUserIds = extractMentionedUserIds(view.state.doc);
            const clear = onAddComment(editorContent, mentionedUserIds);
            if (clear) {
              // Clear the editor content.
              view.dispatch(view.state.tr.delete(0, view.state.doc.content.size));
            }
            return true;
          }
        }
        return false;
      },
    },
    content,
    onCreate: ({ editor }) => {
      onUpdate?.(editor.isEmpty);
      if (editorRef) editorRef(editor);
    },
    onUpdate: ({ editor }) => {
      onUpdate?.(editor.isEmpty);
      // Scan the document for mention nodes and pick the last one.
      const doc = editor.state.doc;
      let lastMention: { id: string; username: string } | undefined;
      doc.descendants((node) => {
        if (node.type.name === "mention") {
          lastMention = {
            id: node.attrs.id,
            username: node.attrs.username,
          };
        }
      });
      // Pass the latest mention to the parent if provided.
      onMentionChange?.(lastMention);
    },
  });

  // Pass the editor to the parent component
  useEffect(() => {
    if (editor && editorRef) {
      editorRef(editor);
    }
  }, [editor, editorRef]);

  // Auto focus the editor
  useEffect(() => {
    if (editor && editorRef) {
      editorRef(editor);
    }
  }, [editor, editorRef]);

  useEffect(() => {
    if (!editor) return;
    if (autofocus) editor.commands.focus();
  }, [editor, autofocus]);

  // Update the editor's editable state
  useEffect(() => {
    if (!editor) return;
    editor.setEditable(!readonly);
  }, [editor, readonly]);

  // Update the editor's content
  useEffect(() => {
    if (!editor) return;
    editor.commands.setContent(content || "");
  }, [content, editor]);

  if (!editor) return null;

  return (
    <div className="flex flex-row gap-2 items-center justify-between w-full">
      <div
        className={classNames("flex-1 min-w-0", {
          "text-sm text-gray-600 mt-1 break-words": truncated,
        })}
        style={
          truncated
            ? {
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }
            : undefined
        }
        ref={commentResponseWrapperRef}
      >
        <EditorContent
          editor={editor}
          className="w-full break-words text-sm"
          readOnly={readonly}
          css={[!showFullComment && showVisibilityControls && tw`line-clamp-10`]}
        />
        {showVisibilityControls && (
          <button
            onClick={(event) => {
              event.preventDefault();
              setShowFullComment((prev) => !prev);
            }}
            className="text-xs mt-2 flex self-start text-slate-500 duration-100 hover:text-slate-900"
          >
            {showFullComment ? "Show less..." : "Show more..."}
          </button>
        )}
      </div>
    </div>
  );
};

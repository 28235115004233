/** @jsxImportSource @emotion/react */
import type { MouseEvent as ReactMouseEvent } from "react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import tw from "twin.macro";

import { useNotification } from "context/notificationContext";
import { useFlags } from "hook/useFlags";
import { DOCUMENT_UPLOAD_FORMATS } from "pages/drive/constants";
import { setAssistantPrompt } from "store/reducers/ai-assistant/aiAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";

import DragAndDropOverlay from "./DragAndDropOverlay";
import InputTextarea from "./InputTextArea";
import InputFooter from "./InputFooter";
import AIAssistantInputEnhancedActions from "../AIAssistantInputEnhancedActions";
import { useFileUploadHandler } from "./AddAttachmentsButton/hooks";
import { useAssistant } from "./hooks";
import { useIsCurrentSessionInTasks } from "../hooks";
import Popover from "components/atoms/popover";
import WorkflowActions from "./ActionsOverlay/WorkflowActions";
import { useSetDeferred } from "hook/useSetDeferred";

const AIAssistantInput = () => {
  const flags = useFlags();
  const { setToast } = useNotification();
  const { prompt, streamState, activeSession } = useAppSelector((root) => root.aiAssistantState);
  const { isStreamingInProgress } = streamState;
  const dispatch = useAppDispatch();
  const { submitMessage, abortConnection } = useAssistant();
  const isCurrentSessionInTasks = useIsCurrentSessionInTasks();
  const { handleFileUpload } = useFileUploadHandler();

  const promptRef = useRef<HTMLTextAreaElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useSetDeferred(false);
  const initialAutoFocusRef = useRef(true);

  const { getRootProps, isDragActive } = useDropzone({
    accept: DOCUMENT_UPLOAD_FORMATS,
    noClick: true,
    onDrop: (files) => handleFileUpload(files),
    onDropRejected: () => setToast.error({ msg: "There was an error uploading your document." }),
  });

  const isProcessingNonWorkflowSession = isCurrentSessionInTasks && !activeSession?.workflow;
  const isInProgressOrIsWorkflow = activeSession?.conversation.length || activeSession?.workflow || prompt.length;

  const canSubmit =
    !isStreamingInProgress &&
    !isProcessingNonWorkflowSession &&
    activeSession?.input_field_type !== INPUT_FIELD_OPTIONS.pendingWorkflow &&
    !!prompt.trim();

  const handleTranslation = (message: string) => {
    const trailingSpace = prompt.endsWith(" ") || prompt === "" ? "" : " ";
    dispatch(setAssistantPrompt(prompt + trailingSpace + message));
  };

  useLayoutEffect(() => {
    initialAutoFocusRef.current = true;
    promptRef.current?.focus();
  }, [activeSession?.id]);

  useEffect(() => {
    if (activeSession?.workflow && !activeSession.conversation.length) {
      submitMessage();
    }
  }, [activeSession?.conversation.length, activeSession?.workflow]);

  const handleInputFocus = () => {
    if (initialAutoFocusRef.current) {
      initialAutoFocusRef.current = false;
      return;
    }
    setPopoverOpen(true);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    if (!popoverRef.current?.contains(document.activeElement)) {
      setPopoverOpen(false);
    }
  };

  const handleInputMouseDown = (e: ReactMouseEvent<HTMLTextAreaElement, MouseEvent>) => {
    e.stopPropagation();
    setPopoverOpen((prev) => !prev);
  };

  return (
    <div
      className="relative flex flex-col pb-6 gap-2 mx-4 lg:px-2 w-full lg:mx-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-[900px] 3xl:max-w-[1100px]"
      {...getRootProps()}
    >
      <div
        className="relative flex bg-white flex-col rounded-lg border shadow-sm overflow-hidden"
        css={[
          flags.documentAttachments ? tw`border-gray-300` : tw`border-gray-light`,
          isDragActive && tw`border-black border-2 border-dashed`,
        ]}
      >
        <DragAndDropOverlay isActive={isDragActive} />
        <Popover
          open={popoverOpen && !isInProgressOrIsWorkflow}
          content={
            <div ref={popoverRef}>
              <WorkflowActions />
            </div>
          }
          contentProps={{
            style: { minWidth: (promptRef.current?.clientWidth ?? 0) + 2 },
            className: "z-[100] text-sm rounded-md outline-none bg-white w-full",
            sideOffset: -45,
          }}
        >
          <div className="relative flex flex-col">
            <div className="relative flex">
              <InputTextarea
                canSubmit={canSubmit}
                prompt={prompt}
                promptRef={promptRef}
                isStreamingInProgress={isStreamingInProgress}
                onSubmit={submitMessage}
                onChange={(e) => dispatch(setAssistantPrompt(e.target.value))}
                flags={flags}
                className="rounded-t-md w-full"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onMouseDown={handleInputMouseDown}
              />
              <InputFooter
                canSubmit={canSubmit}
                flags={flags}
                isStreamingInProgress={isStreamingInProgress}
                isCurrentSessionInTasks={isCurrentSessionInTasks}
                abortConnection={abortConnection}
                submitMessage={submitMessage}
                handleTranslation={handleTranslation}
                className="px-2.5 pb-1.5 pt-1"
              />
            </div>
            {flags.documentAttachments && <AIAssistantInputEnhancedActions />}
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default AIAssistantInput;

/** @jsxImportSource @emotion/react */

import { useCallback, useMemo } from "react";

import { useSelection } from "../SelectionContext";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import * as logger from "utils/log";
import Tooltip from "components/atoms/tooltip";
import { useDocViewNotification } from "components/copilot/extract-v2/context/doc-view-notification-context/context";
import { apiUrl, isOnsiteDeployment } from "config/vultronConfig";
import { HEARTBEAT } from "const-values/Stream";
import { YJS_OPERATIONS } from "const-values/yjs";
import useExtractionOperations from "hook/useExtractionOperations";
import { useLocalStorage } from "hook/useLocalStorage";
import { useParams, useSearchParams } from "react-router-dom";
import {
  appendLiveTemplateExtraction,
  setIsLoadingLiveTemplateExtraction,
} from "store/reducers/extract/LiveTemplateExtractionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { StreamEvent } from "types/Streaming/streamConfig";
import type { TemplateVolume } from "types/TemplateSection";

export const useVultronAiOptions = (container?: HTMLElement | null | undefined) => {
  const [searchParams] = useSearchParams();
  const { extractionId } = useParams();
  const projectId = searchParams.get("id");
  const { selectedBlocks } = useSelection();
  const activeDocumentId = useAppSelector((store) => store.currentExtractionState.activeDocument?.id);
  const isLoading = useAppSelector((store) => store.liveTemplateExtractionState.isLoadingLiveTemplateExtraction);
  const submitLiveTemplateExtraction = useSubmitLiveTemplateExtraction();
  const selectedBlocksLimit = isOnsiteDeployment ? 100 : 1_000;
  const isSelectedBlocksAboveLimit = (selectedBlocks?.length || 0) > selectedBlocksLimit;
  const extractOutlineDisabled =
    isLoading || !selectedBlocks?.length || !activeDocumentId || isSelectedBlocksAboveLimit;

  return useMemo(
    () => [
      {
        key: 1,
        label: (
          <Tooltip
            contentProps={{ side: "left", sideOffset: 8 }}
            portalProps={{ container }}
            disableHoverableContent
            disabled={!isSelectedBlocksAboveLimit}
            content={`Please select under ${selectedBlocksLimit} requirements.`}
          >
            <div className="w-full h-full">Extract outline</div>
          </Tooltip>
        ),
        onSelect: () => {
          if (!projectId || !activeDocumentId || !extractionId || extractOutlineDisabled) return;

          submitLiveTemplateExtraction({
            analysis_id: extractionId,
            document_id: activeDocumentId,
            requirement_ids: (selectedBlocks || []).map(({ requirement }) => requirement.requirement.id),
          });
        },
        style: extractOutlineDisabled ? tw`pointer-events-auto hover:bg-white` : undefined,
        disabled: extractOutlineDisabled,
      },
      {
        key: 2,
        label: "More coming soon",
        disabled: true,
      },
    ],
    [
      activeDocumentId,
      container,
      extractOutlineDisabled,
      extractionId,
      isSelectedBlocksAboveLimit,
      projectId,
      selectedBlocks,
      selectedBlocksLimit,
      submitLiveTemplateExtraction,
    ],
  );
};

export type LiveTemplateExtractionResponse = {
  template_data: { id: string; volumes: TemplateVolume[]; evaluations: null };
};

type LiveTemplateExtractionVariables = {
  requirement_ids: string[];
  document_id: string;
  analysis_id: string;
};

let controller = new AbortController();
const useSubmitLiveTemplateExtraction = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const projectId = searchParams.get("id");
  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const isLoadingLiveTemplateExtraction = useAppSelector(
    (store) => store.liveTemplateExtractionState.isLoadingLiveTemplateExtraction,
  );
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0");
  const auth0Header = use_auth0 === true;

  const abortConnection = useCallback(() => {
    controller.abort();
    controller = new AbortController();
    dispatch(setIsLoadingLiveTemplateExtraction(false));
  }, [dispatch]);

  const submitLiveTemplateExtraction = useCallback(
    (variables: LiveTemplateExtractionVariables) => {
      if (isLoadingLiveTemplateExtraction) return;
      dispatch(setIsLoadingLiveTemplateExtraction(true));

      fetchEventSource(`${apiUrl}/autopilot/${projectId}/extract/template/live`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Workspace: `Workspace ${workspace_id}`,
          Authorization: `Bearer ${localValue}`,
          "X-Authorization-Auth0": JSON.stringify(auth0Header),
          Accept: "application/json",
        },
        body: JSON.stringify(variables),
        signal: controller.signal,
        openWhenHidden: true,
        onmessage(msg) {
          if (msg.event === StreamEvent.StreamStop) {
            abortConnection();
            return;
          }

          if (msg?.data === HEARTBEAT) return;

          if (msg.event === StreamEvent.Payload) {
            try {
              const parsedData = JSON.parse(msg.data) as LiveTemplateExtractionResponse;
              const volumes = parsedData.template_data.volumes;
              dispatch(appendLiveTemplateExtraction(volumes));
            } catch {
              abortConnection();
            }
          }
        },
        async onopen(response) {
          if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
            return; // everything's good
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            abortConnection();
            logger.error("Ask ai failed", response);
          } else {
            // throw new RetriableError();
          }
        },
        onclose: async () => {
          dispatch(setIsLoadingLiveTemplateExtraction(false));
        },
        onerror(err) {
          abortConnection();

          if (err instanceof Error) {
            logger.error(err);
            throw err;
          }
        },
      });
    },
    [abortConnection, auth0Header, dispatch, isLoadingLiveTemplateExtraction, localValue, projectId, workspace_id],
  );

  return submitLiveTemplateExtraction;
};

export const useExtractionBulkActions = () => {
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const { selectedBlocks = [] } = useSelection();
  const { showRequirementSuccessToast } = useDocViewNotification();

  const allBlocksAreUnselected = useMemo(
    () => selectedBlocks.every((block) => block.requirement.requirement.skipped),
    [selectedBlocks],
  );
  const allBlocksAreSelected = useMemo(
    () => selectedBlocks.every((block) => !block.requirement.requirement.skipped),
    [selectedBlocks],
  );
  const allBlocksAreUnassigned = useMemo(
    () => selectedBlocks.every((block) => !block.requirement.proposal_reference.section_id),
    [selectedBlocks],
  );

  const { addAttribution, bulkUnassignExtractionRequirements, setBulkExtractionRequirementsSkipped } =
    useExtractionOperations();

  const performAction = (actionType: string, setRequirementsSkipped: boolean, actionMessage: string) => {
    if (!extraction?.id || selectedBlocks.length === 0) return;

    const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

    setBulkExtractionRequirementsSkipped(extraction.id, selectedReqIds, setRequirementsSkipped);
    addAttribution(actionType);
    showRequirementSuccessToast(actionMessage, selectedReqIds.length);
  };

  const addItemToMatrix = () => {
    performAction(YJS_OPERATIONS.EXTRACTION.SET_REQUIREMENT_BULK_SELECTED, false, "added to Compliance Matrix");
  };

  const removeItemFromMatrix = () => {
    performAction(YJS_OPERATIONS.EXTRACTION.SET_REQUIREMENT_BULK_SKIPPED, true, "removed from Compliance Matrix");
  };

  const unassignItemFromOutline = () => {
    if (!extraction?.id || selectedBlocks.length === 0) return;

    const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

    bulkUnassignExtractionRequirements(extraction.id, selectedReqIds);
    addAttribution(YJS_OPERATIONS.EXTRACTION.SET_REQUIREMENT_BULK_UNASSIGN);
    showRequirementSuccessToast("unassigned from Proposal Outline", selectedReqIds.length);
  };

  return {
    allBlocksAreSelected,
    allBlocksAreUnselected,
    allBlocksAreUnassigned,
    addItemToMatrix,
    removeItemFromMatrix,
    unassignItemFromOutline,
  };
};

import type { Editor } from "@tiptap/react";
import { Button } from "../primitives/Button";
import styles from "./Toolbar.module.css";
import { IndentIcon } from "../icons/Indent";
import { OutdentIcon } from "../icons/Outdent";
import { ToolbarTooltip } from "../primitives/Tooltip";

export function ToolbarIndent({ editor }: { editor: Editor }) {
  return (
    <>
      <ToolbarTooltip content="Decrease indent">
        <Button
          variant="subtle"
          className={styles.toolbarButton}
          onClick={() => editor.chain().focus().decreaseIndent().run()}
          aria-label="Decrease indent"
        >
          <OutdentIcon />
        </Button>
      </ToolbarTooltip>

      <ToolbarTooltip content="Increase indent">
        <Button
          variant="subtle"
          className={styles.toolbarButton}
          onClick={() => editor.chain().focus().increaseIndent().run()}
          aria-label="Increase indent"
        >
          <IndentIcon />
        </Button>
      </ToolbarTooltip>
    </>
  );
}

/** @jsxImportSource @emotion/react */
import { Download, File } from "lucide-react";
import type { WorkspaceFile } from "types/FileStorage";

type CapabilitySearchModalHeaderProps = {
  sourceName: string;
  foundFile?: WorkspaceFile;
};

const CapabilitySearchModalHeader = ({ sourceName, foundFile }: CapabilitySearchModalHeaderProps) => (
  <>
    <div className="flex gap-2 pr-3 items-center">
      <File size={16} />
      <div className="flex flex-col gap-0.5">
        <div className="flex gap-2 font-semibold items-center text-sm text-stone-900 break-all line-clamp-2 pr-4">
          {sourceName || "Unnamed Source File"}
          {!!foundFile && (
            <a
              href={foundFile.download_url}
              download={foundFile.name}
              className="text-[18px] pb-0.5 text-gray-500 duration-150 hover:text-gray-600"
            >
              <Download size={16} />
            </a>
          )}
        </div>
      </div>
    </div>
    <div className="text-sm text-gray-500 font-normal mt-2">
      Below are the parts of the document referenced to generate this response.
    </div>
  </>
);

export default CapabilitySearchModalHeader;

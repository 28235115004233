import React, { useState } from "react";
import { MarkdownEditor } from "components/copilot/MarkdownEditor";
import { VultronAvatar } from "components/molecules/avatar";
import TypingAnimation from "../../molecules/ai-assistant-blocks/vultron-block/vultron-block-subsections/TypingAnimation";
import SourceFileButtons from "./source-file-buttons/SourceFileButtons";
import { BlockContentItemSourceTypes } from "types/Assistants/types";
import type { BlockContentItem, WorkflowInstructions } from "types/Assistants/types";
interface ResponsesWithSourcesProps {
  blockContent: (BlockContentItem | WorkflowInstructions)[];
  isStreaming?: boolean;
  streamCopy?: string;
  error?: boolean;
  blockId?: string;
  itemsPerPage?: number;
}

const ResponsesWithSources: React.FC<ResponsesWithSourcesProps> = ({
  blockContent,
  isStreaming = false,
  streamCopy = "",
  error = false,
  blockId,
  itemsPerPage = 10,
}) => {
  const blockHasError = error;
  const [visibleCount, setVisibleCount] = useState(itemsPerPage);

  const handleShowMore = () => {
    setVisibleCount((prev) => prev + itemsPerPage);
  };

  if (!blockContent || blockContent.length === 0) return null;

  const lastItem = blockContent[blockContent.length - 1];
  const hasWorkflowData = lastItem && "instruction" in lastItem;
  const blockItems: BlockContentItem[] = hasWorkflowData
    ? (blockContent.slice(0, blockContent.length - 1) as BlockContentItem[])
    : (blockContent as BlockContentItem[]);

  const visibleBlocks = blockItems.slice(0, visibleCount);

  return (
    <div className="flex flex-col gap-3 w-full">
      {isStreaming && !streamCopy && <TypingAnimation blockHasError={blockHasError} />}

      {blockHasError && (
        <div className="text-red-400 text-xs">
          There was an issue processing the request. Please try again. If the issue persists, please contact us at
          support@vultron.ai.
        </div>
      )}

      {!blockHasError && blockContent && (
        <>
          <div
            className="flex flex-row gap-2.5 w-full"
            css={[isStreaming && { scrollMarginTop: 8 }]}
            id={isStreaming && blockId ? `assistant-block-${blockId}` : undefined}
          >
            <VultronAvatar size={26} isSquare />
            <div className="flex flex-col text-sm gap-3 flex-1 min-w-0">
              {visibleBlocks.map((block, index) => (
                <React.Fragment key={index}>
                  <div className="markdown-block inline">
                    <MarkdownEditor
                      content={block.response}
                      error={false}
                      inlineSourceFiles={
                        block.source_type === BlockContentItemSourceTypes.Inline ? block.source_files : []
                      }
                    />
                  </div>
                  {block.source_type !== "inline" && block.source_files.length > 0 && (
                    <SourceFileButtons sourceFiles={block.source_files} />
                  )}
                </React.Fragment>
              ))}

              {blockItems.length > visibleCount && (
                <button className="text-gray-500 hover:text-gray-900" onClick={handleShowMore}>
                  Show more
                </button>
              )}
            </div>
          </div>

          {hasWorkflowData && (
            <div className="flex flex-row gap-2.5 w-full mt-3">
              <VultronAvatar size={26} isSquare />
              <div className="flex flex-col text-sm gap-3 flex-1 min-w-0">
                <div className="markdown-block">
                  <MarkdownEditor content={lastItem.instruction} error={false} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ResponsesWithSources;

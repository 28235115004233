import { Node } from "@tiptap/core";
import type { NodeViewProps } from "@tiptap/react";
import { NodeViewWrapper, ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaFetch } from "./hooks";

function ContentLibraryImageComponent({ node, updateAttributes }: NodeViewProps) {
  const { fetchFiles } = useMediaFetch();
  const { mediaUrls } = useSelector((state: any) => state.yjsEditor);
  const [isResizing, setIsResizing] = useState(false);
  const [startWidth, setStartWidth] = useState(0);
  const [startX, setStartX] = useState(0);

  useEffect(() => {
    if (node.attrs.id) {
      fetchFiles([node.attrs.id]);
    }
  }, [node.attrs.id]);

  const startResize = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsResizing(true);
    setStartX(e.clientX);
    setStartWidth(node.attrs.width || 0);
  };

  const handleResize = useCallback(
    (e: MouseEvent) => {
      if (!isResizing) return;
      const deltaX = e.clientX - startX;
      const newWidth = Math.max(100, startWidth + deltaX);
      updateAttributes({ width: newWidth });
    },
    [isResizing, startX, startWidth, updateAttributes],
  );

  const stopResize = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleResize);
      window.addEventListener("mouseup", stopResize);
      return () => {
        window.removeEventListener("mousemove", handleResize);
        window.removeEventListener("mouseup", stopResize);
      };
    }
  }, [isResizing, handleResize, stopResize]);

  return (
    <NodeViewWrapper className="resizable-image-container">
      {mediaUrls[node.attrs.id] && (
        <>
          <img
            className="tiptap-image"
            src={mediaUrls[node.attrs.id]}
            style={{ width: node.attrs.width ? `${node.attrs.width}px` : "auto" }}
            onLoad={(e) => {
              const img = e.target as HTMLImageElement;
              if (!node.attrs.width) {
                updateAttributes({
                  width: img.naturalWidth,
                  height: img.naturalHeight,
                });
              }
            }}
          />
          <div className="resize-handle" onMouseDown={startResize} />
        </>
      )}
    </NodeViewWrapper>
  );
}

export const ContentLibraryImage = Node.create({
  name: "cimg",
  group: "block",

  addAttributes() {
    return {
      id: {
        default: "",
      },
      width: {
        default: null,
        parseHTML: (element) => element.style.width?.replace("px", ""),
        renderHTML: (attributes) => ({
          style: `width: ${attributes.width}px`,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "cimg",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["cimg", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ContentLibraryImageComponent);
  },
});

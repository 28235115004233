/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import type { Workflow } from "pages/ai-assistant/constants";
import { useGlobalWorkflowActions } from "../useGlobalWorkflowActions";
import TypeBadge from "../TypeBadge";
import { Star } from "lucide-react";
import Tooltip from "components/atoms/tooltip";
import tw from "twin.macro";

interface WorkflowCardProps {
  workflow: Workflow;
  favoriteWorkflows: string[];
  handleFavoriteWorkflow: (workflow: string, workflow_subtype?: string | null) => Promise<void>;
  handleUnfavoriteWorkflow: (workflow: string, workflow_subtype?: string | null) => Promise<void>;
}

const WorkflowCard: React.FC<WorkflowCardProps> = ({
  workflow,
  favoriteWorkflows,
  handleFavoriteWorkflow,
  handleUnfavoriteWorkflow,
}) => {
  const { createWorkflow } = useGlobalWorkflowActions();
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [isStarHovered, setIsStarHovered] = useState(false);

  const handleClick = () => {
    createWorkflow(workflow.name, workflow.subType, workflow.label);
  };

  const isFavorite = favoriteWorkflows.includes(workflow.subType);

  const handleFavorite = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isFavorite) {
      handleUnfavoriteWorkflow(workflow.name, workflow.subType);
    } else {
      handleFavoriteWorkflow(workflow.name, workflow.subType);
    }
  };

  return (
    <button
      onClick={handleClick}
      onMouseEnter={() => setIsCardHovered(true)}
      onMouseLeave={() => setIsCardHovered(false)}
      className="flex flex-col gap-2 text-left p-4 bg-white border border-gray-200 rounded-md shadow-sm transition-all"
      css={[isCardHovered && !isStarHovered && tw`border-black shadow-[inset_0_0_0_0.5px_black]`]}
    >
      <div className="flex items-start justify-between gap-3">
        <div className="font-medium text-lg leading-[1.3] flex-1">{workflow.label}</div>
        <Tooltip content={isFavorite ? "Remove from favorites" : "Add to favorites"}>
          <button
            onClick={handleFavorite}
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsStarHovered(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsStarHovered(false);
            }}
            className="flex-shrink-0 transition-all p-1 hover:bg-gray-200 rounded-md"
          >
            {isFavorite ? <Star size={14} fill="currentColor" /> : <Star size={14} className="text-gray-400" />}
          </button>
        </Tooltip>
      </div>
      <div className="text-gray-500 text-sm mb-2">{workflow.description}</div>
      <div className="flex flex-wrap gap-2 mt-auto">
        <TypeBadge type={workflow.type} />
      </div>
    </button>
  );
};

export default WorkflowCard;

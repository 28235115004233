import type { Editor } from "@tiptap/react";
import { useCallback, useState, useEffect } from "react";
import { LineHeightIcon } from "components/yjs-editor/icons/LineHeight";
import { Select } from "../primitives/Select";
import { DEFAULT_LINE_SPACING, LINE_SPACING_OPTIONS } from "./constants";
import { ChevronDown } from "lucide-react";
import { ToolbarTooltip } from "../primitives/Tooltip";

type LineSpacingValue = (typeof LINE_SPACING_OPTIONS)[number]["value"] | "mixed";

export const ToolbarLineSpacing = ({ editor }: { editor: Editor }) => {
  const [currentSpacing, setCurrentSpacing] = useState<LineSpacingValue>(DEFAULT_LINE_SPACING);

  const getLineSpacing = useCallback((): LineSpacingValue => {
    let lineHeight: LineSpacingValue | null = null;

    editor.state.doc.nodesBetween(editor.state.selection.from, editor.state.selection.to, (node) => {
      if (node.type.name === "paragraph" || node.type.name === "heading") {
        const currentValue = node.attrs.lineHeight || DEFAULT_LINE_SPACING;
        if (lineHeight === null) {
          lineHeight = currentValue as LineSpacingValue;
        } else if (lineHeight !== currentValue) {
          lineHeight = "mixed";
        }
      }
    });
    return lineHeight || DEFAULT_LINE_SPACING;
  }, [editor]);

  const onLineSpacingChange = useCallback(
    (value: LineSpacingValue) => {
      if (!editor) return;
      editor.chain().focus().setLineSpacing(value).run();
    },
    [editor],
  );

  // Update line spacing value when selection changes
  useEffect(() => {
    if (!editor) return;
    const updateLineSpacing = () => {
      setCurrentSpacing(getLineSpacing());
    };
    // Initial value
    updateLineSpacing();
    // Listen for selection changes
    editor.on("selectionUpdate", updateLineSpacing);
    return () => {
      editor.off("selectionUpdate", updateLineSpacing);
    };
  }, [editor, getLineSpacing]);

  return (
    <ToolbarTooltip content="Line spacing">
      <div>
        <Select
          variant="subtle"
          value={currentSpacing}
          initialValue={DEFAULT_LINE_SPACING}
          items={LINE_SPACING_OPTIONS}
          onChange={onLineSpacingChange}
          customTrigger={
            <div className="flex items-center gap-1">
              <LineHeightIcon />
              <ChevronDown size={12} />
            </div>
          }
        />
      </div>
    </ToolbarTooltip>
  );
};

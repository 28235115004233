/** @jsxImportSource @emotion/react */

import type { Dispatch } from "react";
import { useEffect, useRef, useState } from "react";
import type { Comment } from "../types";
import { CommentEditor } from "./CommentEditor/CommentEditor";
import { useAppSelector } from "store/storeTypes";
import { useCommentOperations } from "../../../api/comments/useCommentOperations";
import Avatar, { VultronAvatar } from "components/molecules/avatar";
import { ReactionBar } from "./ReactionBar";
import CommentOptions from "./CommentOptions";
import copyText from "utils/copyText";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import Tooltip from "components/atoms/tooltip/Tooltip";
import type { useEditor } from "@tiptap/react";
import { Button } from "components/yjs-editor/primitives/Button";
import { useAutoScroll } from "./useAutoScroll";
import { AssignMenu } from "./AssignMenu";
import { getThreadNotificationBaseUrl, getCurrentTab } from "../../../api/utils";
import { extractMentionedUserIds } from "../utils";
import { ArrowRight, LoaderCircle } from "lucide-react";
import { useTriggerFeedback } from "./hooks";

interface CommentViewProps {
  comment: Comment;
  isEditing: boolean;
  internalContractId: string;
  isLastComment?: boolean;
  threadId?: string;
  quoteText?: string;
  referenceId?: string;
  isActive?: boolean;
  setDisableScroll: React.Dispatch<React.SetStateAction<boolean>>;
  resolved?: boolean;
  setEditingCommentId: Dispatch<React.SetStateAction<string | null>>;
  showCommentOptions?: boolean;
  assignedToUser?: string;
  assignedToUserId?: string;
}

export const CommentView = ({
  comment,
  internalContractId,
  referenceId,
  threadId,
  isActive,
  setDisableScroll,
  isEditing,
  quoteText,
  setEditingCommentId,
  showCommentOptions,
  assignedToUser,
  assignedToUserId,
}: CommentViewProps) => {
  const [readonly, setReadonly] = useState(true);
  const [commentEditor, setCommentEditor] = useState<ReturnType<typeof useEditor> | null>(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const [assignMenuOpen, setAssignMenuOpen] = useState(false);

  const { handleApplyFeedback, isSubmittingFeedback } = useTriggerFeedback(comment.content, quoteText);
  const formatCommentDate = (createdAt: Date): string => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInMs = now.getTime() - createdDate.getTime(); // Difference in milliseconds

    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

    if (diffInMinutes === 0) {
      return "Just now";
    }
    if (diffInMinutes < 60) {
      return `${diffInMinutes}m`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h`;
    } else {
      const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric" };
      return createdDate.toLocaleDateString(undefined, options);
    }
  };

  const currentUser = useAppSelector((store) => store.auth.currentUser);
  const { editCommentMutation, deleteCommentMutation, updateReactionMutation, updateThreadAssigneeMutation } =
    useCommentOperations(internalContractId, referenceId);
  const commentRef = useRef<HTMLDivElement>(null);
  const hasReactions = Object.keys(comment.reactions).length > 0;
  const isUserVultron = comment.commenter.is_vultron;
  const isCommenter = currentUser?.id === comment.commenter.id;

  useAutoScroll(commentRef, isActive);

  const handleReaction = (emoji: string) => {
    const userReactions = comment.reactions[currentUser?.id || ""];
    const remove = userReactions && userReactions.includes(emoji);
    updateReactionMutation.mutate({ comment_id: comment.id, emoji, remove });
  };

  const normalizeTagLabel = (tag: string) => {
    if (tag === "outcomes_benefits") return "Outcomes & Benefits";
    return tag
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const truncatedReviewTags =
    !!comment.review_tags?.length && comment.review_tags?.length > 2
      ? normalizeTagLabel(
          comment.review_tags
            ?.slice(2, comment.review_tags?.length)
            .map((tag) => normalizeTagLabel(tag))
            .join(", "),
        )
      : "";
  const isReviewEngineComment = !!comment.review_tags?.length && isUserVultron;

  useEffect(() => setReadonly(!isEditing), [isEditing]);

  const handleAssign = (userId: string | null, username: string | null, sendEmail = true) => {
    if (threadId) {
      updateThreadAssigneeMutation.mutate({
        thread_id: threadId,
        assignee_id: userId,
        assignee_username: username,
        base_url: getThreadNotificationBaseUrl(),
        tab: getCurrentTab(),
        send_email: sendEmail,
      });
    }
    setAssignMenuOpen(false);
  };

  const handleAddComment = (content: string, mentions: string[]) => {
    editCommentMutation.mutate({
      content,
      comment_id: comment.id,
      mentions,
      base_url: getThreadNotificationBaseUrl(),
      tab: getCurrentTab(),
    });
    setReadonly(true);
    setEditingCommentId(null);
  };

  const handleSaveEdit = () => {
    if (commentEditor && !commentEditor.isEmpty) {
      const content = commentEditor.getHTML();
      // Extract mentioned user IDs from the document
      const mentions = commentEditor.state.doc ? extractMentionedUserIds(commentEditor.state.doc) : [];

      editCommentMutation.mutate({
        content,
        comment_id: comment.id,
        mentions,
        base_url: getThreadNotificationBaseUrl(),
        tab: getCurrentTab(),
      });
      setReadonly(true);
      setEditingCommentId(null);
    }
  };

  const handleCancelEdit = () => {
    setReadonly(true);
    setEditingCommentId(null);
    commentEditor?.commands.setContent(comment.content || "");
  };

  return (
    <div className="flex w-full flex-col gap-2 group/comment" ref={commentRef}>
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 flex-1 min-w-0">
            {isUserVultron ? (
              <VultronAvatar size={22} />
            ) : (
              <Avatar size={22} textColor={"#FFFFFF"} name={comment.commenter.username} id={comment.commenter.id} />
            )}
            <div className="flex flex-row items-baseline gap-1.5 ml-[2px] flex-1 min-w-0">
              <div className="text-gray-darkest font-medium text-sm truncate">
                {isUserVultron ? "Vultron" : comment.commenter.username}
              </div>
            </div>
          </div>
          {(showCommentOptions || !hasReactions) && (
            <div
              className="flex flex-row gap-0.5 self-start p-1 h-4 items-center justify-center opacity-0 group-hover/comment:opacity-100"
              onFocus={(e) => e.stopPropagation()}
              data-interactive="true"
            >
              {!hasReactions && showCommentOptions && (
                <ReactionBar
                  reactions={comment.reactions}
                  onReaction={handleReaction}
                  setDisableScroll={setDisableScroll}
                />
              )}
              {showCommentOptions && (
                <CommentOptions
                  setDisableScroll={setDisableScroll}
                  isCommenter={currentUser?.id === comment.commenter.id}
                  onEdit={() => {
                    setEditingCommentId(comment.id);
                  }}
                  onDelete={() => {
                    triggerConfirm({
                      header: `Are you sure you want to delete this comment?`,
                      body: "This action is irreversible and will delete this reply from the thread.",
                    }).then((proceed) => {
                      if (proceed) {
                        deleteCommentMutation.mutate({ comment_id: comment.id });
                      }
                    });
                  }}
                  onCopyLink={() => {
                    if (!threadId) return;
                    const url = new URL(window.location.href);
                    url.searchParams.set("threadId", threadId);
                    url.searchParams.set("commentId", comment.id);
                    copyText(url.href);
                  }}
                />
              )}
            </div>
          )}
        </div>
        {!!comment?.review_tags?.length && (
          <div className="flex flex-wrap items-center gap-1 flex-shrink-0">
            {comment?.review_tags?.slice(0, 2).map((tag) => (
              <div
                key={tag}
                className="text-xs bg-[#FFFBED] text-[#D9940B] border border-[#D9940B] px-1.5 py-0.5 rounded"
              >
                {normalizeTagLabel(tag)}
              </div>
            ))}
            {!!comment?.review_tags?.length && comment.review_tags.length > 2 && (
              <Tooltip content={truncatedReviewTags}>
                <div className="text-xs bg-[#F6F6F6] text-[#7A7F84] border border-[#7A7F84] px-1.5 py-0.5 rounded">
                  +{comment.review_tags.length - 2}
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <div className="pb-1 flex flex-col gap-y-1">
        <CommentEditor
          readonly={readonly && !isEditing}
          content={comment.content}
          onAddComment={handleAddComment}
          editorRef={setCommentEditor}
          onUpdate={setIsEmpty}
        />
        {assignedToUser && (
          <div className="text-xs text-[#4B5563]">
            <span className="font-normal">Assigned to </span>
            <span
              className={`font-medium text-[#3B82F6] ${isCommenter ? "cursor-pointer hover:underline" : ""}`}
              onClick={
                isCommenter
                  ? (e) => {
                      e.stopPropagation();
                      setAssignMenuOpen(true);
                    }
                  : undefined
              }
              data-interactive="true"
              onFocus={(e) => e.stopPropagation()}
            >
              {assignedToUser}
            </span>
          </div>
        )}
        <div className="text-gray-light text-xs">{formatCommentDate(comment.updated_at)}</div>
        {!readonly && isEditing && (
          <div className="flex justify-end gap-1.5">
            <Button variant="secondary" onClick={handleCancelEdit} style={{ height: "1.75rem" }}>
              Cancel
            </Button>
            <Button variant="thread" onClick={handleSaveEdit} disabled={isEmpty}>
              Save
            </Button>
          </div>
        )}
        {hasReactions && (
          // if there are reactions, move the reaction button next to the reactions
          <div data-interactive="true">
            <ReactionBar
              reactions={comment.reactions}
              onReaction={handleReaction}
              setDisableScroll={setDisableScroll}
            />
          </div>
        )}
        {!isEditing && isReviewEngineComment && (
          <button
            onClick={handleApplyFeedback}
            className="group/comment-feedback w-fit flex items-center gap-x-1 text-xs my-1"
          >
            <span className="font-medium group-hover/comment-feedback:underline underline-offset-2">
              Apply Feedback
            </span>

            {isSubmittingFeedback ? (
              <LoaderCircle className="animate-spin" size={14} />
            ) : (
              <ArrowRight size={15} className="duration-200 group-hover/comment-feedback:translate-x-1" />
            )}
          </button>
        )}
      </div>
      <AssignMenu
        open={assignMenuOpen}
        onClose={() => setAssignMenuOpen(false)}
        onAssign={handleAssign}
        currentAssigneeId={assignedToUserId}
      />
    </div>
  );
};

import { MILLISECONDS_IN_A_DAY } from "const-values/Time";
import { useEffect } from "react";
import type PersistedStorageKeys from "types/PersistedStorage";
import * as logger from "utils/log";

type ExpirationConfig = {
  [K in keyof PersistedStorageKeys]: {
    expiration: number; // Expiration time in days
    expirationCallback: (key: string, value: PersistedStorageKeys[K], expiration: number) => void;
  };
};

const EXPIRATION_CONFIG: Partial<ExpirationConfig> = {
  outlineGeneratedBannerIgnoredState: {
    expiration: 60,
    expirationCallback: (key, value, expirationMs) => {
      const newValue = { ...value };
      const now = Date.now();
      Object.entries(value).forEach(([key, val]) => {
        const isExpired = now - val.timestamp > expirationMs;

        if (isExpired) delete newValue[key];
      });

      localStorage.setItem(key, JSON.stringify(newValue));
    },
  },
};

const useCleanupLocalStorage = () => {
  useEffect(() => {
    Object.keys(EXPIRATION_CONFIG).forEach((key) => {
      const typedKey: keyof PersistedStorageKeys = key as keyof PersistedStorageKeys;
      const config = EXPIRATION_CONFIG[typedKey];

      const storedData = localStorage.getItem(typedKey);
      if (!storedData || !config) return;

      try {
        const parsedData: PersistedStorageKeys[typeof typedKey] = JSON.parse(storedData);
        config.expirationCallback(typedKey, parsedData, config.expiration * MILLISECONDS_IN_A_DAY);
      } catch (error) {
        logger.error(error as Error, `Failed to clean up localStorage key: ${typedKey}`);
      }
    });
  }, []);
};

export default useCleanupLocalStorage;

/** @jsxImportSource @emotion/react */

import Popover from "components/atoms/popover";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { useState } from "react";
import { Archive, Ellipsis } from "lucide-react";
import tw from "twin.macro";
import type { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { useArchivedRowActions } from "./hooks";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";

type Props<T> = {
  archivedSessions: ToImmutable<T>[];
  onUnarchiveSession: (sessionId: string) => void;
  onDeleteArchivedSession: (sessionId: string) => void;
  containerRef?: HTMLElement | null;
};

const AssistantArchiveMenu = <T extends AIAssistantSession | WritingAssistantSession>({
  archivedSessions,
  containerRef,
  ...archivedRowActions
}: Props<T>) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="px-3 py-3.5">
      <Popover
        onOpenChange={setOpen}
        open={open}
        content={
          <div className="flex flex-col p-1 w-full">
            {archivedSessions.map((session) => (
              <ArchivedRow key={session.id} session={session} {...archivedRowActions} />
            ))}
            {!archivedSessions.length && (
              <div className="text-gray-500 text-sm w-full h-full py-8 px-4 text-center">No archived sessions</div>
            )}
          </div>
        }
        portalProps={{ container: containerRef }}
        contentProps={{ side: "top", align: "start", css: tw`w-full max-w-[435px] mx-0` }}
      >
        <div
          className="cursor-pointer select-none flex items-center gap-2 w-full text-sm rounded-lg py-3 px-2.5 text-slate-700 font-medium duration-150 hover:bg-layout-gray-light-hover"
          css={[open && tw`bg-layout-gray-light-hover`]}
        >
          <Archive size={18} /> Archived
          <div className="text-layout-gray-light text-xxs min-w-[18px] h-[18px] flex items-center justify-center bg-slate-500 rounded-full font-medium">
            {archivedSessions?.length}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default AssistantArchiveMenu;

const ArchivedRow = <T extends AIAssistantSession | WritingAssistantSession>({
  session,
  onUnarchiveSession,
  onDeleteArchivedSession,
}: {
  session: ToImmutable<T>;
} & Pick<Props<T>, "onDeleteArchivedSession" | "onUnarchiveSession">) => {
  const actionItems = useArchivedRowActions({ session, onUnarchiveSession, onDeleteArchivedSession });

  return (
    <div
      title={session.name}
      className="group py-2 pr-3 pl-2 relative grow overflow-hidden whitespace-nowrap text-sm text-slate-700"
    >
      {session.name}
      <div className="absolute bottom-0 right-0 top-0 to-transparent from-white bg-gradient-to-l w-8 group-hover:w-14 group-hover:from-50% group-hover:from-white" />
      <div className="opacity-0 text-stone-900 flex items-center gap-1 absolute bottom-0 right-0 top-0 pl-1 pr-2 bg-white group-hover:opacity-100">
        <DropdownMenu
          contentProps={{
            align: "start",
            side: "bottom",
            css: tw`z-[100]`,
          }}
          items={actionItems}
        >
          <div className="text-gray-600 duration-150 hover:text-black">
            <Ellipsis size={18} />
          </div>
        </DropdownMenu>
      </div>
    </div>
  );
};

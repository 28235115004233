import { updateInternalContractDetails } from "api/api";
import { useEffect, useRef, useState, useMemo } from "react";
import { copyToClipBoard } from "utils/helpers";
import show from "../../../Assets/show.svg";
import hide from "../../../Assets/hide.svg";
import copy from "../../../Assets/copy.svg";
import edit from "../../../Assets/edit-2.png";
import { useNotification } from "context/notificationContext";
import type { Project } from "types/Project";
import * as logger from "utils/log";

interface Client {
  client_name: string;
  client_address: string;
  client_poc_name: string;
  client_email?: string;
  client_phone_number: string;
}

interface PointOfContactProps {
  internalContractDetails: Project;
  initialState?: boolean;
}

interface References {
  client_poc_name: React.RefObject<HTMLTextAreaElement>;
  client_email: React.RefObject<HTMLTextAreaElement>;
}

const PointOfContact: React.FC<PointOfContactProps> = ({ internalContractDetails, initialState }) => {
  const { setToast } = useNotification();

  const clientPOCNameTARef = useRef<HTMLTextAreaElement>(null);
  const clientEmailTARef = useRef<HTMLTextAreaElement>(null);

  const references: References = useMemo(
    () => ({
      client_poc_name: clientPOCNameTARef,
      client_email: clientEmailTARef,
    }),
    [],
  );

  const [pocEditable, setPOCEditable] = useState<boolean>(false);
  const [pocEmailEditable, setPOCEmailEditable] = useState<boolean>(false);

  const [activeTextArea, setActiveTextArea] = useState<keyof References | "">("");

  const [client, setClient] = useState<Client>({
    client_name: "",
    client_address: "",
    client_poc_name: "",
    client_email: "",
    client_phone_number: "",
  });

  useEffect(() => {
    const internalCDetails = JSON.parse(JSON.stringify(internalContractDetails));
    setClient((pS) => ({
      ...pS,
      client_poc_name: internalCDetails?.internal_contract?.client_poc_name,
      client_email: internalContractDetails?.internal_contract?.client_email,
    }));
  }, [internalContractDetails]);

  const adjustTextAreaHeight = (ref: React.RefObject<HTMLTextAreaElement>, noFocus?: boolean): void => {
    const textArea = ref?.current;
    if (textArea) {
      if (!noFocus) {
        textArea.focus();
        textArea.setSelectionRange(textArea.value.length, textArea.value.length);
      }

      textArea.style.height = textArea.scrollHeight + "px";
    }
  };

  useEffect(() => {
    if (client && activeTextArea) adjustTextAreaHeight(references[activeTextArea], true);
  }, [client, activeTextArea, references]);

  useEffect(() => {
    if (activeTextArea) adjustTextAreaHeight(references[activeTextArea]);
  }, [activeTextArea, references]);

  const copyText = (txt: string): void => {
    copyToClipBoard(txt).then(() => {
      setToast.success({
        msg: "Copied to clipboard",
      });
    });
  };

  const changeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { name, value } = e.target;
    setClient((pS) => ({
      ...pS,
      [name]: value,
    }));
  };

  const updateClient = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    setPOCEditable(false);
    setPOCEmailEditable(false);
    setActiveTextArea("");

    try {
      const trimmedClient = Object.keys(client).reduce<Client>((acc, key) => {
        const k = key as keyof Client;
        return { ...acc, [k]: client[k]?.trim() || "" };
      }, {} as Client);

      setClient(trimmedClient);
      await updateInternalContractDetails(internalContractDetails?.internal_contract?.id, {
        ...trimmedClient,
      });
    } catch (err) {
      logger.error(err as Error);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      updateClient(event);
    }
  };

  const [open, setOpen] = useState<boolean>(initialState || false);

  return (
    <div className="border border-[#D8DDE2] shadow-sm p-4 pb-2 pl-2 rounded-lg mb-4 bg-white">
      <div
        onClick={() => setOpen(!open)}
        role="button"
        className="flex items-center p-0 pl-1 justify-between cursor-pointer select-none"
      >
        <div className="flex gap-1">
          <div className="text-base font-medium">Point of Contact</div>
        </div>
        <span className="">
          <img className={open ? "" : "hidden"} src={hide} alt="icon" />
          <img className={open ? "hidden" : ""} src={show} alt="icon" />
        </span>
      </div>
      <div
        className={`flex flex-col gap-4 pl-2 transition-all duration-300 ease-linear ${
          open ? "pt-4" : "pt-2 opacity-0 h-0 overflow-hidden"
        }`}
      >
        <span id="estmSpan" style={{ display: "none" }}></span>
        <form onSubmit={updateClient} className="w-full m-0 p-0">
          <div className="flex flex-col gap-1">
            <span className="text-[#5B6B79] text-[14px] font-medium">Contact Name</span>
            <div className="pb-2 flex flex-col gap-[4px]">
              {pocEditable ? (
                <textarea
                  value={client?.client_poc_name}
                  name="client_poc_name"
                  onChange={changeHandler}
                  className="w-full outline-none h-[21px] no-scrollbar text-[14px] p-0 m-0 resize-none border-none  break-words "
                  autoFocus
                  style={{ lineHeight: "20px" }}
                  onBlur={updateClient}
                  ref={clientPOCNameTARef}
                  onKeyDown={handleKeyDown}
                />
              ) : (
                <span className="text-sm group pb-2">
                  {client?.client_poc_name || "-"}

                  <img
                    src={edit}
                    alt=""
                    className="hidden group-hover:inline cursor-pointer mb-[2px] ml-[10px] w-[14px] h-[14px]"
                    onClick={() => {
                      setPOCEditable(true);
                      setActiveTextArea("client_poc_name");
                    }}
                  />
                </span>
              )}
              <span className="text-[#5B6B79] text-[14px] font-medium">Contact Email</span>
              {pocEmailEditable ? (
                <textarea
                  value={client?.client_email}
                  name="client_email"
                  onChange={changeHandler}
                  className="w-full outline-none h-[21px] no-scrollbar text-[14px] p-0 m-0 resize-none border-none  break-words "
                  autoFocus
                  style={{ lineHeight: "20px" }}
                  onBlur={updateClient}
                  ref={clientEmailTARef}
                  onKeyDown={handleKeyDown}
                />
              ) : (
                <span className="text-sm text-action group">
                  {client?.client_email || "-"}
                  {client?.client_email && (
                    <img
                      src={copy}
                      alt=""
                      className="cursor-pointer  select-none inline-block ml-[12px]"
                      onClick={() => copyText(client?.client_email || "")}
                    />
                  )}
                  <img
                    src={edit}
                    alt=""
                    className="hidden group-hover:inline-block cursor-pointer ml-[10px] w-[14px] h-[14px]"
                    onClick={() => {
                      setPOCEmailEditable(true);
                      setActiveTextArea("client_email");
                    }}
                  />
                </span>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PointOfContact;

/** @jsxImportSource @emotion/react */

import { X } from "lucide-react";
import type { Dispatch, SetStateAction } from "react";
import { memo } from "react";
import { useAppDispatch } from "store/storeTypes";
import {
  setFilteredRequirements,
  setHighlightedElementId,
  setRequirementFilters,
  setSearchQuery,
} from "store/reducers/extract/CurrentExtractionReducer";
import { DEFAULT_FILTERS } from "../document-sidebar/requirements-filter/constants";

interface DocumentSearchClearButtonProps {
  setUserIsSearching: Dispatch<SetStateAction<boolean>>;
  cleanUpFilteredRequirements: () => void;
}

const DocumentSearchClearButton = ({
  setUserIsSearching,
  cleanUpFilteredRequirements,
}: DocumentSearchClearButtonProps) => {
  const dispatch = useAppDispatch();

  const clearSearch = () => {
    setUserIsSearching(false);
    cleanUpFilteredRequirements();
    dispatch(setSearchQuery(""));
  };

  return (
    <button
      className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 rounded-md hover:bg-gray-100"
      onClick={clearSearch}
    >
      <X size={16} />
    </button>
  );
};

export default memo(DocumentSearchClearButton);

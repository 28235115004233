import React from "react";
import type { NodeViewProps } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import InlineSourceFileButtons from "components/organisms/ResponsesWithSources/inline-source-file-buttons/InlineSourceFileButtons";
import type { SourceFile } from "types/Assistants/types";

interface InlineSourceButtonsAttrs {
  sourceFiles: SourceFile[];
}

const InlineSourceButtonsComponent: React.FC<NodeViewProps> = ({ node }) => (
  <NodeViewWrapper className="inline-block">
    <InlineSourceFileButtons sourceFiles={node.attrs.sourceFiles} />
  </NodeViewWrapper>
);

const InlineSourceButtons = Node.create<InlineSourceButtonsAttrs>({
  name: "inlineSourceButtons",
  inline: true,
  group: "inline",
  atom: true,

  addAttributes() {
    return {
      sourceFiles: { default: [] },
    };
  },

  parseHTML() {
    return [{ tag: "inline-source-buttons" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["inline-source-buttons", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(InlineSourceButtonsComponent);
  },
});

export default InlineSourceButtons;

import { useCallback } from "react";
// If you use React Query or your own version of `useMutation`, import it here
// import { useMutation } from "@tanstack/react-query";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { find, LiveList, LiveObject, update } from "YJSProvider/LiveObjects";
import { setAiAssistantState, setStreamingState } from "store/reducers/ai-assistant/aiAssistantReducer";
import { EventTracking } from "utils/constants";
import { apiUrl } from "config/vultronConfig";
import * as logger from "utils/log";
import { type ChatMessage } from "types/Chat";
import { StreamEvent } from "types/Streaming/streamConfig";
import type {
  AIAssistantSession,
  AIAssistantBlock,
  BlockSource,
  AIAssistant,
} from "utils/yjs-configs/ai-assistant/schema";
import { AssistantBlockType } from "utils/yjs-configs/ai-assistant/schema";
import { useLocalStorage } from "hook/useLocalStorage";
import type { Workflow, WorkflowLabel } from "pages/ai-assistant/constants";
import { createGlobalChatSession } from "api/api";
import { useTrackUserMetric } from "utils/metrics";
import useAIAssistantOperations from "hook/useAIAssistantOperations";
import { useMutation } from "utils/yjs-configs/ai-assistant/yjs.config";
import { DELIMITER, HEARTBEAT } from "const-values/Stream";

const controller = new AbortController();

export function useGlobalWorkflowActions() {
  const dispatch = useAppDispatch();
  const trackUserEvent = useTrackUserMetric();
  const { activeSession } = useAppSelector((state) => state.aiAssistantState);
  const { createSession } = useAIAssistantOperations();

  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0");

  const continueWorkflow = useMutation(
    async (
      { storage },
      { referenceId, chatSessionType }: { referenceId: string; chatSessionType: ChatMessage["session_type"] },
    ) => {
      if (!activeSession) {
        console.error("No active session available.");
        return;
      }

      const liveSessions = storage.get("ai_assistant")?.get("sessions") as AIAssistant["sessions"] | undefined;
      let liveSession: LiveObject<AIAssistantSession> | undefined;
      if (liveSessions) {
        liveSession = find(liveSessions, (session) => session.get("id") === activeSession.id);
      }
      if (!liveSession) {
        console.error("No matching liveSession found in storage.");
        return;
      }
      const myConversation = liveSession.get("conversation") as LiveList<LiveObject<AIAssistantBlock>> | undefined;

      if (!myConversation || myConversation.length === 0) {
        console.error("No conversation found in the session.");
        return;
      }

      let lastVultronBlock: LiveObject<AIAssistantBlock> | undefined;
      for (let i = myConversation.length - 1; i >= 0; i--) {
        const block = myConversation.get(i);
        if (block.get("type") === AssistantBlockType.VultronBlock) {
          lastVultronBlock = block;
          break;
        }
      }

      if (!lastVultronBlock) {
        console.error("No Vultron block to update.");
        return;
      }

      let text = "";
      dispatch(
        setStreamingState({
          isStreamingInProgress: true,
          blockId: lastVultronBlock.get("id"),
        }),
      );

      fetchEventSource(`${apiUrl}/chat/sessions/${referenceId}/messages/continue`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Workspace: `Workspace ${workspace_id}`,
          Authorization: `Bearer ${localValue}`,
          "X-Authorization-Auth0": JSON.stringify(use_auth0),
          Accept: "application/json",
        },
        body: JSON.stringify({ chat_session_type: chatSessionType }),
        openWhenHidden: true,

        onmessage(msg) {
          if (msg.data === HEARTBEAT) return;

          if (msg.event === StreamEvent.StreamRestart) {
            text = msg.data || "";
            dispatch(setStreamingState({ streamCopy: text }));
            update(lastVultronBlock, { body: text, error: false });
            return;
          }

          if (msg.event === StreamEvent.StreamStop) {
            controller.abort();
            dispatch(setStreamingState({}));
            return;
          }

          if (msg.data?.length) {
            if (!text) liveSession?.set("updated_at", new Date().toISOString());

            try {
              const parsed = JSON.parse(msg.data);
              if (typeof parsed !== "object" && !parsed?.sources) throw new Error("error");
              const sources = (parsed.sources as BlockSource[]).map((source) => {
                const patchedSource = { ...source, date: source.date || new Date().toISOString() };
                return new LiveObject(patchedSource);
              });
              lastVultronBlock.set("sources", new LiveList(sources));
            } catch {
              if (msg.data !== DELIMITER) {
                text += msg.data;
                dispatch(setStreamingState({ streamCopy: text }));
              }
            }
          } else if (typeof msg.data === "string") {
            // Needed for markdown rendering
            text += "\n";
            dispatch(setStreamingState({ streamCopy: text }));
            lastVultronBlock.set("body", text);
          }
        },

        onerror(error) {
          logger.error(error, "Error continuing workflow");
          lastVultronBlock?.set("error", true);
          dispatch(setStreamingState({}));
        },
        onclose() {
          lastVultronBlock?.set("body", text);
          dispatch(setStreamingState({}));
        },
      });
    },
    [activeSession, dispatch],
  );

  const createWorkflow = useCallback(
    async (workflow: Workflow["name"], subType: Workflow["subType"], label: WorkflowLabel) => {
      // @ts-expect-error
      const normalizedSubType = workflow === subType ? undefined : subType;
      try {
        trackUserEvent(EventTracking.WORKFLOW_INITIATED, {
          session_id: activeSession?.id,
          workflow_type: workflow,
          workflow_subtype: normalizedSubType,
        });

        const newSession = await createGlobalChatSession(label, workflow, normalizedSubType);
        createSession(label, newSession.data.id, workflow, normalizedSubType);
      } catch (error) {
        logger.error(error as Error, "Error creating workflow");
      }
    },
    [activeSession?.id, createSession, trackUserEvent],
  );

  const endWorkflow = useCallback(() => {
    if (activeSession) {
      trackUserEvent(EventTracking.WORKFLOW_COMPLETED, {
        session_id: activeSession.id,
        workflow: activeSession.workflow,
        workflow_subtype: activeSession.workflow_subtype,
      });
      dispatch(
        setAiAssistantState({
          activeSession: { ...activeSession, workflowEnded: true },
        }),
      );
    }
  }, [activeSession, dispatch, trackUserEvent]);

  return {
    // Now continueWorkflow is a mutation, so you'll call continueWorkflow.mutate(...)
    // or continueWorkflow({ storage }, { referenceId, chatSessionType })
    continueWorkflow,
    createWorkflow,
    endWorkflow,
  };
}

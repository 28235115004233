/** @jsxImportSource @emotion/react */
import type { SourceGroup } from "components/molecules/ai-assistant-blocks/vultron-block/constants";
import { useRef } from "react";
import SourceGroupItem from "./SourceGroupItem";

type SourceGroupListProps = {
  data: SourceGroup[];
  expandContent: (groupIndex: number, previous: boolean) => Promise<void>;
  canExpandUp: boolean;
  canExpandDown: boolean;
  scrollOnResize: (container: HTMLDivElement, scrollOptions: ScrollToOptions) => void;
  highlightInitialContent?: boolean;
  isFullPage?: boolean;
};

const SourceGroupList = ({
  data,
  expandContent,
  canExpandUp,
  canExpandDown,
  scrollOnResize,
  highlightInitialContent = false,
  isFullPage = false,
}: SourceGroupListProps) => {
  const groupRefs = useRef<(HTMLDivElement | null)[]>([]);

  return (
    <div className="flex flex-col gap-4">
      {data.map((group, idx) => (
        <SourceGroupItem
          key={group.source_id}
          group={group}
          idx={idx}
          expandContent={expandContent}
          canExpandUp={canExpandUp}
          canExpandDown={canExpandDown}
          scrollOnResize={scrollOnResize}
          ref={(el) => (groupRefs.current[idx] = el)}
          highlightInitialContent={highlightInitialContent}
          isFullPage={isFullPage}
        />
      ))}
    </div>
  );
};

export default SourceGroupList;

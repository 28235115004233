import lod from "Assets/loader.svg";
import searchIcon from "Assets/search-normal.png";
import { Plus } from "lucide-react";
import CustomModal from "components/CustomModal";
import Document from "components/Drive/document/Document";
import Folder from "components/Drive/Folder";
import UploadDriveDocModal from "components/Drive/UploadDriveDocModal";
import ExtractingDocumentToast from "components/LoadingToast";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { fetchFileStorage, updateAllDocs, updateAllFolders } from "store/reducers/driveReducerSlice";
import { CloseIconBlack } from "utils/icons";
import MoveModal from "../MoveModal";
import { useDirectoryFileSearch } from "./hooks";
import NotFoundItems from "components/NotFoundItems";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { xor } from "lodash";
import Icon from "components/atoms/icons/Icon";
import { useContentLibraryFilterItems } from "components/molecules/content-drive-popover-content/hooks";
import { LEGACY_DOCUMENT_TYPES } from "pages/drive/documents/constants";
import { SelectedFilesToolbar } from "../toolbar/SelectedFilesToolbar";
import { type StateHistory, useFileOperations } from "./useFileOperations";
import { ContentLibraryPanel } from "../sidebar/ContentLibraryPanel";
import { useFolderOperations } from "./useFolderOperations";
import { useDragSelect } from "components/Drive/useDragSelect";
import { useOutletContext } from "react-router-dom";
import { DragAndDropToast } from "../DragAndDropToast";
import { CONTENT_LIBRARY } from "const-values/testIds";
import { useAppSelector } from "store/storeTypes";
import type { WorkspaceFile } from "types/FileStorage";
import type { Directory } from "api/types";

const DocumentsContent = () => {
  const dispatch = useDispatch();
  const {
    rootFiles: allDocs,
    rootSubdirectories: allFolders,
    isInitialFetch,
  } = useAppSelector((state) => state.drive.fileStorage);
  const {
    isSearching,
    searchResults,
    isSearchActive,
    setDocumentTypes,
    setSearchQuery,
    searchQuery,
    documentTypes,
    setUploaded,
    uploaded,
    folderSearchResults,
    throttleSearch,
  } = useDirectoryFileSearch();
  // @ts-expect-error types are incorrect scrollContainerRef exists and has this shape: { current: HTMLDivElement }
  const { scrollContainerRef } = useOutletContext();

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [currentFolders, setCurrentFolders] = useState<Directory["subdirectories"]>([]);
  const [currentDocs, setCurrentDocs] = useState<WorkspaceFile[]>([]);
  const [loadingToast, setLoadingToast] = useState({ loading: false, files: 1 });
  const [draggingFolderId, setDraggingFolderId] = useState("");
  const [draggingDocIds, setDraggingDocIds] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isDragOverDrive, setIsDragOverDrive] = useState(false);
  const [selectedFolderHeading, setSelectedFolderHeading] = useState("");
  const [stateHistory, setStateHistory] = useState<StateHistory>({
    type: null,
    data: [],
    itemInfo: {},
  });
  const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);
  const { typeItems, publishDateItemOptions } = useContentLibraryFilterItems({
    publishDatesConfig: { uploaded, onSelect: (val) => setUploaded(val === uploaded ? "" : val) },
    typesConfig: {
      documentTypes,
      onCheck: (val) => {
        if (Array.isArray(val)) {
          setDocumentTypes((prev) => xor(prev, val));
        } else {
          setDocumentTypes((prev) => xor(prev, [val]));
        }
      },
    },
  });
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<WorkspaceFile | null>(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const refreshResults = useCallback(() => {
    if (isSearchActive) {
      throttleSearch(true);
    } else {
      dispatch(fetchFileStorage());
    }
  }, [dispatch, isSearchActive, throttleSearch]);

  const {
    createDocument,
    updateDocument,
    isValidFile,
    moveDocument,
    deleteDocument,
    moveModalOpen,
    handleMoveModalOpen,
    handleCloseMoveModal,
    onMoveManually,
  } = useFileOperations(allDocs, allFolders, refreshResults, setStateHistory, setSelectedDocuments);

  const {
    folderModal,
    createFolder,
    updateFolder,
    deleteFolder: deleteSelectedDirectory,
    moveFolder: moveSelectedDirectory,
    openFolderModal,
    closeFolderModal,
    updateFolderName,
  } = useFolderOperations(allFolders, refreshResults, setStateHistory, {
    isRootLevel: true,
  });

  const handleDocumentSelect = useCallback((id: string, isSelected: boolean) => {
    setSelectedDocuments((prev) => (isSelected ? [...prev, id] : prev.filter((docId) => docId !== id)));
  }, []);

  useDragSelect({
    onSelect: handleDocumentSelect,
    selectedDocuments,
    scrollContainerRef,
  });

  const handleMoveSelected = useCallback(() => {
    handleMoveModalOpen(selectedDocuments, "doc");
  }, [selectedDocuments, handleMoveModalOpen]);

  const handleDeleteSelected = () => {
    if (selectedDocuments.length > 0) {
      deleteDocument(selectedDocuments);
    }
  };

  const handleDocumentClick = useCallback((doc: WorkspaceFile) => {
    setSelectedDocument(doc);
    setIsPanelOpen(true);
  }, []);

  const handleClosePanel = useCallback(() => {
    setSelectedDocument(null);
    setIsPanelOpen(false);
  }, []);

  const handleCreateDocument = useCallback(
    async (files: File[], _parentId: null, tags: string[]) => {
      if (!files.length) {
        setLoadingToast({ loading: false, files: 1 });
        return;
      }

      setLoadingToast({ loading: true, files: files.length });
      await createDocument(files, { tags });
      setShowUploadModal(false);
      setLoadingToast({ loading: false, files: 1 });
    },
    [createDocument],
  );

  useEffect(() => {
    // Check if allDocs and allFolders are empty before dispatching the action
    if (!allDocs.length && !allFolders.length) {
      dispatch(fetchFileStorage({ isInitialFetch: true }));
    }
  }, [dispatch, allDocs.length, allFolders.length]);

  useEffect(() => {
    if (isSearchActive) {
      setCurrentDocs(searchResults);
      setCurrentFolders(folderSearchResults);
    } else {
      setCurrentDocs(allDocs);
      setCurrentFolders(allFolders);
    }
  }, [allDocs, allFolders, folderSearchResults, isSearchActive, searchResults]);

  ///////////////////////////DRAG AND DROP FROM COMPUTER FUNCTIONALITY ////////////////////////////
  ///// function to handle files dropped from computer into drive
  function onMainDrop(acceptedFiles: File[]) {
    if (showUploadModal) return;
    setSelectedFolderHeading("");
    setIsDragOverDrive(false);
    const validFiles = acceptedFiles.filter((file) => isValidFile(file));
    if (validFiles.length) {
      createDocument(validFiles, { parentDirectoryId: null });
    }
  }

  ///// function to handle files dropped from computer into any folder
  function onFolderDrop(acceptedFiles: File[], targetFolderId: string) {
    setSelectedFolderHeading("");
    setIsDragOverDrive(false);
    const validFiles = acceptedFiles.filter((file) => isValidFile(file));
    createDocument(validFiles, { parentDirectoryId: targetFolderId });
  }

  const { getRootProps: getMainRootProps, getInputProps: getMainInputProps } = useDropzone({
    onDrop: onMainDrop,
    onDragOver: (e) => {
      e.preventDefault();
      if (showUploadModal) return;
      setIsDragging(true);
      setIsDragOverDrive(true);
    },
    onDragLeave: (e) => {
      const relatedTarget = e.relatedTarget;
      if (
        !relatedTarget ||
        (relatedTarget !== e.currentTarget &&
          !(relatedTarget instanceof Node && e.currentTarget.contains(relatedTarget)))
      ) {
        setIsDragOverDrive(false);
        setIsDragging(false);
      }
    },

    noClick: true,
  });

  // ////////////// setting up ctrl + z //////////
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Control" || event.key === "Meta") {
        setCtrlKeyPressed(true);
      }
      if (event.key === "z" && ctrlKeyPressed) {
        undo();
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Control" || event.key === "Meta") {
        setCtrlKeyPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [ctrlKeyPressed]);

  // /////// perform undo method for docs & folders ///////////////////
  const undo = async () => {
    if (!stateHistory?.type || !stateHistory?.itemInfo?.id) return;
    if (stateHistory?.type === "folder") {
      await moveSelectedDirectory(stateHistory.itemInfo.parent_directory, stateHistory.itemInfo.id, "prevent");
      dispatch(updateAllFolders(stateHistory?.data || []));
      setStateHistory({ type: null, data: [], itemInfo: {} });
    } else {
      await moveDocument(stateHistory.itemInfo.parent_directory, stateHistory.itemInfo.id, "prevent");
      dispatch(updateAllDocs(stateHistory?.data));
      refreshResults();
      setStateHistory({ type: null, data: [], itemInfo: {} });
    }
  };

  return (
    <div className={`relative flex-grow ${isPanelOpen ? "mr-[400px]" : ""}`} {...getMainRootProps()}>
      <input multiple {...getMainInputProps()} data-testid={CONTENT_LIBRARY.upload.fileInput} />
      {/* --------- DOTTED LINE APPEARS ON DRAG AND DROP ----------- */}
      <div
        className={`${
          (isDragOverDrive || selectedFolderHeading) &&
          !showUploadModal &&
          ((isDragging && !draggingFolderId && !draggingDocIds.length) || // Computer file drag
            (draggingFolderId &&
              currentFolders?.find((f) => f.id === draggingFolderId)?.id !== selectedFolderHeading) || // Folder drag
            draggingDocIds.length) // Document drag
            ? "border-[3px] border-dashed border-[#1E1E1E]"
            : ""
        } absolute w-full h-full pointer-events-none z-50`}
      />
      <div className="relative">
        {/* ---------------------------------- SECTION SEARCH -------------------------------- */}
        <section className="pt-2 pb-6 px-1">
          <div className="flex gap-3">
            <div className="flex-1 relative">
              <img src={searchIcon} alt="search icon" loading="lazy" className="absolute top-2.5 left-1.5 w-5" />

              <input
                placeholder="Search..."
                className="bg-white focus:outline-none block focus:ring-0 focus:ring-gray-200 text-sm text-gray-500 w-full h-10 py-3 pl-9 pr-3 rounded-md border border-gray-light"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={handleClosePanel}
              />
            </div>

            <div className="flex items-center gap-3 flex-wrap">
              <DropdownMenu multiselect items={typeItems}>
                <div className="rounded-md min-w-[140px] text-sm h-10 w-[140px] bg-white flex justify-between px-2 py-1 items-center gap-2 border border-gray-light text-slate-600">
                  <div className="truncate">
                    {documentTypes.length
                      ? documentTypes.filter((document) => !LEGACY_DOCUMENT_TYPES.includes(document)).join(", ")
                      : "Type"}
                  </div>
                  <Icon name="CarrotDown" className="min-w-3" />
                </div>
              </DropdownMenu>
              <DropdownMenu items={publishDateItemOptions}>
                <div className="rounded-md min-w-[140px] text-sm h-10 w-[140px] bg-white flex justify-between px-2 py-1 items-center gap-2 border border-gray-light text-slate-600">
                  <div className="truncate">{uploaded || "Uploaded"}</div>
                  <Icon name="CarrotDown" className="min-w-3" />
                </div>
              </DropdownMenu>
            </div>
          </div>
        </section>
        {/* ---------------------------- FOLDERS SECTION ------------------------------ */}
        <section className="folders-section min-h-[150px] px-1 pt-1">
          <div className="flex justify-between items-center mb-6">
            <h5 className="font-medium text-md">Folders</h5>
            <div
              className="flex items-center gap-1 cursor-pointer hover:bg-hover-default px-1.5 py-1 rounded-md bg-gray-100 hover:border-gray-300 border border-gray-200"
              onClick={() => openFolderModal()}
              data-testid={CONTENT_LIBRARY.folder.createButton}
            >
              <span className="text-sm font-semibold">Folder</span>
              <Plus size={14} />
            </div>
          </div>

          {isInitialFetch && !currentFolders.length ? (
            <div className="w-full flex justify-center flex-col py-4 px-2">
              <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
              <div className="flex-grow" />
            </div>
          ) : currentFolders.length ? (
            <div className="cards grid grid-cols-[repeat(auto-fill,_minmax(230px,_1fr))] gap-5 mb-8">
              {currentFolders.map((folder) => (
                <Folder
                  key={folder.id}
                  {...folder}
                  draggingFolderId={draggingFolderId}
                  setDraggingFolderId={setDraggingFolderId}
                  draggingDocIds={draggingDocIds}
                  setDraggingDocIds={setDraggingDocIds}
                  moveSelectedDirectory={moveSelectedDirectory}
                  handleDelete={deleteSelectedDirectory}
                  moveDocument={moveDocument}
                  setSelectedFolderHeading={setSelectedFolderHeading}
                  setIsDragOverDrive={setIsDragOverDrive}
                  selectedFolderHeading={selectedFolderHeading}
                  onFolderDrop={onFolderDrop}
                  handleMoveModalOpen={() => {
                    handleMoveModalOpen(folder.id, "folder");
                  }}
                  openFolderModal={openFolderModal}
                />
              ))}
            </div>
          ) : (
            !isSearchActive && (
              <div className="pb-10">
                <h5 className="text-base text-center font-semibold text-[#1D2630]">No Folders</h5>
                <p className="text-[#353535] text-center pt-[5px]">
                  Create a folder by clicking on the “Folder +” button.
                </p>
              </div>
            )
          )}

          <CustomModal isOpen={folderModal.isOpen} onClose={closeFolderModal}>
            <div className="p-5 w-[444px]">
              <div className="flex justify-between items-center mb-5">
                <h3 className="font-semibold text-xl">
                  {folderModal.selectedFolderId ? "Update Folder" : "New Folder"}
                </h3>
                <div className="w-[15.56px] h-[15.56px]">
                  <span className="cursor-pointer">
                    <CloseIconBlack onClick={closeFolderModal} />
                  </span>
                </div>
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (folderModal.selectedFolderId) {
                    updateFolder();
                  } else {
                    createFolder();
                  }
                }}
              >
                <label className="mb-2 block text-sm text-[#5B6B79]">Name</label>
                <input
                  type="text"
                  className="w-full rounded-md py-2 px-3 h-10 border-[1px] border-[#CFD1D4] focus:ring-gray-darkest focus:border-gray-darkest outline-none mb-7"
                  value={folderModal.folderName}
                  onChange={(e) => updateFolderName(e.target.value)}
                  autoFocus
                  required
                  data-testid={CONTENT_LIBRARY.folder.nameInput}
                />

                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    className="border-[1px] border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
                    onClick={closeFolderModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="border-0 bg-gray-darkest rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
                    data-testid={CONTENT_LIBRARY.folder.submitButton}
                  >
                    {folderModal.selectedFolderId ? "Update" : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </CustomModal>
        </section>
        {/* -------------------------- SECTION DOCUMENT -------------------------------- */}
        <section className="document-section px-1 relative mb-9">
          <div className="flex items-center justify-between mb-3">
            <div className="flex flex-col gap-3">
              <h5 className="font-medium text-md mb-4">Documents</h5>
            </div>
            {!isSearchActive && (
              <div
                onClick={() => setShowUploadModal(true)}
                className="flex items-center gap-1 rounded-md cursor-pointer px-1.5 py-1  bg-gray-100 hover:border-gray-300 border border-gray-200"
              >
                <span className="text-sm font-semibold">Document</span>
                <span className="relative block cursor-pointer">
                  <Plus size={14} />
                </span>
              </div>
            )}
          </div>

          {isInitialFetch || isSearching ? (
            <div className="w-full flex justify-center flex-col py-14 px-2">
              <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
              <div className="flex-grow" />
            </div>
          ) : (
            <>
              {!!currentDocs.length && (
                <div className="cards grid grid-cols-[repeat(auto-fill,_minmax(230px,_1fr))] gap-5">
                  {currentDocs.map((doc, i) => (
                    <Document
                      key={i + doc?.id}
                      docData={doc}
                      deleteDocument={deleteDocument}
                      setDraggingFolderId={setDraggingFolderId}
                      setDraggingDocIds={setDraggingDocIds}
                      handleMoveModalOpen={handleMoveModalOpen}
                      onSelect={handleDocumentSelect}
                      isSelected={selectedDocuments.includes(doc.id)}
                      selectedDocuments={selectedDocuments}
                      onDocumentClick={() => handleDocumentClick(doc)}
                      isDocumentPanelOpen={selectedDocument?.id === doc.id}
                    />
                  ))}
                </div>
              )}
              {/* -------------- CONTENT LIBRARY PANEL ---------------- */}
              {selectedDocument && (
                <ContentLibraryPanel
                  doc={selectedDocument}
                  isOpen={isPanelOpen}
                  onClose={handleClosePanel}
                  onUpdate={updateDocument}
                />
              )}
              {isSearchActive && !currentDocs.length && (
                <NotFoundItems
                  title="No documents found"
                  subTitle="No matching results. Try another search."
                  className={"w-full flex mt-24 justify-center items-center bg-inherit"}
                />
              )}
              {!isSearchActive && (
                <div className="mb-5">
                  <UploadDriveDocModal isDrive={!currentDocs.length} createDocument={handleCreateDocument} />
                </div>
              )}
            </>
          )}

          {/* ------------ UPLOAD files MODAL --------------- */}
          <UploadDriveDocModal
            showUploadModal={showUploadModal}
            setShowUploadModal={setShowUploadModal}
            createDocument={handleCreateDocument}
          />
        </section>
      </div>
      {/* ------------- Drag and Drop Toast -------------------- */}
      <DragAndDropToast
        isDragOverDrive={isDragOverDrive}
        selectedFolderHeading={selectedFolderHeading}
        draggingFolderId={draggingFolderId}
        draggingDocIds={draggingDocIds}
        currentFolders={currentFolders}
        hideToast={!!showUploadModal}
      />
      {/* ---------- Loading toast new ---------- */}
      {loadingToast?.loading && (
        <ExtractingDocumentToast
          loadingText={`Uploading ${loadingToast?.files > 1 ? "Documents" : "Document"}`}
          handleClose={() => setLoadingToast({ loading: false, files: 1 })}
        />
      )}
      {/* ------------ Move Modal --------------- */}
      <MoveModal
        selectedItems={moveModalOpen.items}
        handleCloseMoveModal={handleCloseMoveModal}
        onMove={onMoveManually}
        currentFiles={currentDocs}
        isMain={true}
      />
      {!selectedFolderHeading && selectedDocuments.length > 0 && (
        <SelectedFilesToolbar
          selectedCount={selectedDocuments.length}
          onClearSelection={() => setSelectedDocuments([])}
          onMove={handleMoveSelected}
          onDelete={handleDeleteSelected}
        />
      )}
    </div>
  );
};

export default DocumentsContent;

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type State = {
  open: boolean;
  assistantOpen: boolean;
  contentSearchOpen: boolean;
  requirementsOpen: boolean;
  requirementDetailsOpen: boolean;
  commentsOpen: boolean;
};

const initialState: State = {
  open: true,
  assistantOpen: true,
  requirementsOpen: false,
  contentSearchOpen: false,
  requirementDetailsOpen: false,
  commentsOpen: false,
};

const copilotDrawerReducer = createSlice({
  name: "copilotDrawerReducer",
  initialState,
  reducers: {
    toggleActions: (_, action: PayloadAction<boolean>) => {
      return { ...initialState, assistantOpen: action.payload, open: action.payload };
    },
    toggleAssistant: (_, action: PayloadAction<boolean>) => {
      return { ...initialState, assistantOpen: action.payload, open: action.payload };
    },
    toggleContentSearch: (_, action: PayloadAction<boolean>) => {
      return { ...initialState, assistantOpen: false, contentSearchOpen: action.payload, open: action.payload };
    },
    toggleRequirements: (_, action: PayloadAction<boolean>) => {
      return { ...initialState, assistantOpen: false, requirementsOpen: action.payload, open: action.payload };
    },
    toggleRequirementDetails: (_, action: PayloadAction<boolean>) => {
      return {
        ...initialState,
        assistantOpen: false,
        requirementDetailsOpen: action.payload,
        open: action.payload,
      };
    },
    toggleComments: (_, action: PayloadAction<boolean>) => {
      return { ...initialState, assistantOpen: false, commentsOpen: action.payload, open: action.payload };
    },
  },
});

export const {
  toggleActions,
  toggleAssistant,
  toggleContentSearch,
  toggleRequirements,
  toggleRequirementDetails,
  toggleComments,
} = copilotDrawerReducer.actions;

export default copilotDrawerReducer.reducer;

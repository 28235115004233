import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import { ReactRenderer } from "@tiptap/react";
import type { Instance } from "tippy.js";
import tippy from "tippy.js";

interface MentionUser {
  id: string;
  username: string;
  color: string;
}

interface MentionSuggestionProps {
  items: Array<{ username: string; id: string; color: string }>;
  command: ({ id, username, color }: { id: string; username: string; color: string }) => void;
}

export interface MentionSuggestionRef {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
}

export const MentionSuggestion = forwardRef<MentionSuggestionRef, MentionSuggestionProps>(({ items, command }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = items[index];
    if (item) {
      command({ id: item.id, username: item.username, color: item.color });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [items]);

  const handleKeyDown = ({ event }: { event: KeyboardEvent }) => {
    if (event.key === "ArrowUp") {
      upHandler();
      return true;
    }

    if (event.key === "ArrowDown") {
      downHandler();
      return true;
    }

    if (event.key === "Enter") {
      enterHandler();
      return true;
    }

    return false;
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: handleKeyDown,
  }));

  return (
    <div className="bg-white shadow-md rounded-md overflow-hidden min-w-[150px] max-w-[250px]">
      <div className="max-h-[180px] overflow-y-auto">
        {items.length === 0 ? (
          <div className="flex items-center justify-center p-2 cursor-default">
            <div className="text-sm text-gray-500">No suggestions</div>
          </div>
        ) : (
          items.map((item, index) => (
            <button
              key={index}
              className={`flex items-center w-full text-left p-2 ${
                index === selectedIndex ? "bg-blue-100" : "hover:bg-gray-100"
              }`}
              onClick={() => selectItem(index)}
            >
              <div
                className="h-5 min-w-5 rounded-full flex items-center justify-center text-sm"
                style={{ backgroundColor: item.color }}
              >
                {item.username.charAt(0).toUpperCase()}
              </div>
              <div className="ml-2 text-sm text-gray-700 truncate">{item.username}</div>
            </button>
          ))
        )}
      </div>
    </div>
  );
});

export const createMentionSuggestion = (
  workspaceMembers: MentionUser[],
  setDisableScroll?: React.Dispatch<React.SetStateAction<boolean>>,
  isMentionActiveRef?: React.MutableRefObject<boolean>,
) => {
  let reactRenderer: ReactRenderer | null = null;
  let popup: Instance[] | null = null;

  return {
    items: ({ query }: { query: string }) => {
      if (isMentionActiveRef) {
        isMentionActiveRef.current = true;
      }
      return workspaceMembers
        .filter((user: { username: string }) => user.username.toLowerCase().startsWith(query.toLowerCase()))
        .slice(0, 5);
    },

    render: () => {
      return {
        onStart: (props: any) => {
          if (!props.clientRect) {
            return;
          }
          setDisableScroll?.(true);
          if (isMentionActiveRef) {
            isMentionActiveRef.current = true;
          }
          reactRenderer = new ReactRenderer(MentionSuggestion, {
            props,
            editor: props.editor,
          });

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
            arrow: false,
          });
        },

        onUpdate(props: any) {
          if (!reactRenderer) return;
          reactRenderer.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup?.[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props: any) {
          if (props.event.key === "Escape") {
            setDisableScroll?.(false);
            if (isMentionActiveRef) {
              isMentionActiveRef.current = false;
            }
            popup?.[0].hide();
            return true;
          }

          return !!(reactRenderer?.ref as MentionSuggestionRef | undefined)?.onKeyDown(props);
        },

        onExit() {
          setDisableScroll?.(false);
          if (isMentionActiveRef) {
            isMentionActiveRef.current = false;
          }
          popup?.[0].destroy();
          if (reactRenderer) {
            reactRenderer.destroy();
          }
        },
      };
    },
  };
};

import { useAppSelector } from "store/storeTypes";
import { SourceModal } from "./SourceModal";
import { CreateProjectModal } from "components/modals/create-project-modal";
import { memo } from "react";
import DocumentViewerModal from "./DocumentViewerModal";
import CapabilitySearchModal from "./capability-search-modal/CapabilitySearchModal";

const GlobalModals = () => {
  const { sourceModal, capabilitySearchModal, createProjectModal, documentViewerModal } = useAppSelector(
    (state) => state.modals,
  );

  return (
    <>
      <SourceModal {...sourceModal} />
      <CapabilitySearchModal {...capabilitySearchModal} />
      <CreateProjectModal {...createProjectModal} />
      <DocumentViewerModal {...documentViewerModal} />
    </>
  );
};

export default memo(GlobalModals);

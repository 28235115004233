import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import type { WorkspaceMember } from "types/User";
import {
  CloseIconBlack,
  InfoIconWarn,
  SortingActiveBottomIcon,
  SortingActiveIcon,
  SortingActiveTopIcon,
} from "utils/icons";
import { useSortable } from "hook/useSortable";
import { useDispatch } from "react-redux";
import { Spinner } from "utils/icons";
import loadingImg from "../../Assets/generating.gif";
import { createProposalDocument, deleteProposal, getProposalStatus } from "api/api";
import CustomModal from "components/CustomModal";
import { createdNewPropsalDocumentAction } from "../../store/reducers/yjs-editor-reducer";
import { useNotification } from "context/notificationContext";
import { useTrackUserMetric } from "utils/metrics";
import { useAppSelector } from "store/storeTypes";
import { TableRow } from "./TableRow";
import { setProposals } from "store/reducers/projectReducer";
import { useFlags } from "hook/useFlags";
import * as logger from "utils/log";

const CONFIRM_DELETE_TEXT = "confirm";

type Props = {
  internalContractId: string;
  setDocId: (docId: string | undefined) => void;
  setForceRefresh: Dispatch<SetStateAction<boolean>>;
};

type ModalProps = { open: boolean; id: string | null };

const ProposalTable = ({ internalContractId, setDocId, setForceRefresh }: Props) => {
  const { setToast } = useNotification();
  const trackUserEvent = useTrackUserMetric();
  const proposals = useAppSelector((store) => store.project.proposals);
  const { workspaceMembers } = useAppSelector((store) => store.auth);
  const [deleting, setDeleting] = useState<string[]>([]);
  const [creatingProposalDoc, setCreatingProposalDoc] = useState(false);
  const [deleteModal, setDeleteModal] = useState<ModalProps>({ open: false, id: null });
  const [confirmDeleteText, setConfirmDeleteText] = useState("");
  const flags = useFlags();
  // tracks the displayed generation loader on the screen
  const [displayProposalGenerationLoader, setDisplayProposalGenerationLoader] = useState(false);
  // tracks if the proposal is in active generation state
  const [proposalGenerating, setProposalGenerating] = useState(false);

  const [cancelModel, setCancelModel] = useState(false);

  // Load initial proposal generation status
  useEffect(() => {
    getProposalStatus(internalContractId)
      .then((resp) => {
        setDisplayProposalGenerationLoader(resp?.data?.in_progress === true);
        setProposalGenerating(resp?.data?.in_progress === true);
      })
      .catch((err) => {
        logger.error(err, "Error fetching proposal generation status");
      });
  }, []);

  // Poll proposal generation status
  useEffect(() => {
    const updateGenerateProposalState = () => {
      getProposalStatus(internalContractId)
        .then((response) => {
          if (response?.data?.in_progress !== true && proposalGenerating) {
            setForceRefresh(true);
            setDisplayProposalGenerationLoader(false);
            setProposalGenerating(false);
          }
        })
        .catch((err) => {
          logger.error(err, "Error fetching proposal generation status");
        });
    };
    const intervalId = setInterval(() => updateGenerateProposalState(), 15000);
    return () => clearInterval(intervalId);
  }, [proposalGenerating]);

  // find a user
  const getUser = (userId: string): WorkspaceMember | undefined => workspaceMembers?.find((v) => v?.id === userId);

  // sorted data
  const { items, sortConfig, handleSorting } = useSortable(
    proposals?.map((v) => {
      return {
        ...v,
        creator: getUser(v?.created_by)?.username || "",
      };
    }),
  );

  // handle sort click event on table headers
  const handleSortEvent = (key: string, by_date?: boolean) => {
    if (sortConfig?.key === key) {
      if (sortConfig?.direction === "ascending") {
        handleSorting(key, "descending", by_date);
      } else {
        handleSorting("", "", false);
      }
    } else {
      handleSorting(key, "ascending", by_date);
    }
  };

  // render sort icon
  const renderSortIcon = (key: string, by_date?: boolean) => {
    if (sortConfig?.key !== key) {
      return (
        <SortingActiveIcon
          onclick1={() => handleSorting(key, "ascending", by_date)}
          onclick2={() => handleSorting(key, "descending", by_date)}
        />
      );
    }
    switch (sortConfig?.direction) {
      case "ascending":
        return (
          <SortingActiveTopIcon
            onclick1={() => handleSorting(key, "ascending", by_date)}
            onclick2={() => handleSorting(key, "descending", by_date)}
          />
        );
      case "descending":
        return (
          <SortingActiveBottomIcon
            onclick1={() => handleSorting(key, "ascending", by_date)}
            onclick2={() => handleSorting(key, "descending", by_date)}
          />
        );
      default:
        return (
          <SortingActiveIcon
            onclick1={() => handleSorting(key, "ascending", by_date)}
            onclick2={() => handleSorting(key, "descending", by_date)}
          />
        );
    }
  };

  // Check if already deleting or not
  const isAlreadyDeleting = (id?: string | null) => (deleting?.find((v) => v === id) ? true : false);

  // Delete a proposal
  const deleteAProposal = () => {
    if (typeof deleteModal?.id !== "string" || isAlreadyDeleting(deleteModal.id)) return;
    if (confirmDeleteText !== CONFIRM_DELETE_TEXT) return;
    setDeleting((prev) => (deleteModal?.id ? [...prev, deleteModal.id] : prev));
    deleteProposal(deleteModal.id)
      .then(() => {
        setDeleting((prev) => prev.filter((p) => p !== deleteModal.id));
        dispatch(setProposals(proposals?.filter((pr) => pr?.id !== deleteModal?.id)));
        setForceRefresh(true);
        setDeleteModal({ open: false, id: null });
        setConfirmDeleteText("");
        setToast.success({
          msg: "Proposal deleted",
        });
      })
      .catch(() => {
        setDeleting((prev) => prev.filter((p) => p !== deleteModal.id));
        setToast.error({
          title: "Unable to delete proposal",
          msg: "We were unable to delete the proposal due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      });
  };
  // on create new doc tiptap editor should open

  const dispatch = useDispatch();
  // create new proposal doc
  const createNewProposalDoc = () => {
    if (creatingProposalDoc) return;
    setCreatingProposalDoc(true);
    createProposalDocument(internalContractId, { proposal_name: "Untitled" })
      .then((res) => {
        dispatch(setProposals([...proposals, res.data]));
        dispatch(createdNewPropsalDocumentAction(true));
        setForceRefresh(true);
        setDocId(res.data.id);
        if (flags.enableNewProjectFlow && !proposals.length) setDocId(res.data.id);
      })
      .catch((err) => {
        logger.error(err, "Error while creating a new proposal document");
      })
      .finally(() => {
        setCreatingProposalDoc(false);
      });
  };

  return (
    <div className="pt-4 flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
      <div className="px-5 h-full">
        <button
          tabIndex={1}
          onClick={createNewProposalDoc}
          className={`${
            creatingProposalDoc ? "opacity-50 cursor-wait" : " cursor-pointer"
          } select-none flex justify-end text-sm ml-auto`}
        >
          <span className="text-gray-darkest flex items-center hover:border-gray-300 border border-gray-200 bg-gray-100 cursor-pointer text-sm px-2.5 py-1.5 rounded gap-2">
            + New Proposal
          </span>
        </button>
        <div className="flex items-top pt-1">
          <div className="ml-auto relative group flex gap-2 z-30">
            {proposalGenerating ? (
              <div className="absolute font-inter font-semibold w-max max-w-[200px] top-[120%] right-[0px] hidden  cursor-pointer rounded border-[2px] border-[#F5F5F5]  bg-white shadow-xl  group-hover:block z-20">
                <div className="menu-arrow left !bg-gray-400"></div>
                <div className="bg-white p-2">
                  <p className="text-xs text-gray-500 text-gray-primary pt-1 font-normal pr-1">
                    There is an <b>active import in progress</b>. Please wait until the import is complete before{" "}
                    <b>starting a new one</b>.
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* proposal table */}
        <div
          className="max-w-full overflow-auto border bg-white rounded-md mt-3 scrollbar-0"
          style={{ maxHeight: `calc(100% - 35px)` }}
        >
          <table className="overflow-auto">
            <thead className="border-b text-sm sticky top-0 left-0 bg-[#f5f5f5] rounded-tl-md rounded-tr-md z-[10]">
              <tr className="text-left font-thin">
                <th className="px-4 pl-7 py-1 w-[58%] min-w-[350px] text-sm font-medium" style={{ height: "44px" }}>
                  <p className="flex w-max max-w-full items-center select-none cursor-pointer">
                    <span onClick={() => handleSortEvent("name")} className="block pr-1">
                      Name
                    </span>
                    <span className="p-[2px] block">{renderSortIcon("name")}</span>
                  </p>
                </th>
                <th className="px-4 py-1 w-[26%] font-medium text-sm" style={{ height: "44px" }}>
                  <p className="flex w-max max-w-full items-center select-none cursor-pointer">
                    <span onClick={() => handleSortEvent("created_at", true)} className="block pr-1">
                      Created at
                    </span>
                    <span className="p-[2px] block">{renderSortIcon("created_at", true)}</span>
                  </p>
                </th>
                <th className="px-4 py-1 font-medium text-sm w-[35%]" style={{ height: "44px" }}>
                  <p className="flex w-max max-w-full items-center select-none cursor-pointer">
                    <span onClick={() => handleSortEvent("creator")} className="block pr-1">
                      Owner
                    </span>
                    <span className="p-[2px] block">{renderSortIcon("creator")}</span>
                  </p>
                </th>
                <th className="px-4 py-1 font-medium text-xs min-w-[60px] max-w-[60px]" style={{ height: "44px" }}></th>
              </tr>
              <tr>
                <th className="h-[1px] bg-gray-200" colSpan={8}></th>
              </tr>
            </thead>
            <tbody>
              {items?.map((data, i) => {
                return (
                  <TableRow
                    key={i}
                    getUser={getUser}
                    data={data}
                    mainData={proposals}
                    isAlreadyDeleting={isAlreadyDeleting}
                    setDeleteModal={setDeleteModal}
                    setConfirmDeleteText={setConfirmDeleteText}
                    setForceRefresh={setForceRefresh}
                    setDocId={setDocId}
                    trackUserEvent={trackUserEvent}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        {displayProposalGenerationLoader && (
          <div className="fixed bg-white z-[999] top-0 left-0 w-full h-full flex justify-center items-center flex-col p-5">
            <img
              width="420"
              height="420"
              className="mx-auto max-w-full object-contain"
              style={{ maxWidth: "calc(100vh - 15%)" }}
              src={loadingImg}
              alt="Loading..."
            />
            <div className="p-2 text-center">
              <h3 className="text-2xl font-bold text-black">Proposal is generating...</h3>
              <p className="my-4 text-gray-500">
                Grab some water, pick out a snack, and take a moment to unwind.
                <br />
                This could take between 10 to 30 minutes depending on the proposal draft length.
              </p>
              <button
                onClick={() => {
                  setCancelModel(true);
                }}
                className="flex items-center mx-auto gap-2 text-sm bg-action rounded-lg py-2 px-6 text-white"
              >
                Leave Proposal Generation
              </button>
            </div>
          </div>
        )}
        {/* ************ Cancel Modal ************* */}
        <CustomModal
          isOpen={cancelModel}
          onClose={() => {
            setCancelModel(false);
          }}
          className={"w-[100%] max-w-[477px]"}
        >
          <div className="px-5 py-6">
            <div className="flex items-start">
              <span className="mr-2">
                <InfoIconWarn />{" "}
              </span>
              <p className="text-[16px] font-semibold">Are you sure you want to leave?</p>
              <span
                className="ml-auto my-auto p-1 cursor-pointer select-none"
                role="button"
                onClick={() => {
                  setCancelModel(false);
                }}
              >
                <CloseIconBlack />
              </span>
            </div>
            <div className="flex items-start mt-5 flex-col">
              <div className="pl-8">
                <p className="text-[15px]">Leaving will not cancel the proposal generation.</p>
              </div>
            </div>
            <div className="flex items-center justify-end w-full gap-3 mt-7">
              <button
                className="border-gray-300 border py-2 px-4 rounded-lg font-[500]"
                onClick={() => {
                  setCancelModel(false);
                }}
              >
                Stay
              </button>
              <button
                className="py-2 px-4 rounded-lg font-[500] border-action bg-action text-white active:bg-[#304aa5de]"
                onClick={() => {
                  setCancelModel(false);
                  setDisplayProposalGenerationLoader(false);
                }}
              >
                Leave
              </button>
            </div>
          </div>
        </CustomModal>
        {/* Ask delete modal */}
        <CustomModal
          isOpen={deleteModal?.open}
          onClose={() => (deleting?.length > 0 ? null : setDeleteModal({ open: false, id: null }))}
          className={"w-[100%] max-w-[477px]"}
        >
          <div className="px-5 py-6">
            <div className="flex items-start">
              <span className="mr-2">
                <InfoIconWarn />{" "}
              </span>
              <p className="text-[16px] font-semibold">Are you sure you want to delete this proposal?</p>
              <span
                className="p-2 ml-auto cursor-pointer"
                role="button"
                onClick={() => (deleting?.length > 0 ? null : setDeleteModal({ open: false, id: null }))}
              >
                <CloseIconBlack width="13" height="13" />
              </span>
            </div>
            <div className="flex pl-8 pr-2 items-start mt-5 flex-col">
              <p className="text-[15px]">
                This is an irreversible operation. You will <b>NOT</b> be able to recover this proposal. Please type
                <span className="font-semibold"> "{CONFIRM_DELETE_TEXT}"</span> below to confirm.
              </p>
              <input
                type="text"
                onChange={(e) => setConfirmDeleteText(e.target.value)}
                value={confirmDeleteText}
                className="bg-white mt-5 focus:outline-none border px-3 py-1 rounded-md focus:ring-0 focus:ring-action text-md text-black w-full placeholder:text-md"
                placeholder='Enter "confirm" to delete.'
              />
            </div>
            <div className="flex items-center justify-end w-full gap-3 mt-8">
              <button
                disabled={deleting?.length > 0}
                className="border-gray-300 border py-2 px-4 rounded-md font-normal"
                onClick={() => {
                  setDeleteModal({ open: false, id: null });
                }}
              >
                Cancel
              </button>
              <button
                className="py-2 px-4 rounded-md font-normal bg-dark text-white active:bg-dark disabled:opacity-60"
                onClick={deleteAProposal}
                disabled={deleting?.length > 0 || confirmDeleteText !== CONFIRM_DELETE_TEXT}
              >
                {deleting?.length > 0 ? (
                  <>
                    {" "}
                    <span className="mr-1">
                      <Spinner width={18} height={18} />
                    </span>{" "}
                    Deleting
                  </>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};

export default ProposalTable;

import { useState, useEffect, useCallback } from "react";
import Drawer from "components/organisms/drawer/Drawer";
import tw from "twin.macro";
import type { ReviewStatus } from "components/Drive/getReviewStatus";
import { getReviewStatus } from "components/Drive/getReviewStatus";
import { useGetUsernameById } from "hook/user/useGetUsernameById";

import { HeaderSection } from "./components/HeaderSection";
import { PropertiesSection } from "./components/PropertiesSection";
import { TagsSection } from "./components/TagsSection";
import { NotesSection } from "./components/NotesSection";
import type { WorkspaceFile, WorkspaceMedia } from "types/FileStorage";
import { setDocumentUserTags, setMediaUserTags } from "store/reducers/driveReducerSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";

interface ContentLibraryPanelProps {
  doc: WorkspaceFile | WorkspaceMedia;
  isOpen: boolean;
  onClose: () => void;
  onUpdate:
    | ((id: string, data: Partial<WorkspaceMedia>) => Promise<void>)
    | ((id: string, data: Partial<WorkspaceFile>) => Promise<void>);
  isMediaFile?: boolean;
}

export const ContentLibraryPanel = ({
  doc,
  isOpen,
  onClose,
  onUpdate,
  isMediaFile = false,
}: ContentLibraryPanelProps) => {
  const [date, setDate] = useState<string>("");
  const [reviewDate, setReviewDate] = useState<string>("");
  const [isClassificationDropdown, setIsClassificationDropdown] = useState<boolean>(false);
  const [selectedClassification, setSelectedClassification] = useState<string>("");
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [isAddingTag, setIsAddingTag] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [reviewStatus, setReviewStatus] = useState<ReviewStatus>({
    needsReview: false,
  });

  const getUsernameById = useGetUsernameById();
  const uploaderUsername = getUsernameById(doc?.created_by || "") || "";
  const { userTags } = useAppSelector((state) => (isMediaFile ? state.drive.media : state.drive.fileStorage));
  const dispatch = useAppDispatch();
  const getFileName = () => {
    return isMediaFile ? (doc as WorkspaceMedia).file_name : (doc as WorkspaceFile).name;
  };

  useEffect(() => {
    if (doc) {
      // Handle different field names based on type
      if (isMediaFile) {
        const mediaDoc = doc as WorkspaceMedia;
        setFileName(mediaDoc.file_name || "");
        setDate(mediaDoc.formatted_set_date || "");
        setTags(mediaDoc.user_tags || []);
        setNotes(mediaDoc.notes || "");
      } else {
        const fileDoc = doc as WorkspaceFile;
        setFileName(fileDoc.name || "");
        setDate(fileDoc.formatted_set_date || "");
        setReviewDate(fileDoc.formatted_review_date || "");
        setReviewStatus(getReviewStatus(fileDoc.review_date));
        setSelectedClassification(fileDoc.classification || "");
        setTags(fileDoc.tags || []);
        setNotes(fileDoc.notes || "");
      }
    }
  }, [doc, isMediaFile]);

  // Header handlers
  const handleNameBlur = async () => {
    setIsEditingName(false);
    if (fileName.trim() && doc && getFileName() !== fileName.trim()) {
      const updateField = isMediaFile ? "file_name" : "name";
      await onUpdate(doc.id, { [updateField]: fileName.trim() });
    }
  };

  const handleNameKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleNameBlur();
    } else if (e.key === "Escape") {
      const fileName = getFileName();
      setFileName(fileName || "");
      setIsEditingName(false);
    }
  };

  const handleDownload = () => {
    if (!doc) return;
    const a = document.createElement("a");
    a.href = doc.download_url;
    a.download = getFileName();
    a.click();
  };

  // Properties handlers
  const handleDateChange = useCallback(
    (date: any) => {
      if (!doc) return;
      const dateValue = date?.valueOf();
      if (!dateValue) return;

      const newDate = new Date(dateValue);
      const formattedDate = newDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });

      setDate(formattedDate);
      onUpdate(doc.id, {
        set_date: newDate.toISOString(),
      });
    },
    [doc, onUpdate],
  );

  const handleReviewDateChange = useCallback(
    (date: any) => {
      if (!doc) return;
      const dateValue = date?.valueOf();
      if (!dateValue) return;

      const newDate = new Date(dateValue);
      const formattedDate = newDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      const newReviewDate = newDate.toISOString();

      setReviewDate(formattedDate);
      setReviewStatus(getReviewStatus(newReviewDate));
      onUpdate(doc.id, {
        review_date: newDate.toISOString(),
      });
    },
    [doc, onUpdate],
  );

  const handleClassificationSelect = useCallback(
    (type: string) => {
      if (!doc) return;
      setSelectedClassification(type);
      setIsClassificationDropdown(false);
      onUpdate(doc.id, { classification: type });
    },
    [doc, onUpdate],
  );

  // Tags handlers
  const handleAddTag = (e: React.FormEvent | string) => {
    if (typeof e !== "string") {
      e.preventDefault();
    }
    if (!doc) return;

    const tagToAdd = typeof e === "string" ? e : newTag;

    if (tagToAdd.trim() && !tags.includes(tagToAdd.trim())) {
      const updatedTags = [...tags, tagToAdd.trim()];
      setTags(updatedTags);

      // update userTags if new tag is added
      if (!userTags.includes(tagToAdd.trim())) {
        if (isMediaFile) {
          dispatch(setMediaUserTags([...userTags, tagToAdd.trim()]));
        } else {
          dispatch(setDocumentUserTags([...userTags, tagToAdd.trim()]));
        }
      }

      if (isMediaFile) {
        onUpdate(doc.id, { user_tags: updatedTags });
      } else {
        onUpdate(doc.id, { tags: updatedTags });
      }

      setNewTag("");
      // close the tag input if it was a manual submission
      if (typeof e !== "string") {
        setIsAddingTag(false);
      }
    }
  };

  const handleRemoveTag = async (tagToRemove: string) => {
    if (!doc) return;
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);

    if (isMediaFile) {
      await onUpdate(doc.id, { user_tags: updatedTags });
    } else {
      await onUpdate(doc.id, { tags: updatedTags });
    }

    if (isMediaFile) {
      dispatch(setMediaUserTags([...userTags]));
    } else {
      dispatch(setDocumentUserTags([...userTags]));
    }
  };

  // Notes handlers
  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value);
  };

  const handleNotesBlur = async () => {
    if (!doc) return;
    const initialNotes = doc.notes?.trim() || "";
    const currentNotes = notes.trim() || "";
    if (currentNotes !== initialNotes) {
      setIsSaving(true);
      await onUpdate(doc.id, { notes });
      setIsSaving(false);
    }
  };

  if (!doc) return null;

  return (
    <Drawer
      fullScreen
      opened={isOpen}
      onClose={onClose}
      header={
        <HeaderSection
          fileName={fileName}
          isEditingName={isEditingName}
          setIsEditingName={setIsEditingName}
          handleNameBlur={handleNameBlur}
          handleNameKeyDown={handleNameKeyDown}
          handleDownload={handleDownload}
          onFileNameChange={setFileName}
        />
      }
      styles={[
        tw`fixed top-[54px] right-0 bottom-0 z-[101] min-w-[350px] w-[35vw] max-w-[400px] transition-transform duration-300 overflow-y-auto`,
        isOpen ? tw`translate-x-0` : tw`translate-x-full`,
      ]}
      headerStyles={[tw`border-b border-gray-light pb-4 mb-7`]}
    >
      <PropertiesSection
        uploaderUsername={uploaderUsername}
        uploaderId={doc.created_by}
        date={date}
        handleDateChange={handleDateChange}
        reviewDate={reviewDate}
        handleReviewDateChange={handleReviewDateChange}
        reviewStatus={reviewStatus}
        isClassificationDropdown={isClassificationDropdown}
        setIsClassificationDropdown={setIsClassificationDropdown}
        selectedClassification={selectedClassification}
        handleClassificationSelect={handleClassificationSelect}
        isMediaFile={isMediaFile}
      />

      <hr className="my-7 border-gray-light" />

      <TagsSection
        tags={tags}
        isAddingTag={isAddingTag}
        setIsAddingTag={setIsAddingTag}
        newTag={newTag}
        setNewTag={setNewTag}
        handleAddTag={handleAddTag}
        handleRemoveTag={handleRemoveTag}
        isMediaFile={isMediaFile}
      />

      <hr className="my-7 border-gray-light" />

      <NotesSection
        notes={notes}
        handleNotesChange={handleNotesChange}
        handleNotesBlur={handleNotesBlur}
        isSaving={isSaving}
        isOpen={isOpen}
      />
    </Drawer>
  );
};

import type { ComponentProps } from "react";

export function IndentIcon(props: ComponentProps<"svg">) {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.04297 6.00002H6.1263M6.1263 6.00002L4.08464 3.95835M6.1263 6.00002L4.08464 8.04169"
        stroke="#1E1E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5845 6H10.0845M16.5845 11H1.58447M16.5845 1H1.58447"
        stroke="#1E1E1E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

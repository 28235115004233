import styles from "./Avatars.module.css";
import { useSelector } from "react-redux";
import type { HTMLAttributes } from "react";
import { useEffect, useMemo, useState } from "react";
import { useOthers } from "utils/yjs-configs/proposal-document/yjs.config";
import { Avatar } from "components/molecules/avatar";

export function Avatars(props: HTMLAttributes<HTMLDivElement>) {
  const users = useOthers();

  const { workspaceMembers } = useSelector((store: any) => store.auth);
  const [currentUsers, setCurrentUsers] = useState<any>([]);
  const liveUsersInADoc = useMemo(
    () => workspaceMembers.filter((obj1: { id: string }) => users.some((obj2) => obj2.info?.id === obj1.id)),
    [users, workspaceMembers],
  );

  const handleScrollIntoPresence = (name: string) => {
    const labelDivs = document.getElementsByClassName("collaboration-cursor__label");

    const trimmedName = name.trim();

    Array.from(labelDivs).forEach((labelDiv) => {
      if (labelDiv.innerHTML.trim() === trimmedName) {
        labelDiv.scrollIntoView({ behavior: "smooth" });
        return;
      }
    });
  };

  useEffect(() => {
    if (liveUsersInADoc) {
      setCurrentUsers(liveUsersInADoc);
    }
    return () => {
      setCurrentUsers([]);
    };
  }, [workspaceMembers, liveUsersInADoc]);

  return (
    <>
      {currentUsers.length !== 0 ? (
        <div className={styles.avatars} {...props}>
          <div className="relative  first:ml-0">
            <div role="button" className="mt-auto w-full select-none cursor-pointer">
              <div className="flex gap-3 items-center">
                <span className="flex -space-x-2">
                  {currentUsers?.map((user: any, ind: number) => (
                    <Avatar
                      key={ind}
                      name={user.username}
                      size={32}
                      bgColor={user.color}
                      textColor="#FFFFFF"
                      onClick={() => handleScrollIntoPresence(user.username)}
                      tooltip
                    />
                  ))}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

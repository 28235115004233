import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import { getActiveCommentMarkWithRange } from "./utils";
import type { Editor } from "@tiptap/react";

interface ActiveCommentState {
  activeCommentId: string | null;
  activeCommentPosition: { from: number; to: number } | null;
  commentMarks: Array<{ id: string; from: number; to: number; isResolved?: boolean }>;
}

export const commentPluginHighlightKey = new PluginKey<ActiveCommentState>("commentPluginHighlightKey");
export const commentMetaHighlightKey = "commentPluginHighlightKey";

export const CommentsPluginHighlight = (editor: Editor) =>
  new Plugin({
    key: commentPluginHighlightKey,
    state: {
      init(): ActiveCommentState {
        return {
          activeCommentId: null,
          activeCommentPosition: null,
          commentMarks: [],
        };
      },
      apply(tr, value, oldState, newState): ActiveCommentState {
        let { activeCommentId, activeCommentPosition, commentMarks } = value;

        const meta = tr.getMeta(commentPluginHighlightKey);
        if (meta) {
          activeCommentId = meta.activeCommentId;
          activeCommentPosition = meta.activeCommentPosition;
        }
        // Check if the selection has changed to a different range or if a comment has been resolved
        const { from, to } = tr.selection;
        if ((!tr.selection.eq(oldState.selection) && tr.selection.empty) || tr.getMeta("commentResolveStateChange")) {
          const activeCommentMarkWithRange = getActiveCommentMarkWithRange(newState.doc, from, to);
          if (activeCommentMarkWithRange) {
            activeCommentId = activeCommentMarkWithRange.activeCommentId;
            activeCommentPosition = activeCommentMarkWithRange.activeCommentPosition;
          } else {
            activeCommentId = null;
            activeCommentPosition = null;
          }
        }
        commentMarks = [];
        newState.doc.descendants((node, pos) => {
          node.marks.forEach((mark) => {
            if (mark.type.name === "comment") {
              commentMarks.push({
                id: mark.attrs.id,
                from: pos,
                to: pos + node.nodeSize,
                isResolved: mark.attrs.resolved,
              });
            }
          });
        });

        return {
          activeCommentId,
          activeCommentPosition,
          commentMarks,
        };
      },
    },
    props: {
      decorations(state) {
        const decorations: Decoration[] = [];
        const pluginState = this.getState(state);

        if (!pluginState) return DecorationSet.empty;

        const { activeCommentId, commentMarks } = pluginState;
        const hiddenComments = (editor?.storage.hiddenComments as string[]) || [];

        const overlapMap = new Map<number, number>();

        commentMarks
          .filter((comment) => !comment.isResolved && !hiddenComments.includes(comment.id))
          .forEach(({ from, to }) => {
            for (let i = from; i < to; i++) {
              overlapMap.set(i, (overlapMap.get(i) || 0) + 1);
            }
          });
        commentMarks.forEach(({ id, from, to, isResolved }) => {
          const isActive = id === activeCommentId;
          const isHidden = hiddenComments.includes(id);
          const maxOverlap = Math.max(...Array.from({ length: to - from }, (_, i) => overlapMap.get(from + i) || 0));

          let classes = "";
          if (isHidden) {
            classes = "comment-hidden";
          } else if (isResolved) {
            classes = "comment-resolved";
          } else if (isActive) {
            classes = "comment-active";
          } else {
            classes = "comment-idle";
          }

          if (!isResolved && !isHidden && maxOverlap > 1) {
            classes += ` comment-overlap-${maxOverlap}`;
          }

          decorations.push(
            Decoration.inline(from, to, {
              class: classes,
            }),
          );
        });
        return DecorationSet.create(state.doc, decorations);
      },
    },
  });

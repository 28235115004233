/** @jsxImportSource @emotion/react */
import type { FocusEvent, FormEvent } from "react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setAiAssistantState } from "store/reducers/ai-assistant/aiAssistantReducer";
import { ArrowLeft, SquarePen } from "lucide-react";
import useAIAssistantOperations from "hook/useAIAssistantOperations";
import Tooltip from "components/atoms/tooltip";
import { useNavigate } from "react-router-dom";
import { DEFAULT_SESSION_NAME } from "components/molecules/assistant-sidebar-row/constants";

const AIAssistantHeader = () => {
  const dispatch = useAppDispatch();
  const { createSession, updateSession } = useAIAssistantOperations();

  const navigate = useNavigate();

  const activeSession = useAppSelector((root) => root.aiAssistantState.activeSession);

  const [isEditingName, setIsEditingName] = useState(false);
  const [localSessionName, setLocalSessionName] = useState(activeSession?.name);

  const handleBack = () => {
    navigate("/dashboard/ai-assistant", { replace: true });
    dispatch(setAiAssistantState({ activeSession: undefined }));
  };

  const handleSessionNameUpdate = (e: FormEvent<HTMLFormElement> | FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsEditingName(false);

    if (!activeSession) return;

    if (localSessionName?.trim() && localSessionName.trim() !== activeSession?.name.trim()) {
      updateSession(activeSession?.id, { name: localSessionName.trim() });
    } else if (!localSessionName?.trim()) {
      updateSession(activeSession?.id, { name: DEFAULT_SESSION_NAME });
      setLocalSessionName(DEFAULT_SESSION_NAME);
    }
  };

  useEffect(() => {
    if (activeSession) {
      setLocalSessionName(activeSession.name);
    }
  }, [activeSession?.name]);

  return (
    <div className="flex items-center justify-between w-full px-5 py-2.5 border-b border-gray-200">
      <div className="w-8">
        <button className="flex items-center justify-center gap-1.5 hover:text-black text-sm" onClick={handleBack}>
          <ArrowLeft size={14} />
          Back
        </button>
      </div>
      <form onSubmit={handleSessionNameUpdate} className="w-full min-w-0 flex justify-center items-center">
        {isEditingName ? (
          <input
            type="text"
            value={localSessionName}
            className="min-w-0 w-[450px] px-2 py-1 border-none outline-none bg-white text-center text-[15px]"
            onChange={(e) => setLocalSessionName(e.target.value)}
            onBlur={handleSessionNameUpdate}
            autoFocus
          />
        ) : (
          <div
            className="inline-block min-w-0 w-[450px] px-2 py-1 rounded-md truncate hover:bg-gray-100 cursor-text text-center text-[15px]"
            onClick={() => setIsEditingName(true)}
          >
            {localSessionName}
          </div>
        )}
      </form>
      <div className="w-8">
        <Tooltip content="Create new session">
          <button
            aria-label="Create new session"
            className="flex items-center justify-center rounded-md border border-gray-200 p-2 gap-2 text-sm hover:bg-gray-100"
            onClick={() => createSession("New Session")}
          >
            <SquarePen size={14} />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default AIAssistantHeader;

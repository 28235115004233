/** @jsxImportSource @emotion/react */
import { useMemo, useState } from "react";
import { MoveIcon } from "utils/icons";
import dltLoader from "../../../Assets/delete-loader.svg";
import doc from "../../../Assets/generic-doc.png";
import { Download, Trash2 } from "lucide-react";
import { getDocumentStatus } from "../getDocumentStatus";
import { getReviewStatus } from "../getReviewStatus";
import { useFlags } from "hook/useFlags";
import { createDragHandler } from "pages/drive/utils/dragPreview";
import type { WorkspaceFile } from "types/FileStorage";
import { CONTENT_LIBRARY } from "const-values/testIds";
import DocumentHeader from "./DocumentHeader";
import OpenButton from "./OpenButton";
import DocumentPreview from "./DocumentPreview";
import DocumentMenu from "./DocumentMenu";
import DocumentFooter from "./DocumentFooter";

interface DocumentProps {
  deleteDocument: (id: string) => Promise<void>;
  setDraggingFolderId: (id: string) => void;
  setDraggingDocIds: (ids: string[]) => void;
  docData: WorkspaceFile;
  handleMoveModalOpen: (items: string | string[], type: "doc" | "folder" | null) => void;
  onSelect: (id: string, checked: boolean) => void;
  isSelected: boolean;
  selectedDocuments: string[];
  onDocumentClick: (docData: WorkspaceFile) => void;
  isDocumentPanelOpen: boolean;
}

export default function Document({
  deleteDocument,
  setDraggingFolderId,
  setDraggingDocIds,
  docData,
  handleMoveModalOpen,
  onSelect,
  isSelected,
  selectedDocuments,
  onDocumentClick,
  isDocumentPanelOpen,
}: DocumentProps) {
  const { created_at, id, indexed, name, formatted_set_date, file_extension_type, download_url, review_date } = docData;
  const [deleting, setDeleting] = useState(false);
  const flags = useFlags();
  const status = getDocumentStatus(docData, flags);
  const reviewStatus = getReviewStatus(review_date);

  const handleDragStart = createDragHandler({
    id: docData.id,
    selectedIds: selectedDocuments,
    displayName: name,
    itemsName: "documents",
    onDragStart: (draggedIds) => {
      setDraggingDocIds(draggedIds);
      setDraggingFolderId("");
    },
  });

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2">
            <Download size={14} /> Download
          </div>
        ),
        onSelect: () => {
          const a = document.createElement("a");
          a.href = download_url;
          a.download = name;
          a.click();
        },
      },
      {
        key: 2,
        label: (
          <div className="flex items-center gap-2">
            <MoveIcon />
            Move
          </div>
        ),
        onSelect: () => {
          handleMoveModalOpen(id, "doc");
        },
      },
      {
        key: 3,
        label: (
          <div className="flex items-center gap-2 text-red-500">
            {deleting ? <img src={dltLoader} alt="icon" width={14} height={14} /> : <Trash2 size={14} />}
            Delete
          </div>
        ),
        disabled: deleting,
        onSelect: () => {
          setDeleting(true);
          deleteDocument(id).finally(() => {
            setDeleting(false);
          });
        },
      },
    ],
    [deleteDocument, deleting, download_url, handleMoveModalOpen, id, name],
  );

  return (
    <div
      data-drag-ignore
      className={`card group relative flex flex-col w-full rounded-lg cursor-pointer overflow-hidden ${
        isSelected || isDocumentPanelOpen ? "border-2 border-gray-darkest" : "border-2 border-gray-light"
      }`}
      onDragStart={handleDragStart}
      draggable
      data-doc-id={id}
      onClick={() => onDocumentClick(docData)}
    >
      <DocumentHeader id={id} isSelected={isSelected} onSelect={onSelect} docData={docData} />
      {file_extension_type === "pdf" && <OpenButton download_url={download_url} />}
      <DocumentPreview createdAt={created_at} doc={doc} indexed={indexed} />
      <div className="flex-1 bg-white p-4 z-20 -mt-12">
        <div className="flex justify-between gap-2">
          <p className="font-semibold text-xxs break-all mb-2 truncate" data-testid={CONTENT_LIBRARY.document.name}>
            {name}
          </p>
          <DocumentMenu menuItems={menuItems} />
        </div>
        <DocumentFooter formatted_set_date={formatted_set_date} status={status} reviewStatus={reviewStatus} />
      </div>
    </div>
  );
}

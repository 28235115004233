import { useState, useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { createExtraction } from "utils/extraction";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useExtractionOperations from "hook/useExtractionOperations";
import axios from "axios";
import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import {
  appendCoordinateCancelTokens,
  getCoordinates,
  getTemplate,
} from "store/reducers/extract/CurrentExtractionReducer";
import usePageVisibility from "hook/usePageVisibility";
import type { ExtractionErrorReason, SolicitationType, Storage } from "../CopilotSchemaTypes";
import { ExtractionStatus } from "../CopilotSchemaTypes";
import { SOLICITATION_TYPE_TO_META } from "./project-selection/constants";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { LiveList } from "YJSProvider/LiveObjects";
import { useFlags } from "hook/useFlags";
import { YJS_OPERATIONS } from "const-values/yjs";
import { useProxyRef } from "hook/useProxyRef";

export const useTriggerExtraction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { appendExtraction, addAttribution } = useExtractionOperations();
  const projectId = searchParams.get("id")?.toLocaleLowerCase();
  const [userToken] = usePersistedStorage("vultron_user_token");
  const [workspace_id] = usePersistedStorage("vultron_workspace_id");
  const [use_auth0] = usePersistedStorage("vultron_user_use_auth0");
  const useAuth0Header = use_auth0 === true;

  const selectedDocuments = useAppSelector((store) => store.extractReducerV2.selectedDocuments);

  const uploadFile = useCallback(
    (file: File): Promise<string> => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("internal_upload_type", "0");

      return axios<{ document_id: string }>({
        method: "POST",
        url: "/autopilot/extract/" + projectId + "/upload/document",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken}`,
          Workspace: `Workspace ${workspace_id}`,
          "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
        },
      }).then((res) => res.data.document_id);
    },
    [projectId, useAuth0Header, userToken, workspace_id],
  );

  const triggerExtraction = useCallback(
    async (solicitationType: SolicitationType) => {
      setIsLoading(true);
      addAttribution(YJS_OPERATIONS.EXTRACTION.CREATE_EXTRACTION);
      try {
        const uploadedDocuments = selectedDocuments.filter((doc) => typeof doc !== "string") as File[];
        const existingDocumentIds = selectedDocuments.filter((doc) => typeof doc === "string") as string[];
        const uploadedDocumentIds = await Promise.all(uploadedDocuments.map((file) => uploadFile(file)));
        const allDocIds = [...existingDocumentIds, ...uploadedDocumentIds];
        const allDocIdsPayload = allDocIds.map((documentId) => ({ document_id: documentId }));
        const { data } = await axios.post<{ analysis_id: string }>("/autopilot/analyze/document", {
          documents: allDocIdsPayload,
          solicitation_type: solicitationType,
        });
        const solicitationTypeMeta = SOLICITATION_TYPE_TO_META[solicitationType];

        const createdExtraction = createExtraction({
          id: data.analysis_id,
          file_ids: new LiveList(allDocIds),
          name: solicitationTypeMeta.label,
          solicitation_type: solicitationType,
        });
        const extractionId = appendExtraction(createdExtraction);
        const directionPath = `extractions/${extractionId}?${searchParams.toString()}`;
        navigate(directionPath);
      } catch {
        // silently ignore
      } finally {
        setIsLoading(false);
      }
    },
    [addAttribution, appendExtraction, navigate, searchParams, selectedDocuments, uploadFile],
  );

  return { triggerExtraction, isLoading };
};

export const useGetCoordinates = () => {
  const isPageVisible = usePageVisibility();
  const timerIdRef = useRef<NodeJS.Timeout>();
  const dispatch = useAppDispatch();
  const { extractionId } = useParams();
  const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const coordinates = useAppSelector(
    (store) =>
      store.currentExtractionState.coordinateCache[store.currentExtractionState.activeDocument?.id || ""]
        ?.coordinates || [],
  );
  const [isPollingEnabled, setIsPollingEnabled] = useState(false);

  useEffect(() => {
    if (!activeDocument) {
      setIsPollingEnabled(false);
      return;
    }

    if (!coordinates.length && extraction?.id === extractionId) {
      setIsPollingEnabled(true);
    }

    if (coordinates.length) setIsPollingEnabled(false);
  }, [activeDocument, coordinates.length, extraction?.id, extractionId]);

  useEffect(() => {
    if (!activeDocument || !extractionId) return;
    let promise: any;
    const pollingCallback = () => {
      console.log("polling coords --->");

      const cancelToken = axios.CancelToken.source();
      promise = dispatch(getCoordinates({ fileId: activeDocument?.id, cancelToken: cancelToken.token }));
      dispatch(appendCoordinateCancelTokens(cancelToken));
    };

    const startPolling = () => {
      pollingCallback();
      timerIdRef.current = setInterval(pollingCallback, 3000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
      promise?.abort();
    };

    if (isPageVisible && isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [activeDocument, dispatch, extractionId, isPageVisible, isPollingEnabled]);
};

export const useGetTemplates = () => {
  const isPageVisible = usePageVisibility();
  const timerIdRef = useRef<NodeJS.Timeout>();
  const dispatch = useAppDispatch();
  const flags = useFlags();
  const { extractionId } = useParams();
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const templates = useAppSelector((store) => store.currentExtractionState.templates);
  const [isPollingEnabled, setIsPollingEnabled] = useState(false);
  const { setExtractionErrorReason } = useExtractionOperations();
  const navigate = useNavigate();

  useEffect(() => {
    if (flags.disableTemplateTabInGenerateForOnsite) {
      return;
    }

    if (!templates.length && extraction?.id === extractionId) {
      setIsPollingEnabled(true);
    }

    if (templates.length) {
      setIsPollingEnabled(false);
    }
  }, [extraction?.id, extractionId, templates.length, flags.disableTemplateTabInGenerateForOnsite]);

  useEffect(() => {
    if (!extractionId || flags.disableTemplateTabInGenerateForOnsite) return;
    const pollingCallback = async () => {
      console.log("polling template --->");
      try {
        const response = await dispatch(getTemplate({ extractionId, includeContextTemplates: flags.cmTemplates }));
        const errorReason = (response.payload as { error_reason: ExtractionErrorReason }).error_reason;
        if (errorReason) {
          setExtractionErrorReason(extractionId, errorReason);
          // redirect to extraction carousel if error
          const projectId = new URLSearchParams(window.location.search).get("id");
          if (projectId) {
            navigate(`/dashboard/contracts/details?tab=extract&id=${projectId}`);
          } else {
            navigate(`/dashboard/contracts`);
          }
        }
      } catch (err) {}
    };

    const startPolling = () => {
      pollingCallback();
      timerIdRef.current = setInterval(pollingCallback, 3000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (isPageVisible && isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, extractionId, flags.cmTemplates, isPageVisible, isPollingEnabled]);
};

export const useUpdateExtractionStatuses = (extractions: NonNullable<ToImmutable<Storage>>["extractions"]) => {
  const { setExtractionStatus, addAttribution } = useExtractionOperations();
  const createDocumentViewTasks = useAppSelector((state) => state.autopilotHealthCheck.create_document_view_tasks);
  const { current: isDocumentViewTasksFetched } = useProxyRef(!!createDocumentViewTasks);
  const requirement_response_tasks = useAppSelector((state) => state.autopilotHealthCheck.requirement_response_tasks);

  const validateStatuses = useCallback(() => {
    if (!isDocumentViewTasksFetched) return;

    try {
      extractions?.forEach((extraction) => {
        const foundDocumentViewTask = (createDocumentViewTasks || []).find(
          (task) => extraction.id === task.analysis_id && extraction.file_ids.includes(task.reference_id || ""),
        );

        if (requirement_response_tasks.some((task) => extraction.id === task.reference_id)) {
          setExtractionStatus(extraction.id, ExtractionStatus.Drafting);
          addAttribution(YJS_OPERATIONS.EXTRACTION.SET_STATUS);
        } else if (
          extraction.status === ExtractionStatus.Drafting &&
          !requirement_response_tasks.some((task) => extraction.id === task.reference_id)
        ) {
          setExtractionStatus(extraction.id, ExtractionStatus.ReadyToPublish);
          addAttribution(YJS_OPERATIONS.EXTRACTION.SET_STATUS);
        } else if (foundDocumentViewTask) {
          if (!foundDocumentViewTask.failed && extraction?.status !== ExtractionStatus.Extracting) {
            // otherwise setExtractionStatus to extracting if needed
            setExtractionStatus(extraction.id, ExtractionStatus.Extracting);
            addAttribution(YJS_OPERATIONS.EXTRACTION.SET_STATUS);
          }
          if (foundDocumentViewTask.failed && extraction?.status !== ExtractionStatus.Failed) {
            setExtractionStatus(extraction.id, ExtractionStatus.Failed);
            addAttribution(YJS_OPERATIONS.EXTRACTION.SET_STATUS);
          }
        }
        // if all documents are extracted, then the extraction should be marked as ready
        else if (extraction?.status === ExtractionStatus.Extracting) {
          setExtractionStatus(extraction.id, ExtractionStatus.Ready);
          addAttribution(YJS_OPERATIONS.EXTRACTION.SET_STATUS);
        }
      });
    } catch (e) {}
  }, [addAttribution, createDocumentViewTasks, extractions, requirement_response_tasks, setExtractionStatus]);

  useEffect(() => {
    validateStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDocumentViewTasks]);
};

import driveWhite from "Assets/drive-white.svg";
import folderOpenSm from "Assets/folder-open-white.svg";
import type { Subdirectory } from "types/FileStorage";
import { pluralizeWord } from "utils/string";

interface DragAndDropToastProps {
  isDragOverDrive: boolean;
  selectedFolderHeading: string;
  draggingFolderId: string;
  draggingDocIds: string[];
  currentFolders: Subdirectory[];
  itemType?: "document" | "graphic";
  hideToast?: boolean;
  currentDirectory?: {
    name: string;
  } | null;
}

export const DragAndDropToast = ({
  isDragOverDrive,
  selectedFolderHeading,
  draggingFolderId,
  draggingDocIds,
  currentFolders,
  itemType = "document",
  hideToast = false,
  currentDirectory,
}: DragAndDropToastProps) => {
  // Check if trying to drag folder onto itself
  const isSelfDrag =
    draggingFolderId &&
    selectedFolderHeading &&
    currentFolders.find((f) => f.id === draggingFolderId)?.id === selectedFolderHeading;

  if (hideToast || isSelfDrag || !(isDragOverDrive || selectedFolderHeading)) {
    return null;
  }

  const getMessage = () =>
    draggingFolderId
      ? "Move folder to"
      : draggingDocIds.length
        ? `Move ${pluralizeWord(draggingDocIds.length, itemType)} to`
        : `Drop ${itemType}s to upload them to`;

  const getDestinationName = () => {
    const selectedFolder = currentFolders.find((f) => f.id === selectedFolderHeading)?.name;
    if (selectedFolder) return selectedFolder;
    if (currentDirectory?.name) return currentDirectory.name;
    return "Content Library";
  };

  return (
    <div className="fixed bottom-24 w-full pointer-events-none z-50">
      <div className="fixed left-1/2 transform -translate-x-1/2 bg-gray-darkest py-3.5 px-16 rounded-full text-center">
        <span className="text-lg opacity-90 text-white block mb-1.5">{getMessage()}</span>

        <span className="text-lg font-semibold text-white flex justify-center items-center gap-2">
          <img
            src={selectedFolderHeading ? folderOpenSm : driveWhite}
            alt="icon"
            loading="lazy"
            className="w-[18px] h-[18px]"
          />
          {getDestinationName()}
        </span>
      </div>
    </div>
  );
};

export default DragAndDropToast;

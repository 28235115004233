/** @jsxImportSource @emotion/react */
import { useEffect, useLayoutEffect, useRef } from "react";

import { useFlags } from "hook/useFlags";
import { setAiAssistantState, setAssistantPrompt } from "store/reducers/ai-assistant/aiAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";

import InputTextarea from "pages/ai-assistant/ai-assistant-input/InputTextArea";
import AIAssistantInputEnhancedActions from "pages/ai-assistant/AIAssistantInputEnhancedActions";
import { useAssistant } from "pages/ai-assistant/ai-assistant-input/hooks";
import SubmitButton from "../SubmitButton";
import { getTooltipMessage } from "./utils";
import { createInputFieldData } from "utils/assistants/fileUtils";
import { useDynamicInputValidation } from "./useDynamicInputValidation";

const DynamicInput = () => {
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const { submitMessage } = useAssistant();
  const promptRef = useRef<HTMLTextAreaElement | null>(null);

  const {
    acceptedFileTypes,
    maxTotalCount,
    fileCountLimitExceeded,
    multipleInputsProvided,
    hasUnsupportedFileTypes,
    disableSubmit,
    prompt,
  } = useDynamicInputValidation();

  const tooltipMessage = getTooltipMessage({
    fileCountLimitExceeded,
    multipleInputsProvided,
    acceptedFileTypes,
    hasUnsupportedFileTypes,
    maxTotalCount,
  });

  const activeSession = useAppSelector((root) => root.aiAssistantState.activeSession);
  const selectedFiles = useAppSelector((root) => root.aiAssistantState.selectedFiles);
  const streamState = useAppSelector((root) => root.aiAssistantState.streamState);
  const uploadedDocuments = useAppSelector((root) => root.aiAssistantState.uploadedDocuments);

  useEffect(() => {
    if (activeSession && (selectedFiles || uploadedDocuments || prompt)) {
      const inputField = createInputFieldData({
        contentLibraryFiles: selectedFiles,
        text: prompt,
        uploadedFiles: uploadedDocuments,
        inputType: INPUT_FIELD_OPTIONS.dynamic,
      });
      if (JSON.stringify(activeSession.input_field) !== JSON.stringify(inputField)) {
        dispatch(
          setAiAssistantState({
            activeSession: { ...activeSession, input_field: inputField },
          }),
        );
      }
    }
  }, [activeSession, selectedFiles, uploadedDocuments, dispatch, prompt]);

  useLayoutEffect(() => {
    promptRef.current?.focus();
  }, [activeSession?.id]);

  return (
    <div className="relative flex flex-col pb-6 gap-2 mx-4 lg:px-2 w-full lg:mx-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-[900px] 3xl:max-w-[1100px]">
      <div className="relative flex bg-white flex-col rounded-lg border shadow-sm overflow-hidden border-gray-300">
        <div className="relative flex flex-col">
          <div className="relative flex">
            <InputTextarea
              canSubmit={!disableSubmit}
              prompt={prompt}
              promptRef={promptRef}
              isStreamingInProgress={streamState.isStreamingInProgress}
              onSubmit={submitMessage}
              onChange={(e) => dispatch(setAssistantPrompt(e.target.value))}
              flags={flags}
              className="rounded-t-md w-full"
            />
            <SubmitButton submitDisabled={disableSubmit} tooltipMessage={tooltipMessage} />
          </div>
          {flags.documentAttachments && <AIAssistantInputEnhancedActions />}
        </div>
      </div>
    </div>
  );
};

export default DynamicInput;

/** @jsxImportSource @emotion/react */

import { ReactComponent as PinNavigation } from "Assets/svgs/pin-navigation.svg";
import { ReactComponent as UnpinNavigation } from "Assets/svgs/unpin-navigation.svg";
import { ReactComponent as VultronLogo } from "Assets/svgs/vultron-logo.svg";
import { ReactComponent as VultronLogoNoTitle } from "Assets/svgs/vultron-logo-no-title.svg";
import { useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import SideNavigationNavigationItem from "./side-navigation-navigation-tem/SideNavigationNavigationItem";
import Tooltip from "components/atoms/tooltip/Tooltip";
import tw from "twin.macro";
import { PROJECTS_NAV } from "const-values/testIds";
import { useSideNavigationTabs } from "./hooks";
import { useFlags } from "hook/useFlags";

interface SideNavigationProps {
  isPinned: boolean | undefined;
  setIsPinned: (isPinned: boolean) => void;
}

const SideNavigation = ({ isPinned, setIsPinned }: SideNavigationProps) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id")?.toLocaleLowerCase() || "";
  const [currentlyExpandedTab, setCurrentlyExpandedTab] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const navigationIsExpanded = isHovering || isPinned;
  const { primary, secondary } = useSideNavigationTabs(id);
  const flags = useFlags();

  return (
    <div
      className="absolute h-full bg-white w-[92px] border border-gray-300 flex flex-col justify-between z-[11] ease-in-out duration-300"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      css={[navigationIsExpanded ? tw`w-[230px]` : tw`w-[92px]`]}
    >
      <div className="overflow-x-hidden overflow-y-auto">
        <NavLink className="sticky top-0" to={`/dashboard/contracts`}>
          <div
            className="flex flex-col justify-center px-4 h-[72px] bg-white"
            css={[!navigationIsExpanded && tw`items-center justify-center`]}
            data-testid={PROJECTS_NAV.navigation.home}
          >
            {navigationIsExpanded ? (
              <VultronLogo className="w-[114px] h-[30px]" />
            ) : (
              <VultronLogoNoTitle className="w-[30px] h-[30px]" />
            )}
          </div>
        </NavLink>
        {/* PROJECT SECTION */}
        <div
          className="py-3 px-3 text-[#757575] flex flex-col gap-1]"
          css={[!navigationIsExpanded && tw`items-center justify-center`]}
        >
          {primary.map((tab) => {
            return (
              <SideNavigationNavigationItem
                key={tab.slug}
                tab={tab}
                id={id}
                navigationIsExpanded={navigationIsExpanded}
                currentlyExpandedTab={currentlyExpandedTab}
                setCurrentlyExpandedTab={setCurrentlyExpandedTab}
                to={tab.to}
              />
            );
          })}
        </div>
        {/* ADVANCED SECTION */}
        {!flags.removeAdvProjectFeatures && (
          <div
            className="px-3 text-[#757575] flex flex-col"
            css={[!navigationIsExpanded && tw`items-center justify-center`]}
          >
            <div className="text-xxs font-medium px-2 mb-2" css={[!navigationIsExpanded && tw`px-0`]}>
              VULTRON 1.0
            </div>
            {secondary.map((tab) => {
              return (
                <SideNavigationNavigationItem
                  key={tab.name}
                  tab={tab}
                  id={id}
                  navigationIsExpanded={navigationIsExpanded}
                  currentlyExpandedTab={currentlyExpandedTab}
                  setCurrentlyExpandedTab={setCurrentlyExpandedTab}
                />
              );
            })}
          </div>
        )}
      </div>
      {/* NAVIGATION PIN */}
      {navigationIsExpanded && (
        <div className="p-6 self-end">
          <Tooltip content={isPinned ? "Unpin navigation" : "Pin navigation open"}>
            <button
              onClick={() => setIsPinned(!isPinned)}
              className="h-8 w-8 rounded-md bg-[#F0F0F0] items-center justify-center flex"
            >
              {isPinned ? <UnpinNavigation /> : <PinNavigation />}
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default SideNavigation;

import { useState, useEffect } from "react";
import { Modal } from "components/organisms/modal";
import tw from "twin.macro";
import { Button } from "components/editor/components";

type Props = {
  showLinkModal: boolean;
  setShowLinkModal: (newValue: boolean) => void;
  initialUrl?: string;
  onSubmit: (url: string) => void;
  isEditing?: boolean;
};

export const EditorLinkModal = ({
  showLinkModal,
  setShowLinkModal,
  initialUrl = "https://",
  onSubmit,
  isEditing = false,
}: Props) => {
  const [link, setLink] = useState(initialUrl);

  useEffect(() => {
    setLink(initialUrl);
  }, [initialUrl]);

  const handleSubmit = () => {
    onSubmit(link);
    setShowLinkModal(false);
    setLink("https://");
  };

  const title = isEditing ? "Edit Link" : "Add Link";

  return (
    <Modal
      open={showLinkModal}
      onOpenChange={setShowLinkModal}
      contentProps={{ css: tw`w-[550px]` }}
      title={title}
      header={title}
      body={
        <div className="px-6 flex flex-col gap-3">
          <div className="flex">
            <span className="inline-flex py-2 px-4 items-center text-sm text-gray-900 bg-[#fafafa] border border-gray-300 rounded-l-lg">
              URL
            </span>
            <input
              type="text"
              className="text-gray-700 rounded-none rounded-r-lg p-2 border-l-0 border border-gray-300 w-full focus:outline-none"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              placeholder="https://example.com"
              autoFocus
            />
          </div>
          <div className="flex justify-end items-center mt-4 gap-2">
            <Button
              size="md"
              className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
              variant="outline"
              onClick={() => setShowLinkModal(false)}
            >
              Cancel
            </Button>
            <Button size="md" variant="primaryBlack" onClick={handleSubmit} className="bg-gray-darkest">
              {isEditing ? "Update" : "Add"}
            </Button>
          </div>
        </div>
      }
    />
  );
};

import { useEffect, useRef } from "react";
import { createPollingManager } from "utils/pollingManager";
import usePageVisibility from "./usePageVisibility";

const pollingManager = createPollingManager();

type UsePollingManagerOptions = {
  interval?: number;
};

export const usePollingManager = (
  callback: () => Promise<unknown> | unknown,
  { interval = 3000 }: UsePollingManagerOptions = {},
) => {
  const isPageVisible = usePageVisibility();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!isPageVisible) return;

    const unsubscribe = pollingManager.register(() => callbackRef.current(), interval);

    return () => {
      unsubscribe();
    };
  }, [isPageVisible, interval]);
};

/** @jsxImportSource @emotion/react */

import usePreviousDocuments from "hook/assistants/global-assistant/usePreviousDocuments/usePreviousDocuments";
import { useMemo } from "react";
import tw from "twin.macro";

interface RecentFilesModalBodyProps {
  setSelectedDocuments: React.Dispatch<React.SetStateAction<string[]>>;
}

const RecentFilesModalBody = ({ setSelectedDocuments }: RecentFilesModalBodyProps) => {
  const { documents, loading } = usePreviousDocuments();

  const lastFifteenUniqueDocuments = useMemo(() => {
    const uniqueDocuments = [];
    const seenNames = new Set<string>();

    for (let i = 0; i < documents.length; i++) {
      const doc = documents[i];
      if (!seenNames.has(doc.name)) {
        seenNames.add(doc.name);
        uniqueDocuments.push(doc);
        if (uniqueDocuments.length === 15) break;
      }
    }
    return uniqueDocuments;
  }, [documents]);

  const handleFileSelection = (id: string) => {
    setSelectedDocuments((prevSelectedDocuments) => {
      if (prevSelectedDocuments.includes(id)) {
        return prevSelectedDocuments.filter((docId) => docId !== id);
      } else {
        return [...prevSelectedDocuments, id];
      }
    });
  };

  if (loading)
    return (
      <div className="px-6 text-sm">
        Loading recent files
        <span className="loading-ellipsis" />
      </div>
    );

  return (
    <div className="overflow-auto mx-6 mb-6 rounded-md text-sm">
      {lastFifteenUniqueDocuments.map((document, i) => (
        <div
          key={document.id}
          className="border-b border-gray-200 pl-1 pr-10 py-3 whitespace-nowrap truncate flex items-center"
          css={[i === lastFifteenUniqueDocuments.length - 1 && tw`border-b-0`]}
        >
          <input type="checkbox" className="mr-2" onClick={() => handleFileSelection(document.id)} />
          <span>{document.name}</span>
        </div>
      ))}
    </div>
  );
};

export default RecentFilesModalBody;

import { useEffect, useMemo, useRef, useState } from "react";
import type { StepValue } from "../../types";
import { ArrowDown, ArrowUp } from "lucide-react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { uniqBy } from "lodash";
import { setHighlightedElementId, setShouldTriggerJump } from "store/reducers/extract/CurrentExtractionReducer";

const DocumentJumper = () => {
  const [jumpIndex, setJumpIndex] = useState(-1);
  const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);
  const step = useAppSelector((store) => store.currentExtractionState.currentExtraction?.step);
  const highlightedElementId = useAppSelector((store) => store.currentExtractionState.highlightedElementId);
  const activeDocumentId = useAppSelector((store) => store.currentExtractionState.activeDocument?.id);
  const shouldTriggerJump = useAppSelector((store) => store.currentExtractionState.shouldTriggerJump);
  const groupedFilteredRequirementsByDocument = useAppSelector(
    (store) => store.currentExtractionState.groupedFilteredRequirementsByDocument,
  );
  const currentBlocks = useAppSelector((store) => store.currentExtractionState.groupedBlocks.allFilteredBlocks);

  const filteredRequirements = useMemo(
    () => groupedFilteredRequirementsByDocument[activeDocumentId || ""] || [],
    [activeDocumentId, groupedFilteredRequirementsByDocument],
  );

  const prevStep = useRef<StepValue | undefined>(step);
  const dispatch = useAppDispatch();

  const currentBlocksByReq = useMemo(() => {
    const blocks = uniqBy(currentBlocks, (v) => v.requirement.requirement.id);
    if (filteredRequirements.length)
      return blocks.filter((row) => filteredRequirements.some(({ id }) => id === row.requirement.requirement.id));

    return blocks;
  }, [currentBlocks, filteredRequirements]);

  const jumpBlock = currentBlocksByReq[jumpIndex];

  useEffect(() => {
    setJumpIndex(
      currentBlocksByReq.findIndex((block) => highlightedElementId === block.requirement.requirement.element_id),
    );
  }, [currentBlocksByReq, highlightedElementId]);

  useEffect(() => {
    setJumpIndex(-1);
  }, [activeDocument?.id, step]);

  useEffect(() => {
    if (shouldTriggerJump && !!currentBlocksByReq.length) {
      setJumpIndex(0);
      dispatch(setShouldTriggerJump(false));
    }
  }, [currentBlocksByReq.length, dispatch, shouldTriggerJump]);

  useEffect(() => {
    if (jumpIndex < 0 || prevStep.current !== step) {
      prevStep.current = step;
      return;
    }
    if (!jumpBlock) return;

    const { requirement } = jumpBlock.requirement;
    if (!requirement.element_id) return;

    dispatch(setHighlightedElementId(requirement.element_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jumpIndex, step]);

  return (
    <div className="flex items-center gap-1">
      <button
        disabled={jumpIndex >= currentBlocksByReq?.length - 1}
        onClick={() => jumpIndex < currentBlocksByReq?.length - 1 && setJumpIndex((prev) => prev + 1)}
        className="w-7 h-7 flex justify-center items-center rounded-md shadow-sharp-thin backdrop-blur-lg text-white bg-black/70 duration-100 hover:bg-black disabled:bg-zinc-400 disabled:text-gray-200"
      >
        <ArrowDown size={14} />
      </button>
      <button
        disabled={jumpIndex <= 0}
        onClick={() => !!jumpIndex && setJumpIndex((prev) => prev - 1)}
        className="w-7 h-7 flex justify-center items-center rounded-md shadow-sharp-thin backdrop-blur-lg text-white bg-black/70 duration-100 hover:bg-black  disabled:bg-zinc-400 disabled:text-gray-200"
      >
        <ArrowUp size={14} />
      </button>
    </div>
  );
};

export default DocumentJumper;

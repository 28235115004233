import { useEffect } from "react";
import axios from "axios";
import { isSessionRefreshRequired, isJWTExpired } from "utils/timerUtils";
import useLogout from "hook/useLogout";
import { isSessionValidationBypassed } from "config/vultronConfig";
import * as logger from "utils/log";

export const useValidateUserSession = () => {
  // add interceptor to axios requests
  // check if their request has an Authorization header, if not pass
  // check if using SSO, if so, pass
  // check that session token exists and not expired
  // if expired, do an axios request to refresh token
  // if that fails, force relogin

  const tokenRefreshPath = "/users/auth0/token/refresh";
  const callbackPath = "/users/auth0/callback";
  const loginPath = "/users/auth0/login";
  const logoutPath = "/users/auth0/logout";
  const ignoreInterceptorPath = [loginPath, callbackPath, tokenRefreshPath, logoutPath];

  const logout = useLogout();

  const setAxiosInterceptor = () => {
    axios.interceptors.request.use(
      async (config) => {
        // Bypass interceptor for certain paths (login, token refresh, etc.)
        if (ignoreInterceptorPath.some((path) => config.url?.includes(path))) {
          return config;
        }

        if (!config.headers.Authorization) return config;

        const useSSO = localStorage.getItem("vultron_user_sso") === "true";
        let userToken = "";
        try {
          userToken = JSON.parse(localStorage.getItem("vultron_user_token") || '""') || "";
        } catch {}
        const syntheticToken = localStorage.getItem("synthetic_test_secret") || "";
        const localSession = localStorage.getItem("vultron_local_session") || false;
        if (useSSO) return config;
        if (syntheticToken) return config;
        if (localSession) return config;

        if (isJWTExpired(userToken)) {
          logout();
          throw new Error("User token is expired");
        }
        if (isSessionRefreshRequired()) {
          try {
            // Call the backend endpoint to refresh the token (include cookies)
            const response = await axios.post(tokenRefreshPath, {}, { withCredentials: true });
            const token = response.data.session_token;
            localStorage.setItem("vultron_user_token", JSON.stringify(token));
            config.headers.Authorization = `Bearer ${token}`;
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          } catch (error) {
            logger.error(error as Error, "Token refresh failed");
            throw error;
          }
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  };

  useEffect(() => {
    if (!isSessionValidationBypassed) {
      setAxiosInterceptor();
    }
    // eslint-disable-next-line
  }, []);
};

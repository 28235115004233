import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type BaseOpenState = {
  open: boolean;
  forceClose: boolean;
};

type State = {
  requirementExtraction: BaseOpenState;
  templateExtraction: BaseOpenState;
  smartResponse: BaseOpenState;
};

const initialState: State = {
  templateExtraction: {
    open: false,
    forceClose: false,
  },
  requirementExtraction: {
    open: false,
    forceClose: false,
  },
  smartResponse: {
    open: false,
    forceClose: false,
  },
};

const bannerReducer = createSlice({
  name: "bannerReducer",
  initialState,
  reducers: {
    setBannerState: (state: State, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
    resetBannerState: () => {
      return initialState;
    },
  },
});

export const { setBannerState, resetBannerState } = bannerReducer.actions;

export default bannerReducer.reducer;

/** @jsxImportSource @emotion/react */

import type { HTMLAttributes, PropsWithChildren } from "react";
import "twin.macro";

const ToastBanner = ({ children, ...props }: PropsWithChildren<{}> & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      tw="absolute flex flex-col right-0 bottom-0 z-[998] w-[360px] h-[115px] p-4 rounded-xl [box-shadow:0px_-1px_0.5px_rgba(205,205,205,0.5)] bg-gray-darkest text-white"
      {...props}
    >
      {children}
    </div>
  );
};

export default ToastBanner;

import type { LucideIcon } from "lucide-react";
import { Plus, Trash2, GripHorizontal } from "lucide-react";
import type { Editor } from "@tiptap/react";

interface TableOptionProps {
  icon: LucideIcon;
  label: string;
  onClick: () => void;
  isDelete?: boolean;
}

const TableOption = ({ icon: Icon, label, onClick, isDelete = false }: TableOptionProps) => (
  <button
    onClick={onClick}
    className={`w-full flex items-center gap-2 px-3 py-2 text-sm hover:bg-gray-100
      ${isDelete ? "text-red-600 hover:bg-red-50" : "text-gray-700"}`}
  >
    <Icon size={14} />
    <span>{label}</span>
  </button>
);

export const TableOptionsContent = ({ editor }: { editor: Editor }) => (
  <div className="min-w-[160px] py-1">
    <div className="pb-1 mb-1 border-b border-gray-200">
      <TableOption
        icon={Plus}
        label="Add column before"
        onClick={() => editor.chain().focus().addColumnBefore().run()}
      />
      <TableOption icon={Plus} label="Add column after" onClick={() => editor.chain().focus().addColumnAfter().run()} />
    </div>

    <div className="pb-1 mb-1 border-b border-gray-200">
      <TableOption icon={Plus} label="Add row above" onClick={() => editor.chain().focus().addRowBefore().run()} />
      <TableOption icon={Plus} label="Add row below" onClick={() => editor.chain().focus().addRowAfter().run()} />
    </div>

    <div className="pb-1 mb-1 border-b border-gray-200">
      <TableOption
        icon={GripHorizontal}
        label="Delete column"
        onClick={() => editor.chain().focus().deleteColumn().run()}
      />
      <TableOption icon={GripHorizontal} label="Delete row" onClick={() => editor.chain().focus().deleteRow().run()} />
    </div>

    <TableOption
      icon={Trash2}
      label="Delete table"
      onClick={() => editor.chain().focus().deleteTable().run()}
      isDelete
    />
  </div>
);

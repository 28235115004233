import { useState, useEffect } from "react";
import { attachPreviousDocument, fetchPreviousDocuments, createGlobalChatSession } from "api/api";
import type { ChatSessionDocument } from "types/Assistants/types";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import useAIAssistantOperations from "hook/useAIAssistantOperations";
import * as logger from "utils/log";
import { getChatSessionDocuments } from "store/reducers/writing-assistant/writingAssistantReducer";

export interface DocumentData {
  id: string;
  name: string;
  file_extension_type: string;
  download_url: string;
  secure_preview_url: string;
}

const usePreviousDocuments = () => {
  const [documents, setDocuments] = useState<ChatSessionDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { createSession } = useAIAssistantOperations();
  const dispatch = useAppDispatch();
  const activeSession = useAppSelector((store) => store.aiAssistantState.activeSession);

  const attachDocuments = async (documentIds: string[]) => {
    let sessionId = activeSession?.id;

    if (!sessionId) {
      try {
        const newSessionResponse = await createGlobalChatSession("New Session");
        sessionId = newSessionResponse.data.id;
        createSession("New Session", sessionId);
      } catch (err) {
        logger.error(err as Error, "Could not create session while attaching document");
        setError(err instanceof Error ? err.message : "Unknown error");
        return;
      }
    }

    try {
      const attachPromises = documentIds.map((documentId) => {
        return attachPreviousDocument(sessionId!, documentId, { chat_session_type: "global" });
      });
      await Promise.all(attachPromises);
      dispatch(getChatSessionDocuments(sessionId));
    } catch (err) {
      logger.error(err as Error, "Error attaching document");
      setError(err instanceof Error ? err.message : "Unknown error");
    }
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const { data } = await fetchPreviousDocuments();
        setDocuments(data);
      } catch (err) {
        logger.error(err as Error, "Error fetching previous documents");
        setError(err instanceof Error ? err.message : "Unknown error");
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  return { attachDocuments, documents, loading, error };
};

export default usePreviousDocuments;

export const extractFirstHeaderText = (markdownContent: string): string | null => {
  const headerPattern = /^(#{1,6})\s+(.*)$/m;

  const match = markdownContent.match(headerPattern);

  if (match) {
    return match[2].trim();
  }

  return null;
};

import type { ComponentProps } from "react";
import { useEffect, useRef, useState } from "react";
import { Modal } from "../modal";
import type { Storage } from "components/copilot/CopilotSchemaImmutableTypes";
import { Button } from "components/editor/components";
import { Split, Trash2 } from "lucide-react";
import useRequirementOperations from "hook/useRequirementOperations";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch } from "store/storeTypes";
import tw from "twin.macro";
import { useStorage } from "YJSProvider/createYJSContext";
import { isEqual } from "lodash";

interface Props extends Pick<ComponentProps<typeof Modal>, "open" | "onOpenChange"> {
  splitRequirementId: string;
  retainSection?: boolean;
}

const SplitModal = ({ splitRequirementId, retainSection, ...props }: Props) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { splitRequirements } = useRequirementOperations();
  const [requirementSplits, setRequirementSplits] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const complianceMatrixRow = useStorage(
    (storage) =>
      (storage.compliance_matrix as Storage["compliance_matrix"]).find(
        (req) => req.requirement.id === splitRequirementId,
      ),
    isEqual,
  );

  useEffect(() => {
    if (props.open) setRequirementSplits([]);
  }, [props.open]);

  return (
    <Modal
      title="Split"
      header="Split"
      description="Highlight the text to split into a new requirement."
      contentProps={{ css: tw`max-w-[1100px] w-[75vw]` }}
      footerProps={{ css: tw`mt-4` }}
      body={
        <div className="px-6 flex flex-row gap-4 h-full">
          <div className="h-full flex-1">
            <div className="text-sm font-medium text-slate-600">Original Requirement</div>
            <div
              className="text-sm max-h-[55vh] h-[55vh] overflow-y-auto whitespace-pre-wrap bg-stone-100 rounded-md p-2 selection:bg-[#e1d6fd] select-text"
              onMouseUp={() => {
                const selectedText = window.getSelection()?.toString();
                if (selectedText) {
                  setRequirementSplits((prev) => [...prev, selectedText]);
                  setTimeout(() => scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight), 0);
                }
              }}
            >
              {complianceMatrixRow?.requirement.content || complianceMatrixRow?.requirement.summarized_content}
            </div>
          </div>
          <div className="mt-6 w-px bg-slate-200" />
          <div className="relative flex-1">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-slate-600">Newly Split Requirements</div>
              <div className="flex gap-3 items-center">
                <button
                  onClick={() => setRequirementSplits([])}
                  className="text-red-500 duration-100 text-xs hover:text-red-400"
                >
                  Clear
                </button>
                <div className="text-xs text-slate-600">{requirementSplits.length} requirements</div>
              </div>
            </div>
            <div className="relative flex h-[calc(100%-20px)] border flex-1 border-gray-light overflow-hidden rounded-md">
              <div
                ref={scrollRef}
                className="px-2 flex pointer-events-auto flex-1 h-[55vh] max-h-[55vh] overflow-y-auto"
              >
                {!requirementSplits.length && (
                  <div className="flex flex-1 items-center gap-3 flex-col justify-center h-full text-sm text-slate-400">
                    <Split size={30} className="text-action" />
                    Highlight text to split.
                  </div>
                )}
                {!!requirementSplits.length && (
                  <div className="py-2 flex flex-1 flex-col gap-1 h-fit">
                    {requirementSplits.map((splitReq, i) => (
                      <div
                        key={`${splitReq}-${i}`}
                        className="relative rounded-md p-3 shadow-expanded gap-4 border border-gray-light"
                      >
                        <div className="text-xs pr-8 text-slate-700 whitespace-pre-wrap">{splitReq}</div>
                        <button
                          className="absolute top-2 right-2 rounded p-1 flex duration-150 text-slate-600 hover:bg-slate-100 hover:text-slate-900"
                          onClick={() => setRequirementSplits((prev) => prev.filter((_, idx) => idx !== i))}
                        >
                          <Trash2 size={14} />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="absolute pointer-events-none right-3 left-0 -bottom-[2px] h-7 bg-gradient-to-t from-white to-transparent" />
            </div>
          </div>
        </div>
      }
      footer={
        <>
          <Button
            size="md"
            variant="outline"
            className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
            onClick={() => props.onOpenChange?.(false)}
          >
            Cancel
          </Button>
          <Button
            size="md"
            variant="primary"
            disabled={!requirementSplits.length}
            onClick={() => {
              splitRequirements(splitRequirementId, requirementSplits, retainSection);
              dispatch(setCheckedState({}));
              props.onOpenChange?.(false);
            }}
          >
            <Split size={14} className="mr-1" />
            Import
          </Button>
        </>
      }
      {...props}
    />
  );
};

export default SplitModal;

/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { RoomProvider } from "utils/yjs-configs/ai-assistant/yjs.config";
import { useAppSelector } from "store/storeTypes";
import { useLocalStorage } from "hook/useLocalStorage";
import { Outlet } from "react-router-dom";
import AIAssistantSidebar from "../ai-assistant-sidebar";
import LoadingText from "./LoadingText";
import DrawerOpener from "components/atoms/drawer-opener";

const AIAssistantRoom = () => {
  const { currentUser } = useAppSelector((store) => store.auth);
  const { localValue: currentWorkspaceId } = useLocalStorage("vultron_workspace_id", "");

  // Sidebar state control
  const [isInitial, setIsInitial] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  if (!currentUser?.id)
    return (
      <div className="my-auto h-full flex items-center justify-center">
        <LoadingText />
      </div>
    );

  const aiAssistantRoom = `ai_assistant_${currentUser?.id}_${currentWorkspaceId}`;

  return (
    <RoomProvider id={aiAssistantRoom} initialPresence={{}} fallback={<LoadingText />}>
      <div className="bg-white relative z-0 flex h-full w-full overflow-hidden">
        <AIAssistantSidebar isInitial={isInitial} sidebarOpen={sidebarOpen} />

        <div className="relative flex flex-col flex-1">
          <div className="flex flex-col justify-center relative h-full overflow-hidden">
            <Outlet />
          </div>
          <DrawerOpener
            isInitial={isInitial}
            setIsInitial={setIsInitial}
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={sidebarOpen}
          />
        </div>
      </div>
    </RoomProvider>
  );
};

export default AIAssistantRoom;

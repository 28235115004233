import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { useMemo } from "react";
import type PersistedStorageKeys from "types/PersistedStorage";

const useOutlineGenerationBannerState = (extractionId: string) => {
  const defaultState = useMemo(
    () => ({
      [extractionId]: {
        ignored: false,
        timestamp: Date.now(),
      },
    }),
    [extractionId],
  );
  return usePersistedStorage<PersistedStorageKeys["outlineGeneratedBannerIgnoredState"]>(
    "outlineGeneratedBannerIgnoredState",
    defaultState,
  );
};

export default useOutlineGenerationBannerState;

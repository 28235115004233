import { useEffect, useMemo, useRef, useState } from "react";
import { Checkbox } from "components/atoms/checkbox";
import { CircleX, Plus } from "lucide-react";
import { PopoverClose } from "@radix-ui/react-popover";
import { CORE_CRITERIA, SENSITIVITY_OPTIONS, SensitivityStatus } from "./constants";
import { ReactComponent as ScanCriteria } from "../../../Assets/core-criteria-scan.svg";
import { ReactComponent as ScanLevels } from "../../../Assets/scan-levels.svg";
import { ReactComponent as ScanPlus } from "../../../Assets/scan-plus.svg";
import type { CriteriaParams } from "./hooks";
import useProposalReviewEngine from "./hooks";
import Slider from "components/molecules/slider";
import { addDocIdToReviews } from "store/reducers/proposal/proposalReducer";
import { useAppDispatch } from "store/storeTypes";

const ReviewEnginePopover = ({ proposalDocId }: { proposalDocId: string }) => {
  const [coreCriteria, setCoreCriteria] = useState<string[]>([]);
  const [strictness, setSensitivity] = useState<number>(SensitivityStatus.Concise);
  const [userDefinedCriteria, setUserDefinedCriteria] = useState<string[]>([""]);
  const fetchProposalReviewEngine = useProposalReviewEngine();
  const dispatch = useAppDispatch();
  const newestInputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (index: number, newValue: string) => {
    setUserDefinedCriteria((prev) => {
      const newCriteria = [...prev];
      newCriteria[index] = newValue;
      return newCriteria;
    });
  };

  const handleRemoveInput = (index: number) => {
    setUserDefinedCriteria((prev) => prev.filter((_, i) => i !== index));
  };

  const criteriaEntries = useMemo(() => Object.entries(CORE_CRITERIA), []);

  const hasAddedCriteria = useMemo(() => {
    const hasNonEmptyCoreCriteria = coreCriteria.length > 0;
    const hasNonEmptyUserDefinedCriteria = userDefinedCriteria.some((criteria) => criteria.trim() !== "");

    return hasNonEmptyCoreCriteria || hasNonEmptyUserDefinedCriteria;
  }, [coreCriteria, userDefinedCriteria]);

  useEffect(() => {
    if (newestInputRef.current) {
      newestInputRef.current.focus();
    }
  }, [userDefinedCriteria]);

  const handleStartReview = () => {
    const params: CriteriaParams = {};
    dispatch(addDocIdToReviews(proposalDocId));

    const filteredCustomCriteria = userDefinedCriteria.filter((value) => value.trim() !== "");

    if (coreCriteria.length > 0) {
      params.criteria = coreCriteria;
    }
    if (filteredCustomCriteria.length > 0) {
      params.custom_criteria = filteredCustomCriteria;
    }

    params.strictness = SENSITIVITY_OPTIONS[strictness].value;

    fetchProposalReviewEngine(proposalDocId, params);
  };

  return (
    <div className="scrollbar-none">
      <div className="flex flex-col gap-1 min-w-[415px] max-w-[415px] max-h-[85vh] overflow-y-auto">
        <div className="font-medium text-lg px-4 pb-2 pt-3">Review</div>
        <div className="flex flex-col gap-0.5 px-4 pb-3">
          <div className="font-medium text-[15px] flex items-center justify-start gap-1 mb-1">
            <ScanCriteria className="h-[15px] w-[15px]" /> Core Criteria
          </div>
          <div className="text-[#7C8186] text-xs mb-2">Review against a set of pre-defined criteria.</div>
          <button
            className="flex font-medium items-center justify-start text-xs text-[#1987EA] mb-1 w-20"
            onClick={() => {
              if (coreCriteria.length === criteriaEntries.length) {
                setCoreCriteria([]);
              } else {
                setCoreCriteria(criteriaEntries.map(([key]) => key));
              }
            }}
          >
            {coreCriteria.length === criteriaEntries.length ? "Deselect All" : "Select All"}
          </button>
          {criteriaEntries.map(([key, value]) => (
            <span className="text-sm flex items-center gap-1.5 mb-1" key={key}>
              <Checkbox
                className="my-0.5 ml-0.5 mr-1 h-[10px] w-[10px]"
                label={value}
                checked={coreCriteria.includes(key)}
                onCheck={(checked) => {
                  if (checked) {
                    setCoreCriteria((prev) => [...prev, key]);
                  } else {
                    setCoreCriteria((prev) => prev.filter((c) => c !== key));
                  }
                }}
                size="sm"
                id={key}
              />
              <label htmlFor={key} className="cursor-pointer">
                {value}
              </label>
            </span>
          ))}

          <hr className="border-[#E5E5E5] my-4" />
          <div className="flex items-center justify-start gap-1 mb-1">
            <ScanPlus className="h-[15px] w-[15px]" />{" "}
            <div className="text-[15px]  font-medium">User-Defined Criteria</div>
            <div className="ml-1 text-xxxs px-1.5 py-1 rounded text-gray-mid bg-[#e6e9eb] leading-tight">BETA</div>
          </div>
          <div className="flex justify-between items-center mb-1.5">
            <div className="text-[#7C8186] text-xs">Review against a set of user-defined criteria.</div>
            <button
              className="flex items-center justify-center text-xs text-gray-500 underline focus:outline-none h-5 w-5 bg-gray-lightest border rounded-sm hover:bg-hover-default"
              onClick={() => setUserDefinedCriteria([...userDefinedCriteria, ""])}
            >
              <Plus size={15} />
            </button>
          </div>
          {userDefinedCriteria.map((criteria, i) => (
            <div className="relative flex items-center mt-1" key={i}>
              <input
                type="text"
                className="h-8 w-full px-2 border bg-gray-lightest rounded text-xs focus:border-gray-900 focus:outline-none pr-10 overflow-hidden text-ellipsis whitespace-nowrap hover:bg-hover-default"
                placeholder="+ Insert criteria"
                value={criteria}
                onChange={(e) => handleInputChange(i, e.target.value)}
                ref={i === userDefinedCriteria.length - 1 && i !== 0 ? newestInputRef : null}
              />
              {userDefinedCriteria.length > 1 && (
                <button
                  className="absolute right-0 top-0 h-8 px-2 text-gray-500 bg-transparent border-none cursor-pointer focus:outline-none"
                  onClick={() => handleRemoveInput(i)}
                >
                  <CircleX size={15} />
                </button>
              )}
            </div>
          ))}

          <hr className="border-[#E5E5E5] my-4" />
          <div className="font-medium text-[15px]  flex items-center justify-start gap-1 mb-3">
            <ScanLevels className="h-[15px] w-[15px]" /> Review Level
          </div>
          <div className="text-[13px] font-medium">{SENSITIVITY_OPTIONS[strictness].label}</div>
          <div className="text-[#7C8186] text-xs mb-2">{SENSITIVITY_OPTIONS[strictness].description}</div>
          <Slider
            min={0}
            max={2}
            step={1}
            defaultValue={[strictness]}
            onValueChange={(value) => setSensitivity(value[0])}
          />
        </div>
        <PopoverClose
          className={`min-h-8 mx-3 mb-3 flex items-center justify-center gap-1.5 rounded border text-white text-xs shadow-[0_2px_0_rgba(0,0,0,0.04)] hover:bg-gradient-to-r enabled:hover:from-[#333333] enabled:hover:to-[#5e5d5d] ${
            !hasAddedCriteria ? "bg-gray-200" : "bg-gradient-to-r from-[#1E1E1E] to-[#585858]"
          }`}
          disabled={!hasAddedCriteria}
          onClick={() => {
            handleStartReview();
          }}
        >
          Start Review
        </PopoverClose>
      </div>
    </div>
  );
};

export default ReviewEnginePopover;

/** @jsxImportSource @emotion/react */

import type { ComponentProps } from "react";
import { useEffect, useState } from "react";
import { getFile } from "api/api";
import type { WorkspaceFile } from "types/FileStorage";
import { getFileIcon } from "utils/getFileIcon";
import tw from "twin.macro";
import { Skeleton } from "components/molecules/skeleton";
import { Download } from "lucide-react";
import Popover from "components/atoms/popover";
import { dateFormatter } from "utils/timerUtils";

interface SourcePopoverProps extends Partial<ComponentProps<typeof Popover>> {
  source: { name: string; extension?: string; sourceContent?: string; id: string };
}

const SourcePopover = ({ source, children, contentProps, onOpenChange, ...props }: SourcePopoverProps) => {
  const { name, extension, id, sourceContent } = source;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [foundFile, setFoundFile] = useState<WorkspaceFile>();

  useEffect(() => {
    if (!id || !open) return;
    setIsLoading(true);
    getFile(id)
      .then((res) => {
        const file: WorkspaceFile = res?.data;
        setFoundFile(file);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));

    return () => {
      setFoundFile(undefined);
    };
  }, [id, open]);

  return (
    <Popover
      open={open}
      onOpenChange={(o) => {
        if (o) setIsLoading(true);
        setOpen(o);
        onOpenChange?.(o);
      }}
      contentProps={{ css: [tw`w-[400px] max-w-[400px]`], ...contentProps }}
      content={
        <div className="flex flex-col pt-4">
          <div className="flex gap-3 pl-4 pr-6 items-center">
            {isLoading ? (
              <div className="flex gap-3 items-center">
                <Skeleton borderRadius={4} height={41} width={33} />
                <div className="flex flex-col gap-1">
                  <Skeleton borderRadius={2} height={14} width={200} />
                  <Skeleton borderRadius={2} height={12} width={80} />
                </div>
              </div>
            ) : (
              <>
                <img
                  src={getFileIcon(foundFile?.file_extension_type || extension)}
                  alt=""
                  className="w-[33px] h-[41px]"
                />
                <div className="flex flex-col gap-0.5">
                  <div
                    className="flex font-semibold items-baseline text-sm text-stone-900 break-all line-clamp-2 pr-4"
                    css={[foundFile && tw`items-center`]}
                  >
                    {foundFile?.name || name}
                    {!foundFile && (
                      <span className="text-xs text-gray-500 ml-1.5 font-medium whitespace-nowrap">(Deleted)</span>
                    )}
                    {foundFile && (
                      <a
                        href={foundFile.download_url}
                        download={foundFile?.name || name}
                        className="text-[18px] ml-1.5 pb-0.5 text-action duration-150 hover:text-action-hover"
                      >
                        <Download size={18} />
                      </a>
                    )}
                  </div>
                  {foundFile?.created_at && (
                    <div className="font-normal text-xs text-gray-500">
                      {dateFormatter(foundFile.created_at, "MMMM DD, YYYY")}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div
            className="text-slate-700 pt-4 pb-3 pl-4 pr-6 text-xs text-gray-primary font-normal break-words whitespace-pre-line max-h-[250px] overflow-y-auto"
            css={[isLoading && tw`max-h-fit`]}
          >
            {isLoading ? (
              <Skeleton
                borderRadius={2}
                count={6}
                height={12}
                width="100%"
                containerClassName="flex flex-col gap-1.5"
              />
            ) : (
              sourceContent
            )}
          </div>
        </div>
      }
      {...props}
    >
      {children}
    </Popover>
  );
};

export default SourcePopover;

import { Checkbox } from "components/atoms/checkbox";
import Tooltip from "components/atoms/tooltip";
import type { BaseFile, Subdirectory } from "types/FileStorage";
import { styles } from "./styles";
import { useRef } from "react";
import folderIcon from "Assets/folderIcon.svg";

type Props = {
  shouldRemoveCheckbox: boolean;
  isLoading: boolean;
  folder: Subdirectory;
  selectedFiles: string[];
  setCurrentNestedDriveState: (value: string) => void;
  handleSelectedFiles: (checked: boolean, files: BaseFile[]) => void;
};

const FolderRow = ({
  shouldRemoveCheckbox,
  isLoading,
  setCurrentNestedDriveState,
  folder,
  selectedFiles,
  handleSelectedFiles,
}: Props) => {
  const { name, id, all_nested_files } = folder;
  const folderNameRef = useRef<HTMLDivElement | null>(null);
  const canSelect = !isLoading && !!all_nested_files?.length;

  return (
    <div className={styles.selectableCheckboxItemRow}>
      <Tooltip
        key={id}
        disabled={(folderNameRef.current?.clientWidth || 0) < 314}
        disableHoverableContent
        content={name}
        contentProps={{ side: "left", align: "end" }}
      >
        <button
          disabled={!canSelect}
          onClick={() => {
            setCurrentNestedDriveState(id);
          }}
          className="text-stone-800 flex items-center gap-2 text-sm flex-1 truncate text-start py-1.5 hover:text-stone-500 disabled:cursor-not-allowed disabled:opacity-40 disabled:text-stone-800"
        >
          <img src={folderIcon} alt="" className="h-[18px] w-[18px] -m-0.5" />
          <div ref={folderNameRef} className="flex items-center gap-1 min-w-0">
            <div className="truncate">{name}</div>

            <span className="text-xxs h-[18px] text-gray-500">
              ({all_nested_files?.length || 0} file
              {all_nested_files?.length !== 1 && "s"})
            </span>
          </div>
        </button>
      </Tooltip>
      {!shouldRemoveCheckbox && (
        <Checkbox
          disabled={!canSelect}
          checked={!!all_nested_files?.length && all_nested_files.every((file) => selectedFiles.includes(file.id))}
          onCheck={(checkValue) => handleSelectedFiles(checkValue, all_nested_files || [])}
        />
      )}
    </div>
  );
};

export default FolderRow;

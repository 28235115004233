import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import type { SendMessageVariables } from "./hooks";
import { useAssistantActions } from "./hooks";

interface AssistantContextType {
  submitMessage: (props?: Partial<SendMessageVariables>) => void;
  refreshMessage: (refreshId: string) => void;
}

const AssistantContext = createContext<AssistantContextType | undefined>(undefined);

interface AssistantProviderProps {
  children: ReactNode;
}

export const AssistantProvider: React.FC<AssistantProviderProps> = ({ children }) => {
  const { refreshMessage, submitMessage } = useAssistantActions();

  const assistantActions = useMemo(() => ({ submitMessage, refreshMessage }), [refreshMessage, submitMessage]);

  return <AssistantContext.Provider value={assistantActions}>{children}</AssistantContext.Provider>;
};

export const useAssistant = (): AssistantContextType => {
  const context = useContext(AssistantContext);
  if (!context) {
    throw new Error("useAssistant must be used within an AssistantProvider");
  }
  return context;
};

import { InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import { REQUIREMENT_CLASSNAMES } from "./document-display/constants";
import type { Dispatch, SetStateAction } from "react";

let timeout: NodeJS.Timeout;

export const handleKeyboardSearch = (event: KeyboardEvent, setUserIsSearching: Dispatch<SetStateAction<boolean>>) => {
  const isSearchShortcut = (event.metaKey || event.ctrlKey) && event.key === "f";

  if (isSearchShortcut) {
    event.preventDefault();
    setUserIsSearching(true);
  }
};

export const highlightAndScrollToElement = (highlightedElementId: string) => {
  const blockNodes = document.querySelectorAll(`[data-element='${highlightedElementId}']`);
  blockNodes[0]?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });

  const highlightedSelectedRequirement = document.querySelectorAll(
    `.${REQUIREMENT_CLASSNAMES.selected}, .${REQUIREMENT_CLASSNAMES.assigned}, .${REQUIREMENT_CLASSNAMES.extracted}`,
  );

  const nodeToClassName = (node: Element) => {
    if (node.classList.contains(REQUIREMENT_CLASSNAMES.extracted)) {
      return "highlighted-extracted-requirement";
    } else if (node.classList.contains(REQUIREMENT_CLASSNAMES.selected)) {
      return "highlighted-selected-requirement";
    } else if (node.classList.contains(REQUIREMENT_CLASSNAMES.assigned)) {
      return "highlighted-assigned-requirement";
    }
    return "";
  };

  highlightedSelectedRequirement?.forEach((node) => node.classList.remove(nodeToClassName(node)));

  clearTimeout(timeout);
  blockNodes.forEach((node) => {
    node?.classList.add(nodeToClassName(node));

    timeout = setTimeout(() => {
      node?.classList.remove(nodeToClassName(node));
    }, 8000);
  });
};

export const isInstantDraftStarted = (instantDraftStatus?: InstantDraftStatus) =>
  (instantDraftStatus || InstantDraftStatus.Todo) >= InstantDraftStatus.Pending;

const errors = {
  REQUIREMENTS_NOT_SELECTED:
    "Please confirm that in Step 1, Select Volumes & Sections, you have selected sections that contain requirements, as sections without requirements cannot be used to generate an Instant Draft.",
  INCOMPLETE_CONFIRMATION:
    "Please confirm that in Step 2, Configure Instant Draft, all documents and capture intelligence are properly set up and completed to for optimal results. These steps are important for generating a detailed and comprehensive proposal draft.",
  IN_PROGRESS_OR_LOADING:
    "The Instant Draft Generation is in progress. If you don't see any updates, please refresh the page and try again. If the issue persists, please contact support@vultron.ai for assistance.",
};

export function getErrorMessage(requirementsSelected: boolean, confirmationsIncomplete: boolean) {
  if (!requirementsSelected) {
    return errors.REQUIREMENTS_NOT_SELECTED;
  }

  if (confirmationsIncomplete) {
    return errors.INCOMPLETE_CONFIRMATION;
  }

  return errors.IN_PROGRESS_OR_LOADING;
}

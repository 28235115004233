import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import type { InternalDocument } from "types/Project";

type State = {
  reviewsInProgress: string[];
  proposalsWithSuccessfulReviews: string[];
  proposalsWithCanceledReviews: string[];
  activeProjectFile: InternalDocument | null;
  documentViewerOpen: boolean;
  reviewEngineOpen: boolean;
};

const initialState: State = {
  reviewsInProgress: [],
  proposalsWithSuccessfulReviews: [],
  proposalsWithCanceledReviews: [],
  activeProjectFile: null,
  documentViewerOpen: false,
  reviewEngineOpen: false,
};

const proposalReducer = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    resetProposalState: () => {
      return cloneDeep(initialState);
    },
    addDocIdToReviews: (state, action: PayloadAction<string>) => {
      state.reviewsInProgress.push(action.payload);
    },
    removeDocIdFromReviews: (state, action: PayloadAction<string>) => {
      state.reviewsInProgress = state.reviewsInProgress.filter((id) => id !== action.payload);
    },
    addDocIdToSuccessfulReviews: (state, action: PayloadAction<string>) => {
      const docId = action.payload;

      if (!state.proposalsWithSuccessfulReviews.includes(docId)) {
        state.proposalsWithSuccessfulReviews.push(docId);
      }
    },
    removeDocIdFromSuccessfulReviews: (state, action: PayloadAction<string>) => {
      state.proposalsWithSuccessfulReviews = state.proposalsWithSuccessfulReviews.filter((id) => id !== action.payload);
    },
    addDocIdToCanceledReviews: (state, action: PayloadAction<string>) => {
      state.proposalsWithCanceledReviews.push(action.payload);
    },
    removeDocIdToCanceledReviews: (state, action: PayloadAction<string>) => {
      state.proposalsWithCanceledReviews = state.proposalsWithCanceledReviews.filter((id) => id !== action.payload);
    },
    setActiveProjectFile: (state, action: PayloadAction<State["activeProjectFile"]>) => {
      const file = action.payload;
      state.activeProjectFile = file;
      if (file && !state.documentViewerOpen) state.documentViewerOpen = true;
    },
    setDocumentViewerOpen: (state, action: PayloadAction<State["documentViewerOpen"]>) => {
      state.documentViewerOpen = action.payload;
    },
    setReviewEngineOpen: (state, action: PayloadAction<State["reviewEngineOpen"]>) => {
      state.reviewEngineOpen = action.payload;
    },
  },
});

export const {
  addDocIdToReviews,
  removeDocIdFromReviews,
  addDocIdToSuccessfulReviews,
  removeDocIdFromSuccessfulReviews,
  addDocIdToCanceledReviews,
  removeDocIdToCanceledReviews,
  setActiveProjectFile,
  setDocumentViewerOpen,
  resetProposalState,
  setReviewEngineOpen,
} = proposalReducer.actions;

export default proposalReducer.reducer;

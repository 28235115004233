/** @jsxImportSource @emotion/react */
import type { To } from "react-router-dom";
import tw from "twin.macro";
import { motion } from "framer-motion";
import { useRowActions } from "./hooks";
import type { FC } from "react";
import { useEffect, useState, useRef } from "react";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { DEFAULT_SESSION_NAME } from "./constants";
import type { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import SessionEditor from "./SessionEditor";
import SessionDisplay from "./SessionDisplay";
import RowOptions from "./RowOptions";
import { useAppSelector } from "store/storeTypes";
import { useIsSessionInTasks } from "hook/assistants/hooks";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";

export type AssistantSidebarRowProps = {
  to?: To;
  isActive: boolean;
  session: ToImmutable<AIAssistantSession | WritingAssistantSession>;
  archiveSession: (sessionId: string) => void;
  updateSession: (sessionId: string, properties: Partial<AIAssistantSession | WritingAssistantSession>) => void;
  sessionEditable: string;
  firstMsgBody?: string;
  onEditComplete?: () => void;
  onEditTriggered: () => void;
  onDelete: () => void;
  onRowSelected?: () => void;
  isReady?: boolean;
  onReadyChange?: (sessionId: string, ready: boolean) => void;
};

const AssistantSidebarRow: FC<AssistantSidebarRowProps> = ({
  to,
  isActive,
  session,
  archiveSession,
  updateSession,
  sessionEditable,
  firstMsgBody,
  onEditComplete,
  onEditTriggered,
  onDelete,
  onRowSelected,
  isReady = false,
  onReadyChange,
}) => {
  const [localSessionName, setLocalSessionName] = useState(session.name);
  const actionItems = useRowActions({ session, onEditTriggered, onDelete });
  const [optionsOpen, setOptionsOpen] = useState(false);
  const { uploadChatDocumentTasks } = useAppSelector((state) => state.aiAssistantState);
  const isCurrentSessionInTasks = useIsSessionInTasks(uploadChatDocumentTasks, session.id);

  useEffect(() => {
    if (session.name === DEFAULT_SESSION_NAME && firstMsgBody && isActive) {
      updateSession(session.id, { name: firstMsgBody.slice(0, 150) });
    }
  }, [isActive, firstMsgBody, session.id, session.name, updateSession]);

  useEffect(() => {
    setLocalSessionName(session.name);
  }, [session.name]);

  const isEditing = sessionEditable === session.id;

  const prevInTasks = useRef(isCurrentSessionInTasks);
  useEffect(() => {
    if (onReadyChange) {
      if (prevInTasks.current && !isCurrentSessionInTasks && !isActive) {
        onReadyChange(session.id, true);
      }
    }
    prevInTasks.current = isCurrentSessionInTasks;
  }, [isCurrentSessionInTasks, isActive, onReadyChange, session.id]);

  const handleRowSelected = () => {
    if (onReadyChange && isReady) {
      onReadyChange(session.id, false);
    }
    if (onRowSelected) {
      onRowSelected();
    }
  };

  return (
    <motion.div
      key={session.id}
      layout
      layoutId={session.id}
      animate="visible"
      title={localSessionName}
      css={[
        isActive && tw`relative z-[1] !bg-layout-gray-light-active`,
        optionsOpen && tw`bg-layout-gray-light-hover`,
        !isActive && isEditing && tw`hover:bg-transparent`,
      ]}
      className="relative group overflow-hidden rounded-lg hover:bg-layout-gray-light-hover min-h-9"
    >
      {isEditing ? (
        <SessionEditor
          localSessionName={localSessionName}
          setLocalSessionName={setLocalSessionName}
          session={session}
          updateSession={updateSession}
          onEditComplete={onEditComplete}
        />
      ) : (
        <SessionDisplay
          to={to}
          onRowSelected={handleRowSelected}
          localSessionName={localSessionName}
          isActive={isActive}
          session={session}
        />
      )}
      {!isEditing && (
        <RowOptions
          isActive={isActive}
          isCurrentSessionInTasks={isCurrentSessionInTasks}
          isReady={isReady}
          optionsOpen={optionsOpen}
          setOptionsOpen={setOptionsOpen}
          actionItems={actionItems}
          archiveSession={archiveSession}
          sessionId={session.id}
        />
      )}
    </motion.div>
  );
};

export default AssistantSidebarRow;

import usePreviousDocuments from "hook/assistants/global-assistant/usePreviousDocuments/usePreviousDocuments";
import { toggleRecentFilesModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";

interface RecentFilesModalFooterProps {
  selectedDocuments: string[];
}

const RecentFilesModalFooter = ({ selectedDocuments }: RecentFilesModalFooterProps) => {
  const dispatch = useAppDispatch();
  const { attachDocuments } = usePreviousDocuments();

  const handleAttachDocuments = () => {
    attachDocuments(selectedDocuments);
    dispatch(toggleRecentFilesModal({ open: false }));
  };

  return (
    <div className="flex gap-1.5">
      <button
        className="text-sm rounded-md border border-gray-300 px-3 py-1.5 hover:bg-gray-50"
        onClick={() => dispatch(toggleRecentFilesModal({ open: false }))}
      >
        Close
      </button>
      <button
        disabled={!selectedDocuments.length}
        className="text-sm rounded-md text-white bg-gray-900 px-3 py-1.5 disabled:bg-gray-200"
        onClick={() => handleAttachDocuments()}
      >
        Attach
      </button>
    </div>
  );
};

export default RecentFilesModalFooter;

/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { useAppSelector } from "store/storeTypes";
import { createMessageFeedback } from "api/api";
import * as logger from "utils/log";
import FeedbackButtons from "./FeedbackButtons";
import FeedbackOptionsPanel from "./FeedbackOptionsPanel";
import { EXPLANATION_OPTIONS, RESPONSE_OPTIONS } from "./constants";

interface FeedbackData {
  chat_session_type: "global";
  label: "positive" | "negative";
  score: 1.0 | 0.0;
  explanation?: string;
}

export type ResponseOption = "none" | "positive" | "negative";

const ResponseFeedback = () => {
  const { activeSession } = useAppSelector((root) => root.aiAssistantState);

  const [otherExplanation, setOtherExplanation] = useState<string>("");
  const [response, setResponse] = useState<ResponseOption>(RESPONSE_OPTIONS.NONE);
  const [selectedExplanation, setSelectedExplanation] = useState<string | undefined>(undefined);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showTextArea, setShowTextArea] = useState<boolean>(false);

  const provideMessageFeedback = async (
    sessionId: string,
    messageId: string,
    feedbackData: FeedbackData,
  ): Promise<void> => {
    try {
      await createMessageFeedback(sessionId, messageId, feedbackData);
    } catch (error) {
      logger.error(error as Error, "Failed to submit feedback");
    }
  };

  const handleResponseChange = async (
    value: (typeof RESPONSE_OPTIONS)[keyof typeof RESPONSE_OPTIONS],
    explanation?: string,
  ): Promise<void> => {
    const newResponse = response === value ? RESPONSE_OPTIONS.NONE : value;
    setResponse(newResponse);

    if (newResponse === RESPONSE_OPTIONS.NONE) return;

    const { label, score } =
      newResponse === RESPONSE_OPTIONS.POSITIVE
        ? ({ label: "positive", score: 1.0 } as const)
        : ({ label: "negative", score: 0.0 } as const);

    const feedbackData: FeedbackData = {
      chat_session_type: "global",
      label,
      score,
      ...(explanation ? { explanation } : {}),
    };

    // TODO: This is a placeholder UUID - replace this with the message ID once CRUD is complete
    // Linear: FE-654
    const uuid = "8f5cac73-02ed-46a8-a68c-1d89f270f7f2";

    if (activeSession?.id) {
      await provideMessageFeedback(activeSession.id, uuid, feedbackData);
    }
  };

  const handleExplanationSelection = (explanation: string): void => {
    setSelectedExplanation(explanation);
    handleResponseChange(RESPONSE_OPTIONS.NEGATIVE, explanation);
    setShowOptions(false);
    setShowTextArea(false);
  };

  const handleOtherExplanationSubmit = (): void => {
    if (otherExplanation) {
      handleExplanationSelection(otherExplanation);
    }
  };

  return (
    <>
      <FeedbackButtons
        response={response}
        onPositiveClick={() => {
          handleResponseChange(RESPONSE_OPTIONS.POSITIVE);
          setShowOptions(false);
        }}
        onNegativeClick={() => setShowOptions((prev) => !prev)}
      />
      {showOptions && (
        <div className="absolute left-0 top-full mt-2 w-full z-10 bg-white">
          <FeedbackOptionsPanel
            explanationOptions={EXPLANATION_OPTIONS}
            selectedExplanation={selectedExplanation}
            otherExplanation={otherExplanation}
            showTextArea={showTextArea}
            onClose={() => {
              setShowOptions(false);
              setShowTextArea(false);
            }}
            onSelectExplanation={handleExplanationSelection}
            onOtherExplanationChange={setOtherExplanation}
            onSubmitOtherExplanation={handleOtherExplanationSubmit}
            onToggleTextArea={() => setShowTextArea(true)}
          />
        </div>
      )}
    </>
  );
};

export default ResponseFeedback;

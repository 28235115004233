import classNames from "classnames";
import type React from "react";

type Props = {
  title?: string;
  subTitle?: React.ReactNode;
  className?: string;
};

const NotFoundItems = ({ title, subTitle, className }: Props) => {
  return (
    <div className={classNames("bg-inherit w-full flex justify-center items-center flex-col py-7 px-2", className)}>
      <h1 className="font-bold text-lg">{title || "Not found"}</h1>
      {subTitle && <span className="text-gray-text">{subTitle}</span>}
    </div>
  );
};

export default NotFoundItems;

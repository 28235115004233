import { DropdownMenu } from "components/molecules/dropdown-menu";
import type { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import { EllipsisVertical, Link, Pencil, Trash2, UserPlus } from "lucide-react";

const CommentOptions = ({
  isCommenter,
  onDelete,
  onEdit,
  onCopyLink,
  onAssign,
  authorIsVultron = false,
  isThread = false,
  setDisableScroll,
  hasAssignee = false,
}: {
  isCommenter: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onCopyLink: () => void;
  onAssign?: () => void;
  authorIsVultron?: boolean;
  isThread?: boolean;
  setDisableScroll?: React.Dispatch<React.SetStateAction<boolean>>;
  hasAssignee?: boolean;
}) => {
  const commenterOnlyItems: MenuItem<void>[] = [
    {
      key: "edit",
      label: (
        <div className="flex items-center gap-2 text-sm">
          <Pencil size={16} /> Edit
        </div>
      ),
      onSelect: onEdit,
    },
    ...(onAssign
      ? [
          {
            key: "assign",
            label: (
              <div className="flex items-center gap-2 text-sm">
                <UserPlus size={16} /> {hasAssignee ? "Reassign" : "Assign"}
              </div>
            ),
            onSelect: onAssign,
          },
        ]
      : []),
    {
      key: "delete",
      label: (
        <div className="flex gap-2 text-sm items-center text-red-500">
          <Trash2 size={16} /> {isThread ? "Delete Thread" : "Delete"}
        </div>
      ),
      onSelect: onDelete,
    },
  ];

  const sharedItems: MenuItem<void>[] = [
    {
      key: "link",
      label: (
        <div className="flex gap-2 text-sm items-center">
          <Link size={16} /> {isThread ? "Copy Link to Thread" : "Copy Link to Comment"}
        </div>
      ),
      onSelect: onCopyLink,
    },
  ];

  const userCanDelete = authorIsVultron || isCommenter;
  const dropdownItems = [...sharedItems, ...(userCanDelete ? commenterOnlyItems : [])];

  return (
    <DropdownMenu items={dropdownItems}>
      <div className="py-1 rounded-md hover:bg-gray-200">
        <EllipsisVertical size={18} className="text-gray-light duration-100 hover:text-gray-darkest" />
      </div>
    </DropdownMenu>
  );
};

export default CommentOptions;

import { Extension } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    indent: {
      increaseIndent: () => ReturnType;
      decreaseIndent: () => ReturnType;
    };
  }
}

export const IndentExtension = Extension.create({
  name: "indent",

  addGlobalAttributes() {
    return [
      {
        types: ["paragraph", "heading"],
        attributes: {
          indent: {
            default: 0,
            renderHTML: (attributes) => {
              if (!attributes.indent) return {};
              return {
                style: `padding-left: ${attributes.indent * 3}em`,
              };
            },
            parseHTML: (element) => {
              const indent = element.style.paddingLeft;
              if (!indent) return 0;
              return Math.round(parseInt(indent) / 3) || 0;
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      increaseIndent:
        () =>
        ({ tr, state, dispatch }) => {
          const { selection } = state;
          tr = tr.setSelection(selection);
          state.doc.nodesBetween(selection.from, selection.to, (node, pos) => {
            if (node.type.name === "paragraph" || node.type.name === "heading") {
              const indent = node.attrs.indent || 0;
              if (indent < 8) {
                // Maximum indent level
                tr = tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  indent: Math.min(8, indent + 1),
                });
              }
            }
          });
          if (dispatch) dispatch(tr);
          return true;
        },

      decreaseIndent:
        () =>
        ({ tr, state, dispatch }) => {
          const { selection } = state;
          tr = tr.setSelection(selection);
          state.doc.nodesBetween(selection.from, selection.to, (node, pos) => {
            if (node.type.name === "paragraph" || node.type.name === "heading") {
              const indent = node.attrs.indent || 0;
              if (indent > 0) {
                tr = tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  indent: Math.max(0, indent - 1),
                });
              }
            }
          });
          if (dispatch) dispatch(tr);
          return true;
        },
    };
  },
});

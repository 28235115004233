import { useCallback, useMemo, useState } from "react";

import type { NotificationContextState, NotificationToastState } from "./context";
import { DocViewNotificationContext, NotificationTypeEnum } from "./context";
import DocumentToast from "./DocumentToast";

import * as Toast from "@radix-ui/react-toast";
import { CircleCheck } from "lucide-react";
import type { PropsWithChildren } from "react";
import { pluralizeWord } from "utils/string";

const DocViewNotificationsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [notification, setNotification] = useState<NotificationContextState["notification"]>();

  const handleAddToast = useCallback((state: NotificationToastState) => {
    setNotification(state);
  }, []);

  const setToast: NotificationContextState["setToast"] = useMemo(
    () => ({
      success: (state) => handleAddToast({ ...state, variant: NotificationTypeEnum.Success }),
      warning: (state) => handleAddToast({ ...state, variant: NotificationTypeEnum.Warning }),
    }),
    [handleAddToast],
  );

  const showRequirementSuccessToast = useCallback(
    (action: string, count: number) => {
      setToast.success({
        msg: (
          <div className="flex flex-row gap-2 items-center text-sm text-white">
            <CircleCheck size={14} />
            <span>{`${count} ${pluralizeWord(count, "requirement")} ${action}.`}</span>
          </div>
        ),
      });
    },
    [setToast],
  );

  const clearToast = useCallback(() => setNotification(undefined), []);

  return (
    <DocViewNotificationContext.Provider value={{ setToast, showRequirementSuccessToast, notification, clearToast }}>
      <Toast.Provider swipeDirection="left">
        <DocumentToast />
        {children}
      </Toast.Provider>
    </DocViewNotificationContext.Provider>
  );
};

export default DocViewNotificationsProvider;

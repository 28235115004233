/** @jsxImportSource @emotion/react */
import { Link, type To } from "react-router-dom";
import tw from "twin.macro";
import type { FC, PropsWithChildren } from "react";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { DEFAULT_SESSION_NAME } from "./constants";
import type { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import type { AssistantSidebarRowProps } from "./AssistantSidebarRow";

const SessionDisplay: FC<{
  to?: To;
  onRowSelected?: () => void;
  localSessionName: string;
  isActive: boolean;
  session: ToImmutable<AIAssistantSession | WritingAssistantSession>;
}> = ({ to, onRowSelected, localSessionName, isActive, session }) => (
  <WrapperNode to={to} onRowSelected={onRowSelected}>
    <div
      className="relative text-sm overflow-hidden"
      css={[session.name === DEFAULT_SESSION_NAME ? tw`text-slate-400` : tw`text-slate-700`]}
    >
      <span>{localSessionName}</span>
      <div className="absolute bottom-0 right-0 top-0 w-4 group-hover:w-20" css={[isActive && tw`w-20`]} />
    </div>
    <div className="absolute inset-y-0 right-0 w-10 bg-gradient-to-l from-white to-transparent pointer-events-none" />
  </WrapperNode>
);

export default SessionDisplay;

const WrapperNode: FC<PropsWithChildren<Pick<AssistantSidebarRowProps, "to" | "onRowSelected">>> = ({
  children,
  to,
  onRowSelected,
}) =>
  to ? (
    <Link
      to={to}
      className="relative whitespace-nowrap flex text-sm font-medium w-full justify-between flex-row items-center px-2.5 py-2"
      onClick={onRowSelected}
    >
      {children}
    </Link>
  ) : (
    <button
      className="relative whitespace-nowrap flex text-sm font-medium w-full justify-between flex-row items-center px-2.5 py-2"
      onClick={onRowSelected}
    >
      {children}
    </button>
  );

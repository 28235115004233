/** @jsxImportSource @emotion/react */

import DrawerOpener from "components/atoms/drawer-opener";
import { AlignLeft, Edit } from "lucide-react";
import { type Dispatch, type SetStateAction } from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import AssistantSidebarRow from "components/molecules/assistant-sidebar-row";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setActiveSession, setSessionEditable } from "store/reducers/writing-assistant/writingAssistantReducer";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import useWritingAssistantOperations from "hook/writing-assistant/useWritingAssistantOperations";
import { DEFAULT_SESSION_NAME } from "components/molecules/assistant-sidebar-row/constants";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import AssistantArchiveMenu from "components/molecules/assistant-archive-menu";
import { useAssistantSessionState } from "./hooks";

type Props = {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
};

const WritingAssistantSessionsDrawer = ({ open, onOpenChange }: Props) => {
  const { archivedSessions, sessionEntries } = useAssistantSessionState();
  const { archiveSession, updateSession, deleteSession, createSession, deleteArchivedSession, unarchiveSession } =
    useWritingAssistantOperations();
  const sessionEditable = useAppSelector((store) => store.writingAssistant.sessionEditable);
  const activeSessionId = useAppSelector((store) => store.writingAssistant.activeSession?.id);
  const firstMsgBody = useAppSelector((store) => store.aiAssistantState.activeSession?.conversation?.[0]?.body);
  const dispatch = useAppDispatch();

  return (
    <>
      <div
        tw="z-[9] hidden absolute duration-200 top-0 bottom-0 right-0 left-0 bg-black/5  backdrop-blur-[1px]"
        css={[open && tw`block`]}
        onClick={() => onOpenChange(false)}
        aria-label="Session drawer overlay"
      />
      <div
        role="complementary"
        aria-label="Sessions drawer"
        className="absolute inset-y-0 left-0 flex flex-col min-w-[300px] w-2/3 max-w-[500px] z-10 bg-white shadow-lg duration-150"
        css={[{ transform: "translateX(-100%)" }, open && { transform: "translateX(0%)" }, !open && tw`shadow-none`]}
      >
        <div className="border-b border-gray-light p-2.5 flex justify-between items-center">
          <button onClick={() => onOpenChange(false)} className="p-1 rounded duration-100 hover:bg-gray-100">
            <AlignLeft size={18} />
          </button>
          <button
            onClick={() => {
              const newSession = createSession({ name: DEFAULT_SESSION_NAME });
              const immutableSession = newSession?.toJSON() as ToImmutable<WritingAssistantSession>;
              dispatch(setActiveSession(immutableSession));
              onOpenChange(false);
            }}
            aria-label="Create new session"
            className="p-1 rounded duration-100 hover:bg-gray-100"
          >
            <Edit size={18} />
          </button>
        </div>
        <div className="flex-1 pt-4 overflow-auto">
          <div className="flex flex-col text-sm pb-4 gap-10">
            {!sessionEntries.length && (
              <div className="flex flex-col gap-1.5 px-[18px]">
                <div className="text-xs text-slate-500 font-medium">Today</div>
                <div className="text-sm text-slate-400">New Session</div>
              </div>
            )}
            {sessionEntries.map(([dateRangeKey, sessions]) => (
              <div key={dateRangeKey} className="flex flex-col gap-1.5">
                <motion.div
                  key={dateRangeKey}
                  className="text-xs pl-[18px] text-slate-500 font-medium"
                  layout
                  layoutId={dateRangeKey}
                  animate="visible"
                >
                  {dateRangeKey}
                </motion.div>
                <div className="px-2">
                  {sessions.map((session) => (
                    <AssistantSidebarRow
                      key={session.id}
                      session={session}
                      isActive={activeSessionId === session.id}
                      onDelete={() => {
                        deleteSession(session.id);
                        if (session.id === activeSessionId) dispatch(setActiveSession());
                      }}
                      updateSession={updateSession}
                      archiveSession={(sessionId) => {
                        archiveSession(sessionId);
                        if (activeSessionId === session.id) dispatch(setActiveSession());
                      }}
                      sessionEditable={sessionEditable}
                      firstMsgBody={firstMsgBody}
                      onEditComplete={() => dispatch(setSessionEditable(""))}
                      onEditTriggered={() => dispatch(setSessionEditable(session.id))}
                      onRowSelected={() => {
                        dispatch(setActiveSession(session));
                        onOpenChange(false);
                      }}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <AssistantArchiveMenu
            archivedSessions={archivedSessions}
            onUnarchiveSession={unarchiveSession}
            onDeleteArchivedSession={deleteArchivedSession}
          />
        </div>
        {open && <DrawerOpener setSidebarOpen={onOpenChange} sidebarOpen={open} style={{ left: "100%" }} />}
      </div>
    </>
  );
};

export default WritingAssistantSessionsDrawer;

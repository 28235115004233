/** @jsxImportSource @emotion/react */

import type { HTMLProps } from "react";
import type { TwStyle } from "twin.macro";
import tw from "twin.macro";
import Icon from "../icons/Icon";
import classNames from "classnames";

type Size = "xs" | "sm" | "md" | "lg";

interface Props extends Omit<HTMLProps<HTMLButtonElement>, "size" | "type"> {
  size?: Size;
  onCheck: (checked: boolean) => void;
}

const sizeStyle: Record<Size, TwStyle> = {
  xs: tw`h-3 w-3 min-h-[12px] min-w-[12px]`,
  sm: tw`h-3.5 w-3.5 min-h-[14px] min-w-[14px]`,
  md: tw`h-4 w-4 min-h-[16px] min-w-[16px]`,
  lg: tw`h-[18px] w-[18px] min-h-[18px] min-w-[18px]`,
};

export const Checkbox = ({ size = "md", checked, onCheck, className, ...props }: Props) => {
  return (
    <button
      className={classNames([
        "rounded-[4px] group flex justify-center items-center outline-none bg-white cursor-pointer duration-150 border border-gray-400 hover:border-gray-500 disabled:pointer-events-none disabled:bg-gray-200 disabled:!border-gray-200",
        className,
      ])}
      css={[sizeStyle[size], checked && tw`bg-gray-darkest !border-gray-darkest`]}
      onClick={() => onCheck(!checked)}
      type="button"
      {...props}
    >
      <Icon
        name="Check"
        className="text-white w-full h-full opacity-0 duration-400 group-disabled:text-slate-400"
        css={[checked && tw`opacity-100`]}
      />
    </button>
  );
};

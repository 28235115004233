/** @jsxImportSource @emotion/react */
import { useAppSelector } from "store/storeTypes";
import useAIAssistantOperations from "hook/useAIAssistantOperations";

const AIAssistantConversationWorkflowEnd = () => {
  const activeSession = useAppSelector((store) => store.aiAssistantState.activeSession);
  const { createSession } = useAIAssistantOperations();

  if (!activeSession?.workflowEnded || !activeSession?.workflow) return null;

  return (
    <div className="flex flex-col items-center justify-center w-full gap-2.5 pt-4 pb-12 text-sm">
      <div className="flex items-center gap-1.5">You have completed the workflow.</div>
      <button
        onClick={() => createSession("New Session")}
        className="border border-gray-200 flex items-center justify-center px-2 py-1.5 rounded-md hover:bg-gray-100"
      >
        Start a new session
      </button>
    </div>
  );
};

export default AIAssistantConversationWorkflowEnd;

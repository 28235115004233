import { Mention } from "@tiptap/extension-mention";
import { mergeAttributes } from "@tiptap/core";
import { createMentionSuggestion } from "../MentionSuggestion";

interface WorkspaceMember {
  id: string;
  username: string;
  color: string;
}

export const mentionExtension = (
  workspaceMembers: WorkspaceMember[],
  setDisableScroll?: React.Dispatch<React.SetStateAction<boolean>>,
  isMentionActiveRef?: React.MutableRefObject<boolean>,
) => {
  return Mention.extend({
    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-mention-id"),
          renderHTML: (attributes) => {
            if (!attributes.id) return {};
            return { "data-mention-id": attributes.id };
          },
        },
        username: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-mention-username"),
          renderHTML: (attributes) => {
            if (!attributes.username) return {};
            return { "data-mention-username": attributes.username };
          },
        },
        color: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-mention-color"),
          renderHTML: (attributes) => {
            if (!attributes.color) return {};
            return {
              "data-mention-color": attributes.color,
              style: `background-color: #f3f4f6; color: black;`,
            };
          },
        },
      };
    },
  }).configure({
    HTMLAttributes: {
      class: "mention inline-flex items-center px-1 rounded",
    },
    suggestion: createMentionSuggestion(workspaceMembers, setDisableScroll, isMentionActiveRef),
    renderHTML({ options, node }) {
      return ["span", mergeAttributes(options.HTMLAttributes), `${options.suggestion.char}${node.attrs.username}`];
    },
  });
};

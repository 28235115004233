import DefaultWritingAssistantInput, {
  FileUploadOrSelectionInput,
} from "components/copilot/writing-assistant-drawer/writing-assistant-input/inputs";
import ChecklistSubmitInput from "components/copilot/writing-assistant-drawer/writing-assistant-input/inputs/ChecklistSubmitInput";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import type { ComponentProps } from "react";
import type { InputFieldType } from "types/Assistants/types";

type AssistantComponents = {
  default: React.FC<ComponentProps<typeof DefaultWritingAssistantInput>>;
  fileUploadOrSelection: React.FC<ComponentProps<typeof FileUploadOrSelectionInput>>;
  checklistSubmitInput: React.FC<ComponentProps<typeof ChecklistSubmitInput>>;
};

const PROJECT_ASSISTANT_COMPONENTS: AssistantComponents = {
  default: DefaultWritingAssistantInput,
  fileUploadOrSelection: FileUploadOrSelectionInput,
  checklistSubmitInput: ChecklistSubmitInput,
};

export const assistantInputComponentToRender = (inputType?: InputFieldType) => {
  switch (inputType) {
    case INPUT_FIELD_OPTIONS.contentLibrary:
    case INPUT_FIELD_OPTIONS.allFiles:
    case INPUT_FIELD_OPTIONS.files:
      return PROJECT_ASSISTANT_COMPONENTS.fileUploadOrSelection;
    case INPUT_FIELD_OPTIONS.checklistGroup:
    case INPUT_FIELD_OPTIONS.checklist:
      return PROJECT_ASSISTANT_COMPONENTS.checklistSubmitInput;

    default:
      return PROJECT_ASSISTANT_COMPONENTS.default;
  }
};

import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setAiAssistantState } from "store/reducers/ai-assistant/aiAssistantReducer";
import ContentLibraryPopover from "pages/ai-assistant/ai-assistant-input/ContentLibraryPopover";
import AddAttachmentsButton from "pages/ai-assistant/ai-assistant-input/AddAttachmentsButton/AddAttachmentsButton";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import { useFileUploadHandler } from "pages/ai-assistant/ai-assistant-input/AddAttachmentsButton/hooks";
import { defaultAcceptedFileTypes } from "const-values/assistants/constants";
import DragAndDropOverlay from "pages/ai-assistant/ai-assistant-input/DragAndDropOverlay";
import SubmitButton from "../SubmitButton";
import { createInputFieldData } from "utils/assistants/fileUtils";
import { pluralizeWord } from "utils/string";
import { FileInputFields, type InputFieldType } from "types/Assistants/types";
import { parseWorkflowInstructions } from "utils/assistants/utils";

interface ModularFileUploadProps {
  inputType: InputFieldType;
}

const ModularFileUpload: React.FC<ModularFileUploadProps> = ({ inputType }) => {
  const dispatch = useAppDispatch();
  const { handleFileUpload } = useFileUploadHandler();

  const activeSession = useAppSelector((root) => root.aiAssistantState.activeSession);
  const selectedFiles = useAppSelector((root) => root.aiAssistantState.selectedFiles);
  const uploadedDocuments = useAppSelector((root) => root.aiAssistantState.uploadedDocuments);

  useEffect(() => {
    if (activeSession && (selectedFiles || uploadedDocuments)) {
      const inputField = createInputFieldData({
        contentLibraryFiles: selectedFiles,
        uploadedFiles: uploadedDocuments,
        inputType,
      });

      if (JSON.stringify(activeSession.input_field) !== JSON.stringify(inputField)) {
        dispatch(
          setAiAssistantState({
            activeSession: { ...activeSession, input_field: inputField },
          }),
        );
      }
    }
  }, [activeSession, selectedFiles, uploadedDocuments, inputType, dispatch]);

  const parsedInputInfo = parseWorkflowInstructions(activeSession?.conversation || []);
  const parsedInputInfoWasParsed = !!parsedInputInfo && typeof parsedInputInfo !== "string";
  const excludedOptions =
    parsedInputInfoWasParsed && "exclude" in parsedInputInfo.extra ? parsedInputInfo.extra.exclude : [];
  const maxTotalCount =
    parsedInputInfoWasParsed && "max_total_count" in parsedInputInfo.extra
      ? parsedInputInfo.extra.max_total_count
      : undefined;

  const allowContentLibrary =
    inputType === INPUT_FIELD_OPTIONS.contentLibrary ||
    (inputType === INPUT_FIELD_OPTIONS.allFiles && !excludedOptions?.includes(FileInputFields.ContentLibrary));

  const allowDocuments =
    (inputType === INPUT_FIELD_OPTIONS.allFiles || inputType === INPUT_FIELD_OPTIONS.files) &&
    !excludedOptions?.includes(FileInputFields.ChatDocuments);

  const totalFiles =
    (allowDocuments ? uploadedDocuments?.length || 0 : 0) + (allowContentLibrary ? selectedFiles?.length || 0 : 0);

  const fileCountLabel = totalFiles > 0 ? `Files Selected: ${totalFiles}` : "Select Files";
  const disableSubmit = maxTotalCount && totalFiles > maxTotalCount;
  const tooltipMessage = disableSubmit
    ? `You can only select ${maxTotalCount} ${pluralizeWord(maxTotalCount, "file")}`
    : undefined;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => {
      handleFileUpload(files);
    },
    accept: defaultAcceptedFileTypes,
    disabled: inputType === INPUT_FIELD_OPTIONS.contentLibrary,
    multiple: true,
    noClick: true,
  });

  return (
    <div
      {...getRootProps()}
      className={`border w-full mb-6 rounded-lg lg:mx-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-[900px] 3xl:max-w-[1100px] ${
        isDragActive ? "border-dashed border-black bg-zinc-50" : "border-solid border-gray-300"
      }`}
    >
      <input {...getInputProps()} />
      <div className="relative flex flex-col">
        <DragAndDropOverlay isActive={isDragActive} />
        <div className="flex items-center gap-2 relative px-4 py-4 h-[70px]">
          {allowContentLibrary && <ContentLibraryPopover hideBackground />}
          {allowContentLibrary && allowDocuments && <div className="w-[1px] h-5 bg-gray-300" />}
          {allowDocuments && <AddAttachmentsButton maxFileCount={maxTotalCount || 30} />}
          <SubmitButton submitDisabled={totalFiles === 0 || !!disableSubmit} tooltipMessage={tooltipMessage} />
        </div>
        <div className="bg-gray-100 border-t text-sm border-gray-300 px-4 py-2 rounded-b-lg">{fileCountLabel}</div>
      </div>
    </div>
  );
};

export default ModularFileUpload;

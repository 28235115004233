import { useAssistant } from "pages/ai-assistant/ai-assistant-input/hooks";
import { useAppSelector } from "store/storeTypes";
import { ArrowRight } from "lucide-react";
import useAIAssistantOperations from "hook/useAIAssistantOperations";
import { useCallback } from "react";
import { buildInputFieldFromActiveMetadata } from "utils/assistants/utils";

const ChecklistSubmit = () => {
  const activeSession = useAppSelector((state) => state.aiAssistantState.activeSession);
  const activeMetadata = useAppSelector((state) => state.aiAssistantState.activeMetadata);
  const { submitMessage } = useAssistant();
  const { updateAiAssistantVultronBlock } = useAIAssistantOperations();

  const handleSubmit = useCallback(() => {
    if (!activeSession || !activeMetadata) return;
    updateAiAssistantVultronBlock(activeSession.id, {
      input_metadata: activeMetadata,
    });
    submitMessage(undefined, { input_field: buildInputFieldFromActiveMetadata(activeMetadata) });
  }, [activeMetadata, activeSession, submitMessage, updateAiAssistantVultronBlock]);

  return (
    <div className="border w-full mb-6 rounded-lg border-solid border-gray-300 lg:mx-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-[900px] 3xl:max-w-[1100px]">
      <div className="flex items-center p-4">
        <button
          type="button"
          aria-label="Continue"
          disabled={!activeSession}
          onClick={handleSubmit}
          className="p-2 gap-2 flex items-center justify-center rounded-md font-medium text-gray-700 hover:text-gray-900 disabled:opacity-50"
        >
          <ArrowRight size={16} strokeWidth={2.5} />
          Continue
        </button>
      </div>
      <div className="bg-gray-100 border-t text-sm border-gray-300 px-4 py-2 rounded-b-lg">
        Select continue to proceed
      </div>
    </div>
  );
};

export default ChecklistSubmit;

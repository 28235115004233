import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAIAssistantChatTaskStatuses, getAssistantSessionDocuments } from "api/api";
import { isEqual, uniqBy } from "lodash";
import type {
  ChatSessionDocument,
  ChatTaskStatuses,
  FileMention,
  InputMetadata,
  TaskDetail,
} from "types/Assistants/types";
import * as logger from "utils/log";
import type { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import type { ToImmutable } from "YJSProvider/LiveObjects";

export const getChatTaskStatuses = createAsyncThunk<ChatTaskStatuses>(
  "chat/getChatTaskStatuses",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAIAssistantChatTaskStatuses();
      return response.data;
    } catch (error) {
      logger.error(error as Error, "Error fetching chat task statuses:");
      return rejectWithValue(
        (error as { response?: { data?: string } }).response?.data ||
          "Unable to fetch chat task statuses. Please try again.",
      );
    }
  },
);

export const getChatSessionDocuments = createAsyncThunk<
  ChatSessionDocument[],
  string,
  { rejectValue: { message: string; error?: unknown } }
>("chat/fetchChatSessionDocuments", async (sessionId, { rejectWithValue }) => {
  try {
    const response = await getAssistantSessionDocuments(sessionId);
    return response.data;
  } catch (error) {
    logger.error(error as Error, "Error fetching chat session documents");

    return rejectWithValue({
      message: "An unexpected error occurred while fetching chat session documents.",
      error,
    });
  }
});

export type State = {
  sessionEditable: string;
  autoscroll: boolean;
  activeSession?: ToImmutable<AIAssistantSession>;
  prompt: string;
  streamState: {
    blockId: string | null;
    isStreamingInProgress: boolean;
    streamCopy: string;
  };
  selectedFiles: FileMention[];
  enableInternet: boolean;
  uploadChatDocumentTasks: TaskDetail[] | null;
  uploadedDocuments: ChatSessionDocument[] | null;
  activeMetadata?: InputMetadata;
};

const initialState: State = {
  sessionEditable: "",
  autoscroll: true,
  activeSession: undefined,
  prompt: "",
  streamState: {
    blockId: null,
    isStreamingInProgress: false,
    streamCopy: "",
  },
  selectedFiles: [],
  enableInternet: false,
  uploadChatDocumentTasks: null,
  uploadedDocuments: null,
  activeMetadata: undefined,
};

const aiAssistantReducer = createSlice({
  name: "aiAssistant",
  initialState,
  reducers: {
    resetAssistantState: () => {
      return initialState;
    },
    setAiAssistantState: (state: State, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
    setAssistantPrompt: (state: State, action: PayloadAction<State["prompt"]>) => {
      state.prompt = action.payload;
    },
    insertAssistantFiles: (state: State, action: PayloadAction<State["selectedFiles"]>) => {
      state.selectedFiles = uniqBy([...state.selectedFiles, ...action.payload], "id");
      state.enableInternet = false;
    },
    removeAssistantFiles: (state: State, action: PayloadAction<FileMention["id"][]>) => {
      state.selectedFiles = state.selectedFiles.filter(({ id }) => !action.payload.includes(id));
    },
    removeAllAssistantFiles: (state: State) => {
      state.selectedFiles = [];
    },
    setToggleInternet: (state: State) => {
      state.enableInternet = !state.enableInternet;
    },
    setActiveMetadata: (state: State, action: PayloadAction<State["activeMetadata"]>) => {
      state.activeMetadata = action.payload;
    },
    setStreamingState: (state: State, action: PayloadAction<Partial<State["streamState"]>>) => {
      const reset = !Object.keys(action.payload).length;
      if (reset) {
        state.streamState = initialState.streamState;
      } else {
        state.streamState = { ...state.streamState, ...action.payload };
      }
    },
    updateActiveSession: (state: State, action: PayloadAction<Partial<State["activeSession"]>>) => {
      if (state.activeSession) state.activeSession = { ...state.activeSession, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChatTaskStatuses.fulfilled, (state, action) => {
      const shouldUpdate = !isEqual(state.uploadChatDocumentTasks, action.payload);

      if (shouldUpdate) {
        state.uploadChatDocumentTasks = action.payload.upload_chat_document_tasks;
      }
    });

    builder.addCase(getChatSessionDocuments.fulfilled, (state, action) => {
      state.uploadedDocuments = action.payload;
    });
  },
});

export const {
  setAiAssistantState,
  setStreamingState,
  removeAllAssistantFiles,
  resetAssistantState,
  setAssistantPrompt,
  insertAssistantFiles,
  removeAssistantFiles,
  setToggleInternet,
  updateActiveSession,
  setActiveMetadata,
} = aiAssistantReducer.actions;

export default aiAssistantReducer.reducer;

import { useCallback } from "react";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { threadQueryKey } from "api/comments/comments";
import { useSearchParams } from "react-router-dom";
import type { SENSITIVITY_OPTIONS } from "./constants";
import * as logger from "utils/log";

export interface CriteriaParams {
  criteria?: string[];
  custom_criteria?: string[];
  strictness?: (typeof SENSITIVITY_OPTIONS)[number]["value"];
}

export const useProposalReviewEngine = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id");

  const fetchProposalReviewEngine = useCallback(
    async (proposalDocId: string, params: CriteriaParams) => {
      try {
        await axios.get(`/proposal_review_engine/${proposalDocId}`, { params });
        if (internalContractId)
          queryClient.invalidateQueries({ queryKey: threadQueryKey(internalContractId, proposalDocId) });
      } catch (error) {
        logger.error(error as Error, "Error fetching proposal review engine");
      }
    },
    [internalContractId, queryClient],
  );

  return fetchProposalReviewEngine;
};

export default useProposalReviewEngine;

import { useCallback } from "react";
import type { LiveObject } from "YJSProvider/LiveObjects";
import { find, findIndex, LiveList, update } from "YJSProvider/LiveObjects";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/storeTypes";
import type {
  VultronBlock,
  WritingAssistantSession,
  WritingAssistantUser,
} from "components/copilot/CopilotSchemaTypes";
import { createWritingAssistantSession } from "utils/Liveblocks/WritingAssistant";
import { useMutation } from "YJSProvider/createYJSContext";
import { deleteAssistantChatDocument, uploadProjectAssistantChatDocument } from "api/api";
import useGetActiveProjectId from "hook/Project/useGetActiveProjectId";
import * as logger from "utils/log";
import { useNotification } from "context/notificationContext";

const useWritingAssistantOperations = () => {
  const navigate = useNavigate();
  const userId = useAppSelector((store) => store.auth.currentUser?.id);
  const projectId = useGetActiveProjectId();
  const { setToast } = useNotification();

  const getLiveUserSessions = useMutation(
    ({ storage }) => {
      const writingAssistant = storage.get("writing_assistant") as Storage["writing_assistant"] | undefined;
      const sessions = writingAssistant?.get(userId)?.get("sessions") as WritingAssistantUser["sessions"];

      return sessions;
    },
    [userId],
  );

  const getLiveUserWritingAssistant = useMutation(
    ({ storage }) => {
      const writingAssistant = storage.get("writing_assistant") as Storage["writing_assistant"] | undefined;
      const liveUserWritingAssistant = writingAssistant?.get(userId) as LiveObject<WritingAssistantUser> | undefined;

      return liveUserWritingAssistant;
    },
    [userId],
  );

  const archiveSession = useMutation((_, sessionId: string) => {
    const assistant = getLiveUserWritingAssistant();
    const sessions = assistant?.get("sessions") as WritingAssistantUser["sessions"];

    if (!assistant || !sessions) return;

    const foundSessionIdx = findIndex(sessions, (session) => session.get("id") === sessionId);
    const foundSession = sessions?.get(foundSessionIdx);

    if (!foundSession) return;

    const clonedSession = foundSession.clone();
    const archivedSessions = assistant?.get("archived_sessions");

    if (!archivedSessions) assistant?.set("archived_sessions", new LiveList([]));

    assistant?.get("archived_sessions")?.push([clonedSession]);
    sessions.delete(foundSessionIdx);
  }, []);

  const createSession = useMutation(
    (_, properties?: Partial<WritingAssistantSession>) => {
      const { name, ...rest } = properties || {};
      const sessions = getLiveUserSessions();
      if (!sessions) return;

      const newSession = createWritingAssistantSession({
        name: name?.slice(0, 150),
        ...rest,
      });
      sessions.push([newSession]);

      return newSession;
    },
    [navigate, getLiveUserSessions],
  );

  const unarchiveSession = useMutation(
    (_, sessionId: string) => {
      const assistant = getLiveUserWritingAssistant();
      const archivedSessions = assistant?.get("archived_sessions") as
        | WritingAssistantUser["archived_sessions"]
        | undefined;

      if (!assistant || !archivedSessions) return;

      const foundSessionIdx = findIndex(archivedSessions, (session) => session.get("id") === sessionId);
      const foundSession = archivedSessions?.get(foundSessionIdx);

      if (!foundSession) return;

      const clonedSession = foundSession.clone();

      assistant?.get("sessions")?.push([clonedSession]);
      archivedSessions.delete(foundSessionIdx);
    },
    [getLiveUserWritingAssistant],
  );

  const updateSession = useMutation(
    (_, sessionId: string, properties: Partial<WritingAssistantSession>) => {
      const sessions = getLiveUserSessions();

      if (!sessions) return;

      const foundSession = find(sessions, (session) => session.get("id") === sessionId);

      if (!foundSession) return;

      update(foundSession, { ...properties, updated_at: new Date().toISOString() });
    },
    [getLiveUserSessions],
  );

  const deleteSession = useMutation(
    (_, sessionId: string) => {
      const sessions = getLiveUserSessions();

      if (!sessions) return;

      const foundSessionIdx = findIndex(sessions, (session) => session.get("id") === sessionId);
      const foundSession = sessions.get(foundSessionIdx);

      if (!foundSession) return;

      sessions.delete(foundSessionIdx);
    },
    [getLiveUserSessions],
  );

  const deleteArchivedSession = useMutation(
    (_, sessionId: string) => {
      const sessions = getLiveUserSessions();

      if (!sessions) return;

      const foundSessionIdx = findIndex(sessions, (session) => session.get("id") === sessionId);
      const foundSession = sessions.get(foundSessionIdx);

      if (!foundSession) return;

      sessions.delete(foundSessionIdx);
    },
    [getLiveUserSessions],
  );

  const updateWritingAssistantVultronBlock = useMutation(
    (_, sessionId: string, properties: Partial<VultronBlock>) => {
      const sessions = getLiveUserSessions();

      if (!sessions) return;

      const foundSessionIdx = findIndex(sessions, (session) => session.get("id") === sessionId);
      const foundSession = sessions.get(foundSessionIdx);
      if (!foundSession) return;

      const conversation = foundSession.get("conversation");
      const lastMessage = conversation.get(conversation.length - 1);

      update(lastMessage, properties);
    },
    [getLiveUserSessions],
  );

  const uploadProjectChatDocument = useCallback(
    async (file: File, chatSessionId: string): Promise<void> => {
      try {
        if (!projectId) throw new Error("No project id");
        await uploadProjectAssistantChatDocument(projectId, file, chatSessionId);
      } catch (error) {
        logger.error(error as Error, "File upload failed");
        setToast.error({ msg: `File "${file.name}" failed to upload.` });
        throw new Error("File upload failed. Please try again.", { cause: error });
      }
    },
    [projectId, setToast],
  );

  const deleteProjectChatDocument = async (chatSessionDocumentId: string): Promise<void> => {
    try {
      await deleteAssistantChatDocument(chatSessionDocumentId);
    } catch (error) {
      logger.error(error as Error, "Failed to delete chat document");
      setToast.error({ msg: "File delete failed." });
      throw new Error("Failed to delete chat document. Please try again", { cause: error });
    }
  };

  return {
    updateSession,
    unarchiveSession,
    archiveSession,
    deleteSession,
    deleteArchivedSession,
    createSession,
    uploadProjectChatDocument,
    deleteProjectChatDocument,
    updateWritingAssistantVultronBlock,
  };
};

export default useWritingAssistantOperations;

import { useRoom } from "YJSProvider/createYJSContext";
import { Loading } from "components/yjs-editor/components/Loading";
import { EditorView } from "prosemirror-view";
import { useSelector } from "react-redux";
import { TextEditor } from "components/yjs-editor/components/text-editor/TextEditor";
import { Toolbar } from "components/yjs-editor/components/Toolbar";
import { Suspense } from "react";
import { DocumentLayout } from "components/yjs-editor/components/text-editor/DocumentLayout";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import DocumentSelectionViewer from "components/yjs-editor/components/doument-selection-viewer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setDocumentViewerOpen } from "store/reducers/proposal/proposalReducer";
import { usePanelBehavior } from "./hooks";
import { CommentsDrawer } from "components/Comments/components/CommentsDrawer";
import { ScaleProvider } from "components/yjs-editor/components/text-editor/ScaleContext";

const EditorToggler = ({ liveCursor, fullscreen }: { liveCursor: boolean; fullscreen?: boolean }) => {
  const room = useRoom();
  const { workspaceMembers, currentUser } = useSelector((store: any) => store.auth);
  const liveUsersInADoc = workspaceMembers.filter((obj1: { id: string }) => obj1.id === currentUser.id);

  return (
    <Suspense fallback={<Loading />}>
      <EditorWithToolbar
        fullscreen={fullscreen}
        liveCursor={liveCursor}
        color={liveUsersInADoc[0].color}
        roomId={room.id}
      />
    </Suspense>
  );
};

const EditorWithToolbar = ({
  fullscreen,
  color,
  liveCursor,
  roomId,
}: {
  color: string;
  fullscreen?: boolean;
  liveCursor: boolean;
  roomId: string;
}) => {
  const storeEditorOfYJSTipTap = useAppSelector((state) => state.yjsEditor.storeEditorOfYJSTipTap);
  const dispatch = useAppDispatch();
  const { leftPanelRef } = usePanelBehavior();
  const commentsOpen = useAppSelector((state) => state.commentsDrawer.commentsDrawerOpen);

  return (
    <Suspense fallback={<Loading />}>
      <PanelGroup direction="horizontal">
        <Panel
          ref={leftPanelRef}
          collapsible
          onExpand={() => dispatch(setDocumentViewerOpen(true))}
          onCollapse={() => dispatch(setDocumentViewerOpen(false))}
          defaultSize={0}
          minSize={0}
        >
          <DocumentSelectionViewer />
        </Panel>
        <PanelResizeHandle className="z-[3] relative flex justify-center">
          <div className="w-px h-full bg-gray-200 delay-300 duration-150 hover:bg-gray-darkest hover:z-[11] hover:scale-x-[2.5] hover:rounded-full" />
          <div className="absolute inset-0 w-[16px] -left-[8px]" />
        </PanelResizeHandle>
        <Panel defaultSize={commentsOpen ? 35 : 50} minSize={22}>
          <ScaleProvider>
            <DocumentLayout
              toolbar={storeEditorOfYJSTipTap && <Toolbar editor={storeEditorOfYJSTipTap} fullscreen={fullscreen} />}
              editor={storeEditorOfYJSTipTap}
            >
              <TextEditor fullscreen={fullscreen} color={color} liveCursor={liveCursor} roomId={roomId} />
            </DocumentLayout>
          </ScaleProvider>
        </Panel>
        {commentsOpen && (
          <>
            <PanelResizeHandle className="z-[3] relative flex justify-center">
              <div className="w-px h-full bg-gray-200 delay-300 duration-150 hover:bg-gray-darkest hover:z-[11] hover:scale-x-[2.5] hover:rounded-full" />
              <div className="absolute inset-0 w-[16px] -left-[8px]" />
            </PanelResizeHandle>
            <Panel defaultSize={10} minSize={20} maxSize={30}>
              <CommentsDrawer />
            </Panel>
          </>
        )}
      </PanelGroup>
    </Suspense>
  );
};

// Prevents a matchesNode error on hot reloading
EditorView.prototype.updateState = function updateState(state: { plugins: any }) {
  // @ts-expect-error
  if (!this.docView) return;
  // @ts-expect-error
  // eslint-disable-next-line eqeqeq
  this.updateStateInner(state, this.state.plugins != state.plugins);
};

export default EditorToggler;

import type { PropsWithChildren } from "react";
import type { DropzoneState, FileRejection } from "react-dropzone";
import { ErrorCode, useDropzone } from "react-dropzone";
import { useNotification } from "context/notificationContext";
import { pluralizeWord } from "utils/string";
import { generateSupportedFileTypesObject } from "utils/assistants/utils";
import type { InstructionExtraState } from "../hooks";
import { defaultAcceptedFileTypes } from "const-values/assistants/constants";

interface DropzoneProps extends Partial<DropzoneState> {
  onDrop: (files: File[]) => void;
  fileInstructionState: InstructionExtraState["maxFileCounts"];
}

const Dropzone = ({ onDrop, children, fileInstructionState, ...dropzoneState }: PropsWithChildren<DropzoneProps>) => {
  const { setToast } = useNotification();
  const maxFileCount = fileInstructionState.upload || fileInstructionState.total || undefined;

  const supportedFileTypes = fileInstructionState?.supportedUploadTypes?.length
    ? generateSupportedFileTypesObject(fileInstructionState?.supportedUploadTypes)
    : defaultAcceptedFileTypes;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: supportedFileTypes,
    noClick: true,
    multiple: !!maxFileCount && maxFileCount > 1,
    maxFiles: maxFileCount,
    onDropRejected: (fileRejections: FileRejection[]) => {
      for (const fileRejection of fileRejections) {
        for (const error of fileRejection.errors) {
          if (error.code === ErrorCode.TooManyFiles && maxFileCount) {
            setToast.error({
              title: "Too many documents have been attached",
              msg: `The maximum document count is ${maxFileCount} ${pluralizeWord(maxFileCount, "document")}`,
            });
          }
        }
      }
    },
    ...dropzoneState,
  });

  return (
    <div
      {...getRootProps()}
      className={`p-1 w-full mx-auto relative flex flex-col items-center justify-center bg-white border-[3px] ${
        isDragActive ? "border-dashed border-black bg-zinc-50" : "border-solid border-white"
      }`}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default Dropzone;

import { useMemo, useEffect, useRef } from "react";
import AttachmentPopoverContentHeader from "./AttachmentPopoverContentHeader";
import FileList from "./FileList";
import type { DecoratedChatSessionDocument } from "utils/yjs-configs/ai-assistant/schema";
import * as logger from "utils/log";
import type { ChatSessionDocument, TaskDetail } from "types/Assistants/types";

type AttachmentButtonPopoverContentProps = {
  handleFileUpload: (files: FileList | null) => void;
  deleteDocument: (chatSessionDocumentId: string) => Promise<void>;
  onDeleteSuccess: () => void;
  activeSessionId?: string;
  uploadChatDocumentTasks: TaskDetail[] | null;
  uploadedDocuments: ChatSessionDocument[] | null;
};

const AttachmentButtonPopoverContent = ({
  handleFileUpload,
  deleteDocument,
  onDeleteSuccess,
  activeSessionId,
  uploadChatDocumentTasks,
  uploadedDocuments,
}: AttachmentButtonPopoverContentProps) => {
  const handleClearFiles = async () => {
    if (!activeSessionId || !uploadedDocuments) return;

    try {
      await Promise.all(uploadedDocuments.map((doc) => deleteDocument(doc.id)));
      onDeleteSuccess();
    } catch (error) {
      logger.error(error as Error, "Error clearing files");
    }
  };

  const handleFileDelete = async ({ id: fileId }: DecoratedChatSessionDocument) => {
    if (!activeSessionId) return;

    try {
      await deleteDocument(fileId);
    } catch (error) {
      logger.error(error as Error, `Error deleting file "${fileId}"`);
    }

    onDeleteSuccess();
  };

  const updatedItems = useMemo(() => {
    if (!uploadedDocuments) return [];
    return uploadedDocuments.map((item) => {
      const matchingTask = uploadChatDocumentTasks?.find(
        (task) => task.reference_id === item.id && task.chat_session_id === activeSessionId,
      );

      return {
        ...item,
        minutes_time_remaining: matchingTask?.minutes_time_remaining ?? undefined,
        status: matchingTask?.status ?? undefined,
      };
    });
  }, [uploadedDocuments, uploadChatDocumentTasks, activeSessionId]);

  const prevUpdatedItemsRef = useRef<typeof updatedItems>(updatedItems);

  useEffect(() => {
    if (!activeSessionId) return;
    const getTaskIdsInProgress = (items: DecoratedChatSessionDocument[]) =>
      new Set(items.filter((item) => item.minutes_time_remaining !== undefined).map((item) => item.id));

    const currentTaskIds = getTaskIdsInProgress(updatedItems);
    const prevTaskIds = getTaskIdsInProgress(prevUpdatedItemsRef.current);
    const hasRemovedTask = Array.from(prevTaskIds).some((id) => !currentTaskIds.has(id));

    if (hasRemovedTask) {
      onDeleteSuccess();
    }

    prevUpdatedItemsRef.current = updatedItems;
  }, [updatedItems, activeSessionId, onDeleteSuccess]);

  return (
    <div className="flex flex-col p-1.5 min-w-[350px] max-w-[350px] max-h-[400px] m-1 overflow-y-auto">
      <AttachmentPopoverContentHeader onClear={handleClearFiles} onFileUpload={handleFileUpload} />
      <FileList files={updatedItems} onDelete={handleFileDelete} />
    </div>
  );
};

export default AttachmentButtonPopoverContent;

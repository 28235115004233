/** @jsxImportSource @emotion/react */

import SmartResponseBanner from "./SmartResponseBanner";
import * as Portal from "@radix-ui/react-portal";
import { useAppSelector } from "store/storeTypes";
import type { ComponentProps, FC } from "react";
import { useMemo } from "react";
import ExtractingRequirementsBanner from "./ExtractingRequirementsBanner";
import ExtractingTemplateBanner from "./ExtractingTemplateBanner";

const CopilotBanners = () => {
  const bannerState = useAppSelector((state) => state.bannerState);
  const ideationOpen = useAppSelector((root) => root.ideation.activeIndex >= 0);

  const activeBanners = useMemo(
    () => Object.entries(bannerState).filter(([, val]) => val.open && !val.forceClose),
    [bannerState],
  );

  if (ideationOpen) return;

  const BANNER_TO_COMPONENT: Record<
    string,
    FC<ComponentProps<typeof SmartResponseBanner | typeof ExtractingRequirementsBanner>>
  > = {
    smartResponse: SmartResponseBanner,
    templateExtraction: ExtractingTemplateBanner,
    requirementExtraction: ExtractingRequirementsBanner,
  };

  return (
    <>
      <Portal.Root>
        <div
          className="group z-[998] fixed bottom-6 right-6"
          css={[
            {
              transition: "transform 0.4s ease, bottom 0.4s ease",
            },
            activeBanners.length > 1 && {
              "&:hover": {
                transform: "translate3d(0,-10px,0)",
              },
            },
          ]}
        >
          <div
            css={[
              {
                ".group:hover &": {
                  height: 120 * activeBanners.length,
                  width: 360,
                },
              },
            ]}
          />
          {activeBanners.map(([banner], idx) => {
            const Banner = BANNER_TO_COMPONENT[banner];

            return (
              <Banner
                key={banner}
                css={[
                  {
                    transition: "all 0.35s cubic-bezier(0.25, 0.75, 0.6, 0.98)",
                    bottom: `${20 * (activeBanners.length - 1 - idx)}px`,
                    transform: `scale(${1 - (idx - (activeBanners.length - 1)) * -0.06})`,
                    ".group:hover &": {
                      bottom: `${124 * (activeBanners.length - 1 - idx)}px`,
                      transform: "scale(1)",
                    },
                  },
                ]}
              />
            );
          })}
        </div>
      </Portal.Root>
    </>
  );
};

export default CopilotBanners;

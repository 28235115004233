import { useAppSelector } from "store/storeTypes";
import AddAttachmentsButton from "./ai-assistant-input/AddAttachmentsButton/AddAttachmentsButton";
import ContentLibraryPopover from "./ai-assistant-input/ContentLibraryPopover";
import { useFlags } from "hook/useFlags";
import { FileInputFields } from "types/Assistants/types";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";

const AIAssistantInputEnhancedActions = () => {
  const flags = useFlags();
  const activeSession = useAppSelector((state) => state.aiAssistantState.activeSession);

  const isTextOnlyInput = activeSession?.input_field_type === INPUT_FIELD_OPTIONS.string && activeSession?.workflow;

  const excludeAttachments = activeSession?.excluded_fields?.includes(FileInputFields.ChatDocuments);
  const excludeContentLibrary = activeSession?.excluded_fields?.includes(FileInputFields.ContentLibrary);

  const showAdvancedFeatures = !isTextOnlyInput;
  const showAttachments = showAdvancedFeatures && !excludeAttachments && !flags.hideAttachDocument;
  const showContentLibrary = showAdvancedFeatures && !excludeContentLibrary;

  return (
    <div className="flex items-center gap-1.5 bg-gray-100 p-2 min-h-[30px] border-t border-gray-300">
      {showContentLibrary && <ContentLibraryPopover />}
      {showAdvancedFeatures && (
        <>
          {showAttachments && showContentLibrary && <div className="w-[1px] h-5 bg-gray-300" />}
          {showAttachments && <AddAttachmentsButton />}
        </>
      )}
    </div>
  );
};

export default AIAssistantInputEnhancedActions;

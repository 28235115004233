import type { Editor } from "@tiptap/react";
import { useCallback, useEffect, useState } from "react";
import { Select } from "../primitives/Select";
import { ToolbarTooltip } from "../primitives/Tooltip";

const FONT_FAMILY_OPTIONS = [
  { value: "Arial, sans-serif", title: "Arial" },
  { value: "Times New Roman, serif", title: "Times New Roman" },
  { value: "Verdana, sans-serif", title: "Verdana" },
  { value: "Georgia, serif", title: "Georgia" },
  { value: "Tahoma, sans-serif", title: "Tahoma" },
  { value: "Courier New, monospace", title: "Courier New" },
];

const DEFAULT_FONT_FAMILY = "Arial, sans-serif";

export const ToolbarFontFamily = ({ editor }: { editor: Editor }) => {
  const [currentFontFamily, setCurrentFontFamily] = useState(DEFAULT_FONT_FAMILY);

  useEffect(() => {
    if (!editor) return;

    const updateFontFamily = () => {
      const fontFamily = editor.getAttributes("textStyle").fontFamily || DEFAULT_FONT_FAMILY;
      setCurrentFontFamily(fontFamily);
    };

    updateFontFamily();

    editor.on("selectionUpdate", updateFontFamily);
    editor.on("update", updateFontFamily);

    return () => {
      editor.off("selectionUpdate", updateFontFamily);
      editor.off("update", updateFontFamily);
    };
  }, [editor]);

  const onFontFamilyChange = useCallback(
    (value: string) => {
      if (!editor) return;
      editor.chain().focus().setFontFamily(value).run();
    },
    [editor],
  );

  return (
    <ToolbarTooltip content="Font">
      <div>
        <Select
          variant="subtle"
          value={currentFontFamily}
          initialValue={DEFAULT_FONT_FAMILY}
          items={FONT_FAMILY_OPTIONS}
          onChange={onFontFamilyChange}
          className="w-28 h-8 border border-gray-200 rounded text-sm"
        />
      </div>
    </ToolbarTooltip>
  );
};

/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Plus, Trash } from "lucide-react";
import { useLocation } from "react-router-dom";
import Popover from "components/atoms/popover";
import AttachmentTypeDropdown from "pages/ai-assistant/ai-assistant-input/AddAttachmentsButton/AttachmentTypeDropdown";
import FileSelector from "components/atoms/file-selector/FileSelector";

type AttachmentPopoverContentHeaderProps = {
  onClear: () => void;
  onFileUpload: (files: FileList | null) => void;
};

const AttachmentPopoverContentHeader = ({ onClear, onFileUpload }: AttachmentPopoverContentHeaderProps) => {
  const [addPopoverOpen, setAddPopoverOpen] = useState(false);
  const location = useLocation();
  const isGlobalAssistant = location.pathname.includes("ai-assistant");

  return (
    <div className="flex items-center justify-between border-b border-gray-200 pb-1.5">
      <div className="flex items-center font-medium">Attached Files</div>
      <div className="flex gap-3">
        {isGlobalAssistant && (
          <Popover
            open={addPopoverOpen}
            onOpenChange={setAddPopoverOpen}
            contentProps={{ align: "start" }}
            content={<AttachmentTypeDropdown isDisabled={false} />}
          >
            <button
              type="button"
              className="flex gap-0.5 items-center justify-center text-xs text-gray-700 hover:text-black hover:bg-gray-200  duration-100 cursor-pointer p-1 rounded-md"
            >
              <Plus size={12} />
              Add
            </button>
          </Popover>
        )}
        {!isGlobalAssistant && (
          <FileSelector
            labelClassName="flex gap-0.5 items-center justify-center text-xs text-gray-700 hover:text-black duration-100 cursor-pointer p-1 rounded-md"
            onFileUpload={onFileUpload}
          >
            <Plus size={12} />
            Add
          </FileSelector>
        )}
        <button
          type="button"
          className="flex gap-0.5 items-center justify-center text-xs text-gray-700 hover:text-black hover:bg-gray-200 duration-100 cursor-pointer p-1 rounded-md"
          onClick={onClear}
        >
          <Trash className="mb-0.5" size={11} />
          Clear
        </button>
      </div>
    </div>
  );
};

export default AttachmentPopoverContentHeader;

import { useEffect, useState } from "react";

export const useProgress = (isLoading: boolean, isCompleted: boolean) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isLoading) {
      setProgress(0);
      const interval = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 500);
      return () => clearInterval(interval);
    } else if (isCompleted) {
      setProgress(100);
    }
  }, [isLoading, isCompleted]);

  return progress;
};

import { WorkflowNameType, WorkflowSub } from "pages/ai-assistant/constants";

export const HEALTH_TO_COPY: Record<string, string> = {
  document_extraction_in_progress: "Document Extraction",
  text_extraction_in_progress: "Text Extraction",
};

export const ACTIVE_WORKFLOWS = [
  WorkflowSub.Draft,
  WorkflowSub.Revise,
  WorkflowSub.ExtractOutline,
  WorkflowSub.SearchCapability,
  WorkflowSub.ScoreRequirementResponse,
  WorkflowSub.Summarize,
  WorkflowSub.Questionnaire,
] as const;

export const WORKFLOWS_IN_TOOLBAR: (WorkflowSub | WorkflowNameType)[] = [
  WorkflowNameType.Draft,
  WorkflowNameType.HighlightDraft,
  WorkflowSub.ReviseFixSpellingGrammar,
  WorkflowSub.ReviseActiveVoice,
  WorkflowSub.ReviseRemoveWords,
  WorkflowSub.ReviseMakeShorter,
  WorkflowNameType.Revise,
  WorkflowSub.ReviseConciseTone,
  WorkflowSub.ReviseProfessionalTone,
  WorkflowSub.RevisePersuasiveTone,
  WorkflowSub.ReviseTechnicalTone,
  WorkflowSub.ReviseAcademicTone,
  WorkflowSub.ReviseCustomerFocusedTone,
  WorkflowSub.ScoreRequirementResponse,
  WorkflowSub.ReviseCreateList,
  WorkflowSub.ReviseCreateTable,
] as const;

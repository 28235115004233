/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useRef } from "react";
import { useStorage } from "utils/yjs-configs/ai-assistant/yjs.config";
import "./styles.css";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useParams } from "react-router-dom";
import {
  getChatSessionDocuments,
  getChatTaskStatuses,
  resetAssistantState,
  setAiAssistantState,
} from "store/reducers/ai-assistant/aiAssistantReducer";
import { fetchFileStorage } from "store/reducers/driveReducerSlice";
import { useFlags } from "hook/useFlags";
import { usePollingManager } from "hook/usePollingManager";
import AIAssistantCore from "pages/ai-assistant/AIAssistantCore";
import merge from "lodash/merge";
import AIAssistantModals from "./AIAssistantModals";

const AIAssistantContainer = () => {
  const flags = useFlags();
  const selectedFiles = useAppSelector((root) => root.aiAssistantState.selectedFiles);
  const { currentSessionId } = useParams();
  const fetchedSessionId = useRef<string | null>(null);

  const dispatch = useAppDispatch();

  const aiAssistantSessions = useStorage((root) => root.ai_assistant?.sessions || []);

  const activeSession = useMemo(
    () => aiAssistantSessions.find((session) => session.id === currentSessionId),
    [aiAssistantSessions, currentSessionId],
  );
  const reduxActiveSession = useAppSelector((state) => state.aiAssistantState.activeSession);

  const checkTaskStatus = async () => {
    if (!flags.documentAttachments) return;
    await dispatch(getChatTaskStatuses());
  };

  usePollingManager(checkTaskStatus);

  useEffect(() => {
    if (activeSession) {
      const mergedSession =
        reduxActiveSession && fetchedSessionId.current === activeSession.id
          ? merge({}, reduxActiveSession, activeSession)
          : activeSession;
      dispatch(setAiAssistantState({ activeSession: mergedSession }));

      if (fetchedSessionId.current !== activeSession.id) {
        if (flags.documentAttachments) dispatch(getChatSessionDocuments(activeSession.id));
        if (selectedFiles) dispatch(setAiAssistantState({ selectedFiles: [] }));
        fetchedSessionId.current = activeSession.id;
      }
    }
  }, [activeSession, flags.documentAttachments, dispatch, selectedFiles]);

  useEffect(() => {
    dispatch(fetchFileStorage());
    return () => {
      dispatch(resetAssistantState());
    };
  }, [dispatch]);

  return (
    <>
      <AIAssistantModals />
      <AIAssistantCore />
    </>
  );
};

export default AIAssistantContainer;

import type { OrderKey } from "store/reducers/extract/CurrentExtractionReducer";
import type { Bounds, GroupedBlock, Item, Row } from "../types";

export const getMinMaxMap = (
  blocks: (Item | Row)[],
): {
  minMaxMap: {
    min?: number;
    max?: number;
    bounds: Partial<Bounds>;
  };
  isValidAndSamePage: boolean;
} => {
  const minMaxMap = blocks.reduce<{
    min?: number;
    max?: number;
    bounds: Partial<Bounds>;
  }>(
    (acc2, item) => {
      acc2.min = Object.hasOwn(acc2, "min") && typeof acc2.min === "number" ? Math.min(acc2.min, item.page) : item.page;
      acc2.max = Object.hasOwn(acc2, "max") && typeof acc2.max === "number" ? Math.max(acc2.max, item.page) : item.page;
      const bottomLeft = {
        X:
          typeof acc2.bounds?.bottom_left?.X === "number"
            ? Math.min(item.bounds.bottom_left.X, acc2.bounds?.bottom_left?.X)
            : item.bounds?.bottom_left?.X,
        Y:
          typeof acc2.bounds?.bottom_left?.Y === "number"
            ? Math.max(item.bounds.bottom_left.Y, acc2.bounds?.bottom_left?.Y)
            : item.bounds.bottom_left.Y,
      };
      const bottomRight = {
        X:
          typeof acc2.bounds?.bottom_right?.X === "number"
            ? Math.max(item.bounds.bottom_right.X, acc2.bounds?.bottom_right?.X)
            : item.bounds?.bottom_right?.X,
        Y:
          typeof acc2.bounds?.bottom_right?.Y === "number"
            ? Math.max(item.bounds.bottom_right.Y, acc2.bounds?.bottom_right?.Y)
            : item.bounds.bottom_right.Y,
      };
      const topRight = {
        X:
          typeof acc2.bounds?.top_right?.X === "number"
            ? Math.max(item.bounds.top_right.X, acc2.bounds?.top_right?.X)
            : item.bounds?.top_right?.X,
        Y:
          typeof acc2.bounds?.top_right?.Y === "number"
            ? Math.min(item.bounds.top_right.Y, acc2.bounds?.top_right?.Y)
            : item.bounds.top_right.Y,
      };
      const topLeft = {
        X:
          typeof acc2.bounds?.top_left?.X === "number"
            ? Math.min(item.bounds.top_left.X, acc2.bounds?.top_left?.X)
            : item.bounds?.top_left?.X,
        Y:
          typeof acc2.bounds?.top_left?.Y === "number"
            ? Math.min(item.bounds.top_left.Y, acc2.bounds?.top_left?.Y)
            : item.bounds.top_left.Y,
      };
      acc2.bounds["bottom_left"] = bottomLeft;
      acc2.bounds["bottom_right"] = bottomRight;
      acc2.bounds["top_right"] = topRight;
      acc2.bounds["top_left"] = topLeft;
      return acc2;
    },
    { bounds: {} },
  );

  const parentCalculatedBounds = minMaxMap.bounds;
  const hasMinMax = typeof minMaxMap.max === "number" && typeof minMaxMap.min === "number";

  return {
    minMaxMap,
    isValidAndSamePage:
      hasMinMax &&
      minMaxMap.min === minMaxMap.max &&
      Object.hasOwn(parentCalculatedBounds, "top_left") &&
      Object.hasOwn(parentCalculatedBounds, "top_right") &&
      Object.hasOwn(parentCalculatedBounds, "bottom_left") &&
      Object.hasOwn(parentCalculatedBounds, "bottom_right"),
  };
};

type HandleShiftSelectParams = {
  selectedBlocks: GroupedBlock[];
  orderKeys: Record<OrderKey["requirement_id"], OrderKey>;
  filteredBlocks: GroupedBlock[];
  requirementId: string;
  setSelectedBlocks: React.Dispatch<React.SetStateAction<GroupedBlock[]>> | undefined;
};

export const handleShiftSelect = ({
  selectedBlocks,
  orderKeys,
  filteredBlocks,
  requirementId,
  setSelectedBlocks,
}: HandleShiftSelectParams) => {
  if (!selectedBlocks.length || !orderKeys || !filteredBlocks) return;

  // The last selected item is important for determining the items because users can select items out of order.
  const lastSelectedRequirementId = selectedBlocks[selectedBlocks.length - 1].requirement.requirement.id;
  const lastSelectedOrderKey = orderKeys[lastSelectedRequirementId];
  const targetOrderKey = orderKeys[requirementId];

  if (!lastSelectedOrderKey || !targetOrderKey) return;

  const minOrderKey = Math.min(lastSelectedOrderKey.start_order_key, targetOrderKey.start_order_key);
  const maxOrderKey = Math.max(lastSelectedOrderKey.start_order_key, targetOrderKey.start_order_key);

  const blocksInRange = filteredBlocks.filter((block) => {
    const blockOrderKey = orderKeys[block.requirement.requirement.id];
    return (
      blockOrderKey && blockOrderKey.start_order_key >= minOrderKey && blockOrderKey.start_order_key <= maxOrderKey
    );
  });

  const existingIds = new Set(selectedBlocks.map((block) => block.requirement.requirement.id));
  const newSelection = blocksInRange.filter((block) => !existingIds.has(block.requirement.requirement.id));

  setSelectedBlocks?.((prevSelectedBlocks: GroupedBlock[]) => [...prevSelectedBlocks, ...newSelection]);
};

export const highlightPattern = (text: string, pattern: string) => {
  return text.replace(new RegExp(pattern, "gi"), (match) => `<div class="highlightedContent">${match}</div>`);
};

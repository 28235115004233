/** @jsxImportSource @emotion/react */

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { getMinMaxMap } from "./utils";
import { getSectionsMap } from "utils/Liveblocks/Framework";
import { setActiveDocument, setGroupedBlocks } from "store/reducers/extract/CurrentExtractionReducer";
import type { Bounds, GroupedBlock, Item, MergedRequirement, Row } from "../types";
import type { ComplianceMatrixRow } from "components/copilot/CopilotSchemaTypes";
import { ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import BottomRightActions from "./bottom-right-actions";
import DocumentContainer from "./DocumentContainer";
import Tooltip from "components/atoms/tooltip/Tooltip";
import tw from "twin.macro";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { compact, keyBy } from "lodash";
import { useSyncDraftConfig } from "./hooks";
import { isInstantDraftStarted } from "../utils";
import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import HelpModal from "./bottom-right-actions/HelpModal";
import { ScreenSpinner } from "utils/icons";
import type { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import DocumentSearch from "../document-search/DocumentSearch";
import { AskAiProvider } from "../AskAiContext";
import { useFlags } from "hook/useFlags";
import Scale from "components/atoms/scale";

const DocumentDisplay = () => {
  const dispatch = useAppDispatch();
  const flags = useFlags();

  const [helpOpen, setHelpOpen] = usePersistedStorage("extractionHelpStateOpen", true);
  const [scale, setScale] = useState(1.25);
  const groupedFilteredRequirementsByDocument = useAppSelector(
    (store) => store.currentExtractionState.groupedFilteredRequirementsByDocument,
  );
  const documents = useAppSelector((store) => store.currentExtractionState.documents);
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);
  const coordinates = useAppSelector(
    (store) =>
      store.currentExtractionState.coordinateCache[store.currentExtractionState.activeDocument?.id || ""]
        ?.coordinates || [],
  );

  const mergedRequirements = useAppSelector((store) => store.currentExtractionState.mergedRequirements);
  const searchQuery = useAppSelector((store) => store.currentExtractionState.searchQuery);
  useSyncDraftConfig({ extractionId: extraction?.id, outlineVolumes: extraction?.framework?.volumes });
  const sectionsMap = useMemo(() => getSectionsMap(extraction?.framework?.volumes), [extraction?.framework?.volumes]);
  const keysOfSectionMap = useMemo(() => Object.keys(sectionsMap), [sectionsMap]);
  const filteredRows = useMemo(() => {
    return extraction?.compliance_matrix?.filter((row) => {
      return row.document?.id === activeDocument?.id && !row.requirement?.soft_deleted;
    });
  }, [activeDocument?.id, extraction?.compliance_matrix]);
  const groupedRequirements = useMemo(() => keyBy(mergedRequirements, "id"), [mergedRequirements]);
  const groupedMergedRequirements = useMemo(() => {
    return keyBy(
      compact(mergedRequirements.map(({ id }) => filteredRows?.find((row) => row.requirement.id === id))),
      "requirement.id",
    );
  }, [filteredRows, mergedRequirements]);

  const elementIdToRequirementMap = useMemo(() => {
    return (
      filteredRows?.reduce<Record<string, ToImmutable<ComplianceMatrixRow>>>((acc, row) => {
        return Object.assign(acc, { [row.requirement.element_id || ""]: row });
      }, {}) || {}
    );
  }, [filteredRows]);

  const validateMergedBlock = useCallback(
    (
      acc: {
        allFilteredBlocks: GroupedBlock[];
        pageGroups: Record<number, GroupedBlock[]>;
      },
      mergedRequirement: MergedRequirement,
    ) => {
      const mergedYjsRequirement = groupedMergedRequirements[mergedRequirement.id];

      if (mergedYjsRequirement) {
        mergedRequirement.bounds.forEach((mergedBlock) => {
          const transformedBlock = {
            page: mergedBlock.page_number,
            bounds: mergedBlock.bounds,
            requirement: mergedYjsRequirement,
            id: mergedYjsRequirement?.requirement.element_id || "",
            isMergedRequirement: true,
          };
          acc.pageGroups[mergedBlock.page_number] = [
            ...(acc.pageGroups[mergedBlock.page_number] || []),
            transformedBlock,
          ];
          acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
        });
      }
    },
    [groupedMergedRequirements],
  );

  const groupedBlocks = useMemo(() => {
    const grouped = coordinates.reduce<{
      allFilteredBlocks: GroupedBlock[];
      pageGroups: Record<number, GroupedBlock[]>;
    }>(
      (acc, block) => {
        const blockItems = block.items || [];
        const blockParts = block.parts || [];
        const parentBlockToRequirement = elementIdToRequirementMap[block.id];

        const appendChildBlocks = (childBlocks: (Item | Row)[]) => {
          childBlocks.forEach((innerBlock) => {
            const requirement = elementIdToRequirementMap[parentBlockToRequirement ? block.id : innerBlock.id];
            if (!requirement) return;
            const mergedRequirement = groupedRequirements[requirement.requirement.id];
            if (mergedRequirement) {
              validateMergedBlock(acc, mergedRequirement);
            } else {
              const transformedBlock = {
                ...innerBlock,
                requirement,
              };

              if (innerBlock.text.trim()) {
                acc.pageGroups[innerBlock.page] = [...(acc.pageGroups[innerBlock.page] || []), transformedBlock];
                acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
              }
            }
          });
        };

        if (!!block.bounds && !!parentBlockToRequirement) {
          if (typeof block.page === "number") {
            const requirement = elementIdToRequirementMap[block.id];
            const mergedRequirement = groupedRequirements[requirement.requirement.id];
            if (mergedRequirement) {
              validateMergedBlock(acc, mergedRequirement);
            } else {
              const transformedBlock = {
                ...block,
                requirement,
              };

              acc.pageGroups[block.page] = [...(acc.pageGroups[block.page] || []), transformedBlock];
              acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
            }
          } else {
            if (blockParts.length) {
              const childRows = blockParts
                .map((part) => {
                  if (!part.rows?.length) return [];
                  return part.rows.flat();
                })
                .flat();
              const { minMaxMap, isValidAndSamePage } = getMinMaxMap(childRows);
              const { min, max } = minMaxMap;
              const hasMinMax = typeof max === "number" && typeof min === "number";
              if (isValidAndSamePage && hasMinMax) {
                const mergedRequirement = groupedRequirements[parentBlockToRequirement.requirement.id];
                if (mergedRequirement) {
                  validateMergedBlock(acc, mergedRequirement);
                } else {
                  const transformedBlock = {
                    ...block,
                    requirement: parentBlockToRequirement,
                  };

                  acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                  acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                }
              } else {
                appendChildBlocks(childRows);
              }
            }

            if (blockItems.length) {
              const { minMaxMap, isValidAndSamePage } = getMinMaxMap(blockItems);
              const { min, max } = minMaxMap;
              const hasMinMax = typeof max === "number" && typeof min === "number";
              if (isValidAndSamePage && hasMinMax) {
                const mergedRequirement = groupedRequirements[parentBlockToRequirement.requirement.id];
                if (mergedRequirement) {
                  validateMergedBlock(acc, mergedRequirement);
                } else {
                  const transformedBlock = {
                    ...block,
                    requirement: parentBlockToRequirement,
                  };

                  acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                  acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                }
              } else {
                appendChildBlocks(blockItems);
              }
            }
          }
        } else if (blockItems.length || blockParts.length) {
          if (blockItems.length) {
            if (parentBlockToRequirement) {
              const { minMaxMap, isValidAndSamePage } = getMinMaxMap(blockItems);
              const { min, max } = minMaxMap;
              const hasMinMax = typeof max === "number" && typeof min === "number";
              const parentCalculatedBounds = minMaxMap.bounds;
              if (isValidAndSamePage && hasMinMax) {
                const mergedRequirement = groupedRequirements[parentBlockToRequirement.requirement.id];
                if (mergedRequirement) {
                  validateMergedBlock(acc, mergedRequirement);
                } else {
                  const transformedBlock = {
                    ...{ ...block, bounds: parentCalculatedBounds as Bounds },
                    requirement: parentBlockToRequirement,
                  };

                  acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                  acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                }
              } else {
                appendChildBlocks(blockItems);
              }
            } else {
              appendChildBlocks(blockItems);
            }
          }

          if (blockParts.length) {
            const childRows = blockParts
              .map((part) => {
                if (!part.rows?.length) return [];
                return part.rows.flat();
              })
              .flat();
            if (parentBlockToRequirement) {
              const { minMaxMap, isValidAndSamePage } = getMinMaxMap(childRows);
              const { min, max } = minMaxMap;
              const hasMinMax = typeof max === "number" && typeof min === "number";
              const parentCalculatedBounds = minMaxMap.bounds;
              if (isValidAndSamePage && hasMinMax) {
                const mergedRequirement = groupedRequirements[parentBlockToRequirement.requirement.id];
                if (mergedRequirement) {
                  validateMergedBlock(acc, mergedRequirement);
                } else {
                  const transformedBlock = {
                    ...{ ...block, bounds: parentCalculatedBounds as Bounds },
                    requirement: parentBlockToRequirement,
                  };

                  acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                  acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                }
              } else {
                appendChildBlocks(childRows);
              }
            } else {
              appendChildBlocks(childRows);
            }
          }
        }

        return acc;
      },
      { allFilteredBlocks: [], pageGroups: {} },
    );

    return grouped;
  }, [coordinates, elementIdToRequirementMap, groupedRequirements, validateMergedBlock]);

  const isLoadingInitialCoordinates = !groupedBlocks?.allFilteredBlocks?.length;

  useEffect(() => {
    dispatch(setGroupedBlocks(groupedBlocks));
  }, [groupedBlocks, dispatch]);

  const isReadOnly =
    extraction?.status === ExtractionStatus.Completed || isInstantDraftStarted(extraction?.instantDraftConfig?.status);
  const pdfItemState = useState<DocumentCallback>();
  const [, setPdfItem] = pdfItemState;

  return (
    <AskAiProvider>
      <div className="flex flex-col h-full">
        <div className="flex flex-col gap-2 absolute z-[4] top-2 left-2 right-[190px] w-fit pointer-events-none">
          <div className="flex flex-wrap gap-2 pointer-events-auto">
            {documents.map((doc) => (
              <Tooltip key={doc.id} content={doc.file_name}>
                <div className="relative">
                  {" "}
                  <button
                    key={doc.id}
                    className="border relative truncate text-left text-slate-900 border-transparent max-w-[330px] backdrop-blur-lg shadow-sharp-thin bg-gray-300/70 rounded-md text-xs font-medium px-2.5 py-2 duration-150 hover:text-black hover:bg-gray-300"
                    title={doc.file_name}
                    onClick={() => {
                      if (activeDocument?.id !== doc.id) {
                        setPdfItem(undefined);
                        dispatch(setActiveDocument(doc));
                      }
                    }}
                    css={[doc.id === activeDocument?.id && tw`border-slate-900 bg-gray-300 border-1.5`]}
                  >
                    {doc.file_name}
                  </button>
                  {/* We need to hide the document count chip when a user is searching */}
                  {!!groupedFilteredRequirementsByDocument[doc.id]?.length && !searchQuery.length && (
                    <div className="rounded-full absolute min-h-6 min-w-6 text-center -top-2 -right-2 text-xs font-medium p-1 bg-gray-darkest text-white">
                      {groupedFilteredRequirementsByDocument[doc.id].length}
                    </div>
                  )}
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
        <div className="absolute z-[10] top-2 right-2 flex flex-row items-center gap-2">
          {flags.documentSearch && <DocumentSearch />}
          <Scale scale={scale} setScale={setScale} />
        </div>
        {isLoadingInitialCoordinates && (
          <div className="z-[30] pointer-events-none rounded-md p-3 text-lg font-medium flex flex-row gap-2 items-center bg-gray-300/60 backdrop-blur absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <ScreenSpinner tw="w-6 h-6" />
            Loading content
          </div>
        )}
        <DocumentContainer
          isReadOnly={isReadOnly}
          keysOfSectionMap={keysOfSectionMap}
          sectionsMap={sectionsMap}
          scale={scale}
          pdfItemState={pdfItemState}
        />
        <BottomRightActions setHelpOpen={setHelpOpen} isReadOnly={isReadOnly} />
        <HelpModal open={helpOpen} onOpenChange={(v) => setHelpOpen(v)} />
      </div>
    </AskAiProvider>
  );
};

export default memo(DocumentDisplay);

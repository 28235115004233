import type { HTMLAttributes, PropsWithChildren } from "react";

type Props = HTMLAttributes<HTMLButtonElement>;

const ProjectWorkflowSelectionPopoverRow = ({ children, ...props }: PropsWithChildren<Props>) => {
  return (
    <button
      className="p-2 flex flex-row gap-2 items-center duration-100 transition-colors w-full hover:bg-hover-default"
      {...props}
    >
      {children}
    </button>
  );
};

export default ProjectWorkflowSelectionPopoverRow;

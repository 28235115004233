import type { FetchEventSourceInit } from "@microsoft/fetch-event-source";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { apiUrl } from "config/vultronConfig";
import { useLocalStorage } from "hook/useLocalStorage";

export const useGenerateWinThemes = (options?: FetchEventSourceInit) => {
  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0", "");
  const useAuth0Header = use_auth0 === true;

  return (id: string) => {
    fetchEventSource(`${apiUrl}/proposal_generation/${id}/win_theme/generate/stream`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Workspace: `Workspace ${workspace_id}`,
        Authorization: `Bearer ${localValue}`,
        "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
        Accept: "application/json",
      },
      body: JSON.stringify({ include_ids: true }),
      openWhenHidden: true,
      ...options,
    });
  };
};

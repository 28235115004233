import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import useAIAssistantOperations from "hook/useAIAssistantOperations";
import { getChatSessionDocuments } from "store/reducers/ai-assistant/aiAssistantReducer";
import { useIsCurrentSessionInTasks } from "pages/ai-assistant/hooks";
import { useNotification } from "context/notificationContext";

export const useFileUploadHandler = () => {
  const dispatch = useAppDispatch();
  const { activeSession, uploadedDocuments } = useAppSelector((state) => state.aiAssistantState);
  const { createSession, uploadChatDocument } = useAIAssistantOperations();
  const { setToast } = useNotification();

  const [documentsWithErrors, setDocumentsWithErrors] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const isCurrentSessionInTasks = useIsCurrentSessionInTasks();

  useEffect(() => {
    if (!isCurrentSessionInTasks && activeSession && isProcessing) {
      dispatch(getChatSessionDocuments(activeSession.id));
    }
    setIsProcessing(isCurrentSessionInTasks);
  }, [isCurrentSessionInTasks]);

  const handleFileUpload = async (files: FileList | File[] | null, maxFileCount = 10) => {
    if ((uploadedDocuments?.length || 0) + (files?.length || 0) > maxFileCount) {
      setToast.error({ msg: `You can only attach up to ${maxFileCount} files at a time.` });
      return;
    }

    setIsProcessing(true);
    let newSession;
    if (!files) return;

    if (!activeSession) {
      newSession = createSession("New Session");
    }

    const chatSessionId = activeSession?.id || newSession?.get("id");

    for (const file of Array.from(files)) {
      try {
        await uploadChatDocument(file, chatSessionId);
      } catch (error) {
        setDocumentsWithErrors((prev) => [...prev, file.name]);
        setToast.error({ msg: `File "${file.name}" failed to upload.` });
      }
    }

    dispatch(getChatSessionDocuments(chatSessionId));
  };

  return {
    isProcessing,
    documentsWithErrors,
    handleFileUpload,
    uploadedDocuments,
  };
};

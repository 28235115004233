/** @jsxImportSource @emotion/react */

import { Modal } from "../modal";
import { useAppDispatch } from "store/storeTypes";
import { toggleDocumentViewerModal, toggleSourceModal } from "store/reducers/modalsSlice";
import { useEffect, useState } from "react";
import { getFile } from "api/api";
import type { WorkspaceFile } from "types/FileStorage";
import { getFileIcon } from "utils/getFileIcon";
import tw from "twin.macro";
import { Skeleton } from "components/molecules/skeleton";
import { Download, ExternalLink } from "lucide-react";
import { MarkdownEditor } from "components/copilot/MarkdownEditor";
import { dateFormatter } from "utils/timerUtils";

type SourceModalInitialProps = {
  name: string;
  extension: string;
  sourceContent: string;
  id: string;
};
export type SourceModalProps = {
  open: boolean;
  initialProps?: Partial<SourceModalInitialProps>;
};

export const SourceModal = ({ open, initialProps }: SourceModalProps) => {
  const { name, extension, id, sourceContent } = initialProps || {};
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [foundFile, setFoundFile] = useState<WorkspaceFile>();

  useEffect(() => {
    if (!id || !open) return;
    setIsLoading(true);
    getFile(id)
      .then((res) => {
        const file: WorkspaceFile = res?.data;
        setFoundFile(file);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));

    return () => {
      setFoundFile(undefined);
    };
  }, [id, open]);

  return (
    <Modal
      title="Source dialog"
      modal
      open={open}
      onOpenChange={(openState) => {
        dispatch(toggleSourceModal({ open: openState }));
      }}
      contentProps={{ css: tw`max-w-[650px]` }}
      header={
        <div className="flex gap-3 pr-3 items-center">
          {isLoading ? (
            <div className="flex gap-3 items-center">
              <Skeleton borderRadius={4} height={41} width={33} />
              <div className="flex flex-col gap-1">
                <Skeleton borderRadius={2} height={16} width={200} />
                <Skeleton borderRadius={2} height={12} width={80} />
              </div>
            </div>
          ) : (
            <>
              <img
                src={getFileIcon(foundFile?.file_extension_type || extension)}
                alt=""
                className="w-[33px] h-[41px]"
              />
              <div className="flex flex-col gap-0.5">
                <div
                  className="flex gap-1.5 font-semibold items-baseline text-sm text-stone-900 break-all line-clamp-2 pr-4"
                  css={[foundFile && tw`items-center`]}
                >
                  {foundFile?.name || name}
                  {!foundFile && <span className="text-xs text-gray-500 font-medium whitespace-nowrap">(Deleted)</span>}
                  {foundFile && (
                    <a
                      href={foundFile.download_url}
                      download={foundFile?.name || name}
                      className="text-[18px] pb-0.5 text-action duration-150 hover:text-action-hover"
                    >
                      <Download size={18} />
                    </a>
                  )}
                  {foundFile?.file_extension_type === "pdf" && (
                    <button
                      onClick={() => {
                        dispatch(
                          toggleDocumentViewerModal({ open: true, initialProps: { url: foundFile.download_url } }),
                        );
                      }}
                      className="pb-0.5 text-action duration-150 hover:text-action-hover"
                    >
                      <ExternalLink size={18} className="shrink-0" />
                    </button>
                  )}
                </div>
                {foundFile?.created_at && (
                  <div className="font-normal text-xs text-gray-500">
                    {dateFormatter(foundFile.created_at, "MMMM DD, YYYY")}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      }
      body={
        <div className="text-slate-700 pl-6 pr-10 text-sm text-gray-primary font-normal break-words whitespace-pre-line overflow-y-auto">
          {isLoading ? (
            <Skeleton borderRadius={2} count={90} height={14} width={580} containerClassName="flex flex-col gap-1.5" />
          ) : (
            <MarkdownEditor content={sourceContent || ""} />
          )}
        </div>
      }
    />
  );
};

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type State = {
  drafts: Partial<{
    [key: string]: Partial<{
      id: string;
      isOpen: boolean;
      isLoading: boolean;
      newSections: string[];
    }>;
  }>;
};

const initialState: State = { drafts: {} };

const draftsExtractionReducer = createSlice({
  name: "draftsExtraction",
  initialState,
  reducers: {
    resetDraftsExtractionState: () => {
      return initialState;
    },
    updateDraftExtractionState: (
      state: State,
      action: PayloadAction<Record<keyof State["drafts"], State["drafts"][string]>>,
    ) => {
      const draftId = Object.keys(action.payload)[0];
      const newDraftConfig = action.payload[draftId];
      const existingDraftConfig = state.drafts[draftId];

      if (!newDraftConfig) return;
      state.drafts = { ...state.drafts, [draftId]: { ...existingDraftConfig, ...newDraftConfig } };
    },
    appendDraftSections: (state: State, action: PayloadAction<Record<string, string[]>>) => {
      const draftId = Object.keys(action.payload)[0];
      const newDraftections = action.payload[draftId];
      const existingDraftConfig = state.drafts[draftId];
      const existingDraftSections = state.drafts[draftId]?.newSections || [];

      state.drafts = {
        ...state.drafts,
        [draftId]: { ...existingDraftConfig, newSections: [...existingDraftSections, ...newDraftections] },
      };
    },
  },
});

export const { resetDraftsExtractionState, updateDraftExtractionState, appendDraftSections } =
  draftsExtractionReducer.actions;

export default draftsExtractionReducer.reducer;

/** @jsxImportSource @emotion/react */

import { memo, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";

import DraftSectionRequirementsFilters from "./DraftSectionRequirementsFilters";
import { useRequirementHighlight, useValidateRequirementOrder } from "./hooks";
import SectionRequirement from "./SectionRequirement";
import SortableItem from "./SortableItem";

import ManagerCard from "./manager-card/ManagerCard";

import { DragOverlay } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Checkbox } from "components/atoms/checkbox";
import type { Section } from "components/copilot/CopilotSchemaImmutableTypes";
import type { ComplianceMatrixRow, Sheet, Volume } from "components/copilot/CopilotSchemaImmutableTypes";
import { RequirementStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import { useCreateAndAssignRequirement } from "components/copilot/Framework/hooks";
import { Empty } from "components/molecules/empty";
import SheetsDropdown from "components/molecules/sheets-dropdown";
import { EMPTY_SHEET } from "const-values/Sheets";
import useCreateAtlasRequirement from "hook/Requirements/useCreateRequirement";
import type { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/types";
import { useOutletContext } from "react-router-dom";
import { toggleRequirements } from "store/reducers/copilot/copilotDrawerReducer";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";
import { updateDraftExtractionState } from "store/reducers/draft/DraftsExtractionReducer";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";

const DEFAULT_SEARCH_FILTERS = {
  assignees: "",
  requirementStatusType: "",
};

const DraftSectionRequirements = () => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [searchFilters, setSearchFilters] = useState(DEFAULT_SEARCH_FILTERS);
  const dispatch = useAppDispatch();
  const { createRequirement: createAtlasRequirement, isCreatingRequirement } = useCreateAtlasRequirement();
  const { section, volume, requirements } = useOutletContext<{
    section?: Section;
    volume?: Volume;
    requirements: ComplianceMatrixRow[];
    abortConnection?: () => void;
  }>();
  useRequirementHighlight();
  useValidateRequirementOrder(section);
  const createAndAddToSection = useCreateAndAssignRequirement();

  const autoScroll = useAppSelector((root) => root.sectionState.requirementsState.autoScroll);
  const activeDragRequirementId = useAppSelector((root) => root.sectionState.requirementsState.activeDragRequirementId);

  const sortedAndFilteredRequirements = useMemo(() => {
    return requirements
      .filter((req) => {
        if (searchFilters.assignees) {
          return req.assigned_user_ids?.includes(searchFilters.assignees);
        }
        if (searchFilters.requirementStatusType) {
          return (req.requirement_status || RequirementStatus.Todo) === searchFilters.requirementStatusType;
        }
        return true;
      })
      .sort((a, b) => (a.requirement.section_order || 0) - (b.requirement.section_order || 0))
      .map((row) => ({ ...row, id: row.requirement.id }));
  }, [requirements, searchFilters]);

  const activeRow = useMemo(
    () => requirements.find((row) => row?.requirement?.id === activeDragRequirementId),
    [activeDragRequirementId, requirements],
  );

  const sortedSections = useMemo(() => {
    return (
      volume?.sections?.reduce<FormattedSection[]>((acc, section) => {
        if (!section.parent_id) {
          const subsections = volume?.sections?.filter(({ parent_id }) => parent_id === section.id);
          return [...acc, { ...section, subsections }];
        }

        return acc;
      }, []) || []
    );
  }, [volume?.sections]);

  const onCreateRequirement = useCallback(
    async (sheet: Sheet) => {
      if (section) {
        try {
          const atlasRequirement = await createAtlasRequirement({ content: "" });

          const createdRequirement = createComplianceMatrixRowRequirement({
            id: atlasRequirement?.id,
            ...(sheet?.id !== EMPTY_SHEET.id && { extraction_id: sheet?.id }),
          });

          createAndAddToSection(section.id, undefined, { requirement: createdRequirement });
        } catch {}
      }
    },
    [createAndAddToSection, createAtlasRequirement, section],
  );

  useLayoutEffect(() => {
    if (autoScroll) {
      scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight + 20);
      dispatch(setRequirementsState({ autoScroll: false }));
    }
  }, [autoScroll, dispatch]);

  const checkedState = useAppSelector((root) => root.requirements.checkedState);
  const allCheckedReqIds = useMemo(() => Object.keys(checkedState).filter((id) => checkedState[id]), [checkedState]);
  const getCheckedRows = useMemo(() => {
    return sortedAndFilteredRequirements?.filter((v) => allCheckedReqIds.includes(v?.requirement?.id));
  }, [allCheckedReqIds, sortedAndFilteredRequirements]);
  const isAllSelected = useMemo(() => {
    return !!sortedAndFilteredRequirements?.length && sortedAndFilteredRequirements?.length === getCheckedRows?.length;
  }, [getCheckedRows?.length, sortedAndFilteredRequirements?.length]);
  const handleToggleCheckAll = useCallback(
    (checked: boolean) => {
      const checkedReqs = sortedAndFilteredRequirements.reduce(
        (acc, row) => ({ ...acc, [row?.requirement?.id]: checked }),
        {},
      );
      dispatch(setCheckedState(checkedReqs));
      if (volume?.id) dispatch(updateDraftExtractionState({ [volume.id]: { id: volume.id, isOpen: false } }));
    },
    [dispatch, sortedAndFilteredRequirements, volume?.id],
  );

  if (!section) return null;

  if (!requirements?.length)
    return (
      <div className="relative h-full flex flex-col mt-4 z-[1]">
        {!section.section_manager?.isIgnored && (
          <div className="flex flex-col gap-4 px-3.5">
            <ManagerCard sectionRequirements={requirements} />
            <div className="w-full h-[1.5px] mb-4 bg-[#E2E8F0]" />
          </div>
        )}
        <div className="relative h-full flex flex-col-reverse">
          {new Array(8).fill(0).map((_, i) => (
            <div
              key={i}
              className="absolute px-8 bg-slate-50 left-3.5 right-3.5 gap-3 h-1/3 min-h-[200px] rounded-md shadow flex flex-col items-center justify-center"
              css={[
                {
                  opacity: (-i + 9) / 9,
                  top: 12 * i,
                  transform: `scaleX(${(-i + (9 + i * 0.8)) / 9})`,
                  zIndex: 9 - i,
                },
              ]}
            >
              {!i && (
                <>
                  <div className="text-slate-900 font-semibold text-base">Add Requirement</div>
                  <div className="text-slate-500 text-sm text-center">
                    Add requirement from the{" "}
                    <button
                      onClick={() => dispatch(toggleRequirements(true))}
                      className="text-action font-medium duration-100 hover:text-action-hover"
                    >
                      requirements drawer
                    </button>{" "}
                    or{" "}
                    <SheetsDropdown disabled={isCreatingRequirement} onSelect={onCreateRequirement}>
                      <div className="text-action font-medium duration-100 hover:text-action-hover">
                        create a new one
                      </div>
                    </SheetsDropdown>
                    .
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="relative flex-1 h-full w-full">
        <div
          ref={scrollRef}
          className="overflow-y-auto overflow-x-hidden flex-1 pl-3 pr-5 absolute top-0 right-0 bottom-0 left-0"
        >
          {!section.section_manager?.isIgnored && (
            <div className="flex flex-col gap-4 pl-[30px] pt-4">
              <ManagerCard sectionRequirements={sortedAndFilteredRequirements} />
              <div className="w-full h-[1.5px] mb-2 bg-[#E2E8F0]" />
            </div>
          )}

          {!!requirements.length && (
            <div className="sticky flex items-center justify-between top-0 w-full bg-white z-[3] ml-0.5 pr-1 py-1.5">
              <div className="-ml-0.5 flex items-center rounded-md pl-1 pr-1 text-xs text-slate-500 gap-2">
                <Checkbox size="sm" checked={isAllSelected} onCheck={handleToggleCheckAll} />
                Select all
              </div>
              <DraftSectionRequirementsFilters
                searchFilters={searchFilters}
                setSearchFilters={(filters) => {
                  setSearchFilters(filters);
                  dispatch(setCheckedState({}));
                }}
              />
            </div>
          )}

          {!sortedAndFilteredRequirements.length && (
            <Empty
              name="EmptyList"
              containerProps={{
                className: "flex flex-col items-center h-[calc(100%-224px)] justify-center m-auto",
              }}
              heading="No requirements found that match the filter"
              title={
                <button
                  onClick={() => setSearchFilters(DEFAULT_SEARCH_FILTERS)}
                  className="font-medium text-sm text-action duration-100 hover:text-action-hover"
                >
                  Reset filters
                </button>
              }
            />
          )}
          <SortableContext
            id="TEMPLATE_REQUIREMENTS"
            items={sortedAndFilteredRequirements || []}
            strategy={verticalListSortingStrategy}
          >
            <div className="relative flex flex-col gap-4 pt-2 pb-14">
              {sortedAndFilteredRequirements?.map((row) => (
                <SortableItem
                  key={row.requirement.id}
                  id={row.requirement.id}
                  row={row}
                  sortedSections={sortedSections}
                />
              ))}
            </div>
            <DragOverlay style={{ transformOrigin: "0 0 " }}>
              {!!activeRow?.requirement && <SectionRequirement row={activeRow} isDragging />}
            </DragOverlay>
          </SortableContext>
        </div>
      </div>
    </div>
  );
};

export default memo(DraftSectionRequirements);

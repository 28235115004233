/** @jsxImportSource @emotion/react */
import { Search } from "lucide-react";
import React from "react";

interface SearchBarProps {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: React.FC<SearchBarProps> = ({ query, setQuery }) => {
  return (
    <div className="relative flex-1">
      <Search size={14} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      <input
        autoFocus
        className="w-full pl-8 border border-gray-200 py-2 text-sm text-gray-600 rounded-md focus:outline-none"
        placeholder="Search workflows..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;

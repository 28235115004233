/** @jsxImportSource @emotion/react */

import { Avatar } from "components/molecules/avatar";
import { useAppSelector } from "store/storeTypes";
import type { CoreBlock as LiveCoreBlock } from "utils/yjs-configs/ai-assistant/schema";
import Icon from "components/atoms/icons/Icon";
import Popover from "components/atoms/popover";
import tw, { theme } from "twin.macro";
import { getFileIcon } from "utils/getFileIcon";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { MarkdownEditor } from "components/copilot/MarkdownEditor";
import FilesBlock from "./FilesBlock";
import GenericBlock from "./GenericBlock";
import { pluralizeWord } from "utils/string";
import { AVATAR_BG_COLOR, INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";

const CoreBlock = ({ block }: { block: ToImmutable<LiveCoreBlock> }) => {
  const activeSession = useAppSelector((root) => root.aiAssistantState.activeSession);
  const currentUser = useAppSelector((root) => root.auth.currentUser);
  const uploadedDocuments = useAppSelector((root) => root.aiAssistantState.uploadedDocuments);

  const totalDocuments =
    (block.sources?.length || 0) +
    (block.inputFileType !== INPUT_FIELD_OPTIONS.contentLibrary ? (uploadedDocuments?.length ?? 0) : 0);

  if (block.inputFileType === INPUT_FIELD_OPTIONS.files) {
    return <FilesBlock />;
  }

  if (
    block.inputFileType === INPUT_FIELD_OPTIONS.contentLibrary ||
    block.inputFileType === INPUT_FIELD_OPTIONS.allFiles
  ) {
    let message;
    if (!block.sources) {
      message = "No files selected";
    } else {
      message = `${totalDocuments} ${pluralizeWord(totalDocuments, "file")} selected`;
    }
    return <GenericBlock message={message} />;
  }

  if (
    !block.body ||
    block.inputFileType === INPUT_FIELD_OPTIONS.checklist ||
    block.inputFileType === INPUT_FIELD_OPTIONS.checklistGroup
  )
    return;

  return (
    <div className="flex flex-row gap-2.5 w-full">
      <Avatar
        size={26}
        id={currentUser?.username}
        name={currentUser?.username}
        className="text-base"
        isCircle={false}
        bgColor={AVATAR_BG_COLOR}
        textColor={theme`colors.gray.darkest`}
      />
      <div className="flex flex-col gap-3 flex-1 min-w-0 text-sm">
        <MarkdownEditor content={block.body} />
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-2 items-center text-slate-500">
            {!!block.sources?.length && !activeSession?.workflow && (
              <>
                <Popover
                  contentProps={{ css: tw`max-w-[600px] mx-0`, align: "start" }}
                  content={
                    <div className="flex flex-col gap-1 max-h-80 overflow-y-auto">
                      {block.sources.map((source, i) => (
                        <div
                          key={source.id}
                          className="px-2 py-1.5 w-full flex items-center gap-1.5"
                          css={[i !== (block.sources?.length || 0) - 1 && tw`border-b border-gray-light`]}
                        >
                          <img className="w-4" src={getFileIcon(source.extension_type)} alt={source.name} />
                          <div title={source.name} className="truncate text-xs">
                            {source.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <div className="cursor-pointer group flex flex-row gap-1 items-center duration-150 hover:text-black">
                    <Icon name="Paperclip" className="w-4 h-4" />
                    <div
                      className="flex items-center justify-center backdrop-blur-sm bg-slate-500 text-white text-xxs rounded-full duration-150 group-hover:bg-black"
                      css={[
                        String(block.sources?.length || 0).length > 2
                          ? tw`w-4 h-4 text-[8px]`
                          : tw`w-3.5 h-3.5 text-xxs`,
                      ]}
                    >
                      {block.sources.length}
                    </div>
                  </div>
                </Popover>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreBlock;

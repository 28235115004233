import type { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import { useMemo } from "react";
import { Pencil, Trash2 } from "lucide-react";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";

export const useRowActions = ({
  session,
  onEditTriggered,
  onDelete,
}: {
  session: ToImmutable<AIAssistantSession | WritingAssistantSession>;
  onEditTriggered: () => void;
  onDelete: () => void;
}): MenuItem<void>[] => {
  return useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex flex-row items-center gap-2">
            <Pencil size={14} />
            Rename
          </div>
        ),
        onSelect: onEditTriggered,
      },
      {
        key: 2,
        label: (
          <div className="text-red-500 flex flex-row items-center gap-2">
            <Trash2 size={14} />
            Delete
          </div>
        ),
        onSelect: () => {
          triggerConfirm({
            proceedLabel: "Delete",
            header: "Delete chat?",
            body: (
              <div>
                This will delete <span className="font-semibold">{session.name}</span>
              </div>
            ),
          }).then((proceed) => {
            if (proceed) {
              onDelete();
            }
          });
        },
      },
    ],
    [onEditTriggered, session.name, onDelete],
  );
};

import type { MutationOptions } from "@tanstack/react-query";
import { listItemMutation } from "api/mutations";
import { queryClient } from "api/queryClient";
import type { AxiosError } from "axios";
import axios from "axios";
import type { Form, FormItem, FormItemStatus } from "types/Capture";
import { useParams, useSearchParams } from "react-router-dom";
import { CAPTURE_SCHEMA_TYPE_PREFIX } from "const-values/Capture";
import { CAPTURE_FORMS_QUERY_KEY } from "hook/capture/useGetForms";
import { useRef, useState } from "react";
import { useDebounce } from "react-use";

export const CAPTURE_ITEM_UPDATE_KEY = "capture/item";

type UpdateItemDetailsVariables = Partial<{
  assignee: string | null;
  status: FormItemStatus;
  deadline: string | null;
}>;

export const useUpdateItemDetails = (formId: string, itemId: string) => {
  const { schemaType } = useParams();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const listQueryKey = [
    CAPTURE_FORMS_QUERY_KEY,
    { project_id: projectId, schema_type: `${CAPTURE_SCHEMA_TYPE_PREFIX}:${schemaType}` },
  ];

  return listItemMutation.update<UpdateItemDetailsVariables, FormItem>(
    [CAPTURE_ITEM_UPDATE_KEY, itemId],
    (variables) => axios.put(`/forms/${formId}/item/${itemId}/task_details`, variables).then((res) => res.data),
    {
      onMutate(variables, previousValue) {
        const forms = queryClient.getQueryData<Form[]>(listQueryKey) || [];
        const currentFormIdx = forms.findIndex((form) => form.id === formId);
        const currentForm = forms[currentFormIdx];
        const prevValue = previousValue || currentForm.items.find((item) => item.id === itemId);
        const newValue = {
          ...prevValue,
          task_details: { ...(previousValue?.task_details || {}), ...variables },
        } as FormItem;
        const updatedFormItems = currentForm.items.map((item) => (item.id === prevValue?.id ? newValue : item));
        const updatedFormsList = forms.map((form) =>
          form.id === currentForm.id ? { ...form, items: updatedFormItems } : form,
        );

        queryClient.setQueryData<Form[]>(listQueryKey, updatedFormsList);
        return { previousValue: prevValue, newValue };
      },
      onSettled() {
        queryClient.invalidateQueries({ queryKey: [CAPTURE_FORMS_QUERY_KEY] });
      },
    },
  );
};

type UpdateItemResponseVariables = {
  response: string;
};

interface Options extends MutationOptions<FormItem, AxiosError, UpdateItemResponseVariables> {}

export const useUpdateItemResponse = (formId: string, itemId: string, options?: Options) => {
  const { schemaType } = useParams();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const listQueryKey = [
    CAPTURE_FORMS_QUERY_KEY,
    { project_id: projectId, schema_type: `${CAPTURE_SCHEMA_TYPE_PREFIX}:${schemaType}` },
  ];

  return listItemMutation.update<UpdateItemResponseVariables, FormItem>(
    [CAPTURE_ITEM_UPDATE_KEY, itemId],
    (variables) => axios.put(`/forms/${formId}/item/${itemId}`, variables).then((res) => res.data),
    {
      onMutate(_, previousValue) {
        const forms = queryClient.getQueryData<Form[]>(listQueryKey) || [];
        const currentFormIdx = forms.findIndex((form) => form.id === formId);
        const currentForm = forms[currentFormIdx];
        const prevValue = previousValue || currentForm.items.find((item) => item.id === itemId);
        const newValue = {
          ...prevValue,
          response: prevValue?.response,
        } as FormItem;
        const updatedFormItems = currentForm.items.map((item) => (item.id === prevValue?.id ? newValue : item));
        const updatedFormsList = forms.map((form) =>
          form.id === currentForm.id ? { ...form, items: updatedFormItems } : form,
        );

        queryClient.setQueryData<Form[]>(listQueryKey, updatedFormsList);
        return { previousValue: prevValue, newValue };
      },
      onSettled() {
        queryClient.invalidateQueries({ queryKey: [CAPTURE_FORMS_QUERY_KEY] });
      },
    },
  );
};

export const useFormResponse = (formItemResponse: string | null, formId: string, itemId: string) => {
  const { mutate: updateResponse } = useUpdateItemResponse(formId, itemId);
  const [localResponse, setLocalResponse] = useState(formItemResponse || "");
  const isFirstRender = useRef(true);

  useDebounce(
    () => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      updateResponse({ response: localResponse });
    },
    500,
    [localResponse],
  );

  return {
    localResponse,
    setLocalResponse,
  };
};

import Popover from "components/atoms/popover";
import type { Props as PopoverProps } from "components/atoms/popover/Popover";
import type { PropsWithChildren } from "react";
import type { BaseFile } from "types/FileStorage";
import ContentDrivePopoverContent from "./ContentDrivePopoverContent";

interface Props extends Omit<PopoverProps, "content"> {
  handleSelectedFiles: (checked: boolean, files: BaseFile[]) => void;
  selectedFiles: string[];
  onClear: (files: BaseFile[]) => void;
}

const ContentDrivePopover = ({ handleSelectedFiles, onClear, selectedFiles, ...props }: PropsWithChildren<Props>) => {
  return (
    <Popover
      contentProps={{ side: "bottom" }}
      content={
        <ContentDrivePopoverContent
          handleSelectedFiles={handleSelectedFiles}
          onClear={onClear}
          selectedFiles={selectedFiles}
        />
      }
      {...props}
    />
  );
};

export default ContentDrivePopover;

import { useFlags } from "hook/useFlags";
import { CopilotPresencePage } from "types/Presence";
import { LEGACY_FLOW_PAGE_SEARCH_PARAMS, NEW_FLOW_PAGE_SEARCH_PARAMS } from "./constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export const useSearchParamsRedirection = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "";
  const flags = useFlags();
  const navigate = useNavigate();
  const isLegacyPage = LEGACY_FLOW_PAGE_SEARCH_PARAMS.includes(tab as CopilotPresencePage);
  const isNewPage = NEW_FLOW_PAGE_SEARCH_PARAMS.includes(tab as CopilotPresencePage);
  const shouldRedirectToNewFlow = isLegacyPage && flags.enableNewProjectFlow;
  const shouldRedirectToOldFlow = isNewPage && !flags.enableNewProjectFlow;

  useEffect(() => {
    if (shouldRedirectToNewFlow) {
      const params = new URLSearchParams(searchParams);
      const docId = searchParams.get("docId");
      const tab = searchParams.get("tab");

      if (tab === CopilotPresencePage.Proposal && docId) {
        navigate(`/dashboard/contracts/details?${params.toString()}`, { replace: true });
      } else {
        params.set("tab", CopilotPresencePage.Inputs);
        navigate(`/dashboard/contracts/details/inputs?${params.toString()}`, { replace: true });
      }
    }

    if (shouldRedirectToOldFlow) {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete("docId");
      newParams.set("tab", CopilotPresencePage.Proposal);
      navigate(`/dashboard/contracts/details?${newParams.toString()}`, { replace: true });
    }
  }, [shouldRedirectToNewFlow, shouldRedirectToOldFlow, navigate, searchParams]);
};

/** @jsxImportSource @emotion/react */

import Tooltip from "components/atoms/tooltip/Tooltip";
import { useRef } from "react";
import { setEnableInternet } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { SOURCE_TO_META, Source } from "../../constants";
import { useFlags } from "hook/useFlags";
import Switch from "components/atoms/switch";
import FileSelection from "./FileSelection";
import type { InstructionExtraState } from "../../hooks";

type Props = {
  instructionExtraState: InstructionExtraState;
};

const WritingAssistantInputActions = ({ instructionExtraState }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const enableInternet = useAppSelector((store) => store.writingAssistant.enableInternet);
  const { excludedComponents } = instructionExtraState;
  const { internet: shouldHideInternet } = excludedComponents;

  return (
    <div ref={ref} className="flex flex-row gap-1 items-center w-full h-7 bg-transparent justify-between">
      <FileSelection containerNode={ref.current} instructionExtraState={instructionExtraState} />
      {!flags.disableInternetChat && !shouldHideInternet && (
        <Tooltip content={SOURCE_TO_META[Source.Internet].tooltip}>
          <div className="flex flex-row items-center gap-1" key={Source.Internet}>
            <Switch
              size="sm"
              variant="outline"
              checked={enableInternet}
              onCheckedChange={(checked) => {
                dispatch(setEnableInternet(checked));
              }}
            />
            <span className="text-gray-400 text-sm" css={[enableInternet && tw`text-gray-700`]}>
              Internet
            </span>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default WritingAssistantInputActions;

import type { ReactElement } from "react";
import { ArrowDownNarrowWide, ArrowUpNarrowWide, SpellCheck, Mic, WandSparkles } from "lucide-react";
import Icon from "components/atoms/icons/Icon";

export enum AiOption {
  ImproveWriting = "Improve writing",
  Grammer = "Fix spelling & grammar",
  Shorter = "Make shorter",
  Longer = "Make longer",
  Tone = "Change tone",
  Revise = "Revice",
}

export enum ShortenOption {
  ExtraShort = "ExtraShort",
  Short = "Short",
  Medium = "Medium",
  Long = "Long",
}

export enum ToneOption {
  Professional = "Professional",
  Concise = "Concise",
  Official = "Official",
  Confident = "Confident",
  Persuasive = "Persuasive",
  SolutionOriented = "Solution-Oriented",
  CustomerCentric = "Customer-Centric",
  ActiveVoice = "Active Voice",
}

interface SubItem extends Omit<MenuItem, "value"> {
  value: ToneOption | ShortenOption;
}

type MenuItem = {
  key: number;
  icon?: ReactElement;
  label: string;
  value: AiOption;
  subitems?: SubItem[];
};

export const menuItems: MenuItem[] = [
  {
    key: 1,
    icon: <WandSparkles size={16} color="#A782C3" />,
    label: "Improve writing",
    value: AiOption.ImproveWriting,
  },
  {
    key: 2,
    icon: <SpellCheck size={16} color="#A782C3" />,
    label: "Fix spelling & grammar",
    value: AiOption.Grammer,
  },
  {
    key: 3,
    icon: <ArrowUpNarrowWide size={16} color="#A782C3" />,
    label: "Make shorter",
    value: AiOption.Shorter,
    subitems: [
      { key: 1, label: "Less than 50 words", value: ShortenOption.ExtraShort },
      { key: 2, label: "Less than 100 words", value: ShortenOption.Short },
      { key: 3, label: "Less than 200 words", value: ShortenOption.Medium },
      { key: 4, label: "Less than 500 words", value: ShortenOption.Long },
    ],
  },
  {
    key: 4,
    icon: <ArrowDownNarrowWide size={16} color="#A782C3" />,
    label: "Make longer",
    value: AiOption.Longer,
  },
  {
    key: 5,
    icon: <Mic size={16} color="#A782C3" />,
    label: "Change tone",
    value: AiOption.Tone,
    subitems: [
      { key: 1, label: "Professional", value: ToneOption.Professional },
      { key: 2, label: "Concise", value: ToneOption.Concise },
      { key: 3, label: "Official", value: ToneOption.Official },
      { key: 4, label: "Confident", value: ToneOption.Confident },
      { key: 5, label: "Persuasive", value: ToneOption.Persuasive },
      { key: 6, label: "Solution-Oriented", value: ToneOption.SolutionOriented },
      { key: 7, label: "Customer-Centric", value: ToneOption.CustomerCentric },
      { key: 8, label: "Active Voice", value: ToneOption.ActiveVoice },
    ],
  },
];

export const dynamicMenuItems: MenuItem[] = [
  {
    key: 6,
    icon: <Icon name="Revise" className="text-[#A782C3] w-4 h-4" />,
    label: "Revise",
    value: AiOption.Revise,
  },
];

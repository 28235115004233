import { createContext, type ReactNode, useContext } from "react";

export enum NotificationTypeEnum {
  Success = 1,
  Warning,
}

export type NotificationToastState = {
  variant: NotificationTypeEnum;
  title?: ReactNode;
  msg?: ReactNode;
  duration?: number;
};

export interface NotificationContextState {
  setToast: {
    success: (state: Omit<NotificationToastState, "variant">) => void;
    warning: (state: Omit<NotificationToastState, "variant">) => void;
  };
  clearToast: () => void;
  notification?: Partial<NotificationToastState>;
  showRequirementSuccessToast: (action: string, count: number) => void;
}

export const DocViewNotificationContext = createContext<NotificationContextState>({} as NotificationContextState);

export const useDocViewNotification = () => useContext(DocViewNotificationContext);

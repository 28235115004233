/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useRef, useState } from "react";
import tw, { theme } from "twin.macro";
import type { Section, Storage as ImmutableStorage, Volume, BaseSource } from "../../CopilotSchemaImmutableTypes";
import type { Volume as LiveVolume } from "../../CopilotSchemaTypes";
import "../styles.css";
import Icon from "components/atoms/icons/Icon";
import "twin.macro";
import { useOutletContext, useSearchParams } from "react-router-dom";
import IconButton from "components/atoms/icon-button/IconButton";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { getWordCount } from "utils/getWordCount";
import AiSmartLight from "components/organisms/ai-smart-light";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import type { Framework, Storage } from "../../CopilotSchemaTypes";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import Selections from "../../Selections";
import { useObserveSseController } from "hook/useObserveSseController";
import { AiOption } from "components/organisms/ai-smart-light/utils";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import { ArrowUp, ChevronUp, Orbit, Square } from "lucide-react";
import { useAIReviseStream } from "./hooks";
import ProposalSectionLoading from "Assets/gifs/proposal-section-loading.gif";
import BaseSources from "./BaseSources";
import { setProposalState } from "store/reducers/draft/sectionReducer";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import { useDebounce } from "react-use";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { useMutation, useStorage, useUpdateMyPresence } from "YJSProvider/createYJSContext";
import { isEqual } from "lodash";
import { findIndex, LiveList, LiveObject } from "YJSProvider/LiveObjects";

const LOADING_MSGS = ["Searching for relevant content", "Analyzing data", "Processing content", "Preparing response"];

export type RequirementBlock = {
  requirement: string;
  response: string;
  section_order?: number;
};

type Props = {
  section: Section;
  volume: Volume;
};

const SectionProposal = ({ section, volume }: Props) => {
  const complianceMatrixState = useStorage((root) => root.compliance_matrix as ImmutableStorage["compliance_matrix"]);

  const winThemes = useStorage(
    (root) => (root.win_themes as ImmutableStorage["win_themes"])?.filter(({ content }) => !!content),
    isEqual,
  );
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((store) => store.auth);
  const { sectionInProgress } = useAppSelector((store) => store.sectionState.proposalState);
  const updateMyPresence = useUpdateMyPresence();
  const [selectedTextRange, setSelectedTextRange] = useState<{ startIndex: number; endIndex: number } | undefined>();
  const [selectedText, setSelectedText] = useState<string>("");
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
  const proposalRef = useRef<HTMLTextAreaElement>(null);
  const [isReplacingText, setIsReplacingText] = useState(false);
  const [proposal, setProposal] = useState<string>(section.proposal || "");
  const [prevProposal, setPrevProposal] = useState<string>("");
  const [loadingMsg, setLoadingMsg] = useState<string>(LOADING_MSGS[0]);
  const [locked, setLocked] = useState<boolean>(false);
  const [reviseFeedback, setReviseFeedback] = useState<string>("");
  const [reviseActive, setReviseActive] = useState(false);
  const reviseRef = useRef<HTMLInputElement | null>(null);
  const { setSectionProposal } = useFrameworkOperations();
  const { abortConnection } = useOutletContext<{
    section?: Section;
    volume?: Volume;
    abortConnection?: () => void;
  }>();
  const sectionSources = section.proposal_sources;

  const {
    isLoading: isRevising,
    setIsLoading: setIsRevising,
    reviseText,
    abortConnection: abortRevise,
  } = useAIReviseStream((text) => {
    if (selectedTextRange) {
      setProposal((prev) => {
        setPrevProposal(prev);
        const rawValue =
          prev.substring(0, selectedTextRange.startIndex) +
          text +
          prev.substring(selectedTextRange.endIndex, prev.length);
        return rawValue;
      });
    }

    setSelectedTextRange({
      startIndex: selectedTextRange?.startIndex || 0,
      endIndex: text.length + (selectedTextRange?.startIndex || 0),
    });
    setSelectedText(text);
  }, internalContractId);

  useObserveSseController(abortRevise, () => {
    updateMyPresence({
      selectedId: null,
    });
  });

  const firstUpdate = useRef(true);
  useDebounce(
    () => {
      if (proposal !== section.proposal && !firstUpdate.current && !locked && !isProposalGenerating) {
        setSectionProposal(volume.id, section.id, proposal);
      }
      firstUpdate.current = false;
    },
    200,
    [proposal, section.proposal, locked],
  );

  const handleSetSources = useMutation(
    ({ storage }, sources?: BaseSource[]) => {
      const volumes = (storage.get("framework") as Storage["framework"])?.get("volumes") as
        | Framework["volumes"]
        | undefined;
      if (!volumes) return;
      const volumeIndex = findIndex(volumes, (v) => v.get("id") === volume.id);
      const sections = volumes?.get(volumeIndex)?.get("sections") as LiveVolume["sections"] | undefined;
      if (!sections) return;
      const sectionIndex = findIndex(sections, (s) => s.get("id") === section.id);
      if (sectionIndex === -1 || sectionIndex === undefined) return;
      const sectionFound = sections?.get(sectionIndex);

      if (sources === null || sources === undefined) {
        // if sources is null, delete the sources so that it doesn't show up in the UI
        sectionFound?.delete("proposal_sources");
      } else {
        const liveSources = new LiveList(sources.map((source) => new LiveObject(source)));
        sectionFound?.set("proposal_sources", liveSources);
      }
    },
    [section],
  );

  const sectionRequirements = useMemo(
    () =>
      complianceMatrixState.filter(
        (row) => row.proposal_reference.section_id === section.id && !row.requirement.skipped,
      ),
    [complianceMatrixState, section.id],
  );

  useEffect(() => {
    if (reviseActive) {
      setTimeout(() => reviseRef?.current?.focus(), 200);
    }
  }, [reviseActive]);

  const wordCount = useMemo(() => getWordCount(proposal), [proposal]);
  const isProposalGenerating = sectionInProgress?.id === section?.id;
  const isLoading = isReplacingText || isRevising || isProposalGenerating;
  const pageCount = Math.ceil(wordCount / 400);
  const canRevise =
    !isProposalGenerating && !isReplacingText && !isRevising && !!reviseFeedback?.trim() && !!selectedText?.trim();
  const animinationSpeed = sectionRequirements.length >= 7 ? 16000 : 8000;
  useAnimateLoadingMsg(isProposalGenerating, animinationSpeed, LOADING_MSGS, (msg) => setLoadingMsg(msg));

  useEffect(() => {
    if (locked || isProposalGenerating) setProposal(section.proposal || "");
  }, [isLoading, locked, section.proposal, isProposalGenerating]);

  const range = useMemo(() => {
    if (
      Number.isInteger(selectedTextRange?.startIndex) &&
      Number.isInteger(selectedTextRange?.endIndex) &&
      selectedTextRange?.startIndex !== selectedTextRange?.endIndex
    ) {
      return [selectedTextRange?.startIndex, selectedTextRange?.endIndex];
    }
    return [0, 0];
  }, [selectedTextRange?.endIndex, selectedTextRange?.startIndex]);

  useEffect(() => {
    const onVisibileChange = () => {
      // @ts-expect-error
      const editorNode = proposalRef.current.editor;
      if ((document.activeElement === editorNode && document.visibilityState === "visible") || isProposalGenerating) {
        if (!isProposalGenerating) editorNode?.focus();
        updateMyPresence({
          selectedId: `${section.id}-proposal-section`,
          name: currentUser?.username,
        });
      }
    };
    document.addEventListener("visibilitychange", onVisibileChange);
    return () => document.removeEventListener("visibilitychange", onVisibileChange);
  }, [currentUser?.username, isProposalGenerating, section.id, updateMyPresence]);

  useEffect(() => {
    if (selectedText && isLoading) {
      updateMyPresence({
        selectedId: `${section.id}-proposal-section`,
        name: currentUser?.username,
      });
    }
  }, [currentUser?.username, isLoading, section.id, selectedText, updateMyPresence]);

  const shouldShowLoader = isProposalGenerating && !section.proposal?.trim();

  const canGenerate = useMemo(() => {
    const hasValidReqs =
      !!sectionRequirements?.length &&
      sectionRequirements.every(
        ({ requirement, written_content }) =>
          !!(requirement.content?.trim() || requirement.summarized_content?.trim()) && !!written_content?.trim(),
      );

    return !locked && hasValidReqs;
  }, [locked, sectionRequirements]);

  return (
    <>
      <div className="flex flex-col">
        <div className="bg-slate-100 py-3 px-4 border border-light border-b-0 rounded-t-md flex flex-col overflow-hidden">
          <div className="text-slate-500 text-xs font-medium">Full Section Draft</div>
        </div>
        <div className="flex-col w-full h-full bg-white flex overflow-hidden">
          <div className="flex items-center justify-between p-4 gap-8 border border-zinc-200">
            <div className="flex items-center gap-1 h-8">
              <Tooltip content="Page count is estimated with single-spaced, 12 point, Times New Roman Font.">
                <div className="text-gray-500 text-xs font-normal">
                  {wordCount || 0} word{(wordCount || 0) !== 1 && "s"}, {pageCount || 0} page
                  {(pageCount || 0) !== 1 && "s"}
                </div>
              </Tooltip>
              {!locked && (
                <>
                  <div className="w-px h-4 mx-1 bg-gray-400" />
                  <AiSmartLight
                    dynamicItems={[{ value: AiOption.Revise, onSelect: () => setReviseActive(true) }]}
                    isReplacingText={isReplacingText}
                    setIsReplacingText={setIsReplacingText}
                    replaceText={(text) => {
                      if (selectedTextRange) {
                        setProposal((prev) => {
                          setPrevProposal(prev);
                          return (
                            prev.substring(0, selectedTextRange.startIndex) +
                            text +
                            prev.substring(selectedTextRange.endIndex, prev.length)
                          );
                        });
                      }

                      setSelectedTextRange({
                        startIndex: selectedTextRange?.startIndex || 0,
                        endIndex: text.length + (selectedTextRange?.startIndex || 0),
                      });
                      setSelectedText(text);
                      setReviseActive(false);
                      setReviseFeedback("");
                    }}
                    selectedText={selectedText}
                    isSelected={!!selectedTextRange && selectedTextRange.startIndex !== selectedTextRange.endIndex}
                  />
                </>
              )}
              {!locked && prevProposal && !isReplacingText && (
                <>
                  <div className="w-px h-4 mx-1 bg-gray-400" />
                  <Tooltip
                    delayDuration={500}
                    content={<span className="font-normal text-sm text-stone-800">Undo</span>}
                    contentProps={{ className: "!py-1 !px-2" }}
                  >
                    <IconButton
                      name="Undo"
                      className="text-gray-500 p-1 rounded-md duration-150 hover:bg-gray-100"
                      onClick={() => {
                        setProposal(prevProposal);
                        setSelectedText("");
                        setSelectedTextRange(undefined);
                        setPrevProposal("");
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </div>
            {!locked && (
              <div className="flex items-center gap-4">
                <button
                  className="text-zinc-400 text-sm font-normal"
                  css={[!!proposal.length && tw`text-red-500`, isLoading && tw`text-zinc-400 pointer-events-none`]}
                  disabled={!proposal.length}
                  onClick={() => {
                    if (proposal.length) {
                      triggerConfirm({
                        proceedLabel: "Confirm",
                        header: "Are you sure you want to clear the text?",
                        body: "This will clear all of the proposal section text.",
                      }).then((proceed) => {
                        if (proceed) {
                          setPrevProposal("");
                          setProposal("");
                          setSelectedText("");
                          setSelectedTextRange(undefined);
                          handleSetSources(undefined);
                          setTimeout(() => proposalRef.current?.focus(), 300);
                        }
                      });
                    } else {
                      updateMyPresence({
                        selectedId: null,
                      });
                    }
                  }}
                >
                  Clear
                </button>
                <Tooltip
                  disabled={canGenerate}
                  content="Requirements must not be empty and have a response to generate the full section draft."
                >
                  <button
                    className="text-action text-sm font-normal flex duration-150 items-center gap-1 hover:text-action-hover disabled:text-zinc-400"
                    disabled={!canGenerate}
                    onClick={() => {
                      if (locked) return;

                      if (isProposalGenerating) {
                        abortConnection?.();
                        return;
                      }

                      if (!canGenerate) return;

                      if (proposal.trim().length) {
                        triggerConfirm({
                          proceedLabel: "Confirm",
                          header: "Are you sure you want to regenerate?",
                          body: "This will clear all of the proposal section text.",
                        }).then((proceed) => {
                          if (proceed) {
                            if (isProposalGenerating || locked) return;

                            dispatch(
                              setProposalState({
                                sectionInProgress: {
                                  ...section,
                                  full_requirement_title: false,
                                },
                              }),
                            );
                          } else {
                            updateMyPresence({
                              selectedId: null,
                            });
                          }
                        });
                      } else
                        dispatch(
                          setProposalState({
                            sectionInProgress: {
                              ...section,
                              full_requirement_title: false,
                            },
                          }),
                        );

                      updateMyPresence({
                        selectedId: `${section.id}-proposal-section`,
                        name: currentUser?.username,
                      });
                    }}
                  >
                    {isProposalGenerating ? (
                      <div className="relative w-5 flex items-center justify-center">
                        <Orbit
                          className="absolute"
                          css={[
                            isProposalGenerating && {
                              animation: "rotateAnimation 3s infinite linear",
                            },
                          ]}
                          size={18}
                        />
                        <Square className="absolute fill-current" size={8} />
                      </div>
                    ) : (
                      <Icon name="Generate" className="stroke-[1.5]" />
                    )}
                    <div>{isProposalGenerating ? "Stop" : proposal?.length ? "Regenerate" : "Generate"}</div>
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
          <div
            className="pr-2 pl-4 items-center py-0 min-h-0 h-0 duration-150 border-x border-b border-zinc-200 border-b-transparent flex gap-2 overflow-hidden"
            css={[
              reviseActive && tw`py-3 min-h-[42px] border-zinc-200`,
              isRevising && tw`bg-gray-50`,
              isLoading && tw`pointer-events-none`,
            ]}
          >
            <input
              readOnly={isRevising}
              disabled={isRevising}
              ref={reviseRef}
              value={reviseFeedback}
              onChange={(e) => setReviseFeedback(e.target.value)}
              className="flex-1 resize-none outline-none text-sm py-1 disabled:bg-gray-50"
              placeholder={isRevising ? "This could take up to 1 minute..." : "Enter revisions to implement..."}
              onKeyDown={(e) => {
                if (e.key === "Enter" && canRevise && internalContractId) {
                  setReviseFeedback("");
                  reviseText({
                    previous_response: selectedText || "",
                    user_feedback: reviseFeedback,
                    win_themes: winThemes?.map(({ content }) => content) || [],
                  });
                }
              }}
            />
            <div className="flex gap-1">
              <button
                onClick={() => {
                  if (!internalContractId || !canRevise) return;
                  setReviseFeedback("");
                  reviseText({
                    previous_response: selectedText || "",
                    user_feedback: reviseFeedback,
                    win_themes: winThemes?.map(({ content }) => content) || [],
                  });
                }}
                disabled={!canRevise}
                className="bg-action relative text-sm cursor-pointer flex items-center justify-center text-white w-5 min-w-[20px] h-5 duration-150 rounded-full hover:bg-action-hover disabled:bg-gray-200 disabled:text-slate-400 disabled:cursor-default"
                css={[isRevising && tw`pointer-events-none`]}
              >
                {isRevising ? <SpinnerCircle className="h-3.5 w-3.5" /> : <ArrowUp size={14} />}
              </button>
              <button
                onClick={() => {
                  setReviseActive(false);
                  setReviseFeedback("");
                }}
                disabled={isRevising}
                className="bg-slate-300 text-sm cursor-pointer flex items-center justify-center text-[16px] text-slate-800 w-5 min-w-[20px] h-5 duration-150 rounded-full hover:bg-gray-200 disabled:bg-gray-200 disabled:text-slate-400 disabled:cursor-default"
              >
                <ChevronUp size={14} />
              </button>
            </div>
          </div>
          <div
            id={`${section.id}-proposal-section`}
            className="relative selected-ai overflow-hidden border-x border-zinc-200 min-h-[386px] text-stone-900 text-sm font-normal cursor-text resize-none w-full h-full !outline-none selected-ai"
            css={[
              (!!locked || isLoading) && tw`pointer-events-none`,
              {
                ".public-DraftEditor-content": {
                  minHeight: 384,
                  position: "relative",
                  ...tw`p-4`,
                },
                ".public-DraftEditorPlaceholder-root": {
                  position: "absolute",
                  color: theme`colors.gray.400`,
                  ...tw`p-4`,
                },
              },
            ]}
          >
            <Selections
              id={`${section.id}-proposal-section`}
              containerProps={{ css: tw`!rounded-md` }}
              nameProps={{ css: tw`top-0.5 left-0.5 right-auto` }}
              hasActiveOthers={(hasOthers) => hasOthers !== locked && setLocked(hasOthers)}
            />
            {shouldShowLoader && (
              <div className="z-[1] flex justify-center items-center absolute inset-x-0 inset-y-0 bg-[rgba(255,255,255,0.2)] backdrop-blur-sm">
                <div className="flex flex-col gap-2 items-center">
                  <img className="w-[165px] h-[165px]" src={ProposalSectionLoading} alt="" />
                  <div className="text-center text-gray-darkest">
                    <div className="text-base font-medium">
                      {loadingMsg}
                      <span className="loading-ellipsis" />
                    </div>
                    <div className="text-xs text-gray-lightest">
                      {sectionRequirements?.length >= 7
                        ? "This may take a few minutes. Please stay on this screen"
                        : "This may take up to a minute. Please stay on this screen"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <HighlightWithinTextarea
              ref={proposalRef}
              placeholder="Generate a full section draft from this section's requirement responses. Please confirm all requirements assigned to this section have been addressed..."
              value={proposal}
              onChange={(value, selection) => {
                if (locked) return;
                const start = selection?.start || 0;
                const end = selection?.end || 0;

                if (document.activeElement?.tagName === "BODY") return;

                updateMyPresence({
                  selectedId: `${section.id}-proposal-section`,
                  name: currentUser?.username,
                });
                setProposal(value);
                setSelectedTextRange({
                  startIndex: start,
                  endIndex: end,
                });

                const textSelected = value.slice(start, end);

                setSelectedText(textSelected || "");
              }}
              onBlur={() => {
                setTimeout(() => updateMyPresence({ selectedId: null }), 100);
                setSectionProposal(volume.id, section.id, proposal);
              }}
              highlight={range}
            />
          </div>
        </div>
        <BaseSources responseSources={sectionSources} />
      </div>
    </>
  );
};

export default SectionProposal;

import { Button } from "../primitives/Button";
import { CommentIcon } from "../icons/CommentIcon";
import type { Editor } from "@tiptap/react";
import styles from "./Toolbar.module.css";
import { ToolbarTooltip } from "../primitives/Tooltip";

interface ToolbarAddCommentProps {
  editor: Editor;
  variant?: "default" | "bubble";
}

export const ToolbarAddComment = ({ editor, variant }: ToolbarAddCommentProps) => {
  const isBubble = variant === "bubble";

  return (
    <ToolbarTooltip content="Add comment">
      <Button
        variant={isBubble ? "bubble" : "subtle"}
        className={styles.toolbarButton}
        onClick={() => editor.chain().focus().setActiveComment().setDraft().run()}
        disabled={!editor.can().chain().focus().setDraft().run()}
        aria-label="Add comment"
      >
        <div className={`flex ${isBubble ? "items-center" : "flex-col items-center"}`}>
          <CommentIcon style={isBubble ? { color: "#F4F4F5", height: "20px" } : { height: "16px" }} />
          {!isBubble && <span className="text-xs text-gray-darkest">Comment</span>}
        </div>
      </Button>
    </ToolbarTooltip>
  );
};

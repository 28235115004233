/** @jsxImportSource @emotion/react */
import { useRef } from "react";
import { History, FilePlus2 } from "lucide-react";
import tw from "twin.macro";
import FileSelector from "components/atoms/file-selector/FileSelector";
import { useFileUploadHandler } from "./hooks";
import { toggleRecentFilesModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";

interface AttachmentTypeDropdownProps {
  isDisabled: boolean;
  maxFileCount?: number;
}

const AttachmentTypeDropdown: React.FC<AttachmentTypeDropdownProps> = ({ isDisabled, maxFileCount }) => {
  const dispatch = useAppDispatch();
  const { handleFileUpload } = useFileUploadHandler();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (files: FileList | null): void => {
    if (files) {
      handleFileUpload(files, maxFileCount);
    }
    if (fileInputRef.current) {
      // Reset the value so that the same file can be reselected
      fileInputRef.current.value = "";
    }
  };

  const handleRecentFiles = () => {
    dispatch(toggleRecentFilesModal({ open: true }));
  };

  return (
    <div tw="flex flex-col gap-2 p-2">
      <button
        type="button"
        onClick={handleRecentFiles}
        className="pr-4"
        css={[
          isDisabled
            ? tw`text-gray-400 cursor-default`
            : tw`flex items-center text-sm justify-start gap-1 px-2 py-1 rounded-md cursor-pointer duration-100 hover:bg-gray-200 hover:text-black`,
        ]}
      >
        <History size={15} />
        Recent Files
      </button>
      <FileSelector
        onFileUpload={handleFileChange}
        disabled={isDisabled}
        labelClassName="flex items-center text-sm justify-start gap-1.5 px-2 py-1 rounded-md cursor-pointer duration-100 pr-4"
      >
        <FilePlus2 size={14} />
        From Computer
      </FileSelector>
    </div>
  );
};

export default AttachmentTypeDropdown;

import { RotateCcw, Copy } from "lucide-react";
import { getWordCount } from "utils/getWordCount";
import copyText from "utils/copyText";
import { useNotification } from "context/notificationContext";

interface ActionsSectionProps {
  blockBody: string;
  blockId: string;
  trackUserEvent: (event: string, data: object) => void;
  determineBlockType: string;
  refreshMessage: (blockId: string) => void;
}

const ActionsSection = ({
  blockBody,
  blockId,
  trackUserEvent,
  determineBlockType,
  refreshMessage,
}: ActionsSectionProps) => {
  const { setToast } = useNotification();

  const handleCopy = () => {
    copyText(blockBody, () => {
      setToast.success({ msg: "Copied" });
    });
    trackUserEvent("AI Assistant: Copy Button Clicked", {
      word_count: getWordCount(blockBody),
      type: determineBlockType,
    });
  };

  return (
    <div className="flex flex-row gap-2 items-center text-gray-500">
      <button
        className="flex items-center justify-center gap-1.5 font-medium text-xs duration-150 hover:text-black"
        onClick={handleCopy}
      >
        <Copy size={14} />
        Copy
      </button>
      <div className="h-4 w-px bg-gray-500" />
      <button
        onClick={() => refreshMessage(blockId)}
        className="flex items-center justify-center gap-1.5 font-medium text-xs duration-150 hover:text-black"
      >
        <RotateCcw size={14} />
        Regenerate
      </button>
    </div>
  );
};

export default ActionsSection;

/** @jsxImportSource @emotion/react */

import { useRef } from "react";
import { setToggleInternet } from "store/reducers/ai-assistant/aiAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip";
import { SOURCE_TO_META, Source } from "./constants";
import { useFlags } from "hook/useFlags";
import ContentLibraryPopover from "./ai-assistant-input/ContentLibraryPopover";

const AIAssistantInputActions = () => {
  const ref = useRef(null);
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const enableInternet = useAppSelector((root) => root.aiAssistantState.enableInternet);
  return (
    <div ref={ref} className="flex items-center gap-1.5 bg-white">
      <ContentLibraryPopover />
      {!flags.disableInternetChat && (
        <Tooltip delayDuration={800} content={SOURCE_TO_META[Source.Internet].tooltip}>
          <button
            className="flex items-center justify-center gap-1 p-1 rounded-md text-gray-600 hover:text-black duration-100 hover:bg-gray-200"
            key={Source.Internet}
            onClick={() => dispatch(setToggleInternet())}
            css={[enableInternet && tw`bg-gray-200`]}
          >
            <Icon name="Internet" className="w-[18px] h-[18px]" />
            <div className="text-sm">Internet</div>
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default AIAssistantInputActions;

/** @jsxImportSource @emotion/react */

import { isEqual } from "lodash";
import { memo, useEffect, useState } from "react";

import GenerationSettingsMenu from "./GenerationSettingsMenu";
import { useBulkUpdateOperation } from "./hooks";
import RequirementAttributesMenu from "./RequirementAttributesMenu";
import RequirementsBulkUpdateInput from "./RequirementsBulkUpdateInput";
import SplitModal from "./SplitModal";
import { PromptMode } from "./types";

import { triggerConfirm } from "../confirm-modal/utils";
import MoveRequirementOrSectionModal, { TypeToMove } from "../move-requirement-or-section-modal";
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip";
import type { Storage } from "components/copilot/CopilotSchemaImmutableTypes";
import type { Section } from "components/copilot/CopilotSchemaTypes";
import { SIDEBAR } from "const-values/Layout";
import { useNotification } from "context/notificationContext";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import { List, Merge, Split, Trash2, WandSparkles, X } from "lucide-react";
import { useParams } from "react-router-dom";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { createSection } from "utils/framework";
import { useStorage } from "YJSProvider/createYJSContext";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { LiveList } from "YJSProvider/LiveObjects";

const RequirementsBulkUpdate = () => {
  const [promptMode, setPromptMode] = useState<PromptMode | null>(null);
  const [promptContent, setPromptContent] = useState("");
  const [splitOpen, setSplitOpen] = useState({ open: false, reqId: "" });
  const { sidebarVisible } = useAppSelector((root) => root.copilot);
  const dispatch = useAppDispatch();
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const { sectionId } = useParams();
  const { setToast } = useNotification();
  const { checkedReqIds, removeRows, mergeRequirements, assignToSection, setUserInstructions, setWritingPrompts } =
    useBulkUpdateOperation();
  const { addNewVolume, addNewSection } = useFrameworkOperations();

  const volumes = useStorage(
    (storage) =>
      (storage.framework as Storage["framework"])?.volumes?.filter(
        (volume) => !!volume.sections?.length && !!volume.title.trim(),
      ),
    isEqual,
  );

  const addPromptOrGuideline = () => {
    const trimmedContent = promptContent.trim();
    if (!trimmedContent) return;

    const isWritingPrompt = promptMode === PromptMode.WritingPrompt;
    const action = isWritingPrompt ? setWritingPrompts : setUserInstructions;
    const successMessage = isWritingPrompt
      ? `Writing direction added to ${checkedReqIds.length} requirement${checkedReqIds.length !== 1 ? "s" : ""}.`
      : `Guideline added to ${checkedReqIds.length} requirement${checkedReqIds.length !== 1 ? "s" : ""}.`;

    action({ content: trimmedContent });
    setPromptMode(null);
    setPromptContent("");
    setToast.success({ msg: successMessage });
  };

  const canSplit = checkedReqIds?.length === 1;
  const canMerge = checkedReqIds?.length > 1;

  useEffect(() => {
    if (!checkedReqIds.length) setPromptMode(null);
  }, [checkedReqIds.length]);

  if (!checkedReqIds?.length) return null;

  return (
    <>
      <div
        className="fixed bottom-[84px] z-[6] hidden justify-center duration-200 left-0 right-0 pointer-events-none"
        css={[
          { animation: "contentShow 100ms ease-in" },
          sidebarVisible && { left: SIDEBAR.MAX_WIDTH },
          checkedReqIds.length && tw`flex`,
          sectionId && tw`bottom-[44px]`,
        ]}
      >
        <div className="flex items-center gap-3 flex-row rounded-md p-2.5 bg-gray-darkest pointer-events-auto cursor-default">
          <div className="flex h-7 items-center">
            <div className="text-gray-100 h-[inherit] flex items-center px-2 text-xs border border-r-0 border-dashed border-gray-500 rounded-l">
              {checkedReqIds.length} requirements
            </div>
            <button
              onClick={() => {
                dispatch(setCheckedState({}));
                setPromptMode(null);
              }}
              className="h-[inherit] text-gray-200 text-base px-1.5 border border-dashed border-gray-500 rounded-r duration-100 hover:text-white hover:border-gray-100"
            >
              <X size={16} />
            </button>
          </div>
          <div className="bg-gray-500 w-px h-6" />
          {promptMode ? (
            <RequirementsBulkUpdateInput
              promptMode={promptMode}
              promptContent={promptContent}
              setPromptContent={setPromptContent}
              setPromptMode={setPromptMode}
              handleAdd={addPromptOrGuideline}
            />
          ) : (
            <>
              <div className="flex items-center gap-2">
                <Tooltip content="Delete">
                  <button
                    onClick={() => {
                      triggerConfirm({
                        proceedLabel: "Confirm",
                        header: "Are you sure you want to delete the selected requirements?",
                        body: `You have ${checkedReqIds?.length} total requirement${
                          checkedReqIds?.length !== 1 ? "s" : ""
                        } selected.`,
                      }).then((proceed) => {
                        if (proceed) {
                          removeRows();
                        }
                      });
                    }}
                    className="text-gray-300 px-1.5 h-7 rounded duration-150 hover:text-white hover:bg-zinc-700"
                  >
                    <Trash2 size={14} />
                  </button>
                </Tooltip>
              </div>
              <div className="flex items-center gap-2">
                <button
                  disabled={!canSplit}
                  onClick={() => canSplit && setSplitOpen({ open: true, reqId: checkedReqIds?.[0] })}
                  className="flex items-center gap-2 h-7 px-2 border border-gray-500 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed disabled:!border-gray-500"
                >
                  <Split size={14} className="text-sm" />
                  <div className="text-xs">Split</div>
                </button>
                <button
                  disabled={!canMerge}
                  onClick={() => {
                    if (canMerge) {
                      triggerConfirm({
                        header: `Are you sure you want to merge ${checkedReqIds.length} requirements?`,
                        body: "This action will attempt to merge requirements and clear the existing properties.",
                      }).then((proceed) => {
                        if (proceed) {
                          mergeRequirements(!!sectionId);
                        }
                      });
                    }
                  }}
                  className="flex items-center gap-2 h-7 px-2 border border-gray-500 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed disabled:!border-gray-500"
                >
                  <Merge size={14} className="text-sm" />
                  <div className="text-xs">Merge</div>
                </button>
                <button
                  onClick={() => setMoveModalOpen(true)}
                  className="flex items-center gap-2 h-7 px-2 border border-gray-500 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed disabled:!border-gray-500"
                >
                  <Icon name="Swap" className="text-sm" />
                  <div className="text-xs">Move</div>
                </button>
                <RequirementAttributesMenu contentProps={{ sideOffset: 14 }}>
                  <div className="flex cursor-pointer items-center gap-2 h-7 px-2 border border-gray-500 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100">
                    <List size={14} />
                    <div className="text-xs">Properties</div>
                  </div>
                </RequirementAttributesMenu>
                <GenerationSettingsMenu
                  contentProps={{ sideOffset: 14 }}
                  requirementCount={checkedReqIds.length}
                  setPromptMode={setPromptMode}
                >
                  <div className="flex cursor-pointer items-center gap-2 h-7 px-2 border border-gray-500 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100">
                    <WandSparkles size={14} />
                    <div className="text-xs">Generation Settings</div>
                  </div>
                </GenerationSettingsMenu>
              </div>
            </>
          )}
        </div>
      </div>
      <SplitModal
        retainSection={!!sectionId}
        open={splitOpen.open}
        splitRequirementId={splitOpen.reqId}
        onOpenChange={() => setSplitOpen((prev) => ({ ...prev, open: false }))}
      />
      <MoveRequirementOrSectionModal
        onMove={(selectedSectionOrVolume) => {
          assignToSection(selectedSectionOrVolume);
          setToast.success({
            msg: (
              <div>
                {checkedReqIds.length} requirement
                {checkedReqIds.length === 1 ? "" : "s"} moved to{" "}
                <span className="font-semibold  text-sm">{selectedSectionOrVolume.title}</span>.
              </div>
            ),
          });
          if (sectionId) dispatch(setCheckedState({}));
          setMoveModalOpen(false);
        }}
        onCreateOutline={(newSectionConfig) => {
          if (!newSectionConfig) return;
          let newSection: ToImmutable<Section>;

          if (!newSectionConfig.draft?.id) {
            const { item: createdSection } = createSection({
              title: newSectionConfig.section?.title,
            });
            addNewVolume({
              title: newSectionConfig.draft?.title,
              sections: new LiveList([createdSection]),
            });
            newSection = createdSection.toJSON() as ToImmutable<Section>;
          } else {
            newSection = addNewSection(newSectionConfig.draft.id, {
              title: newSectionConfig.section?.title,
            }) as ToImmutable<Section>;
          }

          if (newSectionConfig.assignToSection) {
            assignToSection(newSection);
            setToast.success({
              msg: (
                <div>
                  {checkedReqIds.length} requirement
                  {checkedReqIds.length === 1 ? "" : "s"} moved to{" "}
                  <span className="font-semibold  text-sm">{newSection.title}</span>.
                </div>
              ),
            });
          }

          setMoveModalOpen(false);
          dispatch(setCheckedState({}));
        }}
        type={TypeToMove.Requirement}
        volumes={volumes}
        open={moveModalOpen}
        onOpenChange={setMoveModalOpen}
      />
    </>
  );
};

export default memo(RequirementsBulkUpdate);

import { toggleCreateProjectModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import { Plus } from "lucide-react";
import { useLocalStorage } from "hook/useLocalStorage";

const NotFound = () => {
  const dispatch = useAppDispatch();

  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: localFilters } = useLocalStorage(`contractFilters_${workspace_id}`, {});
  const hasFilters = !!localFilters.status?.length || !!localFilters.response_deadline?.length;

  return (
    <div className="min-h-[84vh] bg-white w-full flex justify-center items-center flex-col py-7 px-2">
      <div className="text-center mt-5">
        <h1 className="font-bold text-xl mb-1">No projects found</h1>
        {hasFilters ? (
          <>
            <div className="text-gray-text text-sm mt-3">Selected filters did not match any projects.</div>
            <div className="text-gray-text text-sm mt-1">Please adjust or clear filters.</div>
          </>
        ) : (
          <div className="text-gray-text text-sm mt-3">Create a new project to get started</div>
        )}
      </div>
      <button
        className="bg-gray-200 flex min-w-[40px] my-5 min-h-[26px] text-gray-600 gap-1 py-1.5 pr-3.5 pl-2 text-sm items-center rounded-md"
        onClick={() => dispatch(toggleCreateProjectModal({ open: true }))}
      >
        <Plus size={16} />
        <span>New Project</span>
      </button>
    </div>
  );
};

export default NotFound;

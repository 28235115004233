import type { Dispatch, SetStateAction } from "react";
import { useEffect, useRef, useState } from "react";

export function useSetDeferred<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState(initialState);
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const setDeferredState: Dispatch<SetStateAction<S>> = (value) => {
    setTimeout(() => {
      if (!isMountedRef.current) return;
      setState(value);
    }, 0);
  };

  return [state, setDeferredState];
}

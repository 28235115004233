/** @jsxImportSource @emotion/react */

import { useAppSelector } from "store/storeTypes";
import {
  useInitiateActiveSession,
  usePollAssistantTasks,
  useResizeAssistantPanel,
  useSyncActiveSession,
} from "./hooks";
import WritingAssistantInput from "./writing-assistant-input";
import WritingAssistantConversation from "./writing-assistant-conversation";
import { useSyncFromLastMessage } from "./hooks";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useMemo } from "react";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import tw from "twin.macro";

const WritingAssistant = () => {
  const inputType = useAppSelector((store) => store.writingAssistant.activeSession?.input_field_type);
  const workflowEnded = useAppSelector((store) => store.writingAssistant.activeSession?.workflowEnded);
  useSyncFromLastMessage();
  useSyncActiveSession();
  useInitiateActiveSession();
  usePollAssistantTasks();
  const { bottomPanelRef, containerRef, minSize } = useResizeAssistantPanel();

  const isPanelDisabled = useMemo(
    () =>
      workflowEnded ||
      inputType === INPUT_FIELD_OPTIONS.checklistGroup ||
      inputType === INPUT_FIELD_OPTIONS.checklist ||
      inputType === INPUT_FIELD_OPTIONS.contentLibrary ||
      inputType === INPUT_FIELD_OPTIONS.allFiles ||
      inputType === INPUT_FIELD_OPTIONS.files,
    [inputType, workflowEnded],
  );

  return (
    <div ref={containerRef} className="h-[calc(100%-46px)] w-full flex flex-col border-t border-gray-light">
      <PanelGroup direction="vertical">
        <Panel className="flex flex-1">
          <WritingAssistantConversation />
        </Panel>
        <PanelResizeHandle
          disabled={isPanelDisabled}
          className="z-[3] w-full h-px bg-gray-300 relative flex justify-center delay-300 duration-150 hover:bg-gray-darkest hover:scale-y-[2.5]"
          css={[isPanelDisabled && tw`pointer-events-none`, workflowEnded && tw`opacity-0`]}
        />

        <Panel ref={bottomPanelRef} minSize={minSize} maxSize={70} css={[isPanelDisabled && tw`!flex-none`]}>
          {!workflowEnded && <WritingAssistantInput />}
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default WritingAssistant;

import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { moveFiles, moveDirectory, deleteDirectory, deleteFiles, createFile, updateFile } from "api/api";
import { updateAllDocs, updateAllFolders } from "store/reducers/driveReducerSlice";
import type { WorkspaceFile, Subdirectory } from "types/FileStorage";
import { useOperationsUtils } from "./useOperationsUtils";
import { pluralizeWord } from "utils/string";
import { formatBytes } from "utils/helpers";
import { AxiosHeaders } from "axios";
import { ACCEPTED_FORMATS, MAX_FILE_SIZE } from "../constants";

interface MoveModalState {
  items: string[] | null;
  open: boolean;
  type: "doc" | "folder" | null;
}

export interface StateHistory {
  type: "doc" | "folder" | null;
  data: Subdirectory[];
  itemInfo: Partial<WorkspaceFile> | undefined;
}

interface CreateDocument {
  parentDirectoryId?: string | null;
  tags?: string[];
}

type MimeType = keyof typeof ACCEPTED_FORMATS;

export const useFileOperations = (
  allDocs: WorkspaceFile[],
  allFolders: Subdirectory[],
  refreshResults: () => void,
  setStateHistory: (history: StateHistory) => void,
  setSelectedDocuments: (selectedDocs: string[]) => void,
  setAllDocs?: (docs: WorkspaceFile[]) => void,
) => {
  const dispatch = useDispatch();
  const [moveModalOpen, setMoveModalOpen] = useState<MoveModalState>({ items: null, open: false, type: null });
  const { handleApiError, handleApiSuccess, handleInvalidFileMsg } = useOperationsUtils();

  const isValidFile = useCallback(
    (file: File) => {
      const allowedExtensions = Object.values(ACCEPTED_FORMATS).flat();

      if (file.size > MAX_FILE_SIZE) {
        handleInvalidFileMsg(
          `Document exceeds the maximum size limit. We do not support document sizes larger than ${formatBytes(
            MAX_FILE_SIZE,
          )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`,
        );
        return false;
      }

      const fileExtension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
      const fileType = file.type as MimeType;

      if (!allowedExtensions.includes(fileExtension) || !ACCEPTED_FORMATS[fileType]?.includes(fileExtension)) {
        handleInvalidFileMsg("Document format is not supported.");
        return false;
      }

      return true;
    },
    [handleInvalidFileMsg],
  );

  const createDocument = useCallback(
    async (files: File[], { parentDirectoryId, tags }: CreateDocument = {}) => {
      if (!files.length) return;

      const headers = new AxiosHeaders();
      headers.set("Content-Type", "multipart/form-data");

      try {
        const uploadPromises = files.map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);

          // Handle parent directory ID
          if (parentDirectoryId === null) {
            formData.append("parent_directory", "");
          } else if (parentDirectoryId) {
            formData.append("parent_directory_id", parentDirectoryId);
          }

          // Handle tags
          if (tags?.length) {
            for (const tag of tags) {
              formData.append("tags", tag);
            }
          }

          const response = await createFile(formData, "upload", headers);
          return response.data;
        });

        const receivedData = await Promise.all(uploadPromises);

        // Only update root docs if no parent directory specified
        if (!parentDirectoryId) {
          dispatch(updateAllDocs([...allDocs, ...receivedData]));
        }

        refreshResults();
        handleApiSuccess("Files uploaded");
        return receivedData;
      } catch (err) {
        handleApiError(
          err,
          "We were unable to create document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        );
        return null;
      }
    },
    [allDocs, dispatch, refreshResults, handleApiSuccess, handleApiError],
  );

  const updateDocument = useCallback(
    async (documentId: string, data: Partial<WorkspaceFile>) => {
      try {
        const { data: updatedDoc } = await updateFile(documentId, data);

        const updatedDocs = allDocs.map((doc) => (doc.id === documentId ? updatedDoc : doc));
        dispatch(updateAllDocs(updatedDocs));
        setAllDocs?.(updatedDocs);

        handleApiSuccess("Document updated");
        return updatedDoc;
      } catch (err) {
        handleApiError(
          err,
          "We were unable to update document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        );
        return null;
      }
    },
    [allDocs, dispatch, handleApiSuccess, handleApiError],
  );

  const moveDocument = useCallback(
    async (
      dropTargetFolderId: string | undefined | null,
      selectedDocIds: string | string[],
      preventHistory?: "prevent",
    ) => {
      try {
        const docIds = Array.isArray(selectedDocIds) ? selectedDocIds : [selectedDocIds];
        await moveFiles(docIds, {
          parent_directory_id: dropTargetFolderId === "drive" ? null : dropTargetFolderId || null,
        });
        if (!preventHistory) {
          setStateHistory({
            type: "doc",
            data: allDocs,
            itemInfo: allDocs.find((doc) => docIds.includes(doc.id)),
          });
        }
        const updatedDocs = allDocs.filter((doc) => !docIds.includes(doc.id));
        dispatch(updateAllDocs(updatedDocs));
        refreshResults();
        setSelectedDocuments([]);
        handleApiSuccess(`${docIds.length} ${pluralizeWord(docIds.length, "Document")} moved`);
      } catch (err) {
        handleApiError(
          err,
          "We were unable to move the document(s) due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        );
      }
    },
    [allDocs, dispatch, refreshResults, setSelectedDocuments, handleApiSuccess, setStateHistory, handleApiError],
  );

  const moveFolder = useCallback(
    async (dropTargetFolderId: string, selectedFolderId: string) => {
      try {
        await moveDirectory(selectedFolderId, {
          parent_directory_id: dropTargetFolderId === "drive" ? null : dropTargetFolderId,
        });
        const updatedFolders = allFolders.filter((folder) => folder.id !== selectedFolderId);
        dispatch(updateAllFolders(updatedFolders));
        refreshResults();
        handleApiSuccess(`Folder moved`);
      } catch (err) {
        handleApiError(
          err,
          "We were unable to move the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        );
      }
    },
    [allFolders, dispatch, refreshResults, handleApiSuccess, handleApiError],
  );

  const deleteDocument = useCallback(
    async (documentIds: string | string[]) => {
      try {
        const ids = Array.isArray(documentIds) ? documentIds : [documentIds];
        await deleteFiles(ids);
        const updatedDocs = allDocs.filter((doc) => !ids.includes(doc.id));
        dispatch(updateAllDocs(updatedDocs));
        setSelectedDocuments([]);
        refreshResults();
        handleApiSuccess(`${ids.length} ${pluralizeWord(ids.length, "Document")} deleted`);
      } catch (err) {
        handleApiError(
          err,
          "We were unable to delete the document(s) due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        );
      }
    },
    [allDocs, dispatch, setSelectedDocuments, refreshResults, handleApiSuccess, handleApiError],
  );

  const deleteFolder = useCallback(
    async (folderId: string) => {
      try {
        await deleteDirectory(folderId);
        const updatedFolders = allFolders.filter((folder) => folder.id !== folderId);
        dispatch(updateAllFolders(updatedFolders));
        refreshResults();
        handleApiSuccess(`Folder deleted`);
      } catch (err) {
        handleApiError(
          err,
          "We were unable to delete the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        );
      }
    },
    [allFolders, dispatch, refreshResults, handleApiSuccess, handleApiError],
  );

  const handleMoveModalOpen = useCallback((items: string | string[], type: "doc" | "folder" | null) => {
    setMoveModalOpen({ items: Array.isArray(items) ? items : [items], open: true, type });
  }, []);

  const handleCloseMoveModal = useCallback(() => {
    setMoveModalOpen({ items: null, open: false, type: null });
  }, []);

  const onMoveManually = useCallback(
    async (selectedDestination: string) => {
      if (!moveModalOpen.items || moveModalOpen.items.length === 0) return;

      if (moveModalOpen.type === "folder") {
        await moveFolder(selectedDestination, moveModalOpen.items[0]);
      } else {
        await moveDocument(selectedDestination, moveModalOpen.items);
      }
      handleCloseMoveModal();
      refreshResults();
    },
    [moveDocument, moveFolder, moveModalOpen, handleCloseMoveModal, refreshResults],
  );

  return {
    createDocument,
    isValidFile,
    moveDocument,
    updateDocument,
    moveFolder,
    deleteDocument,
    deleteFolder,
    moveModalOpen,
    handleMoveModalOpen,
    handleCloseMoveModal,
    onMoveManually,
  };
};

/** @jsxImportSource @emotion/react */

import type { Interpolation, Theme } from "@emotion/react";
import type { HTMLAttributes, ReactNode } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  dropLabel?: ReactNode;
  styles?: Interpolation<Theme>;
}

const DropItemsContainer = ({ dropLabel = "Drop items", styles, ...props }: Props) => {
  const { onDragLeave } = props;

  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={(e) => {
        e.preventDefault();
        onDragLeave?.(e);
      }}
      className="z-[1] text-sm text-center font-medium flex justify-center items-center absolute inset-0 text-gray-darkest border-gray-darkest bg-zinc-300/40 backdrop-blur-[2px]"
      css={[styles]}
      {...props}
    >
      {dropLabel}
    </div>
  );
};

export default DropItemsContainer;

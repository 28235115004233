import type { Editor } from "@tiptap/react";
import { useState, useCallback, useEffect } from "react";

// ***** Color Utils *****

export const DEFAULT_TEXT_COLOR = "#000000";
export type MarkType = "textStyle" | "highlight";

/**
 * Gets the color of a text mark or highlight at the current selection
 * Handles both single cursor position and text selection cases
 * Returns:
 * - The color value if a single color is found
 * - "mixed" if multiple colors are found
 * - Default color or empty string for no color (depends on mark type)
 */
export const getMarkColor = (editor: Editor, markType: MarkType) => {
  const isHighlight = markType === "highlight";

  // If cursor is at a single position (no selection)
  if (editor.state.selection.empty) {
    const pos = editor.state.selection.from;
    const current = editor.state.doc.resolve(pos);
    const marks = current.marks();
    const mark = marks.find((mark) => {
      if (markType === "textStyle") {
        return mark.type.name === markType && mark.attrs.color;
      }
      return mark.type.name === markType;
    });
    return isHighlight ? mark?.attrs.color || "" : mark?.attrs.color || DEFAULT_TEXT_COLOR;
  }

  // If text is selected
  const foundColors = new Set<string>();
  let hasUnhighlightedText = false;

  editor.state.doc.nodesBetween(editor.state.selection.from, editor.state.selection.to, (node) => {
    if (markType === "textStyle") {
      // Check node's marks first
      const marks = node.marks || [];
      const colorMark = marks.find((mark) => mark.type.name === "textStyle" && mark.attrs.color);
      if (colorMark) {
        foundColors.add(colorMark.attrs.color);
      } else if (node.isText) {
        // If it's a text node with no color mark, it's using the default color
        foundColors.add(DEFAULT_TEXT_COLOR);
      }
    } else {
      if (node.isText) {
        const marks = node.marks || [];
        const highlightMark = marks.find((mark) => mark.type.name === markType);
        if (highlightMark) {
          foundColors.add(highlightMark.attrs.color);
        } else {
          hasUnhighlightedText = true;
        }
      }
    }
  });

  // For highlights, if we have both highlighted and unhighlighted text, it's mixed
  if (isHighlight && hasUnhighlightedText && foundColors.size > 0) {
    return "mixed";
  }

  // If no colors found in selection
  if (foundColors.size === 0) {
    return isHighlight ? "" : DEFAULT_TEXT_COLOR;
  }

  // Return "mixed" only if we have multiple different colors
  return foundColors.size > 1 ? "mixed" : foundColors.values().next().value;
};

/**
 * Hook for managing text color or highlight color state
 * Handles:
 * - Color picker open/close state
 * - Current color tracking
 * - Custom color input
 * - Color changes and updates
 */
export const useMarkColor = (editor: Editor, markType: MarkType) => {
  const isHighlight = markType === "highlight";
  const isTextStyle = markType === "textStyle";
  const [isOpen, setIsOpen] = useState(false);
  const [currentColor, setCurrentColor] = useState<string>(isHighlight ? "" : DEFAULT_TEXT_COLOR);
  const [customColor, setCustomColor] = useState<string>(DEFAULT_TEXT_COLOR);

  // Get current color based on selection
  const getColor = useCallback(() => {
    return getMarkColor(editor, markType);
  }, [editor, markType]);

  // Handle color changes and update editor state
  const onColorChange = useCallback(
    (value: string, shouldClose = true) => {
      if (!editor) return;

      if (value === "default") {
        if (isTextStyle) {
          editor.chain().focus().unsetColor().run();
        } else {
          editor.chain().focus().unsetHighlight().run();
        }
        setCurrentColor(isHighlight ? "" : DEFAULT_TEXT_COLOR);
      } else {
        if (isTextStyle) {
          editor.chain().focus().setColor(value).run();
        } else {
          editor.chain().focus().setHighlight({ color: value }).run();
        }
        setCurrentColor(value);
      }

      if (shouldClose) {
        setIsOpen(false);
      }
    },
    [editor, isHighlight, isTextStyle],
  );

  // Update color when selection changes
  useEffect(() => {
    if (!editor) return;

    const updateColor = () => {
      setCurrentColor(getColor());
    };

    updateColor();
    editor.on("selectionUpdate", updateColor);

    return () => {
      editor.off("selectionUpdate", updateColor);
    };
  }, [editor, getColor]);

  return {
    isOpen,
    setIsOpen,
    currentColor,
    customColor,
    setCustomColor,
    onColorChange,
  };
};

// ***** Toolbar Alignment Utils *****

export const getCurrentAlignment = (editor: Editor) => {
  if (editor.isActive({ textAlign: "center" })) return "center";
  if (editor.isActive({ textAlign: "right" })) return "right";
  if (editor.isActive({ textAlign: "justify" })) return "justify";
  return "left";
};

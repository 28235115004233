import Placeholder from "@tiptap/extension-placeholder";
import Paragraph from "@tiptap/extension-paragraph";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import HardBreak from "@tiptap/extension-hard-break";
import { mentionExtension } from "./MentionExtension";

interface WorkspaceMember {
  id: string;
  username: string;
  color: string;
}

export const getCommentEditorExtensions = (
  placeholder: string,
  workspaceMembers: WorkspaceMember[],
  setDisableScroll?: React.Dispatch<React.SetStateAction<boolean>>,
  isMentionActiveRef?: React.MutableRefObject<boolean>,
) => [
  Document,
  Text,
  Paragraph,
  Placeholder.configure({ placeholder, emptyEditorClass: "tiptap-empty" }),
  mentionExtension(workspaceMembers, setDisableScroll, isMentionActiveRef),
  HardBreak,
];

import type { FormattedVolume } from "types/Volume";
import { useEditorState } from "./hooks/useEditorState";

import { useBulkVolumesUpdate } from "hook/useExtractionOperation/bulkUpdate";
import { useCurrentVersion } from "./hooks/useCurrentVersion";
import type { SharedOutlineProps } from "./types";
import { InternalOutline } from "./InternalOutline";
import { MAX_LEVEL, MIN_LEVEL } from "./constants";
import { useCallback, useMemo } from "react";
import { flattenSections } from "./hooks/utils";
import useOutlineGenerationBannerState from "hook/doc-view/useOutlineGenerationBannerState";

export function Outline({
  className,
  drafts,
  extractionId,
  isReadOnly,
}: SharedOutlineProps & {
  drafts: FormattedVolume[];
  extractionId: string;
}) {
  const [outlineGeneratedBannerIgnoredState, setOutlineGeneratedBannerIgnoredState] =
    useOutlineGenerationBannerState(extractionId);
  const isOutlineGeneratedBannerIgnored = !!outlineGeneratedBannerIgnoredState?.[extractionId]?.ignored;

  const onChange = useBulkVolumesUpdate(extractionId);
  const version = useCurrentVersion(extractionId);
  const maxLevel = MAX_LEVEL;
  const flattenedDrafts = useMemo(() => flattenSections(drafts, MIN_LEVEL, maxLevel), [drafts, maxLevel]);
  const { editor, active } = useEditorState({
    content: flattenedDrafts,
    onChange,
    version,
    isReadOnly,
    maxLevel,
  });

  const onOutlineBannerUpdate = useCallback(() => {
    const updatedState = {
      ...outlineGeneratedBannerIgnoredState,
      [extractionId]: { ignored: true, timestamp: Date.now() },
    };
    setOutlineGeneratedBannerIgnoredState(updatedState);
  }, [extractionId, outlineGeneratedBannerIgnoredState, setOutlineGeneratedBannerIgnoredState]);
  const bannerConfig = useMemo(
    () => ({
      onOutlineBannerUpdate,
      isOutlineGeneratedBannerIgnored,
    }),
    [isOutlineGeneratedBannerIgnored, onOutlineBannerUpdate],
  );

  return <InternalOutline {...{ bannerConfig, editor, isReadOnly, maxLevel, className, active }} />;
}

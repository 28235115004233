import { useEffect, useState } from "react";
import type { Editor } from "@tiptap/react";
import { RedoIcon } from "../icons/Redo";
import { UndoIcon } from "../icons/Undo";
import { Button } from "../primitives/Button";
import styles from "./Toolbar.module.css";
import { ToolbarTooltip } from "../primitives/Tooltip";

export const ToolbarCommands = ({ editor }: { editor: Editor }) => {
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  useEffect(() => {
    if (!editor) return;

    const updateHistoryState = () => {
      setCanUndo(editor.can().undo());
      setCanRedo(editor.can().redo());
    };

    updateHistoryState();

    editor.on("transaction", updateHistoryState);

    return () => {
      editor.off("transaction", updateHistoryState);
    };
  }, [editor]);

  return (
    <>
      <ToolbarTooltip content="Undo">
        <Button
          className={styles.toolbarButton}
          variant="subtle"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!canUndo}
          aria-label="Undo"
        >
          <UndoIcon />
        </Button>
      </ToolbarTooltip>

      <ToolbarTooltip content="Redo">
        <Button
          className={styles.toolbarButton}
          variant="subtle"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!canRedo}
          aria-label="Redo"
        >
          <RedoIcon />
        </Button>
      </ToolbarTooltip>
    </>
  );
};

import type React from "react";
import classNames from "classnames";
import { DOCUMENTS } from "const-values/testIds";

type Props = {
  handleChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: boolean;
};

const SwitchBtn = ({ handleChange, value }: Props) => (
  <label className="relative inline-flex items-center cursor-pointer" data-testid={DOCUMENTS.contentLibrary.toggle}>
    <input type="checkbox" className="sr-only peer" onChange={handleChange} checked={value} />
    <div
      className={classNames(
        "w-9 h-5 bg-gray-200 peer-focus:outline-none",
        "rounded-full peer dark:bg-gray-500",
        "peer-checked:after:translate-x-full",
        "after:content-[''] after:absolute",
        "after:top-[2px]",
        "after:left-[2px]",
        "after:bg-white",
        "after:rounded-full",
        "after:h-4",
        "after:w-4 after:transition-all",
        "dark:border-gray-600 peer-checked:bg-action",
      )}
    ></div>
  </label>
);

export default SwitchBtn;

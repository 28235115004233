/** @jsxImportSource @emotion/react */

import type { ReactNode } from "react";
import { ArrowRight } from "lucide-react";
import tw from "twin.macro";

export interface Step<T> {
  label: ReactNode;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  step: number;
  value: T;
  disabled?: boolean;
}

interface StepperProps<T> {
  disabled?: boolean;
  steps: Step<T>[];
  activeStepItem?: Step<T>;
  onStepSelect: (step: Step<T>) => void;
}

const Stepper = <T extends any>({ steps, activeStepItem, onStepSelect, disabled }: StepperProps<T>) => {
  const { step: activeStep } = activeStepItem || {};

  return (
    <div className="flex flex-row gap-3 whitespace-nowrap" css={[disabled && tw`pointer-events-none`]}>
      {steps.map(({ step, label, icon: Icon, disabled }, i) => (
        <div key={step} className="flex flex-col gap-3" css={[steps.length - 1 === i && tw`flex-none`]}>
          <div className="flex flex-row items-center gap-2">
            <button
              disabled={disabled}
              className="relative size-6 text-gray-darkest rounded-full flex items-center duration-150 transition-colors justify-center bg-gray-100"
              css={[activeStep === step && tw`text-white bg-action`]}
              onClick={() => onStepSelect(steps[i])}
            >
              <Icon className="size-4" />
            </button>
            <button
              disabled={disabled}
              className="flex flex-row items-center gap-2"
              onClick={() => onStepSelect(steps[i])}
            >
              <div
                className="text-slate-500 text-xs font-medium duration-150 transition-colors"
                css={[activeStep === step && tw`text-gray-darkest`]}
              >
                {label}
              </div>
              {i !== steps.length - 1 && <ArrowRight size={14} className="text-gray-darkest" />}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;

import { useCallback, useRef, useMemo } from "react";
import { useAppSelector } from "store/storeTypes";
import { FileSelection } from "./input-actions";
import Dropzone from "../file-upload/Dropzone";
import { useFileUploadHandler } from "./hooks";
import SubmitButton from "../SubmitButton";
import { mapUploadedItemsWithTasks } from "utils/assistants/utils";
import type { InstructionExtraState } from "../hooks";
import { pluralizeWord } from "utils/string";

type Props = {
  instructionExtraState: InstructionExtraState;
};

const FileUploadOrSelectionInput = ({ instructionExtraState }: Props) => {
  const {
    maxFileCounts,
    excludedComponents: { internet: shouldHideInternet },
  } = instructionExtraState || {};
  const defaultSubmitParams = useMemo(
    () => (shouldHideInternet ? { use_internet: false } : undefined),
    [shouldHideInternet],
  );
  const containerNode = useRef<HTMLDivElement | null>(null);
  const activeProject = useAppSelector((root) => root.project.activeProject);
  const {
    streamState,
    selectedContentLibraryFiles,
    selectedProjectFiles,
    activeSession,
    uploadedDocuments,
    uploadChatDocumentTasks,
  } = useAppSelector((root) => root.writingAssistant);
  const { isStreamingInProgress } = streamState;
  const totalSelectedAndUploadedFiles = useMemo(
    () => [
      ...selectedContentLibraryFiles,
      ...selectedProjectFiles,
      ...(mapUploadedItemsWithTasks(uploadedDocuments || [], uploadChatDocumentTasks || [], activeSession?.id) || []),
    ],
    [activeSession?.id, selectedContentLibraryFiles, selectedProjectFiles, uploadChatDocumentTasks, uploadedDocuments],
  );
  const totalSelectedAndUploadedFilesLength = totalSelectedAndUploadedFiles.length;
  const { handleFileUpload } = useFileUploadHandler();
  const label =
    totalSelectedAndUploadedFilesLength > 0 ? `Files Selected: ${totalSelectedAndUploadedFilesLength}` : "Select Files";
  const maxUploadCountExceeded = !!maxFileCounts.total && totalSelectedAndUploadedFilesLength > maxFileCounts.total;
  const uploadFileDifference = totalSelectedAndUploadedFilesLength - (maxFileCounts.total || 0);
  const tooltipMessage = maxUploadCountExceeded
    ? `You can only attach ${maxFileCounts.total} ${pluralizeWord(maxFileCounts.total || 0, "file")}. Please remove the extra ${uploadFileDifference} ${pluralizeWord(uploadFileDifference, "file")}.`
    : undefined;
  const memoizedOnDrop = useCallback(
    (files: File[]) => handleFileUpload(files, maxFileCounts.upload || maxFileCounts.total || undefined),
    [handleFileUpload, maxFileCounts.total, maxFileCounts.upload],
  );

  const submitDisabled = useMemo(
    () =>
      maxUploadCountExceeded ||
      isStreamingInProgress ||
      !activeProject?.internal_contract.id ||
      !totalSelectedAndUploadedFiles.length ||
      totalSelectedAndUploadedFiles.some((item) => "is_processing" in item && item.is_processing),
    [activeProject?.internal_contract.id, isStreamingInProgress, totalSelectedAndUploadedFiles, maxUploadCountExceeded],
  );

  return (
    <>
      <div ref={containerNode} className="relative flex">
        <Dropzone onDrop={memoizedOnDrop} fileInstructionState={instructionExtraState.maxFileCounts}>
          <div className="flex flex-row justify-between items-center gap-1 w-full py-2">
            <FileSelection containerNode={containerNode.current} instructionExtraState={instructionExtraState} />
            <SubmitButton
              disabled={submitDisabled}
              tooltipMessage={tooltipMessage}
              defaultParams={defaultSubmitParams}
            />
          </div>
        </Dropzone>
      </div>
      <div className="bg-gray-100 border-t text-sm border-gray-300 pr-3 pl-2 py-2">{label}</div>
    </>
  );
};

export default FileUploadOrSelectionInput;

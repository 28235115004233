/** @jsxImportSource @emotion/react */
import { memo, useEffect, useMemo, useState } from "react";
import "./styles.css";
import AIAssistantConversation from "./AIAssistantConversation";
import WorkflowButtons from "./WorkflowButtons";
import { renderInputComponent } from "./utils";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useFlags } from "hook/useFlags";
import { useFileUploadHandler } from "./ai-assistant-input/AddAttachmentsButton/hooks";
import {
  extractExcludeArrayFromVultronBlock,
  extractInstruction,
  extractTypeFromLastVultronBlock,
} from "utils/assistants/utils";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import { updateActiveSession } from "store/reducers/ai-assistant/aiAssistantReducer";
import { useGlobalWorkflowActions } from "pages/Workflows/useGlobalWorkflowActions";
import type { InputFieldType } from "types/Assistants/types";
import { DOC_STATUS_OPTIONS } from "components/molecules/assistant-uploaded-files/constants";
import AIAssistantHeader from "./ai-assistant-header/AIAssistantHeader";
import { useSyncFromLastMessage } from "./hooks";

const AIAssistantCore = memo(() => {
  const [extractedInputType, setExtractedInputType] = useState<InputFieldType | null>(null);
  const [previousInputType, setPreviousInputType] = useState<InputFieldType | null>(null);
  const dispatch = useAppDispatch();
  const activeSession = useAppSelector((root) => root.aiAssistantState.activeSession);
  const flags = useFlags();
  const { continueWorkflow } = useGlobalWorkflowActions();
  const { handleFileUpload } = useFileUploadHandler();
  const uploadChatDocumentTasks = useAppSelector((root) => root.aiAssistantState.uploadChatDocumentTasks);
  const [continuedReferenceId, setContinuedReferenceId] = useState<string | null>(null);
  useSyncFromLastMessage();
  const inputType = useMemo(() => {
    const conversation = (activeSession?.conversation || []).map((block) => ({
      ...block,
      sources: block.sources || [],
    }));
    const extractedInputTypeFromVultronBlock = extractTypeFromLastVultronBlock(conversation);
    setExtractedInputType(extractedInputTypeFromVultronBlock);
    dispatch(updateActiveSession({ input_field_type: extractedInputTypeFromVultronBlock }));
    return extractedInputTypeFromVultronBlock === INPUT_FIELD_OPTIONS.pendingWorkflow && previousInputType
      ? previousInputType
      : extractedInputTypeFromVultronBlock;
  }, [activeSession?.conversation, dispatch, previousInputType]);

  useEffect(() => {
    if (extractedInputType !== INPUT_FIELD_OPTIONS.pendingWorkflow) {
      setPreviousInputType(extractedInputType);
    }
  }, [activeSession, dispatch, extractedInputType, inputType, previousInputType]);

  useEffect(() => {
    if (activeSession?.input_field_type === INPUT_FIELD_OPTIONS.pendingWorkflow) {
      const referenceId = extractInstruction(
        activeSession?.conversation?.[activeSession.conversation.length - 1]?.body,
        activeSession,
      );

      const isTaskMissing =
        !uploadChatDocumentTasks ||
        !uploadChatDocumentTasks.some(
          (task) => task.chat_session_id === activeSession.id && task.status !== DOC_STATUS_OPTIONS.failed,
        );

      if (activeSession?.id && referenceId && isTaskMissing && continuedReferenceId !== referenceId) {
        continueWorkflow({
          referenceId: activeSession.id,
          chatSessionType: "global",
        });

        if (typeof referenceId === "string") setContinuedReferenceId(referenceId);
      }
    }
  }, [continueWorkflow, activeSession, uploadChatDocumentTasks, continuedReferenceId]);

  const globalAssistantWorkflows = flags.globalAssistantWorkflows;
  useEffect(() => {
    if (inputType !== INPUT_FIELD_OPTIONS.chat || !activeSession?.conversation) return;

    const excludedFields = extractExcludeArrayFromVultronBlock(
      activeSession?.conversation?.[activeSession.conversation.length - 1],
    );
    if (excludedFields) dispatch(updateActiveSession({ excluded_fields: excludedFields }));
  }, [activeSession?.conversation, dispatch, inputType]);

  return (
    <div className="bg-white relative z-0 flex h-full w-full overflow-hidden">
      <div className="relative flex flex-col flex-1">
        {activeSession && <AIAssistantHeader />}
        <div className="flex flex-col justify-center relative h-full overflow-hidden">
          <AIAssistantConversation />
          {!activeSession?.workflowEnded &&
            renderInputComponent({
              inputType: inputType!,
              globalAssistantWorkflows: !!globalAssistantWorkflows,
              onFileUpload: (files: File[]) => handleFileUpload(files),
            })}
          <WorkflowButtons />
        </div>
      </div>
    </div>
  );
});

export default AIAssistantCore;

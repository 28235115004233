import type { WorkspaceMember } from "types/User";
import {
  type Dispatch,
  type FocusEvent,
  type FormEvent,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { useTrackUserMetric } from "utils/metrics";
import { updateProposalName } from "api/api";
import { CheckMarkGreen, MenuVerticalBars } from "utils/icons";
import { Spinner } from "utils/icons";
import Avatar from "components/Avatar";
import { downloadProposal } from "api/api";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Download, File, Pencil, Trash2 } from "lucide-react";
import tw from "twin.macro";
import { dateFormatter } from "utils/timerUtils";
import type { ProposalDocument } from "types/Proposal";
import { setProposals } from "store/reducers/projectReducer";
import { useAppDispatch } from "store/storeTypes";
import * as logger from "utils/log";

type ModalProps = { open: boolean; id: string | null };

type Data = {
  creator: string;
  created_at: string;
  created_by: string;
  id: string;
  idempotency_key: null;
  internal_contract: string;
  last_synced_at: string;
  name: string;
  updated_at: string;
};
type Props = {
  getUser: (userId: string) => WorkspaceMember | undefined;
  mainData: ProposalDocument[];
  data: Data;
  isAlreadyDeleting: (id?: string | null) => boolean;
  setDeleteModal: Dispatch<SetStateAction<ModalProps>>;
  setConfirmDeleteText: Dispatch<SetStateAction<string>>;
  setForceRefresh: (value: SetStateAction<boolean>) => void;
  setDocId: (docId: string | undefined) => void;
  trackUserEvent: ReturnType<typeof useTrackUserMetric>;
};

export const TableRow = ({
  getUser,
  mainData,
  data,
  isAlreadyDeleting,
  setDeleteModal,
  setConfirmDeleteText,
  setForceRefresh,
  setDocId,
  trackUserEvent,
}: Props) => {
  const [inputValue, setInputValue] = useState(data?.name || "");
  const [editing, setEditing] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const dispatch = useAppDispatch();
  const saveName = (e: FormEvent<HTMLFormElement> | FocusEvent<HTMLInputElement>) => {
    if (e) e.preventDefault();
    updateProposalName({ proposal_name: inputValue }, data?.id)
      .then(() => {
        setForceRefresh(true);
      })
      .catch((err) => {
        logger.error(err, "error while updating the proposal name: ");
      });
    setTimeout(() => {
      const prev = [...mainData];
      const index = mainData?.findIndex((v) => v?.id === data?.id);
      if (index >= 0) {
        prev[index] = {
          ...prev[index],
          name: inputValue,
          updated_at: new Date().toISOString(),
        };
        dispatch(setProposals(prev));
      }
      setEditing(false);
    }, 750);
  };

  useEffect(() => {
    setInputValue(data?.name || "");
  }, [data]);

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2">
            <Pencil size={14} /> Edit
          </div>
        ),
        onSelect: () => {
          setEditing(true);
        },
      },
      {
        key: 2,
        label: (
          <div className="flex items-center gap-2">
            {downloading ? <Spinner width={14} height={14} /> : <Download size={14} />} Download
          </div>
        ),
        disabled: downloading,
        onSelect: () => {
          setDownloading(true);
          trackUserEvent("User Downloaded Proposal");
          downloadProposal(data?.id, "yjs")
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              const filename = `${
                data?.name?.length === 0 ? `proposal_${new Date().toISOString().split("T")[0]}` : data?.name
              }.docx`;
              link.href = url;
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => logger.error(error))
            .finally(() => setDownloading(false));
        },
      },
      {
        key: 3,
        label: (
          <div className="flex items-center gap-2 text-red-500">
            {isAlreadyDeleting(data?.id) ? <Spinner width={14} height={14} /> : <Trash2 size={14} />}Delete
          </div>
        ),
        disabled: !!isAlreadyDeleting(data?.id),
        onSelect: () => {
          setDeleteModal({ open: true, id: data.id });
          setConfirmDeleteText("");
        },
      },
    ],
    [data.id, data?.name, downloading, isAlreadyDeleting, setConfirmDeleteText, setDeleteModal, trackUserEvent],
  );

  return (
    <tr className="hover:bg-[#f5f5f5] border-b text-sm cursor-pointer">
      <td
        className="px-4 pl-7 py-5 w-[58%] min-w-[350px]"
        onClick={() => {
          if (!editing) setDocId(data?.id);
        }}
      >
        <div className="flex items-center gap-2.5 group">
          <File className="text-gray-darkest h-5 w-4 shrink-0" />{" "}
          {!editing ? (
            <div className="py-2">{data?.name || "-"}</div>
          ) : (
            <form onSubmit={saveName}>
              <input
                type="text"
                className="font-medium text-sm outline-none rounded-md ring-[1px] ring-dark w-full overflow-hidden break-word whitespace-pre-line text-[#1E1E1E] px-2 py-2 editable placeholder:text-gray-400"
                autoFocus
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={saveName}
              />
            </form>
          )}
        </div>
      </td>
      <td
        className="px-4 py-5 w-[26%]"
        onClick={() => {
          if (!editing) setDocId(data?.id);
        }}
      >
        {dateFormatter(data?.created_at || new Date(), "MMMM DD, YYYY")}
      </td>
      <td
        className="px-4 py-5 w-[35%] items-center"
        onClick={() => {
          if (!editing) setDocId(data?.id);
        }}
      >
        <div className="flex items-center">
          {!getUser(data?.created_by)?.username && !getUser(data?.created_by)?.email ? null : (
            <Avatar
              src=""
              alt={getUser(data?.created_by)?.username || getUser(data?.created_by)?.email || "-"}
              id={data?.created_by}
              width={24}
              height={24}
              className={"text-[15px]"}
            />
          )}
          <p className="pl-2 my-auto">{getUser(data?.created_by)?.username || "-"}</p>
        </div>
      </td>
      <td className="pr-4 min-w-[40px] max-w-[40px] cursor-default">
        {editing ? (
          <div className="py-1 flex items-center justify-end">
            <CheckMarkGreen />
          </div>
        ) : (
          <DropdownMenu triggerProps={{ css: tw`w-full flex items-center justify-end` }} modal items={menuItems}>
            <div className="bg-transparent border-0 cursor-pointer duration-100 rounded-md py-1 px-0.5 hover:bg-gray-200">
              <MenuVerticalBars />
            </div>
          </DropdownMenu>
        )}
      </td>
    </tr>
  );
};

import type { Editor } from "@tiptap/react";
import { BubbleMenu } from "@tiptap/react";
import { Pencil, Trash2, ExternalLink } from "lucide-react";
import { useState } from "react";
import { EditorLinkModal } from "components/editor/components/EditorLinkModal";
import * as logger from "utils/log";

export const LinkBubbleMenu = ({ editor }: { editor: Editor }) => {
  const [showLinkModal, setShowLinkModal] = useState(false);

  const handleLinkSubmit = (url: string) => {
    if (!url) {
      editor.chain().focus().unsetLink().run();
      return;
    }

    try {
      const { href } = new URL(url.startsWith("http") ? url : `https://${url}`);
      editor.chain().focus().extendMarkRange("a").setLink({ href }).run();
    } catch (error) {
      logger.error(error as Error, "Invalid URL");
    }
  };

  const href = editor.getAttributes("a").href;

  return (
    <>
      <BubbleMenu
        className="flex items-center gap-1 p-2 rounded-lg bg-white shadow-lg border border-gray-200"
        shouldShow={({ editor }) => editor.isActive("a")}
        editor={editor}
      >
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="px-3 py-1 text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1"
        >
          <span className="max-w-[200px] truncate" title={href}>
            {href}
          </span>
          <ExternalLink size={14} />
        </a>
        <div className="w-[1px] h-5 bg-gray-200 mx-1" />
        <button onClick={() => setShowLinkModal(true)} className="p-1 hover:bg-gray-100 rounded" title="Edit link">
          <Pencil size={14} />
        </button>
        <button
          onClick={() => editor.chain().focus().unsetLink().run()}
          className="p-1 hover:bg-gray-100 rounded"
          title="Remove link"
        >
          <Trash2 size={14} />
        </button>
      </BubbleMenu>

      <EditorLinkModal
        showLinkModal={showLinkModal}
        setShowLinkModal={setShowLinkModal}
        initialUrl={href}
        onSubmit={handleLinkSubmit}
        isEditing={true}
      />
    </>
  );
};

import RequirementsListHeader from "./RequirementsListHeader";
import RequirementList from "./RequirementList";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { FormattedSection } from "types/Volume";
import type { Extraction } from "components/copilot/CopilotSchemaTypes";
import { memo } from "react";

type Props = {
  section: FormattedSection;
  volumeId: string;
  extractionId: string;
  isReadOnly: boolean;
  groupedComplianceMatrix: Record<string, ToImmutable<Extraction["compliance_matrix"]>>;
};

const RequirementSectionListContainer = ({
  section,
  volumeId,
  extractionId,
  isReadOnly,
  groupedComplianceMatrix,
}: Props) => {
  const { id: sectionId, title, parent_id } = section;

  return (
    <div className="flex flex-col relative group" id={`template-manager-section-${sectionId}`}>
      <RequirementsListHeader
        sectionId={sectionId}
        volumeId={volumeId}
        extractionId={extractionId}
        title={title}
        parentId={parent_id}
        isReadOnly={isReadOnly}
      />
      <RequirementList requirements={groupedComplianceMatrix[sectionId] || []} sectionId={sectionId} />
    </div>
  );
};

export default memo(RequirementSectionListContainer);

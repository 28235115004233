import type { Editor } from "@tiptap/react";
import { EditorContent } from "@tiptap/react";
import { CommentDraft } from "components/Comments/components/CommentDraft";
import { useCommentsSync } from "components/Comments/components/useCommentsSync";
import { useActiveThread } from "components/Comments/components/useThread";
import { ThreadContext } from "components/Comments/types";
import { useEffect } from "react";
import { toggleComments } from "store/reducers/copilot/copilotDrawerReducer";
import { setActiveCommentEditor } from "store/reducers/copilot/copilotReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import Loader from "utils/Spinner";

interface RequirementEditorProps {
  editor: Editor | null;
  requirementId: string;
  threadIds?: string[];
  sectionId: string;
  referenceId?: string | null;
  internalContractId: string;
}
export const RequirementEditor = ({
  editor,
  internalContractId,
  referenceId,
  requirementId,
  sectionId,
}: RequirementEditorProps) => {
  const dispatch = useAppDispatch();
  const activeThread = useActiveThread(editor);
  const commentsOpen = useAppSelector((state) => state.copilotDrawer.commentsOpen);
  const expandedRequirementIds = useAppSelector((state) => state.sectionState.requirementsState.expandedRequirementIds);
  const { isLoading } = useCommentsSync(editor, internalContractId, referenceId);

  useEffect(() => {
    // manages comment editor state
    if (!editor) return;
    const editorId = `${sectionId}$${requirementId}`;

    dispatch(setActiveCommentEditor({ editor, editorId }));

    return () => {
      if (!expandedRequirementIds.length) dispatch(setActiveCommentEditor({ editor: undefined, editorId: undefined }));
    };
  }, [editor]);

  useEffect(() => {
    // manages panel visibility based on active thread changes
    if (activeThread && !commentsOpen) {
      dispatch(toggleComments(true));
    }
  }, [activeThread]);

  if (!editor) return null;

  return (
    <div className="flex-1 min-w-0 p-4 selected-ai relative h-full">
      {isLoading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader type={undefined} />
        </div>
      ) : (
        <EditorContent editor={editor} className="w-full break-words" />
      )}
      <CommentDraft
        editor={editor}
        internalContractId={(internalContractId || "") as string}
        referenceId={referenceId as string}
        context={ThreadContext.DRAFT}
        requirementId={requirementId}
      />
    </div>
  );
};

import { ArrowLeft } from "lucide-react";
import { Skeleton } from "../skeleton";
import type { HTMLAttributes, PropsWithChildren, ReactNode } from "react";
import type { To } from "react-router-dom";
import { Link } from "react-router-dom";
interface Props extends HTMLAttributes<HTMLDivElement> {
  header: ReactNode;
  headerActions?: ReactNode;
  actions: ReactNode;
  back: To | -1;
  isLoading: boolean;
}

const ProjectHeader = ({ headerActions, actions, back, header, isLoading, ...props }: PropsWithChildren<Props>) => {
  return (
    <div className="flex border-y border-gray-300 items-center min-h-[40px] bg-white justify-between px-3" {...props}>
      <div className="flex items-center gap-0.5">
        {headerActions}
        <Link to={back as To} className="ml-2 text-[20px]">
          <div className="w-[70px]">
            <ArrowLeft size={20} />
          </div>
        </Link>
      </div>
      {isLoading ? (
        <Skeleton width={600} height={20} />
      ) : (
        <h2 className="flex-1 min-w-0 font-medium text-[13px] text-[#1D2630] px-4">{header}</h2>
      )}
      {actions}
    </div>
  );
};

export default ProjectHeader;

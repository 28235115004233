import { useAppSelector } from "store/storeTypes";
import { useStorage } from "YJSProvider/createYJSContext";

const useGetActiveUserSession = () => {
  const activeSessionId = useAppSelector((store) => store.writingAssistant.activeSession?.id);
  const currentUserId = useAppSelector((store) => store.auth.currentUser?.id);

  const userSession = useStorage((root) => {
    if (!currentUserId) return;
    const sessions = root.writing_assistant?.[currentUserId]?.sessions;

    if (!sessions || !activeSessionId) return;

    const session = sessions.find((s) => s.id === activeSessionId);
    return session;
  });

  return userSession;
};

export default useGetActiveUserSession;

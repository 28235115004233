import { DEFAULT_SESSION_NAME } from "components/molecules/assistant-sidebar-row/constants";
import ProjectWorkflowSelectionPopover, {
  ProjectWorkflowSelectionPopoverRow,
} from "components/organisms/project-workflow-selection-popover";
import { AlignLeft, ChevronDown, LucideX, PenBoxIcon } from "lucide-react";
import { useCallback, type Dispatch, type SetStateAction } from "react";
import { toggleActions } from "store/reducers/copilot/copilotDrawerReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { WritingAssistantSession } from "../CopilotSchemaTypes";
import { setActiveSession } from "store/reducers/writing-assistant/writingAssistantReducer";
import useWritingAssistantOperations from "hook/writing-assistant/useWritingAssistantOperations";
import Tooltip from "components/atoms/tooltip";

const ProjectAssistantHeader = ({
  setSessionsDrawerOpen,
}: {
  setSessionsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const activeSessionName = useAppSelector((root) => root.writingAssistant.activeSession?.name);
  const { createSession } = useWritingAssistantOperations();

  const startNewSession = useCallback(() => {
    const newSession = createSession({ name: DEFAULT_SESSION_NAME });
    const immutableSession = newSession?.toJSON() as ToImmutable<WritingAssistantSession>;
    dispatch(setActiveSession(immutableSession));
  }, [createSession, dispatch]);

  return (
    <div className="flex w-full items-center justify-between gap-2 px-3 min-h-[46px] h-[46px] bg-white">
      <button
        aria-label="Sessions"
        onClick={() => setSessionsDrawerOpen(true)}
        className="p-1 rounded duration-100 hover:bg-gray-100"
      >
        <AlignLeft size={18} />
      </button>

      {activeSessionName ? (
        <div className="flex flex-row gap-2 items-center max-w-[60%]">
          <ProjectWorkflowSelectionPopover
            contentProps={{ align: "start", style: { margin: 0 } }}
            topSection={
              <div>
                <ProjectWorkflowSelectionPopoverRow aria-label="New Session" onClick={startNewSession}>
                  <PenBoxIcon size={16} /> Start a new session
                </ProjectWorkflowSelectionPopoverRow>
              </div>
            }
          >
            <button className="flex-1 select-none min-w-0 text-gray-darkest flex flex-row gap-1 items-center text-sm rounded-md p-1 cursor-pointer hover:bg-gray-100">
              <span className="truncate">{activeSessionName}</span> <ChevronDown size={14} className="shrink-0" />
            </button>
          </ProjectWorkflowSelectionPopover>
          <Tooltip content="New Session">
            <button
              aria-label="New Session"
              onClick={startNewSession}
              className="text-gray-400 p-1 rounded duration-100 hover:bg-gray-100 hover:text-gray-800"
            >
              <PenBoxIcon size={16} />
            </button>
          </Tooltip>
        </div>
      ) : (
        <div className="font-bold text-base">Assistant</div>
      )}
      <button
        aria-label="Close Assistant"
        onClick={() => dispatch(toggleActions(false))}
        className="text-gray-darkest h-[26px] w-[26px] flex justify-center items-center duration-100 hover:text-gray-500"
      >
        <LucideX size={18} />
      </button>
    </div>
  );
};

export default ProjectAssistantHeader;

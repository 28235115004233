/** @jsxImportSource @emotion/react */
import { useMemo } from "react";
import { LayoutGrid, Sparkles, SquarePen } from "lucide-react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { motion } from "framer-motion";
import { useStorage } from "utils/yjs-configs/ai-assistant/yjs.config";
import useAIAssistantOperations from "hook/useAIAssistantOperations";
import { useFlags } from "hook/useFlags";
import { NavLink, useNavigate } from "react-router-dom";
import { setAiAssistantState } from "store/reducers/ai-assistant/aiAssistantReducer";
import AssistantSidebarRow from "components/molecules/assistant-sidebar-row";
import { groupYjsSessionsByCategory } from "utils/assistants/utils";
import type { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import AssistantArchiveMenu from "components/molecules/assistant-archive-menu";
import { useReadySessions } from "./hooks";

type Props = {
  isInitial: boolean;
  sidebarOpen: boolean;
};

const AIAssistantSidebar = ({ isInitial, sidebarOpen }: Props) => {
  const dispatch = useAppDispatch();
  const { archiveSession, updateSession, createSession, deleteSession, unarchiveSession, deleteArchivedSession } =
    useAIAssistantOperations();
  const activeSession = useAppSelector((store) => store.aiAssistantState.activeSession);
  const flags = useFlags();
  const archivedSessions = useStorage((root) => root.ai_assistant?.archived_sessions || []);
  const sessions = useStorage((root) => root.ai_assistant?.sessions || []);
  const sessionEditable = useAppSelector((store) => store.aiAssistantState.sessionEditable);
  const firstMsgBody = useAppSelector((store) => store.aiAssistantState.activeSession?.conversation?.[0]?.body);
  const navigate = useNavigate();

  const normalSessions = useMemo(() => {
    return [...sessions].sort((a, b) => {
      if (a.updated_at && b.updated_at) {
        return -a.updated_at.localeCompare(b.updated_at);
      }
      return 0;
    });
  }, [sessions]);

  const { readySessions, setReady } = useReadySessions();

  const readySessionsList = useMemo(
    () => normalSessions.filter((session) => readySessions.has(session.id)),
    [normalSessions, readySessions],
  );

  const filteredSessions = useMemo(
    () => normalSessions.filter((session) => !readySessions.has(session.id)),
    [normalSessions, readySessions],
  );

  const groupedSessions = useMemo(
    () => groupYjsSessionsByCategory<AIAssistantSession>(filteredSessions),
    [filteredSessions],
  );
  const entries = useMemo(() => Array.from(groupedSessions.entries()), [groupedSessions]);

  if (!sidebarOpen) return null;

  return (
    <div
      data-state={isInitial ? undefined : sidebarOpen ? "open" : "closed"}
      className="flex-shrink-0 whitespace-nowrap border-r border-gray-light sidebarCollapsibleContent overflow-x-hidden"
    >
      <div className="h-full flex flex-col">
        <div className="flex-1 relative overflow-hidden">
          <div className="absolute -bottom-[1px] right-0 left-0 h-8 z-[1] from-layout-gray-light to-transparent" />
          <div className="h-full flex flex-col gap-6 px-1 overflow-y-auto">
            <div className="sticky left-0 right-0 top-0 z-20 pt-3.5 px-2 bg-white">
              <button
                onClick={() => createSession("New Session")}
                className="flex text-sm font-medium shadow border border-gray w-full justify-between flex-row items-center rounded-md py-3 pl-2.5 pr-4 duration-150 bg-white hover:brightness-95"
              >
                New Session <SquarePen size={14} />
              </button>
              {flags.globalAssistantWorkflows && (
                <div className="flex flex-col gap-2 justify-around text-sm pt-4">
                  <NavLink
                    replace
                    to="/dashboard/ai-assistant"
                    onClick={() => dispatch(setAiAssistantState({ activeSession: undefined }))}
                    end
                    className={({ isActive }) =>
                      `flex items-center gap-1.5 rounded-md px-2.5 py-2 ${
                        isActive ? "bg-layout-gray-light-hover" : "hover:bg-layout-gray-light-hover"
                      }`
                    }
                  >
                    <Sparkles size={16} /> Home
                  </NavLink>
                  <NavLink
                    replace
                    to="/dashboard/ai-assistant/workflows"
                    className={({ isActive }) =>
                      `flex items-center gap-1.5 rounded-md px-2.5 py-2 ${
                        isActive ? "bg-layout-gray-light-hover" : "hover:bg-layout-gray-light-hover"
                      }`
                    }
                  >
                    <LayoutGrid size={16} /> Explore Workflows
                  </NavLink>
                  <hr className="border-t border-gray-light w-full mt-4" />
                </div>
              )}
            </div>
            <div className="flex flex-col text-sm pb-4 gap-10">
              {readySessionsList.length > 0 && (
                <div className="flex flex-col gap-1.5">
                  <motion.div
                    key="ready-header"
                    className="text-xs pl-[18px] text-slate-500 font-medium"
                    layout
                    layoutId="ready-header"
                    animate="visible"
                  >
                    Ready
                  </motion.div>
                  <div className="px-2">
                    {readySessionsList.map((session) => (
                      <AssistantSidebarRow
                        key={`ready-${session.id}`}
                        session={session}
                        isActive={activeSession?.id === session.id}
                        onDelete={() => {
                          deleteSession(session.id);
                          if (activeSession?.id === session.id) navigate("/dashboard/ai-assistant");
                        }}
                        updateSession={updateSession}
                        archiveSession={archiveSession}
                        sessionEditable={sessionEditable}
                        firstMsgBody={firstMsgBody}
                        onEditTriggered={() => dispatch(setAiAssistantState({ sessionEditable: session.id }))}
                        onEditComplete={() => dispatch(setAiAssistantState({ sessionEditable: "" }))}
                        to={`/dashboard/ai-assistant/${session.id}`}
                        isReady={readySessions.has(session.id)}
                        onReadyChange={setReady}
                        onRowSelected={() => {
                          setReady(session.id, false);
                          dispatch(setAiAssistantState({ activeSession: session }));
                          navigate(`/dashboard/ai-assistant/${session.id}`);
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
              {entries.length === 0 && (
                <div className="flex flex-col gap-1.5 px-[18px]">
                  <div className="text-xs text-slate-500 font-medium">Today</div>
                  <div className="text-sm text-slate-400">New Session</div>
                </div>
              )}
              {entries.map(([dateRangeKey, sessions]) => (
                <div key={dateRangeKey} className="flex flex-col gap-1.5">
                  <motion.div
                    key={dateRangeKey}
                    className="text-xs pl-[18px] text-slate-500 font-medium"
                    layout
                    layoutId={dateRangeKey}
                    animate="visible"
                  >
                    {dateRangeKey}
                  </motion.div>
                  <div className="px-2">
                    {sessions.map((session) => {
                      const isActive = activeSession?.id === session.id;
                      return (
                        <AssistantSidebarRow
                          key={session.id}
                          session={session}
                          isActive={isActive}
                          onDelete={() => {
                            deleteSession(session.id);
                            if (isActive) navigate("/dashboard/ai-assistant");
                          }}
                          updateSession={updateSession}
                          archiveSession={archiveSession}
                          sessionEditable={sessionEditable}
                          firstMsgBody={firstMsgBody}
                          onEditTriggered={() => dispatch(setAiAssistantState({ sessionEditable: session.id }))}
                          onEditComplete={() => dispatch(setAiAssistantState({ sessionEditable: "" }))}
                          to={`/dashboard/ai-assistant/${session.id}`}
                          isReady={readySessions.has(session.id)}
                          onReadyChange={setReady}
                          onRowSelected={() => {
                            dispatch(setAiAssistantState({ activeSession: session }));
                            navigate(`/dashboard/ai-assistant/${session.id}`);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <AssistantArchiveMenu
          archivedSessions={archivedSessions}
          onUnarchiveSession={unarchiveSession}
          onDeleteArchivedSession={deleteArchivedSession}
        />
      </div>
    </div>
  );
};

export default AIAssistantSidebar;

import type { Sheet } from "./../../../components/copilot/CopilotSchemaImmutableTypes";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type State = {
  checkedState: Record<string, boolean>;
  activeRequirementId: string | null;
  // written_content: string;
  activeSheet: Sheet | null;
};

const initialState: State = {
  checkedState: {},
  activeRequirementId: null,
  // written_content: "",
  activeSheet: null,
};

export const requirementsReducer = createSlice({
  name: "requirements",
  initialState,
  reducers: {
    resetRequirementsState: () => {
      return initialState;
    },
    setActiveSheetState: (state: State, action: PayloadAction<State["activeSheet"]>) => {
      state.activeSheet = action.payload;
    },
    setCheckedState: (state: State, action: PayloadAction<State["checkedState"]>) => {
      state.checkedState = action.payload;
    },
    updateCheckedState: (state: State, action: PayloadAction<State["checkedState"]>) => {
      state.checkedState = { ...state.checkedState, ...action.payload };
    },
    setActiveRequirementId: (state: State, action: PayloadAction<State["activeRequirementId"]>) => {
      state.activeRequirementId = action.payload;
    },
    // setWrittenContent: (state: State, action: PayloadAction<State["written_content"]>) => {
    //     state.written_content = action.payload;
    // }
  },
});

export const {
  setCheckedState,
  updateCheckedState,
  resetRequirementsState,
  setActiveSheetState,
  setActiveRequirementId,
  // setWrittenContent,
} = requirementsReducer.actions;

export default requirementsReducer.reducer;

/** @jsxImportSource @emotion/react */

import { Skeleton } from "components/molecules/skeleton";
import VultronLogo from "Assets/svgs/vultron-logo-no-title.svg";
import tw, { theme } from "twin.macro";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { Fragment, memo, useCallback, useEffect } from "react";
import { useSelection } from "../../../document-display/SelectionContext";
import { removeTemplateVolume, setFailedToExtract } from "store/reducers/extract/LiveTemplateExtractionReducer";
import TemplateSectionRow from "components/molecules/template-section-row";
import { LucideCheck, LucideX } from "lucide-react";
import type { TemplateVolume } from "types/TemplateSection";
import { transformTemplateVolumesToYJS } from "utils/Framework/transformTemplateVolumesToYJS";
import useExtractionOperations from "hook/useExtractionOperations";
import { useParams } from "react-router-dom";
import { useScrollToLoader } from "./hooks";

const LiveTemplateExtractionTemplate = () => {
  const { extractionId } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((store) => store.liveTemplateExtractionState.isLoadingLiveTemplateExtraction);
  const failedToExtract = useAppSelector((store) => store.liveTemplateExtractionState.failedToExtract);
  const volumes = useAppSelector((store) => store.liveTemplateExtractionState.templateData);
  const { selectedBlocks } = useSelection();
  const { appendExtractionDrafts } = useExtractionOperations();
  useScrollToLoader(isLoading);
  const shouldShowNode = failedToExtract || !!volumes.length || isLoading;

  useEffect(() => {
    if (!selectedBlocks?.length && failedToExtract) dispatch(setFailedToExtract(false));
  }, [dispatch, failedToExtract, selectedBlocks?.length]);

  const onAcceptOrRejectVolume = useCallback(
    (volume: TemplateVolume, operation: "accept" | "reject") => {
      if (!extractionId) return;
      dispatch(removeTemplateVolume(volume.id));

      if (operation === "accept") {
        const transformedLiveVolumes = transformTemplateVolumesToYJS([volume]);
        appendExtractionDrafts(extractionId, transformedLiveVolumes);
      }
    },
    [appendExtractionDrafts, dispatch, extractionId],
  );

  if (!shouldShowNode) return null;

  return (
    <div className="relative w-full">
      <div className="pointer-events-auto relative bg-slate-100 w-full h-full">
        {!!volumes.length && (
          <div className="flex flex-col w-full">
            {volumes.map((volume, i) => (
              <div
                key={volume.id}
                className="bg-slate-100 p-5 w-full"
                css={[(i !== volumes.length - 1 || isLoading || failedToExtract) && tw`border-b border-slate-300`]}
              >
                <div className="flex flex-row justify-between gap-2 items-center">
                  <label className="text-xs font-medium text-slate-500">Extracted</label>
                  <div className="flex flex-row gap-1 items-center">
                    <button
                      onClick={() => onAcceptOrRejectVolume(volume, "accept")}
                      className="text-green-500 rounded p-0.5 hover:bg-slate-200"
                    >
                      <LucideCheck size={16} />
                    </button>
                    <button
                      onClick={() => onAcceptOrRejectVolume(volume, "reject")}
                      className="text-red-500 rounded p-0.5 hover:bg-slate-200"
                    >
                      <LucideX size={16} />
                    </button>
                  </div>
                </div>
                <div className="text-sm flex flex-col gap-1 font-semibold py-2 min-w-0 w-full relative">
                  <div className="flex flex-row items-center" css={[!volume.title.trim() && tw`text-gray-400`]}>
                    <span>{volume.title || "Volume title..."}</span>
                  </div>
                  {!!volume.sections.length && (
                    <div className="flex flex-col pl-4">
                      {volume.sections.map((section) => (
                        <Fragment key={section.id}>
                          <TemplateSectionRow section={section} />
                          {section.subsections?.map((subsection) => (
                            <TemplateSectionRow key={subsection.id} section={subsection} />
                          ))}
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {!isLoading && failedToExtract && (
          <div className="flex flex-col gap-2 bg-slate-100 p-5">
            <label className="text-xs font-medium text-slate-500">Extracted</label>
            <div className="flex items-center justify-start text-sm text-slate-500">
              Template could not be extracted. Consider trying again with a new selection.
            </div>
          </div>
        )}

        {isLoading && (
          <div className="flex flex-col gap-4 bg-slate-100 p-5">
            <div className="flex flex-col gap-2">
              <Skeleton borderRadius={4} height={20} width="90%" baseColor={theme`colors.slate.200`} />
              <Skeleton borderRadius={4} height={20} width="60%" baseColor={theme`colors.slate.200`} />
              <Skeleton borderRadius={4} height={20} width="80%" baseColor={theme`colors.slate.200`} />
            </div>
            <div className="flex items-center gap-1.5 text-slate-500">
              <img src={VultronLogo} alt="Vultron logo" className="h-4 w-4 opacity-70" />
              <span className="text-xs">
                Extracting outline
                <span className="loading-ellipsis" />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(LiveTemplateExtractionTemplate);

import type { Editor } from "@tiptap/react";
import { HighlightIcon } from "../icons";
import { ColorPicker } from "./ColorPicker";

export const ToolbarHighlight = ({ editor, variant }: { editor: Editor; variant?: "default" | "bubble" }) => (
  <ColorPicker
    editor={editor}
    icon={<HighlightIcon variant={variant} />}
    markType="highlight"
    defaultLabel="Remove highlight"
    variant={variant}
  />
);

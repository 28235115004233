import type { ComponentProps } from "react";
import { memo } from "react";
import MultiDatePicker from "react-multi-date-picker";

type Props = ComponentProps<typeof MultiDatePicker>;

const DatePicker = ({ className, ...props }: Props) => {
  return (
    <MultiDatePicker
      arrow={false}
      shadow={false}
      offsetY={4}
      className={`shadow border border-zinc-200 ${className}`}
      {...props}
    />
  );
};

export default memo(DatePicker);

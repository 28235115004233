import type { Comment, CommentThread } from "../../components/Comments/types";
import { useMutation } from "@tanstack/react-query";
import * as logger from "utils/log";
import type {
  CreateCommentMutationVariables,
  DeleteCommentMutationVariables,
  DeleteThreadMutationVariables,
  EditorCommentMutationVariables,
  ResolveThreadMutationVariables,
  RestoreThreadMutationVariables,
  UpdateReadMutationVariables,
  UpdateReactionMutationVariables,
  UpdateThreadAssigneeMutationVariables,
} from "./types";
import {
  createComment,
  deleteComment,
  deleteThread,
  editComment,
  resolveThread,
  restoreThread,
  threadQueryKey,
  updateRead,
  addReaction,
  removeReaction,
  updateThreadAssignee,
} from "./comments";
import { clearDraft } from "components/Comments/CommentsDraftPlugin";
import { queryClient } from "api/queryClient";
import { useAppSelector } from "store/storeTypes";

export const useCommentOperations = (internalContractId: string, referenceId?: string) => {
  const { currentUser } = useAppSelector((store) => store.auth);
  const createCommentMutation = useMutation({
    mutationFn: (params: CreateCommentMutationVariables) =>
      createComment({ ...params, internalContractId, reference_id: referenceId }),
    onMutate: async ({
      content,
      thread_id: threadId,
      new_thread_id: newThreadId,
      new_comment_id: newCommentId,
      editor,
      quote_text,
      assignee_id,
      assignee_username,
      mentions,
    }) => {
      const queryKey = threadQueryKey(internalContractId, referenceId);
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Update threads cache to include the new thread
      queryClient.setQueryData<CommentThread[]>(queryKey, (old) => {
        const oldThreads = old || [];
        const newComment: Comment = {
          id: newCommentId,
          content,
          commenter: {
            id: currentUser?.id || "",
            username: currentUser?.username || "",
            email: currentUser?.email || "",
            is_vultron: false,
          },
          created_at: new Date(),
          updated_at: new Date(),
          reactions: {},
          mentions: mentions || [],
        };

        if (threadId) {
          return oldThreads.map((thread) => {
            if (thread.id === threadId) {
              return {
                ...thread,
                comments: [...thread.comments, newComment],
                mentions: [...(thread.mentions || []), ...(mentions || [])],
              };
            }
            return thread;
          });
        } else {
          if (!referenceId) throw new Error("Reference ID is required to create a new thread");
          return [
            ...oldThreads,
            {
              id: newThreadId || "",
              internal_contract_id: internalContractId || "",
              quote_text: quote_text || "",
              commenter_id: currentUser?.id || "",
              reference_id: referenceId,
              resolved: false,
              deleted: false,
              is_read: true,
              comments: [newComment],
              updated_at: new Date(),
              created_at: new Date(),
              assignee: assignee_id
                ? {
                    id: assignee_id,
                    username: assignee_username || "",
                    email: "",
                  }
                : null,
              mentions: mentions || [],
            },
          ];
        }
      });

      if (!editor) return;
      // After thread is created, create comment mark
      editor.chain().focus().setCommentMark({ id: newThreadId }).run();
      clearDraft(editor);
    },
    onError: (error, { new_thread_id: newThreadId, editor }) => {
      logger.error(error, "Could not create comment");
      if (!newThreadId || !editor) return;
      // Remove comment mark
      editor.chain().removeCommentMark(newThreadId).run();

      queryClient.invalidateQueries({ queryKey: threadQueryKey(internalContractId, referenceId) });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: threadQueryKey(internalContractId, referenceId) });
    },
  });

  const editCommentMutation = useMutation({
    mutationFn: (params: EditorCommentMutationVariables) => editComment({ ...params, internalContractId }),
    onMutate: async ({ content, comment_id: commentId }) => {
      const queryKey = threadQueryKey(internalContractId, referenceId);
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Update threads cache to include the new thread
      queryClient.setQueryData<CommentThread[]>(queryKey, (old) => {
        if (!old) return [];
        return old.map((thread) => {
          return {
            ...thread,
            comments: thread.comments.map((comment) => {
              if (comment.id === commentId) {
                return {
                  ...comment,
                  content,
                };
              }
              return comment;
            }),
          };
        });
      });
    },
    onError: (error) => {
      logger.error(error, "Could not edit comment");
    },
  });

  const resolveThreadMutation = useMutation({
    mutationFn: (params: ResolveThreadMutationVariables) => resolveThread({ ...params, internalContractId }),
    onMutate: async ({ thread_id: threadId, resolved, editor }) => {
      const queryKey = threadQueryKey(internalContractId, referenceId);
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Update threads cache to include the new thread
      queryClient.setQueryData<CommentThread[]>(queryKey, (old) => {
        if (!old) return [];
        return old.map((thread) => {
          if (thread.id === threadId) {
            return {
              ...thread,
              resolved,
            };
          }
          return thread;
        });
      });

      editor?.commands.resolveCommentMark(threadId, resolved);
    },
    onError: (error, { thread_id: threadId, resolved, editor }) => {
      logger.error(error, "Could not resolve thread");
      editor?.commands.resolveCommentMark(threadId, !resolved);
    },
  });

  const updateReactionMutation = useMutation({
    mutationFn: async ({ comment_id, emoji, remove }: UpdateReactionMutationVariables) => {
      if (remove) {
        return await removeReaction({ comment_id, emoji, internalContractId });
      } else {
        return await addReaction({ comment_id, emoji, internalContractId });
      }
    },
    onMutate: async ({ comment_id, emoji, remove }: UpdateReactionMutationVariables) => {
      const queryKey = threadQueryKey(internalContractId, referenceId);
      await queryClient.cancelQueries({ queryKey });

      queryClient.setQueryData<CommentThread[]>(queryKey, (old) => {
        if (!old) return [];
        return old.map((thread) => ({
          ...thread,
          comments: thread.comments.map((comment) => {
            if (comment.id === comment_id) {
              const updatedReactions = { ...comment.reactions };
              const userId = currentUser?.id || "";

              if (remove) {
                if (updatedReactions[userId] && updatedReactions[userId].includes(emoji)) {
                  updatedReactions[userId] = updatedReactions[userId].filter((reaction) => reaction !== emoji);
                  if (updatedReactions[userId].length === 0) {
                    delete updatedReactions[userId];
                  }
                }
              } else {
                if (!updatedReactions[userId]) {
                  updatedReactions[userId] = [];
                }
                if (!updatedReactions[userId].includes(emoji)) {
                  updatedReactions[userId].push(emoji);
                }
              }

              return { ...comment, reactions: updatedReactions };
            }
            return comment;
          }),
        }));
      });
    },
    onError: (error) => {
      logger.error(error, "Could not update reaction");
    },
  });

  const deleteCommentMutation = useMutation({
    mutationFn: (params: DeleteCommentMutationVariables) => deleteComment({ ...params, internalContractId }),
    onMutate: async ({ comment_id: commentId }) => {
      const queryKey = threadQueryKey(internalContractId, referenceId);
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Update threads cache to include the new thread
      queryClient.setQueryData<CommentThread[]>(queryKey, (old) => {
        if (!old) return [];
        return old.map((thread) => {
          return {
            ...thread,
            comments: thread.comments.filter((comment) => comment.id !== commentId),
          };
        });
      });
    },
    onError: (error) => {
      logger.error(error, "Could not delete comment");
    },
  });

  const deleteThreadMutation = useMutation({
    mutationFn: async (params: DeleteThreadMutationVariables) => deleteThread({ ...params, internalContractId }),
    onMutate: async ({ thread_id, editor }) => {
      const queryKey = threadQueryKey(internalContractId, referenceId);
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Update threads cache to include the new thread
      queryClient.setQueryData<CommentThread[]>(queryKey, (old) => {
        if (!old) return [];
        return old.filter((thread) => thread.id !== thread_id);
      });
      editor?.chain().removeCommentMark(thread_id).setActiveComment(undefined).run();
    },
    onError: (error, { thread_id, editor }) => {
      logger.error(error, "Could not delete thread");
      editor?.chain().setCommentMark({ id: thread_id, resolved: false }).run();
    },
  });

  const restoreThreadMutation = useMutation({
    mutationFn: (params: RestoreThreadMutationVariables) => restoreThread({ ...params, internalContractId }),
    onMutate: async () => {
      const queryKey = threadQueryKey(internalContractId, referenceId);
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey,
      });
    },
    onError: (error) => {
      logger.error(error, "Could not restore thread");
    },
    onSettled: () => {
      // Always refetch after error or success:
      const queryKey = threadQueryKey(internalContractId, referenceId);
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const updateReadMutation = useMutation({
    mutationFn: (params: UpdateReadMutationVariables) => updateRead({ ...params, internalContractId }),
    onMutate: async ({ thread_id: threadId, read }) => {
      const queryKey = threadQueryKey(internalContractId, referenceId);
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Update threads cache to include the new thread
      queryClient.setQueryData<CommentThread[]>(queryKey, (old) => {
        if (!old) return [];
        return old.map((thread) => {
          if (thread.id === threadId) {
            return {
              ...thread,
              is_read: read,
            };
          }
          return thread;
        });
      });
    },
    onError: (error) => {
      logger.error(error, "Could not update read status");
    },
  });

  const updateThreadAssigneeMutation = useMutation({
    mutationFn: (params: UpdateThreadAssigneeMutationVariables) =>
      updateThreadAssignee({
        ...params,
        internalContractId,
      }),
    onMutate: async ({ thread_id, assignee_id, assignee_username }) => {
      const queryKey = threadQueryKey(internalContractId, referenceId);
      await queryClient.cancelQueries({ queryKey });

      queryClient.setQueryData<CommentThread[]>(queryKey, (old) => {
        if (!old) return [];
        return old.map((thread) => {
          if (thread.id === thread_id) {
            return {
              ...thread,
              assignee: assignee_id
                ? {
                    id: assignee_id,
                    username: assignee_username || "",
                    email: "",
                  }
                : null,
            };
          }
          return thread;
        });
      });
    },
    onError: (error) => {
      logger.error(error, "Could not update thread assignee");
    },
  });

  return {
    createCommentMutation,
    editCommentMutation,
    resolveThreadMutation,
    deleteCommentMutation,
    deleteThreadMutation,
    restoreThreadMutation,
    updateReadMutation,
    updateReactionMutation,
    updateThreadAssigneeMutation,
  };
};

/** @jsxImportSource @emotion/react */

import { Button } from "components/editor/components";
import type { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";
import { enqueue } from "store/reducers/requirementsSmartResponseReducer";
import { CircleCheck } from "lucide-react";
import { ResponseTolerance } from "types/Requirement";
import type { Storage } from "components/copilot/CopilotSchemaTypes";
import { STRICTNESS_TO_META } from "const-values/Draft";
import { toggleStrictnessModal } from "store/reducers/modalsSlice";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import { useFlags } from "hook/useFlags";
import { useMutation } from "YJSProvider/createYJSContext";
import { useParams } from "react-router-dom";
import { filter, update } from "YJSProvider/LiveObjects";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useMemo, useState } from "react";

import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip";

type Props = {
  sectionRequirements: ComplianceMatrixRow[];
};

const ManagerCard = ({ sectionRequirements }: Props) => {
  const dispatch = useAppDispatch();
  const { volumeId, sectionId } = useParams();
  const { updateSectionManager } = useFrameworkOperations();
  const [strictness, setStrictness] = useState<ResponseTolerance>(ResponseTolerance.Strict);
  const StrictnessIcon = STRICTNESS_TO_META[strictness].icon;
  const currentUser = useAppSelector((root) => root.auth?.currentUser);
  const { generateResponseQueue: responseQueue } = useAppSelector((root) => root.requirementsSmartResponse);
  const groupedAtlasRequirements = useAppSelector((root) => root.project.groupedAtlasRequirements);
  const isSectionRequirementsGenerating = useMemo(
    () => sectionRequirements.some((req) => req.is_response_generating || req.is_response_in_queue),
    [sectionRequirements],
  );
  const flags = useFlags();

  const queueableRows = useMemo(
    () =>
      sectionRequirements?.filter(
        (row) =>
          !row.locked &&
          !row.is_response_in_queue &&
          (row.requirement.content || row.requirement.summarized_content)?.trim() &&
          !row.is_response_generating &&
          !row.written_content &&
          !responseQueue?.find((queueRow) => queueRow.requirement?.id === row?.requirement?.id),
      ),
    [responseQueue, sectionRequirements],
  );

  const initAutoRespondQueue = useMutation(
    ({ storage }) => {
      if (!queueableRows?.length || !currentUser?.id) return;
      const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;
      if (matrix) {
        const liveRows = filter(matrix, (liveRow) =>
          queueableRows.some((row) => row.requirement.id === liveRow.get("requirement").get("id")),
        );
        liveRows.forEach((row) => {
          update(row, { is_response_in_queue: true, auto_response_actor: currentUser?.id });
        });
      }
      dispatch(
        enqueue(
          queueableRows.map((row) => ({
            ...row,
            ...{ is_response_in_queue: true, auto_response_actor: currentUser?.id },
            response_tolerance: strictness,
            hasAtlasResponse: !!groupedAtlasRequirements?.[row.requirement.id]?.response,
            decompose_requirement: flags.redTeam,
            red_team: flags.redTeam,
            speed_optimized: flags.redTeam,
          })),
        ),
      );
    },
    [
      sectionRequirements,
      currentUser?.id,
      dispatch,
      responseQueue,
      strictness,
      queueableRows,
      groupedAtlasRequirements,
      flags.redTeam,
    ],
  );

  const isAllRespondedTo = useMemo(
    () => sectionRequirements.every((row) => !!row.written_content?.trim()),
    [sectionRequirements],
  );

  return (
    <div className="rounded-md bg-gray-light border border-slate-400 shadow p-3">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <div className="text-sm font-medium">Instant Pink Team Draft</div>
          <div className="text-xs text-slate-500">Generate initial draft responses to all requirements.</div>
        </div>

        <div className="flex items-center justify-between gap-1">
          <div className="flex items-center gap-1">
            <Button
              variant="outline"
              size="sm"
              className="!border gap-1.5 !px-2 shadow-sharp-thin !border-solid !border-action !bg-action-light !text-action hover:opacity-70"
              onClick={() =>
                dispatch(
                  toggleStrictnessModal({
                    open: true,
                    initialProps: {
                      strictness,
                      onStrictnessSubmit: (val) => setStrictness(val),
                    },
                  }),
                )
              }
            >
              {<StrictnessIcon className="w-3.5 h-3.5" />}
              {STRICTNESS_TO_META[strictness].label}
            </Button>
            <Tooltip
              disableHoverableContent
              content={!sectionRequirements.length ? "Add requirements" : "Generate responses."}
            >
              <Button
                variant="primary"
                size="sm"
                disabled={!sectionRequirements.length || isAllRespondedTo}
                className="!pl-3 gap-1.5 shadow-sharp-thin"
                onClick={initAutoRespondQueue}
              >
                <Icon
                  name="Generate"
                  css={[
                    isSectionRequirementsGenerating && {
                      animation: "rotateAnimation 0.5s infinite linear",
                    },
                    { strokeWidth: 1.3 },
                  ]}
                />
                <div>
                  {isSectionRequirementsGenerating ? (
                    <>
                      Generating
                      <span className="loading-ellipsis" />
                    </>
                  ) : (
                    "Generate"
                  )}
                </div>
              </Button>
            </Tooltip>
          </div>
          <div className="flex items-center gap-1">
            <Button
              onClick={() => {
                if (volumeId && sectionId) {
                  updateSectionManager(volumeId, sectionId, {
                    isIgnored: true,
                  });
                }
              }}
              variant="ghost"
              size="sm"
            >
              Skip
            </Button>
            <Button
              onClick={() => {
                if (volumeId && sectionId)
                  updateSectionManager(volumeId, sectionId, {
                    isIgnored: true,
                  });
              }}
              variant="primary"
              size="sm"
              className="!bg-slate-200 !px-3 gap-1.5 !text-slate-700 hover:brightness-95 shadow-sharp-thin"
              style={{ "--pulse-bg": "rgba(42, 71, 171, 0.7)" }}
            >
              <CircleCheck size={16} />
              I'm Done
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerCard;

/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useCallback } from "react";
import { useAppDispatch } from "store/storeTypes";
import { toggleCapabilitySearchModal } from "store/reducers/modalsSlice";
import type { SourceFile } from "types/Assistants/types";
import Popover from "components/atoms/popover";
import tw from "twin.macro";
import InlineSourceContentPreview from "./InlineSourceContentPreview";

interface InlineSourceFileButtonProps {
  file: SourceFile;
  index: number;
}

const InlineSourceFileButton: React.FC<InlineSourceFileButtonProps> = ({ file, index }) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const closeTimeoutRef = useRef<number | null>(null);

  const handlePointerEnter = useCallback(() => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = null;
    }
    setOpen(true);
  }, []);

  const handlePointerLeave = useCallback(() => {
    closeTimeoutRef.current = window.setTimeout(() => {
      setOpen(false);
      closeTimeoutRef.current = null;
    }, 100);
  }, []);

  const handleOpenModal = useCallback(() => {
    dispatch(
      toggleCapabilitySearchModal({
        open: true,
        initialProps: {
          sourceChunkIds: file.chunk_ids,
          sourceId: file.source_id,
          sourceName: file.source_name,
          sourceType: file.type,
        },
      }),
    );
  }, [dispatch, file]);

  return (
    <Popover
      open={open}
      content={
        <div onPointerEnter={handlePointerEnter} onPointerLeave={handlePointerLeave}>
          <InlineSourceContentPreview
            sourceName={file.source_name}
            sourcePreview={file.preview_content || ""}
            onClick={handleOpenModal}
          />
        </div>
      }
      contentProps={{
        css: [tw`border-0 shadow-sm focus:outline-none`],
      }}
    >
      <button
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
        onClick={handleOpenModal}
        className="flex items-center justify-center text-xxs w-4 h-4 rounded-md bg-gray-200 text-gray-700 duration-100 outline-none"
        css={[open && tw`bg-neutral-900 text-white`]}
      >
        {index + 1}
      </button>
    </Popover>
  );
};

export default InlineSourceFileButton;

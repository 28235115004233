interface TypingAnimationProps {
  blockHasError: boolean;
}

const TypingAnimation: React.FC<TypingAnimationProps> = ({ blockHasError }) => {
  const errorBlockHeight = 6;
  const baseHeight = 24;

  return (
    <div className="relative" style={{ height: baseHeight + (blockHasError ? errorBlockHeight : 0) }}>
      <div
        style={{
          position: "absolute",
          top: 6,
          left: 0,
          padding: 0,
        }}
      >
        Typing...
      </div>
    </div>
  );
};

export default TypingAnimation;

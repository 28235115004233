import { useMemo } from "react";
import tw from "twin.macro";

import DatePicker from "components/molecules/date-picker";
import { dateFormatter } from "utils/timerUtils";

export const DateComp = ({
  internalContractId,
  dateString,
  onDateSelect,
}: {
  internalContractId: string;
  dateString: string;
  onDateSelect: (internalContractId: string, value: { response_date: string }) => void;
}) => {
  const date = useMemo(() => new Date(dateString), [dateString]);

  return (
    <DatePicker
      calendarPosition="top-end"
      value={date}
      onChange={(date) => {
        const dateValue = date?.valueOf() as number;
        onDateSelect(internalContractId, {
          response_date: new Date(dateValue)?.toISOString(),
        });
      }}
      render={(_, openCalendar) => (
        <button
          onClick={openCalendar}
          className="text-sm p-1 rounded-md duration-100 hover:bg-gray-200"
          css={[!dateString && tw`w-[82px]`]}
        >
          {dateFormatter(dateString, "MM/DD/YYYY")}
        </button>
      )}
    />
  );
};

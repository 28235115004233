/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useMemo, useState } from "react";
import NotFound from "./NotFound";
import lod from "../../Assets/loader.svg";
import { useSelector, useDispatch } from "react-redux";
import { updateInternalContractDetails } from "api/api";
import { getContractManagerData } from "store/reducers/contractManagerReducerSlice";
import { useSortable } from "hook/useSortable";
import { SortingActiveBottomIcon, SortingActiveIcon, SortingActiveTopIcon } from "utils/icons";
import { getExactDate } from "utils/date";
import { publishedDateOptions, deadlineOptions } from "./constants";
import DeleteConfirmModal from "./DeleteConfirmModal";
import Popover from "components/atoms/popover/Popover";
import { Checkbox } from "components/atoms/checkbox";
import { AvatarGroup } from "components/molecules/avatar-group";
import { Avatar } from "components/molecules/avatar";
import { getProject } from "store/reducers/projectReducer";
import { ContractRow } from "./ContractRow";
import { applySelectedFilters } from "./utils";
import { dateFormatter } from "utils/timerUtils";

// ----------------- Contract table component --------------------
const ContractTable = ({ selectedFilters }) => {
  const dispatch = useDispatch();
  const { data: contracts, isLoadingContractManagerData } = useSelector((store) => store.contracts);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [selectedProject, setSelectedProject] = useState();

  // sorted data
  const { items, sortConfig, handleSorting } = useSortable(
    contracts?.map((v) => ({
      id: v?.id,
      starred: v?.starred,
      status: v?.status,
      assigned_user_ids: v?.assigned_user_ids || [],
      projectName: v?.project_name || "-",
      ...v?.contract,
      publishDate: v?.start_date?.length > 0 ? v?.start_date : new Date().toISOString(),
      responseDate: v?.response_date,
      unreadCount: v?.unread_count,
    })),
  );

  const queryPublishDate = useMemo(() => {
    let end = "";
    const start = getExactDate(selectedFilters?.publishedDate, publishedDateOptions, "past");
    if (start?.length > 0) {
      end = dateFormatter(new Date(), "MM/DD/YYYY");
    }
    return { start, end };
  }, [selectedFilters?.publishedDate]);
  const queryDeadlineDate = useMemo(() => {
    let start = "";
    const end = getExactDate(selectedFilters?.response_deadline, deadlineOptions);
    if (end?.length > 0) {
      start = dateFormatter(new Date(), "MM/DD/YYYY");
    }
    return { start, end };
  }, [selectedFilters?.response_deadline]);

  const filterData = useMemo(
    () => applySelectedFilters(items, selectedFilters, queryDeadlineDate, queryPublishDate),
    [items, selectedFilters, queryDeadlineDate, queryPublishDate],
  );

  // handle sort click event on table headers
  const handleSortEvent = (key, by_date) => {
    if (sortConfig?.key === key) {
      if (sortConfig?.direction === "ascending") {
        handleSorting(key, "descending", by_date);
      } else {
        handleSorting("", "", false);
      }
    } else {
      handleSorting(key, "ascending", by_date);
    }
  };

  // render sort icon
  const renderSortIcon = (key, by_date) => {
    if (sortConfig?.key !== key) {
      return (
        <SortingActiveIcon
          onclick1={() => handleSorting(key, "ascending", by_date)}
          onclick2={() => handleSorting(key, "descending", by_date)}
        />
      );
    }
    switch (sortConfig?.direction) {
      case "ascending":
        return (
          <SortingActiveTopIcon
            onclick1={() => handleSorting(key, "ascending", by_date)}
            onclick2={() => handleSorting(key, "descending", by_date)}
          />
        );
      case "descending":
        return (
          <SortingActiveBottomIcon
            onclick1={() => handleSorting(key, "ascending", by_date)}
            onclick2={() => handleSorting(key, "descending", by_date)}
          />
        );
      default:
        return (
          <SortingActiveIcon
            onclick1={() => handleSorting(key, "ascending", by_date)}
            onclick2={() => handleSorting(key, "descending", by_date)}
          />
        );
    }
  };

  const onDateSelect = useCallback(
    async (internalContractId, data) => {
      try {
        await updateInternalContractDetails(internalContractId, data);
        dispatch(getProject({ internalContractId }));
        dispatch(getContractManagerData());
      } catch {
        // Silently ignore error
      }
    },
    [dispatch],
  );

  return (
    <div className="max-w-full flex-1 overflow-auto bg-white scrollbar-0">
      <table className="w- overflow-auto">
        <thead className="border-b text-sm sticky top-0 left-0 bg-gray-50 rounded-tl-lg rounded-tr-lg z-[1]">
          <tr className="text-left text-gray-500">
            <th className="px-2 py-3 min-w-[35px] min-[1550px]:min-w-[45px] min-[1550px]:w-[54px]"></th>
            <th className="pr-4 w-[95%] pl-2 py-3 text-sm">
              <p className="flex w-max max-w-full items-center select-none cursor-pointer hover:bg-gray-200 rounded-md p-1.5">
                <span onClick={() => handleSortEvent("title")} className="block pr-1">
                  Title
                </span>
                <span className="p-[2px] block">{renderSortIcon("title")}</span>
              </p>
            </th>
            <th className="min-[1550px]:min-w-[170px] min-[1550px]:max-w-[170px] min-[1550px]:w-[170px] min-w-[142px] w-[162px] max-w-[162px] py-3 text-sm">
              <p className="flex w-max max-w-full items-center select-none cursor-pointer hover:bg-gray-200 rounded-md p-1.5">
                <span onClick={() => handleSortEvent("responseDate", true)} className="block pr-1">
                  Deadline
                </span>
                <span className="p-[2px] block">{renderSortIcon("responseDate", true)}</span>
              </p>
            </th>
            <th className="px-4 py-3 text-sm w-[170px] min-[1550px]:min-w-[190px] min-[1550px]:w-[210px]">
              <p className="flex w-max max-w-full items-center select-none py-1">
                <span className="block pr-1">Assignees</span>
              </p>
            </th>
            <th className="px-4 py-3 text-sm min-w-[150px] max-w-[150px]">
              <p className="flex w-max max-w-full items-center select-none py-1">
                <span className="block pr-1">Status</span>
              </p>
            </th>
            <th className="px-4 py-3 text-sm min-w-[50px] max-w-[150px]">
              <p className="flex w-max max-w-full items-center select-none py-1">
                <span className="block pr-1"></span>
              </p>
            </th>
          </tr>
          <tr>
            <th className="h-[1px] bg-gray-200" colSpan="8"></th>
          </tr>
        </thead>
        <tbody>
          {isLoadingContractManagerData && contracts?.length === 0 ? (
            <tr>
              <td colSpan="8">
                <div className="min-h-[64vh] bg-white w-full flex justify-center items-center flex-col py-7 px-2">
                  <img className="mx-auto max-w-full" src={lod} alt="" />
                </div>
              </td>
            </tr>
          ) : contracts?.length === 0 || filterData?.length === 0 ? (
            <tr>
              <td colSpan="8">
                <NotFound />
              </td>
            </tr>
          ) : (
            filterData?.map((data) => (
              <ContractRow
                contracts={contracts}
                data={data}
                key={data.id}
                onDateSelect={onDateSelect}
                setDeleteModalActive={setDeleteModalActive}
                setSelectedProject={setSelectedProject}
              />
            ))
          )}
        </tbody>
      </table>
      <DeleteConfirmModal
        isOpen={deleteModalActive}
        setIsOpen={setDeleteModalActive}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
      />
    </div>
  );
};

export const AssignedDropDown = ({ data, updateSelectedMember }) => {
  const { workspaceMembers } = useSelector((store) => store.auth);
  const [opened, setOpened] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [assignedUsers, setAssignedUsers] = useState(data.assigned_user_ids || []);
  const isTheSame =
    data.assigned_user_ids?.length === assignedUsers.length &&
    data.assigned_user_ids?.every((id) => assignedUsers.includes(id));

  useEffect(() => {
    if (!opened && !isTheSame) setAssignedUsers(data.assigned_user_ids || []);
  }, [data.assigned_user_ids, isTheSame, opened]);

  const handleMemberSelect = (v) => {
    setAssignedUsers((prev) => {
      if (prev.includes(v)) return prev.filter((m) => m !== v);
      return [...prev, v];
    });
  };

  const getUsernameById = useCallback(
    (userId) => {
      const result = workspaceMembers?.find((member) => member?.id === userId);
      return result?.username;
    },
    [workspaceMembers],
  );

  const sortedMembers = useMemo(
    () =>
      [...workspaceMembers].sort((a, b) => {
        const aSelected = assignedUsers?.includes(a.id);
        const bSelected = assignedUsers?.includes(b.id);

        if (aSelected && !bSelected) {
          return -1; // a is selected but b is not, move a to the top
        } else if (!aSelected && bSelected) {
          return 1; // b is selected but a is not, move b to the top
        } else {
          // both a and b are selected or both are not selected, sort alphabetically
          return a.username.localeCompare(b.username);
        }
      }),
    [assignedUsers, workspaceMembers],
  );

  const filteredUsers = useMemo(() => {
    return sortedMembers?.filter((me) => me?.username?.toLocaleLowerCase()?.includes(searchVal?.toLocaleLowerCase()));
  }, [searchVal, sortedMembers]);

  const avatars = useMemo(
    () =>
      assignedUsers?.reduce((acc, userId) => {
        const username = getUsernameById(userId);
        if (!username) return acc;
        return [
          ...acc,
          {
            id: username,
            name: username,
            size: 32,
            tooltip: true,
          },
        ];
      }, []),
    [assignedUsers, getUsernameById],
  );

  return (
    <Popover
      open={opened}
      onOpenChange={(open) => {
        if (open) setAssignedUsers(data.assigned_user_ids || []);
        setOpened(open);
        if (!open) updateSelectedMember(data.id, assignedUsers);
      }}
      modal
      content={
        <div className="w-max max-w-[425px] rounded-[6px] overflow-hidden bg-white shadow-[0_0_15px_rgba(0,0,0,0.1)] z-20">
          <div className="max-h-[250px] overflow-y-auto">
            <div className="sticky top-0 left-0 bg-white z-[2] p-[0.15rem]">
              <input
                autoFocus
                onChange={(e) => setSearchVal(e.target.value)}
                value={searchVal}
                type="text"
                placeholder={"Search Members"}
                className={`w-[100%] h-[25px] py-5 px-2 text-sm rounded border !outline-0 border-gray-200`}
                style={{ outlineStyle: "solid" }}
              />
            </div>
            <div className="p-2 pt-1">
              {filteredUsers?.length > 0 ? (
                filteredUsers?.map((v, i) => (
                  <div key={i} className="flex items-center min-w-[120px] max-w-[425px] py-0.5">
                    <label className="flex items-center gap-2 py-1 text-sm select-none cursor-pointer w-full text-gray-900 max-w-full overflow-hidden">
                      <Checkbox
                        onCheck={() => handleMemberSelect(v?.id)}
                        checked={!!assignedUsers?.find((n) => n === v?.id)}
                      />
                      <Avatar id={v?.username} size={24} name={v?.username} className="!text-[13px]" />
                      {v?.username}
                    </label>
                  </div>
                ))
              ) : (
                <div className="min-w-[160px] text-gray-400 text-center px-2 py-3 text-xs">No members found.</div>
              )}
            </div>
          </div>
        </div>
      }
    >
      <div className="cursor-pointer py-1 w-full">
        {workspaceMembers?.length > 0 ? (
          <div className="flex items-center w-full">
            <div className="flex items-center -space-x-[11px] ml-[9px] min-w-[100px] min-h-[32px]">
              {avatars?.length > 0 ? (
                <AvatarGroup maxCount={3} avatars={avatars} size={26} />
              ) : (
                <p className="text-gray-400 text-sm">Add Members</p>
              )}
            </div>
            {workspaceMembers?.length > 2 && <span className="text-sm text-gray-400 ml-1">+</span>}
          </div>
        ) : null}
      </div>
    </Popover>
  );
};

export default ContractTable;

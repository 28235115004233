import * as Y from "yjs";

// To make migration away from liveblocks easier, we create a wrapper around Y.Map and Y.Array with equivalent name
// And similar functionality to liveblocks LiveObject and LiveList
export class LiveObject<T extends object> extends Y.Map<any> {
  constructor(initial: T) {
    super();
    Object.entries(initial).forEach(([key, value]) => {
      this.set(key, value);
    });
    Object.setPrototypeOf(this, Y.Map.prototype);
  }
}

export class LiveList<T> extends Y.Array<T> {
  constructor(initial: T[] = []) {
    super();
    this.insert(0, initial);
    Object.setPrototypeOf(this, Y.Array.prototype);
  }
}

export const findIndex = <T>(liveList: LiveList<T>, predicate: (item: T, idx: number) => boolean) => {
  return liveList.toArray().findIndex(predicate);
};

export const find = <T>(liveList: LiveList<T>, predicate: (item: T) => boolean) => {
  return liveList.toArray().find(predicate);
};

export const moveItem = <T>(liveList: LiveList<T>, fromIndex: number, toIndex: number) => {
  if (fromIndex === toIndex) return;
  let item = liveList.get(fromIndex);
  if (item instanceof Y.Map) {
    // Moving YMaps inside of a YArray is problematic, and requires us to deep clone the YMap https://discuss.yjs.dev/t/moving-elements-in-lists/92/12
    item = item.clone() as T;
  }
  liveList.delete(fromIndex);
  liveList.insert(toIndex, [item]);
};

export const filter = <T>(liveList: LiveList<T>, predicate: (item: T) => boolean) => {
  return liveList.toArray().filter(predicate);
};

export const some = <T>(liveList: LiveList<T>, predicate: (item: T) => boolean) => {
  return liveList.toArray().some(predicate);
};

export const update = <T extends object>(liveObject: LiveObject<T>, updates: Partial<T>) => {
  liveObject.doc?.transact(() => {
    Object.entries(updates).forEach(([key, value]) => {
      liveObject.set(key, value);
    });
  });
};

export type ToImmutable<T> =
  T extends LiveObject<infer U>
    ? ToImmutable<U>
    : T extends LiveList<infer U>
      ? ToImmutable<U>[]
      : T extends object
        ? { [K in keyof T]: ToImmutable<T[K]> }
        : T;

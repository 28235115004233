/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";

//////////////// IMPORTING IMAGES AND ICONS //////////////////////////
import dots from "../../Assets/3dots.svg";
import folderOpenIcon from "../../Assets/folder-open.svg";
import { MoveIcon } from "utils/icons";
import { useAppDispatch } from "store/storeTypes";
import { setDocumentNavHistory } from "store/reducers/driveReducerSlice";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Pencil, Trash2 } from "lucide-react";
import { useAppSelector } from "store/storeTypes";
import type { BaseFile } from "types/FileStorage";
import { CONTENT_LIBRARY } from "const-values/testIds";

function formatName(str: string) {
  if (str.length <= 17) {
    return str;
  }
  return str.substring(0, 17) + "...";
}

type Props = {
  id: string;
  name: string;
  all_nested_files?: BaseFile[];
  draggingFolderId: string;
  setDraggingFolderId: (folderId: string) => unknown;
  draggingDocIds: string[];
  setDraggingDocIds: (draggingDocIds: string[]) => unknown;
  moveSelectedDirectory: (id: string, draggingFolderId: string) => unknown;
  moveDocument: (id: string, docIds: string[]) => unknown;
  handleDelete: (id: string) => unknown;
  setSelectedFolderHeading: (folderHeading: string) => unknown;
  selectedFolderHeading: string;
  setIsDragOverDrive: (dragOverDriver: boolean) => unknown;
  onFolderDrop: (files: File[], id: string) => unknown;
  handleMoveModalOpen: (id: string, type: "folder") => unknown;
  openFolderModal: (id: string, name: string) => unknown;
};

export default function Folder({
  id,
  name,
  all_nested_files = [],
  draggingFolderId,
  setDraggingFolderId,
  draggingDocIds,
  setDraggingDocIds,
  moveSelectedDirectory,
  moveDocument,
  handleDelete,
  setSelectedFolderHeading,
  selectedFolderHeading,
  setIsDragOverDrive,
  onFolderDrop,
  handleMoveModalOpen,
  openFolderModal,
}: Props) {
  const dispatch = useAppDispatch();
  const [, setIsDragOver] = useState(false);
  const { navHistory: docNavHistory } = useAppSelector((root) => root.drive.fileStorage);

  function handleDragOver(e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();

    const hasFiles = e.dataTransfer.types.includes("Files");

    if (hasFiles || draggingDocIds.length > 0 || draggingFolderId) {
      setIsDragOver(true);
    }

    setSelectedFolderHeading(id);
    setIsDragOverDrive(false);
  }

  function handleDragLeave(e: React.DragEvent) {
    const relatedTarget = e.relatedTarget;

    if (!relatedTarget || (relatedTarget !== e.currentTarget && !e.currentTarget.contains(relatedTarget as Node))) {
      setSelectedFolderHeading("");
      setIsDragOver(false);
    }
  }

  function handleDrag(e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();

    setDraggingFolderId(id);
    setDraggingDocIds([]);
  }

  async function handleDrop(e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();

    /// if files are dragged directly from computer
    if (selectedFolderHeading) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      onFolderDrop(droppedFiles, id);
    }

    //// if file is dragged into a folder
    if (draggingDocIds.length > 0) {
      moveDocument(id, draggingDocIds);
    }

    ///// if folder is dragged into another folder
    if (draggingFolderId) {
      // Don't do anything if trying to move folder into itself
      if (draggingFolderId === id) {
        setDraggingFolderId("");
        setSelectedFolderHeading("");
        setIsDragOver(false);
        return;
      }

      moveSelectedDirectory(id, draggingFolderId);
    }

    setDraggingDocIds([]);
    setDraggingFolderId("");
    setSelectedFolderHeading("");
    setIsDragOver(false);
  }

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2">
            <Pencil size={14} /> Rename
          </div>
        ),
        onSelect: () => {
          openFolderModal(id, name);
        },
      },
      {
        key: 2,
        label: (
          <div className="flex items-center gap-2">
            <MoveIcon />
            Move
          </div>
        ),
        onSelect: () => {
          handleMoveModalOpen(id, "folder");
        },
      },
      {
        key: 3,
        label: (
          <div className="flex items-center gap-2 text-red-500">
            <Trash2 size={14} />
            Delete
          </div>
        ),
        onSelect: () => {
          const fileCount = all_nested_files?.length ?? 0;
          if (fileCount > 0) {
            triggerConfirm({
              variant: "error",
              proceedLabel: "Delete",
              header: `Delete "${name}"?`,
              body: `This folder contains ${fileCount} document${fileCount > 1 ? "s" : ""}.`,
            }).then((proceed) => {
              if (proceed) {
                handleDelete(id);
              }
            });
          } else {
            handleDelete(id);
          }
        },
      },
    ],
    [handleDelete, handleMoveModalOpen, id, name, openFolderModal],
  );

  return (
    <Link
      title={name}
      to={`/dashboard/library/documents/folder/${id}`}
      onClick={() => {
        dispatch(
          setDocumentNavHistory([
            ...docNavHistory,
            {
              name,
              id,
            },
          ]),
        );
      }}
    >
      <div
        data-drag-ignore
        className={`flex items-center gap-2.5 h-full relative px-2.5 py-2.5 rounded-lg hover:bg-slate-100 shadow-md w-full border border-gray-light`}
        css={[selectedFolderHeading === id ? tw`border-[#1E1E1E] bg-[#DBE0E5]` : tw`bg-white`]}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDrag={handleDrag}
        onDragLeave={handleDragLeave}
        draggable
        data-testid={CONTENT_LIBRARY.folder.folderItem}
      >
        <img
          src={folderOpenIcon}
          alt="Folder Open Icon"
          loading="lazy"
          className="w-[24px] h-[24px] pointer-events-none"
        />
        <div className="flex-1">
          <div className="flex justify-between align-items-center">
            <div>
              <h4 className="font-semibold text-sm break-words">{formatName(name)}</h4>
              <p className="text-[11px] text-[#5B6B79]">{all_nested_files.length} Files</p>
            </div>

            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              className="flex items-center"
            >
              <DropdownMenu items={menuItems}>
                <div className="cursor-pointer ml-auto">
                  <img src={dots} alt="Three Dots" className="max-w-full mx-auto pointer-events-none object-contain" />
                </div>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

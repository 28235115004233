import dropdownIcon from "Assets/icon-wrapper.svg";
import cloud_upload from "Assets/cloud-upload.svg";
import { Button } from "components/editor/components";
import { Modal } from "components/organisms/modal";
import TagsDropdown from "components/organisms/tags-dropdown/TagsDropdown";
import { useNotification } from "context/notificationContext";
import type { ComponentProps } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import type { FileRejection } from "react-dropzone";
import { ErrorCode, useDropzone } from "react-dropzone";
import { Trash2 } from "lucide-react";
import { setMediaUserTags } from "store/reducers/driveReducerSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import type { Subdirectory } from "types/FileStorage";
import { ACCEPT_MEDIA_TYPES } from "../constants";
import type { NestedDriveMediaState } from "../media/hooks";
import { useDropValidator } from "../media/hooks";
import { useMediaOperations } from "../media/useMediaOperations";

interface Props extends Partial<ComponentProps<typeof Modal>> {
  currentNestedDriveMediaState?: NestedDriveMediaState;
  currentDirectory?: Subdirectory | null;
}

const AddMediaFileModal = ({ currentNestedDriveMediaState, ...props }: Props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const { createFile, isLoading } = useMediaOperations();
  const dropValidator = useDropValidator();
  const { setToast } = useNotification();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  }, []);
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLInputElement>(null);
  const { userTags } = useAppSelector((state) => state.drive.media);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    validator: dropValidator,
    maxFiles: 10,
    onDropRejected: (fileRejections: FileRejection[]) => {
      for (const fileRejection of fileRejections) {
        for (const error of fileRejection.errors) {
          if (error.code === ErrorCode.TooManyFiles) {
            setToast.error({
              title: "Upload too many images",
              msg: "You can only upload 10 images at a time",
            });
          }
        }
      }
    },
    onDrop,
    accept: ACCEPT_MEDIA_TYPES,
    noClick: false,
  });

  useEffect(() => {
    if (props.open) {
      setFiles([]);
      setSelectedTags([]);
    }
  }, [props.open]);

  const handleDelete = (e: any, index: number) => {
    e.stopPropagation();
    e.preventDefault();
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <Modal
      contentProps={{ css: tw`w-[744px] max-w-[744px]` }}
      title="Upload Graphic"
      header="Upload Graphic"
      body={
        <div className="flex flex-col overflow-y-auto px-5" ref={containerRef}>
          <div
            {...getRootProps()}
            className="overflow-auto p-3 w-full mx-auto cursor-pointer relative border-gray-300 border-dashed border-2 m-3 justify-center flex flex-col items-center rounded-lg bg-white h-[320px]"
          >
            {!files.length ? (
              <>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div className="absolute bg-gray-100 flex justify-center items-center text-gray-700 top-0 left-0 h-full w-full z-[2] bg-dark1 bg-opacity-75">
                    {" "}
                  </div>
                ) : null}
                <img className="w-9 h-9 mb-2" src={cloud_upload} alt="" />
                <span className="font-semibold">Drop or Select File</span>
                <span className="text-gray-text">{"We accept jpeg and png files (up to 10 files)"} </span>
              </>
            ) : files.length > 1 ? (
              <div className="flex flex-wrap overflow-y-auto cursor-auto justify-around gap-2 w-full">
                {files.map((file, index) => (
                  <div key={index} className="w-[47.5%] flex flex-col gap-2">
                    <img
                      className="w-full h-[230px] rounded-md object-cover"
                      src={URL.createObjectURL(file)}
                      alt={`preview-${index}`}
                    />
                    <div className="flex justify-between items-center gap-x-2">
                      <div className="text-sm font-normal overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {file.name}
                      </div>
                      <div
                        onClick={(e) => handleDelete(e, index)}
                        className="flex items-center gap-2 text-red-500 cursor-pointer"
                      >
                        <Trash2 size={14} />
                      </div>
                    </div>
                  </div>
                ))}
                {files.length % 2 !== 0 && <div className="w-[47.5%]"></div>}
              </div>
            ) : (
              <div className="flex flex-col gap-3 h-full cursor-auto">
                <img className="object-cover" src={URL.createObjectURL(files[0])} alt="" />
                <div className="flex justify-between items-center gap-x-2">
                  <div className="mb-3 text-sm font-normal overflow-hidden whitespace-nowrap overflow-ellipsis">
                    {files[0].name}
                  </div>
                  <div
                    onClick={(e) => handleDelete(e, 0)}
                    className="mb-2 flex items-center gap-2 text-red-500 cursor-pointer"
                  >
                    <Trash2 size={14} />
                  </div>
                </div>
              </div>
            )}
          </div>

          <label className="mb-2 block text-sm text-[#5B6B79]">Tags</label>
          <TagsDropdown
            ref={containerRef}
            dropdownIcon={dropdownIcon}
            selectedTags={selectedTags}
            setSelectedTags={(tags) => {
              setSelectedTags(tags);
              const newUserTags = new Set();
              userTags.forEach((tag) => newUserTags.add(tag));
              tags.forEach((tag) => newUserTags.add(tag));
              dispatch(setMediaUserTags(Array.from(newUserTags)));
            }}
            availableTags={userTags}
            placeholder="Add tags..."
          />
        </div>
      }
      footer={
        <div className="flex justify-end gap-4">
          <button
            type="button"
            className="border border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
            onClick={() => {
              props.onOpenChange?.(false);
            }}
          >
            Cancel
          </button>
          <Button
            variant="primaryBlack"
            size="md"
            loading={isLoading}
            disabled={!files.length}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!files.length) return;
              await Promise.all(
                files.map((file) =>
                  createFile({
                    file,
                    user_tags: selectedTags,
                    parent_directory_id: currentNestedDriveMediaState?.current_directory?.id,
                  }),
                ),
              );
              props.onOpenChange?.(false);
            }}
            className=""
          >
            Upload
          </Button>
        </div>
      }
      {...props}
    />
  );
};

export default AddMediaFileModal;

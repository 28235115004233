/** @jsxImportSource @emotion/react */

import axios from "axios";
import { keyBy } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useDragSelectOperation } from "./hooks";
import PageWrapper from "./PageWrapper";
import { SelectionAreaListener, useSelection } from "./SelectionContext";

import { useDocViewNotification } from "../../context/doc-view-notification-context/context";
import type { Section } from "components/copilot/CopilotSchemaTypes";
import { CircleCheck } from "lucide-react";
import type { Dispatch, SetStateAction } from "react";
import { Document } from "react-pdf";
import type { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { useSearchParams } from "react-router-dom";
import {
  appendCoordinateCancelTokens,
  getCoordinates,
  getOrderKeys,
  setRequirementsConfig,
} from "store/reducers/extract/CurrentExtractionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { ScreenSpinner } from "utils/icons";
import { useTrackUserMetric } from "utils/metrics";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import * as logger from "utils/log";

type PageDimensionsMap = Record<number, { width: number; height: number }>;

type Props = {
  scale: number;
  isReadOnly: boolean;
  sectionsMap: Record<string, ToImmutable<Section>>;
  keysOfSectionMap: string[];
  pdfItemState: [DocumentCallback | undefined, Dispatch<SetStateAction<DocumentCallback | undefined>>];
};

const DocumentContainer = ({ scale, keysOfSectionMap, sectionsMap, isReadOnly, pdfItemState }: Props) => {
  const dispatch = useAppDispatch();
  const { setToast } = useDocViewNotification();
  const trackUserEvent = useTrackUserMetric();
  const groupedBlocks = useAppSelector((store) => store.currentExtractionState.groupedBlocks);
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const activeDocumentId = useAppSelector((store) => store.currentExtractionState.activeDocument?.id);
  const groupedFilteredRequirementsByDocument = useAppSelector(
    (store) => store.currentExtractionState.groupedFilteredRequirementsByDocument,
  );
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const mergedRequirements = useAppSelector((store) => store.currentExtractionState.mergedRequirements);
  const docYMap = useRef(
    (extraction?.file_ids || []).reduce<Record<string, number>>((acc, fileId) => {
      acc[fileId] = 0;
      return acc;
    }, {}),
  );
  const groupedFilteredRequirements = useMemo(
    () =>
      keyBy(
        (groupedFilteredRequirementsByDocument[activeDocumentId || ""] || []).map((filteredRequirement) => {
          return filteredRequirement.group_id
            ? mergedRequirements.find((mergedRequirement) => mergedRequirement.id === filteredRequirement.group_id) ||
                filteredRequirement
            : filteredRequirement;
        }),
        "id",
      ),
    [activeDocumentId, groupedFilteredRequirementsByDocument, mergedRequirements],
  );

  const [visiblePage, setVisiblePage] = useState(
    (extraction?.file_ids || []).reduce<Record<string, number>>((acc, fileId) => {
      acc[fileId] = 1;
      return acc;
    }, {}),
  );
  const [pdfItem, setPdfItem] = pdfItemState;
  const { selectionState, clearSelection } = useSelection();
  const [numPages, setNumPages] = useState(0);
  const [dimensions, setDimensions] = useState<PageDimensionsMap>({});
  const { onMove, onStop, onBeforeStart } = useDragSelectOperation(groupedBlocks.allFilteredBlocks, extraction);
  const isLoading = useAppSelector((store) => {
    const { isLoadingDocuments, isLoadingTemplate, isLoadingRequirementGroups } = store.currentExtractionState;

    return isLoadingDocuments || isLoadingTemplate || isLoadingRequirementGroups;
  });
  const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);

  const pageList = useMemo(() => Array.from({ length: numPages }, (_, i) => i + 1), [numPages]);

  const file = useMemo(() => ({ url: activeDocument?.secure_preview_url || "" }), [activeDocument?.secure_preview_url]);

  useEffect(() => {
    if (projectId) {
      dispatch(getOrderKeys({ projectId }));
    }
  }, [dispatch, projectId]);

  useEffect(() => {
    if (!pdfItem) return;
    const resetDimensions = async () => {
      try {
        const pageDimensionsMap: PageDimensionsMap = {};
        // If you want to get the dimension for page 1 (1-indexed)
        const pageList = Array.from({ length: pdfItem.numPages }, (_, i) => i + 1);
        const requestList = await Promise.all(pageList.map((page) => pdfItem.getPage(page)));

        for (let i = 0; i < requestList.length; i++) {
          const page = requestList[i];
          const width = page.view[2] * scale;
          const height = page.view[3] * scale;
          pageDimensionsMap[pageList[i]] = { width, height };
        }
        setDimensions(pageDimensionsMap);
      } catch {}
    };

    resetDimensions();
  }, [scale, pdfItem]);

  useEffect(() => {
    clearSelection?.();
    setPdfItem(undefined);
    if (activeDocument?.id) {
      const cancelToken = axios.CancelToken.source();
      dispatch(getCoordinates({ fileId: activeDocument?.id, cancelToken: cancelToken.token }));
      dispatch(appendCoordinateCancelTokens(cancelToken));
    }
  }, [activeDocument?.id, clearSelection, dispatch, setPdfItem]);

  const onBlockSelected = useCallback(
    (reqId: string) => {
      setToast.success({
        msg: (
          <div className="flex flex-row gap-2 items-center text-sm text-white">
            <CircleCheck size={14} />
            <span>Requirement added.</span>
          </div>
        ),
      });
      trackUserEvent("Generate: Requirement Added", {
        analysis_id: extraction?.id,
        solicitation_type: extraction?.solicitation_type,
      });
      dispatch(setRequirementsConfig({ currentRequirementIdAdded: reqId }));
    },
    [dispatch, extraction?.id, extraction?.solicitation_type, setToast, trackUserEvent],
  );

  const onDocumentLoadSuccess = useCallback(
    async (pdf: DocumentCallback) => {
      setNumPages(pdf.numPages);
      setPdfItem(pdf);
      try {
        const pageDimensionsMap: PageDimensionsMap = {};
        // If you want to get the dimension for page 1 (1-indexed)
        const pageList = Array.from({ length: pdf.numPages }, (_, i) => i + 1);
        const requestList = await Promise.all(pageList.map((page) => pdf.getPage(page)));

        for (let i = 0; i < requestList.length; i++) {
          const page = requestList[i];
          const width = page.view[2];
          const height = page.view[3];
          pageDimensionsMap[pageList[i]] = { width, height };
        }
        setDimensions(pageDimensionsMap);
      } catch {
        // silently ignore
      }
    },
    [setPdfItem],
  );

  const onDocumentLoadError = useCallback((error: Error) => {
    logger.error(error, "Load error");
  }, []);

  useEffect(() => {
    selectionState?.resolveSelectables();
    // we only need to check for the entire selectionState only resolveSelectables()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiblePage, selectionState?.resolveSelectables]);

  const DisplayChild = useMemo(
    () => (
      <div className="px-8 pt-14 w-full">
        {file.url && !isLoading ? (
          <Document
            renderMode="canvas"
            file={file}
            onLoadError={onDocumentLoadError}
            onLoadSuccess={onDocumentLoadSuccess}
            className="w-full h-full"
            loading={
              <div className="absolute inset-0 top-14 flex items-center justify-center">
                <ScreenSpinner />
              </div>
            }
          >
            {window === undefined || !pdfItem ? (
              <div />
            ) : (
              pageList.map((page) => {
                const pageBlocks = groupedBlocks.pageGroups[page] || [];

                return (
                  <PageWrapper
                    groupedFilteredRequirements={groupedFilteredRequirements}
                    page={page}
                    dimensions={dimensions[page]}
                    key={page}
                    isReadOnly={isReadOnly}
                    pageBlocks={pageBlocks}
                    scale={scale}
                    onBlockSelected={onBlockSelected}
                    sectionsMap={sectionsMap}
                    keysOfSectionMap={keysOfSectionMap}
                    visiblePage={activeDocumentId ? visiblePage[activeDocumentId] : 1}
                  />
                );
              })
            )}
          </Document>
        ) : (
          <div className="flex items-center justify-center h-full">
            <ScreenSpinner />
          </div>
        )}
      </div>
    ),
    [
      activeDocumentId,
      dimensions,
      file,
      groupedBlocks.pageGroups,
      groupedFilteredRequirements,
      isLoading,
      isReadOnly,
      keysOfSectionMap,
      onBlockSelected,
      onDocumentLoadError,
      onDocumentLoadSuccess,
      pageList,
      pdfItem,
      scale,
      sectionsMap,
      visiblePage,
    ],
  );

  const pagesScrollScale = useMemo(() => {
    let cumulativeHeight = 0;
    const values = Object.entries(dimensions).map(([page, dim]) => {
      const start = cumulativeHeight;
      const end = start + dim.height;

      cumulativeHeight = end;

      return {
        value: dim.height,
        start,
        end,
        page,
      };
    });
    return values;
  }, [dimensions]);

  useEffect(() => {
    if (!pdfItem) return;
    const node = document.querySelector(".doc-view-selection-container");
    if (node && activeDocumentId && docYMap.current) {
      setTimeout(() => node.scrollTo(0, Number(docYMap.current?.[activeDocumentId])), 50);
    }
  }, [activeDocumentId, pdfItem, docYMap]);

  return (
    <>
      <SelectionAreaListener
        isReadOnly={isReadOnly}
        onStop={onStop}
        onScroll={(e) => {
          const currentPage = pagesScrollScale.find(
            ({ start, end }) => e.currentTarget.scrollTop >= start && e.currentTarget.scrollTop <= end,
          )?.page;

          if (activeDocumentId && pdfItem)
            setVisiblePage((prev) => ({ ...prev, [activeDocumentId]: Number(currentPage) }));

          if (activeDocumentId && docYMap.current && e.currentTarget.scrollTop && pdfItem) {
            docYMap.current[activeDocumentId] = e.currentTarget.scrollTop;
          }
        }}
        className="doc-view-selection-container flex justify-center h-full overflow-y-auto"
        onMove={onMove}
        selectables=".ds-selectable"
        container=".doc-view-selection-container"
        onBeforeStart={onBeforeStart}
      >
        {DisplayChild}
      </SelectionAreaListener>
    </>
  );
};

export default DocumentContainer;

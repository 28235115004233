/** @jsxImportSource @emotion/react */

import type { Interpolation, Theme } from "@emotion/react";
import * as RadixSwitch from "@radix-ui/react-switch";
import type { ComponentProps } from "react";
import type { TwStyle } from "twin.macro";
import tw from "twin.macro";

type Size = "sm" | "md" | "lg";
type Variant = "primary" | "outline" | "black";

interface Props extends ComponentProps<typeof RadixSwitch.Root> {
  styles?: Interpolation<Theme>;
  thumbStyles?: Interpolation<Theme>;
  size?: Size;
  variant?: Variant;
}

const sizeStyles: Record<Size, { root: TwStyle; thumb: TwStyle }> = {
  sm: {
    root: tw`w-[28px] h-[16px]`,
    thumb: tw`w-[12px] h-[12px] data-[state=checked]:translate-x-[12px]`,
  },
  md: {
    root: tw`w-[36px] h-[20px]`,
    thumb: tw`w-[16px] h-[16px] data-[state=checked]:translate-x-[18px]`,
  },
  lg: {
    root: tw`w-[42px] h-[25px]`,
    thumb: tw`w-[21px] h-[21px] data-[state=checked]:translate-x-[19px]`,
  },
};

const variantStyles: Record<Variant, { root: TwStyle; thumb: TwStyle }> = {
  primary: {
    root: tw`bg-gray-500 data-[state=checked]:bg-action`,
    thumb: tw`w-[12px] h-[12px] data-[state=checked]:translate-x-[12px]`,
  },
  outline: {
    root: tw`bg-transparent border border-[#D1D5DB]`,
    thumb: tw`bg-[#37415180] data-[state=checked]:bg-[#4B5563]`,
  },
  black: {
    root: tw`bg-gray-200 data-[state=checked]:bg-[#1e1e1e]`,
    thumb: tw`bg-gray-400 data-[state=checked]:bg-white`,
  },
};

const Switch = ({ size = "md", styles, thumbStyles, variant = "primary", ...props }: Props) => {
  return (
    <RadixSwitch.Root
      className="rounded-full relative outline-none"
      style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
      css={[sizeStyles[size].root, variantStyles[variant].root, styles]}
      {...props}
    >
      <RadixSwitch.Thumb
        className="block rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform"
        css={[sizeStyles[size].thumb, variantStyles[variant].thumb, thumbStyles]}
      />
    </RadixSwitch.Root>
  );
};

export default Switch;

import { CopilotPresencePage } from "types/Presence";
import type { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import type { Storage } from "components/copilot/CopilotSchemaTypes";
import { ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import type { LiveObject, ToImmutable } from "YJSProvider/LiveObjects";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useCallback, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useFlags } from "hook/useFlags";
import { CircleCheck, FileEdit, FileText, FileUp, FolderClosed, House, Target } from "lucide-react";
import { setActiveSheetState } from "store/reducers/copilot/requirementsReducer";
import { useStorage } from "YJSProvider/createYJSContext";

export const useInputsSubItems = (id: string | undefined) => {
  const { pathname } = useLocation();
  const isDocuments = pathname.includes("documents");
  const isCapture = pathname.includes("capture");

  return [
    {
      label: "Documents",
      to: `/dashboard/contracts/details/inputs/documents?tab=${CopilotPresencePage.Inputs}&id=${id}`,
      isActive: isDocuments,
    },
    {
      label: "Capture",
      to: `/dashboard/contracts/details/inputs/capture?tab=${CopilotPresencePage.Inputs}&id=${id}`,
      isActive: isCapture,
    },
  ];
};

export const useExtractionSubItems = (id: string | undefined) => {
  const { extractionId } = useParams();
  const extractions = useStorage<NonNullable<ToImmutable<LiveObject<Storage>>["extractions"]>>(
    (root) => (root as ToImmutable<LiveObject<Storage>>).extractions || [],
  );
  return useMemo(
    () =>
      extractions
        ?.filter(({ status }) => !!status && status !== ExtractionStatus.Failed)
        ?.map((extraction) => ({
          label: extraction?.name,
          to: `/dashboard/contracts/details/extractions/${extraction?.id}?tab=${CopilotPresencePage.Extract}&id=${id}`,
          isActive: extractionId === extraction?.id,
        })) || [],
    [extractionId, id, extractions],
  );
};

export const useSheetSubItems = (id: string | undefined) => {
  const activeSheet = useAppSelector((root) => root.requirements.activeSheet);
  const dispatch = useAppDispatch();
  const sheets: Sheet[] = useStorage((storage) => storage.sheets || []);

  const handleSheetSelection = useCallback(
    (sheet?: Sheet) => {
      if (sheet) dispatch(setActiveSheetState(sheet));
    },
    [dispatch],
  );

  return useMemo(
    () =>
      sheets?.map((sheet) => ({
        label: sheet?.name,
        to: `/dashboard/contracts/details?tab=${CopilotPresencePage.ComplianceMatrix}&id=${id}`,
        value: sheet,
        isActive: sheet?.id === activeSheet?.id,
        onClick: handleSheetSelection,
      })),
    [sheets, activeSheet, id, handleSheetSelection],
  );
};

export const useVolumeSubItems = (id: string | undefined) => {
  const { volumeId } = useParams();
  const volumes = useStorage<NonNullable<ToImmutable<LiveObject<Storage>>["framework"]>>(
    (root) => (root as ToImmutable<LiveObject<Storage>>).framework || [],
  );

  return useMemo(
    () =>
      volumes?.volumes?.map((volume) => ({
        label: volume.title,
        to: `/dashboard/contracts/details/volumes/${volume?.id}/sections/${volume.sections[0]?.id}/requirements?tab=${CopilotPresencePage.Template}&id=${id}`,
        isActive: volumeId === volume?.id,
      })) || [],
    [id, volumeId, volumes?.volumes],
  );
};

export const useGuideLinkSubItems = () => {
  const { enableNewProjectFlow } = useFlags();

  if (enableNewProjectFlow)
    return [
      {
        label: "Projects",
        to: "https://intercom.help/vultron-ai/en/articles/8893852-getting-started-projects",
        openInNewTab: true,
      },
      {
        label: "Documents",
        to: "https://intercom.help/vultron-ai/en/articles/8896158-projects-import-documents",
        openInNewTab: true,
      },
      {
        label: "Proposals",
        to: "https://intercom.help/vultron-ai/en/articles/8902376-project-proposals",
        openInNewTab: true,
      },
    ];

  return [
    {
      label: "Projects",
      to: "https://intercom.help/vultron-ai/en/articles/8893852-getting-started-projects",
      openInNewTab: true,
    },
    {
      label: "Documents",
      to: "https://intercom.help/vultron-ai/en/articles/8896158-projects-import-documents",
      openInNewTab: true,
    },
    {
      label: "Generate",
      to: "https://intercom.help/vultron-ai/en/articles/9312141-projects-generate",
      openInNewTab: true,
    },
    {
      label: "Drafts",
      to: "https://intercom.help/vultron-ai/en/articles/8931970-projects-drafts",
      openInNewTab: true,
    },
    {
      label: "Proposals",
      to: "https://intercom.help/vultron-ai/en/articles/8902376-project-proposals",
      openInNewTab: true,
    },
  ];
};

export const useSideNavigationTabs = (id: string) => {
  const { enableNewProjectFlow } = useFlags();
  const proposals = useAppSelector((store) => store.project.proposals);
  const hasSingleProposal = proposals?.length === 1;
  const firstDocId = proposals[0]?.id;
  const inputsSubItems = useInputsSubItems(id);
  const extractionsSubItems = useExtractionSubItems(id);
  const sheetSubItems = useSheetSubItems(id);
  const volumesSubItems = useVolumeSubItems(id);

  const secondaryItems = [
    { name: "Generate", slug: CopilotPresencePage.Extract, tabIcon: FileUp, subitems: extractionsSubItems },
    {
      name: "Requirements",
      slug: CopilotPresencePage.ComplianceMatrix,
      tabIcon: CircleCheck,
      subItems: sheetSubItems,
    },
    { name: "Draft", slug: CopilotPresencePage.Template, tabIcon: FileEdit, subItems: volumesSubItems },
  ];

  if (enableNewProjectFlow)
    return {
      primary: [
        {
          name: "Draft",
          slug: CopilotPresencePage.Proposal,
          tabIcon: House,
          to: `/dashboard/contracts/details?tab=${CopilotPresencePage.Proposal}&id=${id}${hasSingleProposal ? `&docId=${firstDocId}` : ""}`,
        },
        {
          name: "Inputs",
          slug: CopilotPresencePage.Inputs,
          tabIcon: FolderClosed,
          subItems: inputsSubItems,
          to: `/dashboard/contracts/details/inputs?tab=${CopilotPresencePage.Inputs}&id=${id}`,
        },
      ],
      secondary: secondaryItems,
    };

  return {
    primary: [
      { name: "Proposals", slug: CopilotPresencePage.Proposal, tabIcon: FileText },
      {
        name: "Documents",
        slug: CopilotPresencePage.Documents,
        tabIcon: FolderClosed,
        to: `/dashboard/contracts/details/documents/project-specific?tab=${CopilotPresencePage.Documents}&id=${id}`,
      },
      {
        name: "Capture",
        slug: CopilotPresencePage.Capture,
        tabIcon: Target,
        to: `/dashboard/contracts/details/capture?tab=${CopilotPresencePage.Capture}&id=${id}`,
      },
    ],
    secondary: secondaryItems,
  };
};

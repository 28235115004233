import { DRIVE_ROUTES } from "Routes/drive";
import axios from "axios";
import type { NestedDriveState } from "components/molecules/content-drive-popover-content/hooks";
import { useDebouncedCallback } from "hook/useDebouncedCallback";
import throttle from "lodash/throttle";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { WorkspaceFile, Subdirectory } from "types/FileStorage";

export const BEFORE_STRING_OFFSET_DAY = {
  Yesterday: 1,
  "Last Week": 7,
  "Last Month": 30,
  "Last 6 Months": 180,
  "Last Year": 365,
};

const getDateBasedOnOffset = (before: keyof typeof BEFORE_STRING_OFFSET_DAY | "") => {
  if (!before) return;
  const offsetDays = BEFORE_STRING_OFFSET_DAY[before];
  const date = new Date();
  date.setDate(date.getDate() - offsetDays);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;

  const date_now = new Date();
  date_now.setDate(date_now.getDate() + 1);
  const now_year = date_now.getFullYear();
  const now_month = ("0" + (date_now.getMonth() + 1)).slice(-2);
  const now_day = ("0" + date_now.getDate()).slice(-2);
  const formattedNowDate = `${now_year}-${now_month}-${now_day}`;

  return { startDate: formattedDate, endDate: formattedNowDate };
};

export const useDirectoryFileSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [directoryId, setDirectoryId] = useState<string>("");
  const [uploaded, setUploaded] = useState<keyof typeof BEFORE_STRING_OFFSET_DAY | "">("");
  const [documentTypes, setDocumentTypes] = useState<string[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<WorkspaceFile[]>([]);
  const [folderSearchResults, setFolderSearchResults] = useState<Subdirectory[]>([]);

  const triggerSearch = useCallback(
    async (omitLoader?: boolean) => {
      const { startDate, endDate } = getDateBasedOnOffset(uploaded) || {};

      if (!searchQuery.trim() && !directoryId && !startDate && !endDate && !documentTypes.length) {
        setSearchResults([]);
        setFolderSearchResults([]);
        setIsSearching(false);
        return;
      }
      if (!omitLoader) setIsSearching(true);
      try {
        const { data } = await axios.get<NestedDriveState>(DRIVE_ROUTES.document.search, {
          params: {
            ...(searchQuery && { search_query: searchQuery }),
            ...(directoryId && { directory_id: directoryId }),
            ...(startDate && { start_date: startDate }),
            ...(endDate && { end_date: endDate }),
            ...(documentTypes.length && {
              document_types: documentTypes.map((doc) => doc.replaceAll(" ", "_").toLowerCase()),
            }),
          },
        });
        setSearchResults(data.files ?? []);
        if (searchQuery) {
          setFolderSearchResults(data.subdirectories ?? []);
        } else {
          // Clear folders when searching by filters
          setFolderSearchResults([]);
        }
      } catch {
        // silently ignore
      } finally {
        if (!omitLoader) setIsSearching(false);
      }
    },
    [directoryId, documentTypes, searchQuery, uploaded],
  );

  const debounceSearch = useDebouncedCallback(triggerSearch, 500);
  const throttleSearch = useMemo(() => throttle(debounceSearch, 0), [debounceSearch]);

  useEffect(() => {
    if (documentTypes.length || uploaded || searchQuery) {
      setIsSearching(true);
      throttleSearch();
    }
  }, [documentTypes.length, throttleSearch, uploaded, searchQuery]);

  const resetSearch = useCallback(() => {
    setSearchQuery("");
    setIsSearching(false);
    if (!uploaded && !documentTypes.length) {
      setSearchResults([]);
      setFolderSearchResults([]);
    }
    if (!!uploaded || !!documentTypes.length) {
      setIsSearching(true);
      throttleSearch();
    }
  }, [documentTypes.length, throttleSearch, uploaded]);

  const isSearchActive = !!searchQuery || !!documentTypes.length || !!uploaded;

  useEffect(() => {
    if (!isSearchActive) resetSearch();
  }, [isSearchActive, resetSearch]);

  const filteredSearchResults = useMemo(
    () => searchResults?.filter(({ parent_directory }) => !directoryId || parent_directory === directoryId) ?? [],

    [directoryId, searchResults],
  );

  const filteredFolderResults = useMemo(
    () => folderSearchResults?.filter((subdirectory) => subdirectory.id !== directoryId) ?? [],
    [directoryId, folderSearchResults],
  );

  return {
    isSearching,
    searchResults: filteredSearchResults,
    setSearchQuery,
    searchQuery,
    resetSearch,
    setDocumentTypes,
    setDirectoryId,
    setUploaded,
    uploaded,
    documentTypes,
    isSearchActive,
    throttleSearch,
    folderSearchResults: filteredFolderResults,
  };
};

import type { ReactNode } from "react";
import React, { createContext, useContext, useMemo } from "react";
import { useAskAi } from "components/organisms/ask-ai-form/hooks";

interface AskAiContextType {
  query: string;
  isLoading: boolean;
  answer: string | null;
  setQuery: (query: string) => void;
  handleAskAi: (params: { requirement_ids?: string[]; search?: boolean; user_query?: string }) => void;
  isError: boolean;
  abortConnection: () => void;
  resetForm: () => void;
}

const AskAiContext = createContext<AskAiContextType | null>(null);

interface AskAiProviderProps {
  children: ReactNode;
}

export const AskAiProvider: React.FC<AskAiProviderProps> = ({ children }) => {
  const { query, isLoading, answer, setQuery, handleAskAi, isError, abortConnection, resetForm } = useAskAi();

  const values = useMemo(
    () => ({
      query,
      isLoading,
      answer,
      setQuery,
      handleAskAi,
      isError,
      abortConnection,
      resetForm,
    }),
    [query, isLoading, answer, setQuery, handleAskAi, isError, abortConnection, resetForm],
  );

  return <AskAiContext.Provider value={values}>{children}</AskAiContext.Provider>;
};

export const useAskAiContext = (): AskAiContextType => {
  const context = useContext(AskAiContext);
  if (!context) {
    throw new Error("useAskAiContext must be used within an AskAiProvider");
  }
  return context;
};

/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import Tooltip from "components/atoms/tooltip";
import type { HTMLAttributes } from "react";

interface DrawerOpenerProps extends HTMLAttributes<HTMLButtonElement> {
  isInitial?: boolean;
  setIsInitial?: React.Dispatch<React.SetStateAction<boolean>>;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarOpen: boolean;
}

const DrawerOpener = ({ isInitial, setIsInitial, setSidebarOpen, sidebarOpen, ...props }: DrawerOpenerProps) => {
  return (
    <Tooltip
      contentProps={{ side: "right", align: "center" }}
      content={!sidebarOpen ? "Open sidebar" : "Close sidebar"}
    >
      <button
        onClick={() => {
          setSidebarOpen((prev) => !prev);
          if (isInitial) setIsInitial?.(false);
        }}
        className="group absolute left-0 top-[calc(50%-72px)] flex h-[72px] w-8 items-center justify-center"
        {...props}
      >
        <div className="flex h-6 w-6 flex-col items-center">
          <div
            className="h-3 w-1 rounded-full duration-200 bg-slate-400 translate-y-0.5 group-hover:bg-slate-900"
            css={[sidebarOpen && tw`group-hover:rotate-[15deg]`, !sidebarOpen && tw`rotate-[-15deg]`]}
          />
          <div
            className="h-3 w-1 rounded-full duration-200 bg-slate-400 -translate-y-0.5 group-hover:bg-slate-900"
            css={[sidebarOpen && tw`group-hover:rotate-[-15deg]`, !sidebarOpen && tw`rotate-[15deg]`]}
          />
        </div>
      </button>
    </Tooltip>
  );
};

export default DrawerOpener;

interface DragAndDropOverlayProps {
  isActive: boolean;
}

const DragAndDropOverlay: React.FC<DragAndDropOverlayProps> = ({ isActive }) => {
  if (!isActive) return null;
  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-20 z-10 backdrop-blur-[1px]">
      <div className="text-center px-4">
        <p className="font-semibold text-lg">Drag and drop files here</p>
        <p className="text-sm text-gray-600">We accept pdf and docx</p>
      </div>
    </div>
  );
};

export default DragAndDropOverlay;

import { ChevronDown } from "lucide-react";
import { ToolbarTooltip } from "../primitives/Tooltip";
import { useContext, useCallback } from "react";
import { ScaleContext } from "./text-editor/ScaleContext";
import { Select } from "../primitives/Select";
import { SCALE_OPTIONS } from "./constants";

export const ToolbarScale = () => {
  const { scale, setScale } = useContext(ScaleContext);

  const onScaleChange = useCallback(
    (value: string) => {
      setScale(parseFloat(value));
    },
    [setScale],
  );

  // Find the closest scale option to display, or show current scale if not matching preset
  const currentScaleValue =
    SCALE_OPTIONS.find((option) => Math.abs(parseFloat(option.value) - scale) < 0.01)?.value || scale.toString();

  return (
    <ToolbarTooltip content="Zoom">
      <div>
        <Select
          variant="subtle"
          value={currentScaleValue}
          initialValue="1"
          items={SCALE_OPTIONS}
          onChange={onScaleChange}
          customTrigger={
            <div className="flex items-center gap-1">
              <div className="text-xs font-semibold">{Math.trunc(scale * 100)}%</div>
              <ChevronDown size={12} />
            </div>
          }
        />
      </div>
    </ToolbarTooltip>
  );
};

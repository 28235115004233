/** @jsxImportSource @emotion/react */
import dots from "../../../Assets/3dots.svg";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import tw from "twin.macro";

interface DocumentMenuProps {
  menuItems: any[];
}

const DocumentMenu: React.FC<DocumentMenuProps> = ({ menuItems }) => (
  <div onClick={(e) => e.stopPropagation()}>
    <DropdownMenu triggerProps={{ css: tw`h-fit` }} items={menuItems}>
      <div className="cursor-pointer ml-auto min-w-[20px]">
        <img src={dots} alt="Three Dots" className="max-w-full mx-auto pointer-events-none object-contain" />
      </div>
    </DropdownMenu>
  </div>
);

export default DocumentMenu;

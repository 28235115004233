/** @jsxImportSource @emotion/react */

import type { CommentThread } from "../types";
import { CommentsStatusFilter, ThreadContext } from "../types";
import { CommentView } from "./CommentView";
import { CommentEditor } from "./CommentEditor/CommentEditor";
import { useCommentOperations } from "../../../api/comments/useCommentOperations";
import { useThread } from "./useThread";
import { CircleCheck, RotateCcw } from "lucide-react";
import { v4 } from "uuid";
import type { MutableRefObject } from "react";
import { useEffect, useRef, useState } from "react";
import Tooltip from "components/atoms/tooltip/Tooltip";
import type { Editor, useEditor } from "@tiptap/react";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ControlItemSlug } from "pages/draft-section/hooks";
import tw from "twin.macro";
import CommentOptions from "./CommentOptions";
import copyText from "utils/copyText";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { Button } from "components/yjs-editor/primitives/Button";
import { ReactionBar } from "./ReactionBar";
import { useAutoScroll } from "./useAutoScroll";
import { CommentCard } from "./CommentCard";
import { AssignMenu } from "./AssignMenu";
import { getThreadNotificationBaseUrl, getCurrentTab } from "../../../api/utils";
import { extractMentionedUserIds } from "../utils";
import { pluralizeWord } from "utils/string";

interface ThreadProps {
  editorId: string | undefined;
  thread: CommentThread;
  internalContractId: string;
  context: ThreadContext;
  editor?: Editor | null;
  isActiveThread?: boolean;
  referenceId?: string;
  activeCommentId?: string | null;
  statusFilter?: CommentsStatusFilter;
  setDisableScroll: React.Dispatch<React.SetStateAction<boolean>>;
  initialActiveThreadIdRef: MutableRefObject<string | null>;
  onContentChange?: () => void;
}

export const Thread = ({
  thread,
  context,
  internalContractId,
  referenceId,
  isActiveThread,
  editor,
  editorId,
  activeCommentId,
  statusFilter,
  setDisableScroll,
  initialActiveThreadIdRef,
  onContentChange,
}: ThreadProps) => {
  const [searchParams] = useSearchParams();
  const editorThread = useThread(editor, thread.id);
  const threadRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((store) => store.auth.currentUser);
  const isAssignedToCurrentUser = thread.assignee?.id === currentUser?.id;
  const assignedToUser = !isAssignedToCurrentUser ? thread.assignee?.username : undefined;
  const assignedToUserId = thread.assignee?.id;
  const [assignMenuOpen, setAssignMenuOpen] = useState(false);
  const isCommenter = currentUser?.id === thread.commenter_id;
  const navigate = useNavigate();
  const activeFromFocusRef = useRef(false);
  const isFocused = document.activeElement === threadRef.current;
  const {
    createCommentMutation,
    resolveThreadMutation,
    deleteThreadMutation,
    updateReactionMutation,
    updateReadMutation,
    updateThreadAssigneeMutation,
  } = useCommentOperations(internalContractId, referenceId);
  const unread = !thread.is_read;
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
  const [replyEditor, setReplyEditor] = useState<ReturnType<typeof useEditor> | null>(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const authorIsVultron = thread.comments[0].commenter.is_vultron;
  const commentsOpen = useAppSelector((state) => state.commentsDrawer.commentsDrawerOpen);
  const isFilterOpen = statusFilter === CommentsStatusFilter.Open;

  const [threadComment, ...otherComments] = thread.comments;
  const threadHasReactions = Object.keys(threadComment.reactions).length > 0;

  const MAX_VISIBLE_COMMENTS = 3;
  const [expandedComments, setExpandedComments] = useState(false);
  const hasHiddenComments = otherComments.length > MAX_VISIBLE_COMMENTS;
  const visibleComments = expandedComments ? otherComments : otherComments.slice(0, MAX_VISIBLE_COMMENTS);

  const handleThreadCommentReaction = (emoji: string) => {
    const userReactions = threadComment.reactions[currentUser?.id || ""];
    const remove = userReactions && userReactions.includes(emoji);
    updateReactionMutation.mutate({ comment_id: threadComment.id, emoji, remove });
  };

  const handleAssign = (userId: string | null, username: string | null, sendEmail = true) => {
    // return if user is already assigned
    if (userId === thread.assignee?.id) {
      setAssignMenuOpen(false);
      return;
    }
    updateThreadAssigneeMutation.mutate({
      thread_id: thread.id,
      assignee_id: userId,
      assignee_username: username,
      base_url: getThreadNotificationBaseUrl(),
      tab: getCurrentTab(),
      send_email: sendEmail,
    });
    setAssignMenuOpen(false);
  };

  // scroll to active thread
  useAutoScroll(threadRef, isActiveThread && commentsOpen, 100);

  const sectionId = editorId?.split("$")[0];
  const requirementId = editorId?.split("$")[1];
  const handleFocus = () => {
    if (context === ThreadContext.DRAFT) {
      navigate(`sections/${sectionId}/${ControlItemSlug.requirements}?${searchParams.toString()}`);
      if (requirementId) dispatch(setRequirementsState({ expandedRequirementIds: [requirementId] }));
    }
    editorThread?.domNode.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
    if (isFilterOpen && commentsOpen) {
      editor?.commands.setActiveComment(thread.id);
      activeFromFocusRef.current = true;
    }
    // If the thread is unread, mark it as read
    if (unread) {
      updateReadMutation.mutate({ thread_id: thread.id, read: true });
    }
  };

  const handleResolve = () => {
    resolveThreadMutation.mutate({
      thread_id: thread.id,
      editor,
      resolved: !thread.resolved,
    });
  };

  const handleReply = (content: string, mentions: string[]) => {
    createCommentMutation.mutate({
      content,
      thread_id: thread.id,
      new_comment_id: v4(),
      context,
      mentions,
      base_url: getThreadNotificationBaseUrl(),
      tab: getCurrentTab(),
    });
    return true;
  };

  const handleSubmitReply = () => {
    if (replyEditor && !replyEditor.isEmpty) {
      // Extract mentioned user IDs from the document
      const mentionedUserIds = replyEditor.state.doc ? extractMentionedUserIds(replyEditor.state.doc) : [];

      handleReply(replyEditor.getHTML(), mentionedUserIds);
      replyEditor.commands.setContent("");
      setIsEmpty(true);
    }
  };

  // Notify DocumentComments when thread size changes
  useEffect(() => {
    if (!threadRef.current || !onContentChange) return;

    const resizeObserver = new ResizeObserver(() => {
      onContentChange();
    });

    resizeObserver.observe(threadRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [onContentChange]);

  useEffect(() => {
    // set the active comment in the editor when the thread is focused
    if (!editor || !isFocused) return;
    if (isFilterOpen) {
      editor.commands.setActiveComment(thread.id);
    }
  }, [editor, thread.id, isFocused, statusFilter]);

  useEffect(() => {
    if (!initialActiveThreadIdRef.current) return;
    if (thread.id === initialActiveThreadIdRef.current) {
      threadRef.current?.focus();
      initialActiveThreadIdRef.current = null;
    }
  }, []);

  const repliesLeftCount = otherComments.length - MAX_VISIBLE_COMMENTS;

  return (
    <div className="p-[1px] rounded-md" css={[isActiveThread ? tw`bg-gray-darkest` : tw`bg-transparent`]}>
      {isAssignedToCurrentUser && (
        <div className="px-3.5 py-1.5 bg-gray-200 rounded-t-md">
          <div className="text-sm font-medium">Assigned to you</div>
        </div>
      )}
      <CommentCard
        tabIndex={-1}
        onFocus={handleFocus}
        ref={threadRef}
        className="hover:bg-gray-50 focus:bg-gray-50 focus-within:bg-gray-50"
        css={[
          isActiveThread ? tw`bg-gray-50` : tw`bg-white`,
          isAssignedToCurrentUser ? tw`rounded-t-none rounded-b-md` : null,
        ]}
      >
        {unread && (
          <div className="absolute top-2 left-2">
            <div className="w-2 h-2 bg-[#E8C969] rounded-full" />
          </div>
        )}

        <div
          className="absolute top-2 right-2 flex flex-row gap-0.5 items-center text-gray-light opacity-0 group-hover:opacity-100 z-10"
          onFocus={(e) => e.stopPropagation()}
          data-interactive="true"
        >
          {!threadHasReactions && (
            <ReactionBar
              reactions={threadComment.reactions}
              onReaction={handleThreadCommentReaction}
              setDisableScroll={setDisableScroll}
            />
          )}
          <Tooltip content={`${thread.resolved ? "Reopen" : "Resolve"} thread`}>
            <button
              onClick={handleResolve}
              onMouseDown={(e) => e.preventDefault()}
              className="duration-100 p-1 rounded-md hover:bg-gray-200 hover:text-gray-darkest"
              disabled={!editor}
            >
              {thread.resolved ? <RotateCcw size={18} /> : <CircleCheck size={18} />}
            </button>
          </Tooltip>
          <CommentOptions
            isThread
            authorIsVultron={authorIsVultron}
            isCommenter={isCommenter}
            onEdit={() => {
              setEditingCommentId(threadComment.id);
            }}
            onDelete={() => {
              triggerConfirm({
                header: `Are you sure you want to delete this thread?`,
                body: "This action is irreversible and will delete all comments within this thread.",
              }).then((proceed) => {
                if (proceed) {
                  deleteThreadMutation.mutate({ editor, thread_id: thread.id });
                }
              });
            }}
            onCopyLink={() => {
              const url = new URL(window.location.href);
              url.searchParams.set("threadId", thread.id);
              copyText(url.href);
            }}
            onAssign={() => setAssignMenuOpen(true)}
            hasAssignee={!!thread.assignee?.id}
          />
          <AssignMenu
            open={assignMenuOpen}
            onClose={() => setAssignMenuOpen(false)}
            onAssign={handleAssign}
            currentAssigneeId={thread.assignee?.id}
          />
        </div>

        <CommentView
          comment={threadComment}
          isEditing={editingCommentId === threadComment.id}
          quoteText={thread.quote_text}
          setEditingCommentId={setEditingCommentId}
          threadId={thread.id}
          resolved={thread.resolved}
          key={threadComment.id}
          internalContractId={internalContractId}
          referenceId={referenceId}
          isActive={activeCommentId === threadComment.id}
          isLastComment={!otherComments.length}
          setDisableScroll={setDisableScroll}
          assignedToUser={assignedToUser}
          assignedToUserId={assignedToUserId}
        />

        {visibleComments?.map((comment, idx) => (
          <CommentView
            comment={comment}
            isEditing={editingCommentId === comment.id}
            quoteText={thread.quote_text}
            setEditingCommentId={setEditingCommentId}
            threadId={thread.id}
            resolved={thread.resolved}
            key={comment.id}
            internalContractId={internalContractId}
            referenceId={referenceId}
            isActive={activeCommentId === comment.id}
            setDisableScroll={setDisableScroll}
            isLastComment={idx === visibleComments.length - 1 && (!hasHiddenComments || expandedComments)}
            showCommentOptions
          />
        ))}

        {hasHiddenComments && (
          <button
            onClick={() => setExpandedComments(!expandedComments)}
            className="text-sm text-gray-600 hover:text-gray-900 py-1 flex items-center gap-1.5 mt-1"
            onFocus={(e) => e.stopPropagation()}
            data-interactive="true"
            type="button"
          >
            {expandedComments
              ? "Show less replies"
              : `${repliesLeftCount} more ${repliesLeftCount === 1 ? "reply" : "replies"}`}
          </button>
        )}

        {!thread.resolved && !editingCommentId && (
          <div className="flex flex-col gap-2 max-w-full">
            <div className="flex items-center gap-2">
              <div className="flex-grow min-w-0">
                <div className="border-[0.5px] border-[#D0D0D0] rounded-md p-1">
                  <CommentEditor
                    readonly={false}
                    editorRef={setReplyEditor}
                    onUpdate={setIsEmpty}
                    placeholder="Reply..."
                    onAddComment={handleReply}
                  />
                </div>
              </div>
            </div>
            {!isEmpty && (
              <div className="flex justify-end">
                <Button variant="thread" onClick={handleSubmitReply}>
                  Reply
                </Button>
              </div>
            )}
          </div>
        )}
      </CommentCard>
    </div>
  );
};

export const styles = {
  item: "py-2 text-sm px-3 pl-2 cursor-pointer hover:bg-[#F6F6F6] outline-none flex items-center justify-between gap-2 word-break-[break-word]",
  itemTrigger:
    "py-2 px-3 pl-2 cursor-default hover:bg-[#F6F6F6] outline-none flex items-center justify-between gap-2 word-break-[break-word]",
  checkboxItem: "py-1.5 px-3 pl-2 cursor-pointer outline-none flex items-center gap-2 word-break-[break-word]",
  content:
    "relative text-stone-900 text-sm font-normal z-[99] min-w-[140px] max-w-[350px] max-h-[375px] overflow-y-auto shadow rounded-md bg-white border border-zinc-200 flex flex-col p-1",
  subtrigger: "!outline-0",
  trigger: "!outline-0 select-none group disabled:pointer-events-none disabled:duration-0",
  chevronRight: "ml-auto pl-5",
  subcontent:
    "z-[100] min-w-[140px] max-w-[350px] max-h-[50vh] my-1 overflow-auto shadow rounded-md bg-white border border-zinc-200 flex flex-col gap-1 p-1",
};

export const nestedMenuStyles = {
  item: "!outline-0",
  content: "z-20 min-w-[200px] max-w-[350px] shadow rounded-md bg-white border border-zinc-200 flex flex-col gap-1 p-1",
  subtrigger: "!outline-0",
  chevronRight: "ml-auto pl-5",
  subcontent:
    "z-[100] min-w-[200px] max-w-[350px] shadow rounded-md bg-white border border-zinc-200 flex flex-col gap-1 p-1",
};

/** @jsxImportSource @emotion/react */

import type { Interpolation, Theme } from "@emotion/react";
import type { HTMLAttributes, ReactNode } from "react";
import tw from "twin.macro";

export type Tab<T> = {
  name: ReactNode;
  slug: T;
  icon?: ReactNode;
  component?: ReactNode;
  empty?: ReactNode;
  emptyAction?: ReactNode;
  styles?: Interpolation<Theme>;
};

interface Props<T> extends HTMLAttributes<HTMLDivElement> {
  activeTab: Tab<T>;
  tabs: Tab<T>[];
  onTabSelect: (tab: Tab<T>) => void;
}

const Tabs = <T extends string | number>({ activeTab, tabs, onTabSelect, ...props }: Props<T>) => {
  return (
    <div tw="flex gap-4 border-b border-[#E7EBEE] max-w-full" {...props}>
      {tabs.map((tab) => (
        <div
          css={[
            tw`text-sm mr-1 font-normal border-b items-center flex gap-2 text-gray-text cursor-pointer border-transparent pt-3.5 pb-2.5 hover:border-gray-darkest`,
            activeTab.slug === tab.slug && tw`border-gray-darkest text-gray-darkest`,
            tab.styles,
          ]}
          key={tab.slug}
          onClick={() => onTabSelect(tab)}
        >
          {tab.icon}
          {tab?.name}
        </div>
      ))}
    </div>
  );
};

export default Tabs;

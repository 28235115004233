import type { ComponentProps } from "react";

export function ImageIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{ width: "18px", height: "18px" }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.085 10.3333L12.6847 7.93311C12.393 7.64149 11.9974 7.47767 11.585 7.47767C11.1725 7.47767 10.7769 7.64149 10.4852 7.93311L3.41829 15M2.64052 1H13.5294C14.3885 1 15.085 1.69645 15.085 2.55556V13.4444C15.085 14.3036 14.3885 15 13.5294 15H2.64052C1.78141 15 1.08496 14.3036 1.08496 13.4444V2.55556C1.08496 1.69645 1.78141 1 2.64052 1ZM7.30718 5.66667C7.30718 6.52578 6.61074 7.22222 5.75163 7.22222C4.89252 7.22222 4.19607 6.52578 4.19607 5.66667C4.19607 4.80756 4.89252 4.11111 5.75163 4.11111C6.61074 4.11111 7.30718 4.80756 7.30718 5.66667Z"
        stroke="#1E1E1E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

// Imports
import type { FC, ForwardRefExoticComponent, RefAttributes } from "react";
import { ReactComponent as Note } from "Assets/note-2.svg";
import type { LucideProps } from "lucide-react";
import {
  ArrowDownWideNarrow,
  FileScan,
  NotebookPen,
  PencilLine,
  Search,
  PencilRuler,
  FileMinus2,
  ListMinus,
  MicVocal,
  SpellCheck,
  AudioLines,
  List,
  Table,
  MessageCircleQuestion,
} from "lucide-react";

// Enums
export enum WorkflowSub {
  ContentGapAnalysis = "content_gap_analysis",
  Summarize = "summarize",
  SummarizeSolicitation = "summarize_solicitation",
  SummarizeMeetingNotes = "summarize_meeting_notes",
  SummarizeCustomerIntelligence = "summarize_customer_intelligence",
  Analyze = "analyze",
  AnalyzeSolicitation = "analyze_solicitation",
  AnalyzeMeetingNotes = "analyze_meeting_notes",
  AnalyzeCustomerIntelligence = "analyze_customer_intelligence",
  Report = "report",
  ReportSolicitation = "report_solicitation",
  ReportMeetingNotes = "report_meeting_notes",
  ReportCustomerIntelligence = "report_customer_intelligence",
  Shred = "shred",
  ExtractOutline = "extract_outline",
  Revise = "revise",
  ReviseFixSpellingGrammar = "fix_spelling_grammar",
  ReviseActiveVoice = "active_voice",
  ReviseRemoveWords = "remove_words",
  ReviseMakeShorter = "make_shorter",
  ReviseProfessionalTone = "professional_tone",
  ReviseCreateList = "create_list",
  ReviseCreateTable = "create_table",
  ReviseConciseTone = "concise_tone",
  RevisePersuasiveTone = "persuasive_tone",
  ReviseTechnicalTone = "technical_tone",
  ReviseAcademicTone = "academic_tone",
  ReviseCustomerFocusedTone = "customer_focused_tone",
  Draft = "draft",
  HighlightDraft = "highlight_draft",
  Questionnaire = "questionnaire",
  QuestionnaireCapture = "questionnaire_capture",
  Search = "search",
  SearchCapability = "search_capability",
  SearchExperience = "search_experience",
  ScoreRequirementResponse = "score_requirement_response",
  TechnicalMatrix = "technical_matrix",
  RFIDraft = "rfi_draft",
  ComplianceScoreMatrix = "compliance_score_matrix",
  CapabilityMatrix = "capability_matrix",
  Translate = "translate",
}

export enum Source {
  ContentDrive = 1,
  Internet,
}

export enum WorkflowNameType {
  ContentGapAnalysis = "content_gap_analysis",
  Draft = "draft",
  HighlightDraft = "highlight_draft",
  Revise = "revise",
  Summarize = "summarize",
  Analyze = "analyze",
  Report = "report",
  Shred = "shred",
  ExtractOutline = "extract_outline",
  Search = "search",
  Questionnaire = "questionnaire",
  ScoreRequirementResponse = "score_requirement_response",
  TechnicalMatrix = "technical_matrix",
  RFIDraft = "rfi_draft",
  ComplianceScoreMatrix = "compliance_score_matrix",
  CapabilityMatrix = "capability_matrix",
  Translate = "translate",
}

// Types
export type WorkflowOptionType = "Summarize" | "Analyze" | "Search" | "Create" | "Draft" | "Review";

export interface Workflow {
  name: WorkflowNameType;
  subType: WorkflowSub;
  label: string;
  description: string;
  color: string;
  icon: ForwardRefExoticComponent<Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement>>;
  teams: TeamName[];
  type: WorkflowOptionType;
  featured?: boolean;
  top?: boolean;
}

// Constant Options
export const TYPE_OPTIONS: WorkflowOptionType[] = ["Summarize", "Analyze", "Search", "Create", "Draft", "Review"];

export const SOURCE_TO_META: Record<Source, { copy: string; searchPlaceholder?: string; icon: FC; tooltip?: string }> =
  {
    [Source.Internet]: {
      copy: "Internet",
      icon: Note,
      tooltip:
        "Search the entire internet, a full website, or a specific link. Please indicate how to use the link, when provided, such as whether to use the full website or only the specific link.",
    },
    [Source.ContentDrive]: {
      searchPlaceholder: "Search content library documents...",
      copy: "Content Library",
      icon: Note,
    },
  };

export const typeOptions = [
  { name: "Search", color: "#F6EAB9" },
  { name: "Summarize", color: "#C7D2FE" },
  { name: "Analyze", color: "#FFE4E6" },
  { name: "Create", color: "#FED7AA" },
  { name: "Draft", color: "#BAE6FD" },
  { name: "Review", color: "#B9F8CF" },
] as const;

export const ALL_TEAM_OPTIONS = [
  "Proposal",
  "Capture",
  "Business Development",
  "Technical Writing",
  "Solutions Architect",
  "Program Management",
  "Operations",
] as const;

export const WORKFLOW_TO_META: Record<
  WorkflowNameType,
  { icon: ForwardRefExoticComponent<Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement>>; color: string }
> = {
  [WorkflowNameType.Draft]: { icon: PencilLine, color: "#93C5FD" },
  [WorkflowNameType.HighlightDraft]: { icon: PencilLine, color: "#93C5FD" },
  [WorkflowNameType.Revise]: { icon: PencilRuler, color: "#A5B4FC" },
  [WorkflowNameType.Summarize]: { icon: ArrowDownWideNarrow, color: "#C7D2FE" },
  [WorkflowNameType.Report]: { icon: NotebookPen, color: "#C7D2FE" },
  [WorkflowNameType.Shred]: { icon: NotebookPen, color: "#C7D2FE" },
  [WorkflowNameType.Translate]: { icon: NotebookPen, color: "#FED7AA" },
  [WorkflowNameType.Analyze]: { icon: NotebookPen, color: "#FFE4E6" },
  [WorkflowNameType.ExtractOutline]: { icon: FileScan, color: "#22c55e" },
  [WorkflowNameType.Search]: { icon: Search, color: "#000080" },
  [WorkflowNameType.ScoreRequirementResponse]: { icon: NotebookPen, color: "#FFA500" },
  [WorkflowNameType.Questionnaire]: { icon: MessageCircleQuestion, color: "#FFA500" },
  [WorkflowNameType.ContentGapAnalysis]: { icon: Search, color: "#F6EAB9" },
  [WorkflowNameType.TechnicalMatrix]: { icon: PencilLine, color: "#BAE6FD" },
  [WorkflowNameType.ComplianceScoreMatrix]: { icon: PencilLine, color: "#BAE6FD" },
  [WorkflowNameType.CapabilityMatrix]: { icon: PencilLine, color: "#BAE6FD" },
  [WorkflowNameType.RFIDraft]: { icon: PencilLine, color: "#BAE6FD" },
} as const;

// Workflow Definitions
export const REVISE_WORKFLOW: Workflow = {
  name: WorkflowNameType.Revise,
  subType: WorkflowSub.Revise,
  label: "Revise Content",
  description: "Refine content to improve grammar, style, quality, etc.",
  color: WORKFLOW_TO_META[WorkflowNameType.Revise].color,
  icon: WORKFLOW_TO_META[WorkflowNameType.Revise].icon,
  teams: ["Proposal"],
  type: "Draft",
};

// This must be outside of the WORKFLOW_OPTIONS, since it is project specific
export const HIGHLIGHT_DRAFT_WORKFLOW: Workflow = {
  name: WorkflowNameType.HighlightDraft,
  subType: WorkflowSub.HighlightDraft,
  label: "Draft Proposal Section",
  description: "Draft a proposal response tailored to a specific solicitation requirement or section.",
  color: WORKFLOW_TO_META[WorkflowNameType.Draft].color,
  icon: WORKFLOW_TO_META[WorkflowNameType.Draft].icon,
  teams: ["Proposal", "Solutions Architect", "Technical Writing"],
  type: "Draft",
};

export const WORKFLOW_OPTIONS: Workflow[] = [
  // Summarize Workflows
  {
    name: WorkflowNameType.Summarize,
    subType: WorkflowSub.Summarize,
    label: "Summarize Documents",
    description: "Summarize key topics, themes, and insights from documents into a concise overview.",
    color: "text-indigo-400",
    icon: ArrowDownWideNarrow,
    teams: [...ALL_TEAM_OPTIONS],
    type: "Summarize",
  },
  {
    name: WorkflowNameType.Summarize,
    subType: WorkflowSub.SummarizeSolicitation,
    label: "Summarize Solicitation",
    description:
      "Organize instructions, technical requirements, evaluation criteria, and additional information from a solicitation.",
    color: "text-indigo-400",
    icon: ArrowDownWideNarrow,
    teams: ["Business Development", "Proposal", "Capture"],
    type: "Summarize",
  },
  {
    name: WorkflowNameType.Summarize,
    subType: WorkflowSub.SummarizeMeetingNotes,
    label: "Meeting Summary",
    description: "Generate a concise overview of meeting highlights with actionable next steps.",
    color: "text-indigo-400",
    icon: ArrowDownWideNarrow,
    teams: [...ALL_TEAM_OPTIONS],
    type: "Summarize",
  },
  {
    name: WorkflowNameType.Summarize,
    subType: WorkflowSub.SummarizeCustomerIntelligence,
    label: "Customer Insights",
    description: "Organize customer preferences, priorities, and challenges into actionable insights.",
    color: "text-indigo-400",
    icon: ArrowDownWideNarrow,
    teams: ["Business Development", "Proposal", "Capture", "Solutions Architect"],
    type: "Summarize",
  },

  // Analyze Workflows
  {
    name: WorkflowNameType.Analyze,
    subType: WorkflowSub.Analyze,
    label: "Document Analysis",
    description: "Generate a comprehensive assessment highlighting key topics, insights, and patterns.",
    color: "text-teal-400",
    icon: FileScan,
    teams: ["Proposal", "Capture", "Business Development", "Solutions Architect"],
    type: "Analyze",
    featured: true,
    top: true,
  },
  {
    name: WorkflowNameType.Analyze,
    subType: WorkflowSub.AnalyzeSolicitation,
    label: "Analyze Solicitation",
    description: "Identify key objectives, requirements, instructions, and evaluation criteria from a solicitation.",
    color: "text-teal-400",
    icon: FileScan,
    teams: ["Business Development", "Proposal", "Capture"],
    type: "Analyze",
  },
  {
    name: WorkflowNameType.Analyze,
    subType: WorkflowSub.AnalyzeMeetingNotes,
    label: "Meeting Insights & Outcomes",
    description: "Capture key takeaways, actionable insights, and decisions from the meeting into strategic outcomes.",
    color: "text-teal-400",
    icon: FileScan,
    teams: [...ALL_TEAM_OPTIONS],
    type: "Analyze",
  },
  {
    name: WorkflowNameType.Analyze,
    subType: WorkflowSub.AnalyzeCustomerIntelligence,
    label: "Customer Intelligence Analysis",
    description: "Analyze customer intelligence to surface pain points, key drivers, and strategic opportunities.",
    color: "text-teal-400",
    icon: FileScan,
    teams: ["Business Development", "Capture", "Proposal"],
    type: "Analyze",
  },

  // Create Report Workflows
  {
    name: WorkflowNameType.Report,
    subType: WorkflowSub.Report,
    label: "Create Report",
    description: "Generate a comprehensive report based on provided context.",
    color: "text-yellow-400",
    icon: NotebookPen,
    teams: [...ALL_TEAM_OPTIONS],
    type: "Create",
  },
  {
    name: WorkflowNameType.Report,
    subType: WorkflowSub.ReportSolicitation,
    label: "Full Solicitation Report",
    description:
      "Produce a comprehensive solicitation report containing key objectives, requirements, and other critical information.",
    color: "text-yellow-400",
    icon: NotebookPen,
    teams: ["Business Development", "Proposal", "Capture"],
    type: "Create",
  },
  {
    name: WorkflowNameType.Report,
    subType: WorkflowSub.ReportCustomerIntelligence,
    label: "Business Intelligence Report",
    description:
      "Create business intelligence reports capturing customer trends, competitive insights, and market developments.",
    color: "text-yellow-400",
    icon: NotebookPen,
    teams: ["Business Development", "Capture"],
    type: "Create",
  },

  // Create Workflows (Shred & Extract Outline)
  {
    name: WorkflowNameType.Shred,
    subType: WorkflowSub.Shred,
    label: "Shred Documents",
    description: "Shred documents using keywords (e.g., shall, will, must).",
    color: "text-red-400",
    icon: FileScan,
    teams: ["Proposal", "Capture", "Business Development", "Program Management"],
    type: "Create",
    featured: true,
    top: true,
  },
  {
    name: WorkflowNameType.ExtractOutline,
    subType: WorkflowSub.ExtractOutline,
    label: "Extract Proposal Outline",
    description: "Extract a base proposal outline from a solicitation.",
    color: WORKFLOW_TO_META[WorkflowNameType.ExtractOutline].color,
    icon: WORKFLOW_TO_META[WorkflowNameType.ExtractOutline].icon,
    teams: ["Proposal", "Capture"],
    type: "Create",
  },

  // Draft Workflows
  REVISE_WORKFLOW,
  {
    name: WorkflowNameType.Draft,
    subType: WorkflowSub.Draft,
    label: "Draft Proposal Section",
    description: "Draft a proposal response tailored to a specific solicitation requirement or section.",
    color: WORKFLOW_TO_META[WorkflowNameType.Draft].color,
    icon: WORKFLOW_TO_META[WorkflowNameType.Draft].icon,
    teams: ["Proposal", "Solutions Architect", "Technical Writing"],
    type: "Draft",
    featured: true,
    top: true,
  },
  {
    name: WorkflowNameType.TechnicalMatrix,
    subType: WorkflowSub.TechnicalMatrix,
    label: "PWS Response Matrix",
    description: "Shred PWS requirements and generate draft responses in a structured requirements matrix.",
    color: WORKFLOW_TO_META[WorkflowNameType.Draft].color,
    icon: WORKFLOW_TO_META[WorkflowNameType.Draft].icon,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.ComplianceScoreMatrix,
    subType: WorkflowSub.ComplianceScoreMatrix,
    label: "PWS Compliance Score Matrix",
    description: "Score proposal content against the PWS/SOW to assess compliance and gaps.",
    color: WORKFLOW_TO_META[WorkflowNameType.Draft].color,
    icon: WORKFLOW_TO_META[WorkflowNameType.Draft].icon,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.CapabilityMatrix,
    subType: WorkflowSub.CapabilityMatrix,
    label: "Capability Matrix",
    description: "Compare past performances and capabilities against the PWS/SOW to assess gaps.",
    color: WORKFLOW_TO_META[WorkflowNameType.Draft].color,
    icon: WORKFLOW_TO_META[WorkflowNameType.Draft].icon,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.RFIDraft,
    subType: WorkflowSub.RFIDraft,
    label: "RFI Initial Draft",
    description: "Automatically generate an initial RFI draft.",
    color: WORKFLOW_TO_META[WorkflowNameType.Draft].color,
    icon: WORKFLOW_TO_META[WorkflowNameType.Draft].icon,
    teams: ["Proposal"],
    type: "Draft",
  },
  // Search Workflows
  {
    name: WorkflowNameType.Search,
    subType: WorkflowSub.Search,
    label: "Search Content Library",
    description: "Search content library to find specific content.",
    color: "text-blue-400",
    icon: Search,
    teams: ["Proposal", "Business Development"],
    type: "Search",
  },
  {
    name: WorkflowNameType.Search,
    subType: WorkflowSub.SearchCapability,
    label: "Search Capabilities",
    description: "Search the content library to find organizational capabilities.",
    color: WORKFLOW_TO_META[WorkflowNameType.Search].color,
    icon: WORKFLOW_TO_META[WorkflowNameType.Search].icon,
    teams: ["Proposal", "Business Development"],
    type: "Search",
    featured: true,
    top: true,
  },
  {
    name: WorkflowNameType.Search,
    subType: WorkflowSub.SearchExperience,
    label: "Search Experiences",
    description: "Search the content library to locate specific experiences.",
    color: "text-blue-400",
    icon: Search,
    teams: ["Proposal", "Business Development"],
    type: "Search",
  },
  {
    name: WorkflowNameType.ContentGapAnalysis,
    subType: WorkflowSub.ContentGapAnalysis,
    label: "Content Gap Analysis",
    description: "Identify gaps in the content library.",
    color: "text-blue-400",
    icon: Search,
    teams: ["Proposal", "Business Development"],
    type: "Search",
  },
  {
    name: WorkflowNameType.Questionnaire,
    subType: WorkflowSub.QuestionnaireCapture,
    label: "Capture Briefing",
    description:
      "Highlight opportunity details, competitive positioning, and strategic proposal approach to guide the pursuit team.",
    color: "text-blue-400",
    icon: WORKFLOW_TO_META[WorkflowNameType.Questionnaire].icon,
    teams: ["Proposal", "Business Development"],
    type: "Search",
  },
  {
    name: WorkflowNameType.Translate,
    subType: WorkflowSub.Translate,
    label: "Translate",
    description: "Translate content from one language to another.",
    color: "text-blue-400",
    icon: NotebookPen,
    teams: ["Proposal", "Business Development"],
    type: "Create",
  },
  {
    name: WorkflowNameType.Questionnaire,
    subType: WorkflowSub.Questionnaire,
    label: "Answer Questions",
    description: "Search the content library to answer one or more questions.",
    color: "text-blue-400",
    icon: WORKFLOW_TO_META[WorkflowNameType.Questionnaire].icon,
    teams: ["Proposal", "Business Development"],
    type: "Search",
  },
  // Review Workflows
  {
    name: WorkflowNameType.ScoreRequirementResponse,
    subType: WorkflowSub.ScoreRequirementResponse,
    label: "Score Requirement Response",
    description: "Assess and score a response to a requirement with actionable feedback for improvement.",
    color: WORKFLOW_TO_META[WorkflowNameType.ScoreRequirementResponse].color,
    icon: WORKFLOW_TO_META[WorkflowNameType.ScoreRequirementResponse].icon,
    teams: ["Proposal"],
    type: "Review",
  },
] as const;

export const REVISE_WORKFLOW_OPTIONS: Workflow[] = [
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.ReviseFixSpellingGrammar,
    label: "Fix Grammar",
    description: "Correct spelling and grammar in the selected text.",
    color: "text-teal-400",
    icon: SpellCheck,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.ReviseActiveVoice,
    label: "Active Voice",
    description: "Apply active voice to the selected text.",
    color: "text-teal-400",
    icon: MicVocal,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.ReviseRemoveWords,
    label: "Remove Words",
    description: "Remove words from the selected text.",
    color: "text-teal-400",
    icon: FileMinus2,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.ReviseMakeShorter,
    label: "Make Shorter",
    description: "Reduce the word count of the selected text.",
    color: "text-teal-400",
    icon: ListMinus,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.ReviseCreateList,
    label: "Create List",
    description: "Convert the selected text into a list.",
    color: "text-teal-400",
    icon: List,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.ReviseCreateTable,
    label: "Create Table",
    description: "Convert the selected text into a table.",
    color: "text-teal-400",
    icon: Table,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    ...REVISE_WORKFLOW,
    label: "Apply Custom",
    icon: NotebookPen,
  },
] as const;

export const TONE_WORKFLOW_OPTIONS: Workflow[] = [
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.ReviseProfessionalTone,
    label: "Professional",
    description: "Apply Professional Tone.",
    color: "text-teal-400",
    icon: AudioLines,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.ReviseConciseTone,
    label: "Concise",
    description: "Apply Concise Tone.",
    color: "text-teal-400",
    icon: AudioLines,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.RevisePersuasiveTone,
    label: "Persuasive",
    description: "Apply Persuasive Tone.",
    color: "text-teal-400",
    icon: AudioLines,
    teams: ["Proposal"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.ReviseTechnicalTone,
    label: "Technical",
    description: "Apply Technical Tone.",
    color: "text-teal-400",
    icon: AudioLines,
    teams: ["Proposal"],
    type: "Draft",
  },
] as const;

export const ADDITIONAL_TONE_WORKFLOW_OPTIONS: Workflow[] = [
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.ReviseAcademicTone,
    label: "Academic",
    description: "Apply Academic Tone.",
    color: "text-teal-400",
    icon: AudioLines,
    teams: ["Proposal", "Technical Writing", "Solutions Architect"],
    type: "Draft",
  },
  {
    name: WorkflowNameType.Revise,
    subType: WorkflowSub.ReviseCustomerFocusedTone,
    label: "Customer Focused",
    description: "Apply Customer Focused Tone.",
    color: "text-teal-400",
    icon: AudioLines,
    teams: ["Proposal", "Technical Writing"],
    type: "Draft",
  },
];

export const ALL_REVISE_WORKFLOW_OPTIONS = [
  REVISE_WORKFLOW,
  ...REVISE_WORKFLOW_OPTIONS,
  ...TONE_WORKFLOW_OPTIONS,
  ...ADDITIONAL_TONE_WORKFLOW_OPTIONS,
];

// Exported Types
export type WorkflowName = (typeof WORKFLOW_OPTIONS)[number]["name"];
export type WorkflowLabel = (typeof WORKFLOW_OPTIONS)[number]["label"];
export type WorkflowSubType = (typeof WORKFLOW_OPTIONS)[number]["subType"];
export type TeamName = (typeof ALL_TEAM_OPTIONS)[number];

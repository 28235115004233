import { Paragraph } from "@tiptap/extension-paragraph";
import { Document } from "@tiptap/extension-document";
import { DocumentMarginNode } from "./document-margin/DocumentMargin";
import type { YJSProvider } from "YJSProvider/YJSProvider";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import type * as Y from "yjs";
import { CommentsMark } from "components/Comments";
import { SHARED_EXTENSIONS } from "components/copilot/MarkdownEditor";
import UnfocusedSelectionExtension from "./UnfocusedSelection";
import Link from "@tiptap/extension-link";
import { LineSpacingExtension } from "./LineSpacingExtension";
import { IndentExtension } from "./IndentExtension";

export const getExtensions = ({
  provider,
  doc,
  user,
  removeThread,
  restoreThread,
}: {
  provider?: YJSProvider;
  doc: Y.Doc;
  user: Record<string, any>;
  removeThread: (threadId: string) => void;
  restoreThread: (threadId: string) => void;
}) => [
  ...SHARED_EXTENSIONS,
  UnfocusedSelectionExtension,
  Document.extend({
    content: "documentmargin",
  }),
  IndentExtension,
  Link.configure({
    openOnClick: false,
    HTMLAttributes: {
      class: "text-blue-600 underline hover:text-blue-800 hover:cursor-pointer",
    },
  }).extend({
    name: "a",
  }),
  LineSpacingExtension,
  Paragraph.configure({
    HTMLAttributes: {
      class: "tiptap-paragraph",
    },
  }),
  DocumentMarginNode,
  CommentsMark.configure({
    provider,
    removeThread,
    restoreThread,
  }),
  Collaboration.configure({
    document: doc,
  }),
  ...(provider
    ? [
        CollaborationCursor.configure({
          provider: provider,
          user,
        }),
      ]
    : []),
];

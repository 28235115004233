import { useEffect, useLayoutEffect, useRef, useState } from "react";
import type { Editor } from "@tiptap/react";
import { CheckIcon } from "components/yjs-editor/icons/Check";
import styles from "../primitives/Select/Select.module.css";
import ClickAwayListener from "helpers/ClickAwayListener";
import { ChevronDown } from "lucide-react";
import { ToolbarTooltip } from "../primitives/Tooltip";
import { Portal } from "@radix-ui/react-portal";

interface HeadingOption {
  value: "p" | "h1" | "h2" | "h3";
  label: string;
  styles: string;
}

const headingOptions: HeadingOption[] = [
  {
    value: "p",
    label: "Normal text",
    styles: "text-sm font-normal",
  },
  {
    value: "h1",
    label: "Heading 1",
    styles: "text-xl font-semibold",
  },
  {
    value: "h2",
    label: "Heading 2",
    styles: "text-lg font-semibold",
  },
  {
    value: "h3",
    label: "Heading 3",
    styles: "text-base font-semibold",
  },
];

const triggerBaseStyles = "flex items-center gap-2 p-1 mx-1 rounded";
const triggerVariants = {
  bubble: "text-[#F4F4F5] hover:bg-[#404040] w-30",
  default: `${styles.trigger} ${styles.triggerSubtle} w-[110px]`,
};

const dropdownBaseStyles = `${styles.select} absolute w-44`;
const dropdownVariants = {
  bubble: "bg-[#171717] border-[#404040] mt-2",
  default: "",
};

const itemBaseStyles = `${styles.item}`;
const itemVariants = {
  bubble: "text-[#F4F4F5] hover:bg-[#404040]",
  default: "hover:bg-[var(--color-surface)] hover:text-[var(--color-text)]",
};

export const ToolbarHeadings = ({
  editor,
  variant = "default",
}: {
  editor: Editor;
  variant?: "default" | "bubble";
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentHeading, setCurrentHeading] = useState<HeadingOption["value"]>("p");
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef<HTMLButtonElement>(null);
  const isBubble = variant === "bubble";

  useLayoutEffect(() => {
    if (isOpen && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + 4,
        left: rect.left,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (!editor) return;

    const updateHeading = () => {
      if (editor.isActive("heading", { level: 1 })) {
        setCurrentHeading("h1");
      } else if (editor.isActive("heading", { level: 2 })) {
        setCurrentHeading("h2");
      } else if (editor.isActive("heading", { level: 3 })) {
        setCurrentHeading("h3");
      } else {
        setCurrentHeading("p");
      }
    };

    updateHeading();
    editor.on("selectionUpdate", updateHeading);
    editor.on("update", updateHeading);

    return () => {
      editor.off("selectionUpdate", updateHeading);
      editor.off("update", updateHeading);
    };
  }, [editor]);

  const handleHeadingChange = (value: HeadingOption["value"]) => {
    if (!editor) return;

    const { $from } = editor.state.selection;
    const pos = $from.start($from.depth);
    const node = $from.node($from.depth);

    editor.commands.setTextSelection({
      from: pos,
      to: pos + node.nodeSize - 1,
    });

    switch (value) {
      case "p":
        editor.chain().focus().setParagraph().unsetFontSize().run();
        break;
      case "h1":
        editor.chain().focus().setHeading({ level: 1 }).unsetFontSize().run();
        break;
      case "h2":
        editor.chain().focus().setHeading({ level: 2 }).unsetFontSize().run();
        break;
      case "h3":
        editor.chain().focus().setHeading({ level: 3 }).unsetFontSize().run();
        break;
    }

    editor.commands.setTextSelection($from.pos);
    setIsOpen(false);
  };

  const currentOption = headingOptions.find((h) => h.value === currentHeading);

  const triggerClassName = `${triggerBaseStyles} ${isBubble ? triggerVariants.bubble : triggerVariants.default}`;
  const dropdownClassName = `${dropdownBaseStyles} ${isBubble ? dropdownVariants.bubble : dropdownVariants.default}`;
  const itemClassName = `${itemBaseStyles} ${isBubble ? itemVariants.bubble : itemVariants.default}`;

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className="relative">
        <ToolbarTooltip content="Styles">
          <button ref={triggerRef} onClick={() => setIsOpen(!isOpen)} className={triggerClassName}>
            <span className={`text-sm font-normal ${isBubble ? "text-[#F4F4F5]" : ""}`}>{currentOption?.label}</span>
            <ChevronDown size={12} className={isBubble ? "text-[#F4F4F5]" : styles.triggerIcon} />
          </button>
        </ToolbarTooltip>

        {isOpen && (
          <Portal>
            <div
              className={dropdownClassName}
              style={{
                position: "fixed",
                top: position.top,
                left: position.left,
                zIndex: 50,
              }}
            >
              {headingOptions.map((option) => (
                <div
                  key={option.value}
                  className={itemClassName}
                  role="button"
                  onPointerDown={() => handleHeadingChange(option.value)}
                >
                  <div className={styles.itemIndicator}>
                    {currentHeading === option.value && <CheckIcon className={isBubble ? "text-[#F4F4F5]" : ""} />}
                  </div>
                  <div className={`${styles.itemInfo} ${option.styles} flex-1 ${isBubble ? "text-[#F4F4F5]" : ""}`}>
                    {option.label}
                  </div>
                </div>
              ))}
            </div>
          </Portal>
        )}
      </div>
    </ClickAwayListener>
  );
};

import type { ExtractionTemplate } from "store/reducers/extract/CurrentExtractionReducer";
import { theme } from "twin.macro";

export const TEMPLATE_TYPE_TO_META: Record<
  ExtractionTemplate["template_type"],
  { label: string; color: string; headerLabel?: string }
> = {
  evaluation_criteria: {
    headerLabel: "Evaluation Criteria",
    label: "Section: Evaluation Criteria",
    color: theme`colors.gray.800`,
  },
  identified: {
    label: "Identified Volume",
    color: theme`colors.emerald.600`,
  },
  extracted: {
    label: "Extracted Template",
    color: theme`colors.emerald.600`,
  },
  generated: {
    label: "AI Generated Volume",
    color: theme`colors.amber.500`,
  },
  statement_of_work: {
    headerLabel: "SOW/PWS",
    label: "Section: SOW/PWS",
    color: theme`colors.gray.800`,
  },
};

import useWritingAssistantOperations from "hook/writing-assistant/useWritingAssistantOperations";
import { useEffect, useState } from "react";
import { getChatSessionDocuments, setActiveSession } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useNotification } from "context/notificationContext";
import { useIsSessionInTasks } from "hook/assistants/hooks";
import { pluralizeWord } from "utils/string";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";
import * as logger from "utils/log";
import { DEFAULT_SESSION_NAME } from "components/molecules/assistant-sidebar-row/constants";

export const useFileUploadHandler = () => {
  const dispatch = useAppDispatch();
  const { activeSession, uploadedDocuments, uploadChatDocumentTasks } = useAppSelector(
    (state) => state.writingAssistant,
  );
  const { createSession, uploadProjectChatDocument } = useWritingAssistantOperations();
  const { setToast } = useNotification();

  const [documentsWithErrors, setDocumentsWithErrors] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const isCurrentSessionInTasks = useIsSessionInTasks(uploadChatDocumentTasks, activeSession?.id);

  useEffect(() => {
    if (!isCurrentSessionInTasks && activeSession?.id && isProcessing) {
      dispatch(getChatSessionDocuments(activeSession.id));
    }
    setIsProcessing(isCurrentSessionInTasks);
  }, [isCurrentSessionInTasks, dispatch]);

  const handleFileUpload = async (files: FileList | File[] | null, maxFileCount = 10) => {
    if ((uploadedDocuments?.length || 0) + (files?.length || 0) > maxFileCount) {
      setToast.error({
        msg: `You can only attach up to ${maxFileCount} ${pluralizeWord(maxFileCount, "file")} at a time.`,
      });
      return;
    }

    let session = activeSession;
    if (!files) return;

    if (!activeSession) {
      const newSession = createSession({ name: DEFAULT_SESSION_NAME });
      const immutableSession = newSession?.toJSON() as ToImmutable<WritingAssistantSession>;
      session = immutableSession;
      dispatch(setActiveSession(immutableSession));
    }

    const chatSessionId = activeSession?.id || session?.id;

    if (!chatSessionId) {
      logger.error("Could not find chatSessionId in handleFileUpload - project assistant");
      return;
    }

    setIsProcessing(true);

    for (const file of Array.from(files)) {
      try {
        await uploadProjectChatDocument(file, chatSessionId);
      } catch (error) {
        setDocumentsWithErrors((prev) => [...prev, file.name]);
        setToast.error({ msg: `File "${file.name}" failed to upload.` });
      }
    }

    dispatch(getChatSessionDocuments(chatSessionId));
  };

  return {
    isProcessing: isProcessing && !!activeSession,
    documentsWithErrors,
    handleFileUpload,
    uploadedDocuments,
  };
};

import { ArrowLeft, TableOfContents } from "lucide-react";
import { useState, useEffect } from "react";
import type { Editor } from "@tiptap/react";
import { TextSelection } from "prosemirror-state";

type HeaderItem = {
  id: string;
  level: number;
  text: string;
  pos: number;
};

const HeadersList = ({ headers, onHeaderClick }: { headers: HeaderItem[]; onHeaderClick: (pos: number) => void }) => {
  if (headers.length === 0) {
    return <div className="text-sm text-gray-500 px-2">No headers found</div>;
  }

  return (
    <div className="space-y-0.5">
      {headers.map((header) => (
        <div
          key={header.id}
          className="py-1.5 px-2 text-sm text-gray-700 hover:bg-gray-200 cursor-pointer rounded-md transition-colors duration-150"
          style={{ paddingLeft: `${(header.level - 1) * 16 + 8}px` }}
          onClick={() => onHeaderClick(header.pos)}
        >
          <span className="block overflow-hidden text-ellipsis whitespace-nowrap" title={header.text}>
            {header.text}
          </span>
        </div>
      ))}
    </div>
  );
};

const getHeadersFromEditor = (editor: Editor): HeaderItem[] => {
  const headers: HeaderItem[] = [];
  editor.state.doc.descendants((node, pos) => {
    if (node.type.name === "heading") {
      headers.push({
        id: `heading-${pos}`,
        level: node.attrs.level,
        text: node.textContent,
        pos,
      });
    }
  });
  return headers;
};

export const ContentNavigator = ({ editor }: { editor?: Editor | null }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [headers, setHeaders] = useState<HeaderItem[]>([]);

  useEffect(() => {
    if (!editor) return;

    const updateHeaders = () => setHeaders(getHeadersFromEditor(editor));

    updateHeaders();
    editor.on("update", updateHeaders);
    return () => {
      editor.off("update", updateHeaders);
    };
  }, [editor]);

  const scrollToHeader = (pos: number) => {
    if (!editor) return;

    const tr = editor.state.tr;
    const selection = TextSelection.create(editor.state.doc, pos);
    editor.view.dispatch(tr.setSelection(selection));

    const domNode = editor.view.nodeDOM(pos);
    if (domNode) {
      (domNode as HTMLElement).scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return isOpen ? (
    <div className="w-full flex flex-col">
      <div className="flex items-center gap-1 mb-2">
        <button
          onClick={() => setIsOpen(false)}
          className="h-8 w-8 rounded-md hover:bg-gray-200 flex items-center justify-center"
        >
          <ArrowLeft size={18} />
        </button>
        <span className="font-medium text-gray-700">Navigation</span>
      </div>
      <div className="overflow-y-auto max-h-[calc(100vh-260px)]">
        <HeadersList headers={headers} onHeaderClick={scrollToHeader} />
      </div>
    </div>
  ) : (
    <button
      onClick={() => setIsOpen(true)}
      className="h-8 w-8 rounded-md hover:bg-gray-200 flex items-center justify-center"
    >
      <TableOfContents size={18} />
    </button>
  );
};

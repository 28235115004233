import { useState, useEffect, useCallback } from "react";
import { getSourceGroups, expandFileContent } from "api/api";
import type { SourceGroup } from "../../molecules/ai-assistant-blocks/vultron-block/constants";
import * as Sentry from "@sentry/react";

interface UseSourceGroupsResult {
  data: SourceGroup[] | null;
  loading: boolean;
  error: Error | null;
  expandContent: (groupIndex: number, previous: boolean) => Promise<void>;
  canExpandUp: boolean;
  canExpandDown: boolean;
}

export function useSourceGroups(
  sourceType: string,
  sourceId: string,
  initialChunkIds: string[],
): UseSourceGroupsResult {
  const [data, setData] = useState<SourceGroup[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [canExpandUp, setCanExpandUp] = useState<boolean>(true);
  const [canExpandDown, setCanExpandDown] = useState<boolean>(true);

  useEffect(() => {
    // Reset state when the source changes to avoid stale content.
    setData(null);
    setError(null);
    setCanExpandUp(true);
    setCanExpandDown(true);

    let isMounted = true;

    const fetchData = async () => {
      if (!sourceType || !sourceId) return;

      setLoading(true);
      try {
        const response = await getSourceGroups({
          source_type: sourceType,
          source_id: sourceId,
          chunk_ids: initialChunkIds,
        });
        if (isMounted) {
          setData(response.data);
        }
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [sourceType, sourceId, initialChunkIds]);

  const expandContent = useCallback(
    async (groupIndex: number, previous: boolean) => {
      try {
        if (!data) return;

        const group = data[groupIndex];
        if (!group) return;

        // For a given group, determine which chunk ID to use for expansion.
        const fileContentId = previous ? group.chunk_ids[0] : group.chunk_ids[group.chunk_ids.length - 1];

        const response = await expandFileContent(fileContentId, previous);
        const { content: newContent, file_content_id: newFileContentId } = response.data;

        // If the API returns a payload with both content and file_content_id as null,
        // then no further expansion is available in that direction.
        if (newContent === null && newFileContentId === null) {
          if (previous) {
            setCanExpandUp(false);
          } else {
            setCanExpandDown(false);
          }
          return;
        }

        setData((prevData) => {
          if (!prevData) return prevData;
          return prevData.map((g, idx) => {
            if (idx === groupIndex) {
              return {
                ...g,
                content: previous ? `${newContent}\n${g.content}` : `${g.content}\n${newContent}`,
                chunk_ids: previous ? [newFileContentId, ...g.chunk_ids] : [...g.chunk_ids, newFileContentId],
              };
            }
            return g;
          });
        });

        // Reset the expansion flags on successful expansion.
        if (previous) {
          setCanExpandUp(true);
        } else {
          setCanExpandDown(true);
        }
      } catch (err) {
        setError(err as Error);
        Sentry.captureException(err);
      }
    },
    [data],
  );

  return { data, loading, error, expandContent, canExpandUp, canExpandDown };
}

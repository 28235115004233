/** @jsxImportSource @emotion/react */

import { useRef } from "react";
import { Checkbox } from "components/atoms/checkbox";
import { styles } from "components/molecules/content-drive-popover-content/styles";
import Tooltip from "components/atoms/tooltip";
import type { BaseFile } from "types/FileStorage";

type Props = {
  id: string;
  name: string;
  file_extension_type: string;
  getFileIcon: (file_extension_type: string) => string;
  handleSelectedFiles: (checked: boolean, files: BaseFile[]) => void;
  selectedFiles: string[];
};

const AttachmentRow = ({ id, name, file_extension_type, getFileIcon, handleSelectedFiles, selectedFiles }: Props) => {
  const fileNameRef = useRef<HTMLDivElement | null>(null);

  return (
    <Tooltip
      key={id}
      disabled={(fileNameRef.current?.clientWidth || 0) < 308}
      disableHoverableContent
      content={name}
      contentProps={{ side: "left", align: "end" }}
    >
      <div key={id} className={styles.checkboxItemRow}>
        <div className="text-stone-800 flex items-center gap-2 text-sm flex-1 truncate text-start py-1.5">
          <img src={getFileIcon(file_extension_type)} alt="" className="h-[18px]" />
          <div className="truncate" ref={fileNameRef}>
            {name}
          </div>
        </div>
        <Checkbox
          checked={!!selectedFiles.includes(id)}
          onCheck={(checkValue) =>
            handleSelectedFiles(checkValue, [{ id, name: name, file_extension_type: file_extension_type }])
          }
        />
      </div>
    </Tooltip>
  );
};

export default AttachmentRow;

import type { SectionStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import type { Framework, Section, SectionManager, Storage, Volume } from "components/copilot/CopilotSchemaTypes";
import { useUpdateTemplate } from "components/copilot/Framework/hooks";
import { createSection, createSectionManager, createVolume } from "utils/framework";
import { useTrackUserMetric } from "utils/metrics";
import { useMutation } from "YJSProvider/createYJSContext";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { filter, find, findIndex, moveItem, some, update } from "YJSProvider/LiveObjects";

export const useFrameworkOperations = (frameworkState?: ToImmutable<Storage>["framework"]) => {
  const updateTemplate = useUpdateTemplate();
  const trackUserEvent = useTrackUserMetric();

  const getFrameworkVolumeTitle = useMutation(({ storage }, volumeId: string) => {
    const liveVolumes = (storage.get("framework") as Storage["framework"])?.get("volumes") as Framework["volumes"];
    if (!liveVolumes) return "";
    const index = findIndex(liveVolumes, (v) => v.get("id") === volumeId);
    if (index === undefined || index === -1) return "";
    return liveVolumes.get(index)?.get("title") || "";
  }, []);

  const getFrameworkVolumeSectionTitle = useMutation(({ storage }, volumeId: string, sectionId: string) => {
    const liveVolumes = storage.get("framework")?.get("volumes") as Framework["volumes"];
    if (!liveVolumes) return "";

    const volumeIndex = findIndex(liveVolumes, (v) => v.get("id") === volumeId);
    if (volumeIndex === -1 || volumeIndex === undefined) return "";

    const liveSections = liveVolumes.get(volumeIndex)?.get("sections") as Volume["sections"];
    if (!liveSections) return "";

    const sectionIndex = findIndex(liveSections, (s) => s.get("id") === sectionId);
    if (sectionIndex === -1 || sectionIndex === undefined) return "";

    return liveSections?.get(sectionIndex)?.get("title");
  }, []);

  const addNewVolume = useMutation(({ storage }, volumeProperties?: Partial<Volume>) => {
    const newVolume = createVolume(volumeProperties);
    (storage.get("framework") as Storage["framework"])?.get("volumes")?.push([newVolume]);

    updateTemplate({ isDirty: true });

    trackUserEvent("Drafts: Draft Added", {
      volume_id: newVolume.get("id"),
    });

    return newVolume.toJSON() as ToImmutable<Volume>;
  }, []);

  const addNewSection = useMutation(
    ({ storage }, volumeId: string, sectionProperties?: Partial<Section>): ToImmutable<Section> => {
      const { item: newSection } = createSection(sectionProperties);
      const volumes = (storage.get("framework") as Storage["framework"])?.get("volumes") as Framework["volumes"];
      if (!volumes) return newSection.toJSON() as ToImmutable<Section>;
      const foundVolume = find(volumes, (vol) => vol.get("id") === volumeId);

      foundVolume?.get("sections")?.push([newSection]);
      updateTemplate({ isDirty: true });

      trackUserEvent("Drafts: Section Added", {
        volume_id: volumeId,
        section_id: newSection.get("id"),
        is_subsection: !!sectionProperties?.parent_id,
      });
      return newSection.toJSON() as ToImmutable<Section>;
    },
    [],
  );

  const updateVolumeTitle = useMutation(({ storage }, id: string, newTitle: string) => {
    const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes") as Framework["volumes"];
    if (!volumeList) return;

    const foundVolume = find(volumeList, (volume) => volume.get("id") === id);
    foundVolume?.set("title", newTitle);

    updateTemplate({ isDirty: true });
  }, []);

  const updateSectionTitle = useMutation(({ storage }, volumeId: string, sectionId: string, newTitle: string) => {
    const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes") as Framework["volumes"];
    if (!volumeList) return;
    const foundVolume = find(volumeList, (volume) => volume.get("id") === volumeId);
    const sections = foundVolume?.get("sections") as Volume["sections"];
    if (!sections || !foundVolume) return;
    const foundSection = find(sections, (section) => section.get("id") === sectionId);
    foundSection?.set("title", newTitle);

    updateTemplate({ isDirty: true });
  }, []);

  const updateSectionStatus = useMutation(({ storage }, volumeId: string, sectionId: string, status: SectionStatus) => {
    const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes") as Framework["volumes"];
    if (!volumeList) return;
    const foundVolume = find(volumeList, (volume) => volume.get("id") === volumeId);
    const sections = foundVolume?.get("sections") as Volume["sections"];
    if (!sections || !foundVolume) return;
    const foundSection = find(sections, (section) => section.get("id") === sectionId);
    foundSection?.set("status", status);

    updateTemplate({ isDirty: true });
  }, []);

  const assignToVolume = useMutation(({ storage }, volumeId: string, assignees: Section["assignees"]) => {
    const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes") as Framework["volumes"];
    if (!volumeList) return;
    const foundVolume = find(volumeList, (volume) => volume.get("id") === volumeId);
    foundVolume?.set("assignees", assignees);

    updateTemplate({ isDirty: true });
  }, []);

  const assignToSection = useMutation(
    ({ storage }, volumeId: string, sectionId: string, assignees: Section["assignees"]) => {
      const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes") as Framework["volumes"];
      if (!volumeList) return;
      const foundVolume = find(volumeList, (volume) => volume.get("id") === volumeId);
      const sections = foundVolume?.get("sections") as Volume["sections"];
      if (!sections) return;
      const foundSection = find(sections, (section) => section.get("id") === sectionId);
      foundSection?.set("assignees", assignees);

      updateTemplate({ isDirty: true });
    },
    [],
  );

  const setSectionProposal = useMutation(
    ({ storage }, volumeId: string, sectionId: string, proposal: Section["proposal"]) => {
      const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes") as Framework["volumes"];
      if (!volumeList) return;
      const foundVolume = find(volumeList, (volume) => volume.get("id") === volumeId);
      const sections = foundVolume?.get("sections") as Volume["sections"];
      if (!sections) return;
      const foundSection = find(sections, (section) => section.get("id") === sectionId);
      foundSection?.set("proposal", proposal);

      updateTemplate({ isDirty: true });
    },
    [],
  );

  const convertToSection = useMutation(({ storage }, volumeId: string, subsectionId: string) => {
    const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes") as Framework["volumes"];

    if (!volumeList) return;
    const foundVolume = find(volumeList, (volume) => volume.get("id") === volumeId);
    const sections = foundVolume?.get("sections") as Volume["sections"];

    if (!sections || !foundVolume) return;
    const foundSectionIdx = findIndex(sections, (section) => section.get("id") === subsectionId);

    if (typeof foundSectionIdx !== "number" || foundSectionIdx < 0) return;

    const foundSection = sections.get(foundSectionIdx);
    const parentSectionIdx = findIndex(sections, (sec) => sec.get("id") === foundSection?.get("parent_id"));

    if (typeof parentSectionIdx !== "number" || parentSectionIdx < 0) return;

    foundSection?.set("parent_id", "");
    moveItem(sections, foundSectionIdx, parentSectionIdx + 1);

    updateTemplate({ isDirty: true });
  }, []);

  const moveSectionToDraft = useMutation(
    ({ storage }, volumeId: string, sectionId: string, destinationDraftId: string) => {
      const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes") as Framework["volumes"];
      if (!volumeList) return;
      const destinationDraft = find(volumeList, (volume) => volume.get("id") === destinationDraftId);
      const destinationDraftSections = destinationDraft?.get("sections") as Volume["sections"] | undefined;
      const foundVolume = find(volumeList, (volume) => volume.get("id") === volumeId);
      const sections = foundVolume?.get("sections") as Volume["sections"];
      if (!sections || !foundVolume) return;
      const foundSectionIdx = findIndex(sections, (section) => section.get("id") === sectionId);
      if (typeof foundSectionIdx !== "number" || foundSectionIdx < 0) return;

      const foundSection = sections.get(foundSectionIdx);
      const isSubsection = foundSection?.get("parent_id");
      const clonedFoundSection = foundSection?.clone();
      const subsections = filter(sections, (section) => section.get("parent_id") === sectionId);

      if (!clonedFoundSection) return;

      if (isSubsection) clonedFoundSection?.set("parent_id", "");

      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;

      const liveRows = complianceMatrix
        ? filter(complianceMatrix, (row) => {
            const sectionIdRef = row.get("proposal_reference")?.get("section_id");

            return (
              sectionIdRef === foundSection?.get("id") || some(sections, (subsec) => subsec.get("id") === sectionIdRef)
            );
          })
        : [];

      destinationDraftSections?.push([clonedFoundSection]);
      foundVolume?.get("sections")?.delete(foundSectionIdx);

      subsections?.forEach((subsec) => {
        destinationDraftSections?.push([subsec.clone()]);
        const foundSectionIdx = findIndex(sections, (section) => section.get("id") === subsec.get("id"));
        if (typeof foundSectionIdx === "number" && foundSectionIdx >= 0) {
          foundVolume?.get("sections")?.delete(foundSectionIdx);
        }
      });

      liveRows?.forEach((row) => {
        row?.get("proposal_reference")?.set("volume_id", destinationDraftId);
      });

      updateTemplate({ isDirty: true });
    },
    [],
  );

  const deleteVolume = useMutation(({ storage }, volumeId: string) => {
    const liveReqs = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    const volumeList = (storage.get("framework") as Storage["framework"])?.get("volumes") as Framework["volumes"];
    if (!volumeList) return;
    const index = findIndex(volumeList, (v) => v.get("id") === volumeId);

    if (index === -1) return;
    liveReqs?.forEach((row) => {
      const assignedVolumeId = row.get("proposal_reference")?.get("volume_id");
      if (assignedVolumeId === volumeId) {
        const proposalReference = row.get("proposal_reference");
        update(proposalReference, {
          volume_id: "",
          section_id: "",
          subsection_id: "",
        });
      }
    });
    volumeList?.delete(index);

    trackUserEvent("Drafts: Draft Deleted", {
      volume_id: volumeId,
    });

    updateTemplate({ isDirty: true });
  }, []);

  const deleteSection = useMutation(({ storage }, volumeId: string, sectionId: string) => {
    const liveReqs = storage.get("compliance_matrix") as Storage["compliance_matrix"];
    const volumeList = (storage.get("framework") as Storage["framework"])?.get("volumes") as Framework["volumes"];
    if (!volumeList) return;
    const volumeIndex = findIndex(volumeList, (vol) => vol.get("id") === volumeId);
    if (volumeIndex === -1) return;

    const sectionList = volumeList?.get(volumeIndex)?.get("sections") as Volume["sections"];

    sectionList?.forEach((sec) => {
      const id = sec.get("id");
      const parentId = sec.get("parent_id");
      const foundSectionOrSubsection = id === sectionId || parentId === sectionId;

      if (foundSectionOrSubsection) {
        liveReqs?.forEach((row) => {
          const assignedSectionId = row.get("proposal_reference")?.get("section_id");
          if (assignedSectionId === sectionId) {
            const proposal_reference = row.get("proposal_reference");
            update(proposal_reference, {
              volume_id: "",
              section_id: "",
              subsection_id: "",
            });
          }
        });

        const sectionIndex = findIndex(sectionList, (section) => section.get("id") === id);
        if (sectionIndex === -1) return;

        sectionList?.delete(sectionIndex);

        trackUserEvent("Drafts: Section Deleted", {
          volume_id: volumeId,
          section_id: sectionId,
          is_subsection: !!parentId,
        });
      }
    });
    updateTemplate({ isDirty: true });

    return sectionList?.toArray();
  }, []);

  const updateSectionManager = useMutation(
    ({ storage }, volumeId: string, sectionId: string, properties: Partial<SectionManager>) => {
      const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes") as Framework["volumes"];
      if (!volumeList) return;
      const foundVolume = find(volumeList, (volume) => volume.get("id") === volumeId);
      const sections = foundVolume?.get("sections") as Volume["sections"];
      if (!sections || !foundVolume) return;
      const foundSection = find(sections, (section) => section.get("id") === sectionId);
      const managerStep = foundSection?.get("section_manager") as Section["section_manager"];

      if (!properties) return;

      if (managerStep) {
        update(managerStep, properties);
      } else foundSection?.set("section_manager", createSectionManager(properties));
    },
    [],
  );

  return {
    getFrameworkVolumeTitle,
    getFrameworkVolumeSectionTitle,
    addNewVolume,
    addNewSection,
    updateVolumeTitle,
    updateSectionTitle,
    assignToVolume,
    assignToSection,
    updateSectionStatus,
    setSectionProposal,
    deleteVolume,
    deleteSection,
    convertToSection,
    moveSectionToDraft,
    updateSectionManager,
    frameworkState,
  };
};

import type { DocumentProps } from "react-pdf";
import { Document, Page } from "react-pdf";
import { ScreenSpinner } from "utils/icons";
import { memo, type Dispatch, type MutableRefObject, type SetStateAction } from "react";
import { useObservePages } from "./hooks";
import "react-pdf/dist/Page/TextLayer.css";

interface Props extends DocumentProps {
  pageList: number[];
  scale?: number;
  setCurrentPage?: Dispatch<SetStateAction<number | null>>;
  pageRefs?: MutableRefObject<(HTMLDivElement | null)[]>;
}

const DocumentRenderer = ({
  file,
  pageList,
  scale = 1.75,
  onLoadSuccess,
  onLoadError,
  setCurrentPage,
  pageRefs,
  ...props
}: Props) => {
  const isFirstPageInitiallyRendered = useObservePages({ pageList, pageRefs, setCurrentPage, file });

  return (
    <Document
      renderMode="canvas"
      file={file}
      onLoadError={onLoadError}
      onLoadSuccess={onLoadSuccess}
      loading={
        <div className="absolute inset-0 top-14 flex items-center justify-center">
          <ScreenSpinner />
        </div>
      }
      {...props}
    >
      {window === undefined ? (
        <div />
      ) : (
        pageList.map((page, idx) => {
          return (
            <div key={page} ref={pageRefs?.current ? (el) => (pageRefs.current[idx] = el) : undefined} className="mb-4">
              <Page
                className="mx-auto w-fit"
                pageNumber={page}
                renderAnnotationLayer={false}
                scale={scale}
                onRenderSuccess={!idx ? () => (isFirstPageInitiallyRendered.current = true) : undefined}
                loading={
                  <div className="absolute inset-0 flex items-center justify-center">
                    <ScreenSpinner />
                  </div>
                }
              />
            </div>
          );
        })
      )}
    </Document>
  );
};

export default memo(DocumentRenderer);

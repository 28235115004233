import { useEffect, useState, useMemo, useRef } from "react";
import ProgressBar from "components/ProgressBar";
import { maxMinutesRemainingResponses, useMaxMinutesRemainingForSession } from "pages/ai-assistant/hooks";
import { useAppSelector } from "store/storeTypes";
import { getWorkflowLabel } from "utils/assistants/utils";
import { getInitialMaxForSession, removeInitialMaxForSession } from "./utils";

const PendingLoader = () => {
  const activeSession = useAppSelector((root) => root.aiAssistantState.activeSession);
  const sessionId = activeSession?.id;
  const maxMinutesRemaining = useMaxMinutesRemainingForSession(sessionId);

  const [initialMax, setInitialMax] = useState<number | null>(null);
  const initialMaxSet = useRef(false);

  useEffect(() => {
    if (sessionId && maxMinutesRemaining != null && !initialMaxSet.current && maxMinutesRemaining > 0) {
      const storedInitialMax = getInitialMaxForSession(sessionId, maxMinutesRemaining);
      setInitialMax(storedInitialMax);
      initialMaxSet.current = true;
    }
  }, [sessionId, maxMinutesRemaining]);

  const progressPercentage = useMemo(() => {
    if (maxMinutesRemaining === maxMinutesRemainingResponses.TASK_TIME_CALCULATING) return 0;
    if (maxMinutesRemaining === maxMinutesRemainingResponses.SESSION_NOT_IN_TASK_QUEUE) return 100;
    if (initialMax === null || maxMinutesRemaining == null) return 0;
    return ((initialMax - maxMinutesRemaining) / initialMax) * 100;
  }, [initialMax, maxMinutesRemaining]);

  useEffect(() => {
    if (sessionId && initialMax !== null && maxMinutesRemaining != null && progressPercentage >= 100) {
      removeInitialMaxForSession(sessionId);
    }
  }, [sessionId, initialMax, maxMinutesRemaining, progressPercentage]);

  const actionLabel =
    maxMinutesRemaining === maxMinutesRemainingResponses.SESSION_NOT_IN_TASK_QUEUE
      ? "Processing output..."
      : "Processing inputs...";
  const timeRemainingLabel =
    maxMinutesRemaining === maxMinutesRemainingResponses.TASK_TIME_CALCULATING
      ? "Calculating time remaining..."
      : `${maxMinutesRemaining} min remaining`;

  const workflowName = activeSession?.workflow ? getWorkflowLabel(activeSession.workflow) : "";

  return (
    <div className="bg-zinc-50 border border-zinc-300 rounded-md min-h-[110px] min-w-[600px] px-6 py-2 flex flex-col justify-around">
      <div className="flex justify-between items-center">
        <h2 className="text-gray-900 text-base font-medium">Processing {workflowName} Workflow</h2>
        {maxMinutesRemaining !== maxMinutesRemainingResponses.SESSION_NOT_IN_TASK_QUEUE && (
          <div className="text-sm text-blue-500">{timeRemainingLabel}</div>
        )}
      </div>
      <ProgressBar percent={progressPercentage} progressBarColor="linear-gradient(90deg, #6C6C6C 0%, #1E1E1E 100%)" />
      <div className="flex items-center gap-1.5">
        <div className="relative">
          <div className="rounded-full w-2.5 h-2.5 bg-blue-500" />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="rounded-full w-2 h-2 bg-blue-500 animate-ping" />
          </div>
        </div>
        <div className="text-sm text-gray-500">{actionLabel}</div>
      </div>
    </div>
  );
};

export default PendingLoader;

/** @jsxImportSource @emotion/react */

import useRequirementOperations from "hook/useRequirementOperations";
import { useMemo, useState } from "react";
import UserInstructionRow from "./UserInstructionRow";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { useDrag } from "./hooks";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import { CirclePlus, Plus, Star } from "lucide-react";
import Tooltip from "components/atoms/tooltip";
import Icon from "components/atoms/icons/Icon";
import VoiceTranslateItem from "components/voice-assist/VoiceTranslateItem";
import tw from "twin.macro";
import { useTrackUserMetric } from "utils/metrics";
import ClickAwayListener from "helpers/ClickAwayListener";
import { useCallback } from "react";
import type { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";

type Props = {
  row: ComplianceMatrixRow;
};

const UserInstructions = ({ row }: Props) => {
  const { user_instructions, requirement } = row;
  const instructions = user_instructions;
  const { addNewUserInstruction } = useRequirementOperations();
  const [editableInstruction, setEditableInstruction] = useState("");
  const [showDefaultGuidelines, setShowDefaultGuidelines] = useState(false);
  const { sensors, handleDragEnd, handleDragCancel, handleDragStart, activeDragId } = useDrag();
  const trackUserEvent = useTrackUserMetric();

  const activeInstruction = useMemo(
    () => instructions?.find(({ id }) => id === activeDragId),
    [activeDragId, instructions],
  );

  const handleTranslation = (text: string) => {
    addNewUserInstruction(requirement.id, text);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={verticalSortableListCollisionDetection}
      onDragEnd={(event) => handleDragEnd(event, requirement.id)}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
    >
      <div className="flex flex-col gap-1.5">
        <div className="flex items-center justify-between">
          <div className="text-xs font-semibold py-1 text-gray-darkest flex items-center gap-1">
            Guidelines
            <Tooltip
              disableHoverableContent
              content="Guidelines provides control over the generated content’s structure and style, from paragraph and word count to tone and language style used. Experiment with more complex instructions for tailored outputs."
            >
              <Icon name="InfoCircle" className="w-3 h-3" />
            </Tooltip>
          </div>
          <div className="flex gap-1.5">
            <div className="relative">
              <button
                onClick={() => {
                  setShowDefaultGuidelines(!showDefaultGuidelines);
                }}
                disabled={row.locked}
                className="flex items-center gap-1 bg-slate-200 text-slate-600 rounded shadow-sm text-xs px-2 py-1 duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
              >
                <Star size={14} />
                Recommended
              </button>
              {showDefaultGuidelines && (
                <GuidelinePopover
                  setGuidelinePopoverOpen={setShowDefaultGuidelines}
                  handleSelectGuideline={(guideline: Guideline) =>
                    addNewUserInstruction(requirement.id, guideline.prompt)
                  }
                />
              )}
            </div>
            <VoiceTranslateItem
              onComplete={handleTranslation}
              bg="slate-200"
              text="slate-600"
              hoverBg="slate-300"
              hoverText="slate-900"
              rounded="rounded"
              disabled={row.locked}
            />
            <button
              onClick={() => {
                const createdInstruction = addNewUserInstruction(requirement.id, "");
                setEditableInstruction(createdInstruction?.id || "");

                trackUserEvent("Drafts: New Guideline Added", {
                  requirement_id: String(requirement.id),
                });
              }}
              disabled={row.locked}
              className="bg-slate-200 text-slate-600 rounded shadow-sm text-base w-6 h-6 flex items-center justify-center duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
            >
              <Plus size={14} />
            </button>
          </div>
        </div>
        <div
          className="flex flex-col rounded max-h-[260px] overflow-auto"
          css={[row.locked && tw`pointer-events-none`]}
        >
          {instructions?.length ? (
            <SortableContext
              id="REQUIREMENT_PROMPTS"
              items={instructions || []}
              strategy={verticalListSortingStrategy}
              disabled={row.locked}
            >
              <div className="flex flex-col gap-1">
                {instructions?.map((instruction) => (
                  <SortableItem
                    key={instruction.id}
                    reqId={requirement.id}
                    editableInstruction={editableInstruction}
                    setEditableInstruction={setEditableInstruction}
                    instruction={instruction}
                    disabled={row.locked}
                  />
                ))}
              </div>
              <DragOverlay style={{ transformOrigin: "0 0 " }}>
                {!!activeInstruction && (
                  <UserInstructionRow
                    reqId={requirement.id}
                    editableInstruction={editableInstruction}
                    setEditableInstruction={setEditableInstruction}
                    instruction={activeInstruction}
                    isDragging
                  />
                )}
              </DragOverlay>
            </SortableContext>
          ) : (
            <button
              onClick={() => {
                const createdInstruction = addNewUserInstruction(requirement.id, "");
                setEditableInstruction(createdInstruction?.id || "");

                trackUserEvent("Drafts: New Guideline Added", {
                  requirement_id: String(requirement.id),
                });
              }}
              disabled={row.locked}
              className="bg-slate-200 h-11 text-start flex items-center justify-between gap-2 text-slate-600 rounded text-xs px-2 py-1.5 disabled:opacity-50"
            >
              <div className="flex items-center gap-2">
                <CirclePlus size={14} /> Add guideline
              </div>
            </button>
          )}
        </div>
      </div>
    </DndContext>
  );
};

interface GuidelinePopoverProps {
  setGuidelinePopoverOpen: (val: boolean) => void;
  handleSelectGuideline: (guideline: Guideline) => void;
}

interface Guideline {
  name: string;
  description: string;
  prompt: string;
}

const GuidelinePopover = ({ setGuidelinePopoverOpen, handleSelectGuideline }: GuidelinePopoverProps) => {
  const trackUserEvent = useTrackUserMetric();
  const guidelines = [
    {
      name: "Set Paragraph Count",
      description: "Organize response into a specific number of paragraphs",
      prompt: "Use INSERT_NUM_PARAGRAPHS paragraphs in the response",
    },
    {
      name: "Limit Word Count",
      description: "Limit the length of the response",
      prompt: "Use a maximum of INSERT_NUM_WORDS words in the response",
    },
    {
      name: "Use Lists",
      description: "Use a list to organize paragraphs in the response",
      prompt: "Use a list format for paragraph INSERT_PARAGRAPH_NUM",
    },
    {
      name: "Apply Tone",
      description: "Use a specific response tone",
      prompt: "Use a INSERT_TONE tone in the response",
    },
    {
      name: "Exclude Terms & Phrases",
      description: "Exclude specific terms and phrases in the response",
      prompt: "Do not use the following words in the response: INSERT_WORDS",
    },
  ];

  const handleSelect = useCallback(
    (guideline: Guideline) => {
      setGuidelinePopoverOpen(false);
      handleSelectGuideline(guideline);
      trackUserEvent("Guideline: Selected", {
        name: guideline.name,
        description: guideline.description,
        prompt: guideline.prompt,
      });
    },
    [handleSelectGuideline, setGuidelinePopoverOpen, trackUserEvent],
  );

  return (
    <div className="right-0 absolute z-[999] min-w-[370px] bg-white shadow rounded-md border border-zinc-200 text-gray-500 text-sm max-h-[40vh] overflow-y-auto flex flex-col gap-y-4 px-2 py-2 top-full mt-1">
      <ClickAwayListener
        onClickAway={(e) => {
          e.stopPropagation();
          setGuidelinePopoverOpen(false);
        }}
      >
        {guidelines.map((guideline: Guideline) => (
          <div
            key={guideline.name}
            className="flex justify-between items-center bg-white hover:bg-[#f7f7f7] cursor-pointer p-2 rounded-md"
            onClick={() => handleSelect(guideline)}
          >
            <div className="flex flex-col gap-1">
              <div className="text-xs text-black font-medium">{guideline.name}</div>
              <div className="text-xs">{guideline.description}</div>
            </div>
          </div>
        ))}
      </ClickAwayListener>
    </div>
  );
};

export default UserInstructions;

import React, { useEffect, useMemo } from "react";
import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TaskList from "@tiptap/extension-task-list";
import TextAlign from "@tiptap/extension-text-align";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import FontFamily from "@tiptap/extension-font-family";
import Image from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import { Document } from "@tiptap/extension-document";
import { Color } from "@tiptap/extension-color";
import { ExtensionFontSize } from "components/yjs-editor/components/extensions/ExtensionFontSize";
import { Markdown as TiptapMarkdown } from "tiptap-markdown";
import { useEditor, EditorContent, Editor } from "@tiptap/react";
import { ContentLibraryImage } from "components/yjs-editor/components/ContentLibrary/ContentLibraryImage";
import { CustomTableCell } from "components/yjs-editor/components/CustomTableCell";
import { CustomTaskItem } from "components/yjs-editor/components/CustomTaskItem";
import { CustomListItem } from "components/yjs-editor/components/extensions/CustomListItem";
import PasteExtension from "components/yjs-editor/components/extensions/PasteExtension";
import Link from "@tiptap/extension-link";
import { useFlags } from "hook/useFlags";
import { useAppSelector } from "store/storeTypes";
import { extractInstruction } from "utils/assistants/utils";
import InlineSourceButtons from "components/yjs-editor/components/InlineSourceButtons";
import type { SourceFile } from "types/Assistants/types";

export const SHARED_EXTENSIONS = [
  StarterKit.configure({
    document: false,
    blockquote: {
      HTMLAttributes: { class: "tiptap-blockquote" },
    },
    heading: {
      levels: [1, 2, 3, 4, 5],
      HTMLAttributes: { class: "tiptap-heading" },
    },
    history: false,
    horizontalRule: { HTMLAttributes: { class: "tiptap-hr" } },
    listItem: false,
    orderedList: { HTMLAttributes: { class: "tiptap-ordered-list" } },
    paragraph: { HTMLAttributes: { class: "markdown-paragraph" } },
  }),
  Document.extend({ content: "block+" }),
  Highlight.configure({
    multicolor: true,
    HTMLAttributes: { style: "background-color: {color}" },
  }),
  ContentLibraryImage,
  Image.configure({ HTMLAttributes: { class: "tiptap-image" } }),
  Table.configure({ HTMLAttributes: { class: "tiptap-table" } }),
  TableRow.configure({ HTMLAttributes: { class: "tiptap-table-row" } }),
  TableHeader.configure({ HTMLAttributes: { class: "tiptap-table-header" } }),
  CustomTableCell,
  Placeholder.configure({ placeholder: "Start writing…", emptyEditorClass: "tiptap-empty" }),
  CustomTaskItem,
  TaskList.configure({ HTMLAttributes: { class: "tiptap-task-list" } }),
  TextAlign.configure({
    types: ["heading", "heading1", "heading2", "heading3", "heading4", "heading5", "paragraph"],
  }),
  Typography,
  Underline,
  TextStyle,
  Color.configure({ types: ["textStyle"] }),
  ExtensionFontSize,
  FontFamily.configure({ types: ["textStyle"] }),
  PasteExtension,
  CustomListItem.configure({ HTMLAttributes: { class: "tiptap-list-item" } }),
  TiptapMarkdown.configure({
    html: true,
    tightLists: true,
    bulletListMarker: "-",
    linkify: false,
    breaks: false,
    transformPastedText: true,
    transformCopiedText: false,
  }),
];

export const getMarkdownExtensions = (markdownBreaks: boolean) => {
  return [
    ...SHARED_EXTENSIONS,
    Link.configure({
      HTMLAttributes: { target: "_blank", style: "color: #3B82F6;" },
    }),
    InlineSourceButtons,
    TiptapMarkdown.configure({ breaks: markdownBreaks }),
  ];
};

interface MarkdownEditorProps {
  content: string;
  isStreaming?: boolean;
  streamCopy?: string;
  error?: boolean;
  inlineSourceFiles?: SourceFile[];
}

export const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  content,
  isStreaming = false,
  streamCopy = "",
  error = false,
  inlineSourceFiles = [],
}) => {
  const flags = useFlags();

  const extensions = useMemo(() => getMarkdownExtensions(flags.markdownBreaks), [flags.markdownBreaks]);

  const stableInlineSourceFiles = useMemo(() => inlineSourceFiles, [JSON.stringify(inlineSourceFiles)]);

  // Create the editor instance only once unless extensions change - this was causing the editor to re-render every few seconds
  const editor = useEditor({
    extensions,
    editable: false,
    content: "",
  });

  const activeSession = useAppSelector((root) => root.aiAssistantState.activeSession);

  useEffect(() => {
    if (!editor || error) return;

    const setEditorContent = (markdownContent: string, sourceFiles?: SourceFile[]) => {
      editor.commands.setContent(markdownContent);

      if (sourceFiles && sourceFiles.length > 0) {
        const { state, view } = editor;
        const { doc } = state;
        const pos = doc.content.size > 0 ? doc.content.size - 1 : 0;

        if (doc.content.size > 0) {
          view.dispatch(
            state.tr.insert(
              pos,
              state.schema.nodes.inlineSourceButtons.create({
                sourceFiles,
              }),
            ),
          );
        }
      }
    };

    if (isStreaming && streamCopy) {
      const markdownToSet =
        activeSession && activeSession.workflow ? extractInstruction(streamCopy, activeSession) || "" : streamCopy;

      if (typeof markdownToSet === "string") {
        setTimeout(() => setEditorContent(markdownToSet), 0);
      }
    } else if (!isStreaming) {
      setTimeout(() => setEditorContent(content, stableInlineSourceFiles), 0);
    }
  }, [editor, isStreaming, streamCopy, content, error, activeSession, stableInlineSourceFiles]);

  if (!editor) {
    return null;
  }

  return <EditorContent editor={editor} />;
};

export const getMarkdownEditor = (content: string, enableMarkdownBreaks = false) => {
  const extensions = getMarkdownExtensions(enableMarkdownBreaks);
  const editor = new Editor({
    extensions,
    content,
  });
  return editor;
};

export default MarkdownEditor;

import type { WorkspaceFile } from "types/FileStorage";

export const getDocumentStatus = (document: WorkspaceFile, flags: Record<string, any>) => {
  const createdDate = new Date(document.created_at);
  const now = new Date();
  const minutesSinceCreation = (now.getTime() - createdDate.getTime()) / 60000;

  const fileTooLargeMessages = [
    "Apologies! File is too large to process. For support with larger files, please connect with our support team at support@vultron.ai.",
    "The document size exceeds our processing limits (approximately 500 pages and 200MB). Please contact support@vultron.ai for assistance or to request a limit increase.",
    "The document size exceeds our processing limits (approximately 2000 pages and 500MB). Please contact support@vultron.ai for assistance or to request a limit increase.",
  ];

  if (document.error_message) {
    if (fileTooLargeMessages.includes(document.error_message)) {
      return {
        text: "Limit Exceeded",
        color: "text-[#7063FF]",
        tooltipText: document.error_message,
        showTooltip: false,
      };
    } else {
      return {
        text: "Attention Required",
        color: "text-[#7063FF]",
        tooltipText: document.error_message,
        showTooltip: false,
      };
    }
  } else if (flags?.useIndexed ? document.indexed : minutesSinceCreation > 45) {
    return {
      text: "Processed",
      color: "text-[#4cb592]",
      tooltipText: null,
      showTooltip: false,
    };
  }
};

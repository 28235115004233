/** @jsxImportSource @emotion/react */
import React, { useCallback, useRef, useState } from "react";
import { LoaderCircle, File, FilePlus2, CircleAlert, ChevronDown } from "lucide-react";
import Popover from "components/atoms/popover";
import FileSelector from "components/atoms/file-selector/FileSelector";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { getAttachLabel } from "utils/assistants/utils";
import AttachmentButtonPopoverContent from "components/organisms/AddAttachmentsButtonPopoverContent";
import { useFileUploadHandler } from "../hooks";
import useWritingAssistantOperations from "hook/writing-assistant/useWritingAssistantOperations";
import { getChatSessionDocuments } from "store/reducers/writing-assistant/writingAssistantReducer";
import { pluralizeWord } from "utils/string";

interface AddAttachmentsButtonProps {
  maxFileCount?: number | null;
}

const AddAttachmentsButton: React.FC<AddAttachmentsButtonProps> = ({ maxFileCount }) => {
  const { isProcessing, documentsWithErrors, handleFileUpload, uploadedDocuments } = useFileUploadHandler();
  const { deleteProjectChatDocument } = useWritingAssistantOperations();
  const [filePopoverOpen, setFilePopoverOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { activeSession, uploadChatDocumentTasks, enableInternet } = useAppSelector((state) => state.writingAssistant);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (files: FileList | null): void => {
    if (files) {
      handleFileUpload(files, maxFileCount ?? undefined);
    }
    if (fileInputRef.current) {
      // Reset the value or the onchange won't trigger if the same file is selected again
      fileInputRef.current.value = "";
    }
  };

  const onDeleteSuccess = useCallback(
    () => dispatch(getChatSessionDocuments(activeSession?.id || "")),
    [dispatch, activeSession?.id],
  );

  const isDisabled = enableInternet;

  return (
    <>
      {uploadedDocuments?.length ? (
        <Popover
          open={filePopoverOpen}
          onOpenChange={setFilePopoverOpen}
          contentProps={{ align: "start" }}
          content={
            <AttachmentButtonPopoverContent
              uploadChatDocumentTasks={uploadChatDocumentTasks}
              uploadedDocuments={uploadedDocuments}
              activeSessionId={activeSession?.id}
              onDeleteSuccess={onDeleteSuccess}
              deleteDocument={deleteProjectChatDocument}
              handleFileUpload={handleFileChange}
            />
          }
        >
          <button
            className="flex items-center text-sm justify-center gap-1.5 px-2 py-1 rounded-md duration-100"
            disabled={isDisabled}
            css={[isDisabled ? tw`text-gray-400` : tw`text-gray-700 hover:text-black hover:bg-gray-200`]}
          >
            {isProcessing ? <LoaderCircle className="animate-spin" size={16} /> : <File size={14} />}
            {isProcessing
              ? "Processing..."
              : `${uploadedDocuments?.length} ${pluralizeWord(uploadedDocuments?.length, "file")}`}
            {documentsWithErrors.length > 0 && (
              <CircleAlert size={13} className="text-red-500" data-testid="error-icon" />
            )}
            <ChevronDown size={14} />
          </button>
        </Popover>
      ) : (
        <FileSelector
          labelClassName="flex items-center text-sm justify-center gap-1.5 px-2 py-1 rounded-md cursor-pointer duration-100"
          onFileUpload={handleFileChange}
          disabled={isDisabled}
        >
          {isProcessing ? <LoaderCircle className="animate-spin" size={16} /> : <FilePlus2 size={14} />}
          {getAttachLabel([], "Attach", isProcessing)}
          {documentsWithErrors.length > 0 && (
            <CircleAlert size={13} className="text-red-500" data-testid="error-icon" />
          )}
        </FileSelector>
      )}
    </>
  );
};

export default AddAttachmentsButton;

import type { ComponentProps } from "react";

export const LineHeightIcon = (props: ComponentProps<"svg">) => (
  <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.08464 8.45831V12.5416M4.08464 12.5416L6.1263 10.5M4.08464 12.5416L2.04297 10.5"
      stroke="#1E1E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.08431 5.54169V1.45835M4.08431 1.45835L2.04264 3.50002M4.08431 1.45835L6.12598 3.50002"
      stroke="#1E1E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.58447 7H20.5845M9.58447 12H20.5845M9.58447 2H20.5845"
      stroke="#1E1E1E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

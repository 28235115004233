import type { WorkflowOptionType } from "pages/ai-assistant/constants";
import { typeOptions } from "pages/ai-assistant/constants";

interface TypeBadgeProps {
  type: WorkflowOptionType;
}

const TypeBadge: React.FC<TypeBadgeProps> = ({ type }) => {
  const findTeamColor = (type: WorkflowOptionType) => {
    const typeOption = typeOptions.find((option) => option.name === type);
    return typeOption?.color;
  };

  return (
    <div
      className={`flex items-center gap-1 px-2 py-1 text-gray-700 rounded-md text-sm`}
      style={{ backgroundColor: findTeamColor(type) }}
    >
      {type}
    </div>
  );
};

export default TypeBadge;

import { pluralizeWord } from "utils/string";

export function getTooltipMessage({
  fileCountLimitExceeded,
  multipleInputsProvided,
  acceptedFileTypes,
  hasUnsupportedFileTypes,
  maxTotalCount,
}: {
  fileCountLimitExceeded: boolean;
  multipleInputsProvided: boolean;
  acceptedFileTypes: string[];
  hasUnsupportedFileTypes: boolean;
  maxTotalCount?: number;
}): string | undefined {
  let message: string | undefined = undefined;

  if (fileCountLimitExceeded && multipleInputsProvided && maxTotalCount) {
    message = `You can only select up to ${maxTotalCount} ${pluralizeWord(maxTotalCount, "file")}. Also, only one input method is allowed – please choose either an attachment or copied text.`;
  } else if (fileCountLimitExceeded && maxTotalCount) {
    message = `You can only select up to ${maxTotalCount} ${pluralizeWord(maxTotalCount, "file")}.`;
  } else if (multipleInputsProvided) {
    message = `Only one input method is allowed. Please choose either an attachment or copied text.`;
  }

  if (hasUnsupportedFileTypes) {
    const unsupportedMessage = `You can only upload files of the following types: ${acceptedFileTypes.join(", ")}.`;
    message = message ? `${message} ${unsupportedMessage}` : unsupportedMessage;
  }

  return message;
}

import { useEffect, useState } from "react";
import { ScreenSpinner } from "utils/icons";

const LoadingText = () => {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((currentDots) => {
        if (currentDots.length < 3) {
          return currentDots + ".";
        }
        return ".";
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="p-20 w-full h-full my-auto flex flex-col items-center justify-center gap-3 bg-white">
      <ScreenSpinner />
      <div className="relative text-gray-400">
        <span>Establishing secure connection</span>
        <span className="absolute left-full top-0">{dots}</span>
      </div>
    </div>
  );
};

export default LoadingText;

/** @jsxImportSource @emotion/react */
import { Checkbox } from "components/atoms/checkbox";
import type { WorkspaceFile } from "types/FileStorage";

interface DocumentHeaderProps {
  id: string;
  isSelected: boolean;
  onSelect: (id: string, checked: boolean) => void;
  docData: WorkspaceFile;
}

const DocumentHeader: React.FC<DocumentHeaderProps> = ({ id, isSelected, onSelect, docData }) => (
  <div className="absolute top-3 left-3 z-10" onClick={(e) => e.stopPropagation()}>
    <Checkbox checked={isSelected} onCheck={(checked) => onSelect(id, checked)} />
  </div>
);

export default DocumentHeader;

import BasicSidebarItem from "components/atoms/basic-sidebar-item";
import { CAPTURE_SCHEMA_TYPE_PREFIX } from "const-values/Capture";
import { useSearchParams } from "react-router-dom";
import type { CaptureSchema } from "types/Capture";
import { useGetNormalizedNavigationLink } from "./hooks";

type Props = {
  schema: CaptureSchema;
};

const CaptureSchemaItem = ({ schema }: Props) => {
  const [searchParams] = useSearchParams();
  const getNormalizedNavigationLink = useGetNormalizedNavigationLink();
  const slug = schema.type.replace(`${CAPTURE_SCHEMA_TYPE_PREFIX}:`, "");

  return (
    <BasicSidebarItem
      to={{ pathname: getNormalizedNavigationLink(slug), search: searchParams.toString() }}
      data-testid={schema.title}
    >
      {schema.title}
    </BasicSidebarItem>
  );
};

export default CaptureSchemaItem;

/** @jsxImportSource @emotion/react */

import { Avatar } from "components/molecules/avatar";
import { useAppSelector } from "store/storeTypes";
import { Download } from "lucide-react";
import { theme } from "twin.macro";
import { AVATAR_BG_COLOR } from "const-values/assistants/constants";

const FilesBlock = () => {
  const { currentUser } = useAppSelector((root) => root.auth);
  const { uploadedDocuments } = useAppSelector((root) => root.aiAssistantState);

  return (
    <div className="flex gap-2 w-full">
      <Avatar
        size={26}
        id={currentUser?.username}
        name={currentUser?.username}
        className="text-base"
        isCircle={false}
        bgColor={AVATAR_BG_COLOR}
        textColor={theme`colors.gray.darkest`}
      />
      <div className="flex flex-col gap-3 flex-1 min-w-0">
        <div className="flex flex-col gap-2">
          {uploadedDocuments &&
            uploadedDocuments.map((doc, index) => (
              <div key={index} className="flex flex-col justify-center gap-2">
                <a
                  href={doc.download_url}
                  download={doc.name}
                  className="truncate text-sm text-blue-500 flex items-center gap-1.5"
                  title={`Download ${doc.name}`}
                >
                  {doc.name}
                  <Download size={14} />
                </a>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FilesBlock;

type Props = {
  percent: number;
  progressBarColor?: string;
};

const ProgressBar = ({ percent, progressBarColor }: Props) => {
  const computedStyle = {
    width: percent + "%",
    ...(progressBarColor ? { background: progressBarColor } : {}),
  };

  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5">
      <div
        className={`h-2.5 rounded-full transition-all ${!progressBarColor ? "bg-action" : ""}`}
        style={computedStyle}
      ></div>
    </div>
  );
};

export default ProgressBar;

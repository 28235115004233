/** @jsxImportSource @emotion/react */
import { Modal } from "../../../modal";
import { useAppDispatch } from "store/storeTypes";
import { toggleRecentFilesModal } from "store/reducers/modalsSlice";
import tw from "twin.macro";
import RecentFilesModalHeader from "./RecentFilesModalHeader";
import RecentFilesModalFooter from "./RecentFilesModalFooter";
import RecentFilesModalBody from "./RecentFilesModalBody";
import { useEffect, useState } from "react";

export type RecentFilesModalProps = {
  open: boolean;
};

const RecentFilesModal = ({ open }: RecentFilesModalProps) => {
  const dispatch = useAppDispatch();

  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

  useEffect(() => {
    setSelectedDocuments([]);
  }, [open]);

  return (
    <Modal
      modal
      open={open}
      onOpenChange={(openState) => {
        dispatch(toggleRecentFilesModal({ open: openState }));
      }}
      contentProps={{ css: tw`!min-w-[1100px] !max-w-[1100px]` }}
      title="Recent Files"
      header={<RecentFilesModalHeader />}
      body={<RecentFilesModalBody setSelectedDocuments={setSelectedDocuments} />}
      footer={<RecentFilesModalFooter selectedDocuments={selectedDocuments} />}
    />
  );
};

export default RecentFilesModal;

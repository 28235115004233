/** @jsxImportSource @emotion/react */

import * as Accordion from "@radix-ui/react-accordion";
import { Checkbox } from "components/atoms/checkbox";
import { ChevronRight } from "lucide-react";
import tw from "twin.macro";
import SectionRow from "./SectionRow";
import type { FormattedVolumeWithRequirementCount } from "types/Volume";
import { useMemo } from "react";
import sumBy from "lodash/sumBy";
import Tooltip from "components/atoms/tooltip";

type Props = {
  isReadOnly: boolean;
  volume: FormattedVolumeWithRequirementCount;
  onCheck: (checked: boolean, reference: Partial<{ volumes: string[]; sections: string[] }>) => void;
  onPageCountSelect: (sectionId: string, count: number) => void;
};

const VolumeAndSectionsTableRow = ({ volume, onCheck, onPageCountSelect, isReadOnly }: Props) => {
  const flattenedSections = useMemo(
    () => volume.sections.flatMap((section) => [section, ...(section.subsections || [])]),
    [volume.sections],
  );
  const volumePageCount = useMemo(() => sumBy(flattenedSections, "draft_page_count") || "-", [flattenedSections]);
  const allSectionsWithoutPageCounts = useMemo(
    () => flattenedSections.filter(({ draft_page_count }) => !draft_page_count),
    [flattenedSections],
  );
  const shouldShowPageCountWarning = volumePageCount != "-" && allSectionsWithoutPageCounts.length;

  return (
    <Accordion.Item value={volume.id}>
      <Accordion.Header className="bg-white sticky top-0 z-[1]">
        <div className="flex flex-col gap-1 select-none">
          <div className="cursor-default relative overflow-hidden group border-b border-gray-lightest bg-white text-gray-darkest">
            <div className="flex flex-row gap-0.5 items-center justify-between">
              <div className="px-4">
                <Checkbox
                  checked={volume.checked}
                  disabled={isReadOnly}
                  onCheck={(checked) => {
                    onCheck(checked, {
                      volumes: [volume.id],
                      sections: volume.sections.flatMap((section) => [
                        section.id,
                        ...(section.subsections || []).map(({ id }) => id),
                      ]),
                    });
                  }}
                />
              </div>
              <Accordion.Trigger className="flex group flex-row items-center w-[calc(100%-50px)]">
                <div title={volume.title} className="flex flex-row flex-1 gap-1.5 items-center min-w-0 duration-100">
                  <ChevronRight
                    size={14}
                    className="flex-shrink-0 transition-transform mr-0.5 duration-200 group-data-[state=open]:rotate-90"
                  />
                  <div className="text-sm py-3.5 w-full min-w-0">
                    <div className="flex flex-row justify-between items-center min-w-0">
                      <span
                        className="whitespace-nowrap font-medium truncate"
                        css={[!volume.title.trim() && tw`text-gray-400`]}
                      >
                        {volume.title.trim() || "Volume title..."}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-36 text-center text-sm text-zinc-600">{volume.requirementCount}</div>
                <div className="w-36 text-sm text-zinc-500 flex items-center justify-center">
                  <Tooltip
                    disabled={!shouldShowPageCountWarning}
                    content={`${allSectionsWithoutPageCounts.length}/${flattenedSections.length} sections do not have a specified page limit. Vultron will automatically allocate the page counts for these sections, which may result in additional pages being allocated to this volume.`}
                  >
                    <div
                      className="bg-gray-100 rounded flex items-center justify-center text-sm border border-gray-100 h-6 min-w-6"
                      css={[shouldShowPageCountWarning && tw`border-yellow-400 border-[1.5px]`]}
                    >
                      {volumePageCount}
                    </div>
                  </Tooltip>
                </div>
              </Accordion.Trigger>
            </div>
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Content className="collapsibleContent">
        <div
          className="border-b border-gray-lightest flex flex-col pl-[34px]"
          css={[!volume.sections.length && tw`text-gray-500 pl-[72px] py-1 text-sm`]}
        >
          {!volume.sections.length && "No sections available"}
          {volume.sections.map((section, i) => (
            <SectionRow
              onPageCountSelect={onPageCountSelect}
              key={section.id}
              onCheck={(checked, sections) => {
                const allUncheckedSections = volume.sections
                  .flatMap((section) => [section, ...(section.subsections || [])])
                  ?.filter((section) => !section.checked);

                const shouldCheckVolume = checked && allUncheckedSections.every(({ id }) => sections.includes(id));

                onCheck(checked, { volumes: !checked || shouldCheckVolume ? [volume.id] : [], sections });
              }}
              isReadOnly={isReadOnly}
              section={section}
              isLastSection={volume.sections.length - 1 === i}
              showConnection={section.subsections?.length ? false : volume.sections.length - 1 !== i}
            />
          ))}
        </div>
      </Accordion.Content>
    </Accordion.Item>
  );
};

export default VolumeAndSectionsTableRow;

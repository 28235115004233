import { useEffect, useState } from "react";

import { useMergeRequirements } from "../hooks";
import { useSelection } from "../SelectionContext";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { useDocViewNotification } from "components/copilot/extract-v2/context/doc-view-notification-context/context";
import { CircleCheck, Merge } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import type { OrderKey } from "store/reducers/extract/CurrentExtractionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import * as logger from "utils/log";

const MergeRequirementsButton = () => {
  const [searchParams] = useSearchParams();
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const isLoadingOrderKeys = useAppSelector((store) => store.currentExtractionState.isLoadingOrderKeys);
  const orderKeys = useAppSelector((store) => store.currentExtractionState.orderKeys);
  const projectId = searchParams.get("id");
  const dispatch = useAppDispatch();
  const { mergeRequirements, isLoading: isMergingRequirements } = useMergeRequirements();
  const { selectedBlocks = [] } = useSelection();
  const { setToast } = useDocViewNotification();

  const [isMergable, setIsMergable] = useState(false);

  useEffect(() => {
    if (isLoadingOrderKeys || !orderKeys || !selectedBlocks.length) return;

    const selectedOrderKeys = selectedBlocks
      .map((block) => orderKeys[block.requirement.requirement.id])
      .filter((orderKey): orderKey is OrderKey => orderKey !== undefined);

    if (!selectedOrderKeys.length) return;

    // This is needed because users can select items out of order
    const sortedOrderKeys = [...selectedOrderKeys].sort((a, b) => a.start_order_key - b.start_order_key);

    const isConsecutive = sortedOrderKeys.every((key, index, arr) => {
      return index === 0 || arr[index - 1].end_order_key === key.start_order_key - 1;
    });

    setIsMergable(isConsecutive);
  }, [isLoadingOrderKeys, selectedBlocks, orderKeys, projectId, dispatch]);

  return (
    <Tooltip
      content="Only contiguous requirement are able to be merged."
      disabled={isMergable}
      contentProps={{ style: { maxWidth: "600px" } }}
    >
      <button
        onClick={async () => {
          if (!extraction?.id || isMergingRequirements) return;
          const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

          try {
            await mergeRequirements({ requirement_ids: selectedReqIds });

            setToast.success({
              msg: (
                <div className="flex flex-row gap-2 items-center text-sm text-white">
                  <CircleCheck size={14} />
                  <span>{`${selectedReqIds.length} requirements merged.`}</span>
                </div>
              ),
            });
          } catch {
            logger.error("Failed to merge requirements");
          }
        }}
        className="text-xs flex items-center gap-2 max-w-80 font-medium border px-2.5 py-1.5 border-gray-400 rounded duration-100 text-gray-200 hover:text-white hover:border-gray-100 hover:bg-black disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-transparent disabled:border-gray-500"
        disabled={isMergingRequirements || isLoadingOrderKeys || !isMergable}
      >
        {isMergingRequirements || isLoadingOrderKeys ? <SpinnerCircle className="h-3.5 w-3.5" /> : <Merge size={14} />}

        <div className="text-xs">Merge</div>
      </button>
    </Tooltip>
  );
};

export default MergeRequirementsButton;

import Tooltip from "components/atoms/tooltip";
import ProjectAttachmentsDropdown from "components/molecules/project-attachments-dropdown";
import useGetActiveProjectId from "hook/Project/useGetActiveProjectId";
import useUploadProjectFiles from "hook/Project/useUploadProjectFiles";
import { ChevronDown, LucideX, UploadCloudIcon } from "lucide-react";
import { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { setActiveProjectFile, setDocumentViewerOpen } from "store/reducers/proposal/proposalReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import type { InternalDocument } from "types/Project";
import FileUploadLoader from "./FileUploadLoader";
import DropItemsContainer from "components/atoms/drop-items-container";
import tw from "twin.macro";
import EmptyStateContainer from "./EmptyStateContainer";

const DocumentSelectionViewer = () => {
  const dispatch = useAppDispatch();
  const internalContractId = useGetActiveProjectId();
  const activeProjectFile = useAppSelector((store) => store.proposal.activeProjectFile);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const { uploadProjectFiles, isLoading: isUploadingFiles, isCompleted } = useUploadProjectFiles(internalContractId);
  const onHandleFileUpload = (files: FileList | File[]) => {
    uploadProjectFiles(files).then((uploadedFile) => {
      if (uploadedFile) onSelectFile(uploadedFile);
    });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onHandleFileUpload,
    noClick: true,
    accept: { "application/pdf": [".pdf"] },
  });

  const file = activeProjectFile?.secure_preview_url;
  const onSelectFile = useCallback((file: InternalDocument) => dispatch(setActiveProjectFile(file)), [dispatch]);

  return (
    <div className="flex flex-col h-full">
      <div className="h-16 flex flex-nowrap gap-x-16 border-r-0 items-center justify-between w-full bg-white border-b border-gray-200 shadow-sm z-10 px-4 py-1">
        <ProjectAttachmentsDropdown
          acceptedFiles={["pdf"]}
          triggerProps={{ className: "min-w-0" }}
          contentProps={{ align: "start" }}
          onSelect={onSelectFile}
          isUploadingFiles={isUploadingFiles}
          isCompleted={isCompleted}
          uploadProjectFiles={uploadProjectFiles}
        >
          <div className="min-w-0 py-2 px-2.5 flex items-center gap-1 text-sm text-gray-darkest duration-100 transition-colors rounded-md bg-white hover:bg-gray-100">
            <div className="truncate w-full">{activeProjectFile?.name || "Select file"}</div>{" "}
            <ChevronDown className="ml-2 shrink-0" size={18} />
          </div>
        </ProjectAttachmentsDropdown>
        <Tooltip content="Close file">
          <button
            onClick={() => dispatch(setDocumentViewerOpen(false))}
            className="text-gray-darkest duration-150 transition-colors hover:text-gray-500"
          >
            <LucideX size={22} />
          </button>
        </Tooltip>
      </div>
      <div {...getRootProps()} className="relative flex-1 w-full min-h-0 z-[3]">
        {isDragActive && (
          <div className="z-[1]">
            <DropItemsContainer
              styles={[tw`bg-zinc-300/80`]}
              dropLabel={
                <div className="w-full h-full flex items-center justify-center">
                  <div className="w-2/3 max-w-[400px] rounded-lg px-6 py-8 border-gray-darkest border-dashed border-2 flex flex-col justify-center items-center gap-1">
                    <UploadCloudIcon size={22} />
                    <div className="font-medium text-base">Drag and drop files here</div>
                    <div className="text-gray-600 text-sm">We accept pdf and docx</div>
                  </div>
                </div>
              }
            />
          </div>
        )}
        <div className="flex-1 h-full relative flex bg-gray-lightest">
          {file ? (
            <object data={file} type="application/pdf" width="100%" height="100%" />
          ) : (
            <EmptyStateContainer
              inputFileNode={inputFileRef.current}
              isUploadingFiles={isUploadingFiles}
              isCompleted={isCompleted}
              activeProjectFile={activeProjectFile}
            />
          )}
        </div>
        {(isUploadingFiles || isCompleted) && activeProjectFile && (
          <div className="w-full h-full bg-zinc-300/80 backdrop-blur-[2px] flex justify-center items-center z-[1] absolute inset-0">
            <FileUploadLoader isCompleted={isCompleted} isUploadingFiles={isUploadingFiles} />
          </div>
        )}
      </div>
      <input accept=".pdf" className="hidden" {...getInputProps()} />
      <input
        type="file"
        multiple
        ref={inputFileRef}
        className="hidden"
        accept=".pdf"
        onChange={async (e) => {
          if (e.target.files?.length) onHandleFileUpload(e.target.files);
        }}
      />
    </div>
  );
};

export default DocumentSelectionViewer;

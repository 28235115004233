import { useCallback } from "react";
import {
  setActiveMetadata,
  setAiAssistantState,
  setStreamingState,
} from "store/reducers/ai-assistant/aiAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { useNotification } from "context/notificationContext";
import { useLocalStorage } from "hook/useLocalStorage";
import { createCoreBlock, createVultronBlock } from "utils/aiAssistant";
import { getWordCount } from "utils/getWordCount";
import { useTrackUserMetric } from "utils/metrics";
import { useObserveSseController } from "hook/useObserveSseController";
import { DELIMITER } from "./constants";
import * as logger from "utils/log";
import type {
  AIAssistant,
  AIAssistantBlock,
  AIAssistantSession,
  BlockSource,
  VultronBlock,
} from "utils/yjs-configs/ai-assistant/schema";
import { AssistantBlockType } from "utils/yjs-configs/ai-assistant/schema";
import { useNavigate } from "react-router-dom";
import { useMutation } from "utils/yjs-configs/ai-assistant/yjs.config";
import { createAIAssistantSession } from "utils/Liveblocks/AIAssistant";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { find, LiveList, LiveObject, update } from "YJSProvider/LiveObjects";
import type { StreamStopMsgData } from "types/Streaming/streamConfig";
import { StreamEvent, StreamEventType } from "types/Streaming/streamConfig";
import { HEARTBEAT } from "const-values/Stream";
import { apiUrl } from "config/vultronConfig";
import type { InputField } from "types/Chat";
import type { FileMention } from "types/Assistants/types";
import { useFlags } from "hook/useFlags";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";
import { getAndBuildInputMetadataFromBody } from "utils/assistants/utils";

type PastMessage = {
  content: string;
  is_assistant: boolean;
  sent_at: string;
  sources: ToImmutable<VultronBlock["sources"]>;
};

type SendMessageVariables = {
  user_request: string;
  search_file_ids: string[];
  past_messages: PastMessage[];
  use_internet?: boolean;
  chat_document_ids?: string[];
  hide_user_block?: boolean;
  is_first_message?: boolean;
  session_id?: string;
  input_field?: InputField;
};

let controller = new AbortController();
export const useAssistant = () => {
  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0", "");
  const useAuth0Header = use_auth0 === true;
  const { setToast } = useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { prompt, selectedFiles, streamState, enableInternet, activeSession, uploadedDocuments } = useAppSelector(
    (root) => root.aiAssistantState,
  );
  const myConversation = activeSession?.conversation as ToImmutable<AIAssistantBlock>[] | undefined;

  const canSubmit = !streamState.isStreamingInProgress;
  const canRefresh = !streamState.isStreamingInProgress;
  const trackUserEvent = useTrackUserMetric();
  const flags = useFlags();

  const acceptUploadedDocumentArgument =
    uploadedDocuments?.length &&
    !enableInternet &&
    // If the user is in a workflow, then permit even if the feature flag is off
    (!flags.hideAttachDocument || activeSession?.workflow) &&
    // We don't want to remove uploaded documents so this is to ensure they're not included in requests for other input types
    activeSession?.input_field_type !== INPUT_FIELD_OPTIONS.contentLibrary &&
    flags.documentAttachments;

  const sendMessage = useMutation(
    ({ storage }, messagePayload: SendMessageVariables, selectedFiles?: FileMention[], refreshId?: string) => {
      let text = "";
      let vultronBlock: LiveObject<VultronBlock>;
      const isNewMessage = !refreshId;
      const liveSessions = storage.get("ai_assistant")?.get("sessions") as AIAssistant["sessions"];
      let liveSession: LiveObject<AIAssistantSession> | undefined = liveSessions
        ? find(liveSessions, (session) => session.get("id") === activeSession?.id)
        : undefined;

      if (!liveSession) {
        const newSession = createAIAssistantSession({ name: messagePayload.user_request.slice(0, 150) });
        liveSessions.push([newSession]);
        liveSession = newSession;
        navigate(`/dashboard/ai-assistant/${newSession.get("id")}`);
      }

      const myConversation = liveSession?.get("conversation") as AIAssistantSession["conversation"];

      const determineBlockType = () => {
        if (vultronBlock.get("enableInternet")) {
          return "internet";
        } else if (vultronBlock.get("promptSources")?.length) {
          return "content search";
        } else {
          return "other";
        }
      };

      const sourceLength = () => {
        if (vultronBlock.get("sources")?.length) {
          return vultronBlock.get("sources")?.length;
        } else {
          return 0;
        }
      };

      if (!isNewMessage && myConversation) {
        const foundBlock = find(
          myConversation,
          (block) => block.get("type") === AssistantBlockType.VultronBlock && block.get("id") === refreshId,
        ) as LiveObject<VultronBlock>;
        if (!foundBlock) return;
        vultronBlock = foundBlock;
        dispatch(setStreamingState({ isStreamingInProgress: true, blockId: refreshId }));
        update(vultronBlock, { error: false, sources: new LiveList([] as LiveObject<BlockSource>[]) });
      } else {
        const liveSources =
          selectedFiles?.map((file) => new LiveObject({ ...file, extension_type: file.fileExtensionType })) || [];
        const userBlock = createCoreBlock({
          body: messagePayload.user_request,
          sources: new LiveList(liveSources),
          inputFileType: messagePayload.input_field?.input_field_type,
        });
        vultronBlock = createVultronBlock({
          prompt: messagePayload.user_request,
          promptSources: messagePayload.search_file_ids,
          error: false,
          enableInternet: messagePayload.use_internet,
          inputFileType: messagePayload.input_field?.input_field_type,
        }) as LiveObject<VultronBlock>;
        if (myConversation) {
          myConversation.push([userBlock]);
          myConversation.push([vultronBlock]);
        }
        dispatch(setStreamingState({ isStreamingInProgress: true, blockId: vultronBlock.get("id") }));
        dispatch(setAiAssistantState({ prompt: "" }));
        dispatch(setActiveMetadata(undefined));
      }

      fetchEventSource(`${apiUrl}/chat/ai_assistant/send_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Workspace: `Workspace ${workspace_id}`,
          Authorization: `Bearer ${localValue}`,
          "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
          Accept: "application/json",
        },
        body: JSON.stringify(messagePayload),
        signal: controller.signal,
        openWhenHidden: true,
        onmessage(msg) {
          if (msg.event === StreamEvent.StreamRestart) {
            text = msg.data || "";
            dispatch(setStreamingState({ streamCopy: text }));
            update(vultronBlock, { body: text, sources: new LiveList([]), error: false });
            return;
          }

          if (msg.event === StreamEvent.StreamStop) {
            abortConnection();

            try {
              const parsedData = JSON.parse(msg.data) as StreamStopMsgData;
              if (parsedData.type === StreamEventType.Repetition) {
                text = "";
                update(vultronBlock, { body: "", sources: new LiveList([]), error: true });
              }
              if (parsedData.reason) {
                setToast.error({
                  msg: parsedData.reason,
                });
              }
            } catch {
              // silently ignore
            }
            return;
          }
          if (msg?.data === HEARTBEAT) return;

          if (msg.data?.length) {
            if (!text) liveSession?.set("updated_at", new Date().toISOString());

            try {
              const parsed = JSON.parse(msg.data);
              if (typeof parsed !== "object" && !parsed?.sources) throw new Error("error");
              const sources = (parsed.sources as BlockSource[]).map((source) => {
                const patchedSource = { ...source, date: source.date || new Date().toISOString() };
                return new LiveObject(patchedSource);
              });
              vultronBlock.set("sources", new LiveList(sources));
            } catch {
              if (msg.data !== DELIMITER) {
                text += msg.data;
                dispatch(setStreamingState({ streamCopy: text }));
              }
            }
          } else if (typeof msg.data === "string") {
            text += "\n";
            dispatch(setStreamingState({ streamCopy: text }));
            vultronBlock.set("body", text);
          }
        },
        async onopen(response) {
          if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
            return; // everything's good
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            setToast.error({
              title: "Unable to send message",
              msg: "We were unable to send message due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
            });
            vultronBlock.set("error", true);
            dispatch(setStreamingState({}));
            logger.error("Ai assistant failed", response);
          }
        },
        onclose() {
          vultronBlock.set("body", text);
          const inputMetadata = getAndBuildInputMetadataFromBody(text);
          if (inputMetadata) {
            vultronBlock.set("input_metadata", { type: inputMetadata.type, data: inputMetadata.data });
          }
          setTimeout(() => dispatch(setStreamingState({})), 100);
          trackUserEvent("AI Assistant: Message Recieved", {
            type: determineBlockType(),
            word_count: getWordCount(vultronBlock.get("body") || ""),
            number_sources: sourceLength(),
          });
        },
        onerror(err) {
          setToast.error({
            title: "Unable to send message",
            msg: "We were unable to send message due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
          vultronBlock.set("error", true);
          dispatch(setStreamingState({}));
          if (err instanceof Error) {
            logger.error(err);
            throw err;
          }
        },
      });
    },
    [activeSession?.id, dispatch, localValue, navigate, setToast, trackUserEvent, workspace_id],
  );

  const abortConnection = useCallback(() => {
    if (activeSession?.workflow) {
      return;
    }
    controller.abort();
    controller = new AbortController();
    dispatch(setStreamingState({}));
  }, [dispatch]);

  useObserveSseController(abortConnection);

  const submitMessage = useCallback(
    (message?: string, sendMessageProperties?: Partial<SendMessageVariables>) => {
      if (!canSubmit) return;

      const inputFieldType = activeSession?.input_field_type;
      const userRequest = message ? message : prompt;
      const lastTwenty = myConversation?.slice(-20).filter(({ body }) => !!body.trim()) || [];
      const hideUserBlock =
        inputFieldType === INPUT_FIELD_OPTIONS.checklist || inputFieldType === INPUT_FIELD_OPTIONS.checklistGroup;
      const contentLibraryFiles = enableInternet ? [] : selectedFiles;

      sendMessage(
        {
          user_request: activeSession?.workflow && !prompt && !message ? "" : userRequest,
          search_file_ids: contentLibraryFiles.map(({ id }) => id),
          past_messages: lastTwenty.map(({ body, type, updated_at, sources }) => ({
            content: body,
            is_assistant: type === AssistantBlockType.VultronBlock,
            sent_at: updated_at,
            sources:
              type === AssistantBlockType.CoreBlock
                ? []
                : (sources || [])
                    .filter((source) => !!source.content?.trim())
                    .map((source) => ({
                      ...source,
                      date: source.date || updated_at,
                    })),
          })),
          use_internet: enableInternet,
          ...(acceptUploadedDocumentArgument && {
            chat_document_ids: uploadedDocuments.map(({ id }) => id),
          }),
          is_first_message: myConversation?.length === 0,
          ...(activeSession?.workflow && {
            session_id: activeSession?.id,
          }),
          ...(activeSession?.input_field && {
            input_field: activeSession.input_field,
          }),
          hide_user_block: hideUserBlock,
          ...sendMessageProperties,
        },
        contentLibraryFiles,
      );
      if (activeSession) {
        const { input_field, ...updatedActiveSession } = activeSession;
        dispatch(setAiAssistantState({ activeSession: updatedActiveSession }));
      }
    },
    [
      canSubmit,
      enableInternet,
      myConversation,
      prompt,
      selectedFiles,
      sendMessage,
      activeSession,
      dispatch,
      acceptUploadedDocumentArgument,
      uploadedDocuments,
    ],
  );

  const refreshMessage = useCallback(
    (refreshId: string) => {
      if (!canRefresh) return;

      const blockIdx = myConversation?.findIndex(({ id }) => refreshId === id);
      if (typeof blockIdx !== "number" || blockIdx === -1) return;

      const block = myConversation?.[blockIdx];
      if (block?.type !== AssistantBlockType.VultronBlock || !block.prompt?.trim()) return;
      const lastTwenty =
        myConversation
          ?.slice(0, blockIdx)
          .slice(-20)
          .filter(({ body }) => !!body.trim()) || [];
      sendMessage(
        {
          user_request: block.prompt,
          search_file_ids: block.promptSources || [],
          past_messages:
            lastTwenty?.map(({ body, type, updated_at, sources }) => ({
              content: body,
              is_assistant: type === AssistantBlockType.VultronBlock,
              sent_at: updated_at,
              sources:
                type === AssistantBlockType.CoreBlock
                  ? []
                  : (sources || [])
                      .filter((source) => !!source.content?.trim())
                      .map((source) => ({
                        ...source,
                        date: source.date || updated_at,
                      })),
            })) || [],
          use_internet: block.enableInternet,
          ...(uploadedDocuments?.length && {
            chat_document_ids: uploadedDocuments.map(({ id }) => id),
          }),
        },
        undefined,
        refreshId,
      );
    },
    [canRefresh, myConversation, sendMessage],
  );

  return { abortConnection, refreshMessage, submitMessage };
};

import { ArchiveRestore, Trash2 } from "lucide-react";
import type { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import { useMemo } from "react";
import type { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import type { WritingAssistantSession } from "components/copilot/CopilotSchemaTypes";

export const useArchivedRowActions = <T extends AIAssistantSession | WritingAssistantSession>({
  session,
  onUnarchiveSession,
  onDeleteArchivedSession,
}: {
  session: ToImmutable<T>;
  onUnarchiveSession: (sessionId: string) => void;
  onDeleteArchivedSession: (sessionId: string) => void;
}): MenuItem<void>[] => {
  return useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex flex-row items-center gap-2">
            <ArchiveRestore size={14} />
            Unarchive
          </div>
        ),
        onSelect: () => onUnarchiveSession(session.id),
      },
      {
        key: 2,
        label: (
          <div className="text-red-500 flex flex-row items-center gap-2">
            <Trash2 size={14} />
            Delete
          </div>
        ),
        onSelect: () => {
          triggerConfirm({
            proceedLabel: "Delete",
            header: "Delete chat?",
            body: (
              <div>
                This will delete <span className="font-semibold">{session.name}</span>
              </div>
            ),
          }).then((proceed) => {
            if (proceed) {
              onDeleteArchivedSession(session.id);
            }
          });
        },
      },
    ],
    [onDeleteArchivedSession, session.id, session.name, onUnarchiveSession],
  );
};

export const deadlineOptions = [
  { name: "Next Week", value: "Next Week" },
  { name: "Next Month", value: "Next Month" },
  { name: "Next 6 Months", value: "Next 6 Months" },
  { name: "Next Year", value: "Next Year" },
] as const;

export const publishedDateOptions = [
  { name: "Yesterday", value: "Yesterday" },
  { name: "Last Week", value: "Last Week" },
  { name: "Last Month", value: "Last Month" },
  { name: "Last 6 Months", value: "Last 6 Months" },
  { name: "Last Year", value: "Last Year" },
] as const;

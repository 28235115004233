import { Plugin, PluginKey } from "@tiptap/pm/state";
import type { Mark } from "@tiptap/pm/model";
import type { EditorState, Transaction } from "@tiptap/pm/state";

type RestoreThreadPluginOptions = {
  removeThread: (threadId: string) => void;
  restoreThread: (threadId: string) => void;
};

const restoreThreadPluginKey = new PluginKey("restoreThread");

export const createRestoreThreadPlugin = (options: RestoreThreadPluginOptions) => {
  const deletedCommentIds = new Set<string>();

  return new Plugin({
    key: restoreThreadPluginKey,
    state: {
      init() {},

      apply(tr: Transaction, value: any, oldState: EditorState, newState: EditorState) {
        // Check if the transaction is a user action - delete from comments panel
        const isUserAction = tr.getMeta("userAction");
        if (isUserAction) {
          return;
        }

        const oldCommentIds = new Set<string>();
        const newCommentIds = new Set<string>();

        // Collect comment IDs from the old document state
        oldState.doc.descendants((node) => {
          node.marks.forEach((mark: Mark) => {
            if (mark.type.name === "comment" && mark.attrs.id) {
              oldCommentIds.add(mark.attrs.id);
            }
          });
        });

        // Collect comment IDs from the new document state
        newState.doc.descendants((node) => {
          node.marks.forEach((mark: Mark) => {
            if (mark.type.name === "comment" && mark.attrs.id) {
              newCommentIds.add(mark.attrs.id);
            }
          });
        });

        // Determine removed comment IDs
        const removedCommentIds = new Set<string>();
        oldCommentIds.forEach((id) => {
          if (!newCommentIds.has(id)) {
            removedCommentIds.add(id);
          }
        });

        // Determine restored comment IDs
        const restoredCommentIds = new Set<string>();
        newCommentIds.forEach((id) => {
          if (deletedCommentIds.has(id)) {
            restoredCommentIds.add(id);
          }
        });

        // Handle removed comment IDs
        removedCommentIds.forEach((id: string) => {
          options.removeThread(id);
          deletedCommentIds.add(id);
        });

        // Handle restored comment IDs
        restoredCommentIds.forEach((id: string) => {
          options.restoreThread(id);
          deletedCommentIds.delete(id);
        });
      },
    },
  });
};

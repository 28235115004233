/** @jsxImportSource @emotion/react */

import { useAppSelector } from "store/storeTypes";
import { useInstructionState, useResetWritingAssistantSelection, useTriggerWorkflowMessage } from "./hooks";
import { memo, useMemo } from "react";
import { assistantInputComponentToRender } from "utils/assistants/project-assistant/assistantInputComponentToRender";

const WritingAssistantInput = () => {
  const inputType = useAppSelector((store) => store.writingAssistant.activeSession?.input_field_type);
  useResetWritingAssistantSelection();
  useTriggerWorkflowMessage();
  const instructionState = useInstructionState();

  const AssistantInputComponent = useMemo(() => assistantInputComponentToRender(inputType), [inputType]);

  return (
    <div className="flex flex-col h-full">
      <AssistantInputComponent instructionExtraState={instructionState} />
    </div>
  );
};

export default memo(WritingAssistantInput);

import { deleteADoc } from "api/api";
import { Upload } from "lucide-react";
import { getProject } from "store/reducers/projectReducer";
import type { InternalDocument } from "types/Project";
import { useNotification } from "context/notificationContext";
import { useOutletContext } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFlags } from "hook/useFlags";
import ProjectSpecificDocumentsTableRow from "./ProjectSpecificDocumentsTableRow";
import useUploadProjectFiles from "hook/Project/useUploadProjectFiles";
import ProjectSpecificEmptyState from "./ProjectSpecificEmptyState";
import * as logger from "utils/log";

const ProjectSpecific = () => {
  const { internalContractId } = useOutletContext<{
    internalContractId: string;
  }>();
  const internalContractDetails = useAppSelector((store) => store.project.activeProject);

  const dispatch = useAppDispatch();
  const flags = useFlags();
  const { uploadProjectFiles } = useUploadProjectFiles(internalContractId);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [allDocs, setAllDocs] = useState<InternalDocument[]>([]);
  const [deletingDocs, setDeletingDocs] = useState<string[]>([]);

  const { setToast } = useNotification();

  const onButtonClick = () => {
    if (inputFile && inputFile.current) inputFile.current.click();
  };
  const acceptDocTypes = flags.enableOnsiteFeatures ? ".pdf" : ".pdf,.docx,.txt,.xlsx,.pptx";

  const uploadFiles = (fileList: any) => {
    const files = [...fileList];
    const newFiles: any[] = [];
    files.forEach((file: any) => {
      if (allDocs.find((doc) => doc.name === file.name)) {
        return;
      } else {
        newFiles.push(file);
      }
    });
    uploadProjectFiles(newFiles);
  };

  useEffect(() => {
    if (internalContractDetails) {
      const government_sources = internalContractDetails?.government_source || [];
      const internal_documents = internalContractDetails?.internal_documents || [];
      const combined_sources = [...government_sources, ...internal_documents];
      combined_sources.sort((a, b) => {
        return new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf();
      });
      setAllDocs(combined_sources);
    }
  }, [
    internalContractDetails,
    internalContractDetails?.government_source,
    internalContractDetails?.internal_documents,
  ]);

  useEffect(() => {
    dispatch(getProject({ internalContractId, triggerDocRefresh: true }));
  }, [dispatch, internalContractId]);

  const deleteInternalDoc = useCallback(
    (id: string) => {
      if (deletingDocs.includes(id)) return;
      deleteADoc(id)
        .then(() => {
          dispatch(getProject({ internalContractId, triggerDocRefresh: true }));
          setTimeout(() => {
            setToast.success({
              msg: `Your document was deleted successfully`,
            });
            setDeletingDocs((prev) => prev?.filter((b) => b !== id));
          }, 500);
        })
        .catch((err) => {
          setToast.error({
            title: "Unable to delete document",
            msg: "We were unable to delete the document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
          logger.error("Error deleting document", err);
          setDeletingDocs((prev) => prev?.filter((b) => b !== id));
        });
    },
    [deletingDocs, setToast, setDeletingDocs, dispatch, internalContractId],
  );

  return (
    <div className="py-2 px-5">
      <div className="mt-2 w-full">
        <div className="flex justify-end mb-4">
          <button
            className="text-gray-900 flex items-center hover:border-gray-300 border border-gray-200 bg-gray-100 cursor-pointer text-sm px-2 py-1.5 rounded gap-2"
            onClick={onButtonClick}
          >
            <Upload size={14} />
            Upload Document
          </button>
          <input
            type="file"
            id="input"
            multiple
            ref={inputFile}
            className="hidden"
            accept={acceptDocTypes}
            onChange={(event) => uploadFiles(event.target.files)}
          />
        </div>
        <div className="max-w-full overflow-auto border rounded-md">
          <table className="table-auto w-full">
            <thead className="text-sm bg-neutral-100 border-b sticky">
              <tr className="text-left text-gray-500">
                <th className="px-6 py-3 font-medium text-sm text-dark">File</th>
                <th className="px-4 py-3 w-52 min-w-52 font-medium text-sm text-dark">
                  {!allDocs.length ? "" : "Content Type"}
                </th>
              </tr>
            </thead>
            <tbody>
              {allDocs.map((doc) => {
                return (
                  <ProjectSpecificDocumentsTableRow
                    key={doc.id}
                    doc={doc}
                    deletingDocs={deletingDocs}
                    deleteInternalDoc={deleteInternalDoc}
                    setDeletingDocs={setDeletingDocs}
                  />
                );
              })}
              {!allDocs.length && <ProjectSpecificEmptyState uploadFiles={uploadFiles} />}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProjectSpecific;

/** @jsxImportSource @emotion/react */

import { Avatar } from "components/molecules/avatar";
import { FilterOption } from "components/copilot/ComplianceMatrix/utils";
import { REQUIREMENT_STATUS_TO_META } from "const-values/Draft";
import { RequirementStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import { useAppSelector } from "store/storeTypes";
import { useMemo } from "react";
import type { User } from "types/User";
import { RiFilterLine, RiFilterFill } from "@remixicon/react";
import tw from "twin.macro";
import { DropdownMenu } from "components/molecules/dropdown-menu";

type SearchFilters = {
  assignees: string;
  requirementStatusType: string;
};

interface DraftSectionRequirementsFiltersProps {
  searchFilters: SearchFilters;
  setSearchFilters: React.Dispatch<React.SetStateAction<SearchFilters>>;
}

const DraftSectionRequirementsFilters = ({ searchFilters, setSearchFilters }: DraftSectionRequirementsFiltersProps) => {
  const { workspaceMembers } = useAppSelector((store) => store.auth);

  const filterDropdownOptions = useMemo(
    () => ({
      [FilterOption.Assignees]: workspaceMembers.map(({ id, username }: User) => ({
        key: id,
        label: (
          <div className="flex items-center gap-2">
            <Avatar size={24} id={username} name={username} className="!text-[13px]" /> {username}
          </div>
        ),
        value: id,
        selected: id === searchFilters.assignees,
        onSelect: () =>
          setSearchFilters((prev) => ({
            ...prev,
            assignees: id === searchFilters.assignees ? "" : id,
          })),
      })),
      [FilterOption.Status]: Object.values(RequirementStatus).map((status) => ({
        key: status,
        label: (
          <div className="flex items-center gap-2">
            {REQUIREMENT_STATUS_TO_META[status].icon}
            {REQUIREMENT_STATUS_TO_META[status].label}
          </div>
        ),
        value: status,
        selected: status === searchFilters.requirementStatusType,
        onSelect: () =>
          setSearchFilters((prev) => ({
            ...prev,
            requirementStatusType: status === searchFilters.requirementStatusType ? "" : status,
          })),
      })),
    }),
    [workspaceMembers, searchFilters.assignees, searchFilters.requirementStatusType, setSearchFilters],
  );

  return (
    <div>
      <DropdownMenu
        items={filterDropdownOptions[FilterOption.Assignees]}
        triggerProps={{ className: "rounded hover:bg-slate-100" }}
      >
        <div className="font-medium text-xs flex justify-center items-center">
          <div
            className="flex items-center gap-1.5 select-none px-3 py-2 border-gray-200"
            css={[!!searchFilters.assignees && tw`text-action`]}
          >
            <div className="flex items-center gap-1">Assignees</div>
            {searchFilters.assignees ? <RiFilterFill size={12} /> : <RiFilterLine size={12} />}
          </div>
        </div>
      </DropdownMenu>
      <DropdownMenu
        items={filterDropdownOptions[FilterOption.Status]}
        triggerProps={{ className: "rounded hover:bg-slate-100" }}
      >
        <div className="font-medium text-xs flex justify-center items-center">
          <div
            className="flex items-center gap-1.5 select-none px-3 py-2 border-gray-200"
            css={[!!searchFilters.requirementStatusType && tw`text-action`]}
          >
            <div className="flex items-center gap-1">Status</div>
            {searchFilters.requirementStatusType ? (
              <RiFilterFill size={12} className="text-xs" />
            ) : (
              <RiFilterLine size={12} className="text-xs" />
            )}
          </div>
        </div>
      </DropdownMenu>
    </div>
  );
};

export default DraftSectionRequirementsFilters;

import { useSearchParams, useNavigate } from "react-router-dom";
import { CAPTURE_BASE_PATH } from "pages/capture/constants";
import { CopilotPresencePage } from "types/Presence";
import { PROJECT_SPECIFIC_BASE_PATH } from "components/copilot/ProposalPlan/project-specific/constants";
import { useEffect } from "react";
import { useFlags } from "./useFlags";
import getStoredPages from "utils/Project/getStoredPages";

export const useMaintainLocation = () => {
  const flags = useFlags();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tab = searchParams.get("tab");
  const projectId = searchParams.get("id")?.toLocaleLowerCase();

  const validTab = Object.values(CopilotPresencePage).includes(tab as CopilotPresencePage) && tab;

  useEffect(() => {
    if (!flags.enableNewProjectFlow || !projectId) return;
    const storedPages = getStoredPages();
    storedPages[projectId] = window.location.href;
    localStorage.setItem("vultron_last_page_url", JSON.stringify(storedPages));
  }, [flags.enableNewProjectFlow, projectId, searchParams]);

  useEffect(() => {
    if (flags.enableNewProjectFlow) return;
    if (validTab && projectId) {
      const storedPages = getStoredPages();
      storedPages[projectId] = validTab;

      localStorage.setItem("vultron_last_page", JSON.stringify(storedPages));
    }
  }, [validTab, projectId, flags.enableNewProjectFlow]);

  useEffect(() => {
    if (!projectId || (tab && tab !== CopilotPresencePage.Project)) return;

    const storedPages = getStoredPages(flags.enableNewProjectFlow);

    const lastPage = storedPages[projectId];

    if (projectId && lastPage) {
      if (lastPage === CopilotPresencePage.Capture) {
        navigate(`${CAPTURE_BASE_PATH}/customer?tab=${lastPage}&id=${projectId}`);
      } else if (lastPage === CopilotPresencePage.Documents) {
        navigate(`${PROJECT_SPECIFIC_BASE_PATH}?tab=${lastPage}&id=${projectId}`);
      } else {
        navigate(`/dashboard/contracts/details?tab=${lastPage}&id=${projectId}`);
      }
    }
  }, [flags.enableNewProjectFlow, tab, projectId, navigate]);
};

import type { Editor } from "@tiptap/react";
import { COLOR_OPTIONS } from "./constants";
import ClickAwayListener from "helpers/ClickAwayListener";
import { useEffect, useLayoutEffect, useRef, useState, type ReactNode } from "react";
import type { MarkType } from "./utils";
import { useMarkColor } from "./utils";
import { ChevronDown } from "lucide-react";
import { ToolbarTooltip } from "../primitives/Tooltip";
import { Portal } from "@radix-ui/react-portal";

type ColorPickerProps = {
  editor: Editor;
  icon: ReactNode;
  markType: MarkType;
  defaultLabel: string;
  variant?: "default" | "bubble";
};

const triggerBaseStyles = "flex items-center gap-1 rounded-md cursor-pointer";
const triggerVariants = {
  bubble: "hover:bg-[#404040] text-[#F4F4F5] py-[2px] px-1 mr-1",
  default: "hover:bg-gray-100 px-2 py-[6px]",
};

const dropdownBaseStyles = "absolute top-full left-0 p-2 rounded-lg shadow-lg z-50";
const dropdownVariants = {
  bubble: "bg-[#171717] border border-[#404040] mt-2",
  default: "bg-white border border-gray-200",
};

const colorButtonBaseStyles = "w-5 h-5 rounded-full border focus:outline-none";
const colorButtonVariants = {
  bubble: "border-[#404040] hover:border-[#666666]",
  default: "border-gray-200 hover:border-gray-400",
};

const customColorButtonBaseStyles = "text-sm w-full text-left px-2 py-1 rounded flex items-center justify-between";
const customColorButtonVariants = {
  bubble: "text-[#F4F4F5] hover:bg-[#404040]",
  default: "hover:bg-gray-100",
};

const defaultColorButtonBaseStyles = "text-sm w-full text-left px-2 py-1 rounded";
const defaultColorButtonVariants = {
  bubble: "text-[#F4F4F5] hover:bg-[#404040]",
  default: "hover:bg-gray-100",
};

export const ColorPicker = ({ editor, icon, markType, defaultLabel, variant = "default" }: ColorPickerProps) => {
  const { isOpen, setIsOpen, currentColor, customColor, setCustomColor, onColorChange } = useMarkColor(
    editor,
    markType,
  );
  const colorInputRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const isBubble = variant === "bubble";

  const triggerClassName = `${triggerBaseStyles} ${isBubble ? triggerVariants.bubble : triggerVariants.default}`;
  const dropdownClassName = `${dropdownBaseStyles} ${isBubble ? dropdownVariants.bubble : dropdownVariants.default}`;
  const colorButtonClassName = `${colorButtonBaseStyles} ${
    isBubble ? colorButtonVariants.bubble : colorButtonVariants.default
  }`;
  const customColorButtonClassName = `${customColorButtonBaseStyles} ${
    isBubble ? customColorButtonVariants.bubble : customColorButtonVariants.default
  }`;
  const defaultColorButtonClassName = `${defaultColorButtonBaseStyles} ${
    isBubble ? defaultColorButtonVariants.bubble : defaultColorButtonVariants.default
  }`;

  const Divider = () => <hr className={`h-px my-1 ${isBubble ? "bg-[#404040]" : "bg-gray-200"}`} />;

  useLayoutEffect(() => {
    if (isOpen && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + 4,
        left: rect.left,
      });
    }
  }, [isOpen]);

  return (
    <ToolbarTooltip content={markType === "highlight" ? "Highlight color" : "Text color"}>
      <div className="relative flex items-center">
        <div ref={triggerRef} onClick={() => setIsOpen(!isOpen)} className={triggerClassName}>
          <div className="flex flex-col items-center w-5">
            <div className="h-5 flex items-center justify-center">{icon}</div>
            <div
              className="w-full h-1 rounded-sm"
              style={{
                backgroundColor: currentColor !== "mixed" ? currentColor : "transparent",
              }}
            />
          </div>
          <ChevronDown size={12} className={isBubble ? "text-[#F4F4F5]" : ""} />
        </div>

        {isOpen && (
          <Portal>
            <ClickAwayListener onClickAway={() => setIsOpen(false)}>
              <div
                className={dropdownClassName}
                style={{
                  position: "fixed",
                  top: position.top,
                  left: position.left,
                  zIndex: 50,
                }}
              >
                <div className="flex flex-col gap-1 pb-1">
                  {COLOR_OPTIONS.map((row, rowIndex) => (
                    <div key={rowIndex} className="flex gap-1 px-2">
                      {row.map((color) => (
                        <button
                          key={color.value}
                          onClick={() => onColorChange(color.value, true)}
                          className={colorButtonClassName}
                          style={{
                            backgroundColor: color.value,
                            boxShadow: currentColor === color.value ? "0 0 0 1px white, 0 0 0 2px #4A86E8" : "none",
                          }}
                          title={color.title}
                        />
                      ))}
                    </div>
                  ))}
                </div>

                <Divider />

                <button onClick={() => colorInputRef.current?.click()} className={customColorButtonClassName}>
                  <span>Custom color</span>
                  <div
                    className={`w-4 h-4 rounded-full border ${isBubble ? "border-[#404040]" : "border-gray-200"}`}
                    style={{ backgroundColor: customColor }}
                  />
                </button>

                <div>
                  <input
                    ref={colorInputRef}
                    type="color"
                    className="w-full h-0 opacity-0 absolute"
                    value={customColor}
                    onChange={(e) => {
                      const newColor = e.target.value;
                      setCustomColor(newColor);
                      onColorChange(newColor, false);
                    }}
                    aria-label="Choose custom color"
                  />
                </div>

                <Divider />

                <button onClick={() => onColorChange("default", true)} className={defaultColorButtonClassName}>
                  {defaultLabel}
                </button>
              </div>
            </ClickAwayListener>
          </Portal>
        )}
      </div>
    </ToolbarTooltip>
  );
};

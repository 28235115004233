import { useMutation } from "YJSProvider/createYJSContext";
import useGetActiveUserSession from "hook/writing-assistant/useGetActiveUserSession";
import { resetAbortController } from "store/reducers/sseControllerSlice";
import { usePollingManager } from "hook/usePollingManager";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  setActiveSession,
  setAssistantPrompt,
  setHideBlock,
  setHighlightedText,
  getChatSessionDocuments,
  getChatTaskStatuses,
  setStreamingState,
  updateActiveSession,
  setActiveMetadata,
} from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { useNotification } from "context/notificationContext";
import { useLocalStorage } from "hook/useLocalStorage";
import type { BlockSource } from "components/copilot/CopilotSchemaImmutableTypes";
import { AssistantBlockType } from "components/copilot/CopilotSchemaImmutableTypes";
import type {
  Storage,
  VultronBlock,
  WritingAssistantSession,
  WritingAssistantUser,
} from "components/copilot/CopilotSchemaTypes";
import { createWritingAssistantBlock } from "utils/complianceMatrix";
import { getWordCount } from "utils/getWordCount";
import { useTrackUserMetric } from "utils/metrics";
import type { ToImmutable } from "YJSProvider/LiveObjects";
import { find, LiveList, LiveObject, update } from "YJSProvider/LiveObjects";
import type { StreamStopMsgData } from "types/Streaming/streamConfig";
import { StreamEvent, StreamEventType } from "types/Streaming/streamConfig";
import { HEARTBEAT } from "const-values/Stream";
import * as logger from "utils/log";
import { apiUrl } from "config/vultronConfig";
import type { InputField } from "types/Chat";
import useWritingAssistantOperations from "hook/writing-assistant/useWritingAssistantOperations";
import { DELIMITER } from "./writing-assistant-input/constants";
import { useFlags } from "hook/useFlags";
import {
  getAndBuildInputMetadataFromBody,
  extractTypeFromLastVultronBlock,
  extractMetadataFromLastVultronBlock,
} from "utils/assistants/utils";
import { createInputFieldData } from "utils/assistants/fileUtils";
import type { ImperativePanelHandle } from "react-resizable-panels";
import { DEFAULT_SESSION_NAME } from "components/molecules/assistant-sidebar-row/constants";
import { INPUT_FIELD_OPTIONS } from "const-values/assistants/constants";

type PastMessage = {
  content: string;
  is_assistant: boolean;
  sent_at: string;
  sources: BlockSource[];
};

export type SendMessageVariables = {
  project_id: string;
  win_themes: string[];
  user_request: string;
  search_file_ids: string[];
  past_messages: PastMessage[];
  use_internet?: boolean;
  hideBlock?: boolean;
  is_first_message?: boolean;
  session_id?: string;
  input_field?: InputField;
  chat_document_ids?: string[];
};

export const useAssistantActions = () => {
  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0", "");
  const useAuth0Header = use_auth0 === true;
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector((store) => store.auth.currentUser?.id);
  const activeProject = useAppSelector((root) => root.project.activeProject);
  const {
    prompt,
    selectedProjectFiles,
    selectedContentLibraryFiles,
    streamState,
    enableInternet,
    hideBlock,
    highlightedText,
    activeSession,
    uploadedDocuments,
  } = useAppSelector((root) => root.writingAssistant);
  const selectedFiles = useMemo(
    () => (enableInternet ? [] : [...selectedContentLibraryFiles, ...selectedProjectFiles]),
    [selectedContentLibraryFiles, selectedProjectFiles, enableInternet],
  );
  const { createSession } = useWritingAssistantOperations();
  const canSubmit = !streamState.isStreamingInProgress && !!activeProject?.internal_contract.id;
  const canRefresh = !streamState.isStreamingInProgress && !!activeProject?.internal_contract.id;
  const trackUserEvent = useTrackUserMetric();
  const abortController = useAppSelector((store) => store.sseController.abortController);
  const flags = useFlags();

  const acceptUploadedDocumentArgument =
    uploadedDocuments?.length &&
    !enableInternet &&
    // If the user is in a workflow, then permit even if the feature flag is off
    (!flags.hideAttachDocument || activeSession?.workflow) &&
    // We don't want to remove uploaded documents so this is to ensure they're not included in requests for other input types
    activeSession?.input_field_type !== INPUT_FIELD_OPTIONS.contentLibrary &&
    flags.documentAttachments;

  const getLiveSession = useCallback(
    (storage: LiveObject<Storage>) => {
      const liveSessions = storage
        .get("writing_assistant")
        ?.get(currentUserId)
        ?.get("sessions") as WritingAssistantUser["sessions"];

      if (!liveSessions) return;

      const liveSession: LiveObject<WritingAssistantSession> | undefined = liveSessions
        ? find(liveSessions, (session) => session.get("id") === activeSession?.id)
        : undefined;

      return liveSession;
    },
    [activeSession?.id, currentUserId],
  );

  const sendMessage = useMutation(
    ({ storage }, messagePayload: SendMessageVariables, refreshId?: string) => {
      let text = "";
      let vultronBlock: LiveObject<VultronBlock>;
      const isNewMessage = !refreshId;

      const liveSessions = storage
        .get("writing_assistant")
        ?.get(currentUserId)
        ?.get("sessions") as WritingAssistantUser["sessions"];

      if (!liveSessions) return;

      let liveSession = getLiveSession(storage);

      const slicedUserRequest = messagePayload.user_request.slice(0, 150);
      if (!liveSession) {
        const newSession = createSession({ name: slicedUserRequest });
        liveSession = newSession;
        dispatch(setActiveSession(newSession?.toJSON() as ToImmutable<WritingAssistantSession>));
      }

      const sessionName = liveSession?.get("name");
      if (sessionName === DEFAULT_SESSION_NAME || !sessionName) {
        liveSession?.set("name", slicedUserRequest);
      }

      const myConversation = liveSession?.get("conversation") as WritingAssistantSession["conversation"];

      const determineBlockType = () => {
        if (vultronBlock.get("enableInternet")) {
          return "internet";
        } else if (vultronBlock.get("promptSources")?.length) {
          return "content search";
        } else {
          return "other";
        }
      };

      const sourceLength = () => {
        if (vultronBlock.get("sources")?.length) {
          return vultronBlock.get("sources")?.length;
        } else {
          return 0;
        }
      };

      if (!isNewMessage && myConversation) {
        const foundBlock = find(
          myConversation,
          (block) => block.get("type") === AssistantBlockType.VultronBlock && block.get("id") === refreshId,
        ) as LiveObject<VultronBlock>;
        if (!foundBlock) return;
        vultronBlock = foundBlock;
        dispatch(setStreamingState({ isStreamingInProgress: true, blockId: refreshId }));
        update(vultronBlock, { error: false, sources: new LiveList([] as LiveObject<BlockSource>[]) });
      } else {
        const liveSources =
          selectedFiles?.map((file) => new LiveObject({ ...file, extension_type: file.fileExtensionType })) || [];

        const userBlock = createWritingAssistantBlock(AssistantBlockType.CoreBlock, {
          body: messagePayload.user_request,
          hideBlock,
          sources: new LiveList(liveSources),
          inputFileType: messagePayload.input_field?.input_field_type,
        });
        vultronBlock = createWritingAssistantBlock(AssistantBlockType.VultronBlock, {
          prompt: messagePayload.user_request,
          promptSources: messagePayload.search_file_ids,
          error: false,
          enableInternet: messagePayload.use_internet,
          inputFileType: messagePayload.input_field?.input_field_type,
        }) as LiveObject<VultronBlock>;

        myConversation?.push([userBlock]);
        myConversation?.push([vultronBlock]);
        dispatch(setStreamingState({ isStreamingInProgress: true, blockId: vultronBlock.get("id") }));
        dispatch(setAssistantPrompt(""));
        dispatch(setHideBlock(false));
        dispatch(setActiveMetadata(undefined));
      }

      fetchEventSource(`${apiUrl}/chat/send_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Workspace: `Workspace ${workspace_id}`,
          Authorization: `Bearer ${localValue}`,
          "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
          Accept: "application/json",
        },
        body: JSON.stringify(messagePayload),
        signal: abortController.signal,
        openWhenHidden: true,
        onmessage(msg) {
          if (msg.event === StreamEvent.StreamRestart) {
            text = msg.data || "";
            dispatch(setStreamingState({ streamCopy: text }));
            update(vultronBlock, { body: text || "", sources: new LiveList([]), error: false });
            return;
          }

          if (msg.event === StreamEvent.StreamStop) {
            dispatch(resetAbortController());
            dispatch(setStreamingState({}));

            try {
              const parsedData = JSON.parse(msg.data) as StreamStopMsgData;
              if (parsedData.type === StreamEventType.Repetition) {
                text = "";
                update(vultronBlock, { body: "", sources: new LiveList([]), error: true });
              }
              if (parsedData.reason) {
                setToast.error({
                  msg: parsedData.reason,
                });
              }
            } catch {}
            return;
          }
          if (msg?.data === HEARTBEAT) return;

          if (msg.data?.length) {
            if (!text) liveSession?.set("updated_at", new Date().toISOString());

            try {
              const parsed = JSON.parse(msg.data);
              if (typeof parsed !== "object" && !parsed?.sources) throw new Error("error");
              const sources = (parsed.sources as BlockSource[]).map((source) => {
                const patchedSource = { ...source, date: source.date || new Date().toISOString() };
                return new LiveObject(patchedSource);
              });
              vultronBlock.set("sources", new LiveList(sources));
            } catch {
              if (msg.data !== DELIMITER) {
                text += msg.data;
                dispatch(setStreamingState({ streamCopy: text }));
              }
            }
          } else if (typeof msg.data === "string") {
            text += "\n";
            dispatch(setStreamingState({ streamCopy: text }));
            vultronBlock.set("body", text);
          }
        },
        async onopen(response) {
          if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
            return; // everything's good
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            setToast.error({
              title: "Unable to send message",
              msg: "We were unable to send message due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
            });
            vultronBlock.set("error", true);
            dispatch(setStreamingState({}));
            logger.error("Writing assistant failed", response);
          }
        },
        onclose() {
          vultronBlock.set("body", text);
          const inputMetadata = getAndBuildInputMetadataFromBody(text);
          if (inputMetadata) {
            vultronBlock.set("input_metadata", { type: inputMetadata.type, data: inputMetadata.data });
          }
          setTimeout(() => dispatch(setStreamingState({})), 100);
          trackUserEvent("Chat: Message Recieved", {
            type: determineBlockType(),
            word_count: getWordCount(vultronBlock.get("body") || ""),
            number_sources: sourceLength(),
          });
        },
        onerror(err) {
          setToast.error({
            title: "Unable to send message",
            msg: "We were unable to send message due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
          vultronBlock.set("error", true);
          dispatch(setStreamingState({}));
          if (err instanceof Error) {
            logger.error(err);
            throw err; // rethrow to stop the operation
          }
        },
      });
    },
    [
      currentUserId,
      workspace_id,
      localValue,
      useAuth0Header,
      activeSession?.id,
      dispatch,
      hideBlock,
      setToast,
      trackUserEvent,
      getLiveSession,
      createSession,
    ],
  );

  const submitMessage = useMutation(
    ({ storage }, sendMessageProperties?: Partial<SendMessageVariables>) => {
      if (!canSubmit) return;
      dispatch(setHighlightedText(""));
      const userRequest = highlightedText && prompt ? `${prompt}: ${highlightedText}` : prompt;

      const winThemes =
        (storage.get("win_themes") as Storage["win_themes"])?.toJSON()?.filter(({ content }) => !!content) || [];

      const liveSession = getLiveSession(storage)?.toJSON() as ToImmutable<WritingAssistantSession> | undefined;
      const myConversation = liveSession?.conversation || [];
      const isFirstMessage = myConversation?.length === 0;
      const lastTwenty = myConversation?.slice(-20).filter(({ body }) => !!body.trim());

      sendMessage({
        win_themes: winThemes?.map(({ content }) => content),
        project_id: activeProject.internal_contract.id,
        user_request: userRequest,
        search_file_ids: !enableInternet ? selectedFiles.map(({ id }) => id) : [],
        past_messages: lastTwenty.map(({ body, type, updated_at, sources }) => ({
          content: body,
          is_assistant: type === AssistantBlockType.VultronBlock,
          sent_at: updated_at,
          sources: (sources || [])
            .filter((source) => !!source.content?.trim())
            .map((source) => ({
              ...source,
              date: source.date || updated_at,
            })),
        })),
        use_internet: enableInternet,
        ...(acceptUploadedDocumentArgument && {
          chat_document_ids: uploadedDocuments.map(({ id }) => id),
        }),
        hideBlock,
        ...(!!activeSession?.workflow && { is_first_message: isFirstMessage }),
        ...(!!activeSession?.workflow && {
          session_id: activeSession?.id,
        }),
        ...(!!activeSession?.input_field && {
          input_field: activeSession.input_field,
        }),
        ...sendMessageProperties,
      });
    },
    [
      activeProject?.internal_contract.id,
      activeSession?.id,
      activeSession?.input_field,
      activeSession?.workflow,
      canSubmit,
      dispatch,
      enableInternet,
      getLiveSession,
      hideBlock,
      highlightedText,
      prompt,
      selectedFiles,
      sendMessage,
      uploadedDocuments,
    ],
  );

  const refreshMessage = useMutation(
    ({ storage }, refreshId: string) => {
      if (!canRefresh) return;

      const winThemes =
        (storage.get("win_themes") as Storage["win_themes"])?.toJSON()?.filter(({ content }) => !!content) || [];

      const liveSession = getLiveSession(storage)?.toJSON() as ToImmutable<WritingAssistantSession> | undefined;
      const myConversation = liveSession?.conversation || [];
      const blockIdx = myConversation?.findIndex(({ id }) => refreshId === id);
      const block = myConversation[blockIdx];
      if (blockIdx === -1) return;
      if (block?.type !== AssistantBlockType.VultronBlock || !block.prompt?.trim()) return;
      const lastTwenty = myConversation?.slice(-20).filter(({ body }) => !!body.trim());

      sendMessage(
        {
          win_themes: winThemes?.map(({ content }) => content),
          project_id: activeProject.internal_contract.id,
          user_request: block.prompt,
          search_file_ids: block.promptSources || [],
          past_messages: lastTwenty.map(({ body, type, updated_at, sources }) => ({
            content: body,
            is_assistant: type === AssistantBlockType.VultronBlock,
            sent_at: updated_at,
            sources: (sources || [])
              .filter((source) => !!source.content?.trim())
              .map((source) => ({
                ...source,
                date: source.date || updated_at,
              })),
          })),
          use_internet: block.enableInternet,
        },
        refreshId,
      );
    },
    [activeProject?.internal_contract.id, canRefresh, getLiveSession, sendMessage],
  );

  return { refreshMessage, submitMessage };
};

export const useSyncActiveSession = () => {
  const inMemorySessionId = useAppSelector((store) => store.writingAssistant.activeSession?.id);
  const activeYjsSession = useGetActiveUserSession();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activeYjsSession && activeYjsSession.id === inMemorySessionId) {
      dispatch(updateActiveSession(activeYjsSession));
    }
  }, [activeYjsSession, dispatch, inMemorySessionId]);
};

export const useInitiateActiveSession = () => {
  const activeSessionId = useAppSelector((store) => store.writingAssistant.activeSession?.id);
  const previousActiveSessionIdRef = useRef(activeSessionId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const previousActiveSessionId = previousActiveSessionIdRef.current;
    if (activeSessionId) {
      dispatch(getChatSessionDocuments(activeSessionId));
    }

    return () => {
      // abort the stream when changing sessions
      if (previousActiveSessionId !== activeSessionId) {
        dispatch(resetAbortController());
        dispatch(setStreamingState({}));
      }
    };
  }, [activeSessionId, dispatch]);
};

export const usePollAssistantTasks = () => {
  const dispatch = useAppDispatch();

  const checkTaskStatus = async () => {
    await dispatch(getChatTaskStatuses());
  };

  usePollingManager(checkTaskStatus);
};

export const useSyncFromLastMessage = () => {
  const dispatch = useAppDispatch();
  const { selectedContentLibraryFiles, selectedProjectFiles, uploadedDocuments, activeSession } = useAppSelector(
    (root) => root.writingAssistant,
  );
  const activeSessionConversation = activeSession?.conversation;
  const inputType = useMemo(() => {
    return extractTypeFromLastVultronBlock(activeSessionConversation || []);
  }, [activeSessionConversation]);
  const messageMetadata = useMemo(() => {
    return extractMetadataFromLastVultronBlock(activeSessionConversation || []);
  }, [activeSessionConversation?.length]);

  useEffect(() => {
    dispatch(updateActiveSession({ input_field_type: inputType }));
  }, [activeSession?.id, inputType, dispatch]);

  useEffect(() => {
    dispatch(setActiveMetadata(messageMetadata));
  }, [dispatch, messageMetadata]);

  useEffect(() => {
    if (!inputType) return;

    const inputField = createInputFieldData({
      contentLibraryFiles: selectedContentLibraryFiles,
      uploadedFiles: uploadedDocuments,
      inputType,
      projectScoped: selectedProjectFiles,
    });

    dispatch(updateActiveSession({ input_field: inputField }));
  }, [dispatch, inputType, selectedContentLibraryFiles, selectedProjectFiles, uploadedDocuments]);
};
export const useResizeAssistantPanel = () => {
  const bottomPanelRef = useRef<ImperativePanelHandle>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [minSize, setMinSize] = useState(0);
  const [defaultOpenSize, setDefaultOpenSize] = useState(0);
  const isDefaultOpenSizeCalculated = useRef<boolean>(false);

  useEffect(() => {
    function updateMinSize() {
      if (containerRef.current) {
        const containerHeight = containerRef.current.offsetHeight;
        const DEFAULT_HEIGHT_ASSISTANT_PIXELS = window.innerHeight >= 800 ? 250 : 200;
        const MIN_HEIGHT_ASSISTANT_PIXELS = 150;
        const defaultOpenSize = (DEFAULT_HEIGHT_ASSISTANT_PIXELS / containerHeight) * 100;
        const minSize = (MIN_HEIGHT_ASSISTANT_PIXELS / containerHeight) * 100;

        setDefaultOpenSize(defaultOpenSize);
        setMinSize(minSize);
      }
    }

    updateMinSize();
    window.addEventListener("resize", updateMinSize);

    return () => window.removeEventListener("resize", updateMinSize);
  }, []);

  useEffect(() => {
    if (!isDefaultOpenSizeCalculated.current && !!defaultOpenSize) {
      bottomPanelRef.current?.resize(defaultOpenSize);
      isDefaultOpenSizeCalculated.current = true;
    }
  }, [defaultOpenSize]);

  return { bottomPanelRef, containerRef, minSize };
};

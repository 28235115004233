export const MarginIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.08529 1.33331H10.0853L13.4186 4.66665V13.3333C13.4186 13.6869 13.2781 14.0261 13.0281 14.2761C12.778 14.5262 12.4389 14.6666 12.0853 14.6666H4.08529C3.73166 14.6666 3.39253 14.5262 3.14248 14.2761C2.89243 14.0261 2.75195 13.6869 2.75195 13.3333V2.66665C2.75195 2.31302 2.89243 1.97389 3.14248 1.72384C3.39253 1.47379 3.73166 1.33331 4.08529 1.33331Z"
      stroke="#1E1E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line x1="5.25195" y1="1.33331" x2="5.25195" y2="14.6666" stroke="#1E1E1E" />
    <line x1="11.252" y1="2.33331" x2="11.252" y2="15" stroke="#1E1E1E" />
    <line x1="12.418" y1="3.83331" x2="2.7513" y2="3.83331" stroke="#1E1E1E" />
    <line x1="13.418" y1="12.5" x2="2.7513" y2="12.5" stroke="#1E1E1E" />
  </svg>
);

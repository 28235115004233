import { useEffect } from "react";
import ContractsSearchBar from "../../components/contractsMainPage/ContractsSearchBar";
import ContractTable from "../../components/contractsMainPage/ContractsTable";
import { getContractManagerData, setContractManager } from "store/reducers/contractManagerReducerSlice";
import { useLocalStorage } from "hook/useLocalStorage";
import { getContractManagerItems } from "api/api";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { usePollingManager } from "hook/usePollingManager";

const ContractManagement = () => {
  const dispatch = useAppDispatch();
  const { data: contracts, isLoadingContractManagerData } = useAppSelector((store) => store.contracts);

  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { setLocalVal: setLocalFilters, localValue: localFilters } = useLocalStorage(
    `contractFilters_${workspace_id}`,
    {},
  );

  const fetchContractManagerItems = async () => {
    try {
      const res = await getContractManagerItems();
      const list = res.data;
      if (list.length > 0) {
        dispatch(setContractManager({ data: list, loading: false }));
      }
    } catch (error) {
      dispatch(setContractManager({ loading: false }));
    }
  };

  usePollingManager(fetchContractManagerItems);

  useEffect(() => {
    if (isLoadingContractManagerData || contracts?.length > 0) return;
    dispatch(getContractManagerData());
  }, []);

  return (
    <>
      <ContractsSearchBar selectedFilters={localFilters} setSelectedFilters={setLocalFilters} />
      <ContractTable selectedFilters={localFilters} />
    </>
  );
};

export default ContractManagement;

import uniqBy from "lodash/uniqBy";
import { highlightAndScrollToElement } from "./utils";
import { useSearchParams } from "react-router-dom";
import type { ExtractionTemplate } from "store/reducers/extract/CurrentExtractionReducer";
import {
  setIsExtractingDocument,
  setTimeRemaining,
  getRequirementGroups,
  getExtractionDocuments,
  getCoordinates,
  appendCoordinateCancelTokens,
  getFilteredRequirements,
  getOrderKeys,
} from "store/reducers/extract/CurrentExtractionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useCallback, useEffect, useMemo, useRef } from "react";
import type { Extraction, Volume } from "components/copilot/CopilotSchemaTypes";
import { ExtractionStatus, InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import useExtractionOperations from "hook/useExtractionOperations";
import { getAtlasRequirements } from "store/reducers/projectReducer";
import { isEqual } from "lodash";
import type { LiveObject, ToImmutable } from "YJSProvider/LiveObjects";
import { LiveList } from "YJSProvider/LiveObjects";
import useGetRequirements from "hook/Requirements/useGetRequirements";
import { GenerationStatus } from "types/Requirement";
import { YJS_OPERATIONS } from "const-values/yjs";
import axios from "axios";
import { useProxyRef } from "hook/useProxyRef";
import { useSelection } from "./document-display/SelectionContext";
import { StepValue } from "./types";
import { useFlags } from "hook/useFlags";
import { transformGeneratedDraftToLiveblocks } from "./template-selection/utils";

export const usePrefetchDocuments = () => {
  const dispatch = useAppDispatch();
  const fileIds = useAppSelector((store) => store.currentExtractionState.currentExtraction?.file_ids);
  const coordinateCancelTokens = useAppSelector((store) => store.currentExtractionState.coordinateCancelTokens);
  const coordinateCancelTokensRef = useProxyRef(coordinateCancelTokens);
  const prevFileIds = useRef(fileIds);

  const prefetchCoordinates = useCallback(() => {
    if (!fileIds?.length || isEqual(fileIds, prevFileIds.current)) return;

    try {
      const coordinatePrefetchForFiles = (fileIds || [])?.map((fileId) => {
        const cancelToken = axios.CancelToken.source();
        const promise = dispatch(getCoordinates({ fileId, cancelToken: cancelToken.token }));
        dispatch(appendCoordinateCancelTokens(cancelToken));
        return promise;
      });
      Promise.all(coordinatePrefetchForFiles);
      prevFileIds.current = fileIds;
    } catch {}
  }, [dispatch, fileIds]);

  useEffect(() => {
    prefetchCoordinates();
  }, [prefetchCoordinates]);

  useEffect(
    () => () => {
      const cancelTokens = coordinateCancelTokensRef.current;
      cancelTokens.forEach((cancelToken) => cancelToken.cancel());
    },
    [coordinateCancelTokensRef],
  );
};

export const useSyncUpdatesWithAtlas = (extraction?: ToImmutable<Extraction>) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const isFirstRender = useRef(true);
  const { selectionState, setSelectedBlocks } = useSelection();
  const selectionStateRef = useProxyRef(selectionState);
  const allFilteredBlocks = useAppSelector((store) => store.currentExtractionState.groupedBlocks.allFilteredBlocks);
  const allFilteredBlocksRef = useProxyRef(allFilteredBlocks);

  useEffect(() => {
    if (projectId) dispatch(getRequirementGroups({ projectId, isInitialFetch: true }));
  }, [dispatch, projectId]);

  const syncMergingAndUnmergingWithAtlas = useCallback(async () => {
    if (!projectId) return;

    await dispatch(getRequirementGroups({ projectId }));
    await dispatch(getFilteredRequirements({ projectId }));
    await dispatch(getOrderKeys({ projectId }));

    const elementIds = selectionStateRef.current?.getSelection().map((node) => {
      if (node instanceof HTMLElement) return node.dataset.element;
      return null;
    });
    const dragSelectedBlocks = uniqBy(
      allFilteredBlocksRef.current.filter((block) => elementIds?.includes(block.id)),
      "id",
    );

    setSelectedBlocks?.(dragSelectedBlocks);
  }, [dispatch, projectId, setSelectedBlocks]);

  useEffect(() => {
    if (!extraction?.compliance_matrix?.length) return;
    if (!isFirstRender.current) syncMergingAndUnmergingWithAtlas();
    isFirstRender.current = false;
  }, [syncMergingAndUnmergingWithAtlas, extraction?.compliance_matrix?.length]);

  const syncExternalClientChangesWithSelection = useCallback(() => {
    if (!allFilteredBlocks) return;

    setSelectedBlocks?.((prev) => {
      return allFilteredBlocks.filter(({ requirement }) =>
        prev.some(({ id }) => id === requirement.requirement.element_id),
      );
    });
  }, [allFilteredBlocks, setSelectedBlocks]);

  useEffect(() => {
    syncExternalClientChangesWithSelection();
  }, [syncExternalClientChangesWithSelection]);
};

export const useValidateExtractionStatus = () => {
  const dispatch = useAppDispatch();
  const createDocumentViewTasks = useAppSelector((state) => state.autopilotHealthCheck.create_document_view_tasks);
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);

  const extractingTask = useMemo(() => {
    return (createDocumentViewTasks || []).find((task) => {
      return !task.failed && task?.reference_id && extraction?.file_ids.includes(task.reference_id);
    });
  }, [createDocumentViewTasks, extraction?.file_ids]);
  const isExtractingDocument = !!extractingTask;

  const timeRemaining = useMemo(() => {
    const tasks = (createDocumentViewTasks || []).filter(
      (task) =>
        extraction?.id === task.analysis_id && task?.reference_id && extraction?.file_ids.includes(task.reference_id),
    );
    return tasks.length > 0
      ? Math.max(...tasks.map((task) => (task?.minutes_time_remaining ? task.minutes_time_remaining : 0)))
      : 0;
  }, [extraction?.file_ids, createDocumentViewTasks, extraction?.id]);

  useEffect(() => {
    dispatch(setIsExtractingDocument(isExtractingDocument));
  }, [dispatch, isExtractingDocument]);

  useEffect(() => {
    dispatch(setTimeRemaining(timeRemaining));
  }, [dispatch, timeRemaining]);

  useEffect(() => {
    if (extractingTask?.is_started && extraction?.file_ids) dispatch(getExtractionDocuments(extraction.file_ids));
  }, [dispatch, extractingTask?.is_started, extraction?.file_ids]);
};

export const useValidateInstantDraftStatusAndConfig = () => {
  const dispatch = useAppDispatch();
  const isSettingToDone = useRef(false);
  const { addAttribution, updateInstantDraftConfig, setInstantDraftCompleted } = useExtractionOperations();
  const requirementResponseTasks = useAppSelector((state) => state.autopilotHealthCheck.requirement_response_tasks);
  const outlineVolumes = useAppSelector((store) => store.currentExtractionState.currentExtraction?.framework.volumes);
  const extractionId = useAppSelector((store) => store.currentExtractionState.currentExtraction?.id);
  const instantDraftStatus = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.status,
  );
  const { data: atlasRequirements, refetch } = useGetRequirements(
    { analysis_id: extractionId || "" },
    {
      enabled: !!extractionId,
      refetchInterval: () =>
        instantDraftStatus === InstantDraftStatus.Pending || instantDraftStatus === InstantDraftStatus.InProgress
          ? 5000
          : false,
    },
  );

  const submittedRequirements = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.submittedRequirements,
  );

  const volumes = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.volumes || [],
  );
  const sections = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.instantDraftConfig?.sections || [],
  );

  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");

  const instantDraftTask = useMemo(() => {
    return requirementResponseTasks.find((task) => {
      return task.reference_id === extractionId;
    });
  }, [extractionId, requirementResponseTasks]);

  const setToDone = useCallback(
    async (extractionId: string, projectId: string) => {
      isSettingToDone.current = true;
      try {
        await dispatch(getAtlasRequirements(projectId));
        const { data: extractionRequirements = [] } = await refetch();

        const submittedAtlasRequirements = extractionRequirements
          .filter(({ id }) => submittedRequirements?.includes(id))
          .map(({ id, response }) => ({
            id,
            content: response?.content || "",
            sources:
              response?.sources?.map(({ file, citations }) => ({
                file_id: file.id,
                name: file.name,
                extension_type: file.type,
                date: file.created_at,
                used_file_contents: citations.map((citation) => ({
                  id: citation.file_content_id,
                  partition_order_key: citation.partition_order_key,
                  content: citation.content,
                  requirement_source_citations: citation.citation,
                })),
              })) || [],
          }));

        if (!submittedAtlasRequirements.length) return;
        console.log("--------- setting draft to done, writing responses --------");

        setInstantDraftCompleted(extractionId, submittedAtlasRequirements);
      } catch (err) {
      } finally {
        isSettingToDone.current = false;
      }
    },
    [dispatch, refetch, setInstantDraftCompleted, submittedRequirements],
  );

  const draftStatusMap = useMemo(() => {
    return atlasRequirements.length
      ? {
          draftStarted: atlasRequirements.some((req) => !!req.response?.generation_status),
          isInProgress:
            instantDraftTask?.completed_responses !== instantDraftTask?.total_responses ||
            atlasRequirements.some((req) => req.response?.generation_status === GenerationStatus.InProgress),
        }
      : null;
  }, [atlasRequirements, instantDraftTask?.completed_responses, instantDraftTask?.total_responses]);

  useEffect(() => {
    if (!draftStatusMap || !projectId || !extractionId || instantDraftStatus === InstantDraftStatus.Done) return;

    if (
      draftStatusMap.isInProgress &&
      instantDraftStatus !== InstantDraftStatus.InProgress &&
      (instantDraftStatus === InstantDraftStatus.Todo || instantDraftStatus === InstantDraftStatus.Pending)
    ) {
      // set status to in progress
      console.log("--------- setting draft to in progress --------");
      updateInstantDraftConfig(extractionId, { status: InstantDraftStatus.InProgress });
      addAttribution(YJS_OPERATIONS.EXTRACTION.SET_INSTANT_DRAFT_STATUS);
      return;
    }

    if (draftStatusMap.draftStarted && !draftStatusMap.isInProgress && !isSettingToDone.current) {
      // set status to done
      setToDone(extractionId, projectId);
      return;
    }
  }, [
    addAttribution,
    draftStatusMap,
    extractionId,
    instantDraftStatus,
    projectId,
    setToDone,
    updateInstantDraftConfig,
  ]);

  useEffect(() => {
    if (!extractionId || instantDraftStatus === InstantDraftStatus.Done) return;

    if (!volumes.length && !sections.length) return;

    const outlineSections = outlineVolumes?.flatMap(({ sections }) => sections) || [];
    const newVolumes = volumes.filter((volumeId) => {
      return !!outlineVolumes?.some(({ id }) => volumeId === id);
    });
    const newSections = sections.filter((sectionId) => {
      return !!outlineSections?.some(({ id }) => sectionId === id);
    });

    if (!isEqual(newVolumes, volumes)) {
      updateInstantDraftConfig(extractionId, { volumes: new LiveList(newVolumes) });
      addAttribution(YJS_OPERATIONS.EXTRACTION.SET_INSTANT_DRAFT_VOLUMES);
    }

    if (!isEqual(newSections, sections)) {
      updateInstantDraftConfig(extractionId, { sections: new LiveList(newSections) });
      addAttribution(YJS_OPERATIONS.EXTRACTION.SET_INSTANT_DRAFT_SECTIONS);
    }
  }, [addAttribution, extractionId, instantDraftStatus, outlineVolumes, sections, updateInstantDraftConfig, volumes]);
};

export const useScrollToNodes = () => {
  const highlightedElementId = useAppSelector((root) => root.currentExtractionState.highlightedElementId);

  const scrollToNodes = useCallback(() => {
    highlightAndScrollToElement(highlightedElementId);
  }, [highlightedElementId]);

  useEffect(() => {
    scrollToNodes();
  }, [scrollToNodes]);

  return scrollToNodes;
};

export const useDeprecatedSteps = () => {
  const flags = useFlags();

  const deprecatedSteps = useMemo(
    () => [
      StepValue.Assign,
      ...(flags.disableTemplateTabInGenerateForOnsite || flags.removeTemplateTabInGenerate ? [StepValue.Template] : []),
    ],
    [flags.disableTemplateTabInGenerateForOnsite, flags.removeTemplateTabInGenerate],
  );
  return deprecatedSteps;
};

export const useAutoImportOutline = (extraction?: ToImmutable<Extraction>) => {
  const flags = useFlags();
  const isReadyStatus = extraction?.status === ExtractionStatus.Ready;
  const framework = extraction?.framework;
  const frameworkVersion = framework?.volumesVersion;
  const hasFramework = !!framework?.volumes?.length && Number.isInteger(frameworkVersion);
  const generatedTemplates = useAppSelector((store) => store.currentExtractionState.templates);
  const { setExtractionOutline } = useExtractionOperations();
  const isImporting = useRef(false);

  const autoImportOutline = useCallback(
    (extractionId: string, templates: ExtractionTemplate[]) => {
      if (isImporting.current) return;
      isImporting.current = true;

      const templatesToLiveVolumes = templates.reduce<LiveObject<Volume>[]>((acc, { template_data }) => {
        const liveVolumes = template_data.volumes.map((volume) => transformGeneratedDraftToLiveblocks(volume));
        return [...acc, ...liveVolumes];
      }, []);

      if (!templatesToLiveVolumes?.length) return;

      setExtractionOutline(extractionId, templatesToLiveVolumes);
    },
    [setExtractionOutline],
  );

  useEffect(() => {
    if (!extraction?.id || !isReadyStatus || !generatedTemplates?.length || hasFramework) return;

    // Only auto-import for non-onsite
    if (flags.removeTemplateTabInGenerate && !flags.disableTemplateTabInGenerateForOnsite) {
      const validTemplates = generatedTemplates.filter(({ context_template }) => !context_template);
      autoImportOutline(extraction.id, validTemplates);
    }
  }, [
    flags.disableTemplateTabInGenerateForOnsite,
    flags.removeTemplateTabInGenerate,
    hasFramework,
    isReadyStatus,
    generatedTemplates,
    extraction?.id,
    autoImportOutline,
  ]);
};

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { SolicitationType } from "components/copilot/CopilotSchemaTypes";
import { xor } from "lodash";

export enum ExtractionType {
  Requirements = 1,
  Template,
}

type State = {
  selectedDocuments: (string | File)[];
  selectedSolicitationType: SolicitationType | null;
};

const initialState: State = {
  selectedDocuments: [],
  selectedSolicitationType: null,
};

const extractReducerV2 = createSlice({
  name: "extractReducerV2",
  initialState,
  reducers: {
    setExtractState: (state: State, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
    setSelectedDocuments: (state: State, action: PayloadAction<State["selectedDocuments"]>) => {
      state.selectedDocuments = xor(state.selectedDocuments, action.payload);
    },
    clearExtractState: () => {
      return initialState;
    },
  },
});

export const { setExtractState, setSelectedDocuments, clearExtractState } = extractReducerV2.actions;

export default extractReducerV2.reducer;

import { DOCUMENT_MARGIN_NODE } from "../extensions/document-margin/constants";
import type { Editor } from "@tiptap/react";

export function getCurrentMarginType(editor: Editor) {
  return editor.getAttributes(DOCUMENT_MARGIN_NODE).marginType;
}
export function getCurrentMargins(editor: Editor) {
  const { top, left, bottom, right } = editor.getAttributes(DOCUMENT_MARGIN_NODE);
  return { top, left, bottom, right };
}

export const formatMargins = (margins: { top: number; bottom: number; left: number; right: number }) =>
  `Top: ${margins.top} Bottom: ${margins.bottom} Left: ${margins.left} Right: ${margins.right}`;

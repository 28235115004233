import compact from "lodash/compact";
import { WORKFLOW_OPTIONS, WORKFLOW_TO_META } from "pages/ai-assistant/constants";
import { useMemo } from "react";
import { START_PROPOSAL_WORKFLOWS } from "./constants";
import { useProjectWorkflowActions } from "pages/Workflows/useProjectWorkflowActions";

export const useStartProposalItems = () => {
  const { createNewWorkflow } = useProjectWorkflowActions();

  const startProposalWorkflows = useMemo(
    () =>
      compact(
        START_PROPOSAL_WORKFLOWS.map((workflowSubtype) =>
          WORKFLOW_OPTIONS.find(({ subType }) => subType === workflowSubtype),
        ),
      ),
    [],
  );

  const workflowItems = useMemo(
    () =>
      startProposalWorkflows.map((workflow) => {
        const Icon = WORKFLOW_TO_META[workflow.name].icon;
        return {
          key: workflow.subType,
          label: (
            <div className="flex items-center gap-2">
              <Icon size={14} />
              {workflow.label}
            </div>
          ),
          onSelect: () => createNewWorkflow(workflow.subType),
        };
      }),
    [startProposalWorkflows, createNewWorkflow],
  );

  return workflowItems;
};

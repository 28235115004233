import type { FeatureFlagSet } from "types/FeatureFlagSet";

export const STATIC_FEATURE_FLAG_MAP: FeatureFlagSet = {
  allowURLParams: false,
  askAiExtraction: true,
  assistantEnhancements: false,
  cmTemplates: true,
  disableExtractionFromTextInRequirements: true,
  disableGenerateSections: true,
  disableIntercomMessenger: true,
  disableInternetChat: true,
  disableTemplateTabInGenerateForOnsite: true,
  documentAttachments: true,
  documentSearch: true,
  emergencyYjs: false,
  enableCancelReviewEngine: true,
  enableNewProjectFlow: false,
  enableOnsiteFeatures: true,
  estimatedTimeMultiplicationFactor: 2,
  globalAssistantWorkflows: {
    workflows: [
      {
        enabled: true,
        name: "questionnaire",
      },
      {
        enabled: true,
        name: "questionnaire_capture",
      },
      {
        enabled: true,
        name: "extract_outline",
      },
      {
        enabled: true,
        name: "revise",
      },
      {
        enabled: true,
        name: "shred",
      },
      {
        enabled: true,
        name: "draft",
      },
      {
        enabled: true,
        name: "score_requirement_response",
      },
      {
        enabled: true,
        name: "search_capability",
      },
      {
        enabled: true,
        name: "search",
      },
      {
        enabled: true,
        name: "search_experience",
      },
      {
        enabled: true,
        name: "technical_matrix",
      },
      {
        enabled: true,
        name: "compliance_score_matrix",
      },
      {
        enabled: true,
        name: "analyze",
      },
      {
        enabled: true,
        name: "analyze_solicitation",
      },
      {
        enabled: false,
        name: "analyze_meeting_notes",
      },
      {
        enabled: true,
        name: "analyze_customer_intelligence",
      },
      {
        enabled: true,
        name: "summarize",
      },
      {
        enabled: true,
        name: "summarize_solicitation",
      },
      {
        enabled: true,
        name: "summarize_meeting_notes",
      },
      {
        enabled: false,
        name: "summarize_customer_intelligence",
      },
      {
        enabled: true,
        name: "report",
      },
      {
        enabled: true,
        name: "report_solicitation",
      },
      {
        enabled: true,
        name: "report_meeting_notes",
      },
      {
        enabled: true,
        name: "report_customer_intelligence",
      },
      {
        enabled: false,
        name: "content_gap_analysis",
      },
      {
        enabled: true,
        name: "capability_matrix",
      },
    ],
  },
  instantDraftFromGeneration: true,
  isAssistantLandingPage: true,
  liveTemplateGeneration: true,
  markdownBreaks: true,
  maxWritingDirections: 10,
  newFeatureBanner: {},
  qualityResponse: true,
  redTeam: false,
  removeTemplateTabInGenerate: true,
  textOutlineEditor: true,
  topBannerV2: { bannerCopy: "" },
  useCRUDInAIAssistant: false,
  useIndexed: true,
  writingAssistantWorkflows: {
    workflows: [
      {
        enabled: true,
        name: "shred",
      },
      {
        enabled: true,
        name: "extract_outline",
      },
    ],
  },
  disableSearchAndRequirementsActions: true,
  showProjectWorkflows: true,
  removeAdvProjectFeatures: true,
  hideAttachDocument: true,
  additionalToneOptions: true,
};

import type { MappedContract, SelectedFilters } from "./types";
import { statusList } from "../../config/data";

export function applySelectedFilters(
  items: MappedContract[],
  selectedFilters: SelectedFilters,
  queryDeadlineDate: { start: string; end: string },
  queryPublishDate: { start: string; end: string },
) {
  if (!selectedFilters || Object.keys(selectedFilters).length === 0) {
    return items;
  }

  const {
    department = [],
    naics_codes = [],
    state = [],
    response_deadline: deadlineDate = [],
    publishedDate = [],
    status: statuses = [],
  } = selectedFilters;

  return items.filter((v) => {
    const matchesDepartment =
      !department.length || department.some((d) => d.toLowerCase().includes(v?.department?.toLowerCase()));

    const matchesNaics =
      !naics_codes.length || naics_codes.some((n) => n.toLowerCase().includes(v?.naics_codes[0]?.toLowerCase()));

    const matchesState =
      !state.length ||
      state.some((s) => v?.contact_information?.office_address?.toLowerCase().includes(s.toLowerCase()));

    const matchesDeadlineDate =
      !deadlineDate.length ||
      (new Date(v?.response_date) >= new Date(queryDeadlineDate?.start) &&
        new Date(v?.response_date) < new Date(queryDeadlineDate?.end));

    const matchesPublishedDate =
      !publishedDate.length ||
      (new Date(v?.publish_date) >= new Date(queryPublishDate?.start) &&
        new Date(v?.publish_date) <= new Date(queryPublishDate?.end));

    const matchesStatus =
      !statuses.length || statuses.some((st) => statusList[v?.status]?.label?.toLowerCase().includes(st.toLowerCase()));

    return (
      matchesDepartment && matchesNaics && matchesState && matchesDeadlineDate && matchesPublishedDate && matchesStatus
    );
  });
}

import ClickAwayListener from "helpers/ClickAwayListener";

interface CDropdownProps {
  className?: string;
  options: string[];
  onSelect: (option: string) => void;
  onClickaway: () => void;
}

const CDropdown: React.FC<CDropdownProps> = ({ className, options, onSelect, onClickaway }) => {
  return (
    <ClickAwayListener onClickAway={onClickaway}>
      <div className={`bg-[#fff] max-w-[300px] text-[12px] ${className}`}>
        {options.map((option) => (
          <div
            className="w-full px-[12px] py-[6px] h-[26px] cursor-pointer flex justify-center items-center"
            key={option}
            onClick={() => onSelect(option)}
          >
            {option}
          </div>
        ))}
      </div>
    </ClickAwayListener>
  );
};

export default CDropdown;

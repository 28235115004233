import type { Editor } from "@tiptap/react";
import { Table, ChevronDown, Plus } from "lucide-react";
import Popover from "components/atoms/popover";
import { TableOptionsContent } from "../TableOptions";
import { useState, useEffect } from "react";
import { ToolbarTooltip } from "components/yjs-editor/primitives/Tooltip";

export const TableControls = ({ editor }: { editor: Editor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredCells, setHoveredCells] = useState({ rows: 0, cols: 0 });
  const [isTableSelected, setIsTableSelected] = useState(editor.isActive("table"));

  const GRID_SIZE = { rows: 8, cols: 10 };

  useEffect(() => {
    const updateTableSelection = () => {
      setIsTableSelected(editor.isActive("table"));
    };

    editor.on("selectionUpdate", updateTableSelection);
    return () => {
      editor.off("selectionUpdate", updateTableSelection);
    };
  }, [editor]);

  const handleMouseEnter = (row: number, col: number) => {
    setHoveredCells({ rows: row + 1, cols: col + 1 });
  };

  const handleInsertTable = () => {
    if (editor.isActive("table")) return;

    editor
      .chain()
      .focus()
      .insertTable({
        rows: hoveredCells.rows,
        cols: hoveredCells.cols,
        withHeaderRow: true,
      })
      .run();

    editor
      .chain()
      .command(({ editor, commands }) => {
        const table = editor.state.doc.lastChild;
        if (!table) return false;
        return commands.insertContentAt(editor.state.doc.nodeSize - 2, {
          type: "paragraph",
          content: [{ type: "text", text: "\n" }],
        });
      })
      .run();

    setIsOpen(false);
  };

  const gridContent = (
    <div className="w-[250px]">
      <div className="p-2 border-b border-gray-200">
        <div className="text-sm text-gray-600">
          {hoveredCells.rows} x {hoveredCells.cols}
        </div>
      </div>

      <div className="p-2">
        <div className="grid grid-cols-10 gap-1">
          {Array.from({ length: GRID_SIZE.rows * GRID_SIZE.cols }).map((_, idx) => {
            const row = Math.floor(idx / GRID_SIZE.cols);
            const col = idx % GRID_SIZE.cols;
            const isHighlighted = row < hoveredCells.rows && col < hoveredCells.cols;

            return (
              <div
                key={idx}
                className={`w-5 h-5 border ${
                  isHighlighted ? "bg-blue-100 border-blue-400" : "border-gray-300"
                } rounded-sm transition-colors`}
                onMouseEnter={() => handleMouseEnter(row, col)}
                onClick={handleInsertTable}
              />
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <ToolbarTooltip content={isTableSelected ? "Table options" : "Insert Table"}>
      <div className="flex items-center">
        <Popover
          open={isOpen}
          onOpenChange={setIsOpen}
          content={isTableSelected ? <TableOptionsContent editor={editor} /> : gridContent}
          contentProps={{ align: "start" }}
        >
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center gap-1 p-2 rounded-md cursor-pointer hover:bg-gray-100"
          >
            <Table size={20} strokeWidth={1.5} />
            {isTableSelected ? <ChevronDown size={12} /> : <Plus size={12} />}
          </div>
        </Popover>
      </div>
    </ToolbarTooltip>
  );
};
